import React, { FC } from 'react'
import { IconProps } from './BellIcon'

const TimetableIcon: FC<IconProps> = ({ fill }) => (
	<svg
		width="29"
		height="28"
		viewBox="0 0 29 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M25.125 23.3334V7.00004C25.125 5.71321 24.0785 4.66671 22.7917 4.66671H20.4583V2.33337H18.125V4.66671H11.125V2.33337H8.79167V4.66671H6.45833C5.1715 4.66671 4.125 5.71321 4.125 7.00004V23.3334C4.125 24.6202 5.1715 25.6667 6.45833 25.6667H22.7917C24.0785 25.6667 25.125 24.6202 25.125 23.3334ZM11.125 21H8.79167V18.6667H11.125V21ZM11.125 16.3334H8.79167V14H11.125V16.3334ZM15.7917 21H13.4583V18.6667H15.7917V21ZM15.7917 16.3334H13.4583V14H15.7917V16.3334ZM20.4583 21H18.125V18.6667H20.4583V21ZM20.4583 16.3334H18.125V14H20.4583V16.3334ZM22.7917 10.5H6.45833V8.16671H22.7917V10.5Z"
			fill={fill || '#666666'}
		/>
	</svg>
)

export default TimetableIcon
