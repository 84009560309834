import React, { FC } from 'react'

import {
	ISection,
	SectionItemType
} from 'containers/Courses/Tabs/Content/ContentForm/Table'
import {
	eventIconSrc,
	lessonIconSrc,
	taskIconSrc,
	testIconSrc
} from 'containers/Courses/Tabs/Content/ContentForm/icons'
import { CourseRole, ResponseEntityId, SchoolRole } from 'types'
import PlainCheckbox from 'UI/Checkbox/PassedCheckbox/PlainCheckbox'
import { Accordion } from 'UI'

import { IEvent } from 'types/models/event.model'
import cl from './style.module.scss'

interface MainTabTableProps {
	sections: ISection[]
	onClick: (
		sectionItemType: SectionItemType,
		sectionItemId: ResponseEntityId,
		courseIdx: ResponseEntityId,
		sectionItem?: IEvent
	) => void
	courseId: ResponseEntityId
	isMobile?: boolean
	currentRole: CourseRole | SchoolRole
}

const LockIcon: React.FC = () => (
	<svg
		width="14"
		height="18"
		viewBox="0 0 14 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.00004 0.672607C4.70254 0.672607 2.83337 2.54177 2.83337 4.83927V6.50594H2.00004C1.08087 6.50594 0.333374 7.25344 0.333374 8.17261V15.6726C0.333374 16.5918 1.08087 17.3393 2.00004 17.3393H12C12.9192 17.3393 13.6667 16.5918 13.6667 15.6726V8.17261C13.6667 7.25344 12.9192 6.50594 12 6.50594H11.1667V4.83927C11.1667 2.54177 9.29754 0.672607 7.00004 0.672607ZM4.50004 4.83927C4.50004 3.46094 5.62171 2.33927 7.00004 2.33927C8.37837 2.33927 9.50004 3.46094 9.50004 4.83927V6.50594H4.50004V4.83927ZM12.0017 15.6726H7.83337V13.7743C8.32921 13.4851 8.66671 12.9534 8.66671 12.3393C8.66671 11.4201 7.91921 10.6726 7.00004 10.6726C6.08087 10.6726 5.33337 11.4201 5.33337 12.3393C5.33337 12.9526 5.67087 13.4851 6.16671 13.7743V15.6726H2.00004V8.17261H12L12.0017 15.6726Z"
			fill="#333333"
		/>
	</svg>
)

const MainTabTable: FC<MainTabTableProps> = ({
	sections,
	onClick,
	courseId,
	isMobile,
	currentRole
}) => (
	<div className={cl.table}>
		<div className={cl.tableBodyList}>
			{sections.map((section) => (
				<Accordion
					labelNode={
						<h2 className={cl.tableAccordionTitle}>{section.header}</h2>
					}
					isOpenDefault
				>
					<ul
						className={`${cl.tableBody} ${cl.tableBody_border}`}
						key={section.id}
					>
						<div className={cl.tableTr}>
							{section.items &&
								section.items.map((sectionItem) => (
									<ul
										onClick={() => {
											if (!sectionItem.available && currentRole === CourseRole.STUDENT) return

											if (sectionItem.type === SectionItemType.EVENT) {
												onClick(
													sectionItem.type,
													sectionItem.id,
													courseId,
													sectionItem as unknown as IEvent
												)
											} else {
												onClick(sectionItem.type, sectionItem.id, courseId)
											}
										}}
										key={sectionItem.id}
										className={cl.tableItem}
									>
										<li>
											<div className={cl.info}>
												{sectionItem.type === SectionItemType.LESSON && (
													<img src={lessonIconSrc} alt="type-lesson" />
												)}
												{sectionItem.type === SectionItemType.EVENT && (
													<img src={eventIconSrc} alt="type-lesson" />
												)}
												{sectionItem.type === SectionItemType.TEST && (
													<img src={testIconSrc} alt="type-lesson" />
												)}
												{sectionItem.type === SectionItemType.TASK && (
													<img src={taskIconSrc} alt="type-lesson" />
												)}
												{sectionItem.name}
												{sectionItem.required && (
													<span className={cl.required}>*</span>
												)}
											</div>

											<div className={cl.itemStatus}>
												{!sectionItem.available && currentRole === CourseRole.STUDENT ? (
													<div className={cl.notAvailable}>
														{!isMobile && <>Недоступно</>}
														<LockIcon />
													</div>
												) : (
													<>
														{sectionItem.type !== SectionItemType.EVENT &&
															currentRole === CourseRole.STUDENT && (
																<>
																	{!isMobile && (
																		<span>
																			{sectionItem.passed
																				? 'Пройден'
																				: 'Не пройден'}
																		</span>
																	)}
																	<PlainCheckbox
																		name={sectionItem.name}
																		value={sectionItem.passed}
																		readOnly
																	/>
																</>
															)}
													</>
												)}
											</div>
										</li>
									</ul>
								))}
						</div>
					</ul>
				</Accordion>
			))}
		</div>
	</div>
)

export default MainTabTable
