import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { courseQuery, testQuery } from 'store/queries'
import { useParams } from 'react-router-dom'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import AttemptAnswerView from 'containers/Courses/Entities/Tests/AttemptAnswerView'
import { useActions, useAppSelector } from 'hooks/redux'
import { ResponseWithError } from 'types'
import { ITestAnswer } from 'store/queries/test.query/types'

const AnswerAttemptViewPage = () => {
	const { courseId, testId, answerId } = useParams()

	if (!(courseId && answerId && testId)) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	const { data: courseInfo, refetch: courseInfoRefetch } =
		courseQuery.useGetCourseQuery(+courseId)
	const { data: testInfo, refetch: testInfoRefetch } =
		testQuery.useGetTestQuery({ testId: +testId })
	const [isPrevAttemptsChecking, setPrevAttemptsChecking] = useState(false)
	const {
		testAnswersInfo,
		currentCheckingId,
		currentAttempt: nextCurrentAttempt
	} = useAppSelector((state) => state.test)
	const { setCurrentAttempt, setCurrentChecking, setTestAnswersInfo } =
		useActions((state) => state.test)
	const { pushError } = useActions((state) => state.system)

	const currentAnswers = useMemo(
		() =>
			testAnswersInfo.find(
				(t) =>
					(t.id.toString() === currentCheckingId &&
						t.attempt.toString() === nextCurrentAttempt) ||
					t.id.toString() === answerId
			),
		[currentCheckingId, nextCurrentAttempt, testAnswersInfo]
	)
	useEffect(() => {
		courseInfoRefetch()
		testInfoRefetch()
	}, [courseId, testId, answerId])

	const togglePrevAttemptsChecking = useCallback(() => {
		setPrevAttemptsChecking((val) => !val)
	}, [setPrevAttemptsChecking])

	const onSetCurrentAttempt = useCallback(
		(nextAttempt: string) => {
			setCurrentAttempt(nextAttempt)
		},
		[setCurrentAttempt]
	)

	const [getAnswersTrigger] = testQuery.useGetAnswersMutation()

	const handleLoadUserAnswers = (userId?: number) => {
		if (!testInfo) return
			; (async () => {
				const response = await getAnswersTrigger({
					userAnswersIdList: testInfo?.data.userAnswersIdList,
					userId
				})

				const { error } = response as unknown as ResponseWithError
				if (error) {
					pushError(error.data)
					return
				}

				const { data } = response as unknown as {
					data: { data: ITestAnswer[] }
				}
				setTestAnswersInfo(data.data)
				setPrevAttemptsChecking(true)
			})()
	}

	useEffect(() => {
		if (!testInfo || (nextCurrentAttempt && currentCheckingId)) {
			return
		}

		; (async () => {
			const response = await getAnswersTrigger({
				userAnswersIdList: testInfo?.data.userAnswersIdList
			})

			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}

			const { data } = response as unknown as {
				data: { data: ITestAnswer[] }
			}

			setTestAnswersInfo(data.data)
			setCurrentAttempt('1')
		})()

		return () => {
			setCurrentAttempt(null)
		}
	}, [testInfo, isPrevAttemptsChecking])

	if (!(courseInfo && testInfo) || !currentAnswers) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<AttemptAnswerView
			testInfo={testInfo.data}
			courseInfo={courseInfo.data}
			currentCheckingId={currentCheckingId}
			currentAnswers={currentAnswers}
			currentAttempt={nextCurrentAttempt || ''}
			testAnswersInfo={testAnswersInfo}
			bestResult={0}
			isPrevAttemptsChecking={isPrevAttemptsChecking}
			togglePrevAttemptsChecking={togglePrevAttemptsChecking}
			setCurrentAttempt={onSetCurrentAttempt}
			setCurrentChecking={setCurrentChecking}
			handleLoadUserAnswers={handleLoadUserAnswers}
		/>
	)
}

export default AnswerAttemptViewPage
