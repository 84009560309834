import React, { FC, useCallback, useEffect, useState } from 'react'

import isMax from 'lodash/max'

import { TaskViewProps as TaskViewContainerProps } from 'containers/Courses/Entities/Tasks/View'
import TaskAnswer from 'containers/Courses/Entities/Tasks/Answer'
import Contacts from 'containers/Courses/Entities/General/Contacts'
import TaskAttempts from 'containers/Courses/Entities/Tasks/Attempts'
import AttemptStatus from 'components/Courses/Entities/Tasks/Attempts/AttemptStatus'
import TeacherTaskInfo from 'containers/Courses/Entities/Tasks/TeacherTaskInfo'
import { AnswerReviewStatus, TaskResponsibilityStatus } from 'types/models/task.model'
import { CourseRole, SchoolRole } from 'types'
import { useAppSelector } from 'hooks/redux'
import { TaskAnswersCheck } from 'containers/Courses/Entities/Tasks/TaskAnswersCheck/TaskAnswersCheck'
import { parseStringToTagA } from 'helpers/parseStringToTagA'

import cl from './style.module.scss'

interface TaskViewProps extends Omit<TaskViewContainerProps, 'isMobile'> { }

const TaskView: FC<TaskViewProps> = ({
	task,
	communicationsInfo,
	taskAnswersAttempts,
	courseId
}) => {
	const [mostAssessment, setMostAssessment] = useState(0)
	const [testStatus, setTestStatus] = useState<AnswerReviewStatus>()
	const { currentRole } = useAppSelector((state) => state.system)
	const [isTaskCheckMode, setTaskCheckMode] = useState(false)
	const [answerEditMode, setAnswerEditMode] = useState(false)

	const checkMostAssessment = () => {
		const allAssessments = taskAnswersAttempts.map((item) => item.assessment)
		const biggestAssessment = isMax(allAssessments)

		if (!biggestAssessment) {
			return
		}

		setMostAssessment(biggestAssessment)
	}

	const checkStatusTestFromAnswersList = () => {
		const accessStatus = taskAnswersAttempts.filter(
			(item) => item.answerStatus === AnswerReviewStatus.PASSED
		)
		const declineStatus = taskAnswersAttempts.filter(
			(item) => item.answerStatus === AnswerReviewStatus.DECLINE
		)

		if (accessStatus.length) {
			setTestStatus(AnswerReviewStatus.PASSED)
			return
		}
		if (declineStatus.length) {
			setTestStatus(AnswerReviewStatus.DECLINE)
			return
		}
		if (!taskAnswersAttempts.length || taskAnswersAttempts[0] === null) {
			setTestStatus(undefined)
			return
		}
		setTestStatus(AnswerReviewStatus.NEED_CHECK)
	}

	const onChangeEditMode = useCallback(
		(value: boolean) => setAnswerEditMode(value),
		[setAnswerEditMode]
	)

	useEffect(() => {
		checkStatusTestFromAnswersList()
		checkMostAssessment()
	}, [task, taskAnswersAttempts])

	if (
		(currentRole === SchoolRole.ADMIN ||
			currentRole === CourseRole.TEACHER ||
			currentRole === CourseRole.CURATOR) &&
		!isTaskCheckMode
	) {
		return <TeacherTaskInfo setTaskCheckMode={setTaskCheckMode} task={task} />
	}

	if (
		(currentRole === SchoolRole.ADMIN ||
			currentRole === CourseRole.TEACHER ||
			currentRole === CourseRole.CURATOR) &&
		isTaskCheckMode
	) {
		return <TaskAnswersCheck courseId={courseId} task={task} />
	}

	const getStartTimeToPassTaskString = () => {
		let str = ''

		if (task.datePassStartSettings.isActive) {
			str += `${new Date(
				task.datePassStartSettings.date
			).toLocaleDateString()} `
			str += task.datePassStartSettings.time
		} else if (task.datePassEndSettings.isActive) {
			str += 'Без ограничений'
		}

		return str
	}

	const getEndTimeToPassTaskString = () => {
		let str = ''

		if (task.datePassEndSettings.isActive) {
			str += `${new Date(task.datePassEndSettings.date).toLocaleDateString()} `
			str += task.datePassEndSettings.time
		} else if (task.datePassStartSettings.isActive) {
			str += 'Без ограничений'
		}

		return str
	}

	console.log(task.answerResponsibilitiesType)

	return (
		<div className={cl.container}>
			<div className={cl.header}>
				<div className={cl.testStatus}>
					<h1>{task.name}</h1>
					<AttemptStatus
						passingScore={10}
						status={testStatus!}
						assessment={mostAssessment}
					/>
				</div>
				<div className={cl.headerInfo}>
					<div className={cl.headerInfoItem}>
						<span>Задание обязательное</span>
						<span>{task.taskResponsibilityStatus === TaskResponsibilityStatus.REQUIRED ? 'Да' : 'Нет'}</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Период прохождения</span>
						<span>
							<span>{getStartTimeToPassTaskString()}</span> <span>—</span>{' '}
							<span>{getEndTimeToPassTaskString()}</span>{' '}
						</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Последние изменения</span>
						<span>{task.dateEdit ? `${task.dateEdit}` : '—'}</span>
					</div>
				</div>
				{task.description && (
					<>
						{task.description.split('\n').map((text) => (
							<p className={cl.caption} key={text}>
								{parseStringToTagA(text, '_blank')}
							</p>
						))}
					</>
				)}
			</div>
			{(task.answerResponsibilitiesType.isFile ||
				task.answerResponsibilitiesType.isText) && (
					<TaskAnswer
						userAnswersIdList={task.userAnswersIdList}
						taskId={task.id}
						maxCountUploadFiles={task.maxCountUploadFiles}
						countAttempts={task.countAttempts}
						answerResponsibilitiesType={task.answerResponsibilitiesType}
						setTestStatus={setTestStatus}
						expireDate={task.datePassEndSettings.date}
					/>
				)}
			{task.userAnswersIdList && task.userAnswersIdList.length > 0 && (
				<TaskAttempts
					passingScore={task.passingScore}
					countAttempts={task.countAttempts}
					taskId={Number(task.id)}
					onChangeEditMode={onChangeEditMode}
					answerEditMode={answerEditMode}
				/>
			)}
			<Contacts communicationsInfo={communicationsInfo} />
		</div>
	)
}

export default TaskView
