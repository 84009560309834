import React, { FC } from 'react'
import { Progress } from 'antd'

import classNames from 'classnames'
import cl from './style.module.scss'

interface StorageFillProgressProps {
	remainingStorage: number
	tariffFilesCapacity: number
	className?: string
}

const StorageFillProgress: FC<StorageFillProgressProps> = ({
	remainingStorage,
	tariffFilesCapacity,
	className
}) => {
	const percentage =
		100 - Math.floor(remainingStorage / (tariffFilesCapacity / 100))
	const tookUpSpace = (
		(tariffFilesCapacity - remainingStorage) /
		1073741824
	).toFixed(2)

	return (
		<div className={classNames(cl.kinescopeLeftovers, className)}>
			<div className={cl.kinescopeCapacity}>
				<span>
					Занято <strong>{tookUpSpace} Гб</strong>
				</span>
				<span>из</span>{' '}
				<span>
					<strong>{tariffFilesCapacity / 1073741824} Гб</strong>
				</span>
			</div>
			<Progress percent={percentage || 0} showInfo={false} />
		</div>
	)
}

export default StorageFillProgress
