import React, { FC, useCallback, useEffect } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormHandleSubmit,
	UseFormRegister
} from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import MainFormTabComponent from 'components/Courses/Entities/Tasks/Tabs/Content/MainForm'
import {
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import { TaskFormState } from 'pages/Course/Task/Create.page'
import { taskQuery } from 'store/queries'
import { TaskCreateResponse } from 'store/queries/task.query/types'
import { ITask } from 'types/models/task.model'
import courseQuery from 'store/queries/course.query'
import { useActions, useAppDispatch } from 'hooks/redux'

export type MainFormTabState = Pick<TaskFormState, 'name' | 'description'>

interface MainFormTabProps {
	errors: FieldErrors<MainFormTabState>
	onChange: (name: FieldPath<MainFormTabState>) => (value: any) => void
	control: Control<MainFormTabState>
	register: UseFormRegister<MainFormTabState>
	handleSubmit: UseFormHandleSubmit<MainFormTabState>
	isEditMode?: boolean
	currentTaskInfo?: ITask<string>
	onOpenCanceledChange: () => void
}

const MainFormTab: FC<MainFormTabProps> = ({
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	isEditMode,
	currentTaskInfo,
	onOpenCanceledChange
}) => {
	const { pushSuccess } = useActions((state) => state.system)
	const dispatch = useAppDispatch()

	const [createTask] = taskQuery.useCreateTaskMutation()
	const [changeTask] = taskQuery.useChangeTaskMutation()

	const { courseId, sectionId } = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		register('name', {
			required: ValidationErrorMessages.EMPTY,
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('description', {
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		return () => {
			register('name', {
				required: undefined,
				minLength: undefined,
				pattern: undefined
			})
			register('description', {
				minLength: undefined,
				pattern: undefined
			})
		}
	}, [])
	const submitEditHandler = useCallback(
		handleSubmit(async (data) => {
			if (!currentTaskInfo) {
				return
			}
			const response = await changeTask({
				body: {
					...data,
					name: data.name?.trim(),
					description: data.description?.trim()
				},
				taskId: +`${currentTaskInfo.id}`
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				return
			}
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
			dispatch(courseQuery.util.invalidateTags(['course']))
		}),
		[handleSubmit]
	)
	const submitCreateHandler = useCallback(
		handleSubmit(async ({ name, description }) => {
			const response = await createTask({
				body: {
					name: name?.trim(),
					description: description?.trim()
				},
				sectionId: +`${sectionId}`
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				return
			}
			dispatch(courseQuery.util.invalidateTags(['course']))
			const { data } = response as unknown as TaskCreateResponse
			navigate(`/course/${courseId}/task/edit/${data.taskId}`)
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
		}),
		[handleSubmit]
	)

	return (
		<MainFormTabComponent
			onOpenCanceledChange={onOpenCanceledChange}
			control={control}
			onChange={onChange}
			onSubmit={isEditMode ? submitEditHandler : submitCreateHandler}
			errors={errors}
		/>
	)
}

export default MainFormTab
