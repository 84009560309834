import React, { FC, useEffect, useMemo, useState } from 'react'
import cl from 'components/Courses/Entities/Lessons/View/style.module.scss'
import { Link, Loader } from 'UI'
import { LinkStyles } from 'UI/Link/types'
import LessonViewComponent from 'components/Courses/Entities/Lessons/View'
import { ILesson } from 'types/models/lesson.model'
import { ResponseEntityId } from 'types'
import { CommunicationsSettings } from 'types/models/course.model/settings/communications.model'
import { courseQuery, lessonQuery } from 'store/queries'
import userQuery from 'store/queries/user.query'
import NavigationThroughLessons from 'UI/NavigationThroughLessons'
import { useMatchMedia } from 'hooks'
import { useAppDispatch } from 'hooks/redux'

export interface LessonViewProps {
	lesson: ILesson
	courseId: ResponseEntityId
	communicationsInfo: CommunicationsSettings
	courseSectionsId: number[]
}

const LessonView: FC<LessonViewProps> = ({
	communicationsInfo,
	lesson,
	courseId,
	courseSectionsId
}) => {
	const { isMobile } = useMatchMedia()
	const [isLessonPassing, setIsLessonPassing] = useState(false)
	const [lessonPassedTrigger] = lessonQuery.usePassedLessonMutation()

	const { data: userInfo } = userQuery.useGetYourselfQuery()
	const dispatch = useAppDispatch()
	const [trigger, { data: coursePassingInfo }] =
		courseQuery.endpoints.courseGetPassing.useLazyQuery()
	const { data: sectionsInfo } = courseQuery.useGetCourseSectionsQuery({
		sectionsIdList: courseSectionsId
	})

	const mappedItems = useMemo(() => {
		if (!sectionsInfo) return undefined

		return sectionsInfo.data.map((i) => i.items).flat()
	}, [sectionsInfo])

	useEffect(() => {
		if (!courseId || !userInfo) {
			return
		}
		trigger({ courseId, userId: userInfo.data.id })
	}, [courseId, userInfo])

	useEffect(() => {
		if (!coursePassingInfo || !coursePassingInfo?.data) {
			setIsLessonPassing(false)
			return
		}

		if (!coursePassingInfo.data.passingLessonsIdList.length) {
			setIsLessonPassing(false)
			return
		}

		const filteredPassLessonsId =
			coursePassingInfo.data.passingLessonsIdList.filter(
				(passLessonId) => passLessonId === lesson.id
			)

		if (!filteredPassLessonsId.length) {
			setIsLessonPassing(false)
			return
		}

		setIsLessonPassing(true)
	}, [coursePassingInfo, lesson])

	const lessonPassedHandler = async (lessonId: ResponseEntityId) => {
		await lessonPassedTrigger({ lessonId })

		dispatch(courseQuery.util.invalidateTags(['course']))
	}

	if (!lesson || !sectionsInfo || !mappedItems) {
		return <Loader />
	}

	return (
		<div className={cl.viewPage}>
			<div
				className={cl.back}
				style={{ marginBottom: '12px' }}
			>
				<Link
					styleTypes={[LinkStyles.GO_BACK]}
					href={`/course/view/${courseId}`}
				>
					Вернуться назад к курсу
				</Link>
			</div>

			<NavigationThroughLessons
				currentId={+lesson.id!}
				itemsArray={mappedItems!}
				courseId={courseId}
				isMobile={isMobile}
			/>

			<LessonViewComponent
				lessonPassedHandler={lessonPassedHandler}
				isLessonPassing={isLessonPassing}
				lesson={lesson}
				communicationsInfo={communicationsInfo}
			/>
		</div>
	)
}

export default LessonView
