import React, { FC } from 'react'

import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import plusIconSrc from 'UI/Button/images/plus.svg'
import CreateQuestion from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFieldArrayRemove,
	UseFormGetValues,
	UseFormRegister
} from 'react-hook-form'

import { QuestionsFormTabState } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm'
import EmptyQuestionsView from './QuestionsList/EmptyQuestionsView'

import cl from './style.module.scss'

interface QuestionFormProps {
	fields: Record<'id', string>[]
	onAppend: () => void
	control: Control<any>
	onSubmit: () => void
	errors: FieldErrors<QuestionsFormTabState>
	onChange: (name: FieldPath<QuestionsFormTabState>) => (value: any) => void
	remove: UseFieldArrayRemove
	getValues: UseFormGetValues<QuestionsFormTabState>
	register: UseFormRegister<QuestionsFormTabState>
	onOpenCanceledChange: () => void
}

const QuestionsForm: FC<QuestionFormProps> = ({
	control,
	fields,
	onAppend,
	onSubmit,
	errors,
	remove,
	onChange,
	getValues,
	register,
	onOpenCanceledChange
}) => (
	<div className={cl.container}>
		<div className={cl.header}>
			<span>Здесь вы можете добавить вопросы для тестирования</span>
			<Button
				onClick={() => onAppend()}
				styleTypes={[ButtonStyles.ROUND, ButtonStyles.PRIMARY]}
			>
				<img src={plusIconSrc} alt="plus" />
				Добавить вопрос
			</Button>
		</div>
		{fields.length ? (
			<CreateQuestion
				onOpenCanceledChange={onOpenCanceledChange}
				getValues={getValues}
				fields={fields}
				errors={errors}
				onSubmit={onSubmit}
				control={control}
				remove={remove}
				onChange={onChange}
				register={register}
			/>
		) : (
			<EmptyQuestionsView />
		)}
	</div>
)

export default QuestionsForm
