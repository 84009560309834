import React, { FC } from 'react'
import { WeekDaysContainerProps } from 'containers/Timetable/Tabs/WeekTab/WeekDays'

import { getNameDayOfTheWeek } from 'helpers/time'
import cl from './style.module.scss'

interface WeekDaysComponentProps extends WeekDaysContainerProps {}

const WeekDays: FC<WeekDaysComponentProps> = ({ mappedWeekDays, short }) => (
	<>
		{mappedWeekDays.map((w) => (
			<div className={cl.cell} key={w.id}>
				{getNameDayOfTheWeek(w.date.getDay(), { isShort: true })}
				<>
					{!short && (
						<>
							, {w.date.getDay() === 0 ? 7 : w.date.getDay()}/{w.date.getDate()}
						</>
					)}
				</>
			</div>
		))}
	</>
)

export default WeekDays
