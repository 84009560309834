import React from 'react'
import { useParams } from 'react-router-dom'

import GetCertificate from 'containers/Courses/Entities/Certificate/Get'

import { Link } from 'UI'
import { LinkStyles } from 'UI/Link/types'

import cl from '../style.module.scss'

const GetCertificatePage: React.FC = () => {
	const { courseId } = useParams()

	return (
		<>
			<div className={cl.container}>
				<div className={cl.back}>
					<Link styleTypes={[LinkStyles.GO_BACK]} href="/">
						Вернуться назад
					</Link>
				</div>

				<div className={cl.header}>
					<div className={cl.headerInfo}>
						<h2>Получение сертификата</h2>
						<p>Оставьте отзыв и получите сертификат</p>
					</div>
				</div>

				<GetCertificate courseId={+courseId!} />
			</div>
		</>
	)
}

export default GetCertificatePage
