import { createSlice } from '@reduxjs/toolkit'

import { CourseSliceState } from 'store/slices/course/types'
import { inviteStudentExtra } from 'store/slices/course/extraReducers/inviteStudent.extra'

const initialState: CourseSliceState = {
	meta: {
		isLoading: false,
		error: ''
	}
}

const courseSlice = createSlice({
	name: 'school',
	initialState,
	reducers: {},
	extraReducers: {
		...inviteStudentExtra
	}
})

export default courseSlice
