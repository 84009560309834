import React from 'react'
import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ModalHeight } from 'UI/Modal/types'
import classNames from 'classnames'

import cl from './style.module.scss'

interface ConfirmSwitchModalProps {
	onClose: () => void
	onConfirm: (extendsTariff: boolean) => void
	isOpen: boolean
	tariffName: string
	usersLimit: number
	courseLimit: number
	isPaidTariffActive?: boolean
}

const ConfirmSwitchModal: React.FC<ConfirmSwitchModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	tariffName,
	courseLimit,
	usersLimit,
	isPaidTariffActive
}) => (
	<>
		{!isPaidTariffActive ? (
			<Modal
				styleTypes={[ModalHeight.SMALL]}
				isOpen={isOpen}
				onClose={onClose}
				title={`Переход на ${tariffName} тариф`}
				description={`Вы собираетесь приобрести тариф ${tariffName}. Вам будет доступно добавление до ${usersLimit} пользователей.`}
			>
				<div className={cl.buttonsContainer}>
					<Button onClick={onClose} styleTypes={[ButtonStyles.DEFAULT_BG]}>
						Отмена
					</Button>
					<Button
						onClick={() => onConfirm(false)}
						styleTypes={[ButtonStyles.PRIMARY]}
					>
						Перейти к оплате
					</Button>
				</div>
			</Modal>
		) : (
			<Modal
				styleTypes={[ModalHeight.SMALL]}
				isOpen={isOpen}
				onClose={onClose}
				title={`Смена тарифа на тариф ${tariffName}`}
				description={`Вы собираетесь приобрести тариф ${tariffName}. Вам будет доступно добавление до ${usersLimit} пользователей.`}
			>
				<div
					className={classNames(
						cl.buttonsContainer,
						isPaidTariffActive && cl.buttonsContainerCol
					)}
				>
					<Button
						onClick={() => onConfirm(false)}
						styleTypes={[ButtonStyles.PRIMARY]}
					>
						Перейти на тариф сейчас
					</Button>
					<Button
						onClick={() => onConfirm(true)}
						styleTypes={[ButtonStyles.PRIMARY]}
					>
						Тариф начнет действовать после окончания текущего тарифа
					</Button>
					<Button onClick={onClose} styleTypes={[ButtonStyles.DEFAULT_BG]}>
						Отмена
					</Button>
				</div>
			</Modal>
		)}
	</>
)

export default ConfirmSwitchModal
