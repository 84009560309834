import React, { FC } from 'react'

import { useMatchMedia } from 'hooks'
import { getFullName, getUniqueId } from 'helpers'

import { NotificationsBar } from 'containers/Header'
import { Avatar, Button } from 'UI'
import { IAvatar } from 'UI/Avatar/types'
import { exitIconSrc } from 'components/Header/ProfileMenu/images/icons'
import { UserRole } from 'store/slices/system/types'
import { CourseRole, ResponseEntityId, SchoolRole, StorageKeys } from 'types'
import { ButtonStyles } from 'UI/Button/types'
import { useActions, useAppSelector } from 'hooks/redux'
import { saveItemToStorage } from 'helpers/storage'
import { StorageTypes } from 'helpers/storage/types'
import { useNavigate } from 'react-router-dom'
import { ICourse } from 'types/models/course.model'
import StorageFillProgress from 'components/StorageFillProgress'
import { DefaultDropdownItemsListProps } from './Default'
import { DefaultDropdownItem } from '../Items'
import homeIconSrc from '../images/home.svg'

import cl from '../style.module.scss'

export interface ProfileMenuDropdownItemsListProps
	extends Omit<DefaultDropdownItemsListProps, 'isTableDropdown'>,
	IAvatar {
	otherRolesInfo?: UserRole[]
}

const ProfileMenuDropdownItemsList: FC<ProfileMenuDropdownItemsListProps> = ({
	dropdownTitle = '',
	dropdownItemsList,
	onClose,
	firstName,
	lastName,
	photoUrl,
	otherRolesInfo
}) => {
	const {
		setCurrentSchoolId,
		setCurrentRole,
		setCurrentCoursesIdList,
		pushSuccess
	} = useActions((state) => state.system)
	const { currentRole } = useAppSelector((state) => state.system)
	const navigate = useNavigate()
	const { isMobile } = useMatchMedia()

	const { remainingStorage, tariffFilesCapacity } = useAppSelector(
		(state) => state.kinescope
	)

	const setSystemRole = (
		role: CourseRole | SchoolRole,
		schoolId: ResponseEntityId,
		courseInfo: ICourse[]
	) => {
		const coursesIdList = courseInfo.map((course) => course.id)

		setCurrentRole(role)
		setCurrentSchoolId(`${schoolId}`)
		setCurrentCoursesIdList(coursesIdList)

		saveItemToStorage([StorageKeys.CURRENT_ROLE, role], {
			storageType: StorageTypes.LOCAL_STORAGE
		})
		saveItemToStorage([StorageKeys.CURRENT_SCHOOL_ID, schoolId], {
			storageType: StorageTypes.LOCAL_STORAGE
		})
		saveItemToStorage([StorageKeys.CURRENT_COURSES_ID_LIST, coursesIdList], {
			storageType: StorageTypes.LOCAL_STORAGE
		})
		navigate('/')
	}

	return (
		<>
			<div className={cl.profileMenuList}>
				<div className={cl.profileMenuListWrapper}>
					{isMobile && (
						<>
							<div onClick={onClose} className={cl.exitButton}>
								<img src={exitIconSrc} alt="exit" />
								<span>Назад</span>
							</div>
							<div className={cl.profileInfoContainer}>
								<Avatar
									firstName={firstName}
									lastName={lastName}
									photoUrl={photoUrl}
								/>
								<span>{getFullName({ firstName, lastName })}</span>
							</div>
							<div className={cl.notificationContainer}>
								<NotificationsBar />
							</div>
							<div className={cl.line} />
						</>
					)}
					<div className={cl.dropdownList}>
						{dropdownTitle && (
							<div className={cl.dropdownTitle}>{dropdownTitle}</div>
						)}
						{dropdownItemsList.map((dropdownItem) => (
							<DefaultDropdownItem
								dropdownItem={dropdownItem}
								key={dropdownItem.id}
								onClose={onClose}
							/>
						))}

						{remainingStorage &&
							tariffFilesCapacity > 0 &&
							(currentRole === SchoolRole.ADMIN ||
								currentRole === CourseRole.TEACHER) && (
								<StorageFillProgress
									remainingStorage={remainingStorage}
									tariffFilesCapacity={tariffFilesCapacity}
								/>
							)}
					</div>
					{otherRolesInfo && otherRolesInfo.length > 0 && (
						<div className={cl.additionalProfileMenuList}>
							{otherRolesInfo.map((role) => (
								<div className={cl.additionalListItem} key={getUniqueId()}>
									{role.schoolInfo.name.trim() && (
										<div className={cl.additionalHeader}>
											<div className={cl.additionalImg}>
												<img src={homeIconSrc} alt="school" />
											</div>
											<span>{role.schoolInfo.name}</span>
										</div>
									)}
									<div className={cl.additionalContent}>
										{role.roleSchool === SchoolRole.ADMIN && (
											<Button
												className={cl.additionalButton}
												styleTypes={
													isMobile
														? [ButtonStyles.PRIMARY, ButtonStyles.ROUND]
														: [ButtonStyles.DROPDOWN]
												}
												onClick={() => {
													setSystemRole(SchoolRole.ADMIN, role.schoolInfo.id, [])
													pushSuccess({
														message: 'Вы успешно переключились на администратора'
													})
												}}
											>
												Переключиться на администратора
											</Button>
										)}
										{role.roleCourse === CourseRole.CURATOR && (
											<Button
												className={cl.additionalButton}
												styleTypes={
													isMobile
														? [ButtonStyles.PRIMARY, ButtonStyles.ROUND]
														: [ButtonStyles.DROPDOWN]
												}
												onClick={() => {
													setSystemRole(
														CourseRole.CURATOR,
														role.schoolInfo.id,
														role.courseInfo
													)
													pushSuccess({
														message: 'Вы успешно переключились на куратора'
													})
												}}
											>
												Переключиться на куратора
											</Button>
										)}
										{role.roleCourse === CourseRole.TEACHER && (
											<Button
												className={cl.additionalButton}
												styleTypes={
													isMobile
														? [ButtonStyles.PRIMARY, ButtonStyles.ROUND]
														: [ButtonStyles.DROPDOWN]
												}
												onClick={() => {
													setSystemRole(
														CourseRole.TEACHER,
														role.schoolInfo.id,
														role.courseInfo
													)
													pushSuccess({
														message: 'Вы успешно переключились на преподавателя'
													})
												}}
											>
												Переключиться на преподавателя
											</Button>
										)}
										{role.roleCourse === CourseRole.STUDENT && (
											<Button
												className={cl.additionalButton}
												styleTypes={
													isMobile
														? [ButtonStyles.PRIMARY, ButtonStyles.ROUND]
														: [ButtonStyles.DROPDOWN]
												}
												onClick={() => {
													setSystemRole(
														CourseRole.STUDENT,
														role.schoolInfo.id,
														role.courseInfo
													)
													pushSuccess({
														message: 'Вы успешно переключились на студента'
													})
												}}
											>
												Переключиться на ученика
											</Button>
										)}
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default ProfileMenuDropdownItemsList
