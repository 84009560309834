export interface SchoolService {
	inviteUser: InviteUser
	checkInviteCode: CheckInviteCode
}

export interface CheckInviteCodeParams {
	inviteCode: string
}

export interface InviteUserParams {
	email: string
}
type InviteUser = (params: InviteUserParams) => Promise<void>
type CheckInviteCode = (params: CheckInviteCodeParams) => Promise<void>

export enum SchoolApiRoutes {
	INVITE_USER = 'schools/invite-user',
	CHECK_INVITE_CODE = 'schools/invite-user-check-code'
}
