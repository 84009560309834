import React from 'react'

import { TariffsPlansContainerProps } from 'containers/Tariffs/TariffsPlans'
import TariffCard from 'containers/Tariffs/TariffsPlans/TariffCard'

import { Select } from 'UI'

import cl from './style.module.scss'

interface TariffsPlansComponentProps extends TariffsPlansContainerProps {}

const TariffsPlans: React.FC<TariffsPlansComponentProps> = ({
	tariffs,
	activeTariffId,
	tariffItems,
	control,
	onChange
}) => {
	const isPaidTariffActive = tariffs.some(
		(tariff) =>
			String(tariff.id) === String(activeTariffId) &&
			!!tariff.value.priceAndPeriod[0].price
	)

	return (
		<div className={cl.tariffs}>
			<div className={cl.tariffsHeader}>
				<h3 className={cl.title}>Тарифные планы</h3>

				<Select
					optionsList={tariffItems}
					control={control}
					onChange={onChange('currentPeriod')}
					name="currentPeriod"
				/>
			</div>

			<div className={cl.text}>
				<p>При покупке тарифа на более длительный срок, вы экономите деньги</p>
			</div>

			<div className={cl.tariffsList}>
				{/* FIX filter out «Индивидуальные условия» */}
				{[...tariffs]
					.sort((a, b) =>
						a.value.priceAndPeriod[0].price > b.value.priceAndPeriod[0].price
							? 1
							: -1
					)
					.filter((t) => t.value.name !== '«Индивидуальные условия»')
					.map((t) => (
						<TariffCard
							{...t}
							key={t.id}
							isActive={t.id.toString() === activeTariffId.toString()}
							control={control}
							isPaidTariffActive={isPaidTariffActive}
						/>
					))}
			</div>
		</div>
	)
}
export default TariffsPlans
