import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import CoursesAccordion from 'containers/Courses/Accordion/CoursesAccordion'
import StudentTabs from 'containers/Courses/StudentRole/Tabs'

import plusIconSrc from 'UI/Button/images/plus.svg'
import cl from 'pages/style.module.scss'
import { useAppSelector } from 'hooks/redux'
import { CourseRole, SchoolRole } from 'types'

const SchoolCoursesListPage = () => {
	const navigate = useNavigate()
	const { currentRole } = useAppSelector((state) => state.system)

	const clickHandler = () => {
		navigate('/course/create')
	} // TODO ПОМЕНЯТЬ НА ССЫЛКУ

	return (
		<div className={cl.asidePageContainer}>
			<div className={cl.header}>
				<h2>
					{currentRole === CourseRole.STUDENT ? 'Мои курсы' : 'Список курсов'}
				</h2>
				{currentRole === SchoolRole.ADMIN &&(
						<Button
							onClick={clickHandler}
							styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
						>
							<img src={plusIconSrc} alt="plus" />
							Добавить курс
						</Button>
					)}
			</div>
			<div className={cl.content}>
				{currentRole === CourseRole.STUDENT ? (
					<StudentTabs />
				) : (
					<CoursesAccordion />
				)}
			</div>
		</div>
	)
}

export default SchoolCoursesListPage
