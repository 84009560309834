type GetImageUrlFromFile = (file?: File) => Promise<string | ArrayBuffer | null> | File

// @ts-ignore
const getImageUrlFromFile: GetImageUrlFromFile = async (file) => {
	if (!file) {
		return ''
	}

	if (file.size > 273678336) {
		return file
	}

	const fileReader = new FileReader()

	fileReader.readAsArrayBuffer(file)

	return new Promise((resolve) => {
		fileReader.onload = () => resolve(fileReader.result)
	})
}

export default getImageUrlFromFile
