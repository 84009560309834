import React, { FC, useCallback, useEffect, useState } from 'react'

import CreateQuestionFormComponent from 'components/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/CreateQuestionForm'
import { CreateQuestionsFormProps } from 'components/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.component'
import { useForm, useWatch } from 'react-hook-form'
import { AnswerType, PicturesInfoItem } from 'types/models/test.model'
import isNil from 'lodash/isNil'
import { Constants, ResponseWithError, ValidationErrorMessages } from 'types'
import { getImageUrlFromFile } from 'helpers/files'
import { useParams } from 'react-router-dom'
import { testQuery } from 'store/queries'
import { getFormData } from 'helpers'
import { useActions } from 'hooks/redux'
import { AddImageResponse } from 'store/queries/test.query/types'
import { ImagePreview } from 'UI/DragAndDrop/types'
import { questionsHaveUnfilledCorrectAnswers } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/index'

export interface CreateQuestionFormProps
	extends Omit<
		CreateQuestionsFormProps,
		'remove' | 'tabsList' | 'onSelectTab' | 'selectedQuestionTabId' | 'fields'
	> {
	name: `questions.${number}`
	questionMobileModalIsOpen: boolean
	closeModal: () => void
}

export interface CreateQuestionImageForm {
	pictures: File[]
}

const CreateQuestionForm: FC<CreateQuestionFormProps> = ({
	name,
	control,
	onSubmit,
	onChange,
	errors,
	getValues,
	register,
	questionMobileModalIsOpen,
	closeModal,
	onOpenCanceledChange
}) => {
	const answersTypeWatcher = useWatch({ name: `${name}.answerType`, control })
	const questions = useWatch({
		control,
		name: 'questions'
	})
	const { pushError } = useActions((state) => state.system)
	const [triggerAddPicture] = testQuery.useAddPictureMutation()
	const [triggerDeletePicture] = testQuery.useDeletePictureMutation()
	const { testId } = useParams()

	const [previewPictures, setPreviewPictures] = useState<ImagePreview[]>([])
	const {
		register: createRegister,
		control: createControl,
		setValue
	} = useForm<CreateQuestionImageForm>()

	const picturesInfoList = useWatch({
		name: `${name}.picturesInfoList`,
		control
	})

	const onDeleteImage = useCallback(
		(urls: string[]) => {
			urls.forEach((url) => {
				const isBlob = url.startsWith('bloqb:')

				if (!isBlob && picturesInfoList) {
					const pictureId = picturesInfoList.find(
						(p) => p.url === url.slice(url.indexOf('/back'))
					)?.id

					triggerDeletePicture({
						testId: Number(testId),
						body: {
							pictureId: Number(pictureId)
						}
					})

					onChange(`${name}.picturesInfoList`)(
						picturesInfoList.filter(
							(p) => p.url !== url.slice(url.indexOf('/back'))
						)
					)
				}

				setPreviewPictures((prev) => prev.filter((p) => p.url !== url))
			})
		},
		[onChange, testId, picturesInfoList, name]
	)

	useEffect(() => {
		if (!picturesInfoList) return

		setPreviewPictures(
			picturesInfoList.map((p) => ({
				url: `${Constants.BASE_URL}${p.url}`
			}))
		)
	}, [picturesInfoList])

	useEffect(() => {
		createRegister('pictures')
	}, [createRegister])

	const pictures = useWatch({
		name: 'pictures',
		control: createControl
	})

	const onPicturesChange = useCallback(
		(files: File[]) => {
			setValue('pictures', files)
		},
		[setValue]
	)

	useEffect(() => {
		if (!pictures) return

		async function makeBlob() {
			const buffers = await Promise.all(pictures.map(getImageUrlFromFile))
			const blobs = buffers.map((b, i) => ({
				url: b ? URL.createObjectURL(new Blob([b])) : '',
				file: pictures[i]
			}))

			const prevPictures =
				picturesInfoList?.map((p) => ({
					url: `${Constants.BASE_URL}${p.url}`
				})) || []

			setPreviewPictures([...prevPictures, ...blobs])
		}

		makeBlob()
	}, [pictures])

	const onImageSubmit = useCallback(async () => {
		const mappedPictures: PicturesInfoItem[] = []

		if (questionsHaveUnfilledCorrectAnswers(questions)) return

		const response = await triggerAddPicture({
			body: await getFormData(pictures),
			testId: Number(testId)
		})

		const { error } = response as ResponseWithError

		if (error) {
			pushError(error.data)
		}

		const { data: imageResponse } = response as unknown as {
			data: AddImageResponse
		}

		imageResponse.data.forEach((i) => mappedPictures.push(i))
		mappedPictures.push(...(picturesInfoList || []))

		onChange(`${name}.picturesInfoList`)(mappedPictures)
	}, [pictures, picturesInfoList, questions])

	const onCreateTestQuestionSubmit = useCallback(
		async (e) => {
			e.preventDefault()

			if (!pictures || !pictures.length) {
				onSubmit()

				return
			}

			await onImageSubmit()
			await onSubmit()
		},
		[onSubmit, onImageSubmit, pictures]
	)

	useEffect(() => {
		register(`${name}.correctAnswersIdList`, {
			validate: (value) =>
				(Array.isArray(value) &&
					getValues(`${name}.answerType`) === AnswerType.MULTIPLE &&
					value.length > 1) ||
				(getValues(`${name}.answers`).length &&
					typeof value === 'number' &&
					getValues(`${name}.answerType`) === AnswerType.SINGLE) ||
				(getValues(`${name}.answerType`) === AnswerType.SINGLE &&
					ValidationErrorMessages.CHOOSE_AT_LEAST_ONE) ||
				(getValues(`${name}.answerType`) === AnswerType.MULTIPLE &&
					ValidationErrorMessages.CHOOSE_AT_LEAST_TWO)
		})
		register(`${name}.pictures`)
	}, [])

	useEffect(() => {
		const correctAnswersIdList = getValues(`${name}.correctAnswersIdList`)
		if (
			answersTypeWatcher === AnswerType.SINGLE &&
			Array.isArray(correctAnswersIdList)
		) {
			onChange(`${name}.correctAnswersIdList`)(
				[...correctAnswersIdList].shift()
			)
			return
		}
		if (
			answersTypeWatcher === AnswerType.MULTIPLE &&
			!Array.isArray(correctAnswersIdList)
		) {
			onChange(`${name}.correctAnswersIdList`)(
				isNil(correctAnswersIdList) ? [] : [correctAnswersIdList]
			)
		}
	}, [answersTypeWatcher])

	return (
		<CreateQuestionFormComponent
			onOpenCanceledChange={onOpenCanceledChange}
			closeModal={closeModal}
			questionMobileModalIsOpen={questionMobileModalIsOpen}
			getValues={getValues}
			control={control}
			name={name}
			onChange={onChange}
			onSubmit={onCreateTestQuestionSubmit}
			onDeleteImage={onDeleteImage}
			previewPictures={previewPictures}
			onPicturesChange={onPicturesChange}
			errors={errors}
			createControl={createControl}
		/>
	)
}

export default CreateQuestionForm
