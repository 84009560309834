import React, { FC } from 'react'
import {
	ISection,
	SectionItemType
} from 'containers/Courses/Tabs/Content/ContentForm/Table'
import { actionIconSrc } from 'containers/Users/Table/images/icons'
import { Dropdown, SortPanel } from 'UI'
import { DropdownItem } from 'UI/Dropdown/types'

import { ResponseEntityId, SortDirections } from 'types'
import ContentFormTableItem from 'components/Courses/Tabs/Content/ContentForm/TableItem'
import cl from './style.module.scss'

interface ContentFormTableProps {
	sections: ISection[]
	sortSection: (
		sectionId: ResponseEntityId,
		sortDirection: SortDirections
	) => void
	getDropdownSectionHeader: (
		id: ResponseEntityId,
		name: string
	) => DropdownItem[]
	getDropdownSectionItemsList: (
		sectionItemId: ResponseEntityId,
		sectionItemType: SectionItemType,
		activity: boolean,
		sectionId: ResponseEntityId,
		sectionItemName: string
	) => DropdownItem[]
}

const ContentFormTable: FC<ContentFormTableProps> = ({
	sections,
	getDropdownSectionHeader,
	getDropdownSectionItemsList,
	sortSection
}) => (
	<div className={cl.table}>
		<ul className={cl.tableHeader}>
			<li>Занятие</li>
			<li>Дата добавления</li>
			<li>Активность</li>
			<li>Дата изменения</li>
		</ul>
		{sections.length > 0 && (
			<div className={cl.tableBodyList}>
				{sections.map((section, sectionIndex) => (
					<ul className={cl.tableBody} key={section.id}>
						<div className={cl.tableTh}>
							<div className={cl.tableThLeft}>
								<h2>{section.header}</h2>
								<Dropdown
									dropdownItemsList={getDropdownSectionHeader(
										section.id,
										section.header
									)}
								>
									<img src={actionIconSrc} alt="actions" />
								</Dropdown>
							</div>
							<SortPanel
								sortingItemId={section.id}
								onSort={sortSection}
								arrayLength={sections.length}
								arrayIndex={sectionIndex}
								className={cl.sortPanel}
							/>
						</div>
						<div className={cl.tableTr}>
							{section.items &&
								section.items.length > 0 &&
								section.items.map((sectionItem, sectionItemIndex) => (
									<ul key={sectionItem.id} className={cl.tableItem}>
										<ContentFormTableItem
											sectionId={section.id}
											sectionItemsArrayIndex={sectionItemIndex}
											sectionItem={sectionItem}
											sectionItems={section.items}
											sectionItemsArrayLength={section.items.length}
											key={sectionItem.id}
											getDropdownSectionItemsList={getDropdownSectionItemsList}
										/>
									</ul>
								))}
						</div>
					</ul>
				))}
			</div>
		)}
	</div>
)

export default ContentFormTable
