import { TimeMeasure } from 'containers/Courses/Tabs/Content/NotificationsForm'

type getDaysByWeeksType = (number: number, measureType: TimeMeasure) => number

const getDaysByWeeks: getDaysByWeeksType = (number, measureType) => {
	if (measureType === TimeMeasure.DAYS) {
		return number
	}

	if (measureType === TimeMeasure.WEEKS) {
		return number * 7
	}

	if (measureType === TimeMeasure.MONTHS) {
		return number * 30
	}

	throw new Error('Time measure is invalid')
}

export default getDaysByWeeks
