import React, { FC } from 'react'
import UserAttemptsComponent from 'components/Courses/Entities/Tests/View/UserAttempts'
import UserAttemptsMobileComponent from 'components/Courses/Entities/Tests/View/UserAttemptsMobile'
import { ITestAnswer } from 'store/queries/test.query/types'
import { useMatchMedia } from 'hooks'

export interface UserAttemptsProps {
	userAnswers: ITestAnswer[]
	questionsCount: number
	setIsQuestionsCheckMode: (_: number) => void
}

const UserAttempts: FC<UserAttemptsProps> = ({
	userAnswers,
	questionsCount,
	setIsQuestionsCheckMode
}) => {
	const { isMobile } = useMatchMedia()

	if (userAnswers.length === 0) {
		return null
	}

	return (
		<>
			{isMobile ? (
				<UserAttemptsMobileComponent
					questionsCount={questionsCount}
					userAnswers={userAnswers}
					setIsQuestionsCheckMode={setIsQuestionsCheckMode}
				/>
			) : (
				<UserAttemptsComponent
					questionsCount={questionsCount}
					userAnswers={userAnswers}
					setIsQuestionsCheckMode={setIsQuestionsCheckMode}
				/>
			)}
		</>
	)
}

export default UserAttempts
