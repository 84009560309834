import { DeleteEventModalContainerProps } from 'containers/Courses/Entities/Events/Edit/DeleteEventModal'
import React, { FC } from 'react'
import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ModalSize } from 'UI/Modal/types'

import cl from './style.module.scss'

interface DeleteEventComponentProps
	extends Omit<DeleteEventModalContainerProps, 'eventId' | 'sectionId' | 'courseId'> {
	onSubmit: () => void
}

const DeleteEventModal: FC<DeleteEventComponentProps> = ({
	isOpen,
	onClose,
	onSubmit
}) => (
	<Modal
		onClose={onClose}
		isOpen={isOpen}
		styleTypes={[ModalSize.BIG]}
		title="Удаление мероприятия"
		description="Вы действительно хотите удалить это мероприятие?"
	>
		<div className={cl.buttons}>
			<Button
				styleTypes={[ButtonStyles.OUTLINE_PRIMARY, ButtonStyles.ROUND]}
				onClick={onClose}
			>
				Отменить
			</Button>
			<Button
				styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
				onClick={onSubmit}
			>
				Удалить
			</Button>
		</div>
	</Modal>
)

export default DeleteEventModal
