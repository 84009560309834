import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Link, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ModalHeight, ModalSize } from 'UI/Modal/types'
import { ReviewResponsibilityStatus } from 'types/models/course.model'
import { useActions } from 'hooks/redux'
import courseQuery from 'store/queries/course.query'
import { TestTypes } from 'types/models/test.model'
import cl from '../style.module.scss'

interface TestPassingModalProps {
	isOpen: boolean
	onSeeResults: () => void
	courseId: string
	type?: TestTypes
	reviewRequired?: number
	getCertificateWithoutReview?: (certificateId?: number) => void
	hasCertificate?: boolean
}

const TestPassingSuccess: FC<TestPassingModalProps> = ({
	isOpen,
	onSeeResults,
	courseId,
	type,
	reviewRequired,
	getCertificateWithoutReview,
	hasCertificate
}) => {
	const navigate = useNavigate()
	const { testModalPassingSuccessClose } = useActions((state) => state.system)
	const { setIsFinalTestOngoing } = useActions((state) => state.test)
	const [triggerCreateCertificate] = courseQuery.useCreateCertificateMutation()

	const onGetCertificate = async () => {
		let certificateId: number
		if (!hasCertificate) {
			const createCertificateResponse = await triggerCreateCertificate({
				courseId: Number(courseId)
			})

			// @ts-ignore
			certificateId = createCertificateResponse.data.certificateId

			if (getCertificateWithoutReview && ReviewResponsibilityStatus.OPTIONAL) {
				getCertificateWithoutReview(certificateId)
			}
		}

		if (reviewRequired === ReviewResponsibilityStatus.REQUIRED) {
			navigate(`/course/${courseId}/get-certificate`)
		}

		setIsFinalTestOngoing(false)
		testModalPassingSuccessClose()
	}

	return (
		<Modal
			styleTypes={[ModalSize.BIG, ModalHeight.SMALL]}
			title="Поздравляем! Вы прошли тестирование."
			description={
				type === TestTypes.FINAL
					? 'Теперь вы можете оставить отзыв о курсе и получить сертификат'
					: 'Вы можете посмотреть результаты теста'
			}
			isOpen={isOpen}
		>
			<div className={cl.buttonsContainer}>
				<Button
					onClick={() => {
						testModalPassingSuccessClose()
						onSeeResults()
					}}
					styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
				>
					Посмотреть результаты
				</Button>
				{type === TestTypes.FINAL && !hasCertificate && (
					<Button
						onClick={onGetCertificate}
						styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
					>
						Получить сертификат
					</Button>
				)}
				{/* {type === TestTypes.INTERMEDIATE && ( */}
				{/* 	<Button styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}> */}
				{/* 		<Link href={`/course/${courseId}`}>Вернуться к курсу</Link> */}
				{/* 	</Button> */}
				{/* )} */}
			</div>
		</Modal>
	)
}

export default TestPassingSuccess
