import React, { FC, useMemo, useState } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormHandleSubmit,
	UseFormRegister
} from 'react-hook-form'
import classnames from 'classnames'
import { DevTool } from '@hookform/devtools'

import { getUniqueId } from 'helpers'
import { Tabs } from 'UI'
import { ITab } from 'UI/Tabs/types'
import { TaskFormState } from 'pages/Course/Task/Create.page'
import MainFormTab, { MainFormTabState } from '../Content/MainForm'

import cl from '../style.module.scss'

interface TasksTabsProps {
	isPreview?: boolean
	errors: FieldErrors<TaskFormState>
	onChange: (name: FieldPath<TaskFormState>) => (value: any) => void
	control: Control<TaskFormState>
	register: UseFormRegister<TaskFormState>
	handleSubmit: UseFormHandleSubmit<TaskFormState>
	onOpenCanceledChange: () => void
}

const TasksTabs: FC<TasksTabsProps> = ({
	isPreview = false,
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	onOpenCanceledChange
}) => {
	const tabsList = useMemo(
		(): ITab[] => [
			{ id: getUniqueId(), text: 'Основная информация' },
			{ id: getUniqueId(), text: 'Настройки ответа' },
			{ id: getUniqueId(), text: 'Ограничения' }
		],
		[]
	)
	const [selectedTabId, setSelectedTabId] = useState<ITab['id']>(tabsList[0].id)

	return (
		// TODO refactor
		<div className={cl.container}>
			<div
				style={{ display: isPreview ? 'none' : 'block' }}
				className={classnames([
					cl.tabsToggles,
					{
						[cl.tabsTogglesHidden]: isPreview
					}
				])}
			>
				<Tabs
					disabled
					value={selectedTabId}
					tabs={tabsList}
					onChange={setSelectedTabId}
				/>
			</div>
			<div className={cl.tabsContent}>
				{selectedTabId === tabsList[0].id && (
					<MainFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						register={register as unknown as UseFormRegister<MainFormTabState>}
						control={control as unknown as Control<MainFormTabState>}
						onChange={onChange}
						errors={errors}
						handleSubmit={handleSubmit}
					/>
				)}
				<DevTool control={control} />
			</div>
		</div>
	)
}

export default TasksTabs
