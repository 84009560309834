import React, { FC } from 'react'

import { ReminderItemContainerProps } from 'containers/Courses/Tabs/Content/NotificationsForm/ReminderForm/ReminderItem'

import { Button, Select, TextInput } from 'UI'
import { IOption } from 'UI/Select/types'

import closeIcon from './images/close.svg'
import cl from './style.module.scss'

interface ReminderItemComponentProps extends ReminderItemContainerProps {
	reminderOptions: IOption[]
	onRemoveReminderItem: () => void
}

const ReminderItem: FC<ReminderItemComponentProps> = ({
	inNumber,
	timeMeasure,
	index,
	reminderOptions,
	control,
	onChange,
	onRemoveReminderItem
}) => (
	<div className={cl.reminderItem}>
		<TextInput
			onChange={onChange(`reminderDelaysDaysList.${index}.inNumber`)}
			control={control}
			name={`reminderDelaysDaysList.${index}.inNumber`}
		/>

		<Select
			optionsList={reminderOptions}
			onChange={onChange(`reminderDelaysDaysList.${index}.timeMeasure`)}
			control={control}
			name={`reminderDelaysDaysList.${index}.timeMeasure`}
		/>

		<Button onClick={onRemoveReminderItem}>
			<img src={closeIcon} className={cl.closeIcon} alt="" />
		</Button>
	</div>
)

export default ReminderItem
