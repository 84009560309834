import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MonthNavigationComponent from 'components/Timetable/TimetableNavigation/MonthNavigation'
import {
	getFirstAndLastDaysOfTheWeek,
	getMonthNameByItsNumber,
	getNameDayOfTheWeek
} from 'helpers/time'
import {
	TimetableContentFormValues,
	TimetableModeTypes
} from 'containers/Timetable/TimetableContent'
import { Control, FieldPath, useWatch } from 'react-hook-form'
import { DatePickerTypes } from 'UI/DatePicker'
import dayjs from 'dayjs'

export interface MonthNavigationContainerProps {
	control: Control<TimetableContentFormValues>
	onChange: (
		name: FieldPath<TimetableContentFormValues>
	) => (value: any) => void
}

const getPickerByCurrentMode = (currentMode: TimetableModeTypes) => {
	switch (currentMode) {
		case TimetableModeTypes.MONTH:
			return DatePickerTypes.MONTH
		case TimetableModeTypes.WEEK:
			return DatePickerTypes.WEEK
		case TimetableModeTypes.SCHEDULE:
		default:
			return undefined
	}
}

const MonthNavigation: React.FC<MonthNavigationContainerProps> = ({
	control,
	onChange
}) => {
	const [datePopupOpen, setDatePopupOpen] = useState(false)
	const currentDate = useWatch({
		control,
		name: 'currentDate',
		defaultValue: new Date()
	})

	const currentMode = useWatch({
		control,
		name: 'mode'
	})

	const currentDayjsDate = useWatch({
		control,
		name: 'currentDayjsDate'
	})

	useEffect(() => {
		if (
			!currentDate ||
			!currentDayjsDate ||
			currentDayjsDate.isSame(currentDate)
		)
			return

		onChange('currentDate')(currentDayjsDate.toDate())
	}, [currentDayjsDate])

	const currentDatePicker = useMemo(
		() => getPickerByCurrentMode(currentMode),
		[currentMode]
	)

	const currentMonthNumber = useMemo(
		() => currentDate.getMonth(),
		[currentDate]
	)

	const currentMonthName = useMemo(
		() => getMonthNameByItsNumber(currentMonthNumber),
		[currentMonthNumber]
	)

	const onDateIncrement = useCallback(() => {
		const newDate = new Date(+currentDate)

		if (currentMode === TimetableModeTypes.MONTH) {
			newDate.setMonth(newDate.getMonth() + 1)

			onChange('currentDate')(newDate)
			onChange('currentDayjsDate')(dayjs(newDate))
			return
		}

		if (currentMode === TimetableModeTypes.WEEK) {
			const { start } = getFirstAndLastDaysOfTheWeek(currentDate)

			newDate.setDate(start + 8)

			onChange('currentDate')(newDate)
			onChange('currentDayjsDate')(dayjs(newDate))
			return
		}

		if (currentMode === TimetableModeTypes.SCHEDULE) {
			newDate.setDate(currentDate.getDate() + 1)

			onChange('currentDate')(newDate)
			onChange('currentDayjsDate')(dayjs(newDate))
		}
	}, [onChange, currentDate, currentMode])

	const onDateDecrement = useCallback(() => {
		const newDate = new Date(+currentDate)

		if (currentMode === TimetableModeTypes.MONTH) {
			newDate.setMonth(newDate.getMonth() - 1)

			onChange('currentDate')(newDate)
			onChange('currentDayjsDate')(dayjs(newDate))
			return
		}

		if (currentMode === TimetableModeTypes.WEEK) {
			const { start } = getFirstAndLastDaysOfTheWeek(currentDate)
			newDate.setDate(start - 7)

			onChange('currentDate')(newDate)
			onChange('currentDayjsDate')(dayjs(newDate))
			return
		}

		if (currentMode === TimetableModeTypes.SCHEDULE) {
			newDate.setDate(currentDate.getDate() - 1)

			onChange('currentDate')(newDate)
			onChange('currentDayjsDate')(dayjs(newDate))
		}
	}, [onChange, currentDate, currentMode])

	const onMonthPopupOpen = useCallback(
		() => setDatePopupOpen(true),
		[setDatePopupOpen]
	)
	const onMonthPopupClose = useCallback(
		() => setDatePopupOpen(false),
		[setDatePopupOpen]
	)

	const dateString = useMemo(() => {
		if (currentMode === TimetableModeTypes.SCHEDULE) {
			return `${getNameDayOfTheWeek(
				currentDate.getDay()
			)}, ${currentDate.getDate()} ${currentMonthName}`
		}

		if (currentMode === TimetableModeTypes.WEEK) {
			const { start, end } = getFirstAndLastDaysOfTheWeek(currentDate)

			return `${start} - ${end} ${currentMonthName.slice(
				0,
				3
			)} ${currentDate.getFullYear()}`
		}

		return `${currentMonthName} ${currentDate.getFullYear()}`
	}, [currentMonthName, currentDate, currentMode])

	return (
		<MonthNavigationComponent
			date={dateString}
			onDateIncrement={onDateIncrement}
			onDateDecrement={onDateDecrement}
			control={control}
			currentDatePicker={currentDatePicker}
			onMonthPopupOpen={onMonthPopupOpen}
			onMonthPopupClose={onMonthPopupClose}
			datePopupOpen={datePopupOpen}
		/>
	)
}

export default MonthNavigation
