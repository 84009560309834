import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { FieldPath, useForm } from 'react-hook-form'
import isEmail from 'validator/lib/isURL'

import UserProfileFormComponent from 'components/PersonalUser/Profile/Form.component'
import { ResponseWithError, ValidationErrorMessages } from 'types'
import { IMainUserInfo } from 'types/models/user.model'
import { selectsQuery, userQuery } from 'store/queries'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { getFormData } from 'helpers'
import { useActions, useAppSelector } from 'hooks/redux'
import { SuccessChangeInfoModal } from 'components/PersonalUser/modals'
import { useModal, useScrollToError } from 'hooks'
import GetImageUrlFromFile from 'helpers/files/getImageUrlFromFile'
import CancelModal from 'components/NotificationSettingsForm/NotificationSettingsTable/CancelModal'
import { useNavigate } from 'react-router-dom'
import { IOption } from 'UI/Select/types'

export interface UserProfileFormState extends IMainUserInfo {
	avatar?: File
	avatarUrl?: string
}

const UserProfileForm = () => {
	const [successChangeInfoModalIsOpen, setChangeInfoModalIsOpen] =
		useState(false)
	const [changePasswordModalIsOpened, setChangePasswordModalIsOpened] =
		useState(false)
	const {
		modalOpen: cancelModalOpen,
		onClose: onCancelModalClose,
		onOpen: onCancelModalOpen
	} = useModal()

	const [triggerGetOptions] = selectsQuery.useLazyGetCityOptionsQuery()

	const [cityOptions, setCityOptions] = useState<IOption[]>([])

	const [updateYourself] = userQuery.useUpdateYourselfMutation()

	const navigate = useNavigate()

	const { token } = useAppSelector((state) => state.system)
	const { successPasswordChange } = useAppSelector((state) => state.user.meta)
	const { pushError } = useActions((state) => state.system)
	const { isLoading, data: userData } = userQuery.useGetYourselfQuery(token)

	const openChangePasswordModalHandler = useCallback(
		setChangePasswordModalIsOpened.bind(null, true),
		[]
	)
	const closeChangePasswordModalHandler = useCallback(
		setChangePasswordModalIsOpened.bind(null, false),
		[]
	)

	const closeModalHandler = () => {
		setChangeInfoModalIsOpen(false)
	}

	const { register, control, formState, setValue, handleSubmit, watch } =
		useForm<UserProfileFormState>()
	useEffect(() => {
		register('firstName', {
			required: ValidationErrorMessages.EMPTY,
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('lastName', {
			required: ValidationErrorMessages.EMPTY,
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('email', {
			required: ValidationErrorMessages.EMPTY,
			validate: {
				value: (value) => isEmail(value) || ValidationErrorMessages.INCORRECT
			}
		})
		register('phone', {
			required: ValidationErrorMessages.EMPTY
		})
		register('city', {
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('aboutMe', {
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('avatarUrl')
		register('avatar')
	}, [])

	const avatarFile = watch('avatar')
	const avatarUrl = watch('avatarUrl')

	useLayoutEffect(() => {
		if (avatarFile === undefined) {
			return
		}
		;(async () => {
			const blob = await GetImageUrlFromFile(avatarFile)
			setValue('avatarUrl', blob ? URL.createObjectURL(new Blob([blob])) : '')
		})()
	}, [avatarFile])

	useEffect(() => {
		const user = userData?.data
		if (!user) {
			return
		}
		setValue('firstName', user.firstName.trim())
		setValue('avatarUrl', user.avatarUrl)
		setValue('lastName', user.lastName.trim())
		setValue('email', user.email.trim())
		setValue('phone', user.phone.trim())
		setValue('city', user.city.trim())
		setValue('birthDate', user.birthDate || undefined)
		setValue('aboutMe', user.aboutMe.trim())
	}, [userData])

	useEffect(() => {
		if (successPasswordChange) {
			setChangePasswordModalIsOpened(false)
		}
	}, [successPasswordChange])

	const changeHandler = useCallback(
		(name: FieldPath<UserProfileFormState>) => (value: any) =>
			setValue(name, value),
		[]
	)

	const getCityOptions = useCallback(async (hint: string) => {
		if (!hint.trim()) {
			setCityOptions([])
			return
		}

		const { data } = await triggerGetOptions(hint)

		if (!data) {
			setCityOptions([])
			return
		}

		const { data: options } = data

		setCityOptions(options)
	}, [])

	useScrollToError(formState)

	const submitHandler = useCallback(
		handleSubmit(async (data) => {
			const response = await updateYourself(
				await getFormData({
					...data,
					firstName: data.firstName.trim(),
					lastName: data.lastName.trim(),
					email: data.email.trim(),
					phone: data.phone.trim(),
					city: data.city.trim(),
					aboutMe: data.aboutMe.trim()
				})
			)
			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}

			setChangeInfoModalIsOpen(true)
		}),
		[handleSubmit]
	)

	const onCancelModalConfirm = useCallback(() => {
		navigate('/')
		onCancelModalClose()
	}, [])

	if (isLoading) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			<UserProfileFormComponent
				cityOptions={cityOptions}
				getOptionsCallback={getCityOptions}
				control={control}
				onChange={changeHandler}
				errors={formState.errors}
				avatarUrl={avatarUrl || ''}
				onSubmit={submitHandler}
				changePasswordModalIsOpened={changePasswordModalIsOpened}
				onCloseChangePasswordModal={closeChangePasswordModalHandler}
				onOpenChangePasswordModal={openChangePasswordModalHandler}
				onCancelModalOpen={onCancelModalOpen}
			/>
			<SuccessChangeInfoModal
				isOpen={successChangeInfoModalIsOpen}
				onClose={closeModalHandler}
			/>
			<CancelModal
				isOpen={cancelModalOpen}
				onClose={onCancelModalClose}
				onConfirm={onCancelModalConfirm}
				confirmText="Выйти"
			/>
		</>
	)
}

export default UserProfileForm
