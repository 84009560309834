import React, { useEffect, useMemo, useState } from 'react'

import { ITab } from 'UI/Tabs/types'
import { getUniqueId } from 'helpers'
import { CheckboxGroup, Tabs } from 'UI'
import { isPast } from 'helpers/time'
import courseQuery from 'store/queries/course.query'
import { useAppSelector } from 'hooks/redux'
import { ICourse } from 'types/models/course.model'
import ProcessCoursesTab from './Content/Process'
import CompletedCoursesTab from './Content/Completed'

import cl from './style.module.scss'

const StudentTabs = () => {
	const [courses, setCourses] = useState<ICourse[]>([])

	const activeCourses = useMemo(
		() =>
			courses.filter((course) => (
				!course.marketplaceSettings.dateLearningSettings.start || (isPast(course.marketplaceSettings.dateLearningSettings.start))
			)),
		[courses]
	)

	const { currentCoursesIdList } = useAppSelector((state) => state.system)
	const { currentRole } = useAppSelector((state) => state.system)

	const { data: coursesInfo, isSuccess } = courseQuery.useGetCoursesQuery({
		coursesIdList: currentCoursesIdList,
		role: currentRole
	})

	useEffect(() => {
		if (!coursesInfo || !isSuccess) {
			return
		}
		setCourses(coursesInfo.data)
	}, [coursesInfo, isSuccess])

	const tabsList = useMemo(
		(): ITab[] => [
			{ id: getUniqueId(), text: 'В процессе' },
			{ id: getUniqueId(), text: 'Завершенные' }
		],
		[]
	)
	const [selectedTabId, setSelectedTabId] = useState<ITab['id']>(
		[...tabsList].shift()!.id
	)

	return (
		<div className={cl.container}>
			<div className={cl.tabsToggles}>
				<Tabs
					value={selectedTabId}
					tabs={tabsList}
					onChange={setSelectedTabId}
				/>
			</div>
			<div className={cl.tabsContent}>
				{selectedTabId === tabsList[0].id && (
					<ProcessCoursesTab courses={activeCourses} />
				)}
				{selectedTabId === tabsList[1].id && (
					<CompletedCoursesTab courses={activeCourses} />
				)}
			</div>
		</div>
	)
}

export default StudentTabs
