import React, { forwardRef, RefObject } from 'react'
import EventsPopupComponents from 'components/Timetable/Tabs/WeekTab/EventsPopup'
import { IEvent } from 'types/models/event.model'
import { useOnClickOutside } from 'hooks'

export interface EventsPopupContainerProps {
	activeEvents: IEvent[]
	eventsDate: Date
	open: boolean
	onHideRestOfEvents: () => void
	onDetailsOpen: (_: IEvent) => void
}

const EventsPopup = forwardRef<HTMLDivElement, EventsPopupContainerProps>(
	(
		{ activeEvents, eventsDate, open, onHideRestOfEvents, onDetailsOpen },
		ref
	) => {
		useOnClickOutside(ref as RefObject<HTMLDivElement>, onHideRestOfEvents)

		if (!open) return null

		return (
			<EventsPopupComponents
				activeEvents={activeEvents}
				eventsDate={eventsDate}
				onHideRestOfEvents={onHideRestOfEvents}
				onDetailsOpen={onDetailsOpen}
				ref={ref}
			/>
		)
	}
)

export default EventsPopup
