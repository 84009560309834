import getTheEndOfTheWordByNumber from './getTheEndOfTheWordByNumber'

const makeTariffValidityString = (t: number) => {
	const periodIsYear = t % 12 === 0
	const tariffMonths: [string, string, string] = ['месяц', 'месяца', 'месяцев']
	const tariffYears: [string, string, string] = ['год', 'года', 'лет']

	const period = periodIsYear ? t / 12 : t
	const postfix = periodIsYear
		? getTheEndOfTheWordByNumber(period, tariffYears)
		: getTheEndOfTheWordByNumber(period, tariffMonths)

	const text = `${period} ${postfix}`

	return text
}

export default makeTariffValidityString
