import React, { FC, useEffect } from 'react'
import { ITaskAnswerResponse } from 'types/models/task.model'

import AttemptItemComponent from 'components/Courses/Entities/Tasks/Attempts/item'
import userQuery from 'store/queries/user.query'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { ResponseEntityId } from 'types'

interface AttemptItemProps {
	answer: ITaskAnswerResponse
	countAttempts: ResponseEntityId
	passingScore: number
	onChangeEditMode?: (_: boolean) => void
	answerEditMode?: boolean
	viewMode?: boolean
}

const AttemptItem: FC<AttemptItemProps> = ({
	answer,
	countAttempts,
	passingScore,
	onChangeEditMode,
	answerEditMode,
	viewMode
}) => {
	const [getAnswerCreatorTrigger, { data: answerCreatorInfo, isSuccess }] =
		userQuery.endpoints.getUserById.useLazyQuery()

	useEffect(() => {
		if (!answer) {
			return
		}
		getAnswerCreatorTrigger(answer.creatorId)
	}, [answer])

	if (!answerCreatorInfo || !isSuccess) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<AttemptItemComponent
			passingScore={passingScore}
			countAttempts={countAttempts}
			answer={answer}
			answerCreatorInfo={answerCreatorInfo.data}
			onChangeEditMode={onChangeEditMode}
			answerEditMode={answerEditMode}
			viewMode={viewMode}
		/>
	)
}

export default AttemptItem
