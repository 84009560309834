import { IRate } from 'types/models/rate.model'

export enum SelectsPaths {
	BASE_URL = 'selects',
	GET_TARIFFS = 'rates',
	GET_TARIFF_PERIODS = 'tariff-periods',
	GET_CITY_OPTIONS = 'cities',
	GET_LEARNING_FORMATS = 'learningFormats'
}

export interface GetTariffsResponse {
	data: IRate[]
}

export interface GetTariffPeriodsResponse {
	data: number[]
}

export interface GetCityOptionsResponse {
	data: SelectItem[]
}

export interface GetLearningFormatsResponse {
	data: SelectItem[]
}

interface SelectItem {
	id: number
	text: string
	value: string
}
