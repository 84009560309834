import React, { FC, useMemo } from 'react'
import TableHeaderComponent from 'components/Timetable/Tabs/ScheduleTab/ScheduleTable/TableHeader'
import { getMonthNameByItsNumber, getNameDayOfTheWeek } from 'helpers/time'

export interface TableHeaderContainerProps {
	date: Date
}

const TableHeader: FC<TableHeaderContainerProps> = ({ date }) => {
	const dayOfTheWeek = useMemo(() => getNameDayOfTheWeek(date.getDay()), [date])
	const day = useMemo(() => date.getDate(), [date])
	const monthName = useMemo(
		() => getMonthNameByItsNumber(date.getMonth()),
		[date]
	)
	const year = useMemo(() => date.getFullYear(), [date])

	return (
		<TableHeaderComponent
			dayOfTheWeek={dayOfTheWeek}
			day={day}
			monthName={monthName}
			year={year}
		/>
	)
}

export default TableHeader
