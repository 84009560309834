import {
	CreateReviewFormContainerProps,
	GetCertificateFormValues
} from 'containers/Courses/Entities/Certificate/Get/CreateReviewForm'
import React from 'react'
import { Control, FieldErrors, FieldPath } from 'react-hook-form'
import { Button, EvaluationButtonGroup, TextArea } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import Delimiter from 'UI/Delimiter'
import ValidateNotify from 'UI/Input/ValidateNotify'
import { ReviewResponsibilityStatus } from 'types/models/course.model'

import cl from './style.module.scss'

interface CreateReviewFormComponentProps
	extends Omit<CreateReviewFormContainerProps, 'courseId'> {
	control: Control<GetCertificateFormValues>
	errors: FieldErrors<GetCertificateFormValues>
	onSubmit: () => void
	onChange: (name: FieldPath<GetCertificateFormValues>) => (value: any) => void
	onSkipFeedback: () => void
	disableSubmitButton: boolean
	reviewRequired?: ReviewResponsibilityStatus
}

const CreateReviewForm: React.FC<CreateReviewFormComponentProps> = ({
	control,
	errors,
	onChange,
	onSubmit,
	onSkipFeedback,
	reviewRequired,
	disableSubmitButton
}) => (
	<>
		<h4 className={cl.title}>Оценка</h4>
		<p className={cl.text}>На сколько вам понравился курс?</p>

		<EvaluationButtonGroup<GetCertificateFormValues>
			from={1}
			to={5}
			control={control}
			onChange={onChange('evaluation')}
			onSubmit={onSubmit}
			name="evaluation"
			fromText="Не понравился"
			toText="Сильно понравился"
		/>

		<h4 className={cl.title}>Отзыв</h4>
		<TextArea
			placeholder="Оставьте свой отзыв здесь"
			name="text"
			onChange={onChange('text')}
			control={control}
			className={cl.textarea}
		/>
		<ValidateNotify error={errors.text?.message} />

		<Delimiter />

		<div className={cl.buttons}>
			{reviewRequired === ReviewResponsibilityStatus.OPTIONAL && (
				<Button styleTypes={[ButtonStyles.TERTIARY]} onClick={onSkipFeedback}>
					Пропустить отзыв
				</Button>
			)}
			<Button styleTypes={[ButtonStyles.PRIMARY]} onClick={onSubmit} disabled={disableSubmitButton}>
				Отправить отзыв
			</Button>
		</div>
	</>
)

export default CreateReviewForm
