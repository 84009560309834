import React, { FC } from 'react'
import { QuestionListCheckProps as QuestionListCheckContainerProps } from 'containers/Courses/Entities/Tests/Check'
import AttemptsTable from 'containers/Courses/Entities/Tests/Check/AttemptsTable'

import cl from './style.module.scss'

interface QuestionListCheckProps extends QuestionListCheckContainerProps {}

const QuestionsTestCheck: FC<QuestionListCheckProps> = ({
	testName,
	courseName,
	userAnswersIdList,
	testQuestionsCount,
	courseId,
	testId
}) => (
	<div className={cl.container}>
		<AttemptsTable
			testId={testId}
			courseId={courseId}
			userAnswersIdList={userAnswersIdList}
			testQuestionsCount={testQuestionsCount}
			testName={testName}
			courseName={courseName}
		/>
	</div>
)

export default QuestionsTestCheck
