import React, { useCallback, useState, useEffect, useRef } from 'react'
import { FieldPath, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Button, Link, Loader } from 'UI'
import showIconUrl from 'UI/Button/images/show.svg'
import { LinkStyles } from 'UI/Link/types'
import TestStatus from 'containers/Courses/Entities/Tests/TestStatus'
import { ButtonStyles } from 'UI/Button/types'
import TestsTabs from 'containers/Courses/Entities/Tests/Tabs/Edit/Wrapper.container'
import { ITest } from 'types/models/test.model'
import TestPreview from 'containers/Courses/Entities/Tests/Preview'
import testQuery from 'store/queries/test.query'
import CancelChangeModal from 'pages/Course/modals/CancelChangeModal'
import { LoaderStyles } from 'UI/Loader/types'

import cl from '../style.module.scss'

export interface TestFormState extends ITest { }

const TestEditPage = () => {
	const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)
	const [testChangesSaved, setTestChangesSaved] = useState(false)
	const { courseId, testId } = useParams()
	const [isTestPreview, setIsTestPreview] = useState(false)
	const [isQuestionsListPreview, setIsQuestionsListPreview] = useState(false)
	const [showRistrictionModal, setShowRistrictionModal] = useState(false)
	const [isAllLimitationsFilledFirstRender, setIsAllLimitationsFilledFirstRender] = useState(false)

	const {
		register,
		control,
		formState,
		setValue,
		handleSubmit,
		watch,
		getValues
	} = useForm<TestFormState>()

	const changeHandler = useCallback(
		(name: FieldPath<TestFormState>) => (value: any) => {
			setValue(name, value)
		},
		[]
	)

	const isLimitationValuesFilled = () =>
		Boolean(getValues().datePassEndSettings &&
			getValues().datePassEndSettings.isActive &&
			getValues().datePassEndSettings &&
			getValues().datePassStartSettings.isActive &&
			getValues().passingScore &&
			getValues().passingTimeLimit.isActive &&
			getValues().testResponsibilityAnswersStatus === 2 &&
			getValues().testResponsibilityStatus === 2)


	const { data: testInfo } = testQuery.useGetTestQuery({ testId: +`${testId}` })

	useEffect(() => {
		setIsAllLimitationsFilledFirstRender(isLimitationValuesFilled())
	}, [testInfo])

	useEffect(() => {
		const showConfirmModal = () => {
			const allLimitationsFilled = isLimitationValuesFilled()

			if (isAllLimitationsFilledFirstRender && allLimitationsFilled && !testChangesSaved) {
				return false
			}

			if (allLimitationsFilled && testChangesSaved) {
				return false
			}

			return true
		}
		setShowRistrictionModal(showConfirmModal())
	}, [isAllLimitationsFilledFirstRender])


	if (!testId) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	if (!testInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			<CancelChangeModal
				isOpen={cancelModalIsOpen}
				onClose={() => setCancelModalIsOpen(false)}
				redirectUri={`/course/edit/${courseId}`}
			/>
			<div className={cl.container}>
				<div className={cl.back}>
					<Link
						styleTypes={[LinkStyles.GO_BACK]}
						href={`/course/edit/${courseId}`}
					>
						Вернуться назад к курсу
					</Link>
				</div>
				<div className={cl.header}>
					<div className={cl.headerLessonInfo}>
						<h2>{testInfo.data.name}</h2>
						<TestStatus
							testId={+testId!}
							isActive={testInfo.data.isActive}
							restrictionsFilled={
								// testInfo.data.countAttempts > 0 &&
								// testInfo.data.passingScore > 0
								showRistrictionModal
							}
						/>
					</div>
					<Button
						onClick={() => {
							setIsTestPreview((prev) => !prev)
							setIsQuestionsListPreview(false)
						}}
						styleTypes={[ButtonStyles.ROUND, ButtonStyles.OUTLINE_DARK]}
					>
						{isTestPreview ? (
							'Вернуться к редактированию'
						) : (
							<>
								<img src={showIconUrl} alt="show" />
								Предпросмотр
							</>
						)}
					</Button>
				</div>
				<TestPreview
					control={control}
					isQuestionsListPreview={isQuestionsListPreview}
					setIsQuestionsListPreview={setIsQuestionsListPreview}
					isTestPreview={isTestPreview}
					getValues={getValues}
					onChange={changeHandler}
				/>
				<TestsTabs
					onOpenCanceledChange={() => setCancelModalIsOpen(true)}
					testInfo={testInfo.data}
					isTestPreview={isTestPreview}
					isQuestionsListPreview={isQuestionsListPreview}
					watch={watch}
					getValues={getValues}
					errors={formState.errors}
					control={control}
					onChange={changeHandler}
					register={register}
					handleSubmit={handleSubmit}
					setValue={setValue}
					setTestChangesSaved={setTestChangesSaved}
				/>
			</div>
		</>
	)
}

export default TestEditPage
