import React, {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import classnames from 'classnames'

import { getUniqueId } from 'helpers'
import {
	clearFileInput,
	getFileList,
	validateFiles,
	filterFileListInfoByFileName
} from 'helpers/files'
import ValidateNotify from 'UI/Input/ValidateNotify'
import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { capitalize } from 'lodash'
import { LessonFile } from 'types/models/lesson.model'
import { AcceptTypes, DragAndDropMultipleProps, SizeTypes } from '../types'

import attachIcon from '../images/attach.svg'
import deleteIcon from '../images/delete.svg'
import cl from '../style.module.scss'

const getFileSizeDescription = (size: [number, SizeTypes]) => {
	const [sizeNumber, sizeType] = size
	const parsedSizeType = capitalize(
		Object.entries(SizeTypes)
			.find(([, value]) => sizeType === value)
			?.shift() as string
	)

	return `${sizeNumber} ${parsedSizeType}`
}

const getFileAcceptDescription = (accept: string) =>
	Object.entries(AcceptTypes)
		.filter(([, value]) => accept.includes(value))
		.map(([fileType]) => fileType.toLowerCase())
		.join(', ')

const DragAndDropMultiple: FC<DragAndDropMultipleProps> = ({
	name,
	files,
	isDrag,
	accept,
	onChange,
	size,
	id,
	previewUrl,
	onDelete,
	imagesPreview,
	recommendedImageWidth,
	recommendedImageHeight,
	singleFile,
	onlyPreviews,
	className,
	...defaultProps
}) => {
	const [removeTitleArray, setRemoveTitleArray] = useState<string[]>([])
	const [filesPreview, setFilesPreview] = useState<LessonFile[]>([])
	const uniqueId = useMemo(getUniqueId.bind(null, id), [])
	const [sizeError, setSizeError] = useState(false)
	const [fileTypeError, setFileTypeError] = useState(false)
	console.log(onlyPreviews)
	useEffect(() => {
		if (previewUrl && !filesPreview.length && !singleFile) {
			setFilesPreview(previewUrl)
		}

		if (previewUrl && singleFile) {
			setFilesPreview(previewUrl)
		}
	}, [previewUrl])

	const fileInput = useRef<HTMLInputElement>(null)

	const changeHandler = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			if (!event.target.files?.length) {
				return
			}

			const { typesIsValid, sizeIsValid } = validateFiles({
				files: [...event.target.files],
				accept,
				size
			})

			setFileTypeError(!typesIsValid)
			setSizeError(!sizeIsValid)

			if (!typesIsValid || !sizeIsValid) {
				clearFileInput(event.target)
				onChange([])
				return
			}

			const fileList = await getFileList([...event.target.files])
			if (!fileList.length) {
				clearFileInput(event.target)
				onChange([])
				return
			}

			const previewsFromFile = [...fileList].map(file => ({
				title: file.name,
				// @ts-ignore
				url: file.url
			}))
			const dublicateFile = previewsFromFile.map(file => filesPreview.findIndex(preview => preview.title === file.title))
			if (singleFile) {
				event.target.files = fileList
			} else
				// {
				// @ts-ignore
				if (dublicateFile[0] !== -1) {
					event.target.files = fileList
				}
			// }

			if (!singleFile) {
				const uniqPreview = [...filesPreview]

				previewsFromFile.map(file => {
					const dublicate = filesPreview.findIndex(preview => preview.title === file.title)
					if (dublicate === -1) {
						uniqPreview.push(file)
					}
				})

				setFilesPreview(uniqPreview)
			}

			if (files) {
				// eslint-disable-next-line
				singleFile ? onChange([...fileList]) : onChange([...files, ...fileList])
				return
			}

			if (!singleFile && dublicateFile[0] === -1) {
				onChange([...fileList])
				return
			}

			if (singleFile) {
				onChange([...fileList])
			}
		},
		[previewUrl, files, filesPreview]
	)

	const deleteHandler = useCallback(
		async (fileName: string) => {
			if (!fileInput.current || !filesPreview?.length) {
				return
			}
			const binaryFiles = filesPreview.filter(file => !file.url)
			const filesFromServer = filesPreview.filter(file => file.url)
			const filteredFileFromServer = filesFromServer.filter(file => file.title !== fileName)

			const filteredBinaryFileList = await filterFileListInfoByFileName(
				binaryFiles,
				fileName,
				false
			)

			fileInput.current.files = filteredBinaryFileList
			const fileTitles = [...filteredBinaryFileList].map(({ name: fileTitle }) => ({ title: fileTitle }))

			setFilesPreview([...filteredFileFromServer, ...fileTitles])
			onChange([...filteredBinaryFileList], fileName)

			setRemoveTitleArray((prev) => {
				const newTitleList = [...prev, fileName]
				return [...new Set(newTitleList)]
			})
		},
		[files, filesPreview]
	)

	useEffect(() => {
		onDelete(removeTitleArray)
	}, [removeTitleArray])

	const deleteImageHandler = useCallback(
		async (deleteBy: string | File) => {
			if (!imagesPreview || !fileInput.current) return

			if (typeof deleteBy === 'string') {
				onDelete([deleteBy])

				return
			}
			const nextFiles = files.filter((f) => f !== deleteBy)

			onChange(nextFiles)
			fileInput.current.files = await getFileList([...nextFiles])
		},
		[imagesPreview, fileInput, files]
	)
	return (
		<>
			<div
				{...defaultProps}
				className={classnames([
					cl.fileDragMultiple,
					className,
					{
						[cl.fileDragMultipleDragover]: isDrag
					}
				])}
			>
				<span>Перетащите файлы или выберите на компьютере</span>
				<Button styleTypes={[ButtonStyles.TERTIARY]}>
					<img className={cl.multiIcon} src={attachIcon} alt="selectFileIcon" />
					Выбрать файл
				</Button>
				<input
					name={name}
					ref={fileInput}
					accept={accept}
					type="file"
					onChange={changeHandler}
					id={uniqueId}
					multiple={!singleFile}
				/>
				{accept && size && (
					<div>
						{accept && <p>Формат: {getFileAcceptDescription(accept)}.</p>}
						{size && (
							<p>Максимальный размер файла: {getFileSizeDescription(size)}.</p>
						)}
					</div>
				)}

				{(recommendedImageWidth || recommendedImageHeight) && (
					<div>
						<p>
							Рекомендуемое разрешение - {recommendedImageWidth}{' '}
							{recommendedImageHeight && `x ${recommendedImageHeight}`}
						</p>
					</div>
				)}
			</div>
			{!onlyPreviews && filesPreview && filesPreview.length > 0 && (
				<div className={cl.fileDragMultiplePreview}>
					{filesPreview.map(({ title }) => (
						<div className={cl.uploadFile} key={title}>
							<span>{title}</span>
							<Button onClick={() => deleteHandler(title)}>
								<img src={deleteIcon} alt="delete" />
							</Button>
						</div>
					))}
				</div>
			)}

			{imagesPreview && imagesPreview.length > 0 && (
				<div className={cl.fileDragMultiplePreview}>
					{imagesPreview.map((preview) => (
						<div className={cl.uploadFile} key={preview.url}>
							<img src={preview.url} className={cl.imagePreview} alt="" />
							<span className={cl.uploadFileName}>{preview.file?.name}</span>
							<Button
								onClick={() => deleteImageHandler(preview.file || preview.url)}
							>
								<img src={deleteIcon} alt="delete" />
							</Button>
						</div>
					))}
				</div>
			)}

			{sizeError && <ValidateNotify error='Размер файла превышает допустимый предел' />}
			{fileTypeError && <ValidateNotify error='Формат файла не соответствует допустимому' />}
		</>
	)
}

export default DragAndDropMultiple
