import React, { useCallback, useState } from 'react'
import { FieldPath, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Button, Dropdown, Link, Loader } from 'UI'
import { LinkStyles } from 'UI/Link/types'
import { ButtonSizes, ButtonStyles } from 'UI/Button/types'
import CoursesTabs from 'containers/Courses/Tabs/Edit/Wrapper.container'
import {
	ICourse,
	ICourseWithCertificateImages,
	StatusType
} from 'types/models/course.model'

import courseQuery from 'store/queries/course.query'
import { LoaderStyles } from 'UI/Loader/types'

import { DropdownItem } from 'UI/Dropdown/types'
import { getUniqueId } from 'helpers'
import {
	ArchivedIcon,
	CopyIcon,
	PublishedIcon,
	TrashedIcon,
	WatchIcon
} from 'containers/Courses/Accordion/icons'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { ResponseEntityId, ResponseWithError } from 'types'
import { MarketplaceSettings } from 'types/models/course.model/settings/marketplace.model'
import { schoolQuery } from 'store/queries'
import ConfirmEditCourseModal from 'containers/Courses/Table/Modals/ConfirmEditCourse'
import ConfirmPublishModal from 'containers/Courses/Table/Modals/ConfirmPublish'
import ConfirmUnPublishModal from 'containers/Courses/Table/Modals/ConfirmUnPublish'
import PublishModal from 'containers/Courses/Table/Modals/Publish'
import UnPublishModal from 'containers/Courses/Table/Modals/UnPublish'
import ArchiveModal from 'containers/Courses/Table/Modals/Archive'
import CancelChangeModal from 'pages/Course/modals/CancelChangeModal'
import { parseStringToTagA } from 'helpers/parseStringToTagA'

import cl from './style.module.scss'


export interface CourseFormState extends ICourseWithCertificateImages { }

const CourseCreatePage = () => {
	const dispatch = useAppDispatch()
	const [setCourseUnPublish] = courseQuery.useCoursesUnPublishMutation()

	const [courseTitle, setCourseTitle] = useState('')

	const [setCourseArchive] = courseQuery.useCoursesArchiveMutation()
	const [copyCourse] = courseQuery.useCopyCourseMutation()
	const { currentRole } = useAppSelector((state) => state.system)
	const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)
	const [isPublishConfirmModalOpen, setIsPublishConfirmModalOpen] =
		useState(false)
	const [isUnPublishConfirmModalOpen, setIsUnPublishConfirmModalOpen] =
		useState(false)
	const [isConfirmEditModalOpen, setIsConfirmEditModalOpen] = useState(false)
	const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
	const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
	const [isUnPublishModalOpen, setIsUnPublishModalOpen] = useState(false)
	const [, setCurrentCourseDateStart] = useState(new Date())

	const coursePublish = (
		id: ResponseEntityId,
		name: string,
		marketplaceSettings: MarketplaceSettings
	) => {
		setCurrentCourseDateStart(marketplaceSettings.dateLearningSettings.start)
		setCourseTitle(name)
		setIsPublishConfirmModalOpen(true)
	}
	const courseUnPublish = async (id: ResponseEntityId, name: string) => {
		setCourseTitle(name)
		setIsUnPublishConfirmModalOpen(true)
	}
	const courseTrashedQuery = async (id: ResponseEntityId, name: string) => {
		const response = await setCourseUnPublish(id)
		const { error } = response as unknown as ResponseWithError
		if (error) {
			return
		}
		setCourseTitle(name)
		setIsUnPublishModalOpen(true)
	}
	const courseArchiveQuery = async (id: ResponseEntityId, name: string) => {
		const response = await setCourseArchive(id)
		const { error } = response as unknown as ResponseWithError
		if (error) {
			return
		}
		setCourseTitle(name)
		setIsArchiveModalOpen(true)
	}
	const courseCopyQuery = async (id: ResponseEntityId, name: string) => {
		const response = await copyCourse({ courseId: id, role: currentRole })
		const { error } = response as unknown as ResponseWithError
		if (error) {
			return
		}

		dispatch(schoolQuery.util.invalidateTags(['school']))
		setCourseTitle(name)
		setIsUnPublishModalOpen(true)
	}

	const getDropdownItemsList = useCallback(
		({
			statusType,
			id,
			name,
			marketplaceSettings
		}: Pick<
			ICourse,
			'statusType' | 'id' | 'name' | 'marketplaceSettings'
		>): DropdownItem[] => {
			switch (statusType) {
				case StatusType.PUBLISHED:
					return [
						{
							id: getUniqueId(),
							icon: WatchIcon,
							label: 'Просмотреть материалы',
							link: `/course/view/${id}`
						},
						{
							id: getUniqueId(),
							icon: CopyIcon,
							label: 'Копировать',
							onClick: () => courseCopyQuery(id, name)
						},
						{
							id: getUniqueId(),
							icon: TrashedIcon,
							label: 'Снять с публикации',
							onClick: () => courseUnPublish(id, name)
						},
						{
							id: getUniqueId(),
							icon: ArchivedIcon,
							label: 'Архивировать',
							onClick: () => courseArchiveQuery(id, name)
						}
					]
				case StatusType.TRASHED:
					return [
						{
							id: getUniqueId(),
							icon: CopyIcon,
							label: 'Копировать',
							onClick: () => courseCopyQuery(id, name)
						},
						{
							id: getUniqueId(),
							icon: PublishedIcon,
							label: 'Опубликовать',
							onClick: () => coursePublish(id, name, marketplaceSettings)
						},
						{
							id: getUniqueId(),
							icon: ArchivedIcon,
							label: 'Архивировать',
							onClick: () => courseArchiveQuery(id, name)
						}
					]
				case StatusType.ARCHIVED:
					return [
						{
							id: getUniqueId(),
							icon: TrashedIcon,
							label: 'Восстановить в черновики',
							onClick: () => courseTrashedQuery(id, name)
						}
					]
				default:
					return []
			}
		},
		[]
	)

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		watch,
		formState,
		control
	} = useForm<CourseFormState>()

	const changeHandler = useCallback(
		(name: FieldPath<CourseFormState>) => (value: any) => setValue(name, value),
		[]
	)
	const { courseId } = useParams()

	if (!courseId) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	const { data: courseInfo } = courseQuery.useGetCourseQuery(+courseId)

	if (!courseInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			<CancelChangeModal
				isOpen={cancelModalIsOpen}
				onClose={() => setCancelModalIsOpen(false)}
			/>
			<ConfirmEditCourseModal
				onClose={() => setIsConfirmEditModalOpen(false)}
				courseId={+courseId}
				isOpen={isConfirmEditModalOpen}
			/>
			<ConfirmPublishModal
				onClose={() => setIsPublishConfirmModalOpen(false)}
				setIsPublishModalOpen={setIsPublishModalOpen}
				isOpen={isPublishConfirmModalOpen}
				courseTitle={courseTitle}
				courseId={+courseId}
			/>
			<ConfirmUnPublishModal
				onClose={() => setIsUnPublishConfirmModalOpen(false)}
				setIsUnPublishModalOpen={setIsUnPublishModalOpen}
				isOpen={isUnPublishConfirmModalOpen}
				courseId={+courseId}
			/>
			<PublishModal
				onClose={() => setIsPublishModalOpen(false)}
				isOpen={isPublishModalOpen}
				courseTitle={courseTitle}
			/>
			<UnPublishModal
				onClose={() => setIsUnPublishModalOpen(false)}
				courseTitle={courseTitle}
				isOpen={isUnPublishModalOpen}
			/>
			<ArchiveModal
				onClose={() => setIsArchiveModalOpen(false)}
				isOpen={isArchiveModalOpen}
				courseTitle={courseTitle}
			/>
			<div className={cl.container}>
				<div className={cl.back}>
					<Link styleTypes={[LinkStyles.GO_BACK]} href="/">
						Вернуться назад
					</Link>
				</div>
				<div className={cl.header}>
					<div className={cl.headerInfo}>
						<h2>{courseInfo.data.name}</h2>
						{courseInfo.data.description.split('\n').map((text) => (
							<p key={text}>
								{parseStringToTagA(text)}
							</p>
						))}
					</div>
					<Dropdown
						dropdownItemsList={getDropdownItemsList({
							name: courseInfo.data.name,
							marketplaceSettings: courseInfo.data.marketplaceSettings,
							id: courseInfo.data.id,
							statusType: courseInfo.data.statusType
						})}
					>
						<Button
							styleTypes={[
								ButtonSizes.BIG,
								ButtonStyles.ROUND,
								ButtonStyles.DARK
							]}
						>
							Выбрать действие
						</Button>
					</Dropdown>
				</div>
				<CoursesTabs
					onOpenCanceledChange={() => setCancelModalIsOpen(true)}
					courseInfo={courseInfo.data}
					setValue={setValue}
					errors={formState.errors}
					onChange={changeHandler}
					control={control}
					register={register}
					watch={watch}
					handleSubmit={handleSubmit}
					getValues={getValues}
				/>
			</div>
		</>
	)
}

export default CourseCreatePage
