import React, { useEffect, useRef } from 'react'

import { useActions, useAppSelector } from 'hooks/redux'
import { Alert } from 'UI'
import { AlertType } from 'UI/Alert/types'
import { ErrorElement, SuccessElement } from 'store/slices/system/types'

const AlertWrapper = () => {
	const { errorElementsList, successElementsList } = useAppSelector(
		(state) => state.system
	)
	const oldErrorElementsList = useRef<ErrorElement[]>([])
	const oldSuccessElementsList = useRef<SuccessElement[]>([])
	const { clearError } = useActions((state) => state.system)
	const { clearSuccess } = useActions((state) => state.system)

	useEffect(() => {
		const newError = errorElementsList.find(
			(errorElement) =>
				!oldErrorElementsList.current.some(
					(oldErrorElement) => oldErrorElement.id === errorElement.id
				)
		)
		if (!newError) {
			return
		}
		setTimeout(() => {
			clearError(newError.id)
		}, 2000)
		oldErrorElementsList.current = errorElementsList
	}, [errorElementsList])
	useEffect(() => {
		const newSuccess = successElementsList.find(
			(errorElement) =>
				!oldErrorElementsList.current.some(
					(oldErrorElement) => oldErrorElement.id === errorElement.id
				)
		)
		if (!newSuccess) {
			return
		}
		setTimeout(() => {
			clearSuccess(newSuccess.id)
		}, 2000)
		oldSuccessElementsList.current = successElementsList
	}, [successElementsList])

	return (
		<>
			{errorElementsList.map((error) => (
				<Alert
					key={error.id}
					message={error.message}
					type={AlertType.ERROR}
					styleTypes={[AlertType.ERROR]}
				/>
			))}
			{successElementsList.map((success) => (
				<Alert
					key={success.id}
					message={success.message}
					type={AlertType.SUCCESS}
					styleTypes={[AlertType.SUCCESS]}
				/>
			))}
		</>
	)
}

export default AlertWrapper
