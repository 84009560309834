import React, { FC } from 'react'
import { Modal } from 'UI'
import { ModalSize } from 'UI/Modal/types'

import { useAppSelector } from 'hooks/redux'
import schoolQuery from 'store/queries/school.query'
import cl from './style.module.scss'

interface SchoolLogoModalProps {
	isOpen: boolean
	onClose: () => void
}

const SchoolLogoModal: FC<SchoolLogoModalProps> = ({ isOpen, onClose }) => {
	const { currentSchoolId } = useAppSelector((state) => state.system)
	const { data: schoolInfo } = schoolQuery.useGetSchoolByIdQuery({
		schoolId: Number(currentSchoolId)
	})

	if (!schoolInfo) {
		return <></>
	}

	return (
		<Modal
			title="Логотип школы"
			isOpen={isOpen}
			onClose={onClose}
			styleTypes={[ModalSize.SMALL]}
		>
			<div className={cl.container}>
				{schoolInfo.data.avatarUrl ? (
					<img src={schoolInfo.data.avatarUrl} alt="logo-modal" />
				) : (
					'Логотип не загружен'
				)}
			</div>
		</Modal>
	)
}

export default SchoolLogoModal
