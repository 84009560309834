import React from 'react'
import { TariffCardContainerProps } from 'containers/Tariffs/TariffsPlans/TariffCard'
import classNames from 'classnames'

import { Button } from 'UI'
import { ButtonSizes, ButtonStyles } from 'UI/Button/types'

import cl from './style.module.scss'

interface TariffCardComponentProps
	extends Pick<TariffCardContainerProps, 'text' | 'isActive' | 'id'> {
	onModalOpen: () => void
	name: string
	fileSize: string
	price: number
	validityPeriodText: string
	numberUsers: number
	numberCourses: number
	onTariffRenewal: () => void
	previousPrice: number
	discountPercentage: number
}

const TariffCard: React.FC<TariffCardComponentProps> = ({
	id,
	discountPercentage,
	previousPrice,
	text,
	isActive,
	price,
	fileSize,
	validityPeriodText,
	name,
	numberCourses,
	numberUsers,
	onModalOpen,
	onTariffRenewal
}) => (
	<div className={cl.card}>
		<div>
			<h4 className={cl.title}>{name}</h4>

			<p className={cl.text}>{text}</p>

		</div>
		<div>
			<div className={cl.priceBlock}>
				{previousPrice && (
					<span className={cl.prevPrice}>{previousPrice} руб</span>
				)}
				<span className={cl.price}>
					{price === 0 ? 'Бесплатно' : `${price} руб`}
				</span>
				{discountPercentage && (
					<span className={cl.discount}>-{discountPercentage}%</span>
				)}
			</div>

			<p className={cl.validity}>{validityPeriodText}</p>
			<p className={classNames(cl.validity, cl.fileSize)}>{fileSize} Гб</p>
			{isActive ? (
				<Button
					styleTypes={[ButtonStyles.OUTLINE_PRIMARY, ButtonSizes.WIDE]}
					onClick={onTariffRenewal}
				>
					Продлить
				</Button>
			) : (
				<Button
					styleTypes={[ButtonStyles.PRIMARY, ButtonSizes.WIDE]}
					onClick={onModalOpen}
				>
					Подключить
				</Button>
			)}
			{/* ////////FIX */}
			<div className={cl.includes}>
				<p className={cl.includesTitle}>Входит в тариф</p>
				<p>
					<span>{numberUsers}</span> пользователей
				</p>
				{/* {numberCourses && (
				<p>
					<span>{numberCourses}</span> размещений курсов
				</p>
			)} */}
			</div>
		</div>
	</div>
)

export default TariffCard
