import React, { FC, useCallback, useState } from 'react'
import { Control, useFieldArray } from 'react-hook-form'
import AnswersListComponent from 'components/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/AnswersList'
import { getUniqueId } from 'helpers'
import DeleteAnswerModal from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/AnswersList/modals/DeleteAnswer'
import { ResponseEntityId } from 'types'
import { CreateQuestionFormProps } from '../CreateQuestionForm'

export interface AnswersListProps
	extends Pick<
		CreateQuestionFormProps,
		'errors' | 'onChange' | 'onSubmit' | 'getValues'
	> {
	control: Control<any>
	name: `${CreateQuestionFormProps['name']}`
}

const AnswersList: FC<AnswersListProps> = ({
	errors,
	onChange,
	control,
	onSubmit,
	name,
	getValues
}) => {
	const [deleteAnswerModalIsOpen, setDeleteAnswerModalIsOpen] = useState(false)
	const [currentAnswerId, setCurrentAnswerId] = useState<ResponseEntityId>()

	const { fields, remove, append } = useFieldArray({
		control,
		name: `${name}.answers`
	})

	// TODO ID
	const appendHandler = useCallback(() => {
		append({ id: +getUniqueId(), value: '', comment: '' })
	}, [])

	const openDeleteAnswerModalHandler = (answerId: ResponseEntityId) => {
		setCurrentAnswerId(answerId)
		setDeleteAnswerModalIsOpen(true)
	}

	return (
		<>
			<DeleteAnswerModal
				answerId={currentAnswerId || 0}
				isOpen={deleteAnswerModalIsOpen}
				remove={remove}
				onClose={() => setDeleteAnswerModalIsOpen(false)}
			/>
			<AnswersListComponent
				getValues={getValues}
				onOpenModal={openDeleteAnswerModalHandler}
				name={name}
				errors={errors}
				onChange={onChange}
				onSubmit={onSubmit}
				control={control}
				onAppend={appendHandler}
				fields={fields}
			/>
		</>
	)
}

export default AnswersList
