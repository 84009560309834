import React, { FC, useEffect } from 'react'
import AnswerCreatorComponent from 'components/Courses/Entities/Tests/Check/AttemptsTable/AnswerCreator'
import { userQuery } from 'store/queries'
import { Loader } from 'UI'

interface AnswerCreatorProps {
	creatorId: number
	noEmail?: boolean
	noName?: boolean
	hideAvatarMobile?: boolean
}

const AnswerCreator: FC<AnswerCreatorProps> = ({
	creatorId,
	noEmail,
	noName = false,
	hideAvatarMobile
}) => {
	const [getUserTrigger, { data: creatorInfo }] =
		userQuery.endpoints.getUserById.useLazyQuery()

	useEffect(() => {
		if (!creatorId) {
			return
		}
		getUserTrigger(creatorId)
	}, [creatorId])

	if (!creatorInfo) {
		return <Loader />
	}

	return (
		<AnswerCreatorComponent
			email={creatorInfo.data.email}
			photoUrl={creatorInfo.data.avatarUrl}
			firstName={creatorInfo.data.firstName}
			lastName={creatorInfo.data.lastName}
			noEmail={noEmail}
			noName={noName}
			hideAvatarMobile={hideAvatarMobile}
		/>
	)
}

export default AnswerCreator
