import React, { FC } from 'react'
import { UseFormGetValues } from 'react-hook-form'

import { Video, Audio, Iframe } from 'UI'
import Contacts from 'containers/Courses/Entities/General/Contacts'
import { LessonFormState } from 'pages/Course/Lesson/Create.page'
import { CommunicationsSettings } from 'types/models/course.model/settings/communications.model'
import { LessonMediaFiles, ILesson } from 'types/models/lesson.model'
import { Constants } from 'types'

import cl from './style.module.scss'

interface LessonPreviewProps {
	getValues: UseFormGetValues<LessonFormState>
	communicationInfo: CommunicationsSettings
	lessonInfo?: ILesson
}

const LessonPreview: FC<LessonPreviewProps> = ({
	getValues,
	communicationInfo,
	lessonInfo
}) => (
	<div className={cl.container}>
		{(getValues('name') || getValues('description')) && (
			<div className={cl.header}>
				{getValues('name') && <h1>{getValues('name')}</h1>}
				{getValues('description') &&
					getValues('description')
						.split('\n')
						.map((text) => <p>{text}</p>)}
			</div>
		)}
		{lessonInfo?.videoEmbedLink && <Iframe src={lessonInfo.videoEmbedLink} />}
		{!!getValues('mediaFilesInfo') && (
			<>
				{getValues('mediaFilesInfo')?.map((video) => {
					if (video.type === LessonMediaFiles.VIDEO && video.url) {
						return (
							<div className={cl.videoContainer}>
								<Video key={video.url} videoUrl={video.url} />
							</div>
						)
					}
				})}

				{getValues('mediaFilesInfo')?.map((audio) => {
					if (
						audio.type === LessonMediaFiles.AUDIO &&
						// @ts-ignore
						audio.url.includes('blob')
					) {
						return (
							<div className={cl.videoContainer}>
								<Audio key={audio.url}
									// @ts-ignore
									audioUrl={audio.url} />
							</div>
						)
					}
					if (
						audio.type === LessonMediaFiles.AUDIO &&
						// @ts-ignore
						!audio.url.includes('blob')
					) {
						return (
							<Audio
								key={audio.url}
								audioUrl={`${Constants.BASE_URL}${audio.url}`}
							/>
						)
					}
				})}
			</>
		)}

		{!!getValues('docsFilesInfo')?.length && (
			<div className={cl.docs}>
				<h2 className={cl.highlighted}>Учебные материалы</h2>
				<div className={cl.docsList}>
					{getValues('docsFilesInfo')?.map((documentInfo, i) => (
						<a
							rel="noreferrer"
							className={cl.downloadLink}
							key={documentInfo.title}
							href={`${Constants.BASE_URL}${documentInfo.url}`}
							target="_blank"
						>
							{documentInfo.title || documentInfo.url}
						</a>
					))}
				</div>
			</div>
		)}
		<Contacts communicationsInfo={communicationInfo} />
	</div>
)

export default LessonPreview
