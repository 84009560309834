import React, { FC } from 'react'
import { TimeLineRowContainerProps } from 'containers/Timetable/Tabs/WeekTab/TimeLine/TimeLineRow'
import TableCell from 'containers/Timetable/Tabs/WeekTab/WeekTableCell/TableCell'

interface TimeLineRowComponentProps
	extends Omit<TimeLineRowContainerProps, 'id'> {}

const TimeLineRow: FC<TimeLineRowComponentProps> = ({
	time,
	elements,
	onHoverIndexChange,
	hoverIndex,
	onDetailsOpen
}) => (
	<>
		<span>{time}</span>
		{elements.map((e, index) => (
			<TableCell
				key={e.id}
				event={e.event}
				index={index}
				highlighted={index === hoverIndex}
				onHoverIndexChange={onHoverIndexChange}
				hoverIndex={hoverIndex}
				onDetailsOpen={onDetailsOpen}
			/>
		))}
	</>
)

export default TimeLineRow
