import React, { FC, useCallback } from 'react'
import { courseQuery, eventsQuery } from 'store/queries'
import { useAppDispatch } from 'hooks/redux'
import DeleteEventModalComponent from 'components/Courses/Entities/Events/Edit/DeleteEventModal'
import { useNavigate } from 'react-router-dom'

export interface DeleteEventModalContainerProps {
	isOpen: boolean
	onClose: () => void
	eventId: number
	sectionId: number
	courseId: number
}

const DeleteEventModal: FC<DeleteEventModalContainerProps> = ({
	eventId,
	isOpen,
	onClose,
	sectionId,
	courseId
}) => {
	const navigate = useNavigate()
	const [deleteEvent] = eventsQuery.useDeleteEventMutation()
	const dispatch = useAppDispatch()

	const onSubmit = useCallback(async () => {
		await deleteEvent({
			sectionId,
			eventId
		})
		dispatch(courseQuery.util.invalidateTags(['course']))
		navigate(`/course/edit/${courseId}`)
		onClose()
	}, [deleteEvent, sectionId, eventId, onClose])

	return (
		<DeleteEventModalComponent
			isOpen={isOpen}
			onClose={onClose}
			onSubmit={onSubmit}
		/>
	)
}

export default DeleteEventModal
