import React, { FC, FormEvent } from 'react'
import { Control, FieldErrors, FieldPath, UseFormWatch } from 'react-hook-form'

import { LimitationsFormTabState } from 'containers/Courses/Entities/Tests/Tabs/Content/LimitationsForm'
import TaskTimeLimit from 'containers/Courses/Entities/Tests/Tabs/Content/LimitationsForm/TimeLimit'
import {
	Button,
	Datepicker,
	RadioGroup,
	Switch,
	TextInput,
	Timepicker
} from 'UI'
import ValidateNotify from 'UI/Input/ValidateNotify'
import { ButtonStyles } from 'UI/Button/types'

import cl from './style.module.scss'

export interface LimitationsFormTabProps {
	errors: FieldErrors<LimitationsFormTabState>
	onChange: (name: FieldPath<LimitationsFormTabState>) => (value: any) => void
	onSubmit: (event: FormEvent) => void
	control: Control<LimitationsFormTabState>
	watch: UseFormWatch<LimitationsFormTabState>
	onOpenCanceledChange: () => void
	questionsCount: number
}

const LimitationsForm: FC<LimitationsFormTabProps> = ({
	control,
	onSubmit,
	errors,
	onChange,
	watch,
	onOpenCanceledChange,
	questionsCount
}) => (
	<>
		<div className={cl.container}>
			<div className={cl.header}>
				<span>
					Вы можете установить ограничения на время прохождения тестирования
				</span>
			</div>
			<form onSubmit={onSubmit} noValidate className={cl.form}>
				<div className={cl.inputs}>
					<div className={cl.inputContainer}>
						<label>Тестирование является обязательным?</label>
						<RadioGroup
							itemsList={[
								{ id: '2', value: 2, labelTitle: 'Да' },
								{ id: '1', value: 1, labelTitle: 'Нет' }
							]}
							name="testResponsibilityStatus"
							control={control}
							onChange={onChange('testResponsibilityStatus')}
						/>
						<ValidateNotify
							className={cl.validateNotify}
							error={errors.testResponsibilityStatus?.message}
						/>
					</div>
					<div className={cl.inputContainer}>
						<label>Выбор всех ответов является обязательным?</label>
						<RadioGroup
							itemsList={[
								{ id: '2', value: 2, labelTitle: 'Да' },
								{ id: '1', value: 1, labelTitle: 'Нет' }
							]}
							name="testResponsibilityAnswersStatus"
							control={control}
							onChange={onChange('testResponsibilityAnswersStatus')}
						/>
						<ValidateNotify
							className={cl.validateNotify}
							error={errors.testResponsibilityAnswersStatus?.message}
						/>
					</div>
					<TaskTimeLimit
						errors={errors}
						watch={watch}
						control={control}
						onChange={onChange}
					/>
					<div className={cl.inputContainerSplit}>
						<label htmlFor="passingScore">
							Минимальное количество правильных ответов
						</label>
						<div className={cl.textInputSplitContainer}>
							<TextInput
								error={errors.passingScore?.message}
								placeholder="количество баллов"
								onChange={onChange('passingScore')}
								control={control}
								type="number"
								name="passingScore"
								id="passingScore"
							/>
							{/* <span>из {questionsCount} баллов</span> */}
						</div>
					</div>
					<div className={cl.inputContainerSplit}>
						<label htmlFor="countAttempts">
							Количество попыток для прохождения
						</label>
						<div className={cl.textInputSplitContainerAttempts}>
							<TextInput
								error={errors.countAttempts?.message}
								placeholder="количество попыток"
								onChange={onChange('countAttempts')}
								control={control}
								type="number"
								name="countAttempts"
								id="countAttempts"
							/>
						</div>
					</div>
					<div className={cl.inputContainer}>
						<label>Ограничение по прохождению</label>
						<Switch
							name="datePassStartSettings.isActive"
							onChange={onChange('datePassStartSettings.isActive')}
							control={control}
							labelTitle="Разрешить выполнение теста с"
						/>
						<div className={cl.datepickerContainer}>
							<Datepicker
								control={control}
								name="datePassStartSettings.date"
								disabled={!watch('datePassStartSettings.isActive')}
							/>
							<Timepicker
								control={control}
								name="datePassStartSettings.time"
								disabled={!watch('datePassStartSettings.isActive')}
							/>
						</div>
						<ValidateNotify
							className={cl.validateError}
							error={
								errors.datePassStartSettings?.date?.message ||
								errors.datePassStartSettings?.time?.message
							}
						/>
						<Switch
							name="datePassEndSettings.isActive"
							onChange={onChange('datePassEndSettings.isActive')}
							control={control}
							labelTitle="Последний срок сдачи"
						/>
						<div className={cl.datepickerContainer}>
							<Datepicker
								control={control}
								name="datePassEndSettings.date"
								disabled={!watch('datePassEndSettings.isActive')}
							/>
							<Timepicker
								control={control}
								name="datePassEndSettings.time"
								disabled={!watch('datePassEndSettings.isActive')}
							/>
						</div>
						<ValidateNotify
							className={cl.validateError}
							error={
								errors.datePassEndSettings?.date?.message ||
								errors.datePassEndSettings?.time?.message
							}
						/>
					</div>
					<div className={cl.splitLine} />
				</div>
				<div className={cl.buttons}>
					<Button
						onClick={onOpenCanceledChange}
						styleTypes={[ButtonStyles.OUTLINE_PRIMARY, ButtonStyles.ROUND]}
					>
						Отменить изменения
					</Button>
					<Button
						type="submit"
						styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
					>
						Сохранить изменения
					</Button>
				</div>
			</form>
		</div>
	</>
)

export default LimitationsForm
