import React, { FC } from 'react'

import { AttemptAnswerViewProps as AttemptAnswerViewContainerProps } from 'containers/Courses/Entities/Tests/AttemptAnswerView'

import { Button, Link } from 'UI'
import { LinkStyles } from 'UI/Link/types'
import { ButtonStyles } from 'UI/Button/types'

import { QuestionAnswerFormState } from 'containers/Courses/Entities/Tests/View/QuestionsListPass/AnswersList'
import AnswerCreator from 'containers/Courses/Entities/Tests/Check/AttemptsTable/AnswerCreator'
import { useAppSelector } from 'hooks/redux'
import { AnswerTestReviewStatus, ITest } from 'types/models/test.model'

import classNames from 'classnames'
import QuestionsListView from '../View/QuestionsListView'
import AttemptsTable from '../Check/AttemptsTable'

import cl from './style.module.scss'

interface AttemptAnswerViewProps extends AttemptAnswerViewContainerProps { }

const getStartTimeToPassTestString = (testInfo: ITest) => {
	let str = ''

	if (testInfo.datePassStartSettings.isActive) {
		str += `${new Date(
			testInfo.datePassStartSettings.date
		).toLocaleDateString()} `
		str += testInfo.datePassStartSettings.time
	} else if (testInfo.datePassEndSettings.isActive) {
		str += 'Без ограничений'
	}

	return str
}

const getEndTimeToPassTestString = (testInfo: ITest) => {
	let str = ''

	if (testInfo.datePassEndSettings.isActive) {
		str += `${new Date(
			testInfo.datePassEndSettings.date
		).toLocaleDateString()} `
		str += testInfo.datePassEndSettings.time
	} else if (testInfo.datePassStartSettings.isActive) {
		str += 'Без ограничений'
	}

	return str
}

const AttemptAnswerView: FC<AttemptAnswerViewProps> = ({
	testInfo,
	courseInfo,
	currentAnswers,
	currentCheckingId,
	currentAttempt,
	testAnswersInfo,
	bestResult,
	isPrevAttemptsChecking,
	togglePrevAttemptsChecking,
	setCurrentAttempt,
	setCurrentChecking,
	handleLoadUserAnswers
}) => (
	<div className={cl.container}>
		<div className={cl.back}>
			<Link
				styleTypes={[LinkStyles.GO_BACK]}
				href={`/course/${courseInfo.id}/test/view/${testInfo.id}`}
			>
				Вернуться к списку ответов
			</Link>
		</div>
		<div className={cl.header}>
			<h1>Просмотр ответов на {testInfo.name}</h1>
			<span>Курс {courseInfo.name}</span>
			{/* <div className={cl.buttons}>
					<Button styleTypes={[ButtonStyles.DARK, ButtonStyles.ROUND]}>
						Предыдущий ответ
					</Button>
					<Button styleTypes={[ButtonStyles.DARK, ButtonStyles.ROUND]}>
						Следующий ответ
					</Button>
				</div> */}
		</div>
		<div className={cl.content}>
			<h2>Ответ ученика</h2>

			<div className={cl.gridTable}>
				<div className={cl.cell}>
					<AnswerCreator creatorId={currentAnswers?.creatorId} noEmail />
				</div>
				<div className={cl.cell}>
					<span>Статус</span>
					<span
						className={classNames({
							[cl.success]:
								currentAnswers.answerStatus === AnswerTestReviewStatus.PASS,
							[cl.fail]:
								currentAnswers.answerStatus === AnswerTestReviewStatus.NOT_PASS
						})}
					>
						{currentAnswers.answerStatus}
					</span>
				</div>
				<div className={cl.cell}>
					<span>Попытка</span>
					<span className="">
						<span className={cl.attempt}>{currentAttempt}</span> из{' '}
						<span className={cl.attempt}>{testInfo.countAttempts}</span>
					</span>
				</div>
				<div className={cl.cell}>
					<span>Количество вопросов</span>
					<span className="">{testInfo.questions.length}</span>
				</div>
				<div className={cl.cell}>
					<span>Дата прохождения</span>
					<span className="">—</span>
				</div>
				<div className={cl.cell}>
					<span>Период прохождения</span>
					<span className="">
						<span>{getStartTimeToPassTestString(testInfo)}</span> <span>—</span>{' '}
						<span>{getEndTimeToPassTestString(testInfo)}</span>{' '}
					</span>
				</div>
			</div>

			<QuestionsListView
				test={testInfo}
				userAnswers={testAnswersInfo}
				setIsQuestionsCheckMode={() => { }}
				userCurrentAttemptCheck={currentCheckingId.toString()}
			/>
		</div>

		<div className={classNames(cl.content, cl.secondary)}>
			<h3 className={cl.prevTitle}>Предыдущие попытки</h3>

			{/* <p className={cl.bestResult}>Лучший результат — {}</p> */}

			{isPrevAttemptsChecking && (
				<AttemptsTable
					testAnswersInfo={testAnswersInfo.filter(
						(t) =>
							t.creatorId === currentAnswers.creatorId &&
							t.attempt.toString() !== currentAttempt
					)}
					testId={testInfo.id!}
					testQuestionsCount={testInfo.questions.length}
					courseId={courseInfo.id}
					userAnswersIdList={[]}
					setCurrentChecking={setCurrentChecking}
					setCurrentAttempt={setCurrentAttempt}
				/>
			)}

			<Button
				styleTypes={[ButtonStyles.OUTLINE_DARK]}
				onClick={() => handleLoadUserAnswers && handleLoadUserAnswers(currentAnswers.creatorId)}
				className={cl.prevAttemptsBtn}
			>
				{isPrevAttemptsChecking ? 'Скрыть' : 'Показать'} предыдущие попытки
			</Button>
		</div>
	</div>
)

export default AttemptAnswerView
