import React from 'react'

import cl from 'pages/style.module.scss'
import NotificationSettingsForm from 'containers/NotificationsSettings/NotificationSettingsForm'

const NotificationsPage = () => (
	<div className={cl.container}>
		<h2>Настройки уведомлений</h2>

		<div className={cl.splitLine} />

		<NotificationSettingsForm />
	</div>
)

export default NotificationsPage
