import { IOption } from 'UI/Select/types'

export const courseLimitationTimeList: IOption[] = [
	{
		value: 7,
		text: '1 неделя'
	},
	{
		value: 30,
		text: '1 месяц'
	},
	{
		value: 90,
		text: '3 месяца'
	}
]
