import React, { FC } from 'react'
import { ModalHeight, ModalSize } from 'UI/Modal/types'
import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { useDisableScrollManual } from 'hooks/useDisableScrollManual'
import { useNavigate } from 'react-router-dom'
import cl from './style.module.scss'

interface UnPublishModalProps {
	isOpen: boolean
	courseTitle: string
	onClose: () => void
}

const UnPublishModal: FC<UnPublishModalProps> = ({
	isOpen,
	courseTitle,
	onClose
}) => {
	const navigate = useNavigate()
	useDisableScrollManual(isOpen)
	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			styleTypes={[ModalSize.BIG, ModalHeight.SMALL]}
			title="Курс сохранен в черновиках"
			description={`Вы можете найти курс "${courseTitle}" в разделе "Черновики" `}
		>
			<div className={cl.buttonsContainer}>
				<Button
					onClick={() => {
						navigate('/')
						onClose()
					}}
					styleTypes={[ButtonStyles.DARK, ButtonStyles.ROUND]}
				>
					Перейти к списку курсов
				</Button>
			</div>
		</Modal>
	)
}

export default UnPublishModal
