import React, { FC, useState } from 'react'

import classNames from 'classnames'
import { AsideBarOption } from 'components/AsideBar/types'
import { Link } from 'UI'

import cl from './style.module.scss'
import EducationHatIcon from '../icons/EducationHatIcon'

interface MobileMenuItemProps extends Omit<AsideBarOption, 'icon'> {
	active: boolean
	Icon: typeof EducationHatIcon
}

const colorToFillWith = '#0496ff'

const MobileMenuItem: FC<MobileMenuItemProps> = ({
	Icon,
	id,
	labelText,
	link,
	active
}) => {
	const [isOver, setOver] = useState(false)

	const mouseEnterHandler = () => {
		setOver(true)
	}

	const mouseLeaveHandler = () => {
		setOver(false)
	}

	return (
		<Link
			href={link}
			className={classNames(cl.link, {
				[cl.active]: active
			})}
			onMouseEnter={mouseEnterHandler}
			onMouseLeave={mouseLeaveHandler}
		>
			<Icon fill={active || isOver ? colorToFillWith : undefined} />
			<span>{labelText}</span>
		</Link>
	)
}

export default MobileMenuItem
