import { TimetableModeTypes } from 'containers/Timetable/TimetableContent'
import {
	getFirstAndLastDayOfTheMonth,
	getFirstAndLastDaysOfTheWeek
} from 'helpers/time'

interface GetFirstAndLastDateDependingOnModeParameters {
	currentDate?: Date
	mode: TimetableModeTypes
}

interface GetFirstAndLastDateDependingOnModeResult {
	firstDate: Date
	lastDate: Date
}

type getFirstAndLastDateDependingOnModeType = (
	_: GetFirstAndLastDateDependingOnModeParameters
) => GetFirstAndLastDateDependingOnModeResult

const getFirstAndLastDateDependingOnMode: getFirstAndLastDateDependingOnModeType =
	({ mode, currentDate }) => {
		switch (mode) {
			case TimetableModeTypes.MONTH:
				const { firstDayDate, lastDayDate } = getFirstAndLastDayOfTheMonth(
					currentDate || new Date()
				)
				return {
					firstDate: firstDayDate,
					lastDate: lastDayDate
				}
			case TimetableModeTypes.WEEK:
				const { firstDayDate: firstDayWeekDate, lastDayDate: lastDayWeekDate } =
					getFirstAndLastDaysOfTheWeek(currentDate || new Date())
				return {
					firstDate: firstDayWeekDate,
					lastDate: lastDayWeekDate
				}
			case TimetableModeTypes.SCHEDULE:
				const lastScheduleDate = new Date(currentDate || Date.now())
				lastScheduleDate.setHours(23)
				lastScheduleDate.setMinutes(59)

				return {
					firstDate: currentDate || new Date(),
					lastDate: lastScheduleDate
				}
			default:
				return {
					firstDate: new Date(),
					lastDate: new Date()
				}
		}
	}

export default getFirstAndLastDateDependingOnMode
