import { ScheduleNavigationContainerProps } from 'containers/Timetable/TimetableNavigation/ScheduleNavigation'
import React from 'react'
import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'

import { TimetableModeTypes } from 'containers/Timetable/TimetableContent'
import cl from './style.module.scss'

interface ScheduleNavigationComponentProps
	extends ScheduleNavigationContainerProps {
	currentMode: TimetableModeTypes
}

const ScheduleNavigation: React.FC<ScheduleNavigationComponentProps> = ({
	currentMode,
	onChange
}) => (
	<>
		<Button
			styleTypes={[
				currentMode === TimetableModeTypes.SCHEDULE
					? ButtonStyles.PRIMARY_HOLLOW
					: ButtonStyles.TERTIARY,
				ButtonStyles.ROUND
			]}
			className={cl.button}
			onClick={() => onChange('mode')(TimetableModeTypes.SCHEDULE)}
		>
			Расписание
		</Button>

		<Button
			styleTypes={[
				currentMode === TimetableModeTypes.WEEK
					? ButtonStyles.PRIMARY_HOLLOW
					: ButtonStyles.TERTIARY,
				ButtonStyles.ROUND
			]}
			className={cl.button}
			onClick={() => onChange('mode')(TimetableModeTypes.WEEK)}
		>
			Неделя
		</Button>
		<Button
			styleTypes={[
				currentMode === TimetableModeTypes.MONTH
					? ButtonStyles.PRIMARY_HOLLOW
					: ButtonStyles.TERTIARY,
				ButtonStyles.ROUND
			]}
			className={cl.button}
			onClick={() => onChange('mode')(TimetableModeTypes.MONTH)}
		>
			Месяц
		</Button>
	</>
)

export default ScheduleNavigation
