import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { makeFetchBaseQueryWithTariffs } from 'store/makeFetchBaseQueryWithTariffs'

import { Constants, ResponseEntityId } from 'types'
import {
	AddVideo,
	ChangeLesson,
	CreateLesson,
	DeleteLesson,
	GetDataForKinescopeResponse,
	GetLesson,
	LessonQueryApiRoutes,
	PassedLesson
} from 'store/queries/lesson.query/types'
import { buildHeaders } from 'helpers/api'
import { ILesson } from 'types/models/lesson.model'

const lessonQuery = createApi({
	reducerPath: 'lessonQuery',
	tagTypes: ['lesson'],
	baseQuery: makeFetchBaseQueryWithTariffs({
		baseUrl: `${Constants.API_BASE_URL}/${LessonQueryApiRoutes.BASE_URL}`
	}),
	endpoints: (build) => ({
		createLesson: build.mutation<ResponseEntityId, CreateLesson>({
			query: ({ body, sectionId }) => ({
				url: `create/section/${sectionId}`,
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['lesson']
		}),
		addVideoLink: build.mutation<void, AddVideo>({
			query: ({ body, lessonId }) => ({
				url: `${lessonId}/add-video`,
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['lesson']
		}),
		changeLesson: build.mutation<ResponseEntityId, ChangeLesson>({
			query: ({ body, lessonId }) => ({
				url: `${lessonId}/change`,
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['lesson']
		}),
		deleteLesson: build.mutation<ResponseEntityId, DeleteLesson>({
			query: ({ sectionId, lessonId }) => ({
				url: `${lessonId}/section/${sectionId}`,
				method: 'DELETE',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['lesson']
		}),
		getLessons: build.mutation<ResponseEntityId, GetLesson>({
			query: ({ lessonId }) => ({
				url: `${lessonId}`,
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['lesson']
		}),
		getLessonInfo: build.query<{ data: ILesson }, ResponseEntityId | undefined>(
			{
				query: (lessonId) => ({
					url: `${lessonId}`,
					method: 'GET',
					headers: buildHeaders({ needAuth: true })
				}),
				onQueryStarted(arg): Promise<void> | void {
					if (!arg) {
						throw new Error()
					}
				},
				providesTags: ['lesson']
			}
		),
		getDataForKinescope: build.query<
			GetDataForKinescopeResponse,
			ResponseEntityId
		>({
			query: (lessonId) => ({
				url: `get-data-for-kinescope/${lessonId}`,
				method: 'GET',
				headers: buildHeaders({ needAuth: true })
			}),
			providesTags: ['lesson']
		}),
		passedLesson: build.mutation<ResponseEntityId, PassedLesson>({
			query: ({ lessonId }) => ({
				url: `${lessonId}/passed`,
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['lesson']
		})
	})
})

export default lessonQuery
