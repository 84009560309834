import React, { FC, useEffect, useMemo, useState } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch,
	useWatch
} from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { courseLimitationTimeList } from 'pages/Course/consts'
import { getFullName, getUniqueId } from 'helpers'
import { ITab } from 'UI/Tabs/types'
import { ContentFormTab, MainFormTab } from 'containers/Courses/Tabs/Content'
import { CourseFormState } from 'pages/Course/Create.page'
import { ICourse } from 'types/models/course.model'
import { schoolQuery, userQuery } from 'store/queries'
import { IOption } from 'UI/Select/types'
import { useAppSelector } from 'hooks/redux'
import { TabsWithScrollByTouch } from 'UI/TabsWithScrollByTouch/TabsWithScrollByTouch'
import NotificationsFormTab from '../Content/NotificationsForm'
import AccessFormTab, { AccessFormTabState } from '../Content/AccessForm'
import StudentsFormTab from '../Content/StudentsForm'
import { MainFormTabState } from '../Content/MainForm'

import cl from '../style.module.scss'

interface CourseTabsProps {
	courseInfo: ICourse
	errors: FieldErrors<CourseFormState>
	onChange: (name: FieldPath<CourseFormState>) => (value: any) => void
	control: Control<CourseFormState>
	setValue: UseFormSetValue<CourseFormState>
	register: UseFormRegister<CourseFormState>
	handleSubmit: UseFormHandleSubmit<CourseFormState>
	watch: UseFormWatch<CourseFormState>
	getValues: UseFormGetValues<CourseFormState>
	onOpenCanceledChange: () => void
}

const CoursesTabs: FC<CourseTabsProps> = ({
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	watch,
	getValues,
	setValue,
	courseInfo,
	onOpenCanceledChange
}) => {
	const tabsList = useMemo(
		(): ITab[] => [
			{ id: getUniqueId(), text: 'Основные' },
			{ id: getUniqueId(), text: 'Содержание' },
			{ id: getUniqueId(), text: 'Уведомления' },
			{ id: getUniqueId(), text: 'Управление доступом' },
			{ id: getUniqueId(), text: 'Ученики' }
		],
		[]
	)

	const [selectedTabId, setSelectedTabId] = useState<ITab['id']>(
		[...tabsList].shift()!.id
	)

	useEffect(() => {
		setValue('id', courseInfo.id)
		setValue('name', courseInfo.name)
		setValue('description', courseInfo.description)
		// because it sets the value BEFORE its being registered, it skips the set
		// tbh the fix should be temporary, because timing the set is not really suitable
		// for this case
		setTimeout(() => {
			setValue('coverUrl', courseInfo.coverUrl)
		}, 500)
		setTimeout(() => {
			setValue('bannerForMPUrl', courseInfo.bannerForMPUrl)
		}, 500)
		setValue(
			'reviewResponsibilityStatus',
			courseInfo.reviewResponsibilityStatus
		)
		setValue('directionsIdList', courseInfo.directionsIdList)
		setValue('certificateSettings', courseInfo.certificateSettings)
		setValue('communicationsSettings', courseInfo.communicationsSettings)
		setValue('marketplaceSettings', courseInfo.marketplaceSettings)
		setValue('teachersIdList', courseInfo.teachersIdList)
		setValue('curatorsIdList', courseInfo.curatorsIdList)

		const presentDays = courseLimitationTimeList.map((item) => item.value)

		setValue('accessTimeCourse.days', courseInfo.accessTimeCourse)
		setValue(
			'accessTimeCourse.other',
			Boolean(
				courseInfo.accessTimeCourse &&
				!presentDays.includes(courseInfo.accessTimeCourse)
			)
		)

		setValue('courseCompletionTime.days', courseInfo.courseCompletionTime)
		setValue(
			'courseCompletionTime.other',
			Boolean(
				courseInfo.courseCompletionTime &&
				!presentDays.includes(courseInfo.courseCompletionTime)
			)
		)
	}, [courseInfo])

	const { currentSchoolId } = useAppSelector((state) => state.system)
	const { data: schoolData } = schoolQuery.useGetSchoolByIdQuery({
		schoolId: Number(currentSchoolId)
	})

	const { data: userData } = userQuery.useGetUsersQuery({
		usersIdList: schoolData?.data.usersIdList
	})

	const teachersIdList = useWatch({
		control,
		name: 'teachersIdList',
		defaultValue: []
	})
	const curatorsIdList = useWatch({
		control,
		name: 'curatorsIdList',
		defaultValue: []
	})

	const employersOptionsList: IOption[] = useMemo(() => {
		const school = schoolData?.data

		if (!school?.usersIdList.length) {
			return []
		}

		if (!userData?.data) {
			return []
		}
		return school.usersIdList.map((userId) => ({
			value: userId,
			text: getFullName(userData.data.find((user) => user.id === userId) || {}),
			hidden: [teachersIdList, curatorsIdList].some((idList) =>
				idList.includes(userId)
			)
		}))
	}, [userData, schoolData, teachersIdList, curatorsIdList])

	// TODO при переходе на таб предупреждение модалка о несохраненной форме
	return (
		<div className={cl.container}>
			<TabsWithScrollByTouch
				value={selectedTabId}
				tabs={tabsList}
				onChange={setSelectedTabId}
			/>
			<div className={cl.tabsContent}>
				{selectedTabId === tabsList[0].id && (
					<MainFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						isEditMode
						setValue={setValue as unknown as UseFormSetValue<MainFormTabState>}
						control={control as unknown as Control<MainFormTabState>}
						watch={watch as unknown as UseFormWatch<MainFormTabState>}
						getValues={
							getValues as unknown as UseFormGetValues<MainFormTabState>
						}
						register={register as unknown as UseFormRegister<MainFormTabState>}
						handleSubmit={handleSubmit}
						onChange={onChange}
						errors={errors}
					/>
				)}
				{selectedTabId === tabsList[1].id && (
					<ContentFormTab
						courseId={courseInfo.id}
						sectionsIdList={courseInfo.sectionsIdList}
					/>
				)}
				{selectedTabId === tabsList[2].id && (
					<NotificationsFormTab
						settings={
							courseInfo.notificationsSettings.noticeSettings
								.eventInformingSettings
						}
						courseInfo={courseInfo}
						isActive={courseInfo.notificationsSettings.noticeSettings.isActive}
					/>
				)}
				{selectedTabId === tabsList[3].id && (
					<AccessFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						courseId={courseInfo.id}
						employersOptionsList={employersOptionsList}
						control={control as unknown as Control<AccessFormTabState>}
						register={
							register as unknown as UseFormRegister<AccessFormTabState>
						}
						setValue={
							setValue as unknown as UseFormSetValue<AccessFormTabState>
						}
						onChange={onChange}
						handleSubmit={handleSubmit}
						errors={errors}
					/>
				)}
				{selectedTabId === tabsList[4].id && (
					<StudentsFormTab courseInfo={courseInfo} />
				)}
			</div>
			<DevTool control={control} />
		</div>
	)
}

export default CoursesTabs
