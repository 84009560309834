import React, { FC, useState } from 'react'
import classnames from 'classnames'
import helperTextIcon from './icons/helper.svg'

import cl from './style.module.scss'

interface TextHelperProps {
	text: string
}

const TextHelper: FC<TextHelperProps> = ({ text }) => {
	const [helperIsOpen, setHelperIsOpen] = useState(false)

	return (
		<div className={cl.container}>
			<img
				src={helperTextIcon}
				onMouseEnter={() => setHelperIsOpen(true)}
				onMouseLeave={() => setHelperIsOpen(false)}
				alt="helper-icon"
			/>
			<div
				className={classnames([
					cl.rectangle,
					{ [cl.rectangleOpened]: helperIsOpen }
				])}
			/>
			<div
				className={classnames([
					cl.textContent,
					{ [cl.textContentOpened]: helperIsOpen }
				])}
			>
				<p>{text}</p>
			</div>
		</div>
	)
}

export default TextHelper
