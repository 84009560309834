import React, { FC } from 'react'

import { Button, CheckboxGroup, RadioGroup } from 'UI'
import { ButtonSizes, ButtonStyles } from 'UI/Button/types'
import { AnswersListProps as AnswersListContainerProps } from 'containers/Courses/Entities/Tests/Preview/QuestionsListPreview/AnswersList'

import { PicturesInfoItem } from 'types/models/test.model'
import { Constants } from 'types'
import cl from '../style.module.scss'

interface AnswersListProps
	extends Omit<AnswersListContainerProps, 'name' | 'onSelect'> {
	name: `questions.${number}`
	fields: Record<'id', string>[]
	nextButtonIsDisabled: boolean
	nextQuestionHandler: (currentQuestionId: number) => void
	viewMode?: boolean
}

const AnswersList: FC<AnswersListProps> = ({
	name,
	getValues,
	control,
	fields,
	nextButtonIsDisabled,
	nextQuestionHandler,
	onChange,
	viewMode
}) => (
	<div className={cl.answers}>
		<div className={cl.answersHeader}>
			{getValues(`${name}.name`)}
			{getValues(`${name}.picturesInfoList`) && (
				<div className={cl.images}>
					{getValues(`${name}.picturesInfoList`).map((p: PicturesInfoItem) => (
						<div className={cl.testImage} key={p.id}>
							<img src={`${Constants.BASE_URL}${p.url}`} alt="" />
						</div>
					))}
				</div>
			)}
		</div>
		<div className={cl.answersBody}>
			{fields.map((field, fieldIndex) => (
				<div key={field.id} className={cl.answerItem}>
					{getValues(`${name}.answerType`) === 1
						? getValues(`${name}.answers.${fieldIndex}.value`) && (
								<RadioGroup
									itemsList={[
										{
											labelTitle: getValues(
												`${name}.answers.${fieldIndex}.value`
											),
											id: `${fieldIndex}`,
											value: getValues(`${name}.answers.${fieldIndex}.id`)
										}
									]}
									name={`${name}.correctAnswersIdList`}
									control={control}
									onChange={onChange(`${name}.correctAnswersIdList`)}
								/>
						  )
						: getValues(`${name}.answers.${fieldIndex}.value`) && (
								<CheckboxGroup
									name={`${name}.correctAnswersIdList`}
									control={control}
									onChange={onChange(`${name}.correctAnswersIdList`)}
									itemsList={[
										{
											labelTitle: getValues(
												`${name}.answers.${fieldIndex}.value`
											),
											id: `${fieldIndex}`,
											value: getValues(`${name}.answers.${fieldIndex}.id`)
										}
									]}
								/>
						  )}
				</div>
			))}
		</div>
		<Button
			onClick={() => nextQuestionHandler(getValues(`${name}.id`))}
			disabled={nextButtonIsDisabled}
			className={cl.buttonNext}
			styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND, ButtonSizes.BIG]}
		>
			Дальше
		</Button>
	</div>
)

export default AnswersList
