import React, { FC } from 'react'
import { UserAttemptsProps as UserAttemptsContainerProps } from 'containers/Courses/Entities/Tests/View/UserAttempts'
import { ITestAnswer } from 'store/queries/test.query/types'

import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import classNames from 'classnames'

import infinityIcon from 'components/Courses/Entities/Tests/View/images/infinity-icon.svg'

import cl from '../UserAttempts/style.module.scss'

interface UserAttemptsProps extends UserAttemptsContainerProps {
	userAnswers: ITestAnswer[]
}

const UserAttempts: FC<UserAttemptsProps> = ({
	userAnswers,
	questionsCount,
	setIsQuestionsCheckMode
}) => (
	<div className={cl.userAttempts}>
		<h2>Предыдущие попытки</h2>
		<div className={cl.attemptsTable}>
			<div className={cl.tb}>
				{userAnswers.map((answer) => (
					<div className={cl.tbItem} key={answer.id}>
						<span>Попытка №</span>
						<span>{answer.attempt}</span>

						<span>Статус</span>
						<span
							className={classNames({
								[cl.success]: answer.answerStatus === 'Пройден',
								[cl.fail]: answer.answerStatus === 'Не пройден'
							})}
						>
							{answer.answerStatus}, {answer.dateCreate}
						</span>

						<span>Правильные ответы</span>
						<span>
							{answer.correctAnswersCount} из{' '}
							{questionsCount || <img src={infinityIcon} alt="Infinity icon" />}
						</span>
						<span />
						<span>
							<Button
								styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
								onClick={() => setIsQuestionsCheckMode(answer.id)}
							>
								Посмотреть
							</Button>
						</span>
						<span />
					</div>
				))}
			</div>
		</div>
	</div>
)

export default UserAttempts
