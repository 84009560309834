import React from 'react'
import TimetableNavigationComponent from 'components/Timetable/TimetableNavigation'
import { Control, FieldPath } from 'react-hook-form'
import { TimetableContentFormValues } from '../TimetableContent'

export interface TimetableNavigationContainerProps {
	control: Control<TimetableContentFormValues>
	onChange: (
		name: FieldPath<TimetableContentFormValues>
	) => (value: any) => void
}

const TimetableNavigation: React.FC<TimetableNavigationContainerProps> = ({
	control,
	onChange
}) => <TimetableNavigationComponent control={control} onChange={onChange} />

export default TimetableNavigation
