import React, { FC } from 'react'
import WeekDaysComponent from 'components/Timetable/Tabs/WeekTab/WeekDays'
import { WeekDay } from 'types/models/day-event.model/day-event.model'

export interface WeekDaysContainerProps {
	mappedWeekDays: WeekDay[]
	short?: boolean
}

const WeekDays: FC<WeekDaysContainerProps> = ({ mappedWeekDays, short }) => (
	<WeekDaysComponent mappedWeekDays={mappedWeekDays} short={short} />
)

export default WeekDays
