import React, { FC } from 'react'
import classnames from 'classnames'

import { AnswerTestReviewStatus } from 'types/models/test.model'
import cl from './style.module.scss'

interface AttemptStatusProps {
	status: AnswerTestReviewStatus
}

const AttemptStatus: FC<AttemptStatusProps> = ({ status }) => {
	const statusClassName = classnames(cl.answerStatus, {
		[cl.answerStatusAccess]: status === AnswerTestReviewStatus.PASS,
		[cl.answerStatusDecline]: status === AnswerTestReviewStatus.NOT_PASS
	})

	return (
		<div className={statusClassName}>
			{status === AnswerTestReviewStatus.NOT_PASS && (
				<span>{AnswerTestReviewStatus.NOT_PASS}</span>
			)}
			{status === AnswerTestReviewStatus.PASS && (
				<span>{AnswerTestReviewStatus.PASS}</span>
			)}
		</div>
	)
}

export default AttemptStatus
