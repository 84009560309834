import React from 'react'

import cl from '../style.module.scss'

interface t {}

const SortToTopArrow = () => (
	<div className={cl.arrow}>
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.16666 7.01666V15.005H10.8333V7.01666L14.4108 10.5942L15.5892 9.41583L9.99999 3.82666L4.41083 9.41583L5.58916 10.5942L9.16666 7.01666Z"
				fill="#999999"
			/>
		</svg>
	</div>
)

export default SortToTopArrow
