import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { changePasswordExtra } from './extraReducers/extra'
import { UserSliceState } from './types'

const initialState: UserSliceState = {
	meta: {
		isLoading: false,
		error: ''
	}
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setCurrentUserId(state, { payload }: PayloadAction<number>) {
			state.currentUserId = payload
		},
		setCurrentUserName(state, { payload }: PayloadAction<string>) {
			state.currentUserName = payload
		}
	},
	extraReducers: {
		...changePasswordExtra
	}
})

export default userSlice
