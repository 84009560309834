import React, {
	FC,
	MouseEvent as ReactMouseEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import TableCellComponent from 'components/Timetable/Tabs/WeekTab/WeekTableCell/TableCell'
import { IEvent } from 'types/models/event.model'
import getEventStartTimeAndEventEndTime from 'helpers/time/getEventStartTimeAndEventEndTime'
import { addZeroToTime } from 'helpers/number'

export interface TableCellContainerProps {
	event?: IEvent
	highlighted?: boolean
	hoverIndex: number | null
	onHoverIndexChange: (_: number | null) => void
	index: number
	onDetailsOpen: (_: IEvent) => void
}

const TableCell: FC<TableCellContainerProps> = ({
	event,
	highlighted,
	hoverIndex,
	onHoverIndexChange,
	index,
	onDetailsOpen
}) => {
	const getEndTime = () => {
		if (event) {
			const dateStart = new Date(event.dateEventStart)
			const [hours, minutes] = event.time.split(':')
			dateStart.setHours(Number(hours))
			dateStart.setMinutes(Number(minutes))
			const dateEnd = new Date(dateStart.getTime() + event.duration)

			return `${addZeroToTime(dateEnd.getHours())}:${addZeroToTime(dateEnd.getMinutes())}`
		}
	}

	const ref = useRef<HTMLDivElement>(null)
	const [mouseOver, setMouseOver] = useState(false)

	const onMouseEnter = useCallback((e: ReactMouseEvent) => {
		e.stopPropagation()

		setMouseOver(true)
	}, [])
	const onMouseLeave = useCallback((e: ReactMouseEvent) => {
		e.stopPropagation()

		setMouseOver(false)
	}, [])

	const { start, end } = useMemo(() => {
		if (!event)
			return {
				start: '',
				end: ''
			}

		return getEventStartTimeAndEventEndTime(event)
	}, [event])

	const scaleRatio = useMemo(() => {
		if (!start || !end) return 0

		const [startHours] = start.split(':')
		const [endHours] = end.split(':')

		return Math.floor(+endHours - +startHours)
	}, [start, end])

	useEffect(() => {
		const onMouseMove = (e: MouseEvent) => {
			e.stopPropagation()

			if (!ref.current) return

			const { clientX, clientY } = e
			const { offsetHeight, offsetWidth } = ref.current

			ref.current.style.top = `${clientY - offsetHeight}px`
			ref.current.style.left = `${clientX - offsetWidth / 2}px`
		}

		document.body.addEventListener('mousemove', onMouseMove)

		return () => {
			document.body.removeEventListener('mousemove', onMouseMove)
		}
	}, [mouseOver])

	return (
		<TableCellComponent
			event={event}
			highlighted={highlighted}
			hoverIndex={hoverIndex}
			onHoverIndexChange={onHoverIndexChange}
			index={index}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			mouseOver={mouseOver}
			scaleRatio={scaleRatio}
			start={event?.time}
			end={getEndTime()}
			ref={ref}
			onDetailsOpen={onDetailsOpen}
		/>
	)
}

export default TableCell
