import React, {
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'

import {
	CertificateStatus,
	CoursePassStatus,
	ICourse,
	ReviewResponsibilityStatus
} from 'types/models/course.model'

import CourseItemComponent from 'components/Courses/StudentRole/Content/CourseItem'
import { courseQuery } from 'store/queries'
import { useAppSelector } from 'hooks/redux'
import userQuery from 'store/queries/user.query'
import { Constants, ResponseEntityId } from 'types'
import { useNavigate } from 'react-router-dom'
import { downloadFile } from 'helpers/files'

export interface CourseItemProps {
	course: ICourse
	completedMode?: boolean
}

const CourseItem: FC<CourseItemProps> = ({ course, completedMode }) => {
	const navigate = useNavigate()
	const linkRef = useRef<HTMLAnchorElement>(null)

	const [userId, setUserId] = useState<ResponseEntityId>()
	const [courseStatus, setCourseStatus] = useState<CoursePassStatus>(
		CoursePassStatus.NEW
	)
	const [certificateStatus, setCertificateStatus] = useState<CertificateStatus>(
		CertificateStatus.READY
	)
	const { token } = useAppSelector((state) => state.system)

	const { data: userInfo, isSuccess } = userQuery.useGetYourselfQuery(token)

	useEffect(() => {
		if (!userInfo || !isSuccess) {
			return
		}
		setUserId(userInfo.data.id)
	}, [userInfo, isSuccess])

	const { data: courseInfo } = courseQuery.useGetCourseQuery(course.id)

	const shouldLeaveFeedback = useMemo(() => {
		if (!courseInfo) return false

		return (
			courseInfo.data.reviewResponsibilityStatus ===
			ReviewResponsibilityStatus.REQUIRED
		)
	}, [courseInfo])

	const certificateAsAResult = useMemo(() => {
		if (!courseInfo) return false

		return courseInfo.data.certificateSettings.isActive
	}, [courseInfo])

	const [trigger, { data: passingCourse, isFetching }] =
		courseQuery.endpoints.courseGetPassing.useLazyQuery()

	const [getCertificateTrigger, { data: certificateInfo }] =
		courseQuery.useLazyGetCertificateQuery()

	const [startCoursePassingTrigger] =
		courseQuery.useStartCoursePassingMutation()

	const [createCertificateTrigger] = courseQuery.useCreateCertificateMutation()

	const [getReviews] = courseQuery.endpoints.getCourseReviews.useLazyQuery()

	useEffect(() => {
		if (!userId) {
			return
		}
		trigger({ courseId: course.id, userId: Number(userId) })
	}, [userId])

	useEffect(() => {
		if (!passingCourse) return

		getCertificateTrigger({ certificateId: passingCourse.data.certificateId })
	}, [passingCourse])

	const clickHandler = (courseId: ResponseEntityId) => {
		if (!userInfo || !isSuccess) return

		navigate(`/course/view/${courseId}`)

		if (
			courseStatus === CoursePassStatus.PROCESS ||
			courseStatus === CoursePassStatus.COMPLETED
		)
			return

		startCoursePassingTrigger({ courseId, userId: userInfo.data.id })
	}
	const viewHandler = (courseId: ResponseEntityId) => {
		if (!userInfo || !isSuccess) return

		navigate(`/course/view/${courseId}`)
	}

	const isCertificatePresent = () => !!passingCourse?.data.certificateId

	const downloadCertificate = useCallback(
		(url?: string) => {
			if (!certificateInfo) return

			if (url) {
				downloadFile(`${Constants.BASE_URL}${url}`, url)

				return
			}

			downloadFile(
				`${Constants.BASE_URL}${certificateInfo.data.url}`,
				certificateInfo.data.url
			)
		},
		[certificateInfo]
	)

	const onGetCertificate = useCallback(async () => {
		if (!passingCourse || !course) return

		const { data: courseReviews } = await getReviews({ courseId: course.id })

		if (!courseReviews?.data && shouldLeaveFeedback) {
			navigate(`/course/${course.id}/get-certificate`)
			return
		}

		if (courseReviews?.data && shouldLeaveFeedback) {
			const isUserLeftReview = courseReviews?.data.find(review => review.user.includes(userInfo?.data.firstName || ''))

			if (!isUserLeftReview) {
				navigate(`/course/${course.id}/get-certificate`)
				return
			}
		}

		if (isCertificatePresent()) {
			downloadCertificate()
			return
		}

		await createCertificateTrigger({ courseId: course.id })

		const { data: coursePassingData } = await trigger({
			courseId: course.id,
			userId: Number(userId)
		})

		if (!coursePassingData) return

		const { data: certificateData } = await getCertificateTrigger({
			certificateId: coursePassingData.data.certificateId
		})

		if (!certificateData) return

		downloadCertificate(certificateData.data.url)
	}, [course, passingCourse, shouldLeaveFeedback, userId, downloadCertificate])

	useEffect(() => {
		if (!passingCourse || !passingCourse.data.id) return

		if (passingCourse.data.passed) {
			setCourseStatus(CoursePassStatus.COMPLETED)
		}

		if (!passingCourse.data.passed && Number(passingCourse.data.passingLevel) < 100) {
			setCourseStatus(CoursePassStatus.PROCESS)
		}

		if (passingCourse.data.certificateId) {
			setCertificateStatus(CertificateStatus.GOTTEN)
		}
	}, [passingCourse])

	if (isFetching || !passingCourse) return null

	if (!completedMode && passingCourse?.data.passed) return null
	if (completedMode && !passingCourse?.data.passed) return null

	return (
		<>
			<label style={{ display: 'none' }}>
				<a
					ref={linkRef}
					href={`${Constants.BASE_URL}${certificateInfo?.data.url}`}
				>
					Скачать
				</a>
			</label>
			<CourseItemComponent
				onClick={completedMode ? viewHandler : clickHandler}
				course={course}
				courseStatus={courseStatus}
				certificateStatus={certificateStatus}
				completedMode={completedMode}
				onGetCertificate={onGetCertificate}
				certificateAsAResult={certificateAsAResult}
				veiwAvailable={passingCourse?.data.viewingAccess}
			/>
		</>
	)
}

export default CourseItem
