import {
	CheckResponseContainerProps,
	CheckResponseFormValues
} from 'containers/Courses/Entities/Tasks/CheckResponse'
import AnswerCreator from 'containers/Courses/Entities/Tests/Check/AttemptsTable/AnswerCreator'
import React from 'react'
import { Control, FieldPath, UseFormWatch } from 'react-hook-form'
import { Button, TextArea } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import NumberInput from 'UI/Input/NumberInput'

import cl from './style.module.scss'

interface CheckResponseComponentProps
	extends Omit<
		CheckResponseContainerProps,
		| 'taskId'
		| 'taskCurrentAnswer'
		| 'editMode'
		| 'setEditMode'
		| 'task'
		| 'taskAnswersInfo'
		| 'currentAnswerIndex'
	> {
	control: Control<CheckResponseFormValues>
	onChange: (name: FieldPath<CheckResponseFormValues>) => (value: any) => void
	onSubmit: (nextTask?: boolean) => void
	watch: UseFormWatch<CheckResponseFormValues>
	loading: boolean
	disableShowNextBtn?: boolean
}

const CheckResponse: React.FC<CheckResponseComponentProps> = ({
	creatorId,
	passingScore,
	control,
	onChange,
	onSubmit,
	watch,
	loading,
	disableShowNextBtn
}) => (
	<div className={cl.checkContainer}>
		<header className={cl.checkHeader}>
			<AnswerCreator creatorId={creatorId} noEmail noName />
			<div className={cl.assessment}>
				<span>Оценка</span>
				<NumberInput
					control={control}
					name="assessment"
					onChange={onChange('assessment')}
					defaultValue={watch('assessment')}
				/>
				<span>из 10</span>;
				<span className={cl.gray}>Проходной балл - {passingScore}</span>
			</div>
		</header>
		<footer className={cl.checkFooter}>
			<TextArea
				control={control}
				onChange={onChange('comment')}
				name="comment"
				defaultValue={watch('comment')}
				placeholder="Комментарий к ответу"
			/>
			<div className={cl.checkButtonsContainer}>
				<Button
					styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
					disabled={loading}
					onClick={() => onSubmit()}
				>
					Отправить ответ
				</Button>

				<Button
					styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
					disabled={loading || disableShowNextBtn}
					onClick={() => {
						onSubmit(true)
					}}
				>
					Отправить и показать следующий
				</Button>
			</div>
		</footer>
	</div>
)

export default CheckResponse
