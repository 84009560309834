import React, { FC } from 'react'
import AttemptsTableComponent from 'components/Courses/Entities/Tasks/Check/AttemptsTable'
import { ResponseEntityId } from 'types'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { ITaskAnswerResponse } from 'types/models/task.model'

export interface AttemptsTasksTable {
	userAnswersIdList: string[]
	courseId: ResponseEntityId
	taskId: ResponseEntityId
	taskAnswersInfo: ITaskAnswerResponse[]
}

const AttemptsTable: FC<AttemptsTasksTable> = ({
	userAnswersIdList,
	courseId,
	taskId,
	taskAnswersInfo
}) => {
	if (!taskAnswersInfo === undefined) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<AttemptsTableComponent
			taskId={taskId}
			courseId={courseId}
			taskAnswersInfo={taskAnswersInfo}
			userAnswersIdList={userAnswersIdList}
			hideAvatarMobile
		/>
	)
}

export default AttemptsTable
