import React from 'react'

import videoSrc from 'components/DownloadInstruction/IOSInstructions/video/help-video.mp4'
import appleShareSrc from 'components/DownloadInstruction/IOSInstructions/images/apple-share.svg'

import { useNavigate } from 'react-router-dom'
import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import cl from '../style.module.scss'

const IOSInstructions = () => {
	const navigate = useNavigate()

	const onConfirm = () => navigate(-1)

	return (
		<div>
			<div className={cl.videoContainer}>
				{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
				<video playsInline loop autoPlay muted className={cl.video}>
					<source
						src={videoSrc}
						type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"'
					/>
				</video>
			</div>

			<div className={cl.container}>
				<div className={cl.text}>
					<ol>
						<li>
							Нажмите кнопку <img src={appleShareSrc} alt="home" width="25px" />{' '}
							внизу браузера - откроется меню, как на картинке;
						</li>
						<li>
							В конце списка выберите пункт <span>На экран «Домой»</span>, а
							затем нажмите <span>Добавить</span>.
						</li>
					</ol>
				</div>
				<div className={cl.text}>
					<p>
						Стабильная работа приложения гарантирована и протестирована в
						браузере Safari. 
					</p>
				</div>

				<Button
					styleTypes={[ButtonStyles.ROUND, ButtonStyles.PRIMARY]}
					onClick={onConfirm}
				>
					Понятно
				</Button>
			</div>
		</div>
	)
}

export default IOSInstructions
