import React, { FC } from 'react'
import { UseFormGetValues } from 'react-hook-form'

import LessonViewComponent from 'components/Courses/Entities/Lessons/Preview'
import { LessonFormState } from 'pages/Course/Lesson/Create.page'
import { useParams } from 'react-router-dom'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import courseQuery from 'store/queries/course.query'
import { ILesson } from 'types/models/lesson.model'

interface LessonPreviewProps {
	isPreview?: boolean
	getValues: UseFormGetValues<LessonFormState>
	lessonInfo?: ILesson
}

const LessonPreview: FC<LessonPreviewProps> = ({
	isPreview = true,
	getValues,
	lessonInfo
}) => {
	if (!isPreview) {
		return <></>
	}
	const { courseId, lessonId } = useParams()
	if (!(courseId && lessonId)) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}
	const { data: courseInfo } = courseQuery.useGetCourseQuery(+courseId)

	if (!courseInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<LessonViewComponent
			communicationInfo={courseInfo.data.communicationsSettings}
			getValues={getValues}
			lessonInfo={lessonInfo}
		/>
	)
}

export default LessonPreview
