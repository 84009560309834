import React, { FC } from 'react'
import { GoBackContainerProps } from 'containers/Timetable/Tabs/ScheduleTab/ScheduleTable/GoBack'
import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'

import cl from './style.module.scss'

interface GoBackComponentProps
	extends Omit<GoBackContainerProps, 'onChange' | 'currentDate'> {
	onGoBack: () => void
}

const GoBack: FC<GoBackComponentProps> = ({ onGoBack, dateString }) => (
	<div className={cl.navLine}>
		<span>Расписание до {dateString}</span>
		<Button onClick={onGoBack} styleTypes={[ButtonStyles.TERTIARY_BLUE]}>
			Показать раньше
		</Button>
	</div>
)

export default GoBack
