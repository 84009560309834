import React, { useEffect, useState, useMemo } from 'react'

import { courseQuery, schoolQuery, selectsQuery } from 'store/queries'
import { Accordion } from 'UI'
import { CoursesListFilter } from 'store/queries/course.query/types'
import { ICourse, StatusType } from 'types/models/course.model'
import { useActions, useAppSelector } from 'hooks/redux'
import { StorageKeys } from 'types'
import { saveItemToStorage } from 'helpers/storage'
import { StorageTypes } from 'helpers/storage/types'
import { ArchivedIcon, PublishedIcon, TrashedIcon } from './icons'
import CoursesTable from '../Table'

import cl from './style.module.scss'

const CoursesAccordion = () => {
	const [courses, setCourses] = useState<ICourse[]>([])

	const { currentSchoolId, currentCoursesIdList } = useAppSelector(
		(state) => state.system
	)
	const { setCurrentCoursesIdList } = useActions((state) => state.system)
	const { currentRole } = useAppSelector((state) => state.system)
	const { data: schoolData } = schoolQuery.useGetSchoolByIdQuery(
		{
			schoolId: Number(currentSchoolId)
		},
		{ refetchOnMountOrArgChange: true }
	)
	const { data: tariffsInfo } = selectsQuery.useGetTariffsQuery()

	const getFilteredCourses = (
		coursesInfo: ICourse[],
		type: StatusType.PUBLISHED | StatusType.TRASHED | StatusType.ARCHIVED
	) => coursesInfo?.filter((course) => course.statusType === type) || []

	const { published, trashed, archived } = {
		published: getFilteredCourses(courses, StatusType.PUBLISHED),
		trashed: getFilteredCourses(courses, StatusType.TRASHED),
		archived: getFilteredCourses(courses, StatusType.ARCHIVED)
	}

	const maxCoursesReached = () => {
		if (!tariffsInfo || !schoolData) return undefined
		const currentTariff = tariffsInfo.data.find(
			(t) => t.id.toString() === schoolData.data.rateId.toString()
		)

		const maxCourses = currentTariff?.value.numberCourses

		if (maxCourses) {
			return maxCourses <= published.length
		}
	}

	const [trigger, { data: coursesInfo }] = courseQuery.endpoints.getCourses.useLazyQuery()
	useEffect(() => {
		if (schoolData) {
			trigger({
			coursesIdList: schoolData.data.coursesIdList,
			role: currentRole
		})
		setCurrentCoursesIdList(schoolData.data.coursesIdList)
		}

	}, [schoolData, currentRole])

	useEffect(() => {
		if (!currentCoursesIdList || !currentCoursesIdList.length) {
			setCourses([])
			// return
		}

		// trigger({ coursesIdList: currentCoursesIdList, role: currentRole })
		// console.log(2, currentCoursesIdList);
		// if (currentCoursesIdList.length) {
		// 	saveItemToStorage([StorageKeys.CURRENT_COURSES_ID_LIST, currentCoursesIdList], {
		// 		storageType: StorageTypes.LOCAL_STORAGE
		// 	})
		// }
	}, [currentCoursesIdList])

	useEffect(() => {
		if (!coursesInfo) return
		setCourses(coursesInfo.data)
	}, [coursesInfo])

	if (!coursesInfo) {
		return <></>
	}

	return (
		<div className={cl.container}>
			<Accordion
				label="Опубликованные курсы"
				iconUrl={PublishedIcon}
				contentItemsLength={
					published.length
						? `Курсов - ${published.length}`
						: `Курсы отсутствуют`
				}
			>
				<CoursesTable data={published} />
			</Accordion>
			<Accordion
				label={<span className={cl.archived}>&quot;Черновики&quot;</span>}
				iconUrl={TrashedIcon}
				contentItemsLength={
					trashed.length ? `Курсов - ${trashed.length}` : `Курсы отсутствуют`
				}
			>
				<CoursesTable data={trashed} maxCoursesReached={maxCoursesReached()} />
			</Accordion>
			<Accordion
				label={<span className={cl.trashed}>&quot;Архивные&quot;</span>}
				iconUrl={ArchivedIcon}
				contentItemsLength={
					archived.length ? `Курсов - ${archived.length}` : `Курсы отсутствуют`
				}
			>
				<CoursesTable data={archived} />
			</Accordion>
		</div>
	)
}

export default CoursesAccordion
