import React, { FC, useRef, MutableRefObject } from 'react'
import { ITab } from 'UI/Tabs/types'
import Tabs from 'UI/Tabs'

import cl from './style.module.scss'

interface TabsWithScrollByTouchProps {
	value: ITab['id']
	tabs: ITab[]
	onChange: (tabId: ITab['id']) => void
}

export const TabsWithScrollByTouch: FC<TabsWithScrollByTouchProps> = ({
	value,
	tabs,
	onChange
}) => {
	const tabsContainerRef = useRef<HTMLDivElement | null>(null)

	return (
		<div className={cl.tabsToggles} ref={tabsContainerRef}>
			<Tabs
				value={value}
				tabs={tabs}
				onChange={onChange}
				scrollContainerRef={
					tabsContainerRef as MutableRefObject<HTMLDivElement>
				}
			/>
		</div>
	)
}
