import React, { FC } from 'react'
import { UserAttemptsProps as UserAttemptsContainerProps } from 'containers/Courses/Entities/Tests/View/UserAttempts'
import { ITestAnswer } from 'store/queries/test.query/types'
import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'

import infinityIcon from 'components/Courses/Entities/Tests/View/images/infinity-icon.svg'

import cl from './style.module.scss'

interface UserAttemptsProps extends UserAttemptsContainerProps {
	userAnswers: ITestAnswer[]
}

const UserAttempts: FC<UserAttemptsProps> = ({
	userAnswers,
	questionsCount,
	setIsQuestionsCheckMode
}) => (
	<div className={cl.userAttempts}>
		<h2>Предыдущие попытки</h2>
		<div className={cl.attemptsTable}>
			<div className={cl.th}>
				<span>Попытка №</span>
				<span>Статус</span>
				<span>Правильные ответы</span>
				<span>Просмотреть</span>
			</div>
			<div className={cl.tb}>
				{userAnswers.map((answer) => (
					<div className={cl.tbItem} key={answer.id}>
						<span>{answer.attempt}</span>
						<span>
							{answer.answerStatus}, {answer.dateCreate}
						</span>
						<span>
							{answer.correctAnswersCount} из{' '}
							{questionsCount || <img src={infinityIcon} alt="Infinity icon" />}
						</span>
						<span>
							<Button
								styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
								onClick={() => setIsQuestionsCheckMode(answer.id)}
							>
								Посмотреть
							</Button>
						</span>
						<span />
					</div>
				))}
			</div>
		</div>
	</div>
)

export default UserAttempts
