import React, { FC, useEffect } from 'react'
import TaskAttemptsComponent from 'components/Courses/Entities/Tasks/Attempts'
import taskQuery from 'store/queries/task.query'
import { ResponseEntityId } from 'types'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'

export interface TaskAttemptsProps {
	taskId: ResponseEntityId
	countAttempts: ResponseEntityId
	passingScore: ResponseEntityId
	onChangeEditMode?: (_: boolean) => void
	answerEditMode?: boolean
}

const TaskAttempts: FC<TaskAttemptsProps> = ({
	taskId,
	countAttempts,
	passingScore,
	onChangeEditMode,
	answerEditMode
}) => {
	const [triggerUserAnswers, { data: userTasksAnswersInfo, isSuccess }] =
		taskQuery.endpoints.getUserTasks.useLazyQuery()

	useEffect(() => {
		if (!taskId) {
			return
		}
		triggerUserAnswers({ taskId })
	}, [taskId])

	if (!isSuccess || !userTasksAnswersInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<TaskAttemptsComponent
			passingScore={passingScore}
			countAttempts={countAttempts}
			answers={userTasksAnswersInfo.data}
			onChangeEditMode={onChangeEditMode}
			answerEditMode={answerEditMode}
		/>
	)
}

export default TaskAttempts
