import React, { FC, useEffect, useState } from 'react'

import { CoursesTabsProps } from 'containers/Courses/Tabs/View/Wrapper.container'
import FeedbacksTabComponent from 'components/Courses/Tabs/ViewContent/Feedback'
import courseQuery from 'store/queries/course.query'
import { ICourseReview } from 'types/models/course.model'

export interface FeedbacksTabProps extends CoursesTabsProps { }

const FeedbacksTab: FC<FeedbacksTabProps> = ({ courseInfo }) => {
	const [reviews, setReviews] = useState<ICourseReview[]>([])
	const [trigger, { data: courseReviews, isSuccess }] = courseQuery.endpoints.getCourseReviews.useLazyQuery()

	const handleShowMoreReviews = () => {
		trigger({ courseId: courseInfo.id, limit: reviews.length + 5 })
	}

	useEffect(() => {
		if (!courseInfo) {
			return
		}
		trigger({ courseId: courseInfo.id, limit: 5 })
	}, [courseInfo])

	useEffect(() => {
		if (!isSuccess || !courseReviews) {
			return
		}
		setReviews(courseReviews.data)
	}, [isSuccess, courseReviews])

	const showMoreButtonShow = !!courseReviews?.totalCount && !!reviews.length && (reviews.length !== courseReviews.totalCount)

	return <FeedbacksTabComponent
		courseReviews={reviews}
		handleShowMoreReviews={handleShowMoreReviews}
		showButton={showMoreButtonShow}
	/>
}

export default FeedbacksTab
