import { EventFilterTypes, IEvent } from 'types/models/event.model'
import { EntityId } from '@reduxjs/toolkit'
import { ResponseEntityId } from 'types'

export enum EventsPaths {
	BASE_URL = 'events/',
	CREATE_EVENT = 'create/section/',
	CHANGE_EVENT = 'change',
	GET_EVENTS_FOR_SCHOOL = 'get-list/',
	DELETE_EVENT = 'section/'
}

export interface CreateEventResponse {
	eventId: string
}

export interface CreateEventPayload extends IEvent {
	sectionId: number
}

export interface ChangeEventPayload extends IEvent {
	eventId: number
}

export interface GetEventByIdResponse {
	data: IEvent
}

export interface GetEventsFilter {
	dateFrom: string
	dateTo: string
	type: EventFilterTypes
}

export interface GetEventsForSchoolPayload {
	schoolId: EntityId

	dateFrom: string
	dateTo: string
}

export interface GetEventsForSchoolResponse {
	data: IEvent[]
}

export interface DeleteEventPayload {
	eventId: ResponseEntityId
	sectionId: ResponseEntityId
}
