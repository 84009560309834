import React, { FC } from 'react'
import { ConfigModalProps as ConfigModalContainerProps } from 'containers/Courses/Tabs/Content/MainForm/CertificateSettings/modals/Config'
import { Button, Checkbox, Modal, TextInput } from 'UI'
import { ModalSize } from 'UI/Modal/types'
import { ButtonSizes, ButtonStyles } from 'UI/Button/types'
import FileInput from 'UI/FileInput'
import { ImageUrl } from 'types/models/course.model/settings/certificate.model'
import { AcceptTypes } from 'UI/DragAndDrop/types'
import ValidateNotify from 'UI/Input/ValidateNotify'
import cl from './style.module.scss'

interface ConfigModalProps
	extends Omit<
		ConfigModalContainerProps,
		'getValues' | 'resetCertificateConfigs'
	> {
	onOpenSchoolLogoModal: () => void
	onOpenFilledCertificateModal: () => void
	onOpenResetCertificateModal: () => void
	signPreview?: ImageUrl
	sampleCertificateForMPPreview?: ImageUrl
	markPreview?: ImageUrl
	onDeleteSignPreview: () => void
	onDeleteSampleCertificateForMPPreview: () => void
	onDeleteMarkPreview: () => void
}

const ConfigModal: FC<ConfigModalProps> = ({
	modalIsOpen,
	onCloseModal,
	onChange,
	control,
	watch,
	onOpenSchoolLogoModal,
	onOpenFilledCertificateModal,
	onOpenResetCertificateModal,
	signPreview,
	sampleCertificateForMPPreview,
	markPreview,
	onDeleteMarkPreview,
	onDeleteSignPreview,
	onDeleteSampleCertificateForMPPreview,
	errors
}) => (
	<Modal
		onClose={onCloseModal}
		styleTypes={[ModalSize.BIG]}
		description="Загрузите данные сертификата"
		isOpen={modalIsOpen}
		title="Настройка сертификата"
	>
		<div className={cl.inputs}>
			<div className={cl.inputContainer}>
				<label htmlFor="certificateSettings.templateInfo.teacherSettings.isActive">
					Преподаватель
				</label>
				{/* <Checkbox
							id="certificateSettings.templateInfo.teacherSettings.isActive"
							onChange={onChange(
								'certificateSettings.templateInfo.teacherSettings.isActive'
							)}
							name="certificateSettings.templateInfo.teacherSettings.isActive"
							control={control}
							labelTitle="Настроить отображение преподавателя"
						/>
						<p className={cl.caption}>
							Если оставить поле ввода пустым, преподаватель не будет показан на
							сертификате.
						</p> */}
				<TextInput
					onChange={onChange(
						'certificateSettings.templateInfo.teacherSettings.name'
					)}
					control={control}
					// disabled={
					// 	!watch('certificateSettings.templateInfo.teacherSettings.isActive')
					// }
					name="certificateSettings.templateInfo.teacherSettings.name"
					placeholder="Виктор Степанов"
				/>
				{errors.certificateSettings?.templateInfo?.teacherSettings?.name && (
					<ValidateNotify error={errors.certificateSettings?.templateInfo?.teacherSettings?.name.message} />
				)}
			</div>
			<div className={cl.inputContainer}>
				<label>Логотип школы</label>
				<p className={cl.caption}>
					Мы используем логотип из настроек профиля школы. Чтобы настроить логотип
					перейдите в настройки школы.
				</p>
				<Button
					onClick={() => {
						onCloseModal()
						setTimeout(() => {
							onOpenSchoolLogoModal()
						}, 100)
					}}
					styleTypes={[
						ButtonStyles.OUTLINE_DARK,
						ButtonStyles.ROUND,
						ButtonSizes.UNSET
					]}
				>
					Посмотреть текущий логотип
				</Button>
			</div>

			<div className={cl.inputContainer}>
				<label>Печать</label>

				<FileInput
					onChange={onChange('signerMark')}
					previewFile={markPreview || null}
					control={control}
					name="signerMark"
					onDeleteFile={onDeleteMarkPreview}
					accept={[
						AcceptTypes.JPEG,
						AcceptTypes.JPG,
						AcceptTypes.SVG,
						AcceptTypes.PNG,
						AcceptTypes.WEBP
					]}
				/>
				{errors.certificateSettings?.templateInfo?.schoolSettings?.signerMarkUrl && (
					<ValidateNotify error={errors.certificateSettings?.templateInfo?.schoolSettings?.signerMarkUrl.message} />
				)}
			</div>

			<div className={cl.inputContainer}>
				<label>Факсимильная подпись</label>

				<FileInput
					onChange={onChange('signerSign')}
					previewFile={signPreview || null}
					control={control}
					name="signerSign"
					onDeleteFile={onDeleteSignPreview}
					accept={[
						AcceptTypes.JPEG,
						AcceptTypes.JPG,
						AcceptTypes.SVG,
						AcceptTypes.PNG,
						AcceptTypes.WEBP
					]}
				/>
				{errors.certificateSettings?.templateInfo?.schoolSettings?.signerSignUrl && (
					<ValidateNotify error={errors.certificateSettings?.templateInfo?.schoolSettings?.signerSignUrl.message} />
				)}
			</div>

			{/* <div className={cl.inputContainer}>
				<label>Образец сертификата для маркетплейса</label>

				<FileInput
					onChange={onChange('sampleCertificateForMP')}
					previewFile={sampleCertificateForMPPreview || null}
					control={control}
					name="sampleCertificateForMP"
					onDeleteFile={onDeleteSampleCertificateForMPPreview}
					accept={[
						AcceptTypes.JPEG,
						AcceptTypes.JPG,
						AcceptTypes.SVG,
						AcceptTypes.PNG,
						AcceptTypes.WEBP
					]}
				/>
			</div> */}

			<div className={cl.inputContainer}>
				<label htmlFor="certificateSettings.templateInfo.schoolSettings.isActive">
					Настройки школы
				</label>
				{/* <Checkbox
						onChange={onChange(
							'certificateSettings.templateInfo.schoolSettings.isActive'
						)}
						name="certificateSettings.templateInfo.schoolSettings.isActive"
						control={control}
						labelTitle="Настроить отображение школы"
					/> */}
				<TextInput
					// disabled={
					// 	!watch('certificateSettings.templateInfo.schoolSettings.isActive')
					// }
					onChange={onChange(
						'certificateSettings.templateInfo.schoolSettings.schoolName'
					)}
					control={control}
					name="certificateSettings.templateInfo.schoolSettings.schoolName"
					placeholder="Название школы"
				/>
				{errors.certificateSettings?.templateInfo?.schoolSettings?.schoolName && (
					<ValidateNotify error={errors.certificateSettings?.templateInfo?.schoolSettings?.schoolName.message} />
				)}
				<TextInput
					// disabled={
					// 	!watch('certificateSettings.templateInfo.schoolSettings.isActive')
					// }
					onChange={onChange(
						'certificateSettings.templateInfo.schoolSettings.signerPost'
					)}
					control={control}
					name="certificateSettings.templateInfo.schoolSettings.signerPost"
					placeholder="Должность подписанта"
				/>
				{errors.certificateSettings?.templateInfo?.schoolSettings?.signerPost && (
					<ValidateNotify error={errors.certificateSettings?.templateInfo?.schoolSettings?.signerPost.message} />
				)}
				<TextInput
					// disabled={
					// 	!watch('certificateSettings.templateInfo.schoolSettings.isActive')
					// }
					onChange={onChange(
						'certificateSettings.templateInfo.schoolSettings.signerName'
					)}
					control={control}
					name="certificateSettings.templateInfo.schoolSettings.signerName"
					placeholder="Подписант"
				/>
				{errors.certificateSettings?.templateInfo?.schoolSettings?.signerName && (
					<ValidateNotify error={errors.certificateSettings?.templateInfo?.schoolSettings?.signerName.message} />
				)}
			</div>
			<div className={cl.buttonsActions}>
				<Button
					onClick={() => {
						onCloseModal()
						setTimeout(() => {
							onOpenResetCertificateModal()
						}, 100)
					}}
					styleTypes={[ButtonStyles.OUTLINE_DARK, ButtonStyles.ROUND]}
				>
					Сбросить настройки
				</Button>
				<Button
					onClick={() => {
						onCloseModal()
						onOpenFilledCertificateModal()
					}}
					disabled={
						!(
							watch(
								'certificateSettings.templateInfo.schoolSettings.schoolName'
							) ||
							watch('certificateSettings.templateInfo.schoolSettings.signerPost') ||
							watch('certificateSettings.templateInfo.schoolSettings.signerName') ||
							watch('certificateSettings.templateInfo.teacherSettings.name')
						)
					}
					styleTypes={[ButtonStyles.OUTLINE_PRIMARY, ButtonStyles.ROUND]}
				>
					Предпросмотр
				</Button>
			</div>
			<div className={cl.splitLine} />
			<div className={cl.buttonsFooter}>
				<Button
					onClick={() => {
						onCloseModal()
					}}
					styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
				>
					Отмена
				</Button>
				<Button
					type="submit"
					onClick={() => {
						setTimeout(() => {
							onCloseModal(true)
						}, 0)
					}}
					styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
				>
					Сохранить изменения
				</Button>
			</div>
		</div>
	</Modal>
)


export default ConfigModal
