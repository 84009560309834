import React, { FC, useEffect, useMemo } from 'react'
import { Control, useWatch } from 'react-hook-form'
import { TestTypes } from 'types/models/test.model'

import { getUniqueId } from 'helpers'

import { isNil } from 'lodash'
import { IRadioGroupItem } from './types'
import RadioGroupItem from './Item'

interface RadioGroupProps {
	itemsList: IRadioGroupItem[]
	name: string
	control: Control<any>
	onChange: (value: IRadioGroupItem['value']) => void
	disabled?: boolean
	testType?: TestTypes
	viewMode?: boolean
	isFinalTestOngoing?: boolean
}

const RadioGroup: FC<RadioGroupProps> = ({
	itemsList: items,
	name,
	onChange,
	control,
	disabled,
	testType,
	viewMode,
	isFinalTestOngoing
}) => {
	const itemsList = useMemo(
		() => items.map((item) => ({ ...item, id: getUniqueId(item.id) })),
		[items]
	)

	const value: IRadioGroupItem['value'] = useWatch({
		control,
		name
	})
	useEffect(() => {
		if (isNil(value)) {
			return
		}

		onChange(value)
	}, [])

	return (
		<>
			{itemsList.map((radio) => (
				<RadioGroupItem
					key={radio.id}
					name={name}
					value={value}
					onChange={onChange}
					item={radio}
					testType={testType}
					disabled={disabled}
					viewMode={viewMode}
					isFinalTestOngoing={isFinalTestOngoing}
				/>
			))}
		</>
	)
}
export default RadioGroup
