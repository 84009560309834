import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import courseQuery from 'store/queries/course.query'
import { lessonQuery } from 'store/queries'
import LessonView from 'containers/Courses/Entities/Lessons/View/View'

const LessonViewPage = () => {
	const { courseId, lessonId } = useParams()

	if (!(courseId && lessonId)) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	const [triggerLessonQuery, { data: lessonInfo }] =
		lessonQuery.endpoints.getLessonInfo.useLazyQuery()

	const [triggerCourseQuery, { data: courseInfo }] =
		courseQuery.endpoints.getCourse.useLazyQuery()

	useEffect(() => {
		if (!lessonId || !courseId) {
			return
		}
		triggerLessonQuery(Number(lessonId))
		triggerCourseQuery(Number(courseId))
	}, [lessonId, courseId])

	if (!lessonInfo || !courseInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<LessonView
			communicationsInfo={courseInfo.data.communicationsSettings}
			lesson={lessonInfo.data}
			courseId={Number(courseId)}
			courseSectionsId={courseInfo.data.sectionsIdList}
		/>
	)
}

export default LessonViewPage
