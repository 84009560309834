import React from 'react'
import GetCertificateComponent from 'components/Courses/Entities/Certificate/Get'

export interface GetCertificateContainerProps {
	courseId: number
}

const GetCertificate: React.FC<GetCertificateContainerProps> = ({
	courseId
}) => <GetCertificateComponent courseId={courseId} />

export default GetCertificate
