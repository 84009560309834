import React from 'react'
import ScheduleNavigationComponent from 'components/Timetable/TimetableNavigation/ScheduleNavigation'
import { Control, FieldPath, useWatch } from 'react-hook-form'
import { TimetableContentFormValues } from 'containers/Timetable/TimetableContent'

export interface ScheduleNavigationContainerProps {
	control: Control<TimetableContentFormValues>
	onChange: (
		name: FieldPath<TimetableContentFormValues>
	) => (value: any) => void
}

const ScheduleNavigation: React.FC<ScheduleNavigationContainerProps> = ({
	control,
	onChange
}) => {
	const currentMode = useWatch({
		control,
		name: 'mode'
	})

	return (
		<ScheduleNavigationComponent
			control={control}
			onChange={onChange}
			currentMode={currentMode}
		/>
	)
}

export default ScheduleNavigation
