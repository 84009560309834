import React from 'react'
import { TimetableNavigationContainerProps } from 'containers/Timetable/TimetableNavigation'
import MonthNavigation from 'containers/Timetable/TimetableNavigation/MonthNavigation'
import ScheduleNavigation from 'containers/Timetable/TimetableNavigation/ScheduleNavigation'

import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'

import cl from './style.module.scss'

interface TimetableNavigationComponentProps
	extends TimetableNavigationContainerProps {}

const TimetableNavigation: React.FC<TimetableNavigationComponentProps> = ({
	control,
	onChange
}) => (
	<div className={cl.container}>
		<div className={cl.containerLeft}>
			<Button
				styleTypes={[ButtonStyles.DEFAULT_BG, ButtonStyles.ROUND]}
				onClick={() => onChange('currentDate')(new Date())}
			>
				Сегодня
			</Button>

			<MonthNavigation control={control} onChange={onChange} />
		</div>
		<div className={cl.containerRight}>
			<ScheduleNavigation control={control} onChange={onChange} />
		</div>
	</div>
)

export default TimetableNavigation
