import React, { FC } from 'react'

import { ITag } from 'UI/Tags/types'
import TagItem from 'containers/Courses/Tabs/ViewContent/About/Tags/TagItem'
import { ResponseEntityId } from 'types'

import cl from './style.module.scss'

interface TagsProps {
	tagsList: ITag[]
	courseDirections: ResponseEntityId[]
}

const Tags: FC<TagsProps> = ({ tagsList, courseDirections }) => {
	let chosenTags: ITag['id'][] = courseDirections
	if (!chosenTags) {
		chosenTags = []
	}
	return (
		<div className={cl.tagList}>
			{tagsList.map((tag) => (
				<TagItem
					isActive={chosenTags.includes(tag.id)}
					key={tag.id}
					tag={tag}
				/>
			))}
		</div>
	)
}

export default Tags
