import React, { FC, useCallback, useEffect, useState } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch
} from 'react-hook-form'
import isURL from 'validator/lib/isURL'

import MainFormComponent from 'components/Courses/Entities/Lessons/Tabs/Content/MainForm'
import {
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import { getFormData } from 'helpers'
import { LessonFormState } from 'pages/Course/Lesson/Create.page'
import { parseIdFromFileName } from 'helpers/files'
import { useNavigate, useParams } from 'react-router-dom'
import lessonQuery from 'store/queries/lesson.query'
import { CreateLessonResponse } from 'store/queries/lesson.query/types'
import { useActions, useAppDispatch, useAppSelector } from 'hooks/redux'
import courseQuery from 'store/queries/course.query'
import { kinescopeQuery } from 'store/queries'
import { UploadMediaToKinescopeResponse } from 'store/queries/kinescope.query/types'
import { VideoExtensions } from 'UI/DragAndDrop/types'
import { getMediaTypeFormatByFileName } from 'helpers/getMediaTypeFormatByFileName'
import { LessonDoc } from 'types/models/lesson.model'

export type MainFormTabState = Pick<
	LessonFormState,
	| 'id'
	| 'name'
	| 'description'
	| 'docsFilesInfo'
	| 'mediaFilesInfo'
	| 'mediaFiles'
	| 'docsFiles'
	| 'mediaFilesInfoTitleList'
	| 'docsFilesInfoTitleList'
	| 'mediaFilesUrlList'
	| 'docsFilesUrlList'
	| 'videoTitle'
	| 'videoEmbedLink'
	| 'videoId'
	| 'lessonResponsibilityStatus'
>

interface MainFormTabProps {
	errors: FieldErrors<MainFormTabState>
	onChange: (name: FieldPath<MainFormTabState>) => (value: any, deleteFileName?: string) => void
	control: Control<MainFormTabState>
	register: UseFormRegister<MainFormTabState>
	handleSubmit: UseFormHandleSubmit<MainFormTabState>
	watch: UseFormWatch<MainFormTabState>
	setValue: UseFormSetValue<MainFormTabState>
	getValues: UseFormGetValues<MainFormTabState>
	isEditMode?: boolean
	onOpenCanceledChange: () => void
}

const MainFormTab: FC<MainFormTabProps> = ({
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	watch,
	setValue,
	isEditMode,
	getValues,
	onOpenCanceledChange
}) => {
	const { pushSuccess, pushError } = useActions((state) => state.system)
	const [loading, setLoading] = useState(false)
	const dispatch = useAppDispatch()
	const kinescopeData = useAppSelector((state) => state.kinescope.kinescopeData)
	const { remainingStorage } = useAppSelector((state) => state.kinescope)

	const { courseId, sectionId } = useParams()

	const [createLesson] = lessonQuery.useCreateLessonMutation()
	const [changeLesson] = lessonQuery.useChangeLessonMutation()
	const [addVideoLink] = lessonQuery.useAddVideoLinkMutation()
	const [uploadVideo] = kinescopeQuery.useUploadMediaToKinescopeMutation()
	const navigate = useNavigate()

	const mediaFilesWatcher = watch('mediaFiles')
	const docsFilesWatcher = watch('docsFiles')

	const mediaFilesInfoWatcher = watch('mediaFilesInfo')
	const docsFilesInfoWatcher = watch('docsFilesInfo')

	useEffect(() => {
		if (mediaFilesWatcher === undefined) {
			return
		}
		; (async () => {
			setValue(
				'mediaFilesInfo',
				await Promise.all(
					(mediaFilesWatcher || []).map(async (file) => {
						const [, name] = parseIdFromFileName(file.name)

						return {
							url: URL.createObjectURL(file),
							title: name,
							type: getMediaTypeFormatByFileName(file.name)
						}
					})
				)
			)
		})()

	}, [mediaFilesWatcher])

	useEffect(() => {
		if (docsFilesWatcher === undefined) {
			return
		}
		const filesArr: LessonDoc[] = []
		const filesInfo = async () => (
			Promise.all(
				(docsFilesWatcher || []).map(async (file) => {
					const dublicate = docsFilesInfoWatcher?.findIndex(docFile => docFile.title === file.name)
					if (dublicate === -1) {
						const [, name] = parseIdFromFileName(file.name)
						filesArr.push({
							title: name,
							url: URL.createObjectURL(file)
						})
					}
				})
			)
		)

		filesInfo()
			; (async () => {
				setValue(
					'docsFilesInfo',
					[...filesArr, ...docsFilesInfoWatcher || []]
				)
			})()
	}, [docsFilesWatcher])


	useEffect(() => {
		register('name', {
			required: ValidationErrorMessages.EMPTY,
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('description', {
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('mediaFiles')
		register('docsFiles')
		register('docsFilesInfo')
		register('mediaFilesInfo')
		register('docsFilesInfoTitleList')
		register('mediaFilesInfoTitleList')
		register('mediaFilesUrlList.url', {
			validate: (value) =>
				!value || isURL(value) || ValidationErrorMessages.INCORRECT
		})
		register('docsFilesUrlList')
		return () => {
			register('name', {
				required: false,
				minLength: undefined,
				pattern: undefined
			})
			register('description', {
				required: false,
				minLength: undefined,
				pattern: undefined
			})
		}
	}, [])

	const submitCreateHandler = useCallback(
		handleSubmit(
			async ({
				mediaFiles,
				docsFiles,
				docsFilesInfo,
				mediaFilesInfo,
				description,
				name,
				mediaFilesInfoTitleList,
				docsFilesInfoTitleList,
				docsFilesUrlList,
				mediaFilesUrlList
			}) => {
				function isStorageFull() {
					return (
						mediaFiles &&
						mediaFiles.length &&
						remainingStorage < mediaFiles[0].size
					)
				}

				const fileExt =
					mediaFiles && mediaFiles[0] && mediaFiles[0].name.split('.').at(-1)

				if (isStorageFull() && Object.values(VideoExtensions).includes(fileExt as VideoExtensions)) {
					pushError({
						message: ValidationErrorMessages.TARIFF_CAPACITY_OVERFLOW
					})
					return
				}

				setLoading(true)

				const response = await createLesson({
					body: await getFormData({
						name: name && name.trim(),
						description: description && description.trim(),
						mediaFiles: mediaFiles?.filter(
							() =>
								!Object.values(VideoExtensions).includes(
									fileExt as VideoExtensions
								)
						),
						docsFiles,
						docsFilesInfo,
						mediaFilesInfo,
						mediaFilesInfoTitleList,
						docsFilesInfoTitleList,
						docsFilesUrlList: JSON.stringify(docsFilesUrlList),
						mediaFilesUrlList: JSON.stringify(mediaFilesUrlList)
					}),
					sectionId: +`${sectionId}`
				})
				const { error } = response as unknown as ResponseWithError
				if (error) {
					return
				}

				const { data } = response as unknown as CreateLessonResponse

				dispatch(courseQuery.util.invalidateTags(['course']))

				if (
					mediaFiles &&
					mediaFiles.length &&
					Object.values(VideoExtensions).includes(fileExt as VideoExtensions)
				) {
					const uploadResponse = await uploadVideo({
						body: mediaFiles ? mediaFiles[0] : undefined,
						title: `${mediaFiles ? mediaFiles[0].name : ''}`,
						folderId: data.folderId,
						projectId: data.projectId,
						lessonId: data.lessonId,
						token: data.token
					})

					const { data: uploadData } =
						uploadResponse as unknown as UploadMediaToKinescopeResponse

					setValue('videoTitle', uploadData.data.title)

					const addVideoResponse = await addVideoLink({
						body: {
							videoEmbedLink: uploadData.data.embed_link,
							videoId: uploadData.data.id,
							videoTitle: uploadData.data.title
						},
						lessonId: +data.lessonId
					})

					const { error: addVideoError } =
						addVideoResponse as unknown as ResponseWithError

					if (addVideoError) {
						return
					}
				}

				navigate(`/course/${courseId}/lesson/edit/${data.lessonId}`)
				const successMessage = {
					message: ValidationSuccessMessages.SUCCESS_CHANGE
				}
				pushSuccess(successMessage)
				setLoading(false)
			}
		),
		[handleSubmit]
	)

	const submitEditHandler = useCallback(
		handleSubmit(
			async ({
				mediaFiles,
				docsFiles,
				docsFilesInfo,
				mediaFilesInfo,
				description,
				mediaFilesInfoTitleList,
				docsFilesInfoTitleList,
				docsFilesUrlList,
				mediaFilesUrlList,
				name,
				videoTitle,
				videoEmbedLink,
				videoId,
				lessonResponsibilityStatus
			}) => {
				function isStorageFull() {
					return (
						mediaFiles &&
						mediaFiles.length &&
						remainingStorage < mediaFiles[0].size
					)
				}

				console.log(lessonResponsibilityStatus)

				const fileExt =
					mediaFiles && mediaFiles[0] && mediaFiles[0].name.split('.').at(-1)

				if (isStorageFull() && Object.values(VideoExtensions).includes(fileExt as VideoExtensions)) {
					pushError({
						message: ValidationErrorMessages.TARIFF_CAPACITY_OVERFLOW
					})
					return
				}
				const lessonId = getValues('id')

				let kinescopeBody = {}

				setLoading(true)

				if (
					(mediaFilesInfo && mediaFilesInfo[0]?.title === videoTitle) ||
					(mediaFiles && mediaFiles[0]?.name === videoTitle)
				) {
					kinescopeBody = {
						videoEmbedLink,
						videoId,
						videoTitle
					}
				}

				if (
					mediaFiles &&
					mediaFiles.length &&
					Object.values(VideoExtensions).includes(fileExt as VideoExtensions) &&
					mediaFiles[0].name !== getValues('videoTitle')
				) {
					const uploadResponse = await uploadVideo({
						body: mediaFiles ? mediaFiles[0] : undefined,
						title: `${mediaFiles && mediaFiles.length ? mediaFiles[0].name : ''
							}`,
						folderId: `${kinescopeData.folderId}`,
						projectId: `${kinescopeData.projectId}`,
						lessonId: `${lessonId}`,
						token: `${kinescopeData.token}`
					})

					const { data: uploadData } =
						uploadResponse as unknown as UploadMediaToKinescopeResponse

					kinescopeBody = {
						videoEmbedLink: uploadData.data.embed_link,
						videoId: uploadData.data.id,
						videoTitle: uploadData.data.title
					}
				}

				const audioFiles = mediaFiles?.filter(
					() =>
						!Object.values(VideoExtensions).includes(fileExt as VideoExtensions)
				)

				const response = await changeLesson({
					body: await getFormData({
						name: name.trim(),
						description: description.trim(),
						mediaFiles: audioFiles,
						docsFiles,
						// docsFilesInfo: JSON.stringify(docsFilesInfo),
						mediaFilesInfo: JSON.stringify(mediaFilesInfo),
						mediaFilesInfoTitleList,
						docsFilesInfoTitleList: JSON.stringify(docsFilesInfoTitleList),
						docsFilesUrlList: JSON.stringify(docsFilesUrlList),
						mediaFilesUrlList: JSON.stringify(mediaFilesUrlList),
						lessonResponsibilityStatus,
						...kinescopeBody
					}),
					lessonId: +`${lessonId}`
				})
				const { error } = response as unknown as ResponseWithError

				if (error) {
					return
				}

				const successMessage = {
					message: ValidationSuccessMessages.SUCCESS_CHANGE
				}

				setValue('docsFilesInfoTitleList', [])
				setValue('docsFiles', [])
				pushSuccess(successMessage)
				setLoading(false)
			}
		),
		[handleSubmit, kinescopeData, remainingStorage]
	)

	return (
		<MainFormComponent
			setValue={setValue}
			onOpenCanceledChange={onOpenCanceledChange}
			control={control}
			onChange={onChange}
			onSubmit={isEditMode ? submitEditHandler : submitCreateHandler}
			errors={errors}
			mediaFilesInfo={mediaFilesInfoWatcher}
			docsFilesInfo={docsFilesInfoWatcher}
			isLoading={loading}
		/>
	)
}

export default MainFormTab
