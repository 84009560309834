import React, { FC, useCallback, useRef, useState } from 'react'
import WeekTableCellComponent from 'components/Timetable/Tabs/WeekTab/WeekTableCell'
import { IEvent } from 'types/models/event.model'

export interface WeekTableCellContainerProps {
	highlighted?: boolean
	large?: boolean
	events?: IEvent[]
	onHoverIndexChange?: (_: number | null) => void
	index?: number
	collapsed: boolean
	borderBottom?: boolean
	title?: string
	onDetailsOpen: (_: IEvent) => void
}

const WeekTableCell: FC<WeekTableCellContainerProps> = ({
	highlighted,
	large,
	events,
	onHoverIndexChange,
	index,
	collapsed,
	borderBottom,
	title,
	onDetailsOpen
}) => {
	const cellRef = useRef<HTMLDivElement>(null)
	const popupRef = useRef<HTMLDivElement>(null)

	const [open, setOpen] = useState(false)
	const [activeEvents, setActiveEvents] = useState<IEvent[]>([])
	const [activeDate, setActiveDate] = useState<Date>(new Date())

	const onSeeAllEvents = useCallback((popupEvents: IEvent[], date: Date) => {
		setOpen(true)
		setActiveDate(date)
		setActiveEvents(popupEvents)
	}, [])

	const onHideRestOfEvents = useCallback(() => {
		setOpen(false)
	}, [])
	const sortedEvents=events?.sort(
		(a, b) => +a.time.split(':')[0] - +b.time.split(':')[0]) ?? []

	return (
		<WeekTableCellComponent
			highlighted={highlighted}
			large={large}
			events={sortedEvents}
			onSeeAllEvents={onSeeAllEvents}
			onHideRestOfEvents={onHideRestOfEvents}
			open={open}
			eventsDate={activeDate}
			activeEvents={activeEvents}
			onHoverIndexChange={onHoverIndexChange}
			index={index}
			collapsed={collapsed}
			borderBottom={borderBottom}
			title={title}
			cellRef={cellRef}
			popupRef={popupRef}
			onDetailsOpen={onDetailsOpen}
		/>
	)
}

export default WeekTableCell
