import React, { FC, MutableRefObject, Dispatch } from 'react'
import { BreakPoint } from 'types'
import Tab from './Tab'
import { ITab } from './types'

import cl from './style.module.scss'

interface TabsProps {
	value: ITab['id']
	tabs: ITab[]
	onChange: (tabId: ITab['id']) => void
	disabled?: boolean
	scrollContainerRef?: MutableRefObject<HTMLDivElement> | null
	setActiveTab?: Dispatch<React.SetStateAction<string>>
}

const Tabs: FC<TabsProps> = ({
	tabs,
	value: selectedId,
	onChange,
	disabled,
	scrollContainerRef,
	setActiveTab
}) => {
	const getRef = (ref: MutableRefObject<HTMLDivElement>) => {
		if (
			scrollContainerRef &&
			window.screen.width <= BreakPoint.TabletVerySmall
		) {
			const leftPadding = parseFloat(
				getComputedStyle(scrollContainerRef.current).paddingLeft
			)
			scrollContainerRef.current.scrollLeft =
				ref.current.offsetLeft - leftPadding
		}
	}

	const clickHandler = (tabId: ITab['id'], activeTab: string) => {
		// eslint-disable-next-line
		setActiveTab && setActiveTab(activeTab)
		if (tabId === selectedId) {
			return
		}

		onChange(tabId)
	}

	return (
		<div className={cl.tabs}>
			{tabs.map((tab) => (
				<Tab
					disabled={disabled}
					tab={tab}
					isActive={tab.id === selectedId}
					key={tab.id}
					onClick={clickHandler}
					getRef={getRef}
				/>
			))}
		</div>
	)
}

export default Tabs
