import { useEffect, useState } from 'react'
import { useActions } from 'hooks/redux'
import { ValidationErrorMessages, ValidationSuccessMessages } from 'types'

const useCheckOnline = () => {
	const [online, setOnline] = useState(true)
	const [changed, setChanged] = useState(false)

	const { pushSuccess, pushError } = useActions((state) => state.system)

	useEffect(() => {
		const onOnline = () => {
			setOnline(true)
			setChanged(true)
		}
		const onLooseConnection = () => {
			setOnline(false)
			setChanged(true)
		}

		window.addEventListener('online', onOnline)
		window.addEventListener('offline', onLooseConnection)

		return () => {
			window.removeEventListener('online', onOnline)
			window.removeEventListener('offline', onLooseConnection)
		}
	}, [])

	useEffect(() => {
		if (!changed) return

		if (online) {
			pushSuccess({ message: ValidationSuccessMessages.CONNECTION_RESTORED })
		} else {
			pushError({ message: ValidationErrorMessages.CONNECTION_LOST })
		}
	}, [changed, online])

	return { changed, online }
}

export default useCheckOnline
