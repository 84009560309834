import React, { FC, useCallback } from 'react'

import TextInput from './TextInput'
import { TextInputProps } from './types'

interface EmailInputProps extends TextInputProps {}

const NumberInput: FC<EmailInputProps> = (props) => (
	<TextInput type="number" {...props} />
)

export default NumberInput
