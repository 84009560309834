import React from 'react'

import CourseEventItem from 'components/NotificationSettingsForm/NotificationSettingsTable/EventItem/CourseEventItem'
import EventItemComponent from 'components/NotificationSettingsForm/NotificationSettingsTable/EventItem'
import { Control, FieldPath } from 'react-hook-form'
import { useMatchMedia } from 'hooks'
import { EventFormItem, SettingsFormValues } from '..'

export interface EventItemContainerProps extends EventFormItem {
	control: Control<SettingsFormValues>
	index: number
	onChange: (name: FieldPath<SettingsFormValues>) => (val: any) => void
	disabled: boolean
	courseSettings?: boolean
}

const EventItem: React.FC<EventItemContainerProps> = ({
	email,
	eventId,
	push,
	curator,
	student,
	teacher,
	text,
	control,
	index,
	disabled,
	courseSettings,
	onChange
}) => {
	const { isMobile } = useMatchMedia()

	return (
		<>
			{courseSettings ? (
				<CourseEventItem
					curator={curator}
					student={student}
					teacher={teacher}
					eventId={eventId}
					text={text}
					control={control}
					index={index}
					disabled={disabled}
					onChange={onChange}
					isMobile={isMobile}
				/>
			) : (
				<EventItemComponent
					email={email}
					eventId={eventId}
					push={push}
					text={text}
					control={control}
					index={index}
					disabled={disabled}
					onChange={onChange}
					isMobile={isMobile}
				/>
			)}
		</>
	)
}

export default EventItem
