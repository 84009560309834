import { ResponseEntityId } from 'types'

enum LessonResponsibilityStatus {
	REQUIRED = 1,
	OPTIONAL
}

export enum LessonMediaFiles {
	AUDIO = 'audio',
	VIDEO = 'video'
}
export interface LessonFile {
	title: string
	url?: string
}

interface LessonMedia extends LessonFile {
	type: LessonMediaFiles
}
export interface LessonDoc extends LessonFile { }

interface MediaFilesUrlList {
	url: string
	type: '1' | '2'
}
export interface DocsFilesUrlList {
	url: string
	name: string
}

export interface ILesson {
	id?: ResponseEntityId
	name: string
	description: string
	lessonResponsibilityStatus: LessonResponsibilityStatus
	mediaFilesInfo?: LessonMedia[]
	docsFilesInfo?: LessonDoc[]
	mediaFilesInfoTitleList: string[]
	docsFilesInfoTitleList: string[]
	mediaFilesUrlList: MediaFilesUrlList
	docsFilesUrlList: DocsFilesUrlList[]
	mediaFiles?: File[]
	docsFiles?: File[]
	isActive: number
	videoEmbedLink?: string
	videoId?: string
	videoTitle: string
	mediaFilesInfoWatcher?: LessonMedia[]
}
