import React, { FC } from 'react'

import { Button } from 'UI'
import { ButtonStyles, ButtonTextStyles } from 'UI/Button/types'
import CourseStatus from 'containers/Courses/StudentRole/Tabs/Content/CourseStatus'
import { CertificateStatus, CoursePassStatus } from 'types/models/course.model'
import { CourseItemProps as CourseItemContainerProps } from 'containers/Courses/StudentRole/Tabs/Content/CourseItem'

import cl from 'components/Courses/StudentRole/Content/style.module.scss'
import { ResponseEntityId } from 'types'
import schoolHatImage from 'components/Courses/StudentRole/Content/images/school-hat.png'
import { parseStringToTagA } from 'helpers/parseStringToTagA'

interface CourseItemProps extends CourseItemContainerProps {
	onClick: (courseId: ResponseEntityId) => void
	onGetCertificate: () => void
	courseStatus: CoursePassStatus
	certificateStatus: CertificateStatus
	certificateAsAResult: boolean
	veiwAvailable?: boolean
}

const CourseItem: FC<CourseItemProps> = ({
	course,
	onClick,
	onGetCertificate,
	courseStatus,
	completedMode,
	certificateStatus,
	certificateAsAResult,
	veiwAvailable
}) => (
	<div className={cl.item}>
		<div className={cl.itemHeader}>
			<div className={cl.courseImg}>
				<img src={course.coverUrl || schoolHatImage} alt={course.coverUrl} />
			</div>
			<div className={cl.courseImgMobile}>
				<h2 className={cl.courseNameMobile}>{course.name}</h2>
				<div className={cl.courseStatusMobile}>
					<CourseStatus status={courseStatus} />
				</div>
			</div>
		</div>

		<div className={cl.courseDesc}>
			<div className={cl.textContent}>
				<h2 className={cl.courseName}>{course.name}</h2>
				<div className={cl.courseStatus}>
					<CourseStatus status={courseStatus} />
				</div>
				<div className={cl.courseDescr}>
					{course.description.split('\n').map((text) => (
						<p key={text}>{parseStringToTagA(text, '_blank')}</p>
					))}
				</div>
			</div>
			{!completedMode ? (
				<Button
					onClick={() => onClick(course.id)}
					className={cl.courseButton}
					styleTypes={[
						ButtonStyles.PRIMARY,
						ButtonStyles.ROUND,
						ButtonTextStyles.MEDIUM
					]}
				>
					{courseStatus === CoursePassStatus.PROCESS ? 'Продолжить' : 'Начать'}{' '}
					обучение
				</Button>
			) : (
				<div className={cl.buttons}>
					{veiwAvailable && (
						<Button
							onClick={() => onClick(course.id)}
							styleTypes={[ButtonStyles.DEFAULT_BG]}
						>
							Просмотреть содержание
						</Button>
					)}


					{certificateAsAResult && (
						<Button
							styleTypes={[ButtonStyles.PRIMARY_HOLLOW]}
							onClick={onGetCertificate}
						>
							{certificateStatus === CertificateStatus.GOTTEN
								? 'Скачать'
								: 'Получить'}{' '}
							сертификат
						</Button>
					)}
				</div>
			)}
		</div>
	</div>
)

export default CourseItem
