import React, { FC, useCallback } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormHandleSubmit,
	UseFormSetValue
} from 'react-hook-form'

import CreateSectionModalComponent from 'components/Courses/Tabs/Content/ContentForm/modals/CreateSection'
import courseQuery from 'store/queries/course.query'
import { SectionFormState } from 'containers/Courses/Tabs/Content/ContentForm'
import { ResponseEntityId } from 'types'

export interface CreateSectionModalProps {
	isModalOpen: boolean
	closeModal: () => void
	control: Control<SectionFormState>
	errors: FieldErrors<SectionFormState>
	onChange: (name: FieldPath<SectionFormState>) => (value: any) => void
	handleSubmit: UseFormHandleSubmit<SectionFormState>
	courseId: ResponseEntityId
	setValue: UseFormSetValue<SectionFormState>
}

const CreateSectionModal: FC<CreateSectionModalProps> = ({
	closeModal,
	isModalOpen,
	control,
	errors,
	onChange,
	handleSubmit,
	courseId,
	setValue
}) => {
	const [createSection] = courseQuery.useAddCourseSectionMutation()
	const submitHandler = useCallback(
		handleSubmit(({ sectionName }) => {
			createSection({
				courseId,
				name: sectionName
			})
			setValue('sectionName', '')
			closeModal()
		}),
		[]
	)

	return (
		<>
			<CreateSectionModalComponent
				onChange={onChange}
				errors={errors}
				control={control}
				onSubmit={submitHandler}
				isModalOpen={isModalOpen}
				closeModal={closeModal}
				courseId={courseId}
				handleSubmit={handleSubmit}
			/>
		</>
	)
}

export default CreateSectionModal
