import React, { FC } from 'react'
import TaskViewComponent from 'components/Courses/Entities/Tasks/View'
import { ITask, ITaskAnswerResponse } from 'types/models/task.model'
import { Link } from 'UI'
import { LinkStyles } from 'UI/Link/types'
import { ResponseEntityId } from 'types'
import { CommunicationsSettings } from 'types/models/course.model/settings/communications.model'

import cl from 'components/Courses/Entities/Tasks/View/style.module.scss'
import { ISectionItem } from 'containers/Courses/Tabs/Content/ContentForm/Table'
import NavigationThroughLessons from 'UI/NavigationThroughLessons'

export interface TaskViewProps {
	task: ITask<string>
	courseId: ResponseEntityId
	communicationsInfo: CommunicationsSettings
	taskAnswersAttempts: ITaskAnswerResponse[]
	mappedItems?: ISectionItem[]
	isMobile: boolean
}

const TaskView: FC<TaskViewProps> = ({
	task,
	courseId,
	communicationsInfo,
	taskAnswersAttempts,
	mappedItems,
	isMobile
}) => (
	<div className={cl.taskPage}>
		<div className={cl.back}>
			<Link styleTypes={[LinkStyles.GO_BACK]} href={`/course/view/${courseId}`}>
				Вернуться назад к курсу
			</Link>
		</div>
		<NavigationThroughLessons
			courseId={courseId}
			currentId={task.id!}
			itemsArray={mappedItems || []}
			isMobile={isMobile}
		/>
		<TaskViewComponent
			taskAnswersAttempts={taskAnswersAttempts}
			communicationsInfo={communicationsInfo}
			courseId={courseId}
			task={task}
		/>
	</div>
)

export default TaskView
