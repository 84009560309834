import React, { FC } from 'react'
import { Control } from 'react-hook-form'

import { QuestionsListPreviewProps as QuestionsListPreviewContainerProps } from 'containers/Courses/Entities/Tests/Preview/QuestionsListPreview'
import QuestionsTabs from 'components/Courses/Entities/Tests/Preview/QuestionsListPreview/Tabs'
import { QuestionTabs } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import AnswersList from 'containers/Courses/Entities/Tests/Preview/QuestionsListPreview/AnswersList'

import cl from './style.module.scss'

interface QuestionsListPreviewProps
	extends Omit<QuestionsListPreviewContainerProps, 'control'> {
	fields: Record<'id', string>[]
	tabsList: QuestionTabs[]
	selectedQuestionTabId: QuestionTabs['id']
	onSelectTab: (tabId: QuestionTabs['id']) => void
	control: Control<any>
	viewMode?: boolean
	onSubmit?: () => void
}

const QuestionsListPreview: FC<QuestionsListPreviewProps> = ({
	getValues,
	passLimitTime,
	fields,
	tabsList = [],
	selectedQuestionTabId,
	onSelectTab,
	control,
	onChange,
	onSubmit,
	viewMode
}) => (
	<div className={cl.questionsListPreview}>
		<div className={cl.header}>
			<h1>{getValues('name')}</h1>
		</div>
		<div className={cl.questionsMenu}>
			<div className={cl.menuInfo}>
				<div className={cl.menuItem}>
					<span>Тип тестирования</span>
					<span>{getValues('type') === 1 ? 'Промежуточное' : 'Итоговое'}</span>
				</div>
				<div className={cl.menuItem}>
					<span>Оставшееся время</span>
					<span>
						<>{passLimitTime.hours ? `${passLimitTime.hours}:` : '00:'}</>
						<>{passLimitTime.minutes ? `${passLimitTime.minutes}:` : '00:'}</>
						<>{passLimitTime.seconds ? passLimitTime.seconds : '00'}</>
					</span>
				</div>
			</div>
			<QuestionsTabs
				onSelectTab={onSelectTab}
				tabs={tabsList}
				selectedTabId={selectedQuestionTabId}
				onSubmit={onSubmit}
			/>
		</div>
		{fields.reduce(
			(acc, field, fieldIndex) =>
				+fieldIndex === +selectedQuestionTabId ? (
					<AnswersList
						onChange={onChange}
						onSelect={onSelectTab}
						control={control}
						getValues={getValues}
						key={field.id}
						name={`questions.${fieldIndex}`}
					/>
				) : (
					acc
				),
			<></>
		)}
	</div>
)

export default QuestionsListPreview
