import React, { FC, useEffect, useState } from 'react'
import { Link } from 'UI'
import { Constants } from 'types'
import { LinkStyles, LinkTextStyles } from 'UI/Link/types'
import cl from './style.module.scss'


const Policy: FC = () => {
    const [policy, setPolicy] = useState<{ title: string, text: string }>()

    useEffect(() => {
        fetch(`${Constants.API_BASE_URL}/selects/privacy-policy`)
            .then(response => response.json())
            .then(({ data }: { data: { title: string, text: string } }) => setPolicy({ title: data.title, text: data.text }))
    }, [])

    return (
        <div className={cl.policy}>
            <div className={cl.policy_container}>
                <Link className={cl.policy_link} href='/' styleTypes={[LinkStyles.GO_BACK, LinkTextStyles.MEDIUM]}>Вернуться назад</Link>
                <h1 className={cl.policy_title}>{policy?.title}</h1>
                <p>{policy?.text}</p>
            </div>
        </div>
    )
}




export default Policy