import React, { FC, useEffect } from 'react'
import { AnswersSortComponent } from 'components/Courses/Entities/Tasks/AnswersSortComponent'
import {
	ITask,
	ITaskAnswerResponse,
	FilterValue,
	SortValue
} from 'types/models/task.model'
import {
	FieldPath,
	UseFormSetValue,
	Control,
	UseFormRegister
} from 'react-hook-form'
import { useActions } from 'hooks/redux'
import { useNavigate } from 'react-router-dom'
import { AnswerSortValues } from '../TaskAnswersCheck/TaskAnswersCheck'

interface AnswersSortProps {
	task: ITask<string>
	courseId: number
	control: Control<AnswerSortValues>
	setValue: UseFormSetValue<AnswerSortValues>
	register: UseFormRegister<AnswerSortValues>
	taskAnswersInfo: ITaskAnswerResponse[]
	totalCount?: number
}

const AnswersSort: FC<AnswersSortProps> = ({
	task,
	courseId,
	setValue,
	control,
	register,
	taskAnswersInfo,
	totalCount
}) => {
	const { setCreatorId, setCurrentTaskAnswer } = useActions(
		(state) => state.task
	)

	const navigate = useNavigate()
	const lastTaskAnswerIndex = taskAnswersInfo.length - 1
	const navigateToFirstTaskinList = () => {
		setCreatorId(taskAnswersInfo[lastTaskAnswerIndex].creatorId)
		setCurrentTaskAnswer(taskAnswersInfo[lastTaskAnswerIndex])
		navigate(
			`/course/${courseId}/task/${task.id}/answer/attempt/view/${taskAnswersInfo[lastTaskAnswerIndex].id}`
		)
	}

	const handleChange =
		(name: FieldPath<AnswerSortValues>) => (value: string | number) => {
			setValue(name, value as string)
			setValue('page', 1)
		}

	useEffect(() => {
		; (async () => {
			setValue('filterStatus', FilterValue.AllANswers)
			setValue('sortType', 'sortDate-asc')
		})()
		register('filterStatus')
		register('sortType')
		register('page')
	}, [])

	const selectData = [
		{
			name: 'filterStatus',
			onChange: handleChange('filterStatus'),
			optionsList: [
				{ value: FilterValue.AllANswers, text: FilterValue.AllANswers },
				{ value: FilterValue.Verified, text: FilterValue.Verified },
				{ value: FilterValue.NeedCheck, text: FilterValue.NeedCheck }
			],
			control
		},
		{
			name: 'sortType',
			onChange: handleChange('sortType'),
			optionsList: [
				{ value: 'sortDate-asc', text: SortValue.New },
				{ value: 'sortDate-desc', text: SortValue.Old },
				{ value: 'sortAssessment-desc', text: SortValue.AssessmentMax },
				{ value: 'sortAssessment-asc', text: SortValue.AssessmentMin }
			],
			control
		}
	]

	return (
		<AnswersSortComponent
			name={task.name}
			description={task.description}
			navigateCb={navigateToFirstTaskinList}
			filterMatch={totalCount}
			selectData={selectData}
			totalCount={task.userAnswersIdList.length}
		/>
	)
}

export default AnswersSort
