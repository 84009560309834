import React, { FC } from 'react'
import { Control, FieldPath, UseFormGetValues } from 'react-hook-form'

import TestPreviewComponent from 'components/Courses/Entities/Tests/Preview'
import { TestFormState } from 'pages/Course/Test/Create.page'
import { getMillisecondsFromTime } from 'helpers/getMillisecondsFromTime'
import { useParams } from 'react-router-dom'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import courseQuery from 'store/queries/course.query'

interface TestPreviewProps {
	isTestPreview?: boolean
	isQuestionsListPreview: boolean
	setIsQuestionsListPreview: (_: boolean) => void
	getValues: UseFormGetValues<TestFormState>
	onChange: (name: FieldPath<TestFormState>) => (value: any) => void
	control: Control<any>
}

const TestPreview: FC<TestPreviewProps> = ({
	isTestPreview = true,
	isQuestionsListPreview,
	setIsQuestionsListPreview,
	getValues,
	control,
	onChange
}) => {
	const passLimitTime = getMillisecondsFromTime(
		getValues('passingTimeLimit.timeLimitSeconds')
	)
	if (!isTestPreview) {
		return <></>
	}

	const { courseId, testId } = useParams()

	if (!(courseId && testId)) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}
	const { data: courseInfo } = courseQuery.useGetCourseQuery(+courseId)

	if (!courseInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<TestPreviewComponent
			communicationInfo={courseInfo.data.communicationsSettings}
			onChange={onChange}
			isQuestionsListPreview={isQuestionsListPreview}
			setIsQuestionsListPreview={setIsQuestionsListPreview}
			passLimitTime={passLimitTime}
			getValues={getValues}
			control={control}
		/>
	)
}

export default TestPreview
