import React, { FC, ReactNode } from 'react'
import {
	MonthTableCellsInfo,
	MonthTableContainerProps
} from 'containers/Timetable/Tabs/MonthTab/MonthTable'

import { WeekDay } from 'types/models/day-event.model/day-event.model'
import WeekDays from 'containers/Timetable/Tabs/WeekTab/WeekDays'

import cl from './style.module.scss'

interface MonthTableComponentProps
	extends Omit<
		MonthTableContainerProps,
		'events' | 'currentDate' | 'onDetailsOpen'
	> {
	mappedWeekDays: WeekDay[]
	tableItems: ReactNode[]
}

const MonthTable: FC<MonthTableComponentProps> = ({
	mappedWeekDays,
	tableItems
}) => (
	<div className={cl.table}>
		<WeekDays mappedWeekDays={mappedWeekDays} short />

		{tableItems}
	</div>
)

export default MonthTable
