import React, { FC, useState, useEffect } from 'react'
import {
	ResponseEntityId,
	ResponseWithError,
	DEFAULT_TASK_REQUEST_COUNT
} from 'types'
import { AnswersSort } from 'containers/Courses/Entities/Tasks/AnswersSort'
import { Pagination } from 'UI/Pagination/Pagination'
import AttemptsTable from 'containers/Courses/Entities/Tasks/Check/AttemptsTable'
import { ITask } from 'types/models/task.model'
import {
	TaskGetAnswersResponse,
	TaskAnswersRequest
} from 'store/queries/task.query/types'
import { taskQuery } from 'store/queries'
import { useActions, useAppSelector } from 'hooks/redux'
import { useForm } from 'react-hook-form'

import cl from './taskAnswersCheck.module.scss'

interface TaskAnswersCheckProps {
	courseId: ResponseEntityId
	task: ITask<string>
}

type PaginationType = Pick<TaskAnswersRequest, 'limit' | 'page'>
type FilterBody = Pick<
	TaskAnswersRequest,
	'sortDate' | 'sortAssessment' | 'filterStatus' | 'page'
>

export interface AnswerSortValues {
	filterStatus?: string
	sortType: string
	page?: number
}

export const TaskAnswersCheck: FC<TaskAnswersCheckProps> = ({
	courseId,
	task
}) => {
	const { control, setValue, register, getValues, watch } =
		useForm<AnswerSortValues>()
	const { setTaskAnswerData, taskCurrentSort } = useActions(
		(state) => state.task
	)
	const { pushError } = useActions((state) => state.system)
	const [getAnswers] = taskQuery.useGetAnswersMutation()
	const { taskAnswersInfo, totalCount } = useAppSelector((state) => state.task)
	const [paginationLimits, setPaginationLimits] = useState<PaginationType>({
		limit: DEFAULT_TASK_REQUEST_COUNT,
		page: 1
	})
	const [watchValues, setWatchValues] = useState<FilterBody>()

	const handleSubmit = async (bodyData: FilterBody, page?: number) => {
		const body = {
			...bodyData,
			userAnswersIdList: task.userAnswersIdList.map((id) => id),
			limit: paginationLimits.limit,
			page: page || paginationLimits.page
		}

		const response = (await getAnswers(body)) as {
			data: TaskGetAnswersResponse
		}

		if (response) {
			setTaskAnswerData(response.data)
		}

		const { error } = response as unknown as ResponseWithError
		if (error) {
			pushError(error.data)
		}
	}

	const getSortValuesByDateOrAssessment = () => {
		if (Object.keys(getValues()).length) {
			const [sortKey, sortValue] = getValues().sortType.split('-')
			return {
				[sortKey]: sortValue
			}
		}
	}

	const setPaginationState = (page: number, limit: number) => {
		setValue('page', page)
		setPaginationLimits((prevState) => ({
			...prevState,
			page,
			limit
		}))
	}

	useEffect(() => {
		const requestBodyData = {
			...getSortValuesByDateOrAssessment(),
			filterStatus: getValues().filterStatus
		}

		handleSubmit(requestBodyData)
	}, [paginationLimits])

	useEffect(() => {
		const requestBodyData = {
			...getSortValuesByDateOrAssessment(),
			filterStatus: getValues().filterStatus,
			page: 1
		}

		if (JSON.stringify(requestBodyData) !== JSON.stringify(watchValues)) {
			setWatchValues(requestBodyData)
			setPaginationLimits((prevState) => ({
				...prevState,
				page: 1
			}))
			taskCurrentSort(requestBodyData as AnswerSortValues)
			handleSubmit(requestBodyData, 1)
		}
	}, [watch()])

	return (
		<div className={cl.answersGap}>
			<AnswersSort
				task={task}
				courseId={courseId}
				setValue={setValue}
				control={control}
				register={register}
				taskAnswersInfo={taskAnswersInfo}
				totalCount={totalCount}
			/>
			<AttemptsTable
				taskId={task.id!}
				userAnswersIdList={task.userAnswersIdList}
				courseId={courseId}
				taskAnswersInfo={taskAnswersInfo}
			/>
			{!!totalCount && (
				<Pagination
					onChange={(page, limit) => setPaginationState(page, limit)}
					total={totalCount}
					showSizeChanger
					pageSizeOptions={[12, 24, 36, 48]}
					current={paginationLimits.page || 1}
				/>
			)}
		</div>
	)
}
