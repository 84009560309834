import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { GetMillisecondsFromTimeResult } from 'helpers/getMillisecondsFromTime'
import QuestionListPassComponent, {
	TestPassFormState
} from 'components/Courses/Entities/Tests/View/QuestionsListPass/index'
import { ITest } from 'types/models/test.model'
import { QuestionTabs } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import { useFieldArray, useForm } from 'react-hook-form'
import testQuery from 'store/queries/test.query'
import { ResponseWithError } from 'types'
import { CreateAnswerAttemptResponse } from 'store/queries/test.query/types'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { useActions, useAppSelector } from 'hooks/redux'
import { DevTool } from '@hookform/devtools'
import { usePreventGoBack } from 'hooks'

interface QuestionsListPassProps {
	passLimitTime: GetMillisecondsFromTimeResult
	test: ITest
	setIsQuestionsPassMode: (_: boolean) => void
	onCheckUserAttempt: (attempt: number) => void
}

const QuestionsListPass: FC<QuestionsListPassProps> = ({
	passLimitTime,
	test,
	setIsQuestionsPassMode,
	onCheckUserAttempt
}) => {
	const { pushError } = useActions((state) => state.system)
	const { setIsFinalTestOngoing } = useActions((state) => state.test)
	const { isFinalTestOngoing } = useAppSelector((state) => state.test)

	const { haveTestPassingSuccess, haveTestPassingFailed } = useAppSelector(
		(state) => state.system
	)

	const [selectedQuestionTabId, setSelectedQuestionTabId] =
		useState<QuestionTabs['id']>(0)

	const [answerAttemptId, setAnswerAttemptId] = useState<number>()
	const [cancelled, setCancelled] = useState(false)

	const onCancelTest = useCallback(() => setCancelled(true), [setCancelled])

	const [timerIsOver, setTimerIsOver] = useState(false)

	const selectTabHandler = useCallback((tabId) => {
		setSelectedQuestionTabId(tabId)
	}, [])

	const questionsList = test.questions

	const tabsList = useMemo(
		(): QuestionTabs[] =>
			questionsList.map((question, fieldIndex) => ({
				id: fieldIndex,
				text: question.name
			})),
		[questionsList]
	)

	const {
		register,
		control,
		getValues: getFinalTestFormState
	} = useForm<TestPassFormState>()

	const { fields, append } = useFieldArray({ control, name: 'questionsPass' })

	const appendHandler = useCallback((questionId, correctAnswersIdList) => {
		append({ questionId, correctAnswersIdList })
	}, [])

	register('questionsPass')

	const [createAnswerAttempt] = testQuery.useCreateAnswerAttemptMutation()

	useEffect(() => {
		; (async () => {
			if (!test.id) {
				return
			}
			const response = await createAnswerAttempt({ testId: test.id })
			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}

			const { data } = response as unknown as {
				data: CreateAnswerAttemptResponse
			}

			setAnswerAttemptId(data.userAnswerId)
		})()
	}, [])

	usePreventGoBack(isFinalTestOngoing)

	useEffect(() => {
		if (!isFinalTestOngoing) {
			return
		}

		return () => {
			setIsFinalTestOngoing(false)
		}
	}, [isFinalTestOngoing])

	useEffect(() => {
		if (haveTestPassingSuccess || haveTestPassingFailed) {
			setTimerIsOver(true)
		}
	}, [haveTestPassingSuccess, haveTestPassingFailed])

	if (!answerAttemptId) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			<DevTool control={control} />

			<QuestionListPassComponent
				getFinalTestFormState={getFinalTestFormState}
				setIsQuestionsPassMode={setIsQuestionsPassMode}
				setTimerIsOver={setTimerIsOver}
				timerIsOver={timerIsOver}
				answerAttemptId={answerAttemptId}
				onAppend={appendHandler}
				fields={fields}
				test={test}
				selectedQuestionTabId={selectedQuestionTabId}
				onSelectTab={selectTabHandler}
				tabsList={tabsList}
				passLimitTime={passLimitTime}
				onSubmit={onCancelTest}
				cancelled={cancelled}
				onCheckUserAttempt={onCheckUserAttempt}
			/>
		</>
	)
}

export default QuestionsListPass
