import React, { FC, useCallback } from 'react'

import ReminderFormComponent from 'components/Courses/Tabs/Content/NotificationsForm/ReminderForm'
import { Control, FieldPath, FieldPathValue, useWatch } from 'react-hook-form'
import {
	NotificationsReminderFormValues,
	TimeMeasure
} from 'containers/Courses/Tabs/Content/NotificationsForm/index'
import { getUniqueId } from 'helpers'

export interface ReminderFormContainerProps {
	control: Control<NotificationsReminderFormValues>
	onChange: (
		name: FieldPath<NotificationsReminderFormValues>
	) => (
		value: FieldPathValue<NotificationsReminderFormValues, typeof name>
	) => void
	onSubmit: () => void
}

const ReminderForm: FC<ReminderFormContainerProps> = ({
	control,
	onChange,
	onSubmit
}) => {
	const watcher = useWatch({
		control,
		name: 'reminderDelaysDaysList'
	})

	const onAddReminderItem = useCallback(() => {
		onChange('reminderDelaysDaysList')([
			...watcher,
			{ id: getUniqueId(), timeMeasure: TimeMeasure.DAYS, inNumber: '1' }
		])
	}, [watcher])

	return (
		<ReminderFormComponent
			control={control}
			onChange={onChange}
			onSubmit={onSubmit}
			watcher={watcher}
			onAddReminderItem={onAddReminderItem}
		/>
	)
}

export default ReminderForm
