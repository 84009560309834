import React, { FC, Ref } from 'react'
import { WeekTableCellContainerProps } from 'containers/Timetable/Tabs/WeekTab/WeekTableCell'

import classNames from 'classnames'

import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { getTheEndOfTheWordByNumber, makeStringShorter } from 'helpers/text'
import EventsPopup from 'containers/Timetable/Tabs/WeekTab/EventsPopup'
import { IEvent } from 'types/models/event.model'
import cl from './style.module.scss'

interface TableCellComponentProps extends WeekTableCellContainerProps {
	onSeeAllEvents: (popupEvents: IEvent[], date: Date) => void
	onHideRestOfEvents: () => void
	open: boolean
	eventsDate: Date
	activeEvents: IEvent[]
	popupRef: Ref<HTMLDivElement>
	cellRef: Ref<HTMLDivElement>
}

const WeekTableCell: FC<TableCellComponentProps> = ({
	large,
	events = [],
	highlighted,
	onSeeAllEvents,
	onHideRestOfEvents,
	open,
	eventsDate,
	activeEvents,
	onHoverIndexChange,
	index,
	collapsed,
	borderBottom,
	title,
	popupRef,
	cellRef,
	onDetailsOpen
}) => (
	<div
		className={classNames(cl.cell, {
			[cl.large]: collapsed && large,
			[cl.highlighted]: highlighted && collapsed,
			[cl.borderBottom]: borderBottom
		})}
		onMouseEnter={() => onHoverIndexChange && onHoverIndexChange(index || 0)}
		onMouseLeave={() => onHoverIndexChange && onHoverIndexChange(null)}
		ref={cellRef}
	>
		{title && <div className={cl.header}>{title}</div>}

		{collapsed ? (
			<>
				{events.length <= 3
					? events.map((e) => (
							<div
								className={cl.label}
								key={e.id}
								onClick={() => onDetailsOpen(e)}
							>
								{makeStringShorter(e.theme, 13)}
							</div>
					  ))
					: events.slice(0, 3).map((e) => (
							<div
								className={cl.label}
								key={e.id}
								onClick={() => onDetailsOpen(e)}
							>
								{makeStringShorter(e.theme, 13)}
							</div>
					  ))}

				{events.length > 3 && (
					<Button
						styleTypes={[ButtonStyles.TERTIARY_BLUE]}
						className={cl.button}
						onClick={() =>
							onSeeAllEvents(events, new Date(events[0].dateEventStart))
						}
					>
						+ еще {events.length - 3}
					</Button>
				)}
			</>
		) : (
			<>
				{events.length > 0 && (
					<Button
						styleTypes={[ButtonStyles.TERTIARY_BLUE]}
						className={cl.button}
						onClick={() =>
							onSeeAllEvents(events, new Date(events[0].dateEventStart))
						}
					>
						{events.length}{' '}
						{getTheEndOfTheWordByNumber(events.length, [
							'событие',
							'события',
							'событий'
						])}
					</Button>
				)}
			</>
		)}

		<EventsPopup
			open={open}
			eventsDate={eventsDate}
			activeEvents={activeEvents}
			onDetailsOpen={onDetailsOpen}
			onHideRestOfEvents={onHideRestOfEvents}
			ref={popupRef}
		/>
	</div>
)

export default WeekTableCell
