import React, { FC, useEffect, useMemo } from 'react'

import { AsideBar as AsideBarComponent } from 'components'
import { AsideBarOption } from 'components/AsideBar/types'
import {
	CoursesIcon,
	TariffsIcon,
	TimetableIcon,
	UsersIcon
} from 'components/AsideBar/images'
import useAsideBarContext from 'contexts/AsideBar.context'
import { useMatchMedia } from 'hooks'
import { SchoolRole } from 'types'
import { useAppSelector } from 'hooks/redux'

interface AsideBarProps { }

export const iconsList = {
	CoursesIcon: <CoursesIcon />,
	UsersIcon: <UsersIcon />,
	TariffsIcon: <TariffsIcon />,
	TimetableIcon: <TimetableIcon />
}

const AsideBar: FC<AsideBarProps> = () => {
	const { tariffExpired } = useAppSelector((state) => state.tariffs)
	const { setAsideIsOpen } = useAsideBarContext()
	const { isTablet } = useMatchMedia()
	const { currentRole } = useAppSelector((state) => state.system)

	const asideBarOptionsList = useMemo<AsideBarOption[]>(() => {
		const tariffExpiredItems = [
			{
				id: 4,
				labelText: 'Тарифы',
				link: '/tariffs',
				icon: <TariffsIcon />
			}
		]
		const defaultItems = [
			{
				id: 1,
				labelText: 'Курсы',
				link: '/',
				icon: <CoursesIcon />
			},
			{
				id: 2,
				labelText: 'Расписание',
				link: '/timetable',
				icon: <TimetableIcon />
			}
		]
		const adminItems = [
			{
				id: 1,
				labelText: 'Курсы',
				link: '/',
				icon: <CoursesIcon />
			},
			{
				id: 2,
				labelText: 'Расписание',
				link: '/timetable',
				icon: <TimetableIcon />
			},
			{
				id: 3,
				labelText: 'Пользователи',
				link: '/user/list',
				icon: <UsersIcon />
			},
			{
				id: 4,
				labelText: 'Тарифы',
				link: '/tariffs',
				icon: <TariffsIcon />
			}
		]

		if (tariffExpired) {
			return tariffExpiredItems
		}

		if (currentRole === SchoolRole.ADMIN) {
			return adminItems
		}

		return defaultItems
	}, [currentRole])

	useEffect(() => {
		setAsideIsOpen(!isTablet)
	}, [isTablet])

	return <AsideBarComponent asideBarOptionsList={asideBarOptionsList} />
}

export default AsideBar
