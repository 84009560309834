import React, { FC, InputHTMLAttributes, useMemo } from 'react'

import { getUniqueId } from 'helpers'

import cl from './style.module.scss'

interface CheckboxProps
	extends Omit<
		InputHTMLAttributes<HTMLInputElement>,
		'value' | 'onChange' | 'name'
	> {
	name: string
	helperText?: string
	value: boolean
	disabled?: boolean
}

const PlainCheckbox: FC<CheckboxProps> = ({
	id,
	name,
	helperText,
	value,
	disabled,
	...defaultProps
}) => {
	const uniqueId = useMemo(getUniqueId.bind(null, id), [])

	return (
		<div className={cl.container}>
			<input
				checked={value}
				id={uniqueId}
				type="checkbox"
				disabled={disabled}
				name={name}
				{...defaultProps}
			/>
		</div>
	)
}

export default PlainCheckbox
