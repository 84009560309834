import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'hooks/redux'
import { ResponseEntityId } from 'types'

import { Button, Modal } from 'UI'
import { ModalHeight } from 'UI/Modal/types'
import { ButtonStyles } from 'UI/Button/types'

import { isBoolean } from 'helpers/isBoolean'
import { Question } from 'types/models/test.model'
import { ITestAnswer } from 'store/queries/test.query/types'
import { QuestionTabs } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import QuestionTab from './Tab'
import { QuestionListAnswersFormState } from '../../../View/QuestionsListPass'

import cl from './style.module.scss'

interface QuestionsTabsProps {
	selectedTabId?: QuestionTabs['id']
	tabs: QuestionTabs[]
	onSelectTab: (tabId: QuestionTabs['id']) => void
	fields?: QuestionListAnswersFormState[]
	questions?: Question[]
	usersTests?: ITestAnswer[]
	answerAttemptId?: ResponseEntityId
	setCorrectAnswersAmount?: (_: number) => void
	testType?: number
	onSubmit?: () => void
	viewMode?: boolean
}

const QuestionsTabs: FC<QuestionsTabsProps> = ({
	selectedTabId,
	onSelectTab,
	tabs,
	fields,
	questions,
	usersTests,
	answerAttemptId,
	setCorrectAnswersAmount,
	testType,
	onSubmit,
	viewMode
}) => {
	const [modalOpen, setModalOpen] = useState(false)
	const { isFinalTestOngoing } = useAppSelector((state) => state.test)
	const clickHandler = (tabId: QuestionTabs['id']) => {
		if (tabId === selectedTabId) {
			return
		}

		onSelectTab(tabId)
	}

	const onModalClose = useCallback(() => {
		setModalOpen(false)

		if (onSubmit) {
			onSubmit()
		}
	}, [setModalOpen, onSubmit])

	const correctAnswersToQuestions = useMemo(() => {
		if (!questions || (!fields && !usersTests)) return

		let arr: QuestionListAnswersFormState[] = []

		if (usersTests && answerAttemptId) {
			arr =
				usersTests.find((a) => a.id.toString() === answerAttemptId.toString())
					?.userAnswers || []
		} else if (fields) {
			arr = fields
		}

		return arr.map((f) => {
			const foundQuestion = questions.find(
				(q) => q.id === ('questionId' in f ? f.questionId : 0)
			)
			if (!foundQuestion) return

			const answersSent =
				'correctAnswersIdList' in f ? f.correctAnswersIdList : []
			const answersCorrect = foundQuestion.correctAnswersIdList
			const foundTab = tabs.find((t) => t.text === foundQuestion.name)
			if (!foundTab) {
				console.log('no tab found')
				return
			}

			let areAllCorrect: boolean

			if (Array.isArray(answersSent) && Array.isArray(answersCorrect)) {
				areAllCorrect = answersSent.every((a) =>
					answersCorrect.find((aSent) => a === aSent)
				)
			} else if (
				Array.isArray(answersCorrect) &&
				typeof answersSent === 'number'
			) {
				areAllCorrect = answersCorrect[0] === answersSent
			} else if (
				Array.isArray(answersSent) &&
				typeof answersCorrect === 'number'
			) {
				areAllCorrect = answersSent[0] === answersCorrect
			} else {
				areAllCorrect = answersCorrect === answersSent
			}

			return {
				[foundTab.id]: areAllCorrect
			}
		})
	}, [questions, fields, usersTests, answerAttemptId])

	useEffect(() => {
		if (
			!correctAnswersToQuestions ||
			!correctAnswersToQuestions?.length ||
			!setCorrectAnswersAmount
		)
			return

		let correctAnswersAmount: number = 0

		correctAnswersToQuestions.forEach((e) => {
			if (!e) return

			type eType = typeof e

			const key: keyof eType = +Object.keys(e)[0]

			const isCorrect = e[key]

			if (isCorrect) correctAnswersAmount += 1
		})

		setCorrectAnswersAmount(correctAnswersAmount)
	}, [correctAnswersToQuestions, setCorrectAnswersAmount])

	const getTabIsCorrect = useCallback(
		(id: number) => {
			if (!correctAnswersToQuestions) return

			const foundAnswersToTab = correctAnswersToQuestions.find(
				(c) => c && isBoolean(c[id])
			)

			if (!foundAnswersToTab) return

			return foundAnswersToTab[id]
		},
		[correctAnswersToQuestions]
	)

	return (
		<>
			<div className={cl.menuList}>
				<span>Вопросы</span>
				<div className={cl.questionsGrid}>
					{tabs.map((tab, i) => (
						<QuestionTab
							tab={tab}
							isActive={tab.id === selectedTabId}
							key={tab.id}
							isCorrect={getTabIsCorrect(tab.id)}
							testType={testType}
							onClick={clickHandler}
							isFinalTestOngoing={isFinalTestOngoing}
						/>
					))}
				</div>
				{!viewMode && (
					<Button
						styleTypes={[ButtonStyles.HOLLOW]}
						onClick={() => setModalOpen(true)} 
						className={cl.cancel}
					>
						Досрочное завершение теста
					</Button>
				)}
			</div>

			<Modal
				isOpen={modalOpen}
				onClose={() => setModalOpen(false)}
				styleTypes={[ModalHeight.SMALL]}
				title="Закончить тестирование"
				description="Вы действительно хотите завершить тестирование досрочно? Вы еще не ответили на все вопросы."
			>
				<div className={cl.buttonsContainer}>
					<Button
						onClick={() => setModalOpen(false)}
						styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
					>
						Отмена
					</Button>
					<Button
						onClick={onModalClose}
						styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
					>
						Закончить досрочно
					</Button>
				</div>
			</Modal>
		</>
	)
}

export default QuestionsTabs
