import React, { FC } from 'react'

import cl from 'components/Courses/Tabs/ViewContent/Feedback/style.module.scss'
import { EmptyContent, Button } from 'UI'
import emptyContentIconSrc from 'components/Courses/Tabs/ViewContent/Feedback/icons/emptyContent.svg'
import { ICourseReview } from 'types/models/course.model'
import ReviewItem from 'containers/Courses/Tabs/ViewContent/Feedback/ReviewItem'
import { ButtonStyles } from 'UI/Button/types'

interface FeedbackTabPops {
	courseReviews: ICourseReview[]
	handleShowMoreReviews: () => void
	showButton?: boolean
}

// TODO показать больше отзывов
const FeedbackTab: FC<FeedbackTabPops> = ({ courseReviews, handleShowMoreReviews, showButton }) => (
	<div className={cl.container}>
		{courseReviews.length > 0 ? (
			<>
				<div className={cl.reviewList}>
					{courseReviews.map((review) => (
						<ReviewItem
							key={review.id}
							text={review.text}
							evaluation={review.evaluation}
							dateCreate={review.dateCreate}
							user={review.user}
							avatar={review.avatar}
						/>
					))}
				</div>
				{showButton && (
					<Button
						styleTypes={[ButtonStyles.ROUND, ButtonStyles.OUTLINE_GREEN]}
						onClick={handleShowMoreReviews}
						className={cl.feedbackButton}
					>
						Показать больше отзывов
					</Button>
				)}
			</>
		) : (
			<EmptyContent
				imageSrc={emptyContentIconSrc}
				title="Отзывов о курсе пока нет"
			/>
		)}
	</div>
)

export default FeedbackTab
