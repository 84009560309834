import React, { FC } from 'react'
import { TimePicker as TimePickerBaseComponent } from 'antd'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
import { Control, Controller } from 'react-hook-form'
import { ru } from 'date-fns/locale'
import { registerLocale } from 'react-datepicker'
import locale from 'antd/es/date-picker/locale/ru_RU'

import { TimeIcon } from './images'

import cl from './style.module.scss'

registerLocale('ru', ru)

interface TimePickerProps {
	control: Control<any>
	name: string
	disabled?: boolean
}

const TimePicker: FC<TimePickerProps> = ({ disabled, control, name }) => {
	dayjs.extend(customParseFormat)
	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<>
					<TimePickerBaseComponent
						format="HH:mm"
						locale={locale}
						name={name}
						disabled={disabled}
						suffixIcon={<TimeIcon />}
						allowClear={false}
						className={cl.timepickerContainer}
						value={dayjs(field.value, 'HH:mm')}
						onChange={(value) => {
							const hour = dayjs(value).hour()
							const minutes = dayjs(value).minute()
							const time = `${hour}:${minutes}`
							field.onChange(time)
						}}
					/>
				</>
			)}
		/>
	)
}

export default TimePicker
