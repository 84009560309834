import React, { FC, useCallback, useEffect, useState } from 'react'
import { TestViewProps as TestViewContainerProps } from 'containers/Courses/Entities/Tests/View'
import {
	AnswerTestReviewStatus,
	TestResponsibilityStatus,
	TestTypes
} from 'types/models/test.model'
import { LoaderStyles } from 'UI/Loader/types'
import { Button, Loader } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import NavigationThroughLessons from 'UI/NavigationThroughLessons'
import Contacts from 'containers/Courses/Entities/General/Contacts'
import { GetMillisecondsFromTimeResult } from 'helpers/getMillisecondsFromTime'
import QuestionListPass from 'containers/Courses/Entities/Tests/View/QuestionsListPass'
import { useMatchMedia } from 'hooks'

import UserAttempts from 'containers/Courses/Entities/Tests/View/UserAttempts'
import { ITestAnswer } from 'store/queries/test.query/types'
import { useActions, useAppSelector } from 'hooks/redux'
import AttemptStatus from 'components/Courses/Entities/Tests/View/UserAttempts/AttemptStatus'
import { CourseRole, SchoolRole } from 'types'
import QuestionListCheck from 'containers/Courses/Entities/Tests/Check'
import { useNavigate } from 'react-router-dom'
import { isPast } from 'helpers/time'
import { parseStringToTagA } from 'helpers/parseStringToTagA'
import { addZeroToTime } from 'helpers/number'
import QuestionListView from './QuestionsListView'
import infinityIcon from './images/infinity-icon.svg'


import cl from './style.module.scss'

interface TestViewProps extends Omit<TestViewContainerProps, 'courseId'> {
	passLimitTime: GetMillisecondsFromTimeResult
	isQuestionsPassMode: boolean
	setIsQuestionsPassMode: (_: boolean) => void
	userAnswers: ITestAnswer[]
	testStatus: AnswerTestReviewStatus
	attemptsIsExhausted: () => boolean
	infiniteAttempts: boolean
	currentRole: CourseRole | SchoolRole
	isQuestionsCheckMode: boolean
	setIsQuestionsCheckMode: (_: boolean) => void
	courseId: number
}

const TestView: FC<TestViewProps> = ({
	test,
	communicationsInfo,
	isQuestionsPassMode,
	setIsQuestionsPassMode,
	passLimitTime,
	userAnswers,
	testStatus,
	attemptsIsExhausted,
	infiniteAttempts,
	currentRole,
	isQuestionsCheckMode,
	setIsQuestionsCheckMode,
	courseName,
	userAnswersIdList,
	courseId,
	mappedItems
}) => {
	const { pushError } = useActions((state) => state.system)
	const { setIsFinalTestOngoing } = useActions((state) => state.test)
	const { isMobile } = useMatchMedia()
	const navigate = useNavigate()
	const [userCurrentAttemptCheck, setUserAttempt] = useState('')
	const { currentCheckingId, isTestChecking } = useAppSelector(
		(state) => state.test
	)
	const { setTestChecking } = useActions((state) => state.test)

	const onSeeAllAttemptsOfTheTest = useCallback(() => {
		if (!test) return

		if (!test.userAnswersIdList || !test.userAnswersIdList.length) {
			pushError({ message: 'Ни одной попытки не найдено' })

			return
		}

		setIsQuestionsCheckMode(true)
	}, [setIsQuestionsCheckMode, test])

	const testExpired = isPast(test.datePassEndSettings.date)

	const onCheckUserAttempt = useCallback((attempt: number) => {
		setIsQuestionsCheckMode(true)
		setUserAttempt(attempt.toString())
		setTestChecking({
			currentCheckingId: attempt.toString(),
			isTestChecking: true
		})
	}, [])

	const setIsTestsChecking = useCallback((isChecking: boolean) => {
		setTestChecking({
			currentCheckingId: userCurrentAttemptCheck,
			isTestChecking: isChecking
		})
		setIsQuestionsCheckMode(isChecking)
	}, [])

	if (isQuestionsPassMode && currentRole === CourseRole.STUDENT) {
		return (
			<QuestionListPass
				setIsQuestionsPassMode={setIsQuestionsPassMode}
				test={test}
				passLimitTime={passLimitTime}
				onCheckUserAttempt={onCheckUserAttempt}
			/>
		)
	}

	if (isQuestionsCheckMode && currentRole === CourseRole.STUDENT) {
		return (
			<QuestionListView
				setIsQuestionsPassMode={setIsQuestionsPassMode}
				setIsQuestionsCheckMode={setIsTestsChecking}
				test={test}
				userAnswers={userAnswers}
				userCurrentAttemptCheck={currentCheckingId}
			/>
		)
	}

	if (
		isQuestionsCheckMode &&
		(currentRole === CourseRole.TEACHER ||
			currentRole === SchoolRole.ADMIN ||
			currentRole === CourseRole.CURATOR)
	) {
		return (
			<QuestionListCheck
				testId={test.id!}
				courseId={courseId}
				userAnswersIdList={userAnswersIdList}
				courseName={courseName}
				testName={test.name}
				testQuestionsCount={test.questions.length}
			/>
		)
	}

	const getStartTimeToPassTestString = () => {
		let str = ''

		if (test.datePassStartSettings.isActive) {
			str += `${new Date(
				test.datePassStartSettings.date
			).toLocaleDateString()} `
			str += test.datePassStartSettings.time
		} else if (test.datePassEndSettings.isActive) {
			str += 'Без ограничений'
		}

		return str
	}

	const getEndTimeToPassTestString = () => {
		let str = ''

		if (test.datePassEndSettings.isActive) {
			str += `${new Date(test.datePassEndSettings.date).toLocaleDateString()} `
			str += test.datePassEndSettings.time
		} else if (test.datePassStartSettings.isActive) {
			str += 'Без ограничений'
		}

		return str
	}

	return (
		<div className={cl.container}>
			{mappedItems && (
				<NavigationThroughLessons
					courseId={courseId}
					currentId={test.id!}
					itemsArray={mappedItems}
					isMobile={isMobile}
				/>
			)}
			<div className={cl.header}>
				{currentRole === CourseRole.STUDENT && (
					<div className={cl.headerStudent}>
						<h1>{test.name}</h1>
						<AttemptStatus status={testStatus} />
					</div>
				)}
				{(currentRole === CourseRole.TEACHER ||
					currentRole === SchoolRole.ADMIN ||
					currentRole === CourseRole.CURATOR) && (
						<div className={cl.headerTeacher}>
							<h1>{test.name}</h1>
							<div className={cl.teacherButtons}>
								<Button
									onClick={onSeeAllAttemptsOfTheTest}
									styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
								>
									Посмотреть результаты тестирования
								</Button>
								{currentRole !== CourseRole.CURATOR && (
									<Button
										onClick={() =>
											navigate(`/course/${courseId}/test/edit/${test.id}`)
										}
										styleTypes={[ButtonStyles.OUTLINE_DARK, ButtonStyles.ROUND]}
									>
										Редактировать
									</Button>
								)}
							</div>
						</div>
					)}
				<div className={cl.headerInfo}>
					<div className={cl.headerInfoItem}>
						<span>Тестирование обязательное</span>
						<span>
							{test.testResponsibilityStatus ===
								TestResponsibilityStatus.REQUIRED
								? 'Да'
								: 'Нет'}
						</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Тип тестирования</span>
						<span>
							{test.type === TestTypes.INTERMEDIATE
								? 'Промежуточное'
								: 'Итоговое'}
						</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Количество вопросов</span>
						<span>
							{test.questions && test.questions.length
								? test.questions.length
								: '—'}
						</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Ограничение прохождения по времени</span>
						<span>
							<>{passLimitTime.hours ? `${addZeroToTime(passLimitTime.hours)}:` : '00:'}</>
							<>{passLimitTime.minutes ? `${addZeroToTime(passLimitTime.minutes)}:` : '00:'}</>
							<>{passLimitTime.seconds ? addZeroToTime(passLimitTime.seconds) : '00'}</>
						</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Дата прохождения</span>
						<span>—</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Период прохождения</span>
						<span>
							<span>{getStartTimeToPassTestString()}</span> <span>—</span>{' '}
							<span>{getEndTimeToPassTestString()}</span>{' '}
						</span>
					</div>
				</div>
				{test.description && (
					<>
						{test.description.split('\n').map((text) => (
							<p className={cl.caption} key={text}>
								{parseStringToTagA(text, '_blank')}
							</p>
						))}
					</>
				)}
			</div>

			{currentRole === CourseRole.STUDENT && (
				<>
					<UserAttempts
						questionsCount={test.questions.length}
						userAnswers={userAnswers}
						setIsQuestionsCheckMode={onCheckUserAttempt}
					/>
					<div className={cl.questionsListPreview}>
						<h2>Тестирование</h2>
						{attemptsIsExhausted() ? (
							<span>У вас закончились попытки</span>
						) : (
							<div className={cl.attemptsCount}>
								<span>Попытки</span>
								<div className={cl.attempt}>
									{userAnswers ? userAnswers.length : 1}
								</div>
								<span>из</span>
								<div className={cl.attempt}>
									{infiniteAttempts && (
										<img src={infinityIcon} alt="Бесконечность" />
									)}

									{test.countAttempts}
								</div>
							</div>
						)}

						<Button
							disabled={attemptsIsExhausted() || testExpired}
							onClick={() => {
								setIsQuestionsPassMode(true)
								setTestChecking({
									currentCheckingId: '',
									isTestChecking: false
								})
								setIsFinalTestOngoing(true && test.type === TestTypes.FINAL)
							}}
							styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
						>
							Начать тестирование
						</Button>
					</div>
				</>
			)}

			<Contacts communicationsInfo={communicationsInfo} />
		</div>
	)
}

export default TestView
