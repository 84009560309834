import React, { FC, useCallback, useEffect, useState } from 'react'

import { CertificateSettingsProps } from 'components/Courses/Tabs/Content/MainForm/CertificateSettings'
import ConfigModalComponent from 'components/Courses/Tabs/Content/MainForm/CertificateSettings/modals/Config'
import SchoolLogoModal from 'pages/Course/modals/SchoolLogo'
import FilledCertificateModal from 'pages/Course/modals/FilledCertificate'
import ResetCertificateModal from 'pages/Course/modals/ResetCertificateModal'
import { useWatch } from 'react-hook-form'

export interface ConfigModalProps
	extends Omit<CertificateSettingsProps, 'onOpenCertificateTemplateModal'> { }

const ConfigModal: FC<ConfigModalProps> = ({
	modalIsOpen,
	onCloseModal,
	onOpenModal,
	control,
	getValues,
	resetCertificateConfigs,
	onChange,
	errors,
	...props
}) => {
	const [resetCertificateModal, setResetCertificateModal] = useState(false)
	const [schoolLogoModalIsOpen, setSchoolLogoModalIsOpen] = useState(false)
	const [filledCertificateModalIsOpen, setFilledCertificateModalIsOpen] =
		useState(false)

	const markFile = useWatch({
		control,
		name: 'signerMark'
	})

	const signFile = useWatch({
		control,
		name: 'signerSign'
	})

	const sampleCertificateForMPFile = useWatch({
		control,
		name: 'sampleCertificateForMP'
	})

	const signerMarkUrl = useWatch({
		control,
		name: 'certificateSettings.templateInfo.schoolSettings.signerMarkUrl'
	})

	const signerSignUrl = useWatch({
		control,
		name: 'certificateSettings.templateInfo.schoolSettings.signerSignUrl'
	})

	const sampleCertificateForMPUrl = useWatch({
		control,
		name: 'certificateSettings.templateInfo.schoolSettings.sampleCertificateForMPUrl'
	})

	const schoolLogoModalCloseHandler = () => {
		setSchoolLogoModalIsOpen(false)
		onOpenModal()
	}

	const filledCertificateModalCloseHandler = () => {
		setFilledCertificateModalIsOpen(false)
		onOpenModal()
	}

	const resetCertificateModalCloseHandler = () => {
		setResetCertificateModal(false)
		onOpenModal()
	}

	const onDeleteMarkPreview = useCallback(() => {
		onChange('certificateSettings.templateInfo.schoolSettings.signerMarkUrl')(
			undefined
		)
	}, [onChange])

	const onDeleteSignPreview = useCallback(() => {
		onChange('certificateSettings.templateInfo.schoolSettings.signerSignUrl')(
			undefined
		)
	}, [onChange])

	const onDeleteSampleCertificateForMPPreview = useCallback(() => {
		onChange(
			'certificateSettings.templateInfo.schoolSettings.sampleCertificateForMPUrl'
		)(undefined)
	}, [onChange])

	useEffect(() => {
		if (!markFile) {
			onChange('certificateSettings.templateInfo.schoolSettings.signerMarkUrl')(
				undefined
			)

			return
		}

		const fileName = markFile.name
		onChange('certificateSettings.templateInfo.schoolSettings.signerMarkUrl')({
			title: fileName,
			url: URL.createObjectURL(markFile)
		})
	}, [markFile])

	useEffect(() => {
		if (!signFile) {
			onChange('certificateSettings.templateInfo.schoolSettings.signerSignUrl')(
				undefined
			)

			return
		}

		const fileName = signFile.name
		onChange('certificateSettings.templateInfo.schoolSettings.signerSignUrl')({
			title: fileName,
			url: URL.createObjectURL(signFile)
		})
	}, [signFile])

	useEffect(() => {
		if (!sampleCertificateForMPFile) {
			onChange(
				'certificateSettings.templateInfo.schoolSettings.sampleCertificateForMPUrl'
			)(undefined)

			return
		}

		const fileName = sampleCertificateForMPFile.name
		onChange(
			'certificateSettings.templateInfo.schoolSettings.sampleCertificateForMPUrl'
		)({
			title: fileName,
			url: URL.createObjectURL(sampleCertificateForMPFile)
		})
	}, [sampleCertificateForMPFile])

	return (
		<>
			<ResetCertificateModal
				resetCertificateConfigs={resetCertificateConfigs}
				isOpen={resetCertificateModal}
				onClose={resetCertificateModalCloseHandler}
			/>
			<FilledCertificateModal
				getValues={getValues}
				isOpen={filledCertificateModalIsOpen}
				onClose={filledCertificateModalCloseHandler}
			/>
			<SchoolLogoModal
				isOpen={schoolLogoModalIsOpen}
				onClose={() => schoolLogoModalCloseHandler()}
			/>
			<ConfigModalComponent
				control={control}
				onOpenFilledCertificateModal={() =>
					setFilledCertificateModalIsOpen(true)
				}
				onOpenResetCertificateModal={() => setResetCertificateModal(true)}
				onOpenSchoolLogoModal={() => setSchoolLogoModalIsOpen(true)}
				modalIsOpen={modalIsOpen}
				onOpenModal={onOpenModal}
				onCloseModal={onCloseModal}
				signPreview={signerSignUrl}
				sampleCertificateForMPPreview={sampleCertificateForMPUrl}
				markPreview={signerMarkUrl}
				onChange={onChange}
				onDeleteSignPreview={onDeleteSignPreview}
				onDeleteSampleCertificateForMPPreview={
					onDeleteSampleCertificateForMPPreview
				}
				onDeleteMarkPreview={onDeleteMarkPreview}
				errors={errors}
				{...props}
			/>
		</>
	)
}

export default ConfigModal
