import React, { FC, useCallback, useEffect, useState } from 'react'
import { FieldPath, useForm } from 'react-hook-form'

import { Select } from 'UI'
import { IOption, SelectBorderStyles, SelectSizeStyles } from 'UI/Select/types'
import { ITest } from 'types/models/test.model'
import {
	ResponseEntityId,
	ResponseWithError,
	ValidationSuccessMessages
} from 'types'
import { SectionItemType } from 'containers/Courses/Tabs/Content/ContentForm/Table'

import { useActions, useAppDispatch } from 'hooks/redux'
import testQuery from 'store/queries/test.query'
import courseQuery from 'store/queries/course.query'
import classnames from 'classnames'
import cl from './style.module.scss'
import ConfirmModal from '../../Tasks/TaskStatus/ConfirmModal'

interface TestStatusFormState extends Pick<ITest, 'isActive'> { }
interface TestStatusProps {
	isActive: ResponseEntityId
	testId: ResponseEntityId
	restrictionsFilled: boolean
}
const TestStatus: FC<TestStatusProps> = ({
	testId,
	isActive,
	restrictionsFilled
}) => {
	const dispatch = useAppDispatch()
	const { pushSuccess, pushError } = useActions((state) => state.system)
	const [changeTest] = testQuery.useChangeTestMutation()
	const [confirmModalOpen, setConfirmModalOpen] = useState(false)
	const onConfirmModalClose = useCallback(() => setConfirmModalOpen(false), [])

	const [statusOptionList] = useState<IOption[]>([
		{
			value: 2,
			text: 'Активный'
		},
		{
			value: 1,
			text: 'Неактивный'
		}
	])
	const { register, setValue, control } = useForm<TestStatusFormState>()

	useEffect(() => {
		register('isActive')
	}, [])

	useEffect(() => {
		setValue('isActive', isActive)
	})

	const onSubmit = useCallback(
		async (value: any) => {
			const response = await changeTest({
				testId,
				body: { isActive: value }
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}
			dispatch(courseQuery.util.invalidateTags(['course']))
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
		},
		[testId]
	)

	const changeHandler = useCallback(
		(name: FieldPath<TestStatusFormState>) => async (value: any) => {
			if (restrictionsFilled && value === 2) {
				setConfirmModalOpen(true)
				return
			}

			setValue(name, value)
			onSubmit(value)
		},
		[onSubmit, restrictionsFilled]
	)

	const onConfirmActivation = useCallback(async () => {
		await onSubmit(2)

		onConfirmModalClose()
	}, [onSubmit])

	return (
		<>
			<Select
				className={classnames([
					cl.statusSelect,
					{
						[cl.statusSelectActive]: isActive === 2,
						[cl.statusSelectNonActive]: isActive === 1
					}
				])}
				styleTypes={[SelectSizeStyles.SMALL, SelectBorderStyles.ROUND]}
				control={control}
				onChange={changeHandler('isActive')}
				name="isActive"
				optionsList={statusOptionList}
			/>

			<ConfirmModal
				isOpen={confirmModalOpen}
				onClose={onConfirmModalClose}
				onConfirm={onConfirmActivation}
				itemType={SectionItemType.TEST}
			/>
		</>
	)
}

export default TestStatus
