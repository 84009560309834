import { EntityId } from '@reduxjs/toolkit'
import React, { FC } from 'react'
import {
	INotification,
	NotificationStatuses
} from 'types/models/notification.model'

import cl from './style.module.scss'

interface NotificationsListItemProps extends INotification {
	toggleReadNotification: (
		id: EntityId,
		nextStatus: NotificationStatuses
	) => void
}

const NotificationsListItem: FC<NotificationsListItemProps> = ({
	dateCreate,
	id,
	status,
	text,
	toggleReadNotification
}) => (
	<div
		key={id}
		className={cl.contentItem}
		onClick={() =>
			toggleReadNotification(
				id,
				status === NotificationStatuses.READ
					? NotificationStatuses.UNREAD
					: NotificationStatuses.READ
			)
		}
	>
		<div className={cl.descriptionContainer}>
			<span>{text}</span>
			<div
				className={
					status === NotificationStatuses.READ
						? cl.readIndicatorEmpty
						: cl.readIndicatorFill
				}
			/>
		</div>
		<p className={cl.date}>{dateCreate.toString()}</p>
	</div>
)

export default NotificationsListItem
