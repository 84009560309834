export enum ModalHeight {
	SMALL = 'HeightSmall'
}
export enum ModalWidth {
	BIG = 'WidthBig'
}
export enum ModalSize {
	SMALL = 'SizeSmall',
	BIG = 'SizeBig'
}
