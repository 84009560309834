import classnames from 'classnames'
import React, { FC } from 'react'

import { CoursePassStatus } from 'types/models/course.model'
import CourseStatusComponent from 'components/Courses/StudentRole/Content/CourseStatus'

import cl from '../style.module.scss'

export interface CourseStatusProps {
	status: CoursePassStatus
}

const CourseStatus: FC<CourseStatusProps> = ({ status }) => {
	const courseStatusClassName = classnames(cl.courseStatus, {
		[cl.courseStatusNew]: status === CoursePassStatus.NEW,
		[cl.courseStatusProcess]: status === CoursePassStatus.PROCESS,
		[cl.courseStatusCompleted]: status === CoursePassStatus.COMPLETED
	})
	return (
		<CourseStatusComponent
			status={status}
			courseStatusClassName={courseStatusClassName}
		/>
	)
}

export default CourseStatus
