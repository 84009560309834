import React from 'react'

import classNames from 'classnames'
import { Divider } from 'antd'
import { Control, FieldPath, UseFormWatch } from 'react-hook-form'
import {
	EventFormItem,
	SettingsFormValues
} from 'containers/NotificationsSettings/NotificationSettingsTable'
import EventItem from 'containers/NotificationsSettings/NotificationSettingsTable/EventItem'
import { ButtonStyles } from 'UI/Button/types'
import { Button, Switch } from 'UI'

import cl from './style.module.scss'
import CancelModal from './CancelModal'

export interface NotificationsSettingsTableComponentProps {
	onSubmit: () => void
	onChange: (name: FieldPath<SettingsFormValues>) => (value: any) => void
	watch: UseFormWatch<SettingsFormValues>
	onOpenModal: () => void
	onCloseModal: () => void
	onConfirmModal: () => void
	events: EventFormItem[]
	control: Control<SettingsFormValues>
	cancelModalOpen: boolean
	courseSettings?: boolean
}

const NotificationsSettingsTable: React.FC<
	NotificationsSettingsTableComponentProps
> = ({
	events,
	control,
	cancelModalOpen,
	courseSettings,
	onChange,
	onSubmit,
	watch,
	onCloseModal,
	onOpenModal,
	onConfirmModal
}) => (
	<>
		<Switch
			name="isActive"
			control={control}
			onChange={onChange('isActive')}
			labelTitle={
				courseSettings
					? 'Уведомления по курсу'
					: 'Получать уведомления от сервиса'
			}
		/>
		<div
			className={classNames(cl.table, {
				[cl.courseTable]: courseSettings,
				[cl.disabled]: !watch('isActive')
			})}
		>
			{!courseSettings ? (
				<>
					<span className={cl.tableHeader}>событие</span>
					<span className={cl.tableHeader}>почта</span>
					<span className={cl.tableHeader}>push-уведомления</span>
				</>
			) : (
				<>
					<span className={cl.tableHeader}>событие</span>
					<span className={cl.tableHeader}>преподаватель</span>
					<span className={cl.tableHeader}>куратор</span>
					<span className={cl.tableHeader}>ученик</span>
				</>
			)}

			{events &&
				events.map((e, i) => (
					<EventItem
						{...e}
						control={control}
						key={e.eventId}
						onChange={onChange}
						disabled={!watch('isActive')}
						index={i}
						courseSettings={courseSettings}
					/>
				))}
		</div>

		<Divider />

		<div className={cl.buttons}>
			<Button styleTypes={[ButtonStyles.OUTLINE_PRIMARY]} onClick={onOpenModal}>
				Отменить изменения
			</Button>
			<Button styleTypes={[ButtonStyles.PRIMARY]} onClick={onSubmit}>
				Сохранить изменения
			</Button>
		</div>

		<CancelModal
			isOpen={cancelModalOpen}
			onClose={onCloseModal}
			onConfirm={onConfirmModal}
		/>
	</>
)

export default NotificationsSettingsTable
