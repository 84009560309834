import React, { FC } from 'react'
import ScheduleTabComponent from 'components/Timetable/Tabs/ScheduleTab'
import { Control, FieldPath, useWatch } from 'react-hook-form'
import { TimetableContentFormValues } from 'containers/Timetable/TimetableContent'
import { IEvent } from 'types/models/event.model'

export interface ScheduleTabContainerProps {
	control: Control<TimetableContentFormValues>
	onChange: (
		name: FieldPath<TimetableContentFormValues>
	) => (value: any) => void
	onDetailsOpen: (_: IEvent) => void
}

const ScheduleTab: FC<ScheduleTabContainerProps> = ({
	control,
	onChange,
	onDetailsOpen
}) => {
	const events = useWatch({
		control,
		name: 'events'
	})

	return (
		<ScheduleTabComponent
			events={events}
			control={control}
			onChange={onChange}
			onDetailsOpen={onDetailsOpen}
		/>
	)
}

export default ScheduleTab
