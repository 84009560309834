import React, { FC, useState, ReactNode } from 'react'
import classnames from 'classnames'

import { dropdownIconSrc } from 'components/Header/ProfileMenu/images/icons'
import cl from './style.module.scss'

interface AccordionProps {
	iconUrl?: string
	label?: ReactNode
	labelNode?: ReactNode
	contentItemsLength?: string
	children: ReactNode
	isOpenDefault?: boolean
}

const Accordion: FC<AccordionProps> = ({
	iconUrl,
	label,
	contentItemsLength,
	children,
	labelNode,
	isOpenDefault = false
}) => {
	const [isOpen, setIsOpen] = useState(isOpenDefault)

	const toggleHandler = setIsOpen.bind(null, (prev) => !prev)

	return (
		<div className={cl.container}>
			<div onClick={toggleHandler} className={cl.accordionToggle}>
				<div>
					{iconUrl && (
						<img className={cl.accordionImg} src={iconUrl} alt="icon" />
					)}
					{label && <span className={cl.accordionToggleHeader}>{label}</span>}
					{labelNode}
					{contentItemsLength && (
						<span className={cl.accordionToggleLessonCount}>
							{contentItemsLength}
						</span>
					)}
				</div>
				<div className={classnames(cl.toggleIcon, {
					[cl.toggleIconActive]: isOpen
				})}>
					<img src={dropdownIconSrc} alt="dropdown" />
				</div>
			</div>
			<div
				className={classnames([cl.accordionContent], {
					[cl.accordionContentOpen]: isOpen
				})}
			>
				{children}
			</div>
		</div>
	)
}

export default Accordion
