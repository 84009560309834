import { ResponseEntityId } from 'types'
import { ITask, ITaskAnswerResponse } from 'types/models/task.model'

export enum TaskQueryApiRoutes {
	BASE_URL = 'tasks'
}
export interface TaskDelete {
	sectionId: ResponseEntityId
	taskId: ResponseEntityId
}
export interface TaskCreate {
	sectionId: ResponseEntityId
	body: Pick<ITask, 'name' | 'description'>
}
export interface TaskChange {
	taskId: ResponseEntityId
	body: Partial<ITask<string>>
}
export interface TaskCreateAnswer {
	taskId: ResponseEntityId
	body: FormData
}

export interface TaskCreateResponse {
	data: { taskId: ResponseEntityId }
}

export interface TaskGetAnswersResponse {
	countPastStudents: number
	data: ITaskAnswerResponse[]
	totalCountStudents: number
	totalCount: number
	limit: number
}

export interface CheckingResponseBody {
	userAnswerId: number | string
	assessment: number
	teacherComment: string
	taskId: string
}

export interface CheckingResponseServerResponse {
	userAnswerId: string | number
}

export interface GetTaskResponse {
	data: ITask<string>
}

export interface TaskAnswersRequest {
	userAnswersIdList: string[]
	limit?: number
	page?: number
	sortDate?: string
	sortAssessment?: string
	filterStatus?: string
}

export interface GetTask {
	taskId: number
	userRole?: string
}
