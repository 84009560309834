import React, { FC } from 'react'
import { ResponseEntityId, ResponseWithError } from 'types'
import { useDisableScrollManual } from 'hooks/useDisableScrollManual'
import { courseQuery } from 'store/queries'
import { ModalHeight } from 'UI/Modal/types'
import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import cl from './style.module.scss'

interface ConfirmUnPublishModalProps {
	onClose: () => void
	isOpen: boolean
	courseId: ResponseEntityId
	setIsUnPublishModalOpen: (_: boolean) => void
}

const ConfirmUnPublishModal: FC<ConfirmUnPublishModalProps> = ({
	setIsUnPublishModalOpen,
	isOpen,
	courseId,
	onClose
}) => {
	const [setCourseUnPublish] = courseQuery.useCoursesUnPublishMutation()

	const courseUnPublishQuery = async (id: ResponseEntityId) => {
		const response = await setCourseUnPublish(id)
		const { error } = response as unknown as ResponseWithError
		if (error) {
			return
		}
		onClose()
		setIsUnPublishModalOpen(true)
	}

	useDisableScrollManual(isOpen)
	return (
		<Modal
			styleTypes={[ModalHeight.SMALL]}
			isOpen={isOpen}
			title="Снять курс с публикации?"
			description="Курс будет сохранен в черновиках, вы сможете его редактировать, но курс пропадет с маркетплейса, если вы его там размещали."
			onClose={onClose}
		>
			<div className={cl.buttonsContainer}>
				<Button
					onClick={onClose}
					styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
				>
					Отмена
				</Button>
				<Button
					onClick={() => courseUnPublishQuery(courseId)}
					styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
				>
					Снять с публикации
				</Button>
			</div>
		</Modal>
	)
}

export default ConfirmUnPublishModal
