import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useActions } from 'hooks/redux'
import { getQueryParams } from 'helpers'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import cl from 'layouts/main/style.module.scss'

const checkInviteUserCodeLayout = () => {
	const navigate = useNavigate()
	const { checkInviteCode } = useActions((state) => state.school)

	useEffect(() => {
		const { inviteCode } = getQueryParams()
		if (!inviteCode) {
			return
		}
		checkInviteCode({ inviteCode: `${inviteCode}` })

		navigate('/')
	}, [])

	return (
		<div className={cl.container}>
			<Loader styleTypes={[LoaderStyles.BIG]} />
		</div>
	)
}

export default checkInviteUserCodeLayout
