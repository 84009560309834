import React, { FC } from 'react'
import { Button } from 'UI'
import { GoForwardContainerProps } from 'containers/Timetable/Tabs/ScheduleTab/ScheduleTable/GoForward'
import cl from 'components/Timetable/Tabs/ScheduleTab/ScheduleTable/GoBack/style.module.scss'
import { ButtonStyles } from 'UI/Button/types'

interface GoForwardComponentProps
	extends Omit<GoForwardContainerProps, 'onChange' | 'currentDate'> {
	onGoForward: () => void
}

const GoForward: FC<GoForwardComponentProps> = ({
	dateString,
	onGoForward
}) => (
	<div className={cl.navLine}>
		<span>Расписание после {dateString}</span>
		<Button onClick={onGoForward} styleTypes={[ButtonStyles.TERTIARY_BLUE]}>
			Показать позднее
		</Button>
	</div>
)

export default GoForward
