import React from 'react'

const CalendarIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.83334 9.1665H7.50001V10.8332H5.83334V9.1665ZM5.83334 12.4998H7.50001V14.1665H5.83334V12.4998ZM9.16668 9.1665H10.8333V10.8332H9.16668V9.1665ZM9.16668 12.4998H10.8333V14.1665H9.16668V12.4998ZM12.5 9.1665H14.1667V10.8332H12.5V9.1665ZM12.5 12.4998H14.1667V14.1665H12.5V12.4998Z"
			fill="#0087CD"
		/>
		<path
			d="M4.16667 18.3332H15.8333C16.7525 18.3332 17.5 17.5857 17.5 16.6665V4.99984C17.5 4.08067 16.7525 3.33317 15.8333 3.33317H14.1667V1.6665H12.5V3.33317H7.5V1.6665H5.83333V3.33317H4.16667C3.2475 3.33317 2.5 4.08067 2.5 4.99984V16.6665C2.5 17.5857 3.2475 18.3332 4.16667 18.3332ZM15.8333 6.6665L15.8342 16.6665H4.16667V6.6665H15.8333Z"
			fill="#0087CD"
		/>
	</svg>
)

export default CalendarIcon
