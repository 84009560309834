import classNames from 'classnames'
import CreateEventForm from 'containers/Courses/Entities/Events/Create/CreateEventForm'
import React, { MouseEvent } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'UI'

import cl from '../style.module.scss'

const EventCreatePage: React.FC = () => {
	const navigate = useNavigate()
	const { sectionId, courseId } = useParams()

	const onGoBack = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()

		navigate(-1)
	}

	return (
		<div className={cl.container}>
			<div className={cl.back}>
				<Button onClick={onGoBack}>Вернуться назад</Button>
			</div>
			<div className={classNames(cl.header, cl.headerEvents)}>
				<div className={cl.headerInfo}>
					<h2>Новое мероприятие</h2>

					<p>Настройте в каком формате ученик должен предоставить ответ</p>
				</div>
			</div>
			<CreateEventForm sectionId={sectionId} courseId={courseId} />
		</div>
	)
}

export default EventCreatePage
