import React, { FC, useCallback, useMemo, useState } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFieldArrayRemove,
	UseFormGetValues,
	UseFormRegister,
	useWatch
} from 'react-hook-form'

import { ITest, Question } from 'types/models/test.model'
import CreateQuestionsComponent from 'components/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.component'
import { QuestionsFormTabState } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/index'

export interface CreateQuestionFormState extends Pick<ITest, 'questions'> { }

export interface QuestionTabs {
	id: number
	text: string
}

interface CreateQuestionsProps {
	control: Control<any>
	remove: UseFieldArrayRemove
	errors: FieldErrors<CreateQuestionFormState>
	onChange: (name: FieldPath<CreateQuestionFormState>) => (value: any) => void
	onSubmit: () => void
	fields: Record<'id', string>[]
	getValues: UseFormGetValues<QuestionsFormTabState>
	register: UseFormRegister<QuestionsFormTabState>
	onOpenCanceledChange: () => void
}

const CreateQuestions: FC<CreateQuestionsProps> = ({
	control,
	remove,
	onChange,
	errors,
	onSubmit,
	fields,
	getValues,
	register,
	onOpenCanceledChange
}) => {
	const questionsList = useWatch({
		control,
		name: 'questions'
	}) as Question[]

	const tabsList = useMemo(
		(): QuestionTabs[] =>
			questionsList.map((question, fieldIndex) => ({
				id: fieldIndex,
				text: question.name
			})),
		[questionsList]
	)

	// TODO refactor
	const [selectedQuestionTabId, setSelectedQuestionTabId] =
		useState<QuestionTabs['id']>(0)

	const selectTabHandler = useCallback((tabId) => {
		setSelectedQuestionTabId(tabId)
	}, [])

	return (
		<CreateQuestionsComponent
			onOpenCanceledChange={onOpenCanceledChange}
			fields={fields}
			errors={errors}
			remove={remove}
			control={control}
			onChange={onChange}
			onSubmit={onSubmit}
			tabsList={tabsList}
			onSelectTab={selectTabHandler}
			selectedQuestionTabId={selectedQuestionTabId}
			getValues={getValues}
			register={register}
		/>
	)
}

export default CreateQuestions
