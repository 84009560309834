import React, { FC } from 'react'

import { useWatch } from 'react-hook-form'
import { TaskAnswerDoc } from 'types/models/task.model'
import {
	FileInputMultipleProps,
	FileInputProps,
	FileInputSingleProps
} from './types'
import { FileInputMultiple, FileInputSingle } from './kinds'

const FileInput: FC<FileInputProps> = ({
	multiple: isMultiple = false,
	accept,
	size,
	onChange,
	id,
	control,
	name,
	previewFile,
	onDeleteFile,
	disabled,
	className
}) => {
	const value = useWatch({
		control,
		name,
		defaultValue: isMultiple ? [] : undefined
	})

	return (
		<>
			{isMultiple ? (
				<FileInputMultiple
					previewFile={previewFile as TaskAnswerDoc[]}
					name={name}
					accept={accept?.toString()}
					size={size}
					files={value}
					onChange={onChange as FileInputMultipleProps['onChange']}
					disabled={disabled}
					className={className}
				/>
			) : (
				<FileInputSingle
					previewFile={previewFile as TaskAnswerDoc}
					id={id}
					name={name}
					accept={accept?.toString()}
					size={size}
					file={value}
					onChange={onChange as FileInputSingleProps['onChange']}
					disabled={disabled}
					onDeleteFile={onDeleteFile}
					className={className}
				/>
			)}
		</>
	)
}
export default FileInput
