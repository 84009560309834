import React, { FC } from 'react'

import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ModalHeight, ModalSize } from 'UI/Modal/types'

import { useActions } from 'hooks/redux'
import cl from '../style.module.scss'

interface TestPassingModalProps {
	isOpen: boolean
	onClose: () => void
}

const TestPassingFailed: FC<TestPassingModalProps> = ({ isOpen, onClose }) => {
	const { testModalPassingFailedClose } = useActions((state) => state.system)

	return (
		<Modal
			styleTypes={[ModalSize.BIG, ModalHeight.SMALL]}
			title="Тестирование провалено"
			description="Пожалуйста, повторите материал и попытайтесь заново."
			isOpen={isOpen}
		>
			<div className={cl.buttonsContainer}>
				<Button
					onClick={() => {
						testModalPassingFailedClose()
						onClose()
					}}
					styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
				>
					Закрыть
				</Button>
			</div>
		</Modal>
	)
}

export default TestPassingFailed
