import React, { DragEventHandler, FC, useCallback, useState } from 'react'

import { useWatch } from 'react-hook-form'
import {
	DragAndDropMultipleProps,
	DragAndDropProps,
	DragAndDropSingleProps
} from './types'
import { DragAndDropSingle, DragAndDropMultiple } from './kinds'

const DragAndDrop: FC<DragAndDropProps> = ({
	multiple: isMultiple = false,
	accept,
	size,
	previewUrl,
	onChange,
	id,
	control,
	onDelete,
	imagesPreview,
	name,
	recommendedImageWidth,
	recommendedImageHeight,
	disabled,
	singleFile = false,
	onlyPreviews,
	className
}) => {
	const value = useWatch({
		control,
		name
	})

	// TODO Добавить зашитую валидацию
	const [isDrag, setIsDrag] = useState(false)

	const dragEnterHandler: DragEventHandler<HTMLDivElement> = useCallback(
		(event) => {
			event.preventDefault()
			setIsDrag(true)
		},
		[]
	)
	const dragLeaveHandler: DragEventHandler<HTMLDivElement> = useCallback(
		(event) => {
			event.preventDefault()
			setIsDrag(false)
		},
		[]
	)

	return (
		<>
			{isMultiple ? (
				<DragAndDropMultiple
					onDelete={onDelete!}
					name={name}
					accept={accept?.toString()}
					size={size}
					files={value}
					onChange={onChange as DragAndDropMultipleProps['onChange']}
					previewUrl={Array.isArray(previewUrl) ? previewUrl : []}
					imagesPreview={Array.isArray(imagesPreview) ? imagesPreview : []}
					isDrag={isDrag}
					onDragEnter={dragEnterHandler}
					onDragLeave={dragLeaveHandler}
					recommendedImageWidth={recommendedImageWidth}
					recommendedImageHeight={recommendedImageHeight}
					disabled={disabled}
					singleFile={singleFile}
					onlyPreviews={onlyPreviews}
					className={className}
				/>
			) : (
				<DragAndDropSingle
					id={id}
					name={name}
					previewUrl={
						!Array.isArray(previewUrl) && previewUrl ? previewUrl : ''
					}
					accept={accept?.toString()}
					size={size}
					file={value}
					onChange={onChange as DragAndDropSingleProps['onChange']}
					isDrag={isDrag}
					onDragEnter={dragEnterHandler}
					onDragLeave={dragLeaveHandler}
					disabled={disabled}
					className={className}
				/>
			)}
		</>
	)
}
export default DragAndDrop
