import { EntityId } from '@reduxjs/toolkit'
import {
	CourseRole,
	FilterWithPagination,
	ResponseEntityId,
	SchoolRole
} from 'types'
import { NotificationType } from 'store/queries/user.query/types'
import { CertificateSettings } from 'types/models/course.model/settings/certificate.model'

export enum CourseQueryApiRoutes {
	BASE_URL = 'courses'
}

export interface CoursesListFilter extends FilterWithPagination {
	coursesIdList?: ResponseEntityId[] | string[]
	role: CourseRole | SchoolRole
}

export interface SectionListFilter {
	sectionsIdList?: ResponseEntityId[]
}

export interface ChangeCourse {
	courseId: ResponseEntityId
	body: FormData
}
export interface ChangeCourseCertificateInfo
	extends Omit<ChangeCourse, 'body'> {
	certificateSettings: CertificateSettings
}
export interface ChangeCourseAccessInfo extends Omit<ChangeCourse, 'body'> {
	teachersIdList: ResponseEntityId[]
	curatorsIdList: ResponseEntityId[]
}
export interface CourseAddSection extends Omit<ChangeCourse, 'body'> {
	name: string
}
export interface CourseSortSection {
	sortItemsIdList: ResponseEntityId[]
}
export interface CourseSortSectionItems {
	sectionId: ResponseEntityId
	sortItemsIdList: ResponseEntityId[]
}
export interface CourseDeleteSection extends Omit<ChangeCourse, 'body'> {
	sectionId: ResponseEntityId
}
export interface CourseInviteStudent extends Omit<ChangeCourse, 'body'> {
	email: string
}
export interface CourseDeleteStudent extends Omit<ChangeCourse, 'body'> {
	studentId: ResponseEntityId
}
export interface CourseGetSections {
	role?: CourseRole | SchoolRole
	sectionsIdList: ResponseEntityId[]
	userId?: number
	courseId?: number
}
export interface CreateCourseResponse {
	data: { courseId: number }
}
export interface CourseEditSection {
	sectionId: ResponseEntityId
	name: string
}
export interface CourseGetPassingResponse {
	id: ResponseEntityId
	courseId: ResponseEntityId
	userId: ResponseEntityId
	passed: boolean
	passingLevel: ResponseEntityId
	passingTestsIdList: ResponseEntityId[]
	passingLessonsIdList: ResponseEntityId[]
	passingTasksIdList: ResponseEntityId[]
	passingEventsIdList: ResponseEntityId[]
	certificateId: ResponseEntityId
	finalTestActive: boolean
	accessPassage: boolean
	viewingAccess: boolean
}
export interface CourseGetPassing {
	courseId: ResponseEntityId
	userId: ResponseEntityId
}

export interface CreateReviewPayload {
	text: string
	evaluation: 1 | 2 | 3 | 4 | 5
	courseId: number
}

export interface ChangeNotificationSettingsPayload {
	courseId: EntityId
	notificationsSettings: NotificationsSettings
}

interface NotificationSetting {
	[eventId: string]: [NotificationType, NotificationType]
}

interface NotificationsSettings {
	reminderDelaysDaysList: number[]
	noticeSettings: NoticeSettings
}

interface NoticeSettings {
	isActive: boolean
	eventInformingSettings: NotificationSetting
}

export interface CreateCertificatePayload {
	courseId: ResponseEntityId
}

export interface GetCertificatePayload {
	certificateId: ResponseEntityId
}
