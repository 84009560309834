import React, { FC } from 'react'

import ContactsComponent from 'components/Courses/Entities/General/Contacts'
import { CommunicationsSettings } from 'types/models/course.model/settings/communications.model'

interface ContactsProps {
	communicationsInfo: CommunicationsSettings
}

const Contacts: FC<ContactsProps> = ({ communicationsInfo }) => (
	<ContactsComponent {...communicationsInfo} />
)

export default Contacts
