import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import { buildHeaders } from 'helpers/api'

import { Constants, ResponseEntityId } from 'types'
import {
	CheckingResponseBody,
	CheckingResponseServerResponse,
	TaskChange,
	TaskCreate,
	TaskCreateAnswer,
	TaskCreateResponse,
	TaskDelete,
	TaskGetAnswersResponse,
	TaskQueryApiRoutes,
	TaskAnswersRequest,
	GetTask
} from 'store/queries/task.query/types'
import { ITask, ITaskAnswerResponse } from 'types/models/task.model'
import { ITestAnswer } from 'store/queries/test.query/types'

const taskQuery = createApi({
	reducerPath: 'taskQuery',
	tagTypes: ['task'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${Constants.API_BASE_URL}/${TaskQueryApiRoutes.BASE_URL}`
	}),
	endpoints: (build) => ({
		createTask: build.mutation<TaskCreateResponse, TaskCreate>({
			query: ({ sectionId, body }) => ({
				url: `create/section/${sectionId}`,
				method: 'POST',
				body: {
					...body,
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
				},
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['task']
		}),
		changeTask: build.mutation<undefined, TaskChange>({
			query: ({ taskId, body }) => ({
				url: `${taskId}/change`,
				method: 'POST',
				body: {
					...body,
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
				},
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['task']
		}),
		deleteTask: build.mutation<undefined, TaskDelete>({
			query: ({ taskId, sectionId }) => ({
				url: `${taskId}/section/${sectionId}`,
				method: 'DELETE',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['task']
		}),
		getTask: build.query<{ data: ITask<string> }, GetTask>({
			query: ({ taskId, userRole }) => ({
				url: `${taskId}`,
				params: {
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					role: userRole
				},
				method: 'GET',
				headers: buildHeaders({ needAuth: true })
			}),
			onQueryStarted(arg): Promise<void> | void {
				if (!arg) {
					throw new Error()
				}
			},
			providesTags: ['task']
		}),
		getUserTasks: build.query<
			{ data: ITaskAnswerResponse[] },
			{ taskId: ResponseEntityId }
		>({
			query: (taskId) => ({
				url: 'get-user-answers',
				method: 'POST',
				body: taskId,
				headers: buildHeaders({ needAuth: true })
			}),
			onQueryStarted(arg): Promise<void> | void {
				if (!arg) {
					throw new Error()
				}
			},
			providesTags: ['task']
		}),
		getAnswers: build.mutation<TaskGetAnswersResponse, TaskAnswersRequest>({
			query: (body) => ({
				url: `get-answers`,
				body,
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['task']
		}),
		checkingResponse: build.mutation<
			{ data: CheckingResponseServerResponse },
			CheckingResponseBody
		>({
			query: (body) => ({
				url: `${body.taskId}/checking-response`,
				body,
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['task']
		}),
		createAnswer: build.mutation<{ data: ITestAnswer[] }, TaskCreateAnswer>({
			query: ({ taskId, body }) => ({
				url: `${taskId}/create-answer`,
				body,
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['task']
		})
	})
})

export default taskQuery
