import React, { useCallback, useEffect, useMemo } from 'react'
import classNames from 'classnames'

import {
	AnswerReviewStatus,
	ITask,
	TaskResponsibilityStatus
} from 'types/models/task.model'

import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ResponseWithError, SchoolRole } from 'types'

import { useNavigate, useParams } from 'react-router-dom'
import { taskQuery } from 'store/queries'
import { useActions, useAppSelector } from 'hooks/redux'
import { TaskGetAnswersResponse } from 'store/queries/task.query/types'
import { parseStringToTagA } from 'helpers/parseStringToTagA'

import cl from './style.module.scss'

interface TeacherTaskInfoProps {
	task: ITask<string>
	setTaskCheckMode: (_: boolean) => void
}

const TeacherTaskInfo: React.FC<TeacherTaskInfoProps> = ({
	task,
	setTaskCheckMode
}) => {
	const navigate = useNavigate()
	const { courseId, taskId } = useParams()
	const { data: currentTaskInfo } = taskQuery.useGetTaskQuery({
		taskId: Number(taskId)
	})
	const { setTask, setTaskAnswerData } = useActions((state) => state.task)
	const { pushError } = useActions((state) => state.system)
	const { taskAnswersInfo } = useAppSelector(
		(state) => state.task
	)

	const { currentRole } = useAppSelector((state) => state.system)

	const [getAnswersTrigger, { data: answersInfo }] =
		taskQuery.useGetAnswersMutation()

	useEffect(() => {
		if (currentTaskInfo) {
			setTask(currentTaskInfo.data)
		}
	}, [])

	useEffect(() => {
		if (answersInfo) {
			setTaskAnswerData(answersInfo)
		}
	}, [answersInfo])

	useEffect(() => {
		if (!currentTaskInfo?.data) {
			return
		}

		; (async () => {
			if (!currentTaskInfo || !currentTaskInfo.data.userAnswersIdList.length) {
				return
			}

			const response = (await getAnswersTrigger({
				userAnswersIdList: currentTaskInfo.data.userAnswersIdList
			})) as {
				data: TaskGetAnswersResponse
			}

			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}

			setTaskAnswerData(response.data)
		})()
	}, [currentTaskInfo])

	const answersInfoCounters = useMemo(() => {
		if (!answersInfo) {
			return {
				countNeedToBeChecked: 0,
				countPassed: 0,
				totalCountStudents: 0
			}
		}

		let countNeedToBeChecked = 0

		taskAnswersInfo.forEach((t) => {
			if (t.answerStatus === AnswerReviewStatus.NEED_CHECK) {
				countNeedToBeChecked += 1
			}
		})

		return {
			countNeedToBeChecked,
			countPassed: answersInfo.countPastStudents,
			totalCountStudents: answersInfo.totalCountStudents
		}
	}, [taskAnswersInfo, answersInfo])

	const onTasksCheckModeSwitch = useCallback(() => {
		if (!currentTaskInfo || !currentTaskInfo.data.userAnswersIdList.length) {
			pushError({ message: 'Ни одной попытки не найдено' })

			return
		}

		setTaskCheckMode(true)
	}, [currentTaskInfo, setTaskCheckMode])

	const getStartTimeToPassTestString = () => {
		let str = ''

		if (task.datePassStartSettings.isActive) {
			str += `${new Date(
				task.datePassStartSettings.date
			).toLocaleDateString()} `
			str += task.datePassStartSettings.time
		} else if (task.datePassEndSettings.isActive) {
			str += 'Без ограничений'
		}

		return str
	}

	const getEndTimeToPassTestString = () => {
		let str = ''

		if (task.datePassEndSettings.isActive) {
			str += `${new Date(task.datePassEndSettings.date).toLocaleDateString()} `
			str += task.datePassEndSettings.time
		} else if (task.datePassStartSettings.isActive) {
			str += 'Без ограничений'
		}

		return str
	}

	return (
		<>
			<div className={cl.content}>{currentTaskInfo?.data.name}</div>

			<div className={classNames(cl.content, cl.secondary)}>
				<div className={cl.header}>
					<h1 className={cl.title}>{task.name}</h1>

					<div className={cl.buttons}>
						<Button
							styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
							onClick={onTasksCheckModeSwitch}
						>
							Проверить работы учеников
						</Button>
						{currentRole === SchoolRole.ADMIN || currentRole === SchoolRole.TEACHER && (
							<Button
								styleTypes={[ButtonStyles.OUTLINE_DARK, ButtonStyles.ROUND]}
								onClick={() =>
									navigate(`/course/${courseId}/task/edit/${taskId}`)
								}
							>
								Редактировать
							</Button>
						)}
					</div>
				</div>

				<div className={cl.grid}>
					<div className={cl.cell}>
						<span>Учеников прошло</span>
						<span>
							{answersInfoCounters.countPassed} из{' '}
							{answersInfoCounters.totalCountStudents}
						</span>
					</div>
					<div className={cl.cell}>
						<span>Ответов</span>
						<span>
							{currentTaskInfo?.data.userAnswersIdList.length} из{' '}
							{taskAnswersInfo.length}
						</span>
					</div>
					<div className={cl.cell}>
						<span>Требуют проверки</span>
						<span>
							{answersInfoCounters.countNeedToBeChecked} из{' '}
							{taskAnswersInfo.length}
						</span>
					</div>
					<div className={cl.cell}>
						<span>Обязательное</span>
						<span>
							{task.taskResponsibilityStatus ===
								TaskResponsibilityStatus.REQUIRED
								? 'Да'
								: 'Нет'}
						</span>
					</div>
					<div className={cl.cell}>
						<span>Период прохождения</span>
						<span>
							<span>{getStartTimeToPassTestString()}</span> <span>—</span>{' '}
							<span>{getEndTimeToPassTestString()}</span>{' '}
						</span>
					</div>
				</div>

				{task.description &&
					task.description.split('\n').map((text) => (
						<div className={cl.descr} key={text}>
							{parseStringToTagA(text, '_blank')}
						</div>
					))}
			</div>
		</>
	)
}

export default TeacherTaskInfo
