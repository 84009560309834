type makeStringShorterType = (
	stringToMakeShorter: string,
	shortenTo: number
) => {}

const makeStringShorter: makeStringShorterType = (
	stringToMakeShorter,
	shortenTo
) => {
	if (stringToMakeShorter.length > shortenTo) {
		return stringToMakeShorter.slice(0, shortenTo).concat('...')
	}

	return stringToMakeShorter
}

export default makeStringShorter
