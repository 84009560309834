import {
	KinescopeUploadHeaders,
	UploadMedia
} from 'store/queries/kinescope.query/types'

const getHeadersForKinescopeUpload = ({
	projectId,
	folderId,
	title,
	descr,
	fileName,
	token
}: Omit<UploadMedia, 'body'>): KinescopeUploadHeaders => ({
	'X-Folder-ID': folderId,
	'X-Project-ID': projectId,
	'X-Video-Title': encodeURIComponent(title),
	Authorization: `Bearer ${token}`,
	'X-File-Name': fileName,
	'X-Video-Description': descr
})

export default getHeadersForKinescopeUpload
