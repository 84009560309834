import { ResponseEntityId } from 'types'

export enum TestTypes {
	INTERMEDIATE = 1,
	FINAL = 2
}
export enum TestResponsibilityStatus {
	REQUIRED = 2,
	OPTIONAL = 1
}
interface PassingTimeLimit {
	isActive: boolean
	timeLimitSeconds: number
}

interface DatePassSettings<T extends Date | string> {
	isActive: boolean
	date: T
	time: string
}

export interface Answer {
	id: number
	value: string
	comment?: string
}

export enum AnswerType {
	SINGLE = 1,
	MULTIPLE = 2
}

export enum AnswerTestReviewStatus {
	PASS = 'Пройден',
	NOT_PASS = 'Не пройден'
}

export interface Question {
	id?: number
	name: string
	answerType: AnswerType
	answers: Answer[]
	correctAnswersIdList: number | number[] | undefined
	picturesInfoList?: PicturesInfoItem[]
	pictures?: File
}

export interface PicturesInfoItem {
	id: ResponseEntityId
	url: string
}
export interface ITest<T extends Date | string = Date> {
	id?: ResponseEntityId
	type: TestTypes
	name: string
	description: string
	questions: Question[]
	testResponsibilityStatus: TestResponsibilityStatus
	passingTimeLimit: PassingTimeLimit
	passingScore: number
	countAttempts: number
	datePassStartSettings: DatePassSettings<T>
	datePassEndSettings: DatePassSettings<T>
	isActive: number
	userAnswersIdList: string[]
	testResponsibilityAnswersStatus: TestResponsibilityStatus
}
