import React, { FC, useMemo, useState } from 'react'
import { ITab } from 'UI/Tabs/types'
import { getUniqueId } from 'helpers'
import { Tabs } from 'UI'

import MainTab from 'containers/Courses/Tabs/ViewContent/MainTab'
import AboutTab from 'containers/Courses/Tabs/ViewContent/About'
import FeedbacksTab from 'containers/Courses/Tabs/ViewContent/Feedback'
import { ICourse } from 'types/models/course.model'
import { ITag } from 'UI/Tags/types'
import userQuery from 'store/queries/user.query'

import cl from '../style.module.scss'

export interface CoursesTabsProps {
	courseInfo: ICourse
}

const CoursesTabs: FC<CoursesTabsProps> = ({ courseInfo }) => {
	const tagsList = useMemo(
		(): ITag[] => [
			{ id: 1, text: 'IT и программирование' },
			{ id: 2, text: 'Финансовая грамотность' },
			{ id: 3, text: 'Дизайн' },
			{ id: 4, text: 'Образование' },
			{ id: 5, text: 'Маркетинг' },
			{ id: 6, text: 'Soft Skills' },
			{ id: 7, text: 'Аналитика' },
			{ id: 8, text: 'Хобби' },
			{ id: 9, text: 'Спорт и здоровье' },
			{ id: 10, text: 'Фото и видео' },
			{ id: 11, text: 'Музыка' },
			{ id: 12, text: 'Другое' }
		],
		[]
	)

	const tabsList = useMemo(
		(): ITab[] => [
			{ id: getUniqueId(), text: 'Содержание' },
			{ id: getUniqueId(), text: 'О курсе' },
			{ id: getUniqueId(), text: 'Отзывы' }
		],
		[]
	)

	const [selectedTabId, setSelectedTabId] = useState<ITab['id']>(
		[...tabsList].shift()!.id
	)

	const { data: creatorInfo } = userQuery.useGetUserByIdQuery(
		courseInfo.creatorId
	)

	return (
		<div className={cl.container}>
			<div className={cl.tabsToggles}>
				<Tabs
					value={selectedTabId}
					tabs={tabsList}
					onChange={setSelectedTabId}
				/>
				<span className={cl.required}>* Обязательные поля</span>
			</div>
			<div className={cl.tabsContentView}>
				{selectedTabId === tabsList[0].id && (
					<MainTab courseInfo={courseInfo} />
				)}
				{selectedTabId === tabsList[1].id && (
					<AboutTab
						creatorInfo={creatorInfo?.data}
						tagsList={tagsList}
						courseInfo={courseInfo}
					/>
				)}
				{selectedTabId === tabsList[2].id && (
					<FeedbacksTab courseInfo={courseInfo} />
				)}
			</div>
		</div>
	)
}

export default CoursesTabs
