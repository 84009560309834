import React, { forwardRef } from 'react'
import { EventsPopupContainerProps } from 'containers/Timetable/Tabs/WeekTab/EventsPopup'

import { getMonthNameByItsNumber, getNameDayOfTheWeek } from 'helpers/time'
import { makeStringShorter } from 'helpers/text'
import CloseIcon from 'components/Timetable/Tabs/WeekTab/EventsPopup/Images/CloseIcon'
import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import cl from './style.module.scss'

interface EventsPopupComponentProps
	extends Omit<EventsPopupContainerProps, 'open'> {}

const EventsPopup = forwardRef<HTMLDivElement, EventsPopupComponentProps>(
	({ eventsDate, activeEvents, onHideRestOfEvents, onDetailsOpen }, ref) => (
		<div className={cl.popup} ref={ref}>
			<div className={cl.header}>
				<div>
					{getNameDayOfTheWeek(eventsDate.getDay())}, {eventsDate.getDate()}{' '}
					{getMonthNameByItsNumber(eventsDate.getMonth())}
				</div>
				<Button
					styleTypes={[ButtonStyles.DEFAULT]}
					onClick={onHideRestOfEvents}
				>
					<CloseIcon />
				</Button>
			</div>
			<div className={cl.events}>
				{activeEvents.map((e) => (
					<div className={cl.label} key={e.id} onClick={() => onDetailsOpen(e)}>
						{makeStringShorter(e.theme, 20)}
					</div>
				))}
			</div>
		</div>
	)
)

export default EventsPopup
