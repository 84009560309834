import React, { FC, MutableRefObject, useRef } from 'react'
import classnames from 'classnames'

import { ITab } from './types'

import cl from './style.module.scss'

interface TabProps {
	tab: ITab
	isActive: boolean
	onClick: (tabId: ITab['id'], tabTitle: string) => void
	disabled?: boolean
	getRef?: (ref: MutableRefObject<HTMLDivElement>) => void
}

const Tab: FC<TabProps> = ({ tab, isActive, onClick, disabled, getRef }) => {
	const ref = useRef<HTMLDivElement>(null)

	return (
		<div
			className={classnames([cl.tab], {
				[cl.tabSelected]: isActive,
				[cl.tabDisabled]: disabled
			})}
			onClick={() => {
				onClick(tab.id, tab.text)
				if (getRef) {
					getRef(ref as MutableRefObject<HTMLDivElement>)
				}
			}}
			ref={ref}
		>
			<p>{tab.text}</p>
		</div>
	)
}

export default Tab
