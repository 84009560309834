import React, { useEffect } from 'react'
import TimetableContentComponent from 'components/Timetable/TimetableContent'
import { FieldPath, FieldPathValue, useForm, useWatch } from 'react-hook-form'
import { useAppSelector } from 'hooks/redux'
import eventsQuery from 'store/queries/events.query'
import { IEvent } from 'types/models/event.model'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { getFirstAndLastDateDependingOnMode } from 'helpers/time'
import dayjs, { Dayjs } from 'dayjs'

export enum TimetableModeTypes {
	MONTH,
	WEEK,
	SCHEDULE
}

export interface TimetableContentFormValues {
	currentDate: Date
	currentDayjsDate: Dayjs
	mode: TimetableModeTypes
	events: IEvent[]
}

export interface TimetableContentContainerProps {}

const TimetableContent: React.FC<TimetableContentContainerProps> = () => {
	const { register, setValue, control } = useForm<TimetableContentFormValues>()

	const { currentSchoolId } = useAppSelector((state) => state.system)

	useEffect(() => {
		register('mode', { value: TimetableModeTypes.SCHEDULE })
		register('currentDate')
		register('currentDayjsDate')
	}, [register])

	useEffect(() => {
		setTimeout(() => {
			setValue('currentDate', new Date())
			setValue('currentDayjsDate', dayjs(new Date()))
			setValue('mode', TimetableModeTypes.SCHEDULE)
		}, 500)
	}, [setValue])

	const currentDate = useWatch({
		control,
		name: 'currentDate'
	})

	const mode = useWatch({
		control,
		name: 'mode'
	})

	const [triggerGetEvents, { data: events, isLoading }] =
		eventsQuery.useLazyGetEventsForSchoolQuery()

	useEffect(() => {
		if (!events) return

		setValue('events', events.data)
	}, [events])

	useEffect(() => {
		if (!currentDate) return

		const { lastDate, firstDate } = getFirstAndLastDateDependingOnMode({
			mode,
			currentDate
		})

		const payload = {
			schoolId: currentSchoolId,
			dateFrom: firstDate.toLocaleDateString('ru'),
			dateTo: lastDate.toLocaleDateString('ru')
		}

		triggerGetEvents(payload)
	}, [currentSchoolId, currentDate, mode, triggerGetEvents])

	const onChange =
		(name: FieldPath<TimetableContentFormValues>) =>
		(value: FieldPathValue<TimetableContentFormValues, typeof name>) => {
			setValue(name, value)
		}

	if (!events || isLoading) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return <TimetableContentComponent control={control} onChange={onChange} />
}

export default TimetableContent
