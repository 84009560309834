import React, { ChangeEvent, FC, InputHTMLAttributes, useMemo } from 'react'
import { Control, useWatch } from 'react-hook-form'

import { getUniqueId } from 'helpers'

import { TextHelper } from 'UI/index'
import cl from './style.module.scss'

interface CheckboxProps
	extends Omit<
		InputHTMLAttributes<HTMLInputElement>,
		'value' | 'onChange' | 'name'
	> {
	onChange: (value: boolean) => void
	name: string
	control: Control<any>
	labelTitle: string
	helperText?: string
}

const Checkbox: FC<CheckboxProps> = ({
	id,
	labelTitle,
	onChange,
	control,
	name,
	helperText,
	...defaultProps
}) => {
	const uniqueId = useMemo(getUniqueId.bind(null, id), [])

	const value: boolean = useWatch({ control, name })

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		onChange(event.target.checked)
	}

	return (
		<div className={cl.container}>
			<input
				checked={value}
				id={uniqueId}
				onChange={changeHandler}
				type="checkbox"
				name={name}
				{...defaultProps}
			/>
			<label htmlFor={uniqueId}>{labelTitle}</label>
			{helperText && <TextHelper text={helperText} />}
		</div>
	)
}

export default Checkbox
