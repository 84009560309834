import React, { FC, useCallback, useEffect, useState } from 'react'
import { FieldPath, useForm } from 'react-hook-form'

import { ResponseEntityId, ValidationErrorMessages } from 'types'
import { ContentFormTab as ContentFormTabComponent } from 'components/Courses/Tabs/Content'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import CreateSectionModal from './modals/CreateSection'
import EditSectionModal from './modals/EditSection'

export interface SectionFormState {
	sectionName: string
}

interface ContentFormTabProps {
	courseId: ResponseEntityId
	sectionsIdList: ResponseEntityId[]
}

const ContentFormTab: FC<ContentFormTabProps> = ({
	sectionsIdList,
	courseId
}) => {
	if (!courseId) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	const [, setSortedSectionsIdList] =
		useState<ResponseEntityId[]>(sectionsIdList)

	const [currentSectionId, setCurrentSectionId] =
		useState<ResponseEntityId | null>()
	const [createSectionModalIsOpen, setCreateSectionModalIsOpen] =
		useState(false)
	const [editSectionModalIsOpen, setEditSectionModalIsOpen] = useState(false)
	const openCreateSectionModalHandler = useCallback(() => {
		setCreateSectionModalIsOpen(true)
	}, [])
	const openEditSectionModalHandler = useCallback((id) => {
		setEditSectionModalIsOpen(true)
		setCurrentSectionId(id)
	}, [])
	const closeCreateSectionModalHandler = useCallback(() => {
		setCreateSectionModalIsOpen(false)
	}, [])
	const closeEditSectionModalHandler = useCallback(() => {
		setEditSectionModalIsOpen(false)
		setCurrentSectionId(null)
	}, [])

	const {
		register,
		control,
		setValue,
		formState: { errors },
		handleSubmit
	} = useForm<SectionFormState>()

	useEffect(() => {
		register('sectionName', {
			required: ValidationErrorMessages.EMPTY,
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
	}, [])

	useEffect(() => {
		setSortedSectionsIdList(sectionsIdList)
	}, [sectionsIdList])

	const changeHandler = useCallback(
		(name: FieldPath<SectionFormState>) => (value: any) =>
			setValue(name, value),
		[]
	)

	return (
		<>
			{currentSectionId && (
				<EditSectionModal
					setValue={setValue}
					currentSectionId={currentSectionId}
					handleSubmit={handleSubmit}
					onChange={changeHandler}
					errors={errors}
					control={control}
					isModalOpen={editSectionModalIsOpen}
					closeModal={closeEditSectionModalHandler}
				/>
			)}
			<CreateSectionModal
				setValue={setValue}
				handleSubmit={handleSubmit}
				onChange={changeHandler}
				errors={errors}
				control={control}
				isModalOpen={createSectionModalIsOpen}
				closeModal={closeCreateSectionModalHandler}
				courseId={courseId}
			/>
			<ContentFormTabComponent
				setSortedSectionsIdList={setSortedSectionsIdList}
				courseId={courseId}
				sectionsIdList={sectionsIdList}
				openCreateSectionModal={openCreateSectionModalHandler}
				openEditSectionModal={openEditSectionModalHandler}
			/>
		</>
	)
}

export default ContentFormTab
