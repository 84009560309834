import React, { useCallback, useEffect, useState } from 'react'
import { FieldPath, useForm } from 'react-hook-form'
import CheckResponseComponent from 'components/Courses/Entities/Tasks/CheckResponse'
import { taskQuery } from 'store/queries'
import { ResponseWithError, ValidationSuccessMessages } from 'types'
import { useActions, useAppSelector } from 'hooks/redux'
import { ITask, ITaskAnswerResponse } from 'types/models/task.model'
import { TaskGetAnswersResponse } from 'store/queries/task.query/types'

export interface CheckResponseFormValues {
	assessment: number | string
	comment: string
}

export interface CheckResponseContainerProps {
	creatorId: number
	passingScore: number
	answerId: string
	taskId: string
	taskCurrentAnswer: ITaskAnswerResponse
	taskAnswersInfo: ITaskAnswerResponse[]
	editMode: boolean
	task: ITask<string>
	setEditMode: (_: boolean) => void
	disableShowNextBtn?: boolean
	currentAnswerIndex: number
}

const CheckResponse: React.FC<CheckResponseContainerProps> = ({
	answerId,
	taskId,
	taskCurrentAnswer,
	editMode,
	setEditMode,
	task,
	currentAnswerIndex,
	taskAnswersInfo,
	...rest
}) => {
	const { register, handleSubmit, watch, control, setValue } =
		useForm<CheckResponseFormValues>()
	const [triggerCheckResponse] = taskQuery.useCheckingResponseMutation()
	const [triggerGetAnswers] = taskQuery.useGetAnswersMutation()
	const [loading, setLoading] = useState(false)
	const { pushError, pushSuccess } = useActions((state) => state.system)

	const { setCurrentTaskAnswer, setTaskAnswerData, showNextTask } = useActions(
		(state) => state.task
	)
	const { taskCurrentSort } = useAppSelector((state) => state.task)

	const changeHandler = useCallback(
		(name: FieldPath<CheckResponseFormValues>) => (value: any) => {
			setValue(name, value)
		},
		[]
	)

	const nextTaskInfo = taskAnswersInfo[(currentAnswerIndex as number) + 1]

	const onSubmit = (nextTask?: boolean) =>
		handleSubmit(async (data) => {
			setLoading(true)
			if (nextTask) {
				showNextTask(true)
			}

			const response = await triggerCheckResponse({
				assessment: data.assessment as number,
				teacherComment: data.comment,
				userAnswerId: answerId,
				taskId
			})

			const { error } = response as unknown as ResponseWithError

			if (error) {
				pushError(error.data)
				setLoading(false)
				return
			}

			const responseGetAnswers = await triggerGetAnswers({
				filterStatus: taskCurrentSort?.filterStatus,
				userAnswersIdList: task.userAnswersIdList
			})

			const { error: getAnswersError } =
				responseGetAnswers as unknown as ResponseWithError

			if (getAnswersError) {
				pushError(getAnswersError.data)
				setLoading(false)
				return
			}

			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}

			const { data: currentTaskAnswersInfo } =
				responseGetAnswers as unknown as {
					data: TaskGetAnswersResponse
				}

			const currentAnswerFound = currentTaskAnswersInfo.data.find(
				(a) => a.id.toString() === answerId
			)

			setTaskAnswerData(currentTaskAnswersInfo)

			if (nextTaskInfo) {
				setCurrentTaskAnswer(nextTaskInfo)
				if (successMessage) {
					setValue('assessment', '')
				}
			}

			if (currentAnswerFound && !nextTask) {
				setCurrentTaskAnswer(currentAnswerFound)
			}

			pushSuccess(successMessage)
			setEditMode(false)
			setLoading(false)
		})()

	useEffect(() => {
		register('assessment', {
			required: true,
			validate: (val) => +val >= 0 && +val <= 10
		})
		register('comment')
	}, [])

	useEffect(() => {
		if (!editMode) return

		setValue('assessment', taskCurrentAnswer.assessment)
		setValue('comment', taskCurrentAnswer.teacherComment)
	}, [setValue, taskCurrentAnswer])

	return (
		<>
			<CheckResponseComponent
				control={control}
				onChange={changeHandler}
				answerId={answerId}
				onSubmit={onSubmit}
				watch={watch}
				loading={loading}
				disableShowNextBtn={!!nextTaskInfo}
				{...rest}
			/>
		</>
	)
}

export default CheckResponse
