import React, { FC, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useDebouncedCallback } from 'hooks'
import { Control, useWatch } from 'react-hook-form'
import TextInput from 'UI/Input/TextInput'
import { InputValidate, TextInputProps } from 'UI/Input/types'
import Option from 'UI/Select/Option'
import { IOption } from 'UI/Select/types'

import cl from './style.module.scss'

interface SelectInputProps extends Omit<TextInputProps, 'onFocus' | 'onBlur'> {
	onChange: (value: string | number) => void
	control: Control<any>
	name: string
	styleTypes?: InputValidate[]
	options: IOption[]
	getOptionsCallback: (hint: string) => Promise<unknown>
	isNeedOnBlur?: boolean 
}

const SelectInput: FC<SelectInputProps> = ({
	control,
	onChange,
	name,
	styleTypes,
	options,
	getOptionsCallback,
	isNeedOnBlur = true,
	...props
}) => {
	const value = useWatch({ control, name })
	const [search, setSearch] = useState(value || '')
	const [open, setOpen] = useState(false)
	const debouncedGetOptionsCb = useDebouncedCallback(getOptionsCallback, 400)

	useEffect(() => {
		setSearch(value)
	}, [value])

	const onSearchTextChange = useCallback(
		(val: string | number) => {
			if (search === '') {
				onChange('')
			}
			setSearch(val.toString())
			debouncedGetOptionsCb(val.toString())
		},
		[debouncedGetOptionsCb]
	)

	const changeHandler = useCallback(
		(optionValue: IOption['value']) => {
			setSearch(optionValue.toString())
			onChange(optionValue)
			setOpen(false)
		},
		[onChange]
	)

	const onFocus = useCallback(() => {
		if (options && options.length) {
			setOpen(true)
		}
	}, [options])
	const onBlur = useCallback(() => setOpen(false), [])

	useEffect(() => {
		if (options && options.length) {
			setOpen(true)
		} else {
			setOpen(false)
		}
	}, [options])

	return (
		<div className={cl.selectInput}>
			<TextInput
				control={control}
				onChange={onSearchTextChange}
				value={search}
				name=""
				{...props}
				onFocus={onFocus}
				onBlur={isNeedOnBlur ? onBlur : undefined}
			/>
			<div
				className={classNames(cl.options, {
					[cl.shown]: open
				})}
			>
				{options.map((o) => (
					<Option
						value={value}
						onChange={changeHandler}
						optionItem={o}
						key={o.value}
					/>
				))}
			</div>
		</div>
	)
}

export default SelectInput
