import React from 'react'
import { useAppSelector } from 'hooks/redux'
import { CourseRole } from 'types'

import TeacherCommentComponent from 'components/Courses/Entities/Tasks/TeacherComment'

export interface TeacherCommentContainerProps {
	creatorId: number
	date: string
	comment: string
	viewMode?: boolean
	setEditMode?: (_: boolean) => void
}

const TeacherComment: React.FC<TeacherCommentContainerProps> = ({
	creatorId,
	date,
	comment,
	viewMode,
	setEditMode
}) => {
	const { currentUserId } = useAppSelector((state) => state.user)
	const { currentRole } = useAppSelector((state) => state.system)
	const canCurrentUserEdit = currentUserId === creatorId && currentRole !== CourseRole.STUDENT

	return (
		<TeacherCommentComponent
			creatorId={creatorId}
			date={date}
			comment={comment}
			viewMode={viewMode}
			setEditMode={setEditMode}
			allowEdit={canCurrentUserEdit}
		/>
	)
}

export default TeacherComment
