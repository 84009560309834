import React from "react"

export 	const parseStringToTagA = (textData: string, target?: string) => (
    textData.split(' ').map(text => {
        if (text.includes('www')) {
            return <a target={target} href={`//${text}`}> {text} </a>
        }
        if (text.includes('http')) {
            return <a target={target} href={text}> {text} </a>
        }
        return ` ${text} ` 
    })

)

