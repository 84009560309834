import React, { FC } from 'react'
import { IconProps } from './BellIcon'

const ProfileIcon: FC<IconProps> = ({ fill }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.1247 0.333313C5.80017 0.333313 0.458008 5.67548 0.458008 12C0.458008 18.3245 5.80017 23.6666 12.1247 23.6666C18.4492 23.6666 23.7913 18.3245 23.7913 12C23.7913 5.67548 18.4492 0.333313 12.1247 0.333313ZM12.1247 6.16665C14.1395 6.16665 15.6247 7.65065 15.6247 9.66665C15.6247 11.6826 14.1395 13.1666 12.1247 13.1666C10.111 13.1666 8.62467 11.6826 8.62467 9.66665C8.62467 7.65065 10.111 6.16665 12.1247 6.16665ZM6.16767 17.5673C7.21417 16.0273 8.95951 15.0006 10.958 15.0006H13.2913C15.291 15.0006 17.0352 16.0273 18.0817 17.5673C16.5907 19.1633 14.4755 20.1666 12.1247 20.1666C9.77384 20.1666 7.65867 19.1633 6.16767 17.5673Z"
			fill={fill || '#666666'}
		/>
	</svg>
)

export default ProfileIcon
