import React, { useCallback, useMemo, useState } from 'react'
import { IRate } from 'types/models/rate.model'

import TariffCardComponent from 'components/Tariffs/TariffsPlans/TariffCard'
import { paymentsQuery } from 'store/queries'
import ConfirmSwitchModal from 'components/Tariffs/ConfirmSwitchModal'
import { TariffsFormValues } from 'containers/Tariffs'
import { Control, useWatch } from 'react-hook-form'
import { SwitchTariffResponse } from 'store/queries/payments.query/types'
import ConfirmTariffRenewal from 'components/Tariffs/ConfirmTariffRenewal'
import { makeTariffValidityString } from 'helpers/text'
import { getFileSizeFromBytesInGb } from 'helpers/files'

export interface TariffCardContainerProps extends IRate {
	isActive: boolean
	control: Control<TariffsFormValues>
	isPaidTariffActive?: boolean
}

const TariffCard: React.FC<TariffCardContainerProps> = ({
	id,
	text,
	value,
	isActive,
	control,
	isPaidTariffActive
}) => {
	const currentPeriod = useWatch({
		control,
		name: 'currentPeriod'
	})

	const currentTariffWithCurrentPeriod = useMemo(
		() =>
			value.priceAndPeriod.find((p) => +p.validityPeriod === +currentPeriod),
		[currentPeriod]
	)

	const [confirmModalOpen, setConfirmModalOpen] = useState(false)
	const [tariffRenewalOpen, setTariffRenewalOpen] = useState(false)
	const [triggerSwitch] = paymentsQuery.useGetTariffsMutation()

	// подключает тариф
	const onSwitchTariff = useCallback(
		async (extendsTariff: boolean) => {
			if (!currentTariffWithCurrentPeriod) return
			// если сразу меняем передаем deffered:false, если откладываем  true
			const { price, validityPeriod } = currentTariffWithCurrentPeriod
			const { data } = (await triggerSwitch({
				tariffId: id,
				price,
				validityPeriod,
				deferred: extendsTariff
			})) as unknown as { data: SwitchTariffResponse }

			if (!data || !data.url) return

			window.location.replace(data.url)
		},
		[id, currentTariffWithCurrentPeriod]
	)

	const onClose = useCallback(
		() => setConfirmModalOpen(false),
		[setConfirmModalOpen]
	)

	const onOpen = useCallback(
		() => setConfirmModalOpen(true),
		[setConfirmModalOpen]
	)

	const onTariffRenewalOpen = useCallback(() => setTariffRenewalOpen(true), [])
	const onTariffRenewalClose = useCallback(
		() => setTariffRenewalOpen(false),
		[]
	)

	if (!currentTariffWithCurrentPeriod) return null

	const { name, numberCourses, numberUsers, fileSize } = value
	const { price, validityPeriod, previousPrice, discountPercentage } =
		currentTariffWithCurrentPeriod

	const fileSizeInGb = getFileSizeFromBytesInGb(fileSize)

	return (
		<>
			<TariffCardComponent
				id={id}
				text={text}
				fileSize={fileSizeInGb}
				name={name}
				numberCourses={numberCourses}
				numberUsers={numberUsers}
				price={price}
				validityPeriodText={makeTariffValidityString(validityPeriod)}
				isActive={isActive}
				onModalOpen={onOpen}
				onTariffRenewal={onTariffRenewalOpen}
				discountPercentage={discountPercentage}
				previousPrice={previousPrice}
			/>
			<ConfirmSwitchModal
				isOpen={confirmModalOpen}
				onClose={onClose}
				onConfirm={onSwitchTariff}
				courseLimit={numberCourses}
				usersLimit={numberUsers}
				tariffName={name}
				isPaidTariffActive={isPaidTariffActive}
			/>
			<ConfirmTariffRenewal
				isOpen={tariffRenewalOpen}
				onClose={onTariffRenewalClose}
				onConfirm={onSwitchTariff}
				validityPeriod={validityPeriod}
				tariffName={name}
			/>
		</>
	)
}

export default TariffCard
