import React, { FC, FormEvent } from 'react'
import { Control, FieldErrors, FieldPath } from 'react-hook-form'

import { Button, RadioGroup } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import ValidateNotify from 'UI/Input/ValidateNotify'
import { LimitationsFormTabState } from 'containers/Courses/Entities/Lessons/Tabs/Content/LimitationsForm'

import cl from '../style.module.scss'

interface LimitationsFormTabProps {
	errors: FieldErrors<LimitationsFormTabState>
	onChange: (name: FieldPath<LimitationsFormTabState>) => (value: any) => void
	onSubmit: (event: FormEvent) => void
	control: Control<LimitationsFormTabState>
	onOpenCanceledChange: () => void
}

const LimitationsFormTab: FC<LimitationsFormTabProps> = ({
	errors,
	onChange,
	control,
	onSubmit,
	onOpenCanceledChange
}) => (
	<div className={cl.container}>
		<div className={cl.header}>
			<span>Вы можете установить обязательность ответа на задание.</span>
		</div>
		<form noValidate onSubmit={onSubmit} className={cl.form}>
			<div className={cl.inputs}>
				<div className={cl.inputContainer}>
					<label>Урок является обязательным?</label>
					<RadioGroup
						name="lessonResponsibilityStatus"
						itemsList={[
							{
								id: '2',
								value: 2,
								labelTitle: 'Да'
							},
							{
								id: '1',
								value: 1,
								labelTitle: 'Нет'
							}
						]}
						control={control}
						onChange={onChange('lessonResponsibilityStatus')}
					/>
					<ValidateNotify
						className={cl.validateNotify}
						error={errors.lessonResponsibilityStatus?.message}
					/>
				</div>
				<div className={cl.splitLine} />
			</div>
			<div className={cl.buttons}>
				<Button
					onClick={onOpenCanceledChange}
					styleTypes={[ButtonStyles.OUTLINE_PRIMARY, ButtonStyles.ROUND]}
				>
					Отменить изменения
				</Button>
				<Button
					type="submit"
					styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
				>
					Сохранить изменения
				</Button>
			</div>
		</form>
	</div>
)

export default LimitationsFormTab
