import React, { FC } from 'react'
import { CoursesTabsProps } from 'containers/Courses/Tabs/View/Wrapper.container'
import MainFormTable from 'containers/Courses/Tabs/ViewContent/MainTab/Table'

interface MainTabProps extends CoursesTabsProps {}

const MainTab: FC<MainTabProps> = ({ courseInfo }) => (
	<MainFormTable
		courseId={courseInfo.id}
		sectionsIdList={courseInfo.sectionsIdList}
	/>
)

export default MainTab
