import React from 'react'

import cl from '../style.module.scss'

const SortToBottomArrow = () => (
	<div className={cl.arrow}>
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.8333 12.9931L10.8333 5.00477L9.16667 5.00477L9.16667 12.9931L5.58917 9.41561L4.41084 10.5939L10 16.1831L15.5892 10.5939L14.4108 9.41561L10.8333 12.9931Z"
				fill="#999999"
			/>
		</svg>
	</div>
)

export default SortToBottomArrow
