import React, { FC, useEffect, useMemo, useState } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch
} from 'react-hook-form'

import { getUniqueId } from 'helpers'
import { Tabs } from 'UI'
import { ITab } from 'UI/Tabs/types'
import { LessonFormState } from 'pages/Course/Lesson/Create.page'
import MainFormTab, { MainFormTabState } from '../Content/MainForm'

import cl from '../style.module.scss'

interface LessonsTabsProps {
	errors: FieldErrors<LessonFormState>
	onChange: (name: FieldPath<LessonFormState>) => (value: any) => void
	control: Control<LessonFormState>
	register: UseFormRegister<LessonFormState>
	handleSubmit: UseFormHandleSubmit<LessonFormState>
	watch: UseFormWatch<LessonFormState>
	setValue: UseFormSetValue<LessonFormState>
	getValues: UseFormGetValues<LessonFormState>
	onOpenCanceledChange: () => void
}

const LessonsTabs: FC<LessonsTabsProps> = ({
	getValues,
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	watch,
	setValue,
	onOpenCanceledChange
}) => {
	const tabsList = useMemo(
		(): ITab[] => [
			{ id: getUniqueId(), text: 'Основная информация' },
			{ id: getUniqueId(), text: 'Ограничения' }
		],
		[]
	)
	const [selectedTabId, setSelectedTabId] = useState<ITab['id']>(tabsList[0].id)

	useEffect(() => {
		setValue('mediaFilesUrlList', { url: '', type: '2' })
	}, [])

	return (
		// TODO refactor
		<div className={cl.container}>
			<div className={cl.tabsToggles}>
				<Tabs
					disabled
					value={selectedTabId}
					tabs={tabsList}
					onChange={setSelectedTabId}
				/>
			</div>
			<div className={cl.tabsContent}>
				{selectedTabId === tabsList[0].id && (
					<MainFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						setValue={setValue as unknown as UseFormSetValue<MainFormTabState>}
						register={register as unknown as UseFormRegister<MainFormTabState>}
						control={control as unknown as Control<MainFormTabState>}
						onChange={onChange}
						errors={errors}
						getValues={
							getValues as unknown as UseFormGetValues<MainFormTabState>
						}
						handleSubmit={handleSubmit}
						watch={watch as unknown as UseFormWatch<MainFormTabState>}
					/>
				)}
			</div>
		</div>
	)
}

export default LessonsTabs
