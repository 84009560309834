import React, { FC, useEffect, useMemo, useState } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch
} from 'react-hook-form'
import classnames from 'classnames'
import { DevTool } from '@hookform/devtools'

import { getUniqueId } from 'helpers'
import { Tabs } from 'UI'
import { ITab } from 'UI/Tabs/types'
import { TaskFormState } from 'pages/Course/Task/Create.page'
import { ITask } from 'types/models/task.model'
import MainFormTab, { MainFormTabState } from '../Content/MainForm'
import AnswerSettingsFormTab, {
	AnswerSettingsFormTabState
} from '../Content/AnswerSettingsForm'
import LimitationsFormTab, {
	LimitationsFormTabState
} from '../Content/LimitationsForm'

import cl from '../style.module.scss'

interface TasksTabsProps {
	isPreview?: boolean
	errors: FieldErrors<TaskFormState>
	onChange: (name: FieldPath<TaskFormState>) => (value: any) => void
	control: Control<TaskFormState>
	register: UseFormRegister<TaskFormState>
	handleSubmit: UseFormHandleSubmit<TaskFormState>
	watch: UseFormWatch<TaskFormState>
	getValues: UseFormGetValues<TaskFormState>
	setValue: UseFormSetValue<TaskFormState>
	currentTaskInfo: ITask<string>
	onOpenCanceledChange: () => void
}

const TasksTabs: FC<TasksTabsProps> = ({
	isPreview = false,
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	watch,
	getValues,
	currentTaskInfo,
	setValue,
	onOpenCanceledChange
}) => {
	const tabsList = useMemo(
		(): ITab[] => [
			{ id: getUniqueId(), text: 'Основная информация' },
			{ id: getUniqueId(), text: 'Настройки ответа' },
			{ id: getUniqueId(), text: 'Ограничения' }
		],
		[]
	)
	useEffect(() => {
		setValue('id', currentTaskInfo.id)
		setValue('name', currentTaskInfo.name)
		setValue('description', currentTaskInfo.description)
		setValue(
			'taskResponsibilityStatus',
			currentTaskInfo.taskResponsibilityStatus
		)
		setValue(
			'answerResponsibilitiesType',
			currentTaskInfo.answerResponsibilitiesType
		)
		setValue('datePassStartSettings', currentTaskInfo.datePassStartSettings)
		setValue(
			'datePassStartSettings.time',
			currentTaskInfo.datePassStartSettings.time
		)
		setValue(
			'datePassEndSettings.time',
			currentTaskInfo.datePassEndSettings.time
		)
		setValue('datePassEndSettings', currentTaskInfo.datePassEndSettings)
		setValue('passingScore', currentTaskInfo.passingScore)
		setValue('countAttempts', currentTaskInfo.countAttempts)
		setValue('maxCountUploadFiles', currentTaskInfo.maxCountUploadFiles)
		setValue('dateEdit', currentTaskInfo.dateEdit)
	}, [currentTaskInfo])

	const [selectedTabId, setSelectedTabId] = useState<ITab['id']>(tabsList[0].id)

	return (
		// TODO refactor
		<div className={cl.container}>
			<div
				style={{ display: isPreview ? 'none' : 'block' }}
				className={classnames([
					cl.tabsToggles,
					{
						[cl.tabsTogglesHidden]: isPreview
					}
				])}
			>
				<Tabs
					value={selectedTabId}
					tabs={tabsList}
					onChange={setSelectedTabId}
				/>
			</div>
			<div
				style={{ display: isPreview ? 'none' : 'block' }}
				className={classnames([
					cl.tabsContent,
					{
						[cl.tabsContentHidden]: isPreview
					}
				])}
			>
				{selectedTabId === tabsList[0].id && (
					<MainFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						register={register as unknown as UseFormRegister<MainFormTabState>}
						control={control as unknown as Control<MainFormTabState>}
						onChange={onChange}
						errors={errors}
						currentTaskInfo={currentTaskInfo}
						handleSubmit={handleSubmit}
						isEditMode
					/>
				)}
				{selectedTabId === tabsList[1].id && (
					<AnswerSettingsFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						currentTaskInfo={currentTaskInfo}
						getValues={
							getValues as unknown as UseFormGetValues<AnswerSettingsFormTabState>
						}
						errors={errors}
						handleSubmit={handleSubmit}
						onChange={onChange}
						control={control as unknown as Control<AnswerSettingsFormTabState>}
						register={
							register as unknown as UseFormRegister<AnswerSettingsFormTabState>
						}
					/>
				)}
				{selectedTabId === tabsList[2].id && (
					<LimitationsFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						currentTaskInfo={currentTaskInfo}
						errors={errors}
						handleSubmit={handleSubmit}
						onChange={onChange}
						control={control as unknown as Control<LimitationsFormTabState>}
						register={
							register as unknown as UseFormRegister<LimitationsFormTabState>
						}
						getValues={
							getValues as unknown as UseFormGetValues<LimitationsFormTabState>
						}
						watch={watch as unknown as UseFormWatch<LimitationsFormTabState>}
					/>
				)}
				<DevTool control={control} />
			</div>
		</div>
	)
}

export default TasksTabs
