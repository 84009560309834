import { useEffect } from "react"

export const usePreventGoBack = (condition?: boolean) => {
    useEffect(() => {
        if (!condition) {
			return
		}

		window.scrollTo(0, 0)
		const preventLeave = (event: any) => {
			event.preventDefault()
		}

		const preventGoBack = () => {
			// eslint-disable-next-line no-restricted-globals
			history.pushState(null, document.title, location.href)
		}

		preventGoBack()
		// eslint-disable-next-line no-restricted-globals
		window.addEventListener('popstate', preventGoBack)
		window.addEventListener('beforeunload', preventLeave)

		return () => {
			window.removeEventListener('popstate', preventGoBack)
			window.removeEventListener('beforeunload', preventLeave)
		}
    }, [condition])
}

