import { useCallback, useRef } from 'react'

const useDebouncedCallback = <FuncType extends Function>(
	cb: FuncType,
	delay = 100
) => {
	const ref = useRef<ReturnType<typeof setTimeout>>()

	return useCallback(
		(...args) => {
			const later = () => {
				clearTimeout(ref.current)
				cb(...args)
			}

			clearTimeout(ref.current)
			ref.current = setTimeout(later, delay)
		},
		[cb, delay]
	)
}

export default useDebouncedCallback
