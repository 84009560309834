import React, { FC, useCallback, useMemo, useState } from 'react'
import { IEvent } from 'types/models/event.model'
import TableItemComponent from 'components/Timetable/Tabs/ScheduleTab/ScheduleTable/TableItem'
import {
	getEventStartTimeAndEventEndTime,
	getMonthNameByItsNumber,
	getNameDayOfTheWeek
} from 'helpers/time'

export interface TableItemContainerProps extends IEvent {
	onDetailsOpen: (_: IEvent) => void
}

const TableItem: FC<TableItemContainerProps> = ({
	id,
	link,
	timeZone,
	time,
	eventType,
	eventHost,
	dateEventEnd,
	dateEventStart,
	theme,
	duration,
	courseId,
	comments,
	capacity,
	address,
	onDetailsOpen
}) => {
	// TODO animation of opening the item
	const [open, setOpen] = useState(false)

	const onOpen = useCallback(() => setOpen(true), [setOpen])
	const onClose = useCallback(() => setOpen(false), [setOpen])

	const { start, end } = useMemo(() => {
		if (!duration || !time || !dateEventStart) {
			return {
				start: '',
				end: ''
			}
		}

		return getEventStartTimeAndEventEndTime({ duration, time, dateEventStart })
	}, [duration, time, dateEventStart])

	const dayOfWeek = useMemo(
		() =>
			getNameDayOfTheWeek(new Date(dateEventStart).getDay(), { isShort: true }),
		[dateEventStart]
	)

	const monthName = useMemo(
		() => getMonthNameByItsNumber(new Date(dateEventStart).getMonth()),
		[dateEventStart]
	)

	const date = useMemo(
		() => new Date(dateEventStart).getDate(),
		[dateEventStart]
	)

	return (
		<TableItemComponent
			id={id}
			link={link}
			timeZone={timeZone}
			time={time}
			eventHost={eventHost}
			eventType={eventType}
			dateEventEnd={dateEventEnd}
			dateEventStart={dateEventStart}
			theme={theme}
			duration={duration}
			courseId={courseId}
			comments={comments}
			capacity={capacity}
			address={address}
			open={open}
			onOpen={onOpen}
			onClose={onClose}
			startEventTime={start}
			endEventTime={end}
			dayOfWeek={dayOfWeek}
			monthName={monthName}
			date={date}
			onDetailsOpen={onDetailsOpen}
		/>
	)
}

export default TableItem
