import React, { FC, useCallback, useEffect, useState } from 'react'
import { FieldPath, useForm, UseFormGetValues } from 'react-hook-form'

import { Select } from 'UI'
import { IOption, SelectBorderStyles, SelectSizeStyles } from 'UI/Select/types'
import { ITask } from 'types/models/task.model'
import {
	ResponseEntityId,
	ResponseWithError,
	ValidationSuccessMessages
} from 'types'

import { useActions, useAppDispatch } from 'hooks/redux'
import taskQuery from 'store/queries/task.query'
import courseQuery from 'store/queries/course.query'
import { TaskFormState } from 'pages/Course/Task/Edit.page'
import { SectionItemType } from 'containers/Courses/Tabs/Content/ContentForm/Table'
import classnames from 'classnames'
import ConfirmModal from './ConfirmModal'
import cl from './style.module.scss'

interface TaskStatusFormState extends Pick<ITask, 'isActive'> {}
interface TaskStatusProps {
	isActive: ResponseEntityId
	taskId: ResponseEntityId
	restrictionsFilled: boolean
	getValues?: UseFormGetValues<TaskFormState>
}

const TaskStatus: FC<TaskStatusProps> = ({
	taskId,
	isActive,
	restrictionsFilled,
	getValues
}) => {
	const dispatch = useAppDispatch()
	const { pushSuccess, pushError } = useActions((state) => state.system)
	const [changeTask] = taskQuery.useChangeTaskMutation()
	const [confirmModalOpen, setConfirmModalOpen] = useState(false)

	const onConfirmModalClose = useCallback(() => setConfirmModalOpen(false), [])

	const [statusOptionList] = useState<IOption[]>([
		{
			value: 2,
			text: 'Активный'
		},
		{
			value: 1,
			text: 'Неактивный'
		}
	])
	const { register, setValue, control } = useForm<TaskStatusFormState>()

	useEffect(() => {
		register('isActive')
	}, [])

	useEffect(() => {
		setValue('isActive', isActive)
	})

	const onSubmit = useCallback(
		async (value: any) => {
			const formValues = getValues && getValues()
			const response = await changeTask({
				taskId: +taskId,
				body: { isActive: value,
				...formValues
			 }
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}
			dispatch(courseQuery.util.invalidateTags(['course']))
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
		},
		[taskId]
	)

	// TODO rtk for change status and border color
	const changeHandler = useCallback(
		(name: FieldPath<TaskStatusFormState>) => async (value: any) => {
			if (!restrictionsFilled && value === 2) {
				setConfirmModalOpen(true)
				return
			}

			setValue(name, value)

			onSubmit(value)
		},
		[restrictionsFilled]
	)

	const onConfirmActivation = useCallback(async () => {
		await onSubmit(2)

		onConfirmModalClose()
	}, [onSubmit])

	return (
		<>
			<Select
				className={classnames([
					cl.statusSelect,
					{
						[cl.statusSelectActive]: isActive === 2,
						[cl.statusSelectNonActive]: isActive === 1
					}
				])}
				styleTypes={[SelectSizeStyles.SMALL, SelectBorderStyles.ROUND]}
				control={control}
				onChange={changeHandler('isActive')}
				name="isActive"
				optionsList={statusOptionList}
			/>

			<ConfirmModal
				isOpen={confirmModalOpen}
				onClose={onConfirmModalClose}
				itemType={SectionItemType.TASK}
				onConfirm={onConfirmActivation}
			/>
		</>
	)
}

export default TaskStatus
