import React from 'react'
import { useRoutes, createBrowserRouter } from 'react-router-dom'

import { AuthLayout } from 'layouts'
import {
	RegistrationPage,
	AuthorizationPage,
	PasswordRecoveryPage,
	Policy
} from 'pages/Auth'
import DownloadInstructionPage from 'pages/DownloadInstruction'
import CheckRoutesList from './check.routes.list'

const GuestRoutes = () =>
	createBrowserRouter([
		{
			path: '/',
			element: <AuthLayout />,
			children: [
				{
					index: true,
					element: <AuthorizationPage />
				},
				{
					path: 'registration',
					element: <RegistrationPage />
				},
				{
					path: 'password-recovery',
					element: <PasswordRecoveryPage />
				},
				...CheckRoutesList
			]
		},
		{
			path: '/help',
			children: [
				{
					path: '/help/download-app',
					element: <DownloadInstructionPage />
				}
			]
		},
		{
			// path: '/policy',
			children: [
				{
					path: '/policy',
					element: <Policy />
				}
			]
		}
	])

export default GuestRoutes
