import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getUniqueId, isRunningStandalone } from 'helpers'
import { useActions, useAppSelector } from 'hooks/redux'
import { schoolQuery, userQuery } from 'store/queries'
import { ProfileMenu as ProfileMenuComponent } from 'components/Header'
import { DropdownItem } from 'UI/Dropdown/types'

import {
	logoutIconSrc,
	notificationIconSrc,
	profileIconSrc,
	schoolIconSrc,
	mobileIconSrc
} from 'components/Header/ProfileMenu/images/icons'
import { CourseRole, SchoolRole } from 'types'
import { UserRole } from 'store/slices/system/types'
import { PushService } from 'API'
import { useMatchMedia, useTariffExpiration } from 'hooks'
import { Loader } from 'UI'

const ProfileMenu = () => {
	const [otherRolesInfo, setOtherRolesInfo] = useState<UserRole[]>([])
	const navigate = useNavigate()

	const { isMobile } = useMatchMedia()

	const {
		token,
		userRole,
		currentSchoolId,
		currentCoursesIdList,
		currentRole
	} = useAppSelector((state) => state.system)
	const { remainingStorage } = useAppSelector((state) => state.kinescope)
	const { setRemainingStorage, setTariffFilesCapacity } = useActions(
		(state) => state.kinescope
	)
	const { data: schoolData, isLoading } = schoolQuery.useGetSchoolByIdQuery({
		schoolId: Number(currentSchoolId)
	})

	// убирает ограничение по тарифам, раскомментировать для возрата ограничений
	// useTariffExpiration(schoolData)

	useEffect(() => {
		setOtherRolesInfo(
			userRole.filter((role) => {
				if (role.roleSchool) {
					return role.roleSchool !== currentRole
				}
				if (role.courseInfo.length) {
					return currentCoursesIdList[0] !== role.courseInfo[0].id
				}
			})
		)
	}, [userRole, currentRole, currentSchoolId, currentCoursesIdList])

	useEffect(() => {
		if (!schoolData) return

		const {
			data: { remainingStorage: remaining, tariffFilesCapacity }
		} = schoolData

		setRemainingStorage(remaining)
		setTariffFilesCapacity(tariffFilesCapacity)
	}, [schoolData])

	const { logOut } = useActions((state) => state.system)
	const { data: userInfo } = userQuery.useGetYourselfQuery(token)

	const logOutHandler = useCallback(async () => {
		if (isRunningStandalone()) {
			await PushService.unregister()
		}
		navigate('/')
		logOut()
	}, [logOut])

	const currentRoleStr = useMemo(() => {
		switch (currentRole) {
			case SchoolRole.ADMIN:
				return 'администратор'
			case CourseRole.TEACHER:
				return 'преподаватель'
			case CourseRole.CURATOR:
				return 'куратор'
			case CourseRole.STUDENT:
				return 'студент'
			default:
				return ''
		}
	}, [currentRole])

	const dropdownItemsList = useMemo<DropdownItem[]>(() => {
		const defaultItems = [
			{
				id: getUniqueId(),
				icon: profileIconSrc,
				label: 'Настройки профиля',
				link: '/profile/user'
			},
			{
				id: getUniqueId(),
				icon: notificationIconSrc,
				label: 'Настроить уведомления',
				link: '/notifications'
			},
			{
				id: getUniqueId(),
				icon: logoutIconSrc,
				label: 'Выйти',
				onClick: logOutHandler
			}
		]

		if (isMobile) {
			const downloadAppItem = {
				id: getUniqueId(),
				icon: mobileIconSrc,
				label: 'Установить приложение',
				link: '/help/download-app'
			}
			defaultItems.splice(2, 0, downloadAppItem)
		}
		switch (currentRole) {
			case SchoolRole.ADMIN:
				defaultItems.splice(1, 0, {
					id: getUniqueId(),
					icon: schoolIconSrc,
					label: 'Настройки школы',
					link: '/profile/school'
				})

				return defaultItems
			case CourseRole.TEACHER:
				return defaultItems
			case CourseRole.CURATOR:
				return defaultItems
			case CourseRole.STUDENT:
				return defaultItems
			default:
				return []
		}
	}, [logOutHandler, isMobile])

	const getTitle = () => {
		// eslint-disable-next-line
		if (!!schoolData?.data.name.trim()) {
			return `Вы сейчас ${currentRoleStr} в школе «${schoolData?.data.name}»`
		}
		return 'Учебная платформа'
	}

	if (isLoading || !schoolData) {
		return <Loader />
	}

	return (
		<ProfileMenuComponent
			avatarParams={{
				photoUrl: userInfo?.data.avatarUrl as string,
				lastName: userInfo?.data.lastName,
				firstName: userInfo?.data.firstName
			}}
			dropdownTitle={getTitle()}
			dropdownItemsList={dropdownItemsList}
			otherRolesInfo={otherRolesInfo}
		/>
	)
}
export default ProfileMenu
