import React, { FC } from 'react'
import { Control, FieldErrors, FieldPath } from 'react-hook-form'

import { Button, Checkbox, Select } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import ValidateNotify from 'UI/Input/ValidateNotify'
import { AnswerSettingsFormTabState } from 'containers/Courses/Entities/Tasks/Tabs/Content/AnswerSettingsForm'

import classNames from 'classnames'
import cl from './style.module.scss'

interface AnswerSettingsFormTabProps {
	errors: FieldErrors<AnswerSettingsFormTabState>
	onChange: (
		name: FieldPath<AnswerSettingsFormTabState>
	) => (value: any) => void
	control: Control<AnswerSettingsFormTabState>
	onSubmit: () => void
	onOpenCanceledChange: () => void
	fileAnswerMode: boolean
}

const AnswerSettingsFormTab: FC<AnswerSettingsFormTabProps> = ({
	control,
	onSubmit,
	errors,
	onChange,
	onOpenCanceledChange,
	fileAnswerMode
}) => (
	<div className={cl.container}>
		<div className={cl.header}>
			<span>Настройте в каком формате ученик должен предоставить ответ</span>
		</div>
		<form onSubmit={onSubmit} noValidate className={cl.form}>
			<div className={cl.inputs}>
				<div className={cl.inputContainer}>
					<label htmlFor="name">Тип предоставляемого ответа *</label>
					<Checkbox
						onChange={onChange('answerResponsibilitiesType.isFile')}
						name="answerResponsibilitiesType.isFile"
						control={control}
						labelTitle="Ответ в виде файла"
					/>
					<Checkbox
						onChange={onChange('answerResponsibilitiesType.isText')}
						name="answerResponsibilitiesType.isText"
						control={control}
						labelTitle="Ответ в виде текста"
					/>
					<ValidateNotify
						className={cl.answerTypeError}
						error={
							errors.answerResponsibilitiesType?.isText?.message ||
							errors.answerResponsibilitiesType?.isFile?.message
						}
					/>
				</div>
				{/* TODO возможно required */}
				<div
					className={classNames(cl.inputContainer, {
						[cl.hidden]: !fileAnswerMode
					})}
				>
					<label htmlFor="description">
						Максимальное количество загружаемых файлов
					</label>
					<Select
						optionsList={[
							{ value: 1, text: '1' },
							{ value: 2, text: '2' },
							{ value: 3, text: '3' },
							{ value: 4, text: '4' },
							{ value: 5, text: '5' },
							{ value: 6, text: '6' },
							{ value: 7, text: '7' },
							{ value: 8, text: '8' },
							{ value: 9, text: '9' },
							{ value: 10, text: '10' }
						]}
						className={cl.uploadSelect}
						onChange={onChange('maxCountUploadFiles')}
						control={control}
						name="maxCountUploadFiles"
					/>
				</div>
				<div className={cl.splitLine} />
			</div>
			<div className={cl.buttons}>
				<Button
					onClick={onOpenCanceledChange}
					styleTypes={[ButtonStyles.OUTLINE_PRIMARY, ButtonStyles.ROUND]}
				>
					Отменить изменения
				</Button>
				<Button
					type="submit"
					styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
				>
					Сохранить изменения
				</Button>
			</div>
		</form>
	</div>
)

export default AnswerSettingsFormTab
