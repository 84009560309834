import React, { useCallback, useState, useEffect } from 'react'
import { FieldPath, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Button, Link, Loader } from 'UI'
import showIconUrl from 'UI/Button/images/show.svg'
import { LinkStyles } from 'UI/Link/types'
import { ButtonStyles } from 'UI/Button/types'

import LessonsTabs from 'containers/Courses/Entities/Lessons/Tabs/Edit/Wrapper.container'
import LessonStatus from 'containers/Courses/Entities/Lessons/LessonStatus'
import LessonPreview from 'containers/Courses/Entities/Lessons/Preview'
import { ILesson } from 'types/models/lesson.model'
import lessonQuery from 'store/queries/lesson.query'
import { LoaderStyles } from 'UI/Loader/types'
import CancelChangeModal from 'pages/Course/modals/CancelChangeModal'

import { useActions } from 'hooks/redux'

import cl from '../style.module.scss'

export interface LessonFormState extends ILesson { }

const LessonEditPage = () => {
	const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)

	const [isPreview, setIsPreview] = useState(false)
	const { courseId, lessonId } = useParams()

	const {
		register,
		handleSubmit,
		setValue,
		control,
		watch,
		formState,
		getValues
	} = useForm<LessonFormState>()

	const changeHandler = useCallback(
		(name: FieldPath<LessonFormState>) => (value: any, deleteFileName?: string) => {
			if (deleteFileName) {
				const filteredDocFileNames = getValues('docsFilesInfo')?.filter(docFile => docFile.title !== deleteFileName)
				setValue('docsFilesInfo', filteredDocFileNames)
			}
			setValue(name, value)
		},
		[]
	)

	const { setKinescopeData } = useActions((state) => state.kinescope)

	const { data: lessonInfo } = lessonQuery.useGetLessonInfoQuery(+`${lessonId}`)
	const { data: kinescopeData, isLoading } =
		lessonQuery.useGetDataForKinescopeQuery(+`${lessonId}`)

	useEffect(() => {
		if (isLoading || !kinescopeData) return

		setKinescopeData(kinescopeData)
	}, [isLoading])

	if (!lessonInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			<CancelChangeModal
				isOpen={cancelModalIsOpen}
				onClose={() => setCancelModalIsOpen(false)}
				redirectUri={`/course/edit/${courseId}`}
			/>
			<div className={cl.container}>
				<div className={cl.back}>
					<Link
						styleTypes={[LinkStyles.GO_BACK]}
						href={`/course/edit/${courseId}`}
					>
						Вернуться назад к курсу
					</Link>
				</div>
				<div className={cl.header}>
					<div className={cl.headerLessonInfo}>
						<h2>{lessonInfo.data.name}</h2>
						<LessonStatus
							isActive={lessonInfo.data.isActive}
							lessonId={+lessonId!}
							docsFilesUrlList={lessonInfo.data.docsFilesUrlList}
						/>
					</div>
					<Button
						onClick={() => setIsPreview((prev) => !prev)}
						styleTypes={[ButtonStyles.ROUND, ButtonStyles.OUTLINE_DARK]}
					>
						{isPreview ? (
							'Вернуться к редактированию'
						) : (
							<>
								<img src={showIconUrl} alt="show" /> Предпросмотр
							</>
						)}
					</Button>
				</div>
				<LessonPreview
					getValues={getValues}
					isPreview={isPreview}
					lessonInfo={lessonInfo.data}
				/>
				<LessonsTabs
					onOpenCanceledChange={() => setCancelModalIsOpen(true)}
					lessonInfo={lessonInfo.data}
					getValues={getValues}
					handleSubmit={handleSubmit}
					errors={formState.errors}
					onChange={changeHandler}
					control={control}
					isPreview={isPreview}
					register={register}
					watch={watch}
					setValue={setValue}
					kinescopeData={kinescopeData}
				/>
			</div>
		</>
	)
}

export default LessonEditPage
