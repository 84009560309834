import React, { FC, useMemo, useState } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch
} from 'react-hook-form'
import { DevTool } from '@hookform/devtools'

import { getUniqueId } from 'helpers'
import { Tabs } from 'UI'
import { ITab } from 'UI/Tabs/types'
import { MainFormTab } from 'containers/Courses/Tabs/Content'
import { CourseFormState } from 'pages/Course/Create.page'
import { MainFormTabState } from '../Content/MainForm'

import cl from '../style.module.scss'

interface CourseTabsProps {
	errors: FieldErrors<CourseFormState>
	onChange: (name: FieldPath<CourseFormState>) => (value: any) => void
	control: Control<CourseFormState>
	setValue: UseFormSetValue<CourseFormState>
	register: UseFormRegister<CourseFormState>
	handleSubmit: UseFormHandleSubmit<CourseFormState>
	watch: UseFormWatch<CourseFormState>
	getValues: UseFormGetValues<CourseFormState>
	onOpenCanceledChange: () => void
}

const CoursesTabs: FC<CourseTabsProps> = ({
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	watch,
	getValues,
	setValue,
	onOpenCanceledChange
}) => {
	const tabsList = useMemo(
		(): ITab[] => [
			{ id: getUniqueId(), text: 'Основные' },
			{ id: getUniqueId(), text: 'Содержание' },
			{ id: getUniqueId(), text: 'Уведомления' },
			{ id: getUniqueId(), text: 'Управление доступом' },
			{ id: getUniqueId(), text: 'Ученики' }
		],
		[]
	)

	const [selectedTabId, setSelectedTabId] = useState<ITab['id']>(
		[...tabsList].shift()!.id
	)

	return (
		<div className={cl.container}>
			<div className={cl.tabsToggles}>
				<Tabs
					disabled
					value={selectedTabId}
					tabs={tabsList}
					onChange={setSelectedTabId}
				/>
			</div>
			<div className={cl.tabsContent}>
				{selectedTabId === tabsList[0].id && (
					<MainFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						setValue={setValue as unknown as UseFormSetValue<MainFormTabState>}
						control={control as unknown as Control<MainFormTabState>}
						watch={watch as unknown as UseFormWatch<MainFormTabState>}
						getValues={
							getValues as unknown as UseFormGetValues<MainFormTabState>
						}
						register={register as unknown as UseFormRegister<MainFormTabState>}
						handleSubmit={handleSubmit}
						onChange={onChange}
						errors={errors}
					/>
				)}
			</div>
			<DevTool control={control} />
		</div>
	)
}

export default CoursesTabs
