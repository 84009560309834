import React, { FC } from 'react'
import TimeLineRowComponent from 'components/Timetable/Tabs/WeekTab/TimeLine/TimeLineRow'
import { EventGroupTime } from 'containers/Timetable/Tabs/WeekTab/TimeLine/index'
import { IEvent } from 'types/models/event.model'

export interface TimeLineRowContainerProps extends EventGroupTime {
	hoverIndex: number | null
	onHoverIndexChange: (_: number | null) => void
	onDetailsOpen: (_: IEvent) => void
}

const TimeLineRow: FC<TimeLineRowContainerProps> = ({
	time,
	elements,
	onHoverIndexChange,
	hoverIndex,
	onDetailsOpen
}) => (
	<TimeLineRowComponent
		time={time}
		elements={elements}
		onHoverIndexChange={onHoverIndexChange}
		hoverIndex={hoverIndex}
		onDetailsOpen={onDetailsOpen}
	/>
)

export default TimeLineRow
