import React, { FC } from 'react'

import { Avatar } from 'UI'
import { CoursesTabsProps } from 'containers/Courses/Tabs/View/Wrapper.container'
import Contacts from 'containers/Courses/Entities/General/Contacts'
import { ITag } from 'UI/Tags/types'
import { IUser } from 'types/models/user.model'
import { parseStringToTagA } from 'helpers/parseStringToTagA'
import Tags from './Tags'

import cl from './style.module.scss'

interface AboutTabProps extends CoursesTabsProps {
	tagsList: ITag[]
	creatorInfo?: IUser
}

const AboutTab: FC<AboutTabProps> = ({ courseInfo, tagsList, creatorInfo }) => (
	<div className={cl.container}>
		<div className={cl.aboutInfoContainer}>
			<div className={cl.aboutInfoItem}>
				<h2>Описание</h2>
				{!courseInfo.description
					? 'Описание отсутствует'
					: courseInfo.description.split('\n').map((p) => (
						<p>{parseStringToTagA(p, '_blank')}</p>
					))}
			</div>
			<div className={cl.aboutInfoItem}>
				<h2>Направления</h2>
				<Tags
					courseDirections={courseInfo.directionsIdList}
					tagsList={tagsList}
				/>
			</div>
			{creatorInfo && (
				<div className={cl.aboutInfoItem}>
					<h2>Автор</h2>
					<div className={cl.creatorInfo}>
						<Avatar
							firstName={creatorInfo.firstName}
							lastName={creatorInfo.lastName}
							photoUrl={creatorInfo.avatarUrl}
						/>
						<span className={cl.initialsOfName}>
							{creatorInfo.firstName} {creatorInfo.lastName}
						</span>
					</div>
				</div>
			)}
		</div>
		<Contacts communicationsInfo={courseInfo.communicationsSettings} />
	</div>
)

export default AboutTab
