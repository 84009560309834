import React, { FC } from 'react'
import { ICourse } from 'types/models/course.model'
import CourseItem from 'containers/Courses/StudentRole/Tabs/Content/CourseItem'

import cl from '../../style.module.scss'

interface CompletedTabsProps {
	courses: ICourse[]
}

const CompletedTabs: FC<CompletedTabsProps> = ({ courses }) => (
	<div className={cl.coursesList}>
		{courses.map((course) => (
			<CourseItem key={course.id} course={course} completedMode />
		))}
	</div>
)

export default CompletedTabs
