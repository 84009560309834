import React, { FC, useCallback } from 'react'

import { useActions, useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Link, Modal } from 'UI'
import { ModalSize } from 'UI/Modal/types'
import { ButtonStyles } from 'UI/Button/types'
import { useDisableScrollManual } from 'hooks/useDisableScrollManual'

import cl from './styles.module.scss'

const RestrictionModal: FC = () => {
	const { open, text } = useAppSelector((state) => state.tariffs.modal)
	const { tariffSoonToExpireModalShown, tariffSoonToExpireModalText } =
		useAppSelector((state) => state.system)

	const { setModalClosed } = useActions((state) => state.tariffs)
	const { hideTariffSoonToExpireModal } = useActions((state) => state.system)

	const dispatch = useAppDispatch()

	const modalText = text || tariffSoonToExpireModalText || ''
	const modalOpen = (open || tariffSoonToExpireModalShown) && modalText

	const onModalClose = useCallback(() => {
		dispatch(setModalClosed())
		dispatch(hideTariffSoonToExpireModal())
	}, [])

	useDisableScrollManual(!!modalOpen)

	return (
		<Modal
			isOpen={!!modalOpen}
			styleTypes={[ModalSize.BIG]}
			title="Ограничение по тарифу"
			description={modalText}
			onClose={onModalClose}
		>
			<div className={cl.buttons}>
				<Button
					styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
					onClick={onModalClose}
				>
					Отмена
				</Button>
				<Button
					styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
					onClick={onModalClose}
				>
					<Link href="/tariffs">Тарифы</Link>
				</Button>
			</div>
		</Modal>
	)
}

export default RestrictionModal
