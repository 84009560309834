import React, { FC } from 'react'
import { LessonViewProps as LessonViewContainerProps } from 'containers/Courses/Entities/Lessons/View/View'
import { Audio, Button, Iframe } from 'UI'
import Contacts from 'containers/Courses/Entities/General/Contacts'
import { Constants, CourseRole, ResponseEntityId } from 'types'
import { ButtonStyles } from 'UI/Button/types'

import AttemptStatus from 'components/Courses/Entities/Lessons/View/AttemptStatus'
import { AnswerTestReviewStatus } from 'types/models/test.model'
import { useAppSelector } from 'hooks/redux'
import classNames from 'classnames'
import { decodeHtmlString } from 'helpers'
import { downLoadFile } from 'helpers/downLoadFile'
import { parseStringToTagA } from 'helpers/parseStringToTagA'
import cl from './style.module.scss'


interface LessonViewProps
	extends Omit<LessonViewContainerProps, 'courseId' | 'courseSectionsId'> {
	isLessonPassing: boolean
	lessonPassedHandler: (lessonId: ResponseEntityId) => void
}

const LessonView: FC<LessonViewProps> = ({
	lesson,
	communicationsInfo,
	isLessonPassing,
	lessonPassedHandler
}) => {
	const { currentRole } = useAppSelector((state) => state.system)

	return (
		<div className={cl.container}>
			{(lesson.name || lesson.description) && (
				<div className={cl.header}>
					<div className={cl.headerTitle}>
						{lesson.name && <h1>{lesson.name}</h1>}
						{currentRole === CourseRole.STUDENT && (
							<>
								{isLessonPassing ? (
									<AttemptStatus status={AnswerTestReviewStatus.PASS} />
								) : (
									<AttemptStatus status={AnswerTestReviewStatus.NOT_PASS} />
								)}
							</>
						)}
					</div>
					{lesson.description && (
						<>
							{lesson.description.split('\n').map((text) => (
								<p key={text}>{parseStringToTagA(text, '_blank')}</p>
							))}
						</>
					)}
				</div>
			)}

			<div
				className={classNames(cl.videoContainer, cl.hello, {
					[cl.hidden]: !(
						(lesson.mediaFilesInfo && lesson.mediaFilesInfo[0]?.url) ||
						lesson.videoEmbedLink
					)
				})}
			>
				{lesson.mediaFilesInfo && lesson.mediaFilesInfo[0]?.url && (
					<Audio
						key={lesson.mediaFilesInfo[0].url}
						audioUrl={`${Constants.BASE_URL}${lesson.mediaFilesInfo[0].url}`}
						classes={cl.audio}
					/>
				)}

				{lesson.videoEmbedLink && (
					<Iframe key={lesson.videoId} src={lesson.videoEmbedLink} />
				)}
			</div>
			{!!lesson.docsFilesInfo?.length && (
				<div className={cl.docs}>
					<h2 className={cl.highlighted}>Учебные материалы</h2>
					<div className={cl.docsList}>
						{lesson.docsFilesInfo.map((documentInfo) => {
							// @ts-ignore
							if (documentInfo.url.includes('.txt')) {
								return <button
									// @ts-ignore
									onClick={() => downLoadFile(documentInfo.url, 'text-file')}
									className={cl.downloadLink}
									key={documentInfo.title}
								>
									{decodeHtmlString(
										// @ts-ignore
										documentInfo.title || documentInfo.url
									)}
								</button>
							}
							return <a
								rel="noreferrer"
								className={cl.downloadLink}
								key={documentInfo.title}
								href={`${Constants.BASE_URL}${documentInfo.url}`}
								target="_blank"
							>
								{decodeHtmlString(
									// @ts-ignore
									documentInfo.title || documentInfo.url
								)}
							</a>
						}

						)}
					</div>
				</div>
			)}
			<Contacts communicationsInfo={communicationsInfo} />
			{!isLessonPassing && currentRole === CourseRole.STUDENT && (
				<div className={cl.btn}>
					<Button
						onClick={() => lessonPassedHandler(lesson.id!)}
						styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
					>
						Отметить урок пройденным
					</Button>
				</div>
			)}
		</div>
	)
}

export default LessonView
