import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITestAnswer } from 'store/queries/test.query/types'

import { TestSliceState } from './types'

const initialState: TestSliceState = {
	isTestChecking: false,
	currentCheckingId: '',
	userAnswersIdList: [],
	testAnswersInfo: [],
	currentAttempt: null
}

const testSlice = createSlice({
	name: 'test',
	initialState,
	reducers: {
		setTestChecking: (
			state,
			{
				payload
			}: PayloadAction<
				Omit<
					TestSliceState,
					'userAnswersIdList' | 'testAnswersInfo' | 'currentAttempt'
				>
			>
		) => {
			state.isTestChecking = payload.isTestChecking
			state.currentCheckingId = payload.currentCheckingId
		},
		setCurrentChecking: (state, { payload }: PayloadAction<string>) => {
			state.currentCheckingId = payload
		},
		setUsersAnswersIdList: (state, { payload }: PayloadAction<string[]>) => {
			state.userAnswersIdList = payload
		},
		setTestAnswersInfo: (state, { payload }: PayloadAction<ITestAnswer[]>) => {
			state.testAnswersInfo = payload
		},
		setCurrentAttempt: (state, { payload }: PayloadAction<string | null>) => {
			state.currentAttempt = payload
		},
		setIsFinalTestOngoing: (state, { payload }: PayloadAction<boolean>) => {
			state.isFinalTestOngoing = payload
		}
	}
})

export default testSlice
