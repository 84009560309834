import React from 'react'

import { EventItemContainerProps } from 'containers/NotificationsSettings/NotificationSettingsTable/EventItem'
import { Checkbox } from 'UI'

import cl from './style.module.scss'

interface EventItemComponentProps
	extends Omit<
		EventItemContainerProps,
		'curator' | 'teacher' | 'student' | 'courseSettings'
	> {
	isMobile: boolean
}

const EventItem: React.FC<EventItemComponentProps> = ({
	email,
	eventId,
	push,
	text,
	control,
	index,
	disabled,
	onChange,
	isMobile
}) => (
	<>
		{!isMobile ? (
			<>
				<span>{text}</span>
				<Checkbox
					control={control}
					onChange={onChange(`events.${index}.email`)}
					name={`events.${index}.email`}
					disabled={disabled}
					labelTitle=""
				/>
				<Checkbox
					control={control}
					onChange={onChange(`events.${index}.push`)}
					name={`events.${index}.push`}
					disabled={disabled}
					labelTitle=""
				/>
			</>
		) : (
			<div className={cl.item}>
				<div>
					<span>{text}</span>
				</div>

				<div className={cl.eventItem}>
					<span>Почта</span>
					<Checkbox
						control={control}
						onChange={onChange(`events.${index}.email`)}
						name={`events.${index}.email`}
						disabled={disabled}
						labelTitle=""
					/>
				</div>

				<div className={cl.eventItem}>
					<span>PUSH уведомления</span>
					<Checkbox
						control={control}
						onChange={onChange(`events.${index}.push`)}
						name={`events.${index}.push`}
						disabled={disabled}
						labelTitle=""
					/>
				</div>
			</div>
		)}
	</>
)

export default EventItem
