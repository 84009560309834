import { EntityId } from '@reduxjs/toolkit'

export interface INotification {
	id: EntityId
	text: string
	dateCreate: string
	status: NotificationStatuses
}

export enum NotificationStatuses {
	UNREAD = 1,
	READ = 2
}
