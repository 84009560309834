import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { makeFetchBaseQueryWithTariffs } from 'store/makeFetchBaseQueryWithTariffs'
import { buildHeadersForKinescope } from 'helpers/api'
import {
	KinescopePaths,
	UploadMedia,
	UploadMediaToKinescopeResponse
} from './types'

const kinescopeQuery = createApi({
	reducerPath: 'kinescopeQuery',
	baseQuery: makeFetchBaseQueryWithTariffs({
		baseUrl: KinescopePaths.BASE_URL
	}),
	endpoints: (build) => ({
		uploadMediaToKinescope: build.mutation<
			UploadMediaToKinescopeResponse,
			UploadMedia
		>({
			query: ({
				body,
				lessonId,
				token,
				descr,
				fileName,
				folderId,
				projectId,
				title
			}) => ({
				url: KinescopePaths.UPLOAD_VIDEO,
				method: 'POST',
				body,
				headers: buildHeadersForKinescope({
					lessonId,
					token,
					descr,
					fileName,
					folderId,
					projectId,
					title
				}) as never
			})
		})
	})
})

export default kinescopeQuery
