import React, { FC, useCallback, useState } from 'react'
import CurrentWeekComponent from 'components/Timetable/Tabs/WeekTab/CurrentWeek'
import { TableCellsInfo } from 'containers/Timetable/Tabs/WeekTab/index'
import { IEvent } from 'types/models/event.model'

export interface CurrentWeekContainerProps {
	mappedWeekDaysElements: TableCellsInfo[]
	onHoverIndexChange: (_: number | null) => void
	hoverIndex: number | null
	onDetailsOpen: (_: IEvent) => void
}

const CurrentWeek: FC<CurrentWeekContainerProps> = ({
	mappedWeekDaysElements,
	onHoverIndexChange,
	hoverIndex,
	onDetailsOpen
}) => {
	const [collapsed, setCollapsed] = useState(false)

	const onCollapse = useCallback(() => setCollapsed(true), [setCollapsed])
	const onShrink = useCallback(() => setCollapsed(false), [setCollapsed])

	return (
		<CurrentWeekComponent
			collapsed={collapsed}
			onCollapse={onCollapse}
			onShrink={onShrink}
			mappedWeekDaysElements={mappedWeekDaysElements}
			onHoverIndexChange={onHoverIndexChange}
			hoverIndex={hoverIndex}
			onDetailsOpen={onDetailsOpen}
		/>
	)
}

export default CurrentWeek
