import React from 'react'

import { TariffsContainerProps, TariffsFormValues } from 'containers/Tariffs'
import TariffsPlans from 'containers/Tariffs/TariffsPlans'
import { IRate } from 'types/models/rate.model'
import TariffsInfo from 'containers/Tariffs/TariffsInfo'
import { IOption } from 'UI/Select/types'
import { Control, FieldPath, FieldPathValue } from 'react-hook-form'
import { FREE_STARTER_TARIFF_NAME } from 'types'

interface TariffsComponentProps extends TariffsContainerProps {
	tariffs: IRate[]
	activeTariffId: number
	usersCount: number
	coursesCount: number
	currentTariff: IRate | undefined
	tariffValidTill: string | null
	tariffItems: IOption[]
	control: Control<TariffsFormValues>
	onChange: (
		name: FieldPath<TariffsFormValues>
	) => (value: FieldPathValue<TariffsFormValues, typeof name>) => void
}

const Tariffs: React.FC<TariffsComponentProps> = ({
	tariffs,
	activeTariffId,
	currentTariff,
	usersCount,
	coursesCount,
	tariffValidTill,
	tariffItems,
	control,
	onChange
}) => (
	<>
		{currentTariff && currentTariff.value.name !== FREE_STARTER_TARIFF_NAME && (
			<TariffsInfo
				currentTariff={currentTariff}
				usersCount={usersCount}
				coursesCount={coursesCount}
				tariffValidTill={tariffValidTill}
			/>
		)}

		<TariffsPlans
			tariffs={tariffs}
			activeTariffId={activeTariffId}
			tariffItems={tariffItems}
			control={control}
			onChange={onChange}
		/>
	</>
)

export default Tariffs
