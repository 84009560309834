import { useCallback, useMemo, useState } from 'react'

interface UseCancelModalReturnValue {
	modalOpen: boolean
	onOpen: () => void
	onClose: () => void
}

const useModal = (): UseCancelModalReturnValue => {
	const [modalOpen, setModalOpen] = useState(false)

	const onOpen = useCallback(() => setModalOpen(true), [])
	const onClose = useCallback(() => setModalOpen(false), [])

	const result = useMemo(() => ({ modalOpen, onOpen, onClose }), [modalOpen])

	return result
}

export default useModal
