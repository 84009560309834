import React, { FC } from 'react'
import {
	AnswerReviewStatus,
	ITaskAnswerResponse
} from 'types/models/task.model'
import { IUser } from 'types/models/user.model'
import { ButtonStyles, ButtonTextStyles } from 'UI/Button/types'
import { Avatar, Button } from 'UI'
import { Constants, CourseRole, ResponseEntityId, SchoolRole } from 'types'
import TeacherComment from 'containers/Courses/Entities/Tasks/Check/TeacherComment'
import AttemptStatus from 'components/Courses/Entities/Tasks/Attempts/AttemptStatus'
import infinityIcon from 'components/Courses/Entities/Tests/View/images/infinity-icon.svg'
import docIconSrc from './icons/doc.svg'

import cl from './style.module.scss'

interface AttemptItemProps {
	answer: ITaskAnswerResponse
	answerCreatorInfo: IUser
	countAttempts: ResponseEntityId
	passingScore: number
	onChangeEditMode?: (_: boolean) => void
	answerEditMode?: boolean
	viewMode?: boolean
}

const AttemptItem: FC<AttemptItemProps> = ({
	answer,
	answerCreatorInfo,
	countAttempts,
	passingScore,
	onChangeEditMode,
	answerEditMode,
	viewMode
}) => (
	<div className={cl.attemptItem}>
		<div className={cl.attemptsCount}>
			<span>Попытка</span>
			<div className={cl.attempt}>{answer.attempt}</div>
			<span>из</span>
			<div className={cl.attempt}>
				{countAttempts || <img src={infinityIcon} alt="Infinity icon" />}
			</div>
			<AttemptStatus
				passingScore={10}
				status={answer.status}
				assessment={Number(answer.assessment)}
			/>
		</div>
		<div className={cl.attemptContent}>
			<div className={cl.avatar}>
				<Avatar
					photoUrl={answerCreatorInfo.avatarUrl}
					firstName={answerCreatorInfo.firstName}
					lastName={answerCreatorInfo.lastName}
				/>
			</div>
			<div className={cl.answerInfo}>
				<div className={cl.aboutAnswerCreator}>
					<div className={cl.initials}>
						<span>{answerCreatorInfo.firstName} </span>
						<span>{answerCreatorInfo.lastName}</span>
					</div>
					<span className={cl.date}>{answer.dateCreate}</span>
					<>
						{(answer.answerStatus === AnswerReviewStatus.AWAITS_CHECK ||
							answer.answerStatus === AnswerReviewStatus.NEED_CHECK) &&
							// <Button
							// 	styleTypes={[ButtonStyles.TERTIARY_BLUE]}
							// 	onClick={() => onChangeEditMode && onChangeEditMode(true)}
							// >
							// 	Редактировать
							// 	{/* Todo onclick */}
							// </Button>
							null}
					</>
				</div>
				<div className={cl.answerTextContent}>
					<span>{answer.answerText}</span>
				</div>
				{Array.isArray(answer.answerFileInfo) && answer.answerFileInfo[0].url &&
					answer.answerFileInfo.map((fileInfo) => (
						<div className={cl.answerFile}>
							<img src={docIconSrc} alt={docIconSrc} />
							<a
								rel="noreferrer"
								className={cl.downloadLink}
								download
								href={`${Constants.BASE_URL}${fileInfo.url}`}
								target="_blank"
							>
								{fileInfo?.title}
							</a>
						</div>
					))}
				{/* {answer.answerFileInfo && answer.answerFileInfo.url && (
						<div className={cl.answerFile}>
							<img src={docIconSrc} alt={docIconSrc} />
							<a
								rel="noreferrer"
								className={cl.downloadLink}
								download
								href={`${Constants.BASE_URL}${answer.answerFileInfo?.url}`}
								target="_blank"
							>
								{answer.answerFileInfo?.title}
							</a>
						</div>
					)} */}
			</div>
		</div>
		{answer.assessment && (
			<TeacherComment
				comment={answer.teacherComment}
				creatorId={answer.commentCreatorId}
				date={answer.dateEdit}
				viewMode={viewMode}
				setEditMode={onChangeEditMode}
			/>
		)}
	</div>
)

export default AttemptItem
