import React, { FC } from 'react'
import { UseFormGetValues } from 'react-hook-form'

import { ModalSize, ModalWidth } from 'UI/Modal/types'
import { Modal } from 'UI'
import schoolQuery from 'store/queries/school.query'
import { MainFormTabState } from 'containers/Courses/Tabs/Content/MainForm'

import { useAppSelector } from 'hooks/redux'
import { useDisableScrollManual } from 'hooks/useDisableScrollManual'
import cl from './style.module.scss'

interface FilledCertificateModalProps {
	isOpen: boolean
	onClose: () => void
	getValues: UseFormGetValues<MainFormTabState>
}

const FilledCertificateModal: FC<FilledCertificateModalProps> = ({
	isOpen,
	onClose,
	getValues
}) => {
	const { currentSchoolId } = useAppSelector((state) => state.system)
	const { data: schoolInfo } = schoolQuery.useGetSchoolByIdQuery({
		schoolId: Number(currentSchoolId)
	})
	useDisableScrollManual(isOpen)

	if (!schoolInfo) {
		return <></>
	}
	return (
		<Modal
			title="Шаблон своего сертификата"
			isOpen={isOpen}
			onClose={onClose}
			styleTypes={[ModalSize.BIG, ModalWidth.BIG]}
		>
			<div className={cl.container}>
				<div className={cl.content}>
					<div className={cl.certificateInfo}>
						<h2 className={cl.title}>
							<span>Учебная</span> платформа
						</h2>
						<div className={cl.header}>Сертификат</div>
						<div className={cl.nameOfStudent}>Петя Петров</div>
						<div className={cl.description}>Окончил курс</div>
						<div className={cl.courseName}>«{getValues('name')}»</div>
						{getValues(
							'certificateSettings.templateInfo.teacherSettings.isActive'
						) && (
							<div className={cl.nameOfTeacher}>
								{getValues(
									'certificateSettings.templateInfo.teacherSettings.name'
								)}
							</div>
						)}
					</div>
					{getValues(
						'certificateSettings.templateInfo.schoolSettings.isActive'
					) ? (
						<div className={cl.schoolInfoCustom}>
							<div className={cl.schoolLogo}>
								{schoolInfo.data.avatarUrl ? (
									<img src={schoolInfo.data.avatarUrl} alt="logo-modal" />
								) : (
									'Логотип не загружен'
								)}
							</div>
							{getValues(
								'certificateSettings.templateInfo.schoolSettings.signerMarkUrl'
							) && (
								<img
									src={
										getValues(
											'certificateSettings.templateInfo.schoolSettings.signerMarkUrl'
										)?.url
									}
									alt="Печать"
								/>
							)}

							{getValues(
								'certificateSettings.templateInfo.schoolSettings.signerSignUrl'
							) && (
								<img
									src={
										getValues(
											'certificateSettings.templateInfo.schoolSettings.signerSignUrl'
										)?.url
									}
									alt="Подпись"
								/>
							)}

							{/* {getValues(
									'certificateSettings.templateInfo.schoolSettings.sampleCertificateForMPUrl'
								) && (
										<img
											src={
												getValues(
													'certificateSettings.templateInfo.schoolSettings.sampleCertificateForMPUrl'
												)?.url
											}
											alt="Подпись"
										/>
									)} */}

							<div className={cl.schoolInfo}>
								{getValues(
									'certificateSettings.templateInfo.schoolSettings.signerPost'
								) && (
									<span>
										{getValues(
											'certificateSettings.templateInfo.schoolSettings.signerPost'
										)}
									</span>
								)}
								{getValues(
									'certificateSettings.templateInfo.schoolSettings.schoolName'
								) && (
									<span>
										«
										{getValues(
											'certificateSettings.templateInfo.schoolSettings.schoolName'
										)}
										»
									</span>
								)}
								{getValues(
									'certificateSettings.templateInfo.schoolSettings.signerName'
								) && (
									<span>
										{getValues(
											'certificateSettings.templateInfo.schoolSettings.signerName'
										)}
									</span>
								)}
							</div>
						</div>
					) : (
						<div className={cl.schoolInfo}>
							<span>Генеральный директор</span>
							<span>«Школа промышленного производства»</span>
							<span>Павел Петров</span>
						</div>
					)}
				</div>
			</div>
		</Modal>
	)
}

export default FilledCertificateModal
