import React, { FC } from 'react'
import { ITask } from 'types/models/task.model'
import { Select, Button } from 'UI'
import { SelectProps } from 'UI/Select'
import cl from './AnswersSortComponent.module.scss'

interface AnswersSortComponentProps
	extends Pick<ITask, 'name' | 'description'> {
	navigateCb: () => void
	selectData: SelectProps[]
	className?: string
	totalCount?: number
	filterMatch?: number
}

const AnswersSortComponent: FC<AnswersSortComponentProps> = ({
	name,
	description,
	navigateCb,
	className,
	totalCount,
	filterMatch,
	selectData
}) => (
	<div className={`${cl.answerSort} ${className}`}>
		<h2 className={cl.answerSortTitle}>{name}</h2>
		<p className={cl.answerSortDescription}>{description}</p>
		<div className={cl.answerSortContainer}>
			<div className={cl.answerSortFilterContainer}>
				{selectData.map(
					({ name: title, onChange, optionsList, control, id }) => (
						<Select
							name={title}
							onChange={onChange}
							optionsList={optionsList}
							control={control}
							className={cl.answerSortSelect}
							key={title}
						/>
					)
				)}
				<Button className={cl.answerSortButton} onClick={navigateCb}>
					Смотреть ответы подряд
				</Button>
			</div>
			<div>
				{totalCount && (
					<p className={cl.answerSortText}>Всего ответов: {totalCount}</p>
				)}
				{!!filterMatch && (
					<p className={cl.answerSortText}>
						Соответствует фильтру: {filterMatch}
					</p>
				)}
			</div>
		</div>
	</div>
)

export default AnswersSortComponent
