import React, { FC } from 'react'
import { Button, Modal, TextInput } from 'UI'
import { ModalSize } from 'UI/Modal/types'
import { ButtonStyles } from 'UI/Button/types'

import { EditSectionModalProps as EditSectionModalContainerProps } from 'containers/Courses/Tabs/Content/ContentForm/modals/EditSection'
import cl from '../CreateSection/style.module.scss'

interface EditSectionModalProps
	extends Omit<
		EditSectionModalContainerProps,
		'handleSubmit' | 'courseId' | 'setValue' | 'currentSectionId'
	> {
	onSubmit: () => void
}

const EditSectionModal: FC<EditSectionModalProps> = ({
	isModalOpen,
	closeModal,
	control,
	errors,
	onSubmit,
	onChange
}) => (
	<Modal
		title="Редактирование раздела"
		description="Укажите название раздела."
		isOpen={isModalOpen}
		onClose={closeModal}
		styleTypes={[ModalSize.SMALL]}
	>
		<form noValidate onSubmit={onSubmit} className={cl.formContainer}>
			<TextInput
				error={errors.sectionName?.message}
				id="sectionName"
				onChange={onChange('sectionName')}
				control={control}
				name="sectionName"
				placeholder="Название раздела"
			/>
			<div className={cl.buttonsContainer}>
				<Button
					onClick={closeModal}
					styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
				>
					Отмена
				</Button>
				<Button
					type="submit"
					styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
				>
					Сохранить
				</Button>
			</div>
		</form>
	</Modal>
)

export default EditSectionModal
