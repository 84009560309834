const dateIsMoreThanToday = (date: Date) => {
	const now = new Date()
	now.setHours(0)
	now.setMinutes(0)
	now.setSeconds(0)
	now.setMilliseconds(1)

	return date > now
}

export default dateIsMoreThanToday
