import React, { FC } from 'react'
import classNames from 'classnames'
import {
	AnswersListPassProps as AnswersListPassContainerProps,
	QuestionAnswerFormState
} from 'containers/Courses/Entities/Tests/View/QuestionsListPass/AnswersList'
import { Button, CheckboxGroup, RadioGroup } from 'UI'
import { ButtonSizes, ButtonStyles } from 'UI/Button/types'
import { Constants, CourseRole, SchoolRole } from 'types'

import { Answer } from 'types/models/test.model'
import { Control, FieldErrors, FieldPath } from 'react-hook-form'
import ValidateNotify from 'UI/Input/ValidateNotify'

import cl from 'components/Courses/Entities/Tests/Preview/QuestionsListPreview/style.module.scss'
import style from './style.module.scss'

interface AnswersListPassProps
	extends Omit<
		AnswersListPassContainerProps,
		| 'questionsList'
		| 'onSelect'
		| 'answerAttemptId'
		| 'onAppend'
		| 'fields'
		| 'setIsQuestionsPassMode'
		| 'getFinalTestFormState'
		| 'setOnSubmit'
	> {
	answersList: Answer[]
	control: Control<any>
	onChange: (
		name: FieldPath<QuestionAnswerFormState>
	) => (value: number | number[]) => void
	onSubmit: () => void
	errors: FieldErrors<QuestionAnswerFormState>
	lastButtonIsSubmit: boolean
	prevButtonIsDisabled: boolean
	onNext: (currentQuestionId: number) => void
	addFieldToArray: (currentQuestionId: number) => void
	viewMode?: boolean
	setIsQuestionsPassMode: (_: boolean) => void
	getAnswerIsCorrect: (_: number) => boolean
	currentRole?: CourseRole | SchoolRole
	disableNextButton?: boolean
	disableSubmitButton?: boolean
	isFinalTestOngoing?: boolean
}

const AnswersListPass: FC<AnswersListPassProps> = ({
	question,
	answersList,
	onChange,
	control,
	onSubmit,
	errors,
	lastButtonIsSubmit,
	onNext,
	prevButtonIsDisabled,
	addFieldToArray,
	viewMode,
	setIsQuestionsPassMode,
	getAnswerIsCorrect,
	testType,
	currentRole,
	timerIsOver,
	setIsQuestionsPassingMode,
	userAnswersIdList,
	countAttempts,
	disableNextButton,
	disableSubmitButton,
	isFinalTestOngoing
}) => (
	<div className={cl.answers}>
		<div className={cl.answersHeader}>
			{question.name}
			<div className={cl.images}>
				{question.picturesInfoList &&
					question.picturesInfoList.map((p) => (
						<div className={cl.testImage} key={p.id}>
							<img src={`${Constants.BASE_URL}${p.url}`} alt="" />
						</div>
					))}
			</div>
		</div>
		<div className={cl.answersBody}>
			{answersList.map((answer, fieldIndex) => (
				<div key={answer.id} className={cl.answerItem}>
					{question.answerType === 1
						? answer.value && (
								<>
									<RadioGroup
										disabled={viewMode || prevButtonIsDisabled || timerIsOver}
										viewMode={viewMode || prevButtonIsDisabled}
										itemsList={[
											{
												labelTitle: answer.value,
												id: `${fieldIndex}`,
												value: Number(answer.id),
												isRight: getAnswerIsCorrect(answer.id),
												comment: answer.comment
											}
										]}
										testType={testType}
										isFinalTestOngoing={isFinalTestOngoing}
										name="correctAnswersIdList"
										control={control}
										onChange={onChange('correctAnswersIdList')}
									/>
								</>
						  )
						: answer.value && (
								<CheckboxGroup
									disabled={viewMode || prevButtonIsDisabled || timerIsOver}
									viewMode={viewMode || prevButtonIsDisabled}
									name="correctAnswersIdList"
									control={control}
									onChange={onChange('correctAnswersIdList')}
									testType={testType}
									isFinalTestOngoing={isFinalTestOngoing}
									itemsList={[
										{
											labelTitle: answer.value,
											id: `${fieldIndex}`,
											value: Number(answer.id),
											isRight: getAnswerIsCorrect(answer.id),
											comment: answer.comment
										}
									]}
								/>
						  )}
				</div>
			))}
			<ValidateNotify error={errors.correctAnswersIdList?.message} />
		</div>
		{!viewMode &&
			(!lastButtonIsSubmit && !timerIsOver ? (
				<Button
					disabled={prevButtonIsDisabled || disableNextButton}
					onClick={() => onNext(question.id!)}
					className={cl.buttonNext}
					styleTypes={[
						ButtonStyles.PRIMARY,
						ButtonStyles.ROUND,
						ButtonSizes.BIG
					]}
				>
					Дальше
				</Button>
			) : (
				<Button
					disabled={disableSubmitButton || disableNextButton || timerIsOver}
					onClick={() => {
						addFieldToArray(question.id!)
						onSubmit()
					}}
					className={cl.buttonNext}
					styleTypes={[
						ButtonStyles.PRIMARY,
						ButtonStyles.ROUND,
						ButtonSizes.BIG
					]}
				>
					Отправить ответы
				</Button>
			))}

		{viewMode && currentRole === CourseRole.STUDENT && (
			<div className={classNames(cl.buttons, style.buttons)}>
				<Button
					onClick={() => setIsQuestionsPassMode(false)}
					styleTypes={[
						ButtonStyles.LightGrey,
						ButtonStyles.ROUND,
						ButtonSizes.BIG
					]}
					className={cl.buttonGoBack}
				>
					Вернуться назад
				</Button>

				{countAttempts && userAnswersIdList.length < countAttempts && (
					<Button
						onClick={() => {
							setIsQuestionsPassMode(false)
							setIsQuestionsPassingMode(true)
						}}
						className={cl.buttonGoBack}
					>
						Перепройти тестирование
					</Button>
				)}
			</div>
		)}
	</div>
)

export default AnswersListPass
