import React, { FC } from 'react'
import { AttemptsTasksTable as AttemptsTableContainerProps } from 'containers/Courses/Entities/Tasks/Check/AttemptsTable'
import {
	AnswerReviewStatus,
	ITaskAnswerResponse
} from 'types/models/task.model'
import classNames from 'classnames'
import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'

import AnswerCreator from 'containers/Courses/Entities/Tests/Check/AttemptsTable/AnswerCreator'
import { useNavigate } from 'react-router-dom'
import { useActions } from 'hooks/redux'

import cl from './style.module.scss'

interface AttemptsTableProps extends AttemptsTableContainerProps {
	taskAnswersInfo: ITaskAnswerResponse[]
	hideAvatarMobile?: boolean
}

const AttemptsTable: FC<AttemptsTableProps> = ({
	taskAnswersInfo,
	courseId,
	taskId,
	userAnswersIdList,
	hideAvatarMobile
}) => {
	const navigate = useNavigate()
	const { setCreatorId, setCurrentTaskAnswer } = useActions(
		(state) => state.task
	)

	const isAnswerAwaitsCheck = (answer: ITaskAnswerResponse) =>
		answer.answerStatus === AnswerReviewStatus.NEED_CHECK ||
		answer.answerStatus === AnswerReviewStatus.AWAITS_CHECK

	return (
		<div className={cl.table}>
			<div className={classNames(cl.tableHeader, cl.tableHeaderAttempts)}>
				<span>Ученик</span>
				<span>Статус</span>
				<span>Оценка</span>
				<span>Дата ответа</span>
				<span>Оценить</span>
			</div>
			<div className={cl.tableBody}>
				{taskAnswersInfo.map((answer) => (
					<div className={classNames(cl.tableBodyItem, cl.tableBodyItemAttempts)} key={answer.id}>
						<AnswerCreator creatorId={answer.creatorId} hideAvatarMobile={hideAvatarMobile} />
						<span>
							{answer.answerStatus}
						</span>
						<span>
							{answer.answerStatus !== AnswerReviewStatus.NEED_CHECK &&
								`${answer.assessment}`}
						</span>
						<span>{answer.dateCreate}</span>
						<span>
							<Button
								onClick={() => {
									navigate(
										`/course/${courseId}/task/${taskId}/answer/attempt/view/${answer.id}`
									)

									setCreatorId(answer.creatorId)
									setCurrentTaskAnswer(answer)
								}}
								styleTypes={[
									isAnswerAwaitsCheck(answer)
										? ButtonStyles.PRIMARY
										: ButtonStyles.DEFAULT_BG,
									ButtonStyles.ROUND
								]}
							>
								{isAnswerAwaitsCheck(answer) ? 'Оценить' : 'Просмотреть'}
							</Button>
						</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default AttemptsTable
