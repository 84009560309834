import React, { FC } from 'react'
import { UseFormGetValues } from 'react-hook-form'

import TaskPreviewComponent from 'components/Courses/Entities/Tasks/Preview'
import { TaskFormState } from 'pages/Course/Task/Create.page'
import { useParams } from 'react-router-dom'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import courseQuery from 'store/queries/course.query'

interface TaskPreviewProps {
	isPreview?: boolean
	getValues: UseFormGetValues<TaskFormState>
}

const TaskPreview: FC<TaskPreviewProps> = ({ isPreview = true, getValues }) => {
	if (!isPreview) {
		return <></>
	}

	const { courseId, taskId } = useParams()
	if (!(courseId && taskId)) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}
	const { data: courseInfo } = courseQuery.useGetCourseQuery(+courseId)

	if (!courseInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<TaskPreviewComponent
			communicationInfo={courseInfo.data.communicationsSettings}
			getValues={getValues}
		/>
	)
}

export default TaskPreview
