import React, { FC } from 'react'
import { TableItemContainerProps } from 'containers/Timetable/Tabs/ScheduleTab/ScheduleTable/TableItem'

import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import classNames from 'classnames'
import cl from './style.module.scss'

interface TableItemComponentProps extends TableItemContainerProps {
	open: boolean
	onOpen: () => void
	onClose: () => void
	startEventTime: string
	endEventTime: string
	dayOfWeek: string
	monthName: string
	date: number
}

const TableItem: FC<TableItemComponentProps> = ({
	onDetailsOpen,
	open,
	onClose,
	onOpen,
	dayOfWeek,
	date,
	monthName,
	startEventTime,
	endEventTime,
	...event
}) => (
	<div className={cl.item} onClick={open ? onClose : onOpen}>
		<span
			className={classNames(cl.preview, {
				[cl.open]: open
			})}
		>
			{event.time}
		</span>
		<span
			className={classNames(cl.preview, {
				[cl.open]: open
			})}
		>
			{event.theme ?? "Встреча"}
		</span>

		{open && (
			<div className={cl.detailsContent}>
				<span className={cl.date}>Дата</span>
				<div>
					<span>
						{dayOfWeek}, {date} {monthName}, {startEventTime} - {endEventTime}
					</span>
					<Button
						styleTypes={[ButtonStyles.TERTIARY_BLUE]}
						onClick={(e) => {
							e.stopPropagation()
							onDetailsOpen(event)
						}}
					>
						подробнее
					</Button>
				</div>
			</div>
		)}
	</div>
)

export default TableItem
