import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { FieldPath, useForm } from 'react-hook-form'
import isURL from 'validator/lib/isURL'
import { SchoolForm as SchoolFormComponent } from 'components'
import { ValidationErrorMessages, PatternsType, ResponseWithError, Constants } from 'types'
import { schoolQuery } from 'store/queries'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { useActions, useAppSelector } from 'hooks/redux'
import { IMainSchoolInfo } from 'types/models/school.model'
import { getFormData, getPatterns } from 'helpers'
import { SuccessChangeInfoModal } from 'components/PersonalSchool/modals'
import { useModal, useScrollToError } from 'hooks'
import GetImageUrlFromFile from 'helpers/files/getImageUrlFromFile'
import { DevTool } from '@hookform/devtools'
import CancelModal from 'components/NotificationSettingsForm/NotificationSettingsTable/CancelModal'
import { useNavigate } from 'react-router-dom'

export interface SchoolProfileFormState extends IMainSchoolInfo {
	avatar?: File
	avatarUrl?: string
}

const SchoolForm = () => {
	const { modalOpen: cancelModalOpen, onClose, onOpen } = useModal()
	const { currentSchoolId } = useAppSelector((state) => state.system)

	const navigate = useNavigate()

	const [successChangeInfoModalIsOpen, setChangeInfoModalIsOpen] =
		useState(false)
	const [updateYourselfSchool] = schoolQuery.useUpdateYourselfSchoolMutation()
	const { pushError } = useActions((state) => state.system)

	const { isLoading, data: schoolData } = schoolQuery.useGetSchoolByIdQuery({
		schoolId: Number(currentSchoolId)
	})

	const { register, control, formState, setValue, handleSubmit, watch } =
		useForm<SchoolProfileFormState>()

	useEffect(() => {
		register('name', {
			required: ValidationErrorMessages.EMPTY,
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('description', {
			required: ValidationErrorMessages.EMPTY,
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('websiteUrl', {
			required: ValidationErrorMessages.EMPTY,
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			},
			pattern: {
				value: getPatterns(PatternsType.TRIM_STRING),
				message: ValidationErrorMessages.INCORRECT
			},
			validate: (value) => isURL(value) || ValidationErrorMessages.INCORRECT
		})
		register('avatarUrl')
		register('avatar')
	}, [])

	const avatarFile = watch('avatar')
	const avatarUrl = watch('avatarUrl')

	useLayoutEffect(() => {
		if (avatarFile === undefined) {
			return
		}
		; (async () => {
			const blob = await GetImageUrlFromFile(avatarFile)
			setValue('avatarUrl', blob ? URL.createObjectURL(new Blob([blob])) : '')
		})()
	}, [avatarFile])

	useEffect(() => {
		const school = schoolData
		if (!school) {
			return
		}
		setValue('name', school.data.name.trim())
		setValue('description', school.data.description.trim())
		setValue('websiteUrl', school.data.websiteUrl.trim())
		setValue('avatarUrl', schoolData?.data.avatarUrl ? `${schoolData?.data.avatarUrl} `: '')
	}, [schoolData])

	const closeModalHandler = () => {
		setChangeInfoModalIsOpen(false)
	}

	const changeHandler = useCallback(
		(name: FieldPath<SchoolProfileFormState>) => (value: any) =>
			setValue(name, value),
		[]
	)

	useScrollToError(formState)

	const submitHandler = useCallback(
		handleSubmit(async (data) => {
			const response = await updateYourselfSchool(
				await getFormData({
					...data,
					name: data.name.trim(),
					description: data.description.trim()
				})
			)

			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}
			setChangeInfoModalIsOpen(true)

			setValue('avatar', undefined)
		}),
		[handleSubmit]
	)

	const onCancelModalConfirm = useCallback(() => {
		navigate('/')
		onClose()
	}, [onClose])
	
	if (isLoading) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}
	return (
		<>
			<DevTool control={control} />
			<SchoolFormComponent
				avatarUrl={avatarFile && avatarUrl || avatarUrl && `${Constants.BASE_URL}/${avatarUrl}` || ''}
				control={control}
				onChange={changeHandler}
				errors={formState.errors}
				onSubmit={submitHandler}
				onCancelModalOpen={onOpen}
			/>
			<SuccessChangeInfoModal
				isOpen={successChangeInfoModalIsOpen}
				onClose={closeModalHandler}
			/>

			<CancelModal
				isOpen={cancelModalOpen}
				onClose={onClose}
				onConfirm={onCancelModalConfirm}
				confirmText="Выйти"
			/>
		</>
	)
}
export default SchoolForm
