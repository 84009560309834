import React, { FC, useEffect, useState } from 'react'

import cl from './style.module.scss'

interface CountDownProps {
	resetTimer?: boolean
	setResetTimer?: (_: boolean) => void
	hours: number
	minutes: number
	seconds: number
	timerIsOver: boolean
	setTimerIsOver: (_: boolean) => void
	isNotLimited?: boolean
}

const CountDown: FC<CountDownProps> = ({
	resetTimer,
	setResetTimer,
	hours,
	minutes,
	seconds,
	timerIsOver,
	setTimerIsOver,
	isNotLimited
}) => {
	const [[h, m, s], setTime] = useState([hours, minutes, seconds])

	const tick = () => {
		if (timerIsOver) {
			return
		}
		if (isNotLimited) return
		if (h === 0 && m === 0 && s === 0) {
			setTimerIsOver(true)
		} else if (m === 0 && s === 0) {
			setTime([h - 1, 59, 59])
		} else if (s === 0) {
			setTime([h, m - 1, 59])
		} else {
			setTime([h, m, s - 1])
		}
	}

	const reset = () => {
		setTime([hours, minutes, seconds])
		setTimerIsOver(false)
		if (setResetTimer) {
			setResetTimer(false)
		}
	}
	useEffect(() => {
		const timerId = setInterval(() => tick(), 1000)
		return () => clearInterval(timerId)
	})

	useEffect(() => {
		if (resetTimer) {
			reset()
		}
	}, [resetTimer])

	return (
		<div className={cl.countDown}>{`${h.toString().padStart(2, '0')}:${m
			.toString()
			.padStart(2, '0')}:${s.toString().padStart(2, '0')}`}</div>
	)
}

export default CountDown
