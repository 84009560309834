import { ResponseEntityId } from 'types'

export interface AnswerResponsibilitiesType {
	isText: boolean
	isFile: boolean
}
export enum TaskResponsibilityStatus {
	REQUIRED = 2,
	OPTIONAL = 1
}

interface DatePassSettings<T extends Date | string> {
	isActive: boolean
	date: T
	time: string
}

export enum AnswerReviewStatus {
	ACCESS = 'Проверено',
	DECLINE = 'Не принято',
	PASSED = 'Принято',
	NEED_CHECK = 'Требует оценки',
	AWAITS_CHECK = 'Ожидает проверки'
}

export interface ITask<T extends Date | string = Date> {
	id?: ResponseEntityId
	name: string
	description: string
	answerResponsibilitiesType: AnswerResponsibilitiesType
	maxCountUploadFiles: number
	taskResponsibilityStatus: TaskResponsibilityStatus
	passingScore: number
	countAttempts: number
	datePassStartSettings: DatePassSettings<T>
	datePassEndSettings: DatePassSettings<T>
	dateEdit: T
	dateCreate: T
	isActive: number
	userAnswersIdList: string[]
}

export interface ITaskAnswerResponse {
	id: ResponseEntityId
	teacherComment: string
	creatorId: ResponseEntityId
	commentCreatorId: ResponseEntityId
	dateCreate: string
	dateEdit: string
	assessment: number
	answerFileInfo: TaskAnswerDoc | TaskAnswerDoc[]
	answerFileUrl?: string
	answerText: string
	attempt: ResponseEntityId
	status: AnswerReviewStatus
	answerStatus: AnswerReviewStatus
}

export interface TaskAnswerDoc {
	title: string
	url: string
}

export interface ITaskAnswer {
	answerText: string
	answerFileInfo?: TaskAnswerDoc | TaskAnswerDoc[]
}

export enum FilterValue {
	AllANswers = 'Все ответы',
	Verified = 'Проверено',
	NeedCheck = 'Требует оценки'
}

export enum SortValue {
	New = 'Сначала новые',
	Old = 'Сначала старые',
	AssessmentMax = 'Оценка меньшая',
	AssessmentMin = 'Оценка большая'
}
