import {
	NotificationsFormTabContainerProps,
	NotificationsReminderFormValues,
	ReminderItemValue
} from 'containers/Courses/Tabs/Content/NotificationsForm'
import NotificationsSettingsTable from 'containers/NotificationsSettings/NotificationSettingsTable'
import ReminderForm from 'containers/Courses/Tabs/Content/NotificationsForm/ReminderForm'
import React from 'react'
import { Control, FieldPath, FieldPathValue } from 'react-hook-form'
import { INotificationEvent } from 'types/notification-event.model'

import { Divider } from 'antd'
import cl from './style.module.scss'

interface NotificationsFormTabComponentProps
	extends Omit<NotificationsFormTabContainerProps, 'courseInfo'> {
	events: INotificationEvent[]
	control: Control<NotificationsReminderFormValues>
	onChange: (
		name: FieldPath<NotificationsReminderFormValues>
	) => (
		value: FieldPathValue<NotificationsReminderFormValues, typeof name>
	) => void
	onSubmit: () => void
	reminderValue: ReminderItemValue[]
}

const NotificationsFormTab: React.FC<NotificationsFormTabComponentProps> = ({
	settings,
	events,
	isActive,
	reminderValue,
	control,
	onChange,
	onSubmit
}) => (
	<div className={cl.container}>
		<div className={cl.header}>
			<span>
				Настраивайте, когда администраторы и/или пользователи будут получать
				уведомления о курсе
			</span>
			<Divider />
			<ReminderForm control={control} onChange={onChange} onSubmit={onSubmit} />
			<NotificationsSettingsTable
				courseSettings
				settings={settings}
				events={events}
				isActive={isActive}
				reminderValue={reminderValue}
			/>
		</div>
	</div>
)

export default NotificationsFormTab
