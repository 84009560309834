export interface UploadMediaToKinescopeResponse {
	data: {
		data: DataKinescope
	}
}

interface DataKinescope {
	id: string
	embed_link: string
	title: string
}

export interface UploadMedia {
	body?: File
	projectId: string
	lessonId: string
	folderId: string
	token: string
	title: string
	fileName?: string
	descr?: string
}

export interface KinescopeUploadHeaders {
	Authorization: string
	'X-Project-ID': string
	'X-Folder-ID': string
	'X-Video-Title': string
	'X-Video-Description'?: string
	'X-File-Name'?: string
}

export enum KinescopePaths {
	BASE_URL = 'https://uploader.kinescope.io',
	UPLOAD_VIDEO = 'video'
}
