import React, { FC } from 'react'
import { useWatch, FieldErrors } from 'react-hook-form'

import { Button, RadioGroup, Switch } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { MainFormTabState } from 'containers/Courses/Tabs/Content/MainForm'
import { CertificateTypes } from 'types/models/course.model/settings/certificate.model'
import { CertificateSettingsProps as CertificateSettingsContainerProps } from 'containers/Courses/Tabs/Content/MainForm/CertificateSettings'
import ConfigModal from 'containers/Courses/Tabs/Content/MainForm/CertificateSettings/modals/Config'

import cl from './style.module.scss'

export interface CertificateSettingsProps
	extends Omit<CertificateSettingsContainerProps, 'setValue'> {
	modalIsOpen: boolean
	onCloseModal: (saveChanges?: boolean) => void
	onOpenModal: () => void
	resetCertificateConfigs: () => void
	errors: FieldErrors<MainFormTabState>
}

const CertificateSettings: FC<CertificateSettingsProps> = ({
	control,
	watch,
	onChange,
	modalIsOpen,
	onCloseModal,
	onOpenModal,
	resetCertificateConfigs,
	onOpenCertificateTemplateModal,
	getValues,
	errors
}) => {
	const certificateSettingsIsActiveWatcher = useWatch({
		name: 'certificateSettings.isActive',
		control
	})
	return (
		<>
			<Switch
				name="certificateSettings.isActive"
				id="certificateSettings.isActive"
				onChange={onChange('certificateSettings.isActive')}
				control={control}
				labelTitle="Выдавать сертификат ученикам по завершению курса."
			/>
			{/* 

				<RadioGroup
				name="certificateSettings.type"
				disabled={!certificateSettingsIsActiveWatcher}
				itemsList={[
					{
						value: CertificateTypes.DEFAULT,
						labelTitle: 'Сертификат от "Учебной платформы"'
					},
					{ value: CertificateTypes.CUSTOM, labelTitle: 'Свой сертификат' }
				]}
				onChange={onChange('certificateSettings.type')}
				control={control}
			/> */}
			<div className={cl.buttonsContainer}>
				<Button
					disabled={
						// раскомментировать чтобы сделать зависимость от радиокнопки 'Свой сертификат'
						// watch('certificateSettings.type') !== CertificateTypes.CUSTOM ||
						!watch('certificateSettings.isActive')
					}
					onClick={onOpenModal}
					styleTypes={[ButtonStyles.OUTLINE_PRIMARY, ButtonStyles.ROUND]}
				>
					Настроить
				</Button>
				{/* <Button
					onClick={onOpenCertificateTemplateModal}
					styleTypes={[ButtonStyles.OUTLINE_DARK, ButtonStyles.ROUND]}
				>
					Посмотреть шаблон
				</Button> */}
			</div>
			<ConfigModal
				getValues={getValues}
				resetCertificateConfigs={resetCertificateConfigs}
				control={control}
				modalIsOpen={modalIsOpen}
				onOpenModal={onOpenModal}
				onCloseModal={onCloseModal}
				onChange={onChange}
				watch={watch}
				errors={errors}
			/>
		</>
	)
}

export default CertificateSettings
