interface TimeObject {
	hours?: number
	minutes?: number
	seconds?: number
	milliseconds?: number
}

type getMillisecondsFromTimeObjectType = (_: TimeObject) => number

const getMillisecondsFromTimeObject: getMillisecondsFromTimeObjectType = (
	data
) => {
	let result = 0

	Object.entries(data).forEach(([key, value]) => {
		if (key === 'hours') {
			result += 1000 * 60 * 60 * value
		} else if (key === 'minutes') {
			result += 1000 * 60 * value
		} else if (key === 'seconds') {
			result += 1000 * value
		} else if (key === 'milliseconds') {
			result += value
		}
	})

	return result
}

export default getMillisecondsFromTimeObject
