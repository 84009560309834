import { IEvent } from 'types/models/event.model'
import { getMillisecondsFromTime } from 'helpers/getMillisecondsFromTime'
import { formatHoursToDate } from 'helpers/number'

const getEventStartTimeAndEventEndTime = (
	event: Pick<IEvent, 'dateEventStart' | 'duration' | 'time'>
) => {
	const startDate = new Date(event.dateEventStart)

	const [startHours, startMinutes] = event.time.split(':')
	startDate.setHours(Number(startHours))
	startDate.setMinutes(Number(startMinutes))

	const { hours, minutes } = getMillisecondsFromTime(event.duration)

	startDate.setHours(startDate.getHours() + Number(hours) || 0)
	startDate.setMinutes(startDate.getMinutes() + Number(minutes) || 0)

	const startTime = event.time
	const endTime = formatHoursToDate(
		startDate.getHours(),
		startDate.getMinutes()
	)

	return {
		start: startTime,
		end: endTime
	}
}

export default getEventStartTimeAndEventEndTime
