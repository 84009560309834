import React, { FC } from 'react'
import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ModalSize } from 'UI/Modal/types'

interface PaymentFailureModalProps {
	isOpen: boolean
	onClose: () => void
}

const PaymentFailureModal: FC<PaymentFailureModalProps> = ({
	isOpen,
	onClose
}) => (
	<Modal
		styleTypes={[ModalSize.SMALL]}
		isOpen={isOpen}
		onClose={onClose}
		title="Ошибка оплаты"
		description="Во время оплаты что то пошло не так. Попробуйте снова"
	>
		<Button
			onClick={onClose}
			styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
		>
			Закрыть
		</Button>
	</Modal>
)

export default PaymentFailureModal
