import React, { FC, useCallback, useEffect, useState } from 'react'

import { useDisableWindowScroll } from 'hooks'

import { NotificationsBar as NotificationsBarComponent } from 'components/Header'
import { userQuery } from 'store/queries'
import {
	INotification,
	NotificationStatuses
} from 'types/models/notification.model'
import { EntityId } from '@reduxjs/toolkit'
import { useAppSelector } from 'hooks/redux'

const NotificationsBar: FC = () => {
	const [notificationsList, setNotificationsList] = useState<INotification[]>(
		[]
	)

	const { currentRole } = useAppSelector((state) => state.system)

	const [haveNewNotifications, setHaveNewNotifications] = useState(false)
	const [isOpen, setIsOpen] = useState(false)

	const { data: notifications } = userQuery.useGetNotificationsQuery(
		{ role: currentRole },
		{ refetchOnMountOrArgChange: true, pollingInterval: 50000 }
	)
	const [triggerReadNotifications] =
		userQuery.useChangeNotificationStatusMutation()

	useDisableWindowScroll(isOpen)

	useEffect(() => {
		if (!notifications) return

		setNotificationsList(notifications.data)
	}, [notifications])

	useEffect(() => {
		setHaveNewNotifications(
			notificationsList.some(
				({ status }) => status === NotificationStatuses.UNREAD
			)
		)
	}, [notificationsList])

	const openHandler = useCallback(setIsOpen.bind(null, true), [])
	const closeHandler = useCallback(setIsOpen.bind(null, false), [])

	const readAllNotifications = useCallback(async () => {
		await triggerReadNotifications({
			status: NotificationStatuses.READ,
			notificationsIdList: notificationsList
				.filter((n) => n.status === NotificationStatuses.UNREAD)
				.map((n) => n.id)
		})

		setNotificationsList((prev) =>
			prev.map((notification) => ({
				...notification,
				status: NotificationStatuses.READ
			}))
		)
	}, [notificationsList])

	const toggleReadNotification = useCallback(
		async (id: EntityId, nextStatus: NotificationStatuses) => {
			await triggerReadNotifications({
				status: nextStatus,
				notificationsIdList: [id]
			})

			setNotificationsList((prev) =>
				prev.map((notification) => ({
					...notification,
					status: notification.id === id ? nextStatus : notification.status
				}))
			)
		},
		[]
	)

	return (
		<NotificationsBarComponent
			onClose={closeHandler}
			onOpen={openHandler}
			readAllNotifications={readAllNotifications}
			isOpen={isOpen}
			notificationsList={notificationsList}
			haveNewNotifications={haveNewNotifications}
			toggleReadNotification={toggleReadNotification}
		/>
	)
}

export default NotificationsBar
