import React from 'react'
import { Control, FieldPath, FieldPathValue } from 'react-hook-form'
import {
	NotificationsReminderFormValues,
	ReminderItemValue
} from 'containers/Courses/Tabs/Content/NotificationsForm'
import ReminderItem from 'containers/Courses/Tabs/Content/NotificationsForm/ReminderForm/ReminderItem'

import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'

import plusIcon from './images/plus.svg'
import cl from './style.module.scss'

interface ReminderFormComponentProps {
	control: Control<NotificationsReminderFormValues>
	onChange: (
		name: FieldPath<NotificationsReminderFormValues>
	) => (
		value: FieldPathValue<NotificationsReminderFormValues, typeof name>
	) => void
	onSubmit: () => void
	onAddReminderItem: () => void
	watcher: ReminderItemValue[]
}

const ReminderForm: React.FC<ReminderFormComponentProps> = ({
	control,
	onSubmit,
	onAddReminderItem,
	onChange,
	watcher
}) => (
	<div className={cl.reminderForm}>
		<h3 className={cl.title}>Напоминание</h3>
		<p className={cl.text}>
			Напомнить пользователям о приближении срока завершения курса за
		</p>

		{watcher.map((notification, index) => (
			<ReminderItem
				{...notification}
				key={notification.id}
				control={control}
				onChange={onChange}
				index={index}
			/>
		))}

		<Button
			styleTypes={[ButtonStyles.OUTLINE_PRIMARY]}
			className={cl.reminderButton}
			onClick={onAddReminderItem}
		>
			<img src={plusIcon} alt="" />
			Добавить напоминание
		</Button>
	</div>
)

export default ReminderForm
