import { useEffect, useState } from 'react'

import userQuery from 'store/queries/user.query'
import { useActions, useAppSelector } from 'hooks/redux'
import { CourseRole, ResponseEntityId, SchoolRole, StorageKeys } from 'types'
import { getItemFromStorage } from 'helpers/storage'
import { StorageTypes } from 'helpers/storage/types'

const UseCheckRole = () => {
	const [userId, setUserId] = useState<ResponseEntityId>()

	const { token, currentRole } = useAppSelector((state) => state.system)
	const {
		setUserRole,
		setCurrentRole,
		setCurrentSchoolId,
		setCurrentCoursesIdList
	} = useActions((state) => state.system)
	const { setCurrentUserId, setCurrentUserName } = useActions((state) => state.user)
	const { data: yourselfInfo } = userQuery.useGetYourselfQuery(token, {
		refetchOnMountOrArgChange: true
	})

	useEffect(() => {
		if (!yourselfInfo) {
			return
		}
		setUserId(yourselfInfo.data.id)
		setCurrentUserId(yourselfInfo.data.id)
		setCurrentUserName(yourselfInfo.data.firstName)
	}, [yourselfInfo])

	const [trigger, { data: userRoles, isSuccess }] =
		userQuery.endpoints.getUserRole.useLazyQuery()

	useEffect(() => {
		if (!userId) {
			return
		}
		trigger({ userId: Number(userId) })
	}, [userId])

	useEffect(() => {
		if (!isSuccess || !userRoles) {
			return
		}
		setUserRole(userRoles.data)

		if (userRoles.data.length === 0) {
			return
		}

		const currentSchoolId = `${userRoles.data.filter((role) => role.roleSchool === SchoolRole.ADMIN)[0]
				?.schoolInfo?.id ||
			userRoles.data.filter((role) => role.roleCourse === CourseRole.TEACHER)[0]
				?.schoolInfo?.id ||
			userRoles.data.filter((role) => role.roleCourse === CourseRole.CURATOR)[0]
				?.schoolInfo?.id ||
			userRoles.data.filter((role) => role.roleCourse === CourseRole.STUDENT)[0]
				?.schoolInfo?.id
			}`

		const currentUserRole = `${userRoles.data.filter((role) => role.roleSchool === SchoolRole.ADMIN)[0]
				?.roleSchool ||
			userRoles.data.filter((role) => role.roleCourse === CourseRole.TEACHER)[0]
				?.roleCourse ||
			userRoles.data.filter((role) => role.roleCourse === CourseRole.CURATOR)[0]
				?.roleCourse ||
			userRoles.data.filter((role) => role.roleCourse === CourseRole.STUDENT)[0]
				?.roleCourse
			}`

		setCurrentSchoolId(
			getItemFromStorage(
				StorageKeys.CURRENT_SCHOOL_ID,
				currentSchoolId,
				StorageTypes.LOCAL_STORAGE
			) || currentSchoolId
		)
		setCurrentRole(
			(getItemFromStorage(
				StorageKeys.CURRENT_ROLE,
				currentUserRole,
				StorageTypes.LOCAL_STORAGE
			) as CourseRole | SchoolRole) ||
			(currentUserRole as CourseRole | SchoolRole)
		)
	}, [isSuccess, userRoles])

	useEffect(() => {
		if (!isSuccess || !userRoles) {
			return
		}

		const foundRoleCourse = userRoles.data.find(
			(u) => u.roleCourse === currentRole || u.roleSchool === currentRole
		)

		if (currentRole === CourseRole.STUDENT) {
			const coursesIds = foundRoleCourse?.courseInfo.map((c) => c.id)
			setCurrentCoursesIdList(coursesIds || [])
		}

		if (currentRole === CourseRole.CURATOR) {
			const coursesIds = foundRoleCourse?.courseInfo.map((c) => c.id)
			setCurrentCoursesIdList(coursesIds || [])
		}

		if (currentRole === CourseRole.TEACHER) {
			const coursesIds = foundRoleCourse?.courseInfo.map((c) => c.id)
			// const coursesIds = foundRoleCourse?.schoolInfo.coursesIdList
			setCurrentCoursesIdList(coursesIds || [])
		}

		if (currentRole === SchoolRole.ADMIN) {
			const coursesIds = foundRoleCourse?.schoolInfo.coursesIdList
			setCurrentCoursesIdList(coursesIds || [])
		}
	}, [userRoles, currentRole])
}

export default UseCheckRole
