import React, { FC } from 'react'

import { QuestionTabs } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'

import { TestTypes } from 'types/models/test.model'
import classnames from 'classnames'

import cl from './style.module.scss'

interface TabProps {
	tab: QuestionTabs
	isActive: boolean
	onClick: (tabId: QuestionTabs['id']) => void
	isCorrect?: boolean
	testType?: number
	isFinalTestOngoing?: boolean
}

const QuestionTab: FC<TabProps> = ({
	tab,
	isActive,
	onClick,
	isCorrect,
	isFinalTestOngoing,
	testType
}) => (
	<div
		onClick={onClick.bind(null, tab.id)}
		className={classnames([cl.cell], {
			[cl.cellSelected]: isActive,
			[cl.wrong]: !isCorrect && isCorrect !== undefined,
			[cl.correct]: isCorrect,
			[cl.endTestingBg]: !!isFinalTestOngoing
		})}
	>
		<span>{tab.id + 1}</span>
	</div>
)

export default QuestionTab
