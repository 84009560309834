import React, { FC, useCallback, useRef, useState } from 'react'

import { useDisableWindowScroll, useMatchMedia, useOnClickOutside } from 'hooks'

import {
	DefaultDropdownItemsList,
	ProfileMenuDropdownItemsList
} from './ItemsLists'
import { DropdownProps, DropdownTypes } from './types'

import dropdownIconSrc from './images/dropdown-icon.svg'
import cl from './style.module.scss'

const Dropdown: FC<DropdownProps> = ({
	dropdownTitle = '',
	type = DropdownTypes.DEFAULT,
	avatarParams = {},
	dropdownItemsList,
	labelText,
	otherRolesInfo,
	children
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const dropdownContainer = useRef(null)

	const { isTable } = useMatchMedia()

	const closeHandler = useCallback(setIsOpen.bind(null, false), [])
	const toggleHandler = useCallback(
		setIsOpen.bind(null, (prev) => !prev),
		[]
	)

	// TODO fix later with mobile
	useDisableWindowScroll(isOpen && isTable)
	useOnClickOutside<HTMLDivElement>(dropdownContainer, closeHandler)

	return (
		<>
			{dropdownItemsList.length > 0 && (
				<div ref={dropdownContainer} className={cl.container}>
					<div onClick={toggleHandler} className={cl.title}>
						{labelText ? (
							<>
								<span>{labelText}</span>
								<img src={dropdownIconSrc} alt="dropdown" />
							</>
						) : (
							children
						)}
					</div>
					{isOpen && (
						<>
							{type === DropdownTypes.DEFAULT && (
								<DefaultDropdownItemsList
									isTableDropdown={isTable}
									onClose={closeHandler}
									dropdownItemsList={dropdownItemsList}
								/>
							)}
							{type === DropdownTypes.PROFILE_MENU && (
								<ProfileMenuDropdownItemsList
									{...avatarParams}
									dropdownTitle={dropdownTitle}
									onClose={closeHandler}
									dropdownItemsList={dropdownItemsList}
									otherRolesInfo={otherRolesInfo}
								/>
							)}
						</>
					)}
				</div>
			)}
		</>
	)
}

export default Dropdown
