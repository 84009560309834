import React, { FC } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { NotificationStatuses } from 'types/models/notification.model'

import OpenNotificationsBarButton from './OpenNotificationsBarButton'
import NotificationsList from './NotificationsList'

import { NotificationsListProps } from './types'

interface NotificationsBarProps extends NotificationsListProps {
	onOpen: () => void
	haveNewNotifications: boolean
	toggleReadNotification: (
		id: EntityId,
		nextStatus: NotificationStatuses
	) => void
}

const NotificationsBar: FC<NotificationsBarProps> = ({
	isOpen,
	notificationsList,
	onOpen,
	onClose,
	readAllNotifications,
	toggleReadNotification,
	haveNewNotifications
}) => (
	<>
		<OpenNotificationsBarButton
			haveNewNotifications={haveNewNotifications}
			onClick={onOpen}
		>
			Уведомления
		</OpenNotificationsBarButton>
		{isOpen && (
			<NotificationsList
				isOpen={isOpen}
				notificationsList={notificationsList}
				onClose={onClose}
				readAllNotifications={readAllNotifications}
				toggleReadNotification={toggleReadNotification}
			/>
		)}
	</>
)

export default NotificationsBar
