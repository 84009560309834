import React, { FC, useEffect, useState } from 'react'
import { ResponseEntityId } from 'types'
import ReviewItemComponent from 'components/Courses/Tabs/ViewContent/Feedback/ReviewItem'

export interface ReviewItemProps {
	text: string
	evaluation: ResponseEntityId
	dateCreate: string
	user: string
	avatar?: string
}

const ReviewItem: FC<ReviewItemProps> = ({
	user,
	dateCreate,
	evaluation,
	text,
	avatar
}) =>  (
		<ReviewItemComponent
			user={user}
			avatar={avatar}
			text={text}
			dateCreate={dateCreate}
			evaluation={evaluation}
		/>
	)


export default ReviewItem
