import React from 'react'
import { useIOS } from 'hooks'
import IOSInstructions from 'components/DownloadInstruction/IOSInstructions'
import AndroidInstructions from 'components/DownloadInstruction/AndroidInstructions'

const DownloadInstruction = () => {
	const isIOS = useIOS()

	if (isIOS) {
		return <IOSInstructions />
	}

	return <AndroidInstructions />
}
export default DownloadInstruction
