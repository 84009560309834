import React, { FC } from 'react'
import AttemptAnswerViewComponent from 'components/Courses/Entities/Tests/AttemptAnswerView'
import { ITest } from 'types/models/test.model'
import { ICourse } from 'types/models/course.model'
import { ITestAnswer } from 'store/queries/test.query/types'

export interface AttemptAnswerViewProps {
	testInfo: ITest
	courseInfo: ICourse
	currentAnswers: ITestAnswer
	currentCheckingId: string
	currentAttempt: string
	testAnswersInfo: ITestAnswer[]
	bestResult: number
	isPrevAttemptsChecking: boolean
	togglePrevAttemptsChecking: () => void
	setCurrentAttempt: (_: string) => void
	setCurrentChecking: (_: string) => void
	handleLoadUserAnswers?: (userId?: number) => void
}

const AttemptAnswerView: FC<AttemptAnswerViewProps> = ({
	testInfo,
	courseInfo,
	currentAnswers,
	currentCheckingId,
	currentAttempt,
	testAnswersInfo,
	bestResult,
	isPrevAttemptsChecking,
	togglePrevAttemptsChecking,
	setCurrentAttempt,
	setCurrentChecking,
	handleLoadUserAnswers
}) => (
	<AttemptAnswerViewComponent
		testInfo={testInfo}
		courseInfo={courseInfo}
		currentAnswers={currentAnswers}
		currentCheckingId={currentCheckingId}
		currentAttempt={currentAttempt}
		testAnswersInfo={testAnswersInfo}
		bestResult={bestResult}
		isPrevAttemptsChecking={isPrevAttemptsChecking}
		togglePrevAttemptsChecking={togglePrevAttemptsChecking}
		setCurrentAttempt={setCurrentAttempt}
		setCurrentChecking={setCurrentChecking}
		handleLoadUserAnswers={handleLoadUserAnswers}
	/>
)

export default AttemptAnswerView
