import React, { FC } from 'react'
import { Control, FieldPath, UseFormGetValues } from 'react-hook-form'

import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { TestFormState } from 'pages/Course/Test/Create.page'
import Contacts from 'containers/Courses/Entities/General/Contacts'
import { GetMillisecondsFromTimeResult } from 'helpers/getMillisecondsFromTime'
import QuestionsListPreview from 'containers/Courses/Entities/Tests/Preview/QuestionsListPreview'
import { addZeroToTime } from 'helpers/number'

import {
	ITest,
	TestResponsibilityStatus,
	TestTypes
} from 'types/models/test.model'
import { CommunicationsSettings } from 'types/models/course.model/settings/communications.model'
import cl from './style.module.scss'

interface TestPreviewProps {
	getValues: UseFormGetValues<TestFormState>
	isQuestionsListPreview: boolean
	passLimitTime: GetMillisecondsFromTimeResult
	setIsQuestionsListPreview: (_: boolean) => void
	control: Control<any>
	onChange: (name: FieldPath<TestFormState>) => (value: any) => void
	communicationInfo: CommunicationsSettings
}

const getStartTimeToPassTestString = (testInfo: ITest) => {
	let str = ''

	if (testInfo.datePassStartSettings.isActive) {
		str += `${new Date(
			testInfo.datePassStartSettings.date
		).toLocaleDateString()} `
		str += testInfo.datePassStartSettings.time
	} else if (testInfo.datePassEndSettings.isActive) {
		str += 'Без ограничений'
	}

	return str
}

const getEndTimeToPassTestString = (testInfo: ITest) => {
	let str = ''

	if (testInfo.datePassEndSettings.isActive) {
		str += `${new Date(
			testInfo.datePassEndSettings.date
		).toLocaleDateString()} `
		str += testInfo.datePassEndSettings.time
	} else if (testInfo.datePassStartSettings.isActive) {
		str += 'Без ограничений'
	}

	return str
}

const TestPreview: FC<TestPreviewProps> = ({
	getValues,
	passLimitTime,
	setIsQuestionsListPreview,
	isQuestionsListPreview,
	control,
	onChange,
	communicationInfo
}) => {
	if (isQuestionsListPreview) {
		return (
			<QuestionsListPreview
				onChange={onChange}
				control={control}
				passLimitTime={passLimitTime}
				getValues={getValues}
			/>
		)
	}

	return (
		<div className={cl.container}>
			<div className={cl.header}>
				<h1>{getValues('name')}</h1>
				<div className={cl.headerInfo}>
					<div className={cl.headerInfoItem}>
						<span>Тестирование обязательное</span>
						<span>
							{getValues('testResponsibilityStatus') ===
								TestResponsibilityStatus.REQUIRED
								? 'Да'
								: 'Нет'}
						</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Тип тестирования</span>
						<span>
							{getValues('type') === TestTypes.INTERMEDIATE
								? 'Промежуточное'
								: 'Итоговое'}
						</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Количество вопросов</span>
						<span>
							{getValues('questions') && getValues('questions').length
								? getValues('questions').length
								: '—'}
						</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Ограничение прохождения по времени</span>
						<span>
							<>{passLimitTime.hours ? `${addZeroToTime(passLimitTime.hours)}:` : '00:'}</>
							<>{passLimitTime.minutes ? `${addZeroToTime(passLimitTime.minutes)}:` : '00:'}</>
							<>{passLimitTime.seconds ? addZeroToTime(passLimitTime.seconds) : '00'}</>
						</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Дата прохождения</span>
						<span>—</span>
					</div>
					<div className={cl.headerInfoItem}>
						<span>Период прохождения</span>
						<span>
							<span>{getStartTimeToPassTestString(getValues())}</span>{' '}
							<span>—</span>{' '}
							<span>{getEndTimeToPassTestString(getValues())}</span>{' '}
						</span>
					</div>
				</div>
				{getValues('description') && (
					<>
						{getValues('description')
							.split('\n')
							.map((text) => (
								<p className={cl.caption} key={text}>
									{text}
								</p>
							))}
					</>
				)}
			</div>
			<div className={cl.questionsListPreview}>
				<h2>Тестирование</h2>
				<div className={cl.attemptsCount}>
					<span>Попытка</span>
					<div className={cl.attempt}>1</div>
					<span>из</span>
					<div className={cl.attempt}>5</div>
				</div>
				<Button
					onClick={() => setIsQuestionsListPreview(true)}
					styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
				>
					Предпросмотр вопросов
				</Button>
			</div>
			<Contacts communicationsInfo={communicationInfo} />
		</div>
	)
}

export default TestPreview
