import React, { FC, ReactNode } from 'react'
import { ScheduleTableContainerProps } from 'containers/Timetable/Tabs/ScheduleTab/ScheduleTable'
import GoBack from 'containers/Timetable/Tabs/ScheduleTab/ScheduleTable/GoBack'

import TableItem from 'containers/Timetable/Tabs/ScheduleTab/ScheduleTable/TableItem'
import GoForward from 'containers/Timetable/Tabs/ScheduleTab/ScheduleTable/GoForward'
import Empty from 'components/Timetable/Tabs/ScheduleTab/ScheduleTable/Empty'
import cl from './style.module.scss'

interface ScheduleTableComponentProps
	extends Omit<ScheduleTableContainerProps, 'control' | 'onDetailsOpen'> {
	dateStringAfter: string
	dateStringLater: string
	mappedItems: ReactNode[]
	currentDate: Date
}

const ScheduleTable: FC<ScheduleTableComponentProps> = ({
	events,
	dateStringAfter,
	dateStringLater,
	mappedItems,
	onChange,
	currentDate
}) => (
	<div className={cl.table}>
		<GoBack
			dateString={dateStringAfter}
			onChange={onChange('currentDate')}
			currentDate={currentDate}
		/>

		{mappedItems && mappedItems.length === 0 && <Empty />}

		{mappedItems}

		<GoForward
			dateString={dateStringLater}
			currentDate={currentDate}
			onChange={onChange('currentDate')}
		/>
	</div>
)

export default ScheduleTable
