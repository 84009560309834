import React, { useCallback, useEffect, useState } from 'react'
import CreateReviewFormComponent from 'components/Courses/Entities/Certificate/Get/CreateReviewForm'
import { FieldPath, FieldPathValue, useForm } from 'react-hook-form'
import { useActions } from 'hooks/redux'
import { courseQuery, userQuery } from 'store/queries'
import {
	ResponseEntityId,
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import GetCertificateModal from 'components/Courses/Entities/Certificate/Get/CreateReviewForm/GetCertificateModal'

export interface GetCertificateFormValues {
	evaluation: 1 | 2 | 3 | 4 | 5
	text: string
}

export interface CreateReviewFormContainerProps {
	courseId: ResponseEntityId
}

const CreateReviewForm: React.FC<CreateReviewFormContainerProps> = ({
	courseId
}) => {
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		control
	} = useForm<GetCertificateFormValues>()
	const { data: userInfo } = userQuery.useGetYourselfQuery()
	const [getCertificateModalOpen, setGetCertificateModalOpen] = useState(false)
	const [withFeedbackSent, setWithFeedbackSent] = useState(false)
	const [disableSubmitButton, setDisableSubmitButton] = useState(true)

	const { pushError, pushSuccess } = useActions((state) => state.system)

	const [triggerGetCoursePassing, { data: coursePassingInfo }] =
		courseQuery.useLazyCourseGetPassingQuery()
	const [triggerGetCertificate, { data: certificateInfo }] =
		courseQuery.useLazyGetCertificateQuery()
	const [triggerCreateReview] = courseQuery.useCreateReviewMutation()
	const [triggerCheckReview] = courseQuery.useLazyCheckReviewQuery()
	const { data: courseInfo } = courseQuery.useGetCourseQuery(courseId)


	const onChange = useCallback(
		(name: FieldPath<GetCertificateFormValues>) =>
			(value: FieldPathValue<GetCertificateFormValues, typeof name>) => {
				setValue(name, value)
			},
		[setValue]
	)

	const [createCertificateTrigger] = courseQuery.useCreateCertificateMutation()
	const onSubmit = useCallback(
		handleSubmit(async (data) => {
			setDisableSubmitButton(true)
			if (!coursePassingInfo?.data.certificateId && userInfo) {
				await createCertificateTrigger({ courseId: coursePassingInfo?.data.courseId as number })
				await triggerGetCoursePassing({ userId: userInfo.data.id, courseId })
			}

			const response = await triggerCreateReview({ ...data, courseId })

			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				setDisableSubmitButton(false)
				return
			}

			pushSuccess({ message: ValidationSuccessMessages.SUCCESS_CHANGE })
			setGetCertificateModalOpen(true)
			setWithFeedbackSent(true)
		}),
		[courseId, coursePassingInfo]
	)

	useEffect(() => {
		if (!userInfo || !courseId) return

		triggerGetCoursePassing({ userId: userInfo.data.id, courseId })
	}, [userInfo, courseId])

	useEffect(() => {
		if (!coursePassingInfo?.data.certificateId) return
		triggerGetCertificate({
			certificateId: coursePassingInfo.data.certificateId
		})
	}, [coursePassingInfo])

	const onModalClose = useCallback(() => setGetCertificateModalOpen(false), [])
	const onSkipFeedback = useCallback(() => setGetCertificateModalOpen(true), [])

	useEffect(() => {
		register('evaluation')
		register('text', {
			validate: (val) => !!val || ValidationErrorMessages.EMPTY
		})
	}, [])

	useEffect(() => {
		setValue('evaluation', 5)
		triggerCheckReview(courseId)
			.then(({ data }) => setDisableSubmitButton(!!data?.status))
			.catch(_ => setDisableSubmitButton(false))
	}, [])

	return (
		<>
			<CreateReviewFormComponent
				control={control}
				onSubmit={onSubmit}
				onChange={onChange}
				errors={errors}
				onSkipFeedback={onSkipFeedback}
				reviewRequired={courseInfo?.data.reviewResponsibilityStatus}
				disableSubmitButton={!!disableSubmitButton}
			/>

			{certificateInfo?.data.url && (
				<GetCertificateModal
					isOpen={getCertificateModalOpen}
					onClose={onModalClose}
					title={
						withFeedbackSent
							? 'Спасибо за отзыв! Ваш сертификат готов!'
							: 'Ваш сертификат готов!'
					}
					certificateUrl={certificateInfo?.data.url || ''}
				/>
			)}
		</>
	)
}

export default CreateReviewForm
