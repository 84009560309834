import React, { FC, useState } from 'react'
import { UseFieldArrayRemove } from 'react-hook-form'

import { QuestionTabs } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import { useMatchMedia } from 'hooks'
import DeleteQuestionModal from 'components/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Tabs/Modals/DeleteQuestion'
import { ResponseEntityId } from 'types'
import Tab from './Tab'

import cl from './style.module.scss'

interface TabsProps {
	selectedTabId?: QuestionTabs['id']
	tabs: QuestionTabs[]
	onSelectTab: (tabId: QuestionTabs['id']) => void
	remove: UseFieldArrayRemove
	openModal: () => void
}

const QuestionsTabs: FC<TabsProps> = ({
	tabs,
	selectedTabId,
	onSelectTab,
	remove,
	openModal
}) => {
	const [deleteQuestionModalIsOpen, setDeleteQuestionModalIsOpen] =
		useState(false)
	const [currentQuestionId, setCurrentQuestionId] = useState<ResponseEntityId>()
	const [currentQuestionName, setCurrentQuestionName] = useState('')

	const { isTable } = useMatchMedia()
	const clickHandler = (tabId: QuestionTabs['id']) => {
		if (tabId === selectedTabId) {
			openModal()
			return
		}
		onSelectTab(tabId)
		if (isTable) {
			openModal()
		}
	}

	const openDeleteQuestionModalHandler = (
		answerId: ResponseEntityId,
		name: string
	) => {
		setCurrentQuestionId(answerId)
		setCurrentQuestionName(name)
		setDeleteQuestionModalIsOpen(true)
	}

	return (
		<>
			<DeleteQuestionModal
				isOpen={deleteQuestionModalIsOpen}
				onClose={() => setDeleteQuestionModalIsOpen(false)}
				remove={remove}
				questionId={currentQuestionId || 0}
				questionName={currentQuestionName}
			/>
			<div className={cl.tabs}>
				<div className={cl.tabsHeader}>
					<span>Вопросы</span>
				</div>
				<div className={cl.tabsList}>
					{tabs.map((tab) => (
						<Tab
							onOpenDeleteModal={openDeleteQuestionModalHandler}
							tab={tab}
							isActive={tab.id === selectedTabId}
							key={tab.id}
							onClick={clickHandler}
						/>
					))}
				</div>
			</div>
		</>
	)
}

export default QuestionsTabs
