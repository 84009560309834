import React, { useMemo } from 'react'

import { NotificationSettingsFormContainerProps } from 'containers/NotificationsSettings/NotificationSettingsForm'
import { INotificationEvent } from 'types/notification-event.model'
import NotificationsSettingsTable from 'containers/NotificationsSettings/NotificationSettingsTable'
import { NotificationSetting } from 'store/queries/user.query/types'

interface NotificationSettingsFormComponentProps
	extends NotificationSettingsFormContainerProps {
	notificationEvents: INotificationEvent[]
	notificationSettings: NotificationSetting
	isActive: boolean
}

const NotificationSettingsForm: React.FC<
	NotificationSettingsFormComponentProps
> = ({ notificationEvents, notificationSettings, isActive }) => (
	<NotificationsSettingsTable
		events={notificationEvents}
		settings={notificationSettings}
		isActive={isActive}
	/>
)

export default NotificationSettingsForm
