import { getResponse } from 'helpers/api'

import { IPushService, PushServiceApiRoutes } from './types'

const PushService: IPushService = {
	async register(body) {
		return getResponse(
			`${PushServiceApiRoutes.BASE_URL}${PushServiceApiRoutes.REGISTER}`,
			body.toJSON(),
			{
				method: 'POST',
				needAuth: true
			}
		)
	},
	async unregister() {
		return getResponse(
			`${PushServiceApiRoutes.BASE_URL}${PushServiceApiRoutes.UNREGISTER}`,
			{},
			{
				method: 'POST',
				needAuth: true
			}
		)
	}
}

export default PushService
