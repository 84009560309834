import React, { FC, useCallback } from 'react'

import PublicationSucceededModalComponent from 'components/Courses/Entities/Events/Create/PublicationSucceededModal'
import { useNavigate } from 'react-router-dom'
import { ResponseEntityId } from 'types'

export interface PublicationSucceededModalContainerProps {
	isOpen: boolean
	onClose: () => void
	publicatedEventId: ResponseEntityId
}

const PublicationSucceededModal: FC<
	PublicationSucceededModalContainerProps
> = ({ publicatedEventId, isOpen, onClose }) => {
	const navigate = useNavigate()

	const onEditEvent = useCallback(() => {
		onClose()

		setTimeout(() => {
			navigate(`/course/event/edit/${publicatedEventId}`)
		}, 500)
	}, [navigate, publicatedEventId, onClose])

	const onGoToTimeTable = useCallback(() => {
		onClose()

		setTimeout(() => {
			navigate('/timetable')
		}, 500)
	}, [navigate, onClose])
	return (
		<PublicationSucceededModalComponent
			onEditEvent={onEditEvent}
			onClose={onClose}
			isOpen={isOpen}
			onGoToTimeTable={onGoToTimeTable}
		/>
	)
}

export default PublicationSucceededModal
