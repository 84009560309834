import React, { useCallback, useState } from 'react'
import { FieldPath, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { DevTool } from '@hookform/devtools'

import { Link } from 'UI'
import { LinkStyles } from 'UI/Link/types'

import LessonsTabs from 'containers/Courses/Entities/Lessons/Tabs/Create/Wrapper.container'
import { ILesson } from 'types/models/lesson.model'

import CancelChangeModal from 'pages/Course/modals/CancelChangeModal'
import cl from '../style.module.scss'

export interface LessonFormState extends ILesson {
	mediaFiles?: File[]
	docsFiles?: File[]
}

const LessonCreatePage = () => {
	const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)

	const { courseId } = useParams()

	const {
		register,
		handleSubmit,
		setValue,
		control,
		watch,
		formState,
		getValues
	} = useForm<LessonFormState>()
	const changeHandler = useCallback(
		(name: FieldPath<LessonFormState>) => (value: any) => setValue(name, value),
		[]
	)

	return (
		<>
			<CancelChangeModal
				isOpen={cancelModalIsOpen}
				onClose={() => setCancelModalIsOpen(false)}
				redirectUri={`/course/edit/${courseId}`}
			/>
			<div className={cl.container}>
				<div className={cl.back}>
					<Link
						styleTypes={[LinkStyles.GO_BACK]}
						href={`/course/edit/${courseId}`}
					>
						Вернуться назад к курсу
					</Link>
				</div>
				<div className={cl.header}>
					<div className={cl.headerLessonInfo}>
						<h2>Новый урок</h2>
					</div>
				</div>
				<LessonsTabs
					onOpenCanceledChange={() => setCancelModalIsOpen(true)}
					getValues={getValues}
					handleSubmit={handleSubmit}
					errors={formState.errors}
					onChange={changeHandler}
					control={control}
					register={register}
					watch={watch}
					setValue={setValue}
				/>
				<DevTool control={control} />
			</div>
		</>
	)
}

export default LessonCreatePage
