import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { FieldPath, useForm } from 'react-hook-form'

import TaskAnswerComponent from 'components/Courses/Entities/Tasks/Answer'
import {
	AnswerResponsibilitiesType,
	AnswerReviewStatus,
	ITaskAnswer
} from 'types/models/task.model'
import {
	CourseRole,
	ResponseEntityId,
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import { taskQuery, userQuery } from 'store/queries'
import { useActions, useAppSelector } from 'hooks/redux'
import GetImageUrlFromFile from 'helpers/files/getImageUrlFromFile'
import { getFileWithoutId, parseIdFromFileName } from 'helpers/files'
import { useScrollToError } from 'hooks'
import { getFormData } from 'helpers'
import { isPast } from 'helpers/time'

export interface TaskAnswerProps {
	answerResponsibilitiesType?: AnswerResponsibilitiesType
	countAttempts?: number
	maxCountUploadFiles?: number
	taskId?: ResponseEntityId
	userAnswersIdList: string[]
	setTestStatus?: (_: AnswerReviewStatus) => void
	expireDate?: string
}

export interface TaskAnswerFormState extends ITaskAnswer {
	answerFile?: File[] | File
	docsFiles?: File[] | File
}

const TaskAnswer: FC<TaskAnswerProps> = (props) => {
	const {
		taskId,
		answerResponsibilitiesType,
		setTestStatus,
		userAnswersIdList,
		countAttempts,
		maxCountUploadFiles,
		expireDate
	} = props

	const { pushSuccess, pushError } = useActions((state) => state.system)

	const { token } = useAppSelector((state) => state.system)
	const { currentRole } = useAppSelector((state) => state.system)

	const { data: userInfo } = userQuery.useGetYourselfQuery(token)

	const [createAnswer] = taskQuery.useCreateAnswerMutation()
	const attempsExhausted = useMemo(
		() =>
			countAttempts !== undefined &&
			countAttempts.toString() !== '' &&
			userAnswersIdList &&
			userAnswersIdList.length >= countAttempts,
		[userAnswersIdList, countAttempts]
	)

	const { register, control, formState, handleSubmit, setValue, watch } =
		useForm<TaskAnswerFormState>()

	const answerFileWatcher = watch('answerFile')
	const answerFileInfoWatcher = watch('answerFileInfo')
	const isExpired = expireDate && isPast(expireDate)

	const setAnswerFileInfo = async (file: File | File[]) => {
		if (maxCountUploadFiles === 1 && file instanceof File) {
			setValue('answerFileInfo', {
				title: file.name,
				url: (await GetImageUrlFromFile(await getFileWithoutId(file))) as string
			})
		}
		if (answerFileWatcher !== undefined && Array.isArray(file)) {
			; (async () => {
				setValue(
					'answerFileInfo',
					await Promise.all(
						file.map(async (fileData) => {
							const [, name] = parseIdFromFileName(fileData.name)
							return {
								title: name,
								url: URL.createObjectURL(fileData)
							}
						})
					)
				)
			})()
		}
	}

	useEffect(() => {
		if (!answerFileWatcher) {
			return
		}
		setAnswerFileInfo(answerFileWatcher)
	}, [answerFileWatcher])

	useEffect(() => {
		register('answerText', {
			required: answerResponsibilitiesType?.isText
				? ValidationErrorMessages.EMPTY
				: undefined
		})
		register('answerFile', {
			required: answerResponsibilitiesType?.isFile
				? ValidationErrorMessages.EMPTY
				: undefined,
			validate: {
				value: (value) => {
					if (maxCountUploadFiles && Array.isArray(value) && value.length > maxCountUploadFiles) {
						return `${ValidationErrorMessages.UPLOAD_FILES_COUNT} ${maxCountUploadFiles}`
					}
				}
			}
		})
		register('answerFileInfo')
	}, [taskId])

	const changeHandler = useCallback(
		(name: FieldPath<TaskAnswerFormState>) => (value: any) =>
			setValue(name, value),
		[]
	)

	useScrollToError(formState)

	const submitHandler = useCallback(
		handleSubmit(async () => {
			// const response = await updateYourself(await getFormData(data))
			// const { error } = response as unknown as ResponseWithError
			// if (error) {
			// 	pushError(error.data)
			// 	return
			// }
			//
			// setChangeInfoModalIsOpen(true)
		}),
		[handleSubmit]
	)

	const submitStudentHandler = useCallback(
		handleSubmit(async ({ answerFile, answerText }) => {
			const response = await createAnswer({
				taskId: Number(taskId),
				body: await getFormData({
					answerFile,
					answerText,
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
				})
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)

			if (setTestStatus) setTestStatus(AnswerReviewStatus.NEED_CHECK)

			setValue('answerText', '')
			setValue('answerFile', [])
			setValue('answerFileInfo', [])
		}),
		[handleSubmit, taskId]
	)

	return (
		<TaskAnswerComponent
			previewFile={answerFileInfoWatcher || null}
			errors={formState.errors}
			control={control}
			onChange={changeHandler}
			onSubmit={
				currentRole === CourseRole.STUDENT
					? submitStudentHandler
					: submitHandler
			}
			avatarParams={{
				photoUrl: userInfo?.data.avatarUrl as string,
				lastName: userInfo?.data.lastName,
				firstName: userInfo?.data.firstName
			}}
			answerResponsibilitiesType={answerResponsibilitiesType}
			attempsExhausted={attempsExhausted}
			maxCountUploadFiles={maxCountUploadFiles}
			isExpiredDate={isExpired as boolean | undefined}
			{...props}
		/>
	)
}

export default TaskAnswer
