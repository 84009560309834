import React, { Dispatch, FC, SetStateAction } from 'react'

import { Button, EmptyContent } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import plusIconSrc from 'UI/Button/images/plus.svg'
import ContentFormTable from 'containers/Courses/Tabs/Content/ContentForm/Table'
import { ResponseEntityId } from 'types'
import emptyIconSrc from './icons/emptyIconQuestions.svg'

import cl from './style.module.scss'

export interface CoursesTabContentProps {
	openCreateSectionModal: () => void
	openEditSectionModal: (id: ResponseEntityId) => void
	sectionsIdList: ResponseEntityId[]
	courseId: ResponseEntityId
	setSortedSectionsIdList: Dispatch<SetStateAction<ResponseEntityId[]>>
}
const ContentFormTab: FC<CoursesTabContentProps> = ({
	openCreateSectionModal,
	openEditSectionModal,
	sectionsIdList,
	courseId,
	setSortedSectionsIdList
}) => (
	<div className={cl.container}>
		<div className={cl.header}>
			<span>Список материалов</span>
			<Button
				onClick={openCreateSectionModal}
				styleTypes={[ButtonStyles.ROUND, ButtonStyles.PRIMARY]}
			>
				<img src={plusIconSrc} alt="plus" />
				Добавить новый раздел
			</Button>
		</div>
		<div className={cl.body}>
			{sectionsIdList.length > 0 ? (
				<ContentFormTable
					setSortedSectionsIdList={setSortedSectionsIdList}
					courseId={courseId}
					sectionsIdList={sectionsIdList}
					openEditSectionModal={openEditSectionModal}
				/>
			) : (
				<EmptyContent
					imageSrc={emptyIconSrc}
					title="Создайте материалы курса"
				/>
			)}
		</div>
	</div>
)

export default ContentFormTab
