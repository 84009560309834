import {
	CourseRole,
	FilterWithPagination,
	ResponseEntityId,
	SchoolRole
} from 'types'
import { UserRole } from 'store/slices/system/types'
import { EntityId } from '@reduxjs/toolkit'
import {
	INotification,
	NotificationStatuses
} from 'types/models/notification.model'

export enum UserQueryApiRoutes {
	BASE_URL = 'users',
	UPDATE_YOURSELF = '/change',
	USERS_CHANGE_PASS = 'users/change-pass',
	GET_NOTIFICATIONS = 'notifications',
	GET_EVENTS_FOR_NOTIFICATIONS = 'get-events-for-notifications',
	GET_NOTIFICATION_SETTINGS = 'get-notification-settings',
	CHANGE_NOTIFICATION_SETTINGS = 'change-notification-settings',
	CHANGE_NOTIFICATION_STATUS = 'change-notifications-status'
}

export interface GetUsersParticipationInCourse {
	userId: ResponseEntityId
	schoolId: ResponseEntityId
}
export interface CoursesParticipationUserInfo {
	courseId: ResponseEntityId
	name: string
	role: string
}
export interface CoursesParticipationInfo {
	data: CoursesParticipationUserInfo[]
}
export interface UsersListFilter extends FilterWithPagination {
	usersIdList?: ResponseEntityId[]
	courseId?: EntityId
}
export interface GetRoleInCourse {
	userId: ResponseEntityId
}
export interface RoleInCourseResponse {
	data: UserRole[]
}

export interface GetEventsForNotificationsPayload {
	schoolId?: number
	courseId?: number
	role: SchoolRole | CourseRole
}

export interface GetNotificationSettings
	extends GetEventsForNotificationsPayload {}

export interface GetNotificationSettingsResponse {
	data: GetNotificationSettingsData
}

interface GetNotificationSettingsData {
	userNotificationSettings: NotificationSetting
	isActive: boolean
}

export interface NotificationSetting {
	[eventId: string]: [NotificationType, NotificationType]
}

export interface GetNotificationEventsPayload {
	role: SchoolRole | CourseRole
}

export interface GetCourseNotificationEventsPayload {
	courseId: EntityId
	courseSettings: true
}

export interface ChangeNotificationSettings
	extends ChangeNotificationSettingsPayload {}

interface ChangeNotificationSettingsPayload {
	userNotificationSettings: NotificationSetting
	isActive: boolean
	role: SchoolRole | CourseRole
}

export interface GetNotificationsPayload {
	role: SchoolRole | CourseRole
}

export interface GetNotificationsResponse {
	data: INotification[]
}

export enum NotificationType {
	EMAIL = 1,
	PUSH = 2
}

export enum NotificationCourseType {
	TEACHER = 1,
	CURATOR = 2,
	STUDENT = 3
}

export interface ChangeNotificationStatusPayload {
	notificationsIdList: EntityId[]
	status: NotificationStatuses
}
