const isRunningStandalone = () => {
	const iosStandalone = (navigator as any).standalone
	const androidStandalone = window.matchMedia(
		'(display-mode: standalone)'
	).matches

	return iosStandalone || androidStandalone
}

export default isRunningStandalone
