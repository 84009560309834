import React, { FC, useCallback } from 'react'

import { Button, Link, Modal } from 'UI'
import { ModalSize } from 'UI/Modal/types'
import { ButtonStyles } from 'UI/Button/types'
import { useDisableScrollManual } from 'hooks/useDisableScrollManual'

import cl from './styles.module.scss'

interface MaxUsersModalProps {
    onClose: () => void
    isOpen: boolean
}

const MaxUsersModal: FC<MaxUsersModalProps> = ({ isOpen, onClose }) => {
    useDisableScrollManual(isOpen)

    return (
        <Modal
            isOpen={isOpen}
            styleTypes={[ModalSize.BIG]}
            title="Ограничение по тарифу"
            description='Вы добавили максимальное число пользователей'
            onClose={onClose}
        >
            <div className={cl.buttons}>
                <Button
                    styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
                    onClick={onClose}
                >
                    Отмена
                </Button>
                <Button
                    styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
                    onClick={onClose}
                >
                    <Link href="/tariffs">Тарифы</Link>
                </Button>
            </div>
        </Modal>
    )
}

export default MaxUsersModal
