import React, { FC, useCallback, useEffect } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormWatch
} from 'react-hook-form'

import {
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import LimitationsFormComponent from 'components/Courses/Entities/Tasks/Tabs/Content/LimitationsForm'
import { TaskFormState } from 'pages/Course/Task/Create.page'
import { ITask } from 'types/models/task.model'
import taskQuery from 'store/queries/task.query'
import { useActions } from 'hooks/redux'

export type LimitationsFormTabState = Pick<
	TaskFormState,
	| 'taskResponsibilityStatus'
	| 'passingScore'
	| 'countAttempts'
	| 'datePassEndSettings'
	| 'datePassStartSettings'
>

interface LimitationsFormProps {
	errors: FieldErrors<LimitationsFormTabState>
	onChange: (name: FieldPath<LimitationsFormTabState>) => (value: any) => void
	control: Control<LimitationsFormTabState>
	register: UseFormRegister<LimitationsFormTabState>
	handleSubmit: UseFormHandleSubmit<LimitationsFormTabState>
	getValues: UseFormGetValues<LimitationsFormTabState>
	watch: UseFormWatch<LimitationsFormTabState>
	currentTaskInfo: ITask<string>
	onOpenCanceledChange: () => void
}
const LimitationsFormTab: FC<LimitationsFormProps> = ({
	control,
	getValues,
	errors,
	onChange,
	register,
	handleSubmit,
	watch,
	currentTaskInfo,
	onOpenCanceledChange
}) => {
	const [changeTask] = taskQuery.useChangeTaskMutation()
	const { pushError, pushSuccess } = useActions((state) => state.system)

	console.log(watch())

	useEffect(() => {
		register('taskResponsibilityStatus', {
			required: ValidationErrorMessages.CHOOSE_AT_LEAST_ONE
		})
		register('passingScore', {
			validate: (value) =>
				value.toString() === '' ||
				/^([0-9]|10)$/.test(`${value}`) ||
				ValidationErrorMessages.PASSING_SCORE_TASK_INCORRECT
		})
		register('countAttempts')
		register('datePassStartSettings.date', {
			validate: (value) =>
				!!(
					!getValues('datePassStartSettings.isActive') ||
					(getValues('datePassStartSettings.date') &&
						new Date(value).toLocaleDateString())
				) || ValidationErrorMessages.DATEPICKER_INCORRECT
		})
		register('datePassStartSettings.time', {
			validate: (value) =>
				!!(
					!getValues('datePassStartSettings.isActive') ||
					(getValues('datePassStartSettings.isActive') &&
						getValues('datePassStartSettings.date') &&
						new Date(value).toLocaleDateString())
				) || ValidationErrorMessages.DATEPICKER_INCORRECT
		})
		register('datePassEndSettings.date', {
			validate: (value) =>
				!getValues('datePassEndSettings.isActive') ||
				!(
					getValues('datePassEndSettings.time') &&
					new Date(value).toLocaleDateString()
				) ||
				new Date(getValues('datePassStartSettings.date')) < new Date(value) ||
				ValidationErrorMessages.DATEPICKER_END_BIG_THEN_START
		})
		register('datePassEndSettings.time', {
			validate: (value) => !!value || ValidationErrorMessages.EMPTY
		})

		return () => {
			register('taskResponsibilityStatus')
			register('passingScore', { required: undefined, validate: undefined })
			register('countAttempts', { required: undefined, validate: undefined })
			register('datePassStartSettings.date', { validate: undefined })
			register('datePassStartSettings.time', { validate: undefined })
			register('datePassEndSettings.date', { validate: undefined })
			register('datePassEndSettings.time', { validate: undefined })
		}
	}, [])

	const submitHandler = useCallback(
		handleSubmit(async (data) => {
			if (!currentTaskInfo) {
				return
			}
			const response = await changeTask({
				body: {
					...data,
					datePassStartSettings: data.datePassStartSettings.isActive
						? data.datePassStartSettings
						: undefined,
					datePassEndSettings: data.datePassEndSettings.isActive
						? data.datePassEndSettings
						: undefined
				},
				taskId: +`${currentTaskInfo.id}`
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
		}),
		[handleSubmit]
	)

	return (
		<LimitationsFormComponent
			onOpenCanceledChange={onOpenCanceledChange}
			getValues={getValues}
			control={control}
			onChange={onChange}
			onSubmit={submitHandler}
			errors={errors}
			watch={watch}
		/>
	)
}

export default LimitationsFormTab
