import ViewAnswer from 'containers/Courses/Entities/Tasks/Check/ViewAnswer'
import { useActions, useAppSelector } from 'hooks/redux'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { courseQuery, taskQuery } from 'store/queries'
import { GetTaskResponse, TaskGetAnswersResponse } from 'store/queries/task.query/types'
import { ResponseWithError } from 'types'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { ITaskAnswerResponse } from 'types/models/task.model'

const AnswerAttemptView: React.FC = () => {
	const [currentAnswer, setCurrentAnswer] = useState<ITaskAnswerResponse | undefined>()
	const { courseId, taskId, answerId } = useParams()
	const { data: courseInfo } = courseQuery.useGetCourseQuery(+`${courseId}`)
	const [triggerGetTask] = taskQuery.endpoints.getTask.useLazyQuery()
	const [triggerGetAnswers] = taskQuery.useGetAnswersMutation()
	const { task, taskAnswersInfo, creatorId, taskCurrentAnswer } =
		useAppSelector((state) => state.task)

	const { setTask } = useActions((state) => state.task)
	const { pushError } = useActions((state) => state.system)

	useEffect(() => {
		async function getTaskData() {
			const response = await triggerGetTask({
				taskId: Number(taskId)
			})

			const { error } = response as unknown as ResponseWithError

			if (error) {
				pushError(error.data)
				return
			}

			const { data: currentTaskInfo } = response as unknown as {
				data: GetTaskResponse
			}

			setTask(currentTaskInfo.data)
		}

		async function getAnswers() {
			if (taskCurrentAnswer.id || !task.name) return
			const responseGetAnswers = await triggerGetAnswers({
				userAnswersIdList: task.userAnswersIdList
			})

			const { error } = responseGetAnswers as unknown as ResponseWithError

			if (error) {
				pushError(error.data)
				return
			}

			const { data: answersData } = responseGetAnswers as unknown as {
				data: TaskGetAnswersResponse
			}

			const currentAnswerFound = answersData.data.find(
				(a) => a.id.toString() === answerId
			)

			setCurrentAnswer(currentAnswerFound)
		}

		if (!task.name) {
			getTaskData()
		}

		getAnswers()
	}, [task])

	if (!task.name || !courseInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			<ViewAnswer
				course={courseInfo.data}
				task={task}
				taskId={taskId!}
				courseId={courseId!}
				answerId={answerId!}
				creatorId={creatorId}
				taskCurrentAnswer={currentAnswer || taskCurrentAnswer}
				taskAnswersInfo={taskAnswersInfo}
			/>
		</>
	)
}

export default AnswerAttemptView
