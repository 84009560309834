const getFirstAndLastDayOfTheMonth = (currentDate?: Date) => {
	let date = new Date()

	if (currentDate) date = currentDate

	const firstDayDate = new Date(date.getFullYear(), date.getMonth(), 1)
	const lastDayDate = new Date(date.getFullYear(), date.getMonth() + 1, 1)

	return {
		firstDayDate,
		lastDayDate
	}
}

export default getFirstAndLastDayOfTheMonth
