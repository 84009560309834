import React, { FC, useEffect, useState } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import classNames from 'classnames'

import cl from './style.module.scss'

interface AudioPlayerProps {
	audioUrl: string
	classes?: string
}

const AudioPlayer: FC<AudioPlayerProps> = ({ audioUrl, classes }) => {
	const [audio, setAudio] = useState(new Audio(audioUrl))

	return (
		<div className={classNames(cl.container, classes)}>
			<ReactAudioPlayer src={audioUrl} controls />
		</div>
	)
}

export default AudioPlayer
