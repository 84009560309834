import React from 'react'

import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ModalHeight } from 'UI/Modal/types'

import cl from '../ConfirmSwitchModal/style.module.scss'

interface ConfirmSwitchModalProps {
	onClose: () => void
	onConfirm: (extendsTariff: boolean) => void
	isOpen: boolean
	tariffName: string
	validityPeriod: number
}

const ConfirmTariffRenewal: React.FC<ConfirmSwitchModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	tariffName,
	validityPeriod
}) => (
	<Modal
		styleTypes={[ModalHeight.SMALL]}
		isOpen={isOpen}
		onClose={onClose}
		title={`Продление тарифа ${tariffName}`}
		description={`Вы собираетесь продлить тариф ${tariffName} на ${validityPeriod} месяцев.`}
	>
		<div className={cl.buttonsContainer}>
			<Button onClick={onClose} styleTypes={[ButtonStyles.DEFAULT_BG]}>
				Отмена
			</Button>
			<Button
				onClick={() => onConfirm(false)}
				styleTypes={[ButtonStyles.PRIMARY]}
			>
				Перейти к оплате
			</Button>
		</div>
	</Modal>
)

export default ConfirmTariffRenewal
