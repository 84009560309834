import React, { FC } from 'react'

import classnames from 'classnames'
import { ITag } from 'UI/Tags/types'

import cl from '../style.module.scss'

interface TagItemProps {
	tag: ITag
	isActive: boolean
}

const TagItem: FC<TagItemProps> = ({ tag, isActive }) => (
	<label
		className={classnames([cl.tagItem], {
			[cl.tagItemSelected]: isActive
		})}
	>
		{tag.text}
	</label>
)

export default TagItem
