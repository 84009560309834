import React, { forwardRef, MouseEvent } from 'react'
import { TableCellContainerProps } from 'containers/Timetable/Tabs/WeekTab/WeekTableCell/TableCell'
import classNames from 'classnames'

import cl from 'components/Timetable/Tabs/WeekTab/WeekTableCell/style.module.scss'
import { makeStringShorter } from 'helpers/text'

interface TableCellComponentProps extends TableCellContainerProps {
	onMouseEnter: (_: MouseEvent) => void
	onMouseLeave: (_: MouseEvent) => void
	mouseOver: boolean
	start?: string
	end?: string
	scaleRatio: number
}

const TableCell = forwardRef<HTMLDivElement, TableCellComponentProps>(
	(
		{
			event,
			highlighted,
			onHoverIndexChange,
			index,
			onMouseEnter,
			onMouseLeave,
			mouseOver,
			start,
			end,
			scaleRatio,
			onDetailsOpen
		},
		ref
	) => (
		<>
			<div
				className={classNames(cl.cell, cl.borderBottom, {
					[cl.highlighted]: highlighted
				})}
				onMouseEnter={() => onHoverIndexChange(index)}
				onMouseLeave={() => onHoverIndexChange(null)}
			>
				{event && (
					<>
						<div
							className={classNames(cl.label, cl.noMargin, {
								[cl.absolute]: scaleRatio > 1
							})}
							style={{ height: `${(scaleRatio || 1) * 100}%` }}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							onClick={() => onDetailsOpen(event)}
							key={event.id}
						>
							<div>
								<div>
									{start} - {end}
								</div>
								<div className={cl.theme}>
									{makeStringShorter(event.theme, 25)}
								</div>
							</div>

							{mouseOver && (
								<div className={cl.popup} ref={ref}>
									<div>
										{start} - {end}
									</div>
									<div className={cl.theme}>
										{makeStringShorter(event.theme, 25)}
									</div>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</>
	)
)

export default TableCell
