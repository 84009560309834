import React, { FC, memo, useCallback, useEffect } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormSetValue
} from 'react-hook-form'

import AccessFormTabComponent from 'components/Courses/Tabs/Content/AccessForm'
import { IOption } from 'UI/Select/types'
import { CourseFormState } from 'pages/Course/Create.page'
import courseQuery from 'store/queries/course.query'
import {
	ResponseEntityId,
	ResponseWithError,
	ValidationSuccessMessages
} from 'types'
import { useActions } from 'hooks/redux'

export type AccessFormTabState = Pick<
	CourseFormState,
	'teachersIdList' | 'curatorsIdList'
>

interface AccessFormTabProps {
	errors: FieldErrors<AccessFormTabState>
	onChange: (name: FieldPath<AccessFormTabState>) => (value: any) => void
	control: Control<AccessFormTabState>
	register: UseFormRegister<AccessFormTabState>
	handleSubmit: UseFormHandleSubmit<AccessFormTabState>
	setValue: UseFormSetValue<AccessFormTabState>
	employersOptionsList: IOption[]
	courseId: ResponseEntityId
	onOpenCanceledChange: () => void
}

const AccessFormTab: FC<AccessFormTabProps> = ({
	errors,
	onChange,
	register,
	handleSubmit,
	setValue,
	control,
	employersOptionsList,
	courseId,
	onOpenCanceledChange
}) => {
	const { pushError, pushSuccess } = useActions((state) => state.system)
	useEffect(() => {
		register('teachersIdList')
		register('curatorsIdList')
	}, [])
	const [changeAccessInfo] = courseQuery.useChangeCourseAccessInfoMutation()

	const submitHandler = useCallback(
		handleSubmit(async ({ teachersIdList, curatorsIdList }) => {
			const response = await changeAccessInfo({
				teachersIdList,
				curatorsIdList,
				courseId
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
		}),
		[handleSubmit]
	)

	return (
		<>
			<AccessFormTabComponent
				onOpenCanceledChange={onOpenCanceledChange}
				onSubmit={submitHandler}
				control={control}
				onChange={onChange}
				errors={errors}
				setValue={setValue}
				employersOptionsList={employersOptionsList}
			/>
		</>
	)
}

export default memo(AccessFormTab)
