import React, { FC, useCallback, useEffect } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	useWatch
} from 'react-hook-form'

import {
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import AnswerSettingsFormTabComponent from 'components/Courses/Entities/Tasks/Tabs/Content/AnswerSettings'
import { TaskFormState } from 'pages/Course/Task/Create.page'
import { taskQuery } from 'store/queries'
import { ITask } from 'types/models/task.model'
import { useActions } from 'hooks/redux'

export type AnswerSettingsFormTabState = Pick<
	TaskFormState,
	'answerResponsibilitiesType' | 'maxCountUploadFiles'
>

interface AnswerSettingsFormProps {
	errors: FieldErrors<AnswerSettingsFormTabState>
	onChange: (
		name: FieldPath<AnswerSettingsFormTabState>
	) => (value: any) => void
	control: Control<AnswerSettingsFormTabState>
	register: UseFormRegister<AnswerSettingsFormTabState>
	handleSubmit: UseFormHandleSubmit<AnswerSettingsFormTabState>
	getValues: UseFormGetValues<AnswerSettingsFormTabState>
	currentTaskInfo: ITask<string>
	onOpenCanceledChange: () => void
}
const AnswerSettingsFormTab: FC<AnswerSettingsFormProps> = ({
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	getValues,
	currentTaskInfo,
	onOpenCanceledChange
}) => {
	const [changeTask] = taskQuery.useChangeTaskMutation()

	const { pushError, pushSuccess } = useActions((state) => state.system)

	useEffect(() => {
		register('answerResponsibilitiesType')
		register('answerResponsibilitiesType.isText', {
			validate: (value) =>
				getValues('answerResponsibilitiesType.isFile') ||
				value ||
				ValidationErrorMessages.CHOOSE_AT_LEAST_ONE
		})
		register('answerResponsibilitiesType.isFile', {
			validate: (value) =>
				getValues('answerResponsibilitiesType.isText') ||
				value ||
				ValidationErrorMessages.CHOOSE_AT_LEAST_ONE
		})
		register('maxCountUploadFiles')
		return () => {
			register('answerResponsibilitiesType.isText', { validate: undefined })
			register('answerResponsibilitiesType.isFile', { validate: undefined })
		}
	}, [])

	const fileAnswerMode = useWatch({
		control,
		name: 'answerResponsibilitiesType.isFile'
	})

	const submitHandler = useCallback(
		handleSubmit(async (data) => {
			if (!currentTaskInfo) {
				return
			}

			const response = await changeTask({
				body: data,
				taskId: +`${currentTaskInfo.id}`
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
		}),
		[handleSubmit]
	)

	return (
		<AnswerSettingsFormTabComponent
			onOpenCanceledChange={onOpenCanceledChange}
			errors={errors}
			control={control}
			onChange={onChange}
			onSubmit={submitHandler}
			fileAnswerMode={fileAnswerMode}
		/>
	)
}

export default AnswerSettingsFormTab
