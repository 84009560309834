import React from 'react'
import Tariffs from 'containers/Tariffs'

import cl from 'pages/style.module.scss'

const TariffsPage = () => (
	<main className={cl.contentTariffs}>
		<div className={cl.container}>
			<h2 className={cl.title}>Тарифные планы</h2>

			<p>
				На этой странице вы можете узнать детали вашего тарифа, а также обновить
				учетную запись.
			</p>
		</div>

		<Tariffs />
	</main>
)

export default TariffsPage
