import React, { FC, useCallback, useState, useEffect } from 'react'

import CertificateSettingsComponent from 'components/Courses/Tabs/Content/MainForm/CertificateSettings'
import {
	Control,
	FieldPath,
	UseFormGetValues,
	UseFormSetValue,
	UseFormWatch,
	FieldErrors
} from 'react-hook-form'
import { MainFormTabState } from '../index'

export interface CertificateSettingsProps {
	onChange: (name: FieldPath<MainFormTabState>) => (value: any) => void
	control: Control<MainFormTabState>
	watch: UseFormWatch<MainFormTabState>
	setValue: UseFormSetValue<MainFormTabState>
	onOpenCertificateTemplateModal: () => void
	getValues: UseFormGetValues<MainFormTabState>
	errors: FieldErrors<MainFormTabState>
}



const CertificateSettings: FC<CertificateSettingsProps> = ({
	setValue,
	onOpenCertificateTemplateModal,
	errors,
	...props
}) => {
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const openModalHandler = useCallback(setModalIsOpen.bind(null, true), [])
	const closeModalHandler = (saveChanges?: boolean) => {
		if (saveChanges && errors && !errors.certificateSettings?.templateInfo?.teacherSettings?.name
			&& !errors.certificateSettings?.templateInfo?.schoolSettings?.signerMarkUrl
			&& !errors.certificateSettings?.templateInfo?.schoolSettings?.signerSignUrl
			&& !errors.certificateSettings?.templateInfo?.schoolSettings?.schoolName
			&& !errors.certificateSettings?.templateInfo?.schoolSettings?.signerPost
			&& !errors.certificateSettings?.templateInfo?.schoolSettings?.signerName
		) {
			setModalIsOpen(false)
		}

		if (!saveChanges) {
			setModalIsOpen(false)
		}
	}

	const resetCertificateConfigsHandler = () => {
		setValue('certificateSettings.resetCertificate', true)
		// setValue('certificateSettings.templateInfo.teacherSettings.isActive', false)
		setValue('certificateSettings.templateInfo.teacherSettings.name', '')
		// setValue('certificateSettings.templateInfo.schoolSettings.isActive', false)
		setValue('certificateSettings.templateInfo.schoolSettings.schoolName', '')
		setValue('certificateSettings.templateInfo.schoolSettings.signerPost', '')
		setValue('certificateSettings.templateInfo.schoolSettings.signerName', '')
		setValue('signerMark', undefined)
		setValue('signerSign', undefined)
		setValue('sampleCertificateForMP', undefined)
		setValue(
			'certificateSettings.templateInfo.schoolSettings.signerMarkUrl',
			undefined
		)
		setValue(
			'certificateSettings.templateInfo.schoolSettings.signerSignUrl',
			undefined
		)
		setValue(
			'certificateSettings.templateInfo.schoolSettings.sampleCertificateForMPUrl',
			undefined
		)
	}
	return (
		<CertificateSettingsComponent
			onOpenCertificateTemplateModal={onOpenCertificateTemplateModal}
			modalIsOpen={modalIsOpen}
			onOpenModal={openModalHandler}
			onCloseModal={closeModalHandler}
			resetCertificateConfigs={resetCertificateConfigsHandler}
			errors={errors}
			{...props}
		/>
	)
}

export default CertificateSettings
