import React, { FC } from 'react'
import { IconProps } from './BellIcon'

const EducationHatIcon: FC<IconProps> = ({ fill }) => (
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3.20801 8.16663V9.33329L16.0413 14L26.5413 9.33329V8.16663L13.708 4.66663L3.20801 8.16663Z"
			fill={fill || '#666666'}
		/>
		<path
			d="M5.54199 12.8334V17.8115C5.54199 19.7027 10.2098 22.3534 16.042 22.1679C20.7087 22.0209 23.7257 19.8672 24.2087 18.123C24.2367 18.0192 24.2518 17.9154 24.2518 17.8104V12.8334L16.042 16.3334L10.2087 14.3885V18.137L9.04199 17.7124V14L5.54199 12.8334Z"
			fill={fill || '#666666'}
		/>
	</svg>
)

export default EducationHatIcon
