import { ResponseEntityId, CourseRole, SchoolRole } from 'types'
import { CertificateSettings } from './settings/certificate.model'
import { MarketplaceSettings } from './settings/marketplace.model'
import { CommunicationsSettings } from './settings/communications.model'
import { NotificationsSettings } from './settings/notification.model'

export enum ReviewResponsibilityStatus {
	REQUIRED = 1,
	OPTIONAL
}
export enum StatusType {
	PUBLISHED = 1,
	TRASHED,
	ARCHIVED
}

export enum CoursePassStatus {
	NEW = 1,
	PROCESS = 2,
	COMPLETED = 3
}

export enum CertificateStatus {
	READY,
	GOTTEN
}

export interface ICourse {
	id: ResponseEntityId
	name: string
	description: string
	cover?: File
	coverUrl?: string
	bannerForMP?: File
	bannerForMPUrl?: string
	statusType: StatusType
	creatorId: ResponseEntityId
	directionsIdList: ResponseEntityId[]
	teachersIdList: ResponseEntityId[]
	curatorsIdList: ResponseEntityId[]
	studentsIdList: ResponseEntityId[]
	certificateSettings: CertificateSettings
	reviewResponsibilityStatus: ReviewResponsibilityStatus
	communicationsSettings: CommunicationsSettings
	marketplaceSettings: MarketplaceSettings
	notificationsSettings: NotificationsSettings
	sectionsIdList: ResponseEntityId[]
	dateCreate: string
	dateEdit: string
	signerSign?: File
	sampleCertificateForMP?: File
	signerMark?: File
	courseCompletionTime: number
	accessTimeCourse: number
}

export interface ICourseWithCertificateImages
	extends Omit<ICourse, 'courseCompletionTime' | 'accessTimeCourse'> {
	signerSign?: File
	sampleCertificateForMP?: File
	signerMark?: File
	courseCompletionTime: CourseCompletionTime
	accessTimeCourse: AccessTimeCourse
}

interface CourseCompletionTime {
	days: number
	other: boolean
}

interface AccessTimeCourse {
	days: number
	other: boolean
}

export interface ICourseReview {
	id: ResponseEntityId
	dateCreate: string
	user: string
	text: string
	evaluation: ResponseEntityId
	avatar?: string
}

export interface CopyCourse {
	courseId: Number
	role: CourseRole | SchoolRole
}

export interface CourseReviewRequest {
	courseId?: number
	limit?: number
}