import React, { FC } from 'react'
import MonthTabComponent from 'components/Timetable/Tabs/MonthTab'
import { IEvent } from 'types/models/event.model'
import { TimetableContentFormValues } from 'containers/Timetable/TimetableContent'
import { Control, FieldPath, useWatch } from 'react-hook-form'

export interface MonthTabContainerProps {
	control: Control<TimetableContentFormValues>
	onChange: (
		name: FieldPath<TimetableContentFormValues>
	) => (value: any) => void
	onDetailsOpen: (_: IEvent) => void
}

const MonthTab: FC<MonthTabContainerProps> = ({
	control,
	onChange,
	onDetailsOpen
}) => {
	const currentDate = useWatch({
		control,
		name: 'currentDate'
	})

	const events = useWatch({
		control,
		name: 'events'
	})

	return (
		<MonthTabComponent
			control={control}
			onChange={onChange}
			events={events}
			currentDate={currentDate}
			onDetailsOpen={onDetailsOpen}
		/>
	)
}

export default MonthTab
