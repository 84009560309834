import React, { FC } from 'react'
import { CoursePassStatus } from 'types/models/course.model'
import { CourseStatusProps as CourseStatusContainerProps } from 'containers/Courses/StudentRole/Tabs/Content/CourseStatus'

interface CourseStatusProps extends CourseStatusContainerProps {
	courseStatusClassName: string
}

const CourseStatus: FC<CourseStatusProps> = ({
	status,
	courseStatusClassName
}) => (
	<div className={courseStatusClassName}>
		{status === CoursePassStatus.NEW && 'Новый'}
		{status === CoursePassStatus.PROCESS && 'В процессе'}
		{status === CoursePassStatus.COMPLETED && 'Завершен'}
		{/* Todo add the percentage of the course being complete */}
	</div>
)

export default CourseStatus
