import React, { FC } from 'react'

import { ICourse } from 'types/models/course.model'
import { DropdownItem } from 'UI/Dropdown/types'
import CoursesMobileTableComponent from 'components/MobileTables/Courses/CoursesTable'

export interface CoursesTableMobileProps {
	data: ICourse[]
	getDropdownItemsList: (course: ICourse) => DropdownItem[]
}

const CoursesMobileTable: FC<CoursesTableMobileProps> = ({
	data,
	getDropdownItemsList
}) => (
	<CoursesMobileTableComponent
		data={data}
		getDropdownItemsList={getDropdownItemsList}
	/>
)

export default CoursesMobileTable
