import React, { useEffect } from 'react'
import { AnswersSortComponent } from 'components/Courses/Entities/Tasks/AnswersSortComponent'
import {
	FieldPath,
	UseFormSetValue,
	Control,
	UseFormRegister
} from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ITestAnswer } from 'store/queries/test.query/types'
import { TestAnswerSortValues } from '../Check/AttemptsTable'

interface TestSortProps {
	title: string
	description: string
	control: Control<TestAnswerSortValues>
	setValue: UseFormSetValue<TestAnswerSortValues>
	register: UseFormRegister<TestAnswerSortValues>
	testId: number
	courseId: number
	testAnswersInfo: ITestAnswer[]
	setCurrentChecking: (id: string) => void
	setCurrentAttempt: (id: string) => void
}

export const TestAnswersSort = ({
	title,
	description,
	setValue,
	control,
	register,
	testAnswersInfo,
	testId,
	courseId,
	setCurrentChecking,
	setCurrentAttempt
}: TestSortProps) => {
	const navigate = useNavigate()
	const lastTestAnswerIndex = testAnswersInfo && testAnswersInfo.length - 1
	const navigateToFirstTaskinList = () => {
		setCurrentChecking(String(testAnswersInfo[lastTestAnswerIndex].id))
		setCurrentAttempt(String(testAnswersInfo[lastTestAnswerIndex].attempt))
		navigate(
			`/course/${courseId}/test/${testId}/answer/attempt/view/${testAnswersInfo[lastTestAnswerIndex].id}`
		)
	}

	const handleChange =
		(name: FieldPath<TestAnswerSortValues>) => (value: string | number) => {
			setValue(name, value as string)
			setValue('page', 1)
		}

	useEffect(() => {
		; (async () => {
			setValue('filterStatus', 'Все ответы')
			setValue('sortDate', 'asc')
		})()
		register('filterStatus')
		register('sortDate')
		register('page')
	}, [])

	const selectData = [
		{
			name: 'filterStatus',
			onChange: handleChange('filterStatus'),
			optionsList: [
				{ value: 'Все ответы', text: 'Все ответы' },
				{ value: 'Пройден', text: 'Пройден' },
				{ value: 'Не пройден', text: 'Не пройден' }
			],
			control
		},
		{
			name: 'sortDate',
			onChange: handleChange('sortDate'),
			optionsList: [
				{ value: 'asc', text: 'Сначала новые' },
				{ value: 'desc', text: 'Сначала старые' }
			],
			control
		}
	]
	return (
		<AnswersSortComponent
			name={title}
			description={description}
			selectData={selectData}
			navigateCb={navigateToFirstTaskinList}
		/>
	)
}
