import React, { FC } from 'react'

import { useMatchMedia } from 'hooks'
import { useAppSelector } from 'hooks/redux'
import { Logo } from 'UI'
import { ProfileMenu, NotificationsBar } from 'containers/Header'
import { AsideBarToggleButton } from 'components/AsideBar/AsideBarToggleButton'

import { useLocation } from 'react-router-dom'
import cl from './style.module.scss'

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
	const { isMobile } = useMatchMedia()
	const { pathname } = useLocation()
	const { isFinalTestOngoing } = useAppSelector((state) => state.test)

	if (pathname === '/help/download-app') return null

	return (
		<header className={`${cl.header} ${isFinalTestOngoing ? cl.hide : ''}`}>
			<div className={cl.burgerMenu}>
				{!isMobile && <AsideBarToggleButton />}
				<Logo />
			</div>
			<div className={cl.optionsMenu}>
				{!isMobile && <NotificationsBar />}
				<ProfileMenu />
			</div>
		</header>
	)
}

export default Header
