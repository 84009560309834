import React from 'react'
import { Control, FieldPath, FieldPathValue } from 'react-hook-form'

import TariffsPlansComponent from 'components/Tariffs/TariffsPlans'
import { IRate } from 'types/models/rate.model'
import { IOption } from 'UI/Select/types'
import { TariffsFormValues } from '..'

export interface TariffsPlansContainerProps {
	tariffs: IRate[]
	activeTariffId: number
	tariffItems: IOption[]
	control: Control<TariffsFormValues>
	onChange: (
		name: FieldPath<TariffsFormValues>
	) => (value: FieldPathValue<TariffsFormValues, typeof name>) => void
}

const TariffsPlans: React.FC<TariffsPlansContainerProps> = ({
	tariffs,
	activeTariffId,
	tariffItems,
	control,
	onChange
}) => (
	<TariffsPlansComponent
		tariffs={tariffs}
		activeTariffId={activeTariffId}
		tariffItems={tariffItems}
		control={control}
		onChange={onChange}
	/>
)

export default TariffsPlans
