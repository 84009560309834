import React, { FC, useCallback, useMemo, useState } from 'react'
import { Column } from 'react-table'

import { Dropdown, Loader, TableDesktop, Avatar } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'

import { courseQuery, userQuery } from 'store/queries'
import { useMatchMedia } from 'hooks'
import { ResponseEntityId } from 'types'
import { DropdownItem } from 'UI/Dropdown/types'
import { getUniqueId } from 'helpers'
import { ConfirmDeleteStudentModal } from 'components/Courses/Tabs/Content/StudentsForm/modals'
import StudentsFormTableMobile from 'containers/MobileTables/StudentsFormTable'
import { ICourse } from 'types/models/course.model'
import { trashIconSrc, actionIconSrc } from './icons'

interface StudentsFormTableProps {
	courseInfo: ICourse
}
const StudentsFormTable: FC<StudentsFormTableProps> = ({ courseInfo }) => {
	const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] =
		useState(false)

	const [currentDeletedUserId, setCurrentDeletedUserId] = useState<number>()

	const [deleteUserFromCourse] = courseQuery.useDeleteCourseStudentMutation()

	const { data: schoolStudentsList } = userQuery.useGetUsersQuery({
		usersIdList: courseInfo.studentsIdList,
		courseId: courseInfo.id
	})

	const { isTable } = useMatchMedia()

	const clickDeleteHandler = useCallback(() => {
		if (!currentDeletedUserId) {
			return
		}
		deleteUserFromCourse({
			courseId: courseInfo.id,
			studentId: currentDeletedUserId
		})
		setConfirmDeleteModalIsOpen(false)
	}, [currentDeletedUserId])

	const getDropdownItemsList = useCallback(
		(id: ResponseEntityId): DropdownItem[] => [
			{
				id: getUniqueId(),
				icon: trashIconSrc,
				label: 'Удалить',
				onClick: () => {
					setConfirmDeleteModalIsOpen(true)
					setCurrentDeletedUserId(id)
				}
			}
		],
		[]
	)

	const closeModalHandler = () => {
		setConfirmDeleteModalIsOpen(false)
	}

	const usersList = useMemo(() => {
		if (!schoolStudentsList?.data) {
			return []
		}

		return schoolStudentsList.data
	}, [schoolStudentsList])

	// @ts-ignore
	const columns: Column<Record<string, unknown>>[] = useMemo(
		() => [
			{
				Header: 'Фото',
				accessor: 'avatarUrl',
				disableSortBy: true,
				Cell: ({ value, row }: any) => {
					const original = row.original as any
					return (
						<Avatar
							photoUrl={value}
							firstName={original.firstName}
							lastName={original.lastName}
						/>
					)
				}
			},
			{
				Header: 'Имя',
				accessor: 'firstName',
				disableSortBy: true
			},
			{
				Header: 'Фамилия',
				accessor: 'lastName',
				disableSortBy: true
			},
			{
				Header: 'Электроная почта',
				accessor: 'email',
				disableSortBy: true
			},
			{
				Header: 'Дата последней авторизация',
				accessor: 'lastLoginDate',
				disableSortBy: true
			},
			{
				Header: 'Уровень прохождения',
				accessor: 'passingLevel',
				Cell: ({ value }: any) => <>{value}%</>,
				disableSortBy: true
			},
			{
				Header: 'Дата добавления',
				accessor: 'createdDate',
				disableSortBy: true
			},
			{
				Header: '',
				accessor: 'id',
				disableSortBy: true,
				Cell: ({ value: id }: { value: ResponseEntityId }) => (
					<Dropdown dropdownItemsList={getDropdownItemsList(id)}>
						<img src={actionIconSrc} alt="actions" />
					</Dropdown>
				)
			}
		],
		[getDropdownItemsList]
	)
	const data = Object.values(usersList) as unknown as Record<string, unknown>[]

	if (!data || !columns) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			{isTable ? (
				<StudentsFormTableMobile
					getDropdownItemsList={getDropdownItemsList}
					data={data as []}
				/>
			) : (
				<TableDesktop data={data} columns={columns} />
			)}
			<ConfirmDeleteStudentModal
				isOpen={confirmDeleteModalIsOpen}
				userName={(() => {
					const user = usersList.find(({ id }) => id === currentDeletedUserId)
					if (!user) {
						return ''
					}
					return `${user.firstName} ${user.lastName}`
				})()}
				onClose={closeModalHandler}
				onDelete={clickDeleteHandler}
			/>
		</>
	)
}

export default StudentsFormTable
