import React, { FC } from 'react'
import { Button, Modal } from 'UI'
import { ModalHeight } from 'UI/Modal/types'
import { DeleteAnswerModalProps as DeleteAnswerModalContainerProps } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/AnswersList/modals/DeleteAnswer'
import cl from 'containers/Courses/Table/Modals/ConfirmUnPublish/style.module.scss'
import { ButtonStyles } from 'UI/Button/types'

interface DeleteAnswerModalProps extends DeleteAnswerModalContainerProps {}

const DeleteAnswerModal: FC<DeleteAnswerModalProps> = ({
	isOpen,
	onClose,
	remove,
	answerId
}) => (
	<Modal
		isOpen={isOpen}
		onClose={onClose}
		title="Удалить ответ на вопрос?"
		description="Вы действительно хотите удалить ответ на вопрос?"
		styleTypes={[ModalHeight.SMALL]}
	>
		<div className={cl.buttonsContainer}>
			<Button
				onClick={onClose}
				styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
			>
				Отмена
			</Button>
			<Button
				onClick={() => {
					remove(answerId)
					onClose()
				}}
				styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
			>
				Удалить
			</Button>
		</div>
	</Modal>
)

export default DeleteAnswerModal
