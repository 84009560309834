import React, { FC, useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'

import {
	useCheckAuth,
	useCheckOnline,
	useCheckRole,
	usePushNotifications,
	usePreventGoBack
} from 'hooks'
import { Loader, Button } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { useAppSelector } from 'hooks/redux'
import { GuestRoutes, SchoolAdminRoutes } from './routes'

const App: FC = () => {
	useCheckOnline()
	useCheckAuth()
	useCheckRole()
	usePushNotifications()

	const { isAuth, isLoading } = useAppSelector((state) => state.system)
	if (isLoading) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<RouterProvider router={isAuth ? SchoolAdminRoutes() : GuestRoutes()} />
	)
}

export default App
