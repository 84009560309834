import React from 'react'

import {
	TariffItem,
	TariffsInfoContainerProps
} from 'containers/Tariffs/TariffsInfo'

import StorageFillProgress from 'components/StorageFillProgress'
import tariffItemCl from 'components/Tariffs/TariffsInfo/TariffInfoItem/style.module.scss'
import TariffInfoItem from './TariffInfoItem'

import cl from './style.module.scss'

interface TariffsInfoComponentProps
	extends Omit<
		TariffsInfoContainerProps,
		'currentTariff' | 'usersCount' | 'coursesCount' | 'tariffValidTill'
	> {
	infoItems: TariffItem[]
	remainingStorage: number
	tariffFilesCapacity: number
}

const TariffsInfo: React.FC<TariffsInfoComponentProps> = ({
	infoItems,
	tariffFilesCapacity,
	remainingStorage
}) => (
	<div className={cl.info}>
		<h3 className={cl.title}>Информация по вашему тарифу</h3>

		<div className={cl.currentTariffInfo}>
			{infoItems.map((tarrifInfo) =>
				tarrifInfo.hide ? null : (
					<TariffInfoItem key={tarrifInfo.id} {...tarrifInfo} />
				)
			)}
			<div className={tariffItemCl.item}>
				<StorageFillProgress
					remainingStorage={remainingStorage}
					tariffFilesCapacity={tariffFilesCapacity}
					className={cl.storageLeftovers}
				/>
			</div>
		</div>
	</div>
)

export default TariffsInfo
