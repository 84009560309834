import React, { useCallback, useEffect, useMemo, useState } from 'react'

import CreateEventFormComponent from 'components/Courses/Entities/Events/Create/CreateEventForm'
import { FieldPath, useForm, useWatch } from 'react-hook-form'
import {
	DatePassSettings,
	DurationSettings,
	EventTypes,
	IEvent
} from 'types/models/event.model'
import { useActions, useAppSelector, useAppDispatch } from 'hooks/redux'
import { courseQuery, eventsQuery, schoolQuery, userQuery } from 'store/queries'
import { IOption } from 'UI/Select/types'
import {
	ResponseEntityId,
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import { IRadioGroupItem } from 'UI/RadioGroup/types'
import {
	getMillisecondsFromTimeObject,
	getUniqueId,
	dateIsMoreThanToday
} from 'helpers'
import dayjs from 'dayjs'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { StatusType } from 'types/models/course.model'
import { CreateEventResponse } from 'store/queries/events.query/types'
import PublicationSucceededModal from 'containers/Courses/Entities/Events/Create/PublicationSucceededModal'
import CreationCancelledModal from 'containers/Courses/Entities/Events/Create/CreationCancelledModal'
import { TimeZoneSelectOption } from '../Edit'

export interface CreateEventFormValues
	extends Omit<IEvent, 'dateEventStart' | 'duration' | 'timeZone'> {
	dateEventStart: DatePassSettings<string>
	duration: DurationSettings
	timeZone: TimeZoneSelectOption
	courseId: ResponseEntityId
	sectionId: ResponseEntityId
}

export interface CreateEventFormContainerProps {
	sectionId?: string
	courseId?: string
}

const CreateEventForm: React.FC<CreateEventFormContainerProps> = ({
	sectionId,
	courseId
}) => {
	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		watch,
		control,
		formState: { errors }
	} = useForm<CreateEventFormValues>()

	const courseIdFormValue = useWatch({
		control,
		name: 'courseId'
	})

	const dispatch = useAppDispatch()
	const [zoomCallActive, setZoomCallActive] = useState(false)
	const { pushError, pushSuccess } = useActions((state) => state.system)

	const { currentSchoolId, currentRole } = useAppSelector(
		(state) => state.system
	)
	const [publicationSucceededModalOpen, setPublicationSucceededModalOpen] =
		useState(false)
	const [creationCancelledModalOpen, setCreationCancelledModalOpen] =
		useState(false)
	const [publicatedEventId, setPublicatedEventId] = useState<ResponseEntityId>()

	const { data: userInfo } = userQuery.useGetYourselfQuery()

	const { data: schoolData, isLoading } = schoolQuery.useGetSchoolByIdQuery({
		schoolId: Number(currentSchoolId)
	})

	const [
		triggerGetTeachers,
		{ data: teachersInfo, isLoading: teachersLoading }
	] = userQuery.endpoints.getUsers.useLazyQuery()

	const [trigger, { data: coursesInfo, isLoading: coursesLoading }] =
		courseQuery.endpoints.getCourses.useLazyQuery()
	const [triggerGetSections, { data: sectionsInfo }] =
		courseQuery.useLazyGetCourseSectionsQuery()

	const [createEvent, {isLoading: createEventLoading}] = eventsQuery.useCreateEventMutation()

	useEffect(() => {
		const ms = new Date(getValues('dateEventStart.date')).getTime() + 86400000
		const tomorrow = new Date(ms)
		register('capacity')
		register('courseId', {
			validate: (val) => !!val || ValidationErrorMessages.EMPTY
		})
		register('dateEventEnd')
		register('dateEventStart.date', {
			validate: (value) =>
				!(
					getValues('dateEventStart.date') &&
					new Date(value).toLocaleDateString()
				) ||
				dateIsMoreThanToday(tomorrow) ||
				ValidationErrorMessages.DATEPICKER_DATE_CANNOTBE_LESS_THAN_TODAY
		})
		// register('dateEventStart.date', { required: ValidationErrorMessages.EMPTY })
		register('dateEventStart.time', {
			validate: (value) => !!value || ValidationErrorMessages.EMPTY
		})
		register('timeZone', {
			validate: (value) => !!value || ValidationErrorMessages.EMPTY
		})
		register('duration.hours', {
			validate: (val) => !!val || ValidationErrorMessages.EMPTY
		})
		register('duration.minutes', {
			validate: (val) => {
				if (!Number(getValues('duration.hours')) && !Number(val)) {
					return ValidationErrorMessages.EMPTY
				}
				return !!val || ValidationErrorMessages.EMPTY
			}
		})
		register('eventHost', {
			validate: (val) => !!val || ValidationErrorMessages.EMPTY
		})
		register('eventType')
		register('theme', {
			validate: (val) => !!val || !!val?.trim() || ValidationErrorMessages.EMPTY
		})
		register('comments')
		register('link')
		register('address')
		register('courseId', { required: !courseId })
		register('sectionId', { required: !sectionId })
	}, [watch()])

	useEffect(() => {
		setValue('eventType', EventTypes.ONLINE_MEETING)
		setValue('dateEventStart.time', '00:00')
		setValue('courseId', Number(courseId))
		setTimeout(() => {
			setValue('timeZone', {
				value: Intl.DateTimeFormat().resolvedOptions().timeZone,
				abbrev: '',
				altName: '',
				label: '',
				offset: 0
			})
		}, 500)
	}, [courseId])

	useEffect(() => {
		if (!courseIdFormValue || sectionId || !coursesInfo) return

		const foundCourse = coursesInfo.data.find((c) => c.id === courseIdFormValue)

		if (!foundCourse) return

		triggerGetSections({
			courseId: courseIdFormValue,
			sectionsIdList: foundCourse.sectionsIdList
		})
	}, [courseIdFormValue, sectionId, coursesInfo])

	const onSubmit = useCallback(
		handleSubmit(async ({ duration, ...data }) => {
			const inMs = getMillisecondsFromTimeObject({
				hours: duration.hours,
				minutes: duration.minutes || 0
			})

			// на бэк почему-то уходит вчерашнее число, поэтому так
			const dateStart = dayjs(data.dateEventStart.date).set('hour', 0).set('minute', 0).set('second', 0).add(1, 'day')
			const response = await createEvent({
				...data,
				dateEventStart: dateStart as unknown as string,
				timeZone: data.timeZone.value,
				duration: inMs,
				time: data.dateEventStart.time,
				sectionId: Number(sectionId) || data.sectionId,
				courseId: Number(courseId) || data.courseId,
				link:
					data.eventType === EventTypes.ONLINE_MEETING ? data.link : undefined,
				address:
					data.eventType === EventTypes.OFFLINE_MEETING
						? data.address
						: undefined
			})

			const { error } = response as unknown as ResponseWithError

			if (error) {
				pushError(error.data)

				return
			}

			const { data: createEventInfo } = response as unknown as {
				data: CreateEventResponse
			}

			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			dispatch(courseQuery.util.invalidateTags(['course']))

			pushSuccess(successMessage)
			setPublicationSucceededModalOpen(true)
			setPublicatedEventId(Number(createEventInfo.eventId))
		}),
		[watch()]
	)

	const onChange = useCallback(
		(name: FieldPath<CreateEventFormValues>) => (value: any) => {
			setValue(name, value)
		},
		[]
	)

	const onZoomLinkToggle = useCallback(
		() => setZoomCallActive((val) => !val),
		[setZoomCallActive]
	)

	const onCancelCreatingModalOpen = useCallback(() => {
		setCreationCancelledModalOpen(true)
	}, [])

	const onCancelCreatingModalClose = useCallback(() => {
		setCreationCancelledModalOpen(false)
	}, [])

	const onPublicationModalClose = useCallback(() => {
		setPublicationSucceededModalOpen(false)
	}, [])

	useEffect(() => {
		if (!schoolData) return

		trigger({ role: currentRole, coursesIdList: schoolData.data.coursesIdList })
		triggerGetTeachers({ usersIdList: schoolData.data.usersIdList })
	}, [schoolData])

	const eventTypeItems = useMemo<IRadioGroupItem[]>(
		() => [
			{
				id: getUniqueId(),
				labelTitle: 'Онлайн-встреча',
				value: EventTypes.ONLINE_MEETING
			},
			{
				id: getUniqueId(),
				labelTitle: 'Офлайн-встреча',
				value: EventTypes.OFFLINE_MEETING
			}
		],
		[]
	)

	const mappedTeachersItems = useMemo<IOption[]>(() => {
		if (!teachersInfo || !userInfo) return []

		const { id, firstName, lastName } = userInfo.data
		const initials = `${firstName} ${lastName}`

		const getCurrentUserOption = () => ({ text: initials, value: id })
		const dublicatedTeacherRemoved = teachersInfo.data.filter(teacher => teacher.id !== id)

		return [
			getCurrentUserOption(),
			...dublicatedTeacherRemoved.map((t) => ({
				text: `${t.firstName} ${t.lastName}`,
				value: t.id
			}))
		]
	}, [teachersInfo, userInfo])

	const mappedCourses = useMemo(() => {
		if (!coursesInfo) return []

		return coursesInfo.data
			.filter((c) => c.statusType === StatusType.PUBLISHED)
			.map((c) => ({ name: c.name, id: c.id }))
	}, [coursesInfo])

	const selectItems = useMemo<IOption[]>(() => {
		if (!mappedCourses) return []

		return mappedCourses.map((c) => ({
			text: c.name,
			value: c.id
		}))
	}, [mappedCourses])

	const sectionSelectItems = useMemo<IOption[]>(() => {
		if (!sectionsInfo) return []

		return sectionsInfo.data.map((section) => ({
			text: section.header,
			value: section.id
		}))
	}, [sectionsInfo])

	if (isLoading || teachersLoading || coursesLoading) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			<CreateEventFormComponent
				control={control}
				watch={watch}
				errors={errors}
				onSubmit={onSubmit}
				onChange={onChange}
				selectItems={selectItems}
				eventTypeItems={eventTypeItems}
				mappedTeachersItems={mappedTeachersItems}
				onZoomLinkToggle={onZoomLinkToggle}
				zoomCallActive={zoomCallActive}
				onCancelCreating={onCancelCreatingModalOpen}
				needToSelectSection={!sectionId && !courseId}
				sectionSelectItems={sectionSelectItems}
				disableSubmitButton={createEventLoading}
			/>

			<PublicationSucceededModal
				isOpen={publicationSucceededModalOpen}
				onClose={onPublicationModalClose}
				publicatedEventId={publicatedEventId || 0}
			/>

			<CreationCancelledModal
				isOpen={creationCancelledModalOpen}
				onClose={onCancelCreatingModalClose}
			/>
		</>
	)
}

export default CreateEventForm
