import React from 'react'
import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ModalSize } from 'UI/Modal/types'

import cl from './style.module.scss'

interface CancelModalProps {
	onClose: () => void
	onConfirm: () => void
	isOpen: boolean
	confirmText?: string
}

const CancelModal: React.FC<CancelModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	confirmText
}) => (
	<Modal
		styleTypes={[ModalSize.BIG]}
		isOpen={isOpen}
		onClose={onClose}
		title="Внесенные изменения будут потеряны"
		description="Вы действительно хотите отменить внесенные изменения? Все внесенные данные будут потеряны."
	>
		<div className={cl.buttonsContainer}>
			<Button onClick={onClose} styleTypes={[ButtonStyles.DEFAULT_BG]}>
				Отмена
			</Button>

			<Button onClick={onConfirm} styleTypes={[ButtonStyles.DANGER]}>
				{confirmText || 'Отменить изменения'}
			</Button>
		</div>
	</Modal>
)

export default CancelModal
