export enum DaysOfWeek {
	SUNDAY,
	MONDAY,
	TUESDAY,
	WEDNESDAY,
	THURSDAY,
	FRIDAY,
	SATURDAY
}

interface GetNameDayOfTheWeekOptions {
	isShort?: boolean
}

type getNameDayOfTheWeekType = (
	day: number,
	options?: GetNameDayOfTheWeekOptions
) => string

const getNameDayOfTheWeek: getNameDayOfTheWeekType = (
	day,
	{ isShort } = {}
) => {
	switch (day) {
		case DaysOfWeek.SUNDAY:
			return isShort ? 'вс' : 'воскресенье'
		case DaysOfWeek.MONDAY:
			return isShort ? 'пн' : 'понедельник'
		case DaysOfWeek.TUESDAY:
			return isShort ? 'вт' : 'вторник'
		case DaysOfWeek.WEDNESDAY:
			return isShort ? 'ср' : 'среда'
		case DaysOfWeek.THURSDAY:
			return isShort ? 'чт' : 'четверг'
		case DaysOfWeek.FRIDAY:
			return isShort ? 'пт' : 'пятница'
		case DaysOfWeek.SATURDAY:
			return isShort ? 'сб' : 'суббота'
		default:
			throw new Error('no such day is present')
	}
}

export default getNameDayOfTheWeek
