import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState
} from 'react'

import TariffsComponent from 'components/Tariffs'
import PaymentSuccessModal from 'components/Tariffs/PaymentSuccessModal'
import PaymentFailureModal from 'components/Tariffs/PaymentFailureModal'
import { paymentsQuery, schoolQuery, selectsQuery } from 'store/queries'

import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { useActions, useAppSelector } from 'hooks/redux'
import { useSearchParams } from 'react-router-dom'
import { FieldPath, FieldPathValue, useForm } from 'react-hook-form'
import { IOption } from 'UI/Select/types'
import { makeTariffValidityString } from 'helpers/text'
import { GetPaymentStatusResponse } from 'store/queries/payments.query/types'
import { ValidationErrorMessages } from 'types'
import { useDisableScrollManual } from 'hooks/useDisableScrollManual'
import tariffsReducer, {
	ActionTypes,
	tariffsInitialState
} from './tariffs.reducer'

export interface TariffsFormValues {
	currentPeriod: number | string
}

export interface TariffsContainerProps {}

const Tariffs: React.FC<TariffsContainerProps> = () => {
	const { register, setValue, control } = useForm<TariffsFormValues>()
	const [state, dispatch] = useReducer(tariffsReducer, tariffsInitialState)

	const [searchParams] = useSearchParams()
	const { pushError } = useActions((s) => s.system)
	const { currentSchoolId } = useAppSelector((s) => s.system)

	const [triggerGetPaymentStatus] = paymentsQuery.useLazyGetStatusQuery()
	const { data: tariffsInfo, isLoading } = selectsQuery.useGetTariffsQuery()
	const { data: tariffPeriodsInfo, isLoading: tariffPeriodsLoading } =
		selectsQuery.useGetTariffPeriodsQuery()
	const { data: schoolData, isLoading: schoolsLoading } =
		schoolQuery.useGetSchoolByIdQuery({
			schoolId: Number(currentSchoolId)
		})

	const tariffItems = useMemo<IOption[]>(() => {
		if (!tariffPeriodsInfo) return []

		return tariffPeriodsInfo.data.map((t) => {
			const text = makeTariffValidityString(t)

			return {
				text,
				value: t
			}
		})
	}, [tariffPeriodsInfo])

	const getPaymentStatus = async () => {
		const orderId = searchParams.get('orderId')

		if (!orderId) return

		const { data } = (await triggerGetPaymentStatus({
			orderId
		})) as unknown as { data: GetPaymentStatusResponse }

		if (!data.Status) {
			pushError({ message: ValidationErrorMessages.SOMETHING_WENT_WRONG })

			return
		}

		// TODO split into an enum
		if (data.Status === 'CONFIRMED') {
			dispatch({ type: ActionTypes.SET_SUCCESS_MODAL_OPEN })
		}
	}

	useEffect(() => {
		const status = searchParams.get('status')

		if (!status) return

		if (status === 'ok') {
			getPaymentStatus()
		} else if (status === 'error') {
			dispatch({ type: ActionTypes.SET_FAILURE_MODAL_OPEN })
		}
	}, [searchParams])

	useDisableScrollManual(
		state.paymentFailureModalOpen || state.paymentSuccessModalOpen
	)

	const currentTariff = useMemo(() => {
		if (!tariffsInfo || !schoolData) return undefined

		return tariffsInfo.data.find(
			(t) => t.id.toString() === schoolData.data.rateId.toString()
		)
	}, [tariffsInfo, schoolData])

	const usersCount = useMemo(() => {
		if (!schoolData) return 0

		return schoolData.data.usersIdList.length
	}, [schoolData])

	const coursesCount = useMemo(() => {
		if (!schoolData) return 0

		return schoolData.data.coursesIdList.length
	}, [schoolData])

	useEffect(() => {
		register('currentPeriod')
	}, [tariffPeriodsInfo])

	useEffect(() => {
		if (!tariffPeriodsInfo) return

		let currDuration = 1
		const desirable = 3

		if (tariffPeriodsInfo.data.includes(desirable)) {
			currDuration = 3
		}

		setValue('currentPeriod', currDuration)
	}, [tariffPeriodsInfo])

	const onChange = useCallback(
		(name: FieldPath<TariffsFormValues>) =>
			(value: FieldPathValue<TariffsFormValues, typeof name>) => {
				setValue(name, value)
			},
		[]
	)

	const onSuccessModalClose = useCallback(
		() => dispatch({ type: ActionTypes.SET_SUCCESS_MODAL_CLOSED }),
		[]
	)

	const onFailureModalClose = useCallback(
		() => dispatch({ type: ActionTypes.SET_FAILURE_MODAL_CLOSED }),
		[]
	)

	if (
		!tariffsInfo ||
		isLoading ||
		!schoolData ||
		schoolsLoading ||
		tariffPeriodsLoading
	) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			<PaymentSuccessModal
				isOpen={state.paymentSuccessModalOpen}
				onClose={onSuccessModalClose}
			/>
			<PaymentFailureModal
				isOpen={state.paymentFailureModalOpen}
				onClose={onFailureModalClose}
			/>
			<TariffsComponent
				tariffs={tariffsInfo.data}
				activeTariffId={schoolData.data.rateId}
				usersCount={usersCount}
				coursesCount={coursesCount}
				currentTariff={currentTariff}
				tariffValidTill={schoolData?.data.tariffEndDate}
				tariffItems={tariffItems}
				control={control}
				onChange={onChange}
			/>
		</>
	)
}

export default Tariffs
