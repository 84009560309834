import React, { FC, useMemo } from 'react'
import {
	Control,
	FieldPath,
	useFieldArray,
	UseFormGetValues,
	useWatch
} from 'react-hook-form'
import AnswersListComponent from 'components/Courses/Entities/Tests/Preview/QuestionsListPreview/AnswersList'
import { DevTool } from '@hookform/devtools'
import { Question } from 'types/models/test.model'
import { QuestionTabs } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import { TestFormState } from 'pages/Course/Test/Create.page'

export interface AnswersListProps {
	name: `questions.${number}`
	getValues: UseFormGetValues<any>
	control: Control<any>
	onSelect: (tabId: QuestionTabs['id']) => void
	onChange: (name: FieldPath<TestFormState>) => (value: any) => void
}

const AnswersList: FC<AnswersListProps> = ({
	getValues,
	name,
	control,
	onSelect,
	onChange
}) => {
	const { fields } = useFieldArray({
		control,
		name: `${name}.answers`
	})

	const questionsList = useWatch({ control, name: 'questions' }) as Question[]
	const currentQuestion = useWatch({ control, name }) as Question

	const nextButtonIsDisabled = useMemo(
		() =>
			questionsList.findIndex(
				(question) => question.id === currentQuestion.id
			) +
				1 ===
			questionsList.length,
		[questionsList, currentQuestion]
	)

	const nextQuestionHandler = (currentQuestionId: number) => {
		const nextQuestionId =
			questionsList.findIndex((question) => question.id === currentQuestionId) +
			1
		onSelect(nextQuestionId)
	}

	return (
		<>
			<AnswersListComponent
				onChange={onChange}
				nextQuestionHandler={nextQuestionHandler}
				nextButtonIsDisabled={nextButtonIsDisabled}
				control={control}
				fields={fields}
				name={name}
				getValues={getValues}
			/>
			<DevTool control={control} />
		</>
	)
}

export default AnswersList
