import React, { FC } from 'react'
import { AnswerReviewStatus } from 'types/models/task.model'

import classnames from 'classnames'
import cl from './style.module.scss'

interface AttemptStatusProps {
	status: AnswerReviewStatus
	assessment: number
	passingScore: number
}

const AttemptStatus: FC<AttemptStatusProps> = ({
	status,
	assessment,
	passingScore
}) => {
	const statusClassName = classnames(cl.answerStatus, {
		[cl.answerStatusAccess]: status === AnswerReviewStatus.PASSED,
		[cl.answerStatusDecline]: status === AnswerReviewStatus.DECLINE,
		[cl.answerStatusNeedCheck]:
			status === AnswerReviewStatus.NEED_CHECK ||
			status === AnswerReviewStatus.AWAITS_CHECK
	})

	return (
		<div className={statusClassName}>
			{(status === AnswerReviewStatus.NEED_CHECK ||
				status === AnswerReviewStatus.AWAITS_CHECK) && (
				<span>Ожидает проверки</span>
			)}
			{status === AnswerReviewStatus.DECLINE && (
				<span>
					Не принято (оценка {assessment}/{passingScore})
				</span>
			)}
			{status === AnswerReviewStatus.PASSED && (
				<span>
					Принято (оценка {assessment}/{passingScore})
				</span>
			)}
		</div>
	)
}

export default AttemptStatus
