export const downLoadFile = async (documentUrl: string, name: string) => {
    const baseUrl = window.location.origin
    fetch(`${baseUrl}/${documentUrl}`)
        .then(res => res.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            link.download = name
            document.body.appendChild(link)
            link.click()
            link.parentNode?.removeChild(link)
        })
}