import React, { FC, useEffect, useMemo, useState } from 'react'
import { LinkStyles } from 'UI/Link/types'
import { Loader, Modal, Button, CheckboxGroup, Link } from 'UI'
import TestViewComponent from 'components/Courses/Entities/Tests/View'
import { ResponseEntityId } from 'types'
import { CommunicationsSettings } from 'types/models/course.model/settings/communications.model'
import {
	AnswerTestReviewStatus,
	ITest,
	TestTypes
} from 'types/models/test.model'
import { getMillisecondsFromTime } from 'helpers/getMillisecondsFromTime'

import cl from 'components/Courses/Entities/Tests/View/style.module.scss'
import { ITestAnswer } from 'store/queries/test.query/types'
import { useAppSelector } from 'hooks/redux'
import { testQuery } from 'store/queries'
import { ISectionItem } from 'containers/Courses/Tabs/Content/ContentForm/Table'
import { LoaderStyles } from 'UI/Loader/types'
import { ModalSize } from 'UI/Modal/types'
import { useNavigate } from 'react-router-dom'
import { ButtonStyles } from 'UI/Button/types'

export interface TestViewProps {
	test: ITest
	courseName: string
	courseId: ResponseEntityId
	communicationsInfo: CommunicationsSettings
	userAnswersIdList: string[]
	mappedItems: ISectionItem[]
}

const TestView: FC<TestViewProps> = ({
	communicationsInfo,
	test,
	courseId,
	courseName,
	userAnswersIdList,
	mappedItems
}) => {
	const { currentRole } = useAppSelector((state) => state.system)
	const { isFinalTestOngoing } = useAppSelector((state) => state.test)

	const [isQuestionsPassMode, setIsQuestionsPassMode] = useState(false)
	const [isQuestionsCheckMode, setIsQuestionsCheckMode] = useState(false)
	const [isModalOpen, setIsmodalOpen] = useState(false)
	const navigate = useNavigate()

	const passLimitTime = getMillisecondsFromTime(
		test.passingTimeLimit.timeLimitSeconds
	)

	const [userAnswers, setUserAnswers] = useState<ITestAnswer[]>([])

	const { data: userAnswersData, isLoading } = testQuery.useGetUserAnswersQuery(
		{ testId: Number(test.id) },
		{ skip: !test?.id }
	)

	const [testStatus, setTestStatus] = useState<AnswerTestReviewStatus>(
		AnswerTestReviewStatus.NOT_PASS
	)

	useEffect(() => {
		if (!userAnswersData) return

		setUserAnswers(userAnswersData.data)
	}, [userAnswersData])

	const checkStatusTestFromAnswersList = () => {
		const accessStatus = userAnswers.filter(
			(item) => item.answerStatus === AnswerTestReviewStatus.PASS
		)
		const declineStatus = userAnswers.filter(
			(item) => item.answerStatus === AnswerTestReviewStatus.NOT_PASS
		)

		if (accessStatus.length) {
			setTestStatus(AnswerTestReviewStatus.PASS)
			return
		}
		if (declineStatus.length) {
			setTestStatus(AnswerTestReviewStatus.NOT_PASS)
			return
		}
		setTestStatus(AnswerTestReviewStatus.NOT_PASS)
	}

	useEffect(() => {
		checkStatusTestFromAnswersList()
	}, [userAnswers])

	const attemptsIsExhausted = () =>
		userAnswers.length > 0 &&
		test.countAttempts > 0 &&
		userAnswers.length >= test.countAttempts

	const infiniteAttempts = useMemo(
		() => test.countAttempts.toString() === '' || test.countAttempts === 0,
		[test]
	)

	const goBack = () => {
		if (test.type === TestTypes.FINAL && isFinalTestOngoing) {
			setIsmodalOpen(true)
		} else {
			navigate(`/course/view/${courseId}`)
		}
	}

	if (isLoading) return <Loader styleTypes={[LoaderStyles.BIG]} />

	return (
		<div className={cl.viewPage}>
			<button className={cl.backButton} onClick={goBack}>
				Вернуться назад к курсу
			</button>
			<TestViewComponent
				userAnswersIdList={userAnswersIdList}
				courseName={courseName}
				currentRole={currentRole}
				attemptsIsExhausted={attemptsIsExhausted}
				infiniteAttempts={infiniteAttempts}
				testStatus={testStatus}
				userAnswers={userAnswers}
				courseId={courseId}
				isQuestionsPassMode={isQuestionsPassMode}
				setIsQuestionsPassMode={setIsQuestionsPassMode}
				isQuestionsCheckMode={isQuestionsCheckMode}
				setIsQuestionsCheckMode={setIsQuestionsCheckMode}
				passLimitTime={passLimitTime}
				test={test}
				communicationsInfo={communicationsInfo}
				mappedItems={mappedItems}
			/>
			<Modal
				isOpen={isModalOpen}
				onClose={() => setIsmodalOpen(false)}
				title="Вы действительно хотите прервать тестирование?
				Попытка прохождения теста будет засчитана."
				styleTypes={[ModalSize.SMALL]}
			>
				<div className={cl.modalButtonContainer}>
					<Button
						styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
						onClick={() => navigate(`/course/view/${courseId}`)}
					>
						Да
					</Button>
					<Button
						styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
						onClick={() => setIsmodalOpen(false)}
					>
						Нет
					</Button>
				</div>
			</Modal>
		</div>
	)
}

export default TestView
