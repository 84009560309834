export interface IPushService {
	register(payload: RegisterPayload): Promise<void>
	unregister(): Promise<void>
}

interface RegisterPayload extends PushSubscription {}

export enum PushServiceApiRoutes {
	BASE_URL = 'push',
	REGISTER = '/register',
	UNREGISTER = '/unregister'
}
