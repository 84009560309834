import React, { FC } from 'react'

import CreateSectionItemModalComponent from 'components/Courses/Tabs/Content/ContentForm/modals/CreateSectionItem'
import {
	lessonIconSrc,
	eventIconSrc,
	taskIconSrc,
	testIconSrc
} from 'containers/Courses/Tabs/Content/ContentForm/icons'
import { ResponseEntityId } from 'types'

export interface CreateSectionItemModalProps {
	isModalOpen: boolean
	onCloseModal: () => void
	currentCreatingSection?: ResponseEntityId
	courseId: ResponseEntityId
}

export interface SectionItemsCreateList {
	id: number
	name: string
	iconUrl: string
	url: string
}

const CreateSectionItemModal: FC<CreateSectionItemModalProps> = ({
	onCloseModal,
	currentCreatingSection,
	isModalOpen,
	courseId
}) => {
	const sectionItemsCreateList: SectionItemsCreateList[] = [
		{
			id: 1,
			name: 'Урок',
			iconUrl: lessonIconSrc,
			url: `/course/${courseId}/lesson/section/${currentCreatingSection}/create`
		},
		{
			id: 2,
			name: 'Задание',
			iconUrl: taskIconSrc,
			url: `/course/${courseId}/task/section/${currentCreatingSection}/create`
		},
		{
			id: 3,
			name: 'Тестирование',
			iconUrl: testIconSrc,
			url: `/course/${courseId}/test/section/${currentCreatingSection}/create`
		},
		{
			id: 4,
			name: 'Мероприятие',
			iconUrl: eventIconSrc,
			url: `/course/${courseId}/event/section/${currentCreatingSection}/create`
		}
	]
	return (
		<CreateSectionItemModalComponent
			sectionItemsCreateList={sectionItemsCreateList}
			isModalOpen={isModalOpen}
			onCloseModal={onCloseModal}
		/>
	)
}

export default CreateSectionItemModal
