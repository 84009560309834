import React, { FC, useEffect, useState } from 'react'

import {
	ISectionItem,
	SectionItemType
} from 'containers/Courses/Tabs/Content/ContentForm/Table'
import {
	eventIconSrc,
	lessonIconSrc,
	taskIconSrc,
	testIconSrc
} from 'containers/Courses/Tabs/Content/ContentForm/icons'
import { Dropdown, SortPanel } from 'UI'
import { actionIconSrc } from 'containers/Users/Table/images/icons'
import { ResponseEntityId, SortDirections } from 'types'
import { DropdownItem } from 'UI/Dropdown/types'
import courseQuery from 'store/queries/course.query'

import cl from '../style.module.scss'

interface ContentFormTableItemProps {
	sectionItems: ISectionItem[]
	sectionId: ResponseEntityId
	sectionItem: ISectionItem
	sectionItemsArrayLength: ResponseEntityId
	sectionItemsArrayIndex: ResponseEntityId
	getDropdownSectionItemsList: (
		sectionItemId: ResponseEntityId,
		sectionItemType: SectionItemType,
		activity: boolean,
		sectionId: ResponseEntityId,
		sectionItemName: string
	) => DropdownItem[]
}

const ContentFormTableItem: FC<ContentFormTableItemProps> = ({
	sectionItems,
	sectionItem,
	getDropdownSectionItemsList,
	sectionItemsArrayLength,
	sectionItemsArrayIndex,
	sectionId
}) => {
	const [sortSectionItemQuery] = courseQuery.useSortCourseSectionItemsMutation()
	const [sortedSectionItems, setSortedSectionItems] = useState<
		ResponseEntityId[]
	>([])

	useEffect(() => {
		const arr: number[] = []
		sectionItems.map((item) => {
			arr.push(item.id)
		})
		setSortedSectionItems(() => [...arr])
	}, [sectionItems])

	const sortSectionItem = (
		sectionItemId: ResponseEntityId,
		sortDirection: SortDirections
	) => {
		setSortedSectionItems((prev) => {
			const currentSectionItemIndex = prev.findIndex(
				(item) => item === sectionItemId
			)
			const leftPartOfArray = prev.slice(0, currentSectionItemIndex)
			const rightPartOfArray = prev.slice(currentSectionItemIndex, prev.length)

			const leftPartOfArrayLastSymbol =
				leftPartOfArray[leftPartOfArray.length - 1]
			const rightPartOfArrayFirstSymbol = rightPartOfArray[0]

			switch (sortDirection) {
				case SortDirections.TO_TOP:
					if (rightPartOfArrayFirstSymbol) {
						leftPartOfArray[leftPartOfArray.length - 1] =
							rightPartOfArrayFirstSymbol
					}
					if (leftPartOfArrayLastSymbol) {
						rightPartOfArray[0] = leftPartOfArrayLastSymbol
					}

					sortSectionItemQuery({
						sectionId,
						sortItemsIdList: [...leftPartOfArray, ...rightPartOfArray]
					})
					return [...leftPartOfArray, ...rightPartOfArray]
				case SortDirections.TO_BOTTOM:
					leftPartOfArray.push(rightPartOfArrayFirstSymbol)
					rightPartOfArray.shift()
					const rightFirst = rightPartOfArray[0]
					const leftLast = leftPartOfArray[leftPartOfArray.length - 1]

					if (!leftLast || !rightFirst) {
						return [...prev]
					}

					if (leftLast) {
						rightPartOfArray[0] = leftLast
					}
					if (rightFirst) {
						leftPartOfArray[leftPartOfArray.length - 1] = rightFirst
					}

					sortSectionItemQuery({
						sectionId,
						sortItemsIdList: [...leftPartOfArray, ...rightPartOfArray]
					})
					return [...leftPartOfArray, ...rightPartOfArray]
				default:
					return [...prev]
			}
		})
	}

	return (
		<>
			<li className={cl.name}>
				{sectionItem.type === SectionItemType.LESSON && (
					<img src={lessonIconSrc} alt="type-lesson" />
				)}
				{sectionItem.type === SectionItemType.EVENT && (
					<img src={eventIconSrc} alt="type-lesson" />
				)}
				{sectionItem.type === SectionItemType.TEST && (
					<img src={testIconSrc} alt="type-lesson" />
				)}
				{sectionItem.type === SectionItemType.TASK && (
					<img src={taskIconSrc} alt="type-lesson" />
				)}
				<p className={cl.title}>{sectionItem.name}</p>
			</li>
			<li className={cl.dateAdded}>{sectionItem.dateAdded}</li>
			<li className={cl.activity}>{sectionItem.activity ? 'Да' : 'Нет'}</li>
			<li className={cl.dateChanged}>{sectionItem.dateChanged}</li>
			<li className={cl.moreDropdownBtn}>
				<div className={cl.moreInfoBtn}>Подробнее</div>
				<Dropdown
					dropdownItemsList={getDropdownSectionItemsList(
						sectionItem.id,
						sectionItem.type,
						sectionItem.activity,
						sectionId,
						sectionItem.name
					)}
				>
					<img src={actionIconSrc} alt="actions" />
				</Dropdown>
			</li>
			<div className={cl.sortPanel}>
				<SortPanel
					sortingItemId={sectionItem.id}
					onSort={sortSectionItem}
					arrayLength={sectionItemsArrayLength}
					arrayIndex={sectionItemsArrayIndex}
				/>
			</div>
		</>
	)
}
export default ContentFormTableItem
