import { TimetableContentFormValues } from 'containers/Timetable/TimetableContent'
import TimetableNavigation from 'containers/Timetable/TimetableNavigation'
import React from 'react'
import { Control, FieldPath } from 'react-hook-form'
import TimetableTabs from 'containers/Timetable/Tabs'

export interface TimetableContentComponentProps {
	control: Control<TimetableContentFormValues>
	onChange: (
		name: FieldPath<TimetableContentFormValues>
	) => (value: any) => void
}

const TimetableContent: React.FC<TimetableContentComponentProps> = ({
	control,
	onChange
}) => (
	<>
		<TimetableNavigation control={control} onChange={onChange} />
		<TimetableTabs control={control} onChange={onChange} />
	</>
)

export default TimetableContent
