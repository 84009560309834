import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { QuestionTabs } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import AnswersListPassComponent from 'components/Courses/Entities/Tests/View/QuestionsListPass/AnswersList'
import TestPassingSuccessModal from 'components/Courses/Entities/Tests/View/modals/TestPassingSuccess'
import TestPassingFailedModal from 'components/Courses/Entities/Tests/View/modals/TestPassingFailed'
import {
	QuestionListAnswersFormState,
	TestPassFormState
} from 'components/Courses/Entities/Tests/View/QuestionsListPass'
import {
	Question,
	TestTypes,
	TestResponsibilityStatus
} from 'types/models/test.model'
import { FieldPath, useForm, UseFormGetValues } from 'react-hook-form'
import { ResponseEntityId, ResponseWithError } from 'types'
import testQuery from 'store/queries/test.query'
import { useActions, useAppSelector } from 'hooks/redux'
import {
	FinishCreateAnswerResponse,
	ITestAnswer
} from 'store/queries/test.query/types'
import { useParams } from 'react-router-dom'
import { courseQuery } from 'store/queries'
import GetCertificateModal from 'components/Courses/Entities/Certificate/Get/CreateReviewForm/GetCertificateModal'

export interface AnswersListPassProps {
	onSelect: (tabId: QuestionTabs['id']) => void
	question: Question
	testId?: ResponseEntityId
	questionsList: Question[]
	answerAttemptId: ResponseEntityId
	onAppend?: (
		questionId: ResponseEntityId,
		correctAnswersIdList: number | number[]
	) => void
	fields: QuestionListAnswersFormState[]
	setIsQuestionsPassMode: (_: boolean) => void
	getFinalTestFormState: UseFormGetValues<TestPassFormState>
	viewMode?: boolean
	userAnswers?: ITestAnswer[]
	testType?: TestTypes
	timerIsOver?: boolean
	cancelled?: boolean
	onCheckUserAttempt?: (_: number) => void
	userAnswersIdList: string[]
	setIsQuestionsPassingMode: (_: boolean) => void
	countAttempts?: number
	isAnswersRequired?: number
}

export interface QuestionAnswerFormState {
	correctAnswersIdList: number[] | number
	questionId: number
}
const AnswersListPass: FC<AnswersListPassProps> = ({
	onSelect,
	question,
	testId,
	questionsList,
	answerAttemptId,
	onAppend,
	fields,
	setIsQuestionsPassMode,
	getFinalTestFormState,
	viewMode,
	userAnswers,
	testType,
	timerIsOver,
	cancelled,
	onCheckUserAttempt,
	userAnswersIdList,
	setIsQuestionsPassingMode,
	countAttempts,
	isAnswersRequired
}) => {
	const { pushError, testModalPassingSuccessOpen, testModalPassingFailedOpen } =
		useActions((state) => state.system)
	const { currentRole } = useAppSelector((state) => state.system)
	const { currentUserId } = useAppSelector((state) => state.user)
	const { isFinalTestOngoing } = useAppSelector((state) => state.test)
	const [answers, setAnswers] = useState<number | number[]>([])
	const [certificateLink, setCertificateLink] = useState<string>('')
	const [certificateModalOpen, setCertificateModalOpen] = useState(false)

	const answerList = question.answers

	const { courseId } = useParams()

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		getValues,
		control
	} = useForm<QuestionAnswerFormState>()

	const [createAnswer] = testQuery.useCreateAnswerMutation()
	const { haveTestPassingSuccess, haveTestPassingFailed } = useAppSelector(
		(state) => state.system
	)

	const [triggerGetCertificate] = courseQuery.useLazyGetCertificateQuery()
	const { data: courseInfo } = courseQuery.useCourseGetPassingQuery({ userId: currentUserId as number, courseId: Number(courseId) })

	const getCertificateWithoutReview = async (certificateId?: number) => {
		const { data: certificateUrl } = await triggerGetCertificate({ certificateId: certificateId || courseInfo?.data.certificateId as number })
		if (certificateUrl) {
			setCertificateLink(certificateUrl?.data.url)
			setCertificateModalOpen(true)
		}
	}

	const changeHandler = useCallback(
		(name: FieldPath<QuestionAnswerFormState>) =>
			(value: number | number[]) => {
				setValue(name, value)
				setAnswers(value)
			},
		[]
	)

	const disableNextButton = () => {
		if (isAnswersRequired === TestResponsibilityStatus.OPTIONAL) {
			return
		}
		if (Array.isArray(answers) && answers.length === 0) {
			return true
		}
		if (!answers) {
			return true
		}
		return false
	}

	const disableSubmitButton = () => {
		if (isAnswersRequired === TestResponsibilityStatus.OPTIONAL) {
			return
		}
		const asweredQuestions = getFinalTestFormState().questionsPass.length
		const allQuestions = questionsList.length - 1
		return asweredQuestions !== allQuestions
	}

	useEffect(() => {
		const currentQuestion = fields.find(
			(field) => field.questionId === question.id
		)
		setValue('questionId', question.id!)

		if (!currentQuestion) {
			setValue('correctAnswersIdList', [])
			return
		}

		setValue('correctAnswersIdList', currentQuestion.correctAnswersIdList)
	}, [question.id, fields])

	const { data } = courseQuery.useGetCourseQuery(Number(courseId))

	useEffect(() => {
		register('correctAnswersIdList')

		register('questionId')
	}, [])

	useEffect(() => {
		if (viewMode && userAnswers) {
			const foundList = userAnswers
				.find((a) => a.id.toString() === answerAttemptId.toString())
				?.userAnswers?.find(
					(u) => u.questionId === question.id
				)?.correctAnswersIdList

			if (!foundList) return

			setValue('correctAnswersIdList', foundList)
		}
	}, [viewMode, userAnswers, answerAttemptId])

	const lastButtonIsSubmit = useMemo(
		() =>
			questionsList.findIndex(
				(questionItem) => questionItem.id === question.id
			) +
			1 ===
			questionsList.length,
		[questionsList, question]
	)

	const prevButtonIsDisabled = useMemo(() => {
		const currentQuestion = fields.find(
			(field) => field.questionId === question.id
		)

		if (!currentQuestion || timerIsOver) {
			return false
		}
		return true
	}, [question.id, fields])

	const addFieldToArray = (currentQuestionId: number) => {
		const correctAnswersIdList = Array.isArray(
			getValues('correctAnswersIdList')
		)
			? [...(getValues('correctAnswersIdList') as number[])]
			: [getValues('correctAnswersIdList') as number]

		if (!onAppend) return

		onAppend(currentQuestionId, correctAnswersIdList)
	}

	const nextQuestionHandler = (currentQuestionId: number) => {
		addFieldToArray(currentQuestionId)

		const nextQuestionId =
			questionsList.findIndex(
				(questionItem) => questionItem.id === currentQuestionId
			) + 1
		onSelect(nextQuestionId)
	}

	const getAnswerIsCorrect = useCallback(
		(id: number) => {
			if (Array.isArray(question.correctAnswersIdList)) {
				return question.correctAnswersIdList.includes(id)
			}

			return question.correctAnswersIdList === id
		},
		[question.correctAnswersIdList]
	)

	const submitCallback = useCallback(() => {
		if (!testId) {
			return
		}

		const userAnswersFinalFormState = getFinalTestFormState('questionsPass')

		if (!userAnswersFinalFormState.length) {
			setIsQuestionsPassMode(false)

			return
		}

		; (async () => {
			const response = await createAnswer({
				userAnswersAttemptId: answerAttemptId,
				testId,
				userAnswers: userAnswersFinalFormState
			})

			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}

			const { data: resp } = response as unknown as {
				data: FinishCreateAnswerResponse
			}

			if (cancelled) {
				setIsQuestionsPassMode(false)
				return
			}

			if (resp.isSuccessPassTest && onCheckUserAttempt) {
				testModalPassingSuccessOpen()
				return
			}

			testModalPassingFailedOpen()
		})()
	}, [testId, answerAttemptId, getFinalTestFormState, cancelled])

	const submitHandler = useCallback(handleSubmit(submitCallback), [
		submitCallback
	])

	const onSeeResults = useCallback(() => {
		if (!onCheckUserAttempt || !userAnswersIdList) return

		setIsQuestionsPassMode(false)
		onCheckUserAttempt(answerAttemptId)
	}, [userAnswersIdList, answerAttemptId])

	const onCloseFailed = useCallback(() => {
		setIsQuestionsPassMode(false)
	}, [setIsQuestionsPassMode])

	useEffect(() => {
		if (!cancelled) return

		submitHandler()
	}, [cancelled])

	return (
		<>
			<AnswersListPassComponent
				addFieldToArray={addFieldToArray}
				prevButtonIsDisabled={prevButtonIsDisabled}
				lastButtonIsSubmit={lastButtonIsSubmit}
				onNext={nextQuestionHandler}
				errors={errors}
				onSubmit={submitHandler}
				testId={testId}
				control={control}
				onChange={changeHandler}
				answersList={answerList}
				question={question}
				viewMode={viewMode}
				testType={testType}
				setIsQuestionsPassMode={setIsQuestionsPassMode}
				getAnswerIsCorrect={getAnswerIsCorrect}
				currentRole={currentRole}
				timerIsOver={timerIsOver}
				userAnswersIdList={userAnswersIdList}
				setIsQuestionsPassingMode={setIsQuestionsPassingMode}
				countAttempts={countAttempts}
				disableNextButton={disableNextButton()}
				disableSubmitButton={disableSubmitButton()}
				isFinalTestOngoing={isFinalTestOngoing}
			/>
			<TestPassingSuccessModal
				isOpen={haveTestPassingSuccess}
				onSeeResults={onSeeResults}
				courseId={courseId!}
				type={testType}
				reviewRequired={data && data?.data.reviewResponsibilityStatus}
				getCertificateWithoutReview={getCertificateWithoutReview}
				hasCertificate={!!courseInfo?.data.certificateId}
			/>
			<TestPassingFailedModal
				isOpen={haveTestPassingFailed}
				onClose={onCloseFailed}
			/>
			<GetCertificateModal
				certificateUrl={certificateLink}
				title='Ваш сертификат готов!'
				isOpen={certificateModalOpen}
				onClose={() => setCertificateModalOpen(false)}
			/>
		</>
	)
}

export default AnswersListPass
