import React, { FC, useCallback, useEffect, useState } from 'react'

import DeleteSectionItemModalComponent from 'components/Courses/Tabs/Content/ContentForm/modals/DeleteSectionItem'
import { SectionItemType } from 'containers/Courses/Tabs/Content/ContentForm/Table'
import {
	courseQuery,
	eventsQuery,
	lessonQuery,
	taskQuery,
	testQuery
} from 'store/queries'
import { ResponseEntityId, ResponseWithError } from 'types'
import { useAppDispatch } from 'hooks/redux'

export interface DeleteSectionItemModalProps {
	isModalOpen: boolean
	currentSectionItemName: string
	currentSectionItemType?: SectionItemType
	sectionItemId: ResponseEntityId
	sectionId: ResponseEntityId
	onClose: () => void
}

const DeleteSectionItemModal: FC<DeleteSectionItemModalProps> = ({
	currentSectionItemName,
	currentSectionItemType,
	isModalOpen,
	sectionItemId,
	sectionId,
	onClose
}) => {
	const dispatch = useAppDispatch()

	const [modalHeader, setModalHeader] = useState('')
	const [modalDescription, setModalDescription] = useState('')

	const [deleteTask] = taskQuery.useDeleteTaskMutation()
	const [deleteLesson] = lessonQuery.useDeleteLessonMutation()
	const [deleteTest] = testQuery.useDeleteTestMutation()
	const [deleteEvent] = eventsQuery.useDeleteEventMutation()

	useEffect(() => {
		switch (currentSectionItemType) {
			case SectionItemType.TASK:
				setModalHeader('Удаление задания')
				setModalDescription(
					`Вы действительно хотите удалить задание "${currentSectionItemName}"?`
				)
				break
			case SectionItemType.TEST:
				setModalHeader('Удаление тестирования')
				setModalDescription(
					`Вы действительно хотите удалить тестирование "${currentSectionItemName}"?`
				)
				break
			case SectionItemType.LESSON:
				setModalHeader('Удаление урока')
				setModalDescription(
					`Вы действительно хотите удалить урок "${currentSectionItemName}"?`
				)
				break
			case SectionItemType.EVENT:
				setModalHeader('Удаление мероприятия')
				setModalDescription(
					`Вы действительно хотите удалить мероприятие "${currentSectionItemName}"?`
				)
				break
			default:
				break
		}
	}, [currentSectionItemType])

	const deleteSectionItem = useCallback(async () => {
		switch (currentSectionItemType) {
			case SectionItemType.TASK:
				const responseDeleteTask = await deleteTask({
					sectionId,
					taskId: sectionItemId
				})
				const { error: errorTaskDelete } =
					responseDeleteTask as unknown as ResponseWithError
				if (errorTaskDelete) {
					return
				}
				dispatch(courseQuery.util.invalidateTags(['course']))
				onClose()
				break
			case SectionItemType.TEST:
				const responseDeleteTest = await deleteTest({
					sectionId,
					testId: sectionItemId
				})
				const { error: errorDeleteTest } =
					responseDeleteTest as unknown as ResponseWithError
				if (errorDeleteTest) {
					return
				}
				dispatch(courseQuery.util.invalidateTags(['course']))
				onClose()
				break
			case SectionItemType.LESSON:
				const responseDeleteLesson = await deleteLesson({
					sectionId,
					lessonId: sectionItemId
				})
				const { error: errorDeleteLesson } =
					responseDeleteLesson as unknown as ResponseWithError
				if (errorDeleteLesson) {
					return
				}
				dispatch(courseQuery.util.invalidateTags(['course']))
				onClose()
				break
			case SectionItemType.EVENT:
				await deleteEvent({
					sectionId,
					eventId: sectionItemId
				})
				dispatch(courseQuery.util.invalidateTags(['course']))
				onClose()
				break
			default:
				break
		}
	}, [currentSectionItemType, sectionItemId])

	return (
		<DeleteSectionItemModalComponent
			onClose={onClose}
			modalDescription={modalDescription}
			modalHeader={modalHeader}
			isModalOpen={isModalOpen}
			deleteSectionItem={deleteSectionItem}
		/>
	)
}
export default DeleteSectionItemModal
