import React from 'react'

import cl from 'pages/style.module.scss'
import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import TimetableContent from 'containers/Timetable/TimetableContent'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'hooks/redux'
import { CourseRole, SchoolRole } from 'types'

const PlusIcon: React.FC = () => (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M14 6H8V0H6V6H0V8H6V14H8V8H14V6Z" fill="white" />
	</svg>
)

const TimetablePage = () => {
	const { currentRole } = useAppSelector((state) => state.system)
	const navigate = useNavigate()

	const onCreateEvent = () => {
		navigate('/course/event/create')
	}

	return (
		<div className={cl.timetable}>
			<div className={cl.container}>
				<div className={cl.header}>
					<h2 className={cl.title}>Расписание</h2>
					{(currentRole === SchoolRole.ADMIN ||
						currentRole === CourseRole.TEACHER) && (
						<Button styleTypes={[ButtonStyles.PRIMARY]} onClick={onCreateEvent}>
							<PlusIcon />
							<span>Добавить мероприятие</span>
						</Button>
					)}
				</div>
			</div>
			<div className={cl.container}>
				<TimetableContent />
			</div>
		</div>
	)
}

export default TimetablePage
