import React, { FC } from 'react'

import { Button, Modal } from 'UI'
import { ResponseEntityId, ResponseWithError } from 'types'
import { ModalHeight } from 'UI/Modal/types'
import { ButtonStyles } from 'UI/Button/types'
import { courseQuery } from 'store/queries'

import cl from './style.module.scss'

interface ConfirmPublishModalProps {
	onClose: () => void
	isOpen: boolean
	courseTitle: string
	courseId: ResponseEntityId
	setIsPublishModalOpen: (_: boolean) => void
}

const ConfirmPublishModal: FC<ConfirmPublishModalProps> = ({
	isOpen,
	onClose,
	courseTitle,
	courseId,
	setIsPublishModalOpen
}) => {
	const [setCoursePublish] = courseQuery.useCoursesPublishMutation()
	// const [dateIsActually, setDateIsActually] = useState(true)

	// in the future refactor all the comments

	const coursePublishQuery = async (id: ResponseEntityId) => {
		const response = await setCoursePublish(id)
		const { error } = response as unknown as ResponseWithError
		if (error) {
			return
		}
		onClose()
		setIsPublishModalOpen(true)
	}
	// useEffect(() => {
	// 	if (!currentCourseDateStart) {
	// 		return
	// 	}
	// 	setDateIsActually(+new Date() < +new Date(currentCourseDateStart))
	// }, [currentCourseDateStart])

	return (
		<Modal
			styleTypes={[ModalHeight.SMALL]}
			isOpen={isOpen}
			// title={dateIsActually ? 'Публикация курса' : 'Проверьте настройки курса'}
			title="Публикация курса"
			// description={
			// 	dateIsActually
			// 		? `Подтвердите публикацию курса "${courseTitle}"`
			// 		: 'Указанная дата начала курса неактуальна или дата начала курса не указана, проверьте корекктность введенных данных'
			// }
			description={`Подтвердите публикацию курса "${courseTitle}"`}
			onClose={onClose}
		>
			<div className={cl.buttonsContainer}>
				<Button
					onClick={onClose}
					styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
				>
					Отмена
				</Button>
				<Button
					// onClick={
					// 	dateIsActually
					// 		? () => coursePublishQuery(courseId)
					// 		: () => navigate(`/course/edit/${courseId}`)
					// }
					onClick={() => coursePublishQuery(courseId)}
					styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
				>
					{/* {dateIsActually ? 'Опубликовать' : 'Редактировать курс'} */}
					Опубликовать
				</Button>
			</div>
		</Modal>
	)
}

export default ConfirmPublishModal
