import { EntityId } from '@reduxjs/toolkit'

export enum PaymentsPaths {
	BASE_URL = 'payments',
	SWITCH_PLAN = 'rate',
	GET_PAYMENT_STATUS = 'get-status'
}

export interface SwitchTariffResponse {
	url: string
}

export interface SwitchTariffsPayload {
	tariffId: EntityId
	validityPeriod: number
	price: number
	deferred?: boolean
}

export interface GetPaymentStatusPayload {
	orderId: EntityId
}

export interface GetPaymentStatusResponse {
	Status: string
}
