import { EntityId } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { buildHeaders } from 'helpers/api'
import { Constants } from 'types'
import {
	ChangeEventPayload,
	CreateEventPayload,
	CreateEventResponse,
	DeleteEventPayload,
	EventsPaths,
	GetEventByIdResponse,
	GetEventsForSchoolPayload,
	GetEventsForSchoolResponse
} from './types'

const eventsQuery = createApi({
	reducerPath: 'eventsQuery',
	baseQuery: fetchBaseQuery({
		baseUrl: `${Constants.API_BASE_URL}/${EventsPaths.BASE_URL}`
	}),
	tagTypes: ['events'],
	endpoints: (build) => ({
		createEvent: build.mutation<CreateEventResponse, CreateEventPayload>({
			query: ({ sectionId, ...body }) => ({
				url: `${EventsPaths.CREATE_EVENT}${sectionId}`,
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['events']
		}),
		changeEvent: build.mutation<void, ChangeEventPayload>({
			query: ({ eventId, ...body }) => ({
				url: `${eventId}/${EventsPaths.CHANGE_EVENT}`,
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['events']
		}),
		getEventById: build.query<GetEventByIdResponse, EntityId>({
			query: (eventId) => ({
				url: eventId.toString(),
				params: { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
				method: 'GET',
				headers: buildHeaders({ needAuth: true })
			}),
			providesTags: ['events']
		}),
		getEventsForSchool: build.query<
			GetEventsForSchoolResponse,
			GetEventsForSchoolPayload
		>({
			query: ({ schoolId, ...params }) => ({
				url: `${EventsPaths.GET_EVENTS_FOR_SCHOOL}`,
				method: 'GET',
				params: {
					...params,
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
				},
				headers: buildHeaders({ needAuth: true })
			}),
			providesTags: ['events']
		}),
		deleteEvent: build.mutation<void, DeleteEventPayload>({
			query: ({ eventId, sectionId }) => ({
				url: `${eventId}/${EventsPaths.DELETE_EVENT}${sectionId}`,
				method: 'DELETE',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['events']
		})
	})
})

export default eventsQuery
