import React, { useMemo } from 'react'

import infinityIcon from 'components/Courses/Entities/Tests/View/images/infinity-icon.svg'
import cl from '../style.module.scss'

interface AttemptsNumberProps {
	currentAnswerCount: number | null
	allowedCount?: number
}

const AttemptsNumber: React.FC<AttemptsNumberProps> = ({
	allowedCount,
	currentAnswerCount
}) => {
	const attemptsExhausted = useMemo(
		() =>
			currentAnswerCount && allowedCount && allowedCount <= currentAnswerCount,
		[allowedCount, currentAnswerCount]
	)

	return (
		<>
			{attemptsExhausted ? (
				<span className={cl.error}>У вас закончились попытки</span>
			) : (
				<>
					<span>Попытка</span>
					<div className={cl.attempt}>
						{currentAnswerCount ? currentAnswerCount + 1 : 1}
					</div>
					<span>из</span>
					<div className={cl.attempt}>
						{!allowedCount && <img src={infinityIcon} alt="Infinity icon" />}
						{allowedCount}
					</div>
				</>
			)}
		</>
	)
}

export default AttemptsNumber
