import React, {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'

import { getUniqueId } from 'helpers'
import {
	clearFileInput,
	getFileList,
	parseIdFromFileName,
	validateFiles,
	filterFileListInfoByFileName
} from 'helpers/files'

import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import plusIconSrc from 'UI/Button/images/plus-black-quarternary.svg'
import { TaskAnswerDoc } from 'types/models/task.model'
import { FileInputMultipleProps } from '../types'
import deleteIcon from '../images/delete.svg'

import cl from '../style.module.scss'

const DragAndDropMultiple: FC<FileInputMultipleProps> = ({
	name,
	files,
	accept,
	onChange,
	size,
	id,
	previewFile,
	disabled,
	clearFiles,
	...defaultProps
}) => {
	const [filesPreview, setFilesPreview] = useState<TaskAnswerDoc[]>()
	const [filesData, setFilesData] = useState<File[]>([])
	const uniqueId = useMemo(getUniqueId.bind(null, id), [])
	const fileInput = useRef<HTMLInputElement>(null)

	useEffect(() => {
		setFilesPreview(previewFile)
	}, [previewFile])

	useEffect(() => {
		onChange(filesData)
	}, [filesData])

	const changeHandler = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			if (!event.target.files?.length) {
				return
			}

			const { typesIsValid, sizeIsValid } = validateFiles({
				files: [...event.target.files],
				accept,
				size
			})

			if (!typesIsValid || !sizeIsValid) {
				clearFileInput(event.target)
				onChange([])

				return
			}

			const fileList = await getFileList([...event.target.files])

			if (!fileList.length) {
				clearFileInput(event.target)
				onChange([])

				return
			}

			if (previewFile && previewFile.length > 0) {
				setFilesData(prev => [...prev, ...fileList])
			} else {
				setFilesData([...fileList])
			}

			event.target.files = fileList
		},
		[previewFile]
	)
	const deleteHandler = useCallback(
		async (fileName: string) => {
			if (!fileInput.current || !filesPreview?.length) {
				return
			}
			const filteredFileList = await filterFileListInfoByFileName(
				filesPreview,
				fileName
			)
			fileInput.current.files = filteredFileList
			onChange([...filteredFileList])
		},
		[files, fileInput.current, filesPreview]
	)

	return (
		<div className={cl.fileInputWrapper}>
			<div {...defaultProps} className={cl.fileInput}>
				<input
					name={name}
					ref={fileInput}
					accept={accept}
					onChange={changeHandler}
					id={uniqueId}
					disabled={disabled}
					type="file"
					multiple
				/>
				<Button
					className={cl.addFileBtn}
					styleTypes={[ButtonStyles.OUTLINE_QUATERNARY, ButtonStyles.ROUND]}
					disabled={disabled}
				>
					<img src={plusIconSrc} alt="plus" />
					<label htmlFor={uniqueId}>Прикрепить файл</label>
				</Button>
			</div>
			{filesPreview && (
				<div className={cl.fileInputMultiplePreview}>
					{filesPreview.map((file) => (
						<div className={cl.uploadFile} key={file.title}>
							<span>{file.title}</span>
							<Button onClick={() => deleteHandler(file.title)}>
								<img src={deleteIcon} alt="delete" />
							</Button>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default DragAndDropMultiple
