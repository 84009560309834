import React, { FC } from 'react'
import { ResponseEntityId, SortDirections } from 'types'
import { SortToBottomArrow, SortToTopArrow } from 'UI/SortPanel/icons'
import classNames from 'classnames'

import cl from './style.module.scss'

interface SortPanelProps {
	sortingItemId: ResponseEntityId
	arrayLength: ResponseEntityId
	arrayIndex: ResponseEntityId
	onSort: (id: ResponseEntityId, sortDirection: SortDirections) => void
	className?: string
}

const SortPanel: FC<SortPanelProps> = ({
	arrayIndex,
	arrayLength,
	onSort,
	sortingItemId,
	className
}) => (
	<div className={classNames(cl.container, className)}>
		{arrayLength > 1 && (
			<div>
				{arrayLength - arrayLength === arrayIndex && (
					<div onClick={() => onSort(sortingItemId, SortDirections.TO_BOTTOM)}>
						<SortToBottomArrow />
					</div>
				)}
				{arrayLength - arrayLength !== arrayIndex &&
					!(arrayLength - 1 === arrayIndex) && (
						<div className={cl.arrows}>
							<div onClick={() => onSort(sortingItemId, SortDirections.TO_TOP)}>
								<SortToTopArrow />
							</div>
							<div
								onClick={() => onSort(sortingItemId, SortDirections.TO_BOTTOM)}
							>
								<SortToBottomArrow />
							</div>
						</div>
					)}
				{arrayLength - 1 === arrayIndex && (
					<div onClick={() => onSort(sortingItemId, SortDirections.TO_TOP)}>
						<SortToTopArrow />
					</div>
				)}
			</div>
		)}
	</div>
)

export default SortPanel
