import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { buildHeaders } from 'helpers/api'
import { Constants } from 'types'
import {
	GetPaymentStatusPayload,
	GetPaymentStatusResponse,
	PaymentsPaths,
	SwitchTariffResponse,
	SwitchTariffsPayload
} from './types'

const paymentsQuery = createApi({
	reducerPath: 'paymentsQuery',
	baseQuery: fetchBaseQuery({
		baseUrl: `${Constants.API_BASE_URL}/${PaymentsPaths.BASE_URL}`
	}),
	tagTypes: ['payments'],
	endpoints: (build) => ({
		getTariffs: build.mutation<SwitchTariffResponse, SwitchTariffsPayload>({
			query: ({ tariffId, ...body }) => ({
				url: `${PaymentsPaths.SWITCH_PLAN}/${tariffId}`,
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			})
		}),
		getStatus: build.query<GetPaymentStatusResponse, GetPaymentStatusPayload>({
			query: ({ orderId }) => ({
				url: `${PaymentsPaths.GET_PAYMENT_STATUS}/${orderId}`,
				method: 'GET',
				headers: buildHeaders({ needAuth: true })
			})
		})
	})
})

export default paymentsQuery
