import React, { FC, useEffect, useMemo, useState } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch
} from 'react-hook-form'
import classnames from 'classnames'
import { DevTool } from '@hookform/devtools'
import { GetDataForKinescopeResponse } from 'store/queries/lesson.query/types'

import { decodeHtmlString, getUniqueId } from 'helpers'
import { Tabs } from 'UI'
import { ITab } from 'UI/Tabs/types'
import { LessonFormState } from 'pages/Course/Lesson/Create.page'
import { ILesson, LessonMediaFiles } from 'types/models/lesson.model'
import MainFormTab, { MainFormTabState } from '../Content/MainForm'
import LimitationsFormTab, {
	LimitationsFormTabState
} from '../Content/LimitationsForm'

import cl from '../style.module.scss'

interface LessonsTabsProps {
	isPreview?: boolean
	errors: FieldErrors<LessonFormState>
	onChange: (name: FieldPath<LessonFormState>) => (value: any, deleteFileName?: string) => void
	control: Control<LessonFormState>
	register: UseFormRegister<LessonFormState>
	handleSubmit: UseFormHandleSubmit<LessonFormState>
	watch: UseFormWatch<LessonFormState>
	setValue: UseFormSetValue<LessonFormState>
	getValues: UseFormGetValues<LessonFormState>
	lessonInfo: ILesson
	onOpenCanceledChange: () => void
	kinescopeData?: GetDataForKinescopeResponse
}

const LessonsTabs: FC<LessonsTabsProps> = ({
	isPreview = false,
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	watch,
	setValue,
	getValues,
	lessonInfo,
	onOpenCanceledChange,
	kinescopeData
}) => {
	const tabsList = useMemo(
		(): ITab[] => [
			{ id: getUniqueId(), text: 'Основная информация' },
			{ id: getUniqueId(), text: 'Ограничения' }
		],
		[]
	)

	useEffect(() => {
		setValue('id', lessonInfo.id)
		setValue('name', lessonInfo.name)
		setValue('description', lessonInfo.description)
		setValue(
			'lessonResponsibilityStatus',
			lessonInfo.lessonResponsibilityStatus
		)
		setValue('docsFilesInfo', lessonInfo.docsFilesInfo)
		setValue('mediaFilesUrlList', lessonInfo.mediaFilesUrlList)
		setValue(
			'docsFilesUrlList',
			lessonInfo.docsFilesUrlList
				? lessonInfo.docsFilesUrlList.map((d) => ({
					...d,
					name: decodeHtmlString(d.name),
					url: decodeHtmlString(d.url)
				}))
				: []
		)
		setValue(
			'mediaFilesInfo',
			lessonInfo.mediaFilesInfo && lessonInfo.mediaFilesInfo.length
				? lessonInfo.mediaFilesInfo
				: undefined
		)
		setValue('videoTitle', lessonInfo.videoTitle)
		setValue('videoEmbedLink', lessonInfo.videoEmbedLink)
		setValue('videoId', lessonInfo.videoId)
	}, [lessonInfo, kinescopeData])

	useEffect(() => {
		if (!lessonInfo.videoEmbedLink || !lessonInfo.videoId) return

		setValue('mediaFilesInfo', [
			{ url: '', title: lessonInfo.videoTitle, type: LessonMediaFiles.VIDEO }
		])
	}, [lessonInfo, kinescopeData])

	const [selectedTabId, setSelectedTabId] = useState<ITab['id']>(tabsList[0].id)

	return (
		// TODO refactor
		<div className={cl.container}>
			<div
				style={{ display: isPreview ? 'none' : 'block' }}
				className={classnames([
					cl.tabsToggles,
					{
						[cl.tabsTogglesHidden]: isPreview
					}
				])}
			>
				<Tabs
					value={selectedTabId}
					tabs={tabsList}
					onChange={setSelectedTabId}
				/>
			</div>
			<div
				style={{ display: isPreview ? 'none' : 'block' }}
				className={classnames([
					cl.tabsContent,
					{
						[cl.tabsContentHidden]: isPreview
					}
				])}
			>
				{selectedTabId === tabsList[0].id && (
					<MainFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						setValue={setValue as unknown as UseFormSetValue<MainFormTabState>}
						register={register as unknown as UseFormRegister<MainFormTabState>}
						control={control as unknown as Control<MainFormTabState>}
						getValues={
							getValues as unknown as UseFormGetValues<MainFormTabState>
						}
						onChange={onChange}
						errors={errors}
						isEditMode
						handleSubmit={handleSubmit}
						watch={watch as unknown as UseFormWatch<MainFormTabState>}
					/>
				)}
				{selectedTabId === tabsList[1].id && (
					<LimitationsFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						register={
							register as unknown as UseFormRegister<LimitationsFormTabState>
						}
						getValues={
							getValues as unknown as UseFormGetValues<LimitationsFormTabState>
						}
						control={control as unknown as Control<LimitationsFormTabState>}
						onChange={onChange}
						errors={errors}
						handleSubmit={handleSubmit}
					/>
				)}
				<DevTool control={control} />
			</div>
		</div>
	)
}

export default LessonsTabs
