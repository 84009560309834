import React, { useCallback, useState } from 'react'
import { FieldPath, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Link } from 'UI'
import { LinkStyles } from 'UI/Link/types'
import TestsTabs from 'containers/Courses/Entities/Tests/Tabs/Create/Wrapper.container'
import { ITest } from 'types/models/test.model'
import CancelChangeModal from 'pages/Course/modals/CancelChangeModal'

import cl from '../style.module.scss'

export interface TestFormState extends ITest { }

const TestCreatePage = () => {
	const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)

	const { courseId } = useParams()

	const { register, control, formState, setValue, handleSubmit, getValues } =
		useForm<TestFormState>()

	const changeHandler = useCallback(
		(name: FieldPath<TestFormState>) => (value: any) => setValue(name, value),
		[]
	)
	return (
		<>
			<CancelChangeModal
				isOpen={cancelModalIsOpen}
				onClose={() => setCancelModalIsOpen(false)}
				redirectUri={`/course/edit/${courseId}`}
			/>
			<div className={cl.container}>
				<div className={cl.back}>
					<Link
						styleTypes={[LinkStyles.GO_BACK]}
						href={`/course/edit/${courseId}`}
					>
						Вернуться назад к курсу
					</Link>
				</div>
				<div className={cl.header}>
					<div className={cl.headerLessonInfo}>
						<h2>Новое тестирование</h2>
					</div>
				</div>
				<TestsTabs
					onOpenCanceledChange={() => setCancelModalIsOpen(true)}
					getValues={getValues}
					errors={formState.errors}
					control={control}
					onChange={changeHandler}
					register={register}
					handleSubmit={handleSubmit}
				/>
			</div>
		</>
	)
}

export default TestCreatePage
