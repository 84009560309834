import classNames from 'classnames'
import { MonthNavigationContainerProps } from 'containers/Timetable/TimetableNavigation/MonthNavigation'
import React from 'react'
import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'

import DatePicker, { DatePickerTypes } from 'UI/DatePicker'
import { Control } from 'react-hook-form'
import { TimetableContentFormValues } from 'containers/Timetable/TimetableContent'
import cl from './style.module.scss'

interface MonthNavigationComponentProps
	extends Omit<MonthNavigationContainerProps, 'onChange' | 'control'> {
	date: string
	onDateIncrement: () => void
	onDateDecrement: () => void
	control: Control<TimetableContentFormValues>
	currentDatePicker?: DatePickerTypes
	onMonthPopupOpen: () => void
	onMonthPopupClose: () => void
	datePopupOpen: boolean
}

const MonthNavigation: React.FC<MonthNavigationComponentProps> = ({
	date,
	onDateIncrement,
	onDateDecrement,
	control,
	currentDatePicker,
	onMonthPopupOpen,
	onMonthPopupClose,
	datePopupOpen
}) => (
	<>
		<Button
			styleTypes={[ButtonStyles.PRIMARY_HOLLOW, ButtonStyles.ROUND]}
			className={classNames(cl.button, cl.left)}
			onClick={onDateDecrement}
		/>
		<Button
			styleTypes={[ButtonStyles.PRIMARY_HOLLOW, ButtonStyles.ROUND]}
			className={classNames(cl.button, cl.right)}
			onClick={onDateIncrement}
		/>
		<div
			className={classNames(cl.date, {
				[cl.open]: datePopupOpen
			})}
		>
			<span onClick={datePopupOpen ? onMonthPopupClose : onMonthPopupOpen}>
				{date}
			</span>
			<DatePicker
				control={control}
				name="currentDayjsDate"
				picker={currentDatePicker}
				closePopup={onMonthPopupClose}
				open={datePopupOpen}
				className={cl.picker}
			/>
		</div>
	</>
)

export default MonthNavigation
