import React, { FC, ReactNode, useCallback, useMemo } from 'react'
import MonthTableComponent from 'components/Timetable/Tabs/MonthTab/MonthTable'
import { IEvent } from 'types/models/event.model'
import {
	getFirstAndLastDayOfTheMonth,
	getFirstAndLastDaysOfTheWeek
} from 'helpers/time'
import {
	IDayEvent,
	WeekDay
} from 'types/models/day-event.model/day-event.model'
import { getUniqueId } from 'helpers'
import dayjs from 'dayjs'
import { TableCellsInfo } from 'containers/Timetable/Tabs/WeekTab'
import WeekTableCell from 'containers/Timetable/Tabs/WeekTab/WeekTableCell'
import { DaysOfWeek } from 'helpers/time/getNameDayOfTheWeek'

export interface MonthTableContainerProps {
	events: IEvent[]
	currentDate: Date
	onDetailsOpen: (_: IEvent) => void
}

export interface MonthTableCellsInfo extends TableCellsInfo {
	date: Date
}

const MonthTable: FC<MonthTableContainerProps> = ({
	events,
	currentDate,
	onDetailsOpen
}) => {
	const { lastDayDate, firstDayDate } = useMemo(
		() => getFirstAndLastDayOfTheMonth(currentDate),
		[currentDate]
	)

	const mappedDaysEvents = useMemo<IDayEvent[]>(() => {
		if (!events) return []

		return events
		.filter(
			(e) =>
					(dayjs(e.dateEventStart).isAfter(firstDayDate)  || dayjs(e.dateEventStart).startOf('month').isSame(dayjs(firstDayDate).startOf('month'))) &&
					dayjs(e.dateEventStart).isBefore(lastDayDate)
					
			)
			.map((e) => ({
				id: getUniqueId(),
				date: new Date(e.dateEventStart),
				value: e
			}))
	}, [dayjs, events])

	const mappedWeekDays = useMemo<WeekDay[]>(() => {
		let days: WeekDay[] = []
		const { firstDayDate: firstDate, lastDayDate: lastDate } =
			getFirstAndLastDaysOfTheWeek(firstDayDate)
		const iterateDate = new Date(+firstDate)

		do {
			days = [...days, { id: getUniqueId(), date: new Date(+iterateDate) }]

			iterateDate.setDate(iterateDate.getDate() + 1)
		} while (iterateDate < lastDate)

		return days
	}, [mappedDaysEvents, firstDayDate])

	const getEventsByDays = useCallback(
		(eventDate: Date) =>
			mappedDaysEvents
				.filter((e) => e.date.getDate() === eventDate.getDate())
				.map((e) => e.value),
		[mappedDaysEvents]
	)

	const dayCellInfos = useMemo<MonthTableCellsInfo[]>(() => {
		const iterateDate = new Date(+firstDayDate)
		let itemsArray: MonthTableCellsInfo[] = []

		do {
			itemsArray = [
				...itemsArray,
				{
					id: getUniqueId(),
					events: getEventsByDays(iterateDate),
					date: new Date(iterateDate)
				}
			]

			iterateDate.setDate(iterateDate.getDate() + 1)
		} while (iterateDate < lastDayDate)

		return itemsArray
	}, [firstDayDate, lastDayDate, getEventsByDays])

	const tableItems = useMemo(() => {
		if (!dayCellInfos || dayCellInfos.length === 0) return []

		let itemsArray: ReactNode[] = []
		let currentWeekDay = 1

		const weekDayOfItem = dayCellInfos[0].date.getDay()

		while (
			(weekDayOfItem === DaysOfWeek.SUNDAY && currentWeekDay < 7) ||
			weekDayOfItem > currentWeekDay
		) {
			itemsArray = [
				...itemsArray,
				<WeekTableCell
					collapsed
					key={getUniqueId()}
					borderBottom
					onDetailsOpen={onDetailsOpen}
				/>
			]
			currentWeekDay++
		}

		itemsArray = [
			...itemsArray,
			...dayCellInfos.map((cell, index) => (
				<WeekTableCell
					borderBottom
					index={index}
					large
					collapsed
					key={cell.id}
					events={cell.events}
					title={cell.date.getDate().toString()}
					onDetailsOpen={onDetailsOpen}
				/>
			))
		]

		const lastMonthWeekDay = dayCellInfos[dayCellInfos.length - 1].date.getDay()

		if (lastMonthWeekDay !== DaysOfWeek.SUNDAY) {
			itemsArray = [
				...itemsArray,
				...new Array(7 - lastMonthWeekDay)
					.fill(true)
					.map(() => (
						<WeekTableCell
							collapsed
							key={getUniqueId()}
							borderBottom
							onDetailsOpen={onDetailsOpen}
						/>
					))
			]
		}

		return itemsArray
	}, [dayCellInfos, onDetailsOpen])

	return (
		<MonthTableComponent
			mappedWeekDays={mappedWeekDays}
			tableItems={tableItems}
		/>
	)
}

export default MonthTable
