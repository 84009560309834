import React, { FC } from 'react'

import CoursesTableBodyComponent from 'components/MobileTables/Courses/CoursesTableBody'
import { ICourse } from 'types/models/course.model'
import { DropdownItem } from 'UI/Dropdown/types'
import { courseQuery, userQuery } from 'store/queries'

export interface CoursesTableBodyProps {
	course: ICourse
	getDropdownItemsList: (course: ICourse) => DropdownItem[]
}

const CoursesTableBody: FC<CoursesTableBodyProps> = ({
	course,
	getDropdownItemsList
}) => {
	const { data: courseCreator } = userQuery.useGetUserByIdQuery(
		course.creatorId
	)
	const { data: lessonsCount } = courseQuery.useGetLessonsCountQuery(course.id)
	const { data: unverifiedAnswersCount } =
		courseQuery.useGetUnverifiedAnswersQuery(course.id)

	return (
		<CoursesTableBodyComponent
			course={course}
			unverifiedAnswersCount={unverifiedAnswersCount?.unverifiedAnswersCount}
			courseCreator={courseCreator?.data}
			lessonsCount={lessonsCount?.lessonCount}
			getDropdownItemsList={getDropdownItemsList}
		/>
	)
}

export default CoursesTableBody
