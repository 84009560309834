import React, { FC } from 'react'

import { GetMillisecondsFromTimeResult } from 'helpers/getMillisecondsFromTime'
import { QuestionTabs } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import QuestionsTabs from 'components/Courses/Entities/Tests/Preview/QuestionsListPreview/Tabs'
import { ITest, TestTypes } from 'types/models/test.model'
import AnswersListPass from 'containers/Courses/Entities/Tests/View/QuestionsListPass/AnswersList'
import { ResponseEntityId } from 'types'
import { CountDown } from 'components/index'
import { UseFormGetValues, UseFormWatch } from 'react-hook-form'
import { ITestAnswer } from 'store/queries/test.query/types'

import cl from 'components/Courses/Entities/Tests/Preview/QuestionsListPreview/style.module.scss'

interface QuestionListPassProps {
	passLimitTime?: GetMillisecondsFromTimeResult
	tabsList: QuestionTabs[]
	selectedQuestionTabId: QuestionTabs['id']
	onSelectTab: (tabId: QuestionTabs['id']) => void
	test: ITest
	onAppend?: (
		questionId: ResponseEntityId,
		correctAnswersIdList: number | number[]
	) => void
	fields: QuestionListAnswersFormState[]
	answerAttemptId: ResponseEntityId
	timerIsOver?: boolean
	setTimerIsOver?: (_: boolean) => void
	setIsQuestionsPassMode: (_: boolean) => void
	getFinalTestFormState: UseFormGetValues<TestPassFormState>
	viewMode?: boolean
	userAnswers?: ITestAnswer[]
	correctAnswersAmount?: number
	setCorrectAnswersAmount?: (_: number) => void
	onSubmit?: () => void
	cancelled?: boolean
	onCheckUserAttempt?: (attempt: number) => void
	setIsQuestionsPassingMode?: (_: boolean) => void
	currentAttempt?: ITestAnswer
	testDuration?: string
}

export interface TestPassFormState {
	questionsPass: QuestionListAnswersFormState[]
}

export interface QuestionListAnswersFormState {
	questionId: ResponseEntityId
	correctAnswersIdList: number[] | number
}

const QuestionListPass: FC<QuestionListPassProps> = ({
	passLimitTime,
	tabsList,
	selectedQuestionTabId,
	onSelectTab,
	test,
	onAppend,
	fields,
	answerAttemptId,
	setTimerIsOver,
	timerIsOver,
	currentAttempt,
	setIsQuestionsPassMode,
	getFinalTestFormState,
	viewMode,
	userAnswers,
	correctAnswersAmount,
	setCorrectAnswersAmount,
	onSubmit,
	cancelled,
	onCheckUserAttempt,
	setIsQuestionsPassingMode,
	testDuration
}) => (
	<div className={cl.questionsListPreview}>
		<div className={cl.header}>
			<h1>{test.name}</h1>
			<p className={cl.attemptsText}>
				{viewMode && (
					<>
						Просмотр попытки <span>{currentAttempt?.attempt}</span>
					</>
				)}
			</p>
		</div>
		<div className={cl.questionsMenu}>
			<div className={cl.menuInfo}>
				<div className={cl.menuItem}>
					<span>Тип тестирования</span>
					<span>
						{test.type === TestTypes.INTERMEDIATE
							? 'Промежуточное'
							: 'Итоговое'}
					</span>
				</div>
				<div className={cl.menuItem}>
					<span>{viewMode ? 'Затраченное время' : 'Оставшееся время'}</span>
					<span>
						{!viewMode && setTimerIsOver ? (
							<CountDown
								timerIsOver={timerIsOver!}
								setTimerIsOver={setTimerIsOver}
								isNotLimited={
									!passLimitTime?.hours &&
									!passLimitTime?.minutes &&
									!passLimitTime?.seconds
								}
								hours={passLimitTime?.hours ? Number(passLimitTime.hours) : 0}
								minutes={
									passLimitTime?.minutes ? Number(passLimitTime.minutes) : 0
								}
								seconds={
									passLimitTime?.seconds ? Number(passLimitTime.seconds) : 0
								}
							/>
						) : (
							<>{userAnswers && <>{currentAttempt?.duration || testDuration}</>}</>
						)}
					</span>
				</div>
				{viewMode && (
					<div className={cl.menuItem}>
						<span>Правильные ответы</span>
						<span>{correctAnswersAmount}</span>
					</div>
				)}
			</div>
			<QuestionsTabs
				onSelectTab={onSelectTab}
				tabs={tabsList}
				fields={fields}
				usersTests={userAnswers}
				testType={test.type}
				questions={test.questions}
				selectedTabId={selectedQuestionTabId}
				answerAttemptId={answerAttemptId}
				setCorrectAnswersAmount={setCorrectAnswersAmount}
				onSubmit={onSubmit}
				viewMode={viewMode}
			/>
		</div>
		{test.questions.reduce(
			(acc, field, fieldIndex) =>
				+fieldIndex === +selectedQuestionTabId ? (
					<AnswersListPass
						getFinalTestFormState={getFinalTestFormState}
						setIsQuestionsPassMode={setIsQuestionsPassMode}
						fields={fields}
						timerIsOver={timerIsOver}
						onAppend={onAppend}
						answerAttemptId={answerAttemptId}
						testId={test.id}
						question={test.questions[fieldIndex]}
						questionsList={test.questions}
						onSelect={onSelectTab}
						testType={test.type}
						key={field.id}
						viewMode={viewMode}
						userAnswers={userAnswers}
						cancelled={cancelled}
						onCheckUserAttempt={onCheckUserAttempt}
						userAnswersIdList={test.userAnswersIdList}
						setIsQuestionsPassingMode={setIsQuestionsPassingMode!}
						countAttempts={test.countAttempts}
						isAnswersRequired={test.testResponsibilityAnswersStatus}
					/>
				) : (
					acc
				),
			<></>
		)}
	</div>
)

export default QuestionListPass
