import React, { ButtonHTMLAttributes, useCallback, useMemo } from 'react'
import { Control, FieldPath, useWatch, FieldValues } from 'react-hook-form'
import Button from 'UI/Button'
import { ButtonStyles } from 'UI/Button/types'

import cl from './style.module.scss'

interface EvaluationButtonGroupProps<FormValueType>
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	from: number
	to: number
	control: Control<FormValueType & FieldValues>
	name: FieldPath<FormValueType & FieldValues>
	fromText: string
	toText: string
	onChange: (value: any) => void
}

const EvaluationButtonGroup = <FormValueType,>({
	from,
	to,
	control,
	name,
	fromText,
	toText,
	onChange,
	...props
}: EvaluationButtonGroupProps<FormValueType>) => {
	const watcher = useWatch({ control, name })

	const mappedEvaluationNumbers = useMemo(
		() => new Array(to).fill(null).map((_, i) => i + from),
		[to, from]
	)

	const getStylesDependingOnValue = useCallback(
		(value: number) => {
			if (value === watcher) return [ButtonStyles.PRIMARY]
			if (value <= watcher) return [ButtonStyles.PRIMARY_HOLLOW]

			return [ButtonStyles.DEFAULT_BG]
		},
		[watcher]
	)

	return (
		<div className={cl.buttonGroupContainer}>
			<div className={cl.buttonGroup}>
				{mappedEvaluationNumbers.map((value) => (
					<Button
						{...props}
						onClick={() => onChange(value)}
						key={value}
						styleTypes={[
							...getStylesDependingOnValue(value),
							ButtonStyles.ROUND
						]}
					>
						{value}
					</Button>
				))}
			</div>
			<div className={cl.labels}>
				<span>{fromText}</span>
				<span>{toText}</span>
			</div>
		</div>
	)
}

export default EvaluationButtonGroup
