import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TariffsSliceState } from './types'

const initialState: TariffsSliceState = {
	tariffExpired: false,
	tariffFilesCapacity: 0,
	modal: {
		open: false,
		text: null
	}
}

const tariffsSlice = createSlice({
	name: 'tariffs',
	initialState,
	reducers: {
		setModalOpen(state, action: PayloadAction<string>) {
			state.modal.open = true
			state.modal.text = action.payload
		},
		setModalClosed(state) {
			state.modal.open = false
			state.modal.text = null
		},
		setTariffExpired(state, { payload }: PayloadAction<boolean>) {
			state.tariffExpired = payload
		},
		setTariffFilesCapacity(state, { payload }: PayloadAction<number>) {
			state.tariffFilesCapacity = payload
		}
	}
})

export const { setModalOpen, setModalClosed, setTariffExpired } =
	tariffsSlice.actions

export default tariffsSlice
