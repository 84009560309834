import React, { FC, useCallback, useEffect } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister
} from 'react-hook-form'
import { LessonFormState } from 'pages/Course/Lesson/Create.page'

import {
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import LimitationsFormComponent from 'components/Courses/Entities/Lessons/Tabs/Content/LimitationsForm'
import lessonQuery from 'store/queries/lesson.query'
import { getFormData } from 'helpers'
import { useActions } from 'hooks/redux'

export type LimitationsFormTabState = Pick<
	LessonFormState,
	'lessonResponsibilityStatus' | 'id'
>

interface LimitationsFormTabProps {
	errors: FieldErrors<LimitationsFormTabState>
	onChange: (name: FieldPath<LimitationsFormTabState>) => (value: any) => void
	control: Control<LimitationsFormTabState>
	register: UseFormRegister<LimitationsFormTabState>
	handleSubmit: UseFormHandleSubmit<LimitationsFormTabState>
	getValues: UseFormGetValues<LimitationsFormTabState>
	onOpenCanceledChange: () => void
}

const LimitationsFormTab: FC<LimitationsFormTabProps> = ({
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	getValues,
	onOpenCanceledChange
}) => {
	const [changeLesson] = lessonQuery.useChangeLessonMutation()

	const { pushError, pushSuccess } = useActions((state) => state.system)

	useEffect(() => {
		register('lessonResponsibilityStatus', {
			required: ValidationErrorMessages.EMPTY
		})
		return () => {
			register('lessonResponsibilityStatus', { required: undefined })
		}
	}, [])
	const submitHandler = useCallback(
		handleSubmit(async ({ lessonResponsibilityStatus }) => {
			const response = await changeLesson({
				lessonId: +`${getValues('id')}`,
				body: await getFormData({ lessonResponsibilityStatus })
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
		}),
		[handleSubmit]
	)

	return (
		<LimitationsFormComponent
			onOpenCanceledChange={onOpenCanceledChange}
			errors={errors}
			control={control}
			onChange={onChange}
			onSubmit={submitHandler}
		/>
	)
}

export default LimitationsFormTab
