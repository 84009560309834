import React from 'react'

import { TeacherCommentContainerProps } from 'containers/Courses/Entities/Tasks/Check/TeacherComment'
import AnswerCreator from 'containers/Courses/Entities/Tests/Check/AttemptsTable/AnswerCreator'

import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'

import cl from './style.module.scss'

interface TeacherCommentProps extends TeacherCommentContainerProps {
	allowEdit: boolean
}

const TeacherComment: React.FC<TeacherCommentProps> = ({
	creatorId,
	date,
	comment,
	setEditMode,
	viewMode,
	allowEdit
}) => (
	<div className={cl.comment}>
		<header className={cl.commentHeader}>
			<AnswerCreator creatorId={creatorId} noEmail />

			<span className={cl.date}>{date}</span>
			{viewMode && (
				<Button
					styleTypes={[ButtonStyles.TERTIARY_BLUE]}
					onClick={() => setEditMode && setEditMode(true)}
					disabled={!allowEdit}
				>
					Редактировать
				</Button>
			)}
		</header>
		<footer className={cl.commentFooter}>{comment}</footer>
	</div>
)

export default TeacherComment
