import { useEffect } from 'react'

export const useDisableScrollManual = (condition: boolean) => {
    useEffect(() => {
        if (condition) {
            document.body.classList.add('no-scroll')
        } else {
            document.body.classList.remove('no-scroll')
        }
        return () => {
            document.body.classList.remove('no-scroll')
        }
    }, [condition])

}