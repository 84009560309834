import React, { FC } from 'react'
import { CurrentWeekContainerProps } from 'containers/Timetable/Tabs/WeekTab/CurrentWeek'

import classNames from 'classnames'
import WeekTableCell from 'containers/Timetable/Tabs/WeekTab/WeekTableCell'
import cl from './style.module.scss'

interface CurrentWeekComponentProps
	extends Omit<CurrentWeekContainerProps, 'control'> {
	collapsed: boolean
	onCollapse: () => void
	onShrink: () => void
}

const CurrentWeek: FC<CurrentWeekComponentProps> = ({
	onCollapse,
	collapsed,
	onShrink,
	mappedWeekDaysElements,
	onHoverIndexChange,
	hoverIndex,
	onDetailsOpen
}) => (
	<>
		<span className={cl.firstCol} onClick={collapsed ? onShrink : onCollapse}>
			<div
				className={classNames(cl.collapseBtn, {
					[cl.collapsed]: collapsed
				})}
			/>
		</span>
		{mappedWeekDaysElements.map((element, index) => (
			<WeekTableCell
				events={element.events}
				key={element.id}
				large
				index={index}
				onHoverIndexChange={onHoverIndexChange}
				highlighted={hoverIndex === index}
				collapsed={collapsed}
				onDetailsOpen={onDetailsOpen}
			/>
		))}
	</>
)

export default CurrentWeek
