import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import systemSlice from 'store/slices/system'
import CourseService from 'API/CourseService'
import { InviteStudentParams } from 'API/CourseService/types'
import { CourseSliceState } from '../types'

const inviteStudent = createAsyncThunk(
	'course/inviteUserExtra',
	async (params: InviteStudentParams, { rejectWithValue, dispatch }) => {
		try {
			await CourseService.inviteStudent(params)
		} catch ({ message }) {
			dispatch(systemSlice.actions.pushError({ message: message as string }))

			return rejectWithValue({ message })
		}
	}
)

export const inviteStudentExtra = {
	[inviteStudent.fulfilled.type]: (state: CourseSliceState) => {
		state.meta.isLoading = false
	},
	[inviteStudent.pending.type]: (state: CourseSliceState) => {
		state.meta.isLoading = true
		state.meta.error = ''
	},
	[inviteStudent.rejected.type]: (
		state: CourseSliceState,
		action: PayloadAction<string>
	) => {
		state.meta.isLoading = false
		state.meta.error = action.payload
	}
}

export default inviteStudent
