import React, { FC, InputHTMLAttributes } from 'react'
import { TestTypes } from 'types/models/test.model'

import { ICheckboxGroupItem } from 'UI/CheckboxGroup/types'
import classNames from 'classnames'

import cl from 'UI/Checkbox/style.module.scss'

export interface CheckboxGroupItemProps
	extends Omit<
		InputHTMLAttributes<HTMLInputElement>,
		'value' | 'onChange' | 'name'
	> {
	name: string
	item: ICheckboxGroupItem
	onToggle: (value: ICheckboxGroupItem['value']) => void
	disabled?: boolean
	isActive: boolean
	testType?: TestTypes
	viewMode?: boolean
	isFinalTestOngoing?: boolean
}

const CheckboxGroupItem: FC<CheckboxGroupItemProps> = ({
	item,
	onToggle,
	name,
	isActive,
	testType,
	disabled,
	viewMode,
	isFinalTestOngoing,
	...defaultProps
}) => (
	<div className={cl.container}>
		<input
			disabled={disabled}
			checked={isActive}
			readOnly
			id={item.id}
			onClick={onToggle.bind(null, item.value)}
			type="checkbox"
			name={name}
			{...defaultProps}
		/>
		<label htmlFor={item.id}>{item.labelTitle}</label>
		{item.isRight &&
			disabled &&
			viewMode &&
			isActive &&
			!isFinalTestOngoing && (
				<label className={classNames(cl.comment, cl.success)}>
					{item.comment}
				</label>
			)}
		{!item.isRight &&
			disabled &&
			viewMode &&
			isActive &&
			!isFinalTestOngoing && (
				<label className={classNames(cl.comment, cl.fail)}>
					{item.comment}
				</label>
			)}
	</div>
)

export default CheckboxGroupItem
