import React, { FC } from 'react'

import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ModalHeight, ModalSize } from 'UI/Modal/types'

import { useNavigate } from 'react-router-dom'
import cl from '../style.module.scss'

interface ConfirmDeleteUserModalProps {
	isOpen: boolean
	userName: string
	onClose: () => void
	onDelete: () => void
	isUserAware?: boolean
}

const ConfirmDeleteUserModal: FC<ConfirmDeleteUserModalProps> = ({
	isOpen,
	userName,
	onClose,
	onDelete,
	isUserAware
}) => {
	const navigate = useNavigate()
	// TODO закончить модальное окно
	return (
		<Modal
			styleTypes={[ModalSize.BIG, ModalHeight.SMALL]}
			title={isUserAware ? 'Удаление сотрудника' : 'Удалить пользователя?'}
			description={
				isUserAware
					? 'Исключите данного сотрудника из привязанных курсов'
					: `Вы действительно хотите удалить пользователя ${userName}?`
			}
			isOpen={isOpen}
			onClose={onClose}
		>
			<div className={cl.buttonsContainer}>
				<Button
					onClick={onClose}
					styleTypes={[ButtonStyles.OUTLINE_DARK, ButtonStyles.ROUND]}
				>
					Отмена
				</Button>
				<Button
					onClick={isUserAware ? () => navigate('/') : onDelete}
					styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
				>
					{isUserAware ? 'Перейти к списку курсов' : 'Удалить'}
				</Button>
			</div>
		</Modal>
	)
}

export default ConfirmDeleteUserModal
