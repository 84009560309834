import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(weekday)

interface getFirstDayFunctionParameters {
	currentDate: Date
	dayOfTheWeek: number
}

type getFirstDayFunctionWhenItsSundayParameters = Omit<
	getFirstDayFunctionParameters,
	'dayOfTheWeek'
>

const getFirstAndLastDaysOfTheWeek = (date?: Date) => {
	const currentDate = date || new Date()
	const dayOfTheWeek = currentDate.getDay()

	if (dayOfTheWeek === 0) {
		return getFirstAndLastDayWhenItsSunday({ currentDate })
	}

	return getFirstAndLastDay({ currentDate, dayOfTheWeek })
}

function getFirstAndLastDayWhenItsSunday({
	currentDate
}: getFirstDayFunctionWhenItsSundayParameters) {
	const newDate = new Date(currentDate)
	newDate.setHours(23, 59, 59)
	newDate.setDate(currentDate.getDate() - 7)

	const start = newDate.getDate()
	const end = currentDate.getDate()
	const firstDayDate = newDate

	const lastDate = new Date(currentDate)
	lastDate.setHours(23, 59, 59)
	const lastDayDate = lastDate

	return { start, end, firstDayDate, lastDayDate }
}

function getFirstAndLastDay({
	currentDate,
	dayOfTheWeek
}: getFirstDayFunctionParameters) {
	const newDate = new Date(currentDate)
	newDate.setHours(23, 59, 59)

	newDate.setDate(currentDate.getDate() - (dayOfTheWeek - 1))
	const firstDayDate = new Date(newDate)
	const start = newDate.getDate()

	newDate.setDate(start + 7)
	const lastDate = new Date(newDate)
	lastDate.setHours(23, 59, 59)
	const lastDayDate = lastDate

	const end = lastDate.getDate()

	return { start, end, firstDayDate, lastDayDate }
}

export default getFirstAndLastDaysOfTheWeek
