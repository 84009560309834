import React, { FC } from 'react'

const TimetableIcon: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.83333 9.16669H7.5V10.8334H5.83333V9.16669ZM5.83333 12.5H7.5V14.1667H5.83333V12.5ZM9.16667 9.16669H10.8333V10.8334H9.16667V9.16669ZM9.16667 12.5H10.8333V14.1667H9.16667V12.5ZM12.5 9.16669H14.1667V10.8334H12.5V9.16669ZM12.5 12.5H14.1667V14.1667H12.5V12.5Z"
			fill="#666666"
		/>
		<path
			d="M4.16667 18.3334H15.8333C16.7525 18.3334 17.5 17.5859 17.5 16.6667V5.00002C17.5 4.08085 16.7525 3.33335 15.8333 3.33335H14.1667V1.66669H12.5V3.33335H7.5V1.66669H5.83333V3.33335H4.16667C3.2475 3.33335 2.5 4.08085 2.5 5.00002V16.6667C2.5 17.5859 3.2475 18.3334 4.16667 18.3334ZM15.8333 6.66669L15.8342 16.6667H4.16667V6.66669H15.8333Z"
			fill="#666666"
		/>
	</svg>
)

export default TimetableIcon
