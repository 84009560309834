import {
	ReminderItemValue,
	TimeMeasure
} from 'containers/Courses/Tabs/Content/NotificationsForm'
import { getUniqueId } from 'helpers/index'

type getWeeksDaysType = (days: number) => ReminderItemValue

const getWeeksByDays: getWeeksDaysType = (days) => {
	if (days >= 30 && days % 30 === 0) {
		return {
			id: getUniqueId(),
			inNumber: `${days / 30}`,
			timeMeasure: TimeMeasure.MONTHS
		}
	}

	if (days >= 7 && days % 7 === 0) {
		return {
			id: getUniqueId(),
			inNumber: `${days / 7}`,
			timeMeasure: TimeMeasure.WEEKS
		}
	}

	return {
		id: getUniqueId(),
		inNumber: `${days}`,
		timeMeasure: TimeMeasure.DAYS
	}
}

export default getWeeksByDays
