import React from 'react'

import classNames from 'classnames'
import cl from './style.module.scss'

interface IFrameProps {
	src?: string
	className?: string
}

const Iframe: React.FC<IFrameProps> = ({ src, className }) => (
	<div
		className={classNames(cl.container, className, {
			[cl.noWidth]: !src
		})}
	>
		{src && (
			<iframe
				title={src}
				style={{
					objectFit: 'cover',
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					background: 'black'
				}}
				src={src}
				width="100%"
				height="100%"
				frameBorder="0"
				allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
				allowFullScreen
			/>
		)}
	</div>
)

export default Iframe
