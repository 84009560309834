import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { KinescopeData, KinescopeSliceState } from './types'

const initialState: KinescopeSliceState = {
	remainingStorage: 0,
	tariffFilesCapacity: 0,
	isLoading: true,
	kinescopeData: {
		folderId: '',
		projectId: '',
		token: ''
	}
}

const kinescopeSlice = createSlice({
	name: 'kinescope',
	initialState,
	reducers: {
		setKinescopeData(state, action: PayloadAction<KinescopeData>) {
			state.kinescopeData = { ...action.payload }
			state.isLoading = false
		},
		setRemainingStorage(state, { payload }: PayloadAction<number>) {
			state.remainingStorage = payload
		},
		setTariffFilesCapacity(state, { payload }: PayloadAction<number>) {
			state.tariffFilesCapacity = payload
		}
	}
})

export default kinescopeSlice
