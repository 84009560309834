import React, { FC } from 'react'
import { Button, Modal } from 'UI'
import { ModalHeight } from 'UI/Modal/types'
import cl from 'containers/Courses/Table/Modals/ConfirmUnPublish/style.module.scss'
import { ButtonStyles } from 'UI/Button/types'

interface ResetCertificateModalProps {
	isOpen: boolean
	onClose: () => void
	resetCertificateConfigs: () => void
}

const ResetCertificateModal: FC<ResetCertificateModalProps> = ({
	onClose,
	isOpen,
	resetCertificateConfigs
}) => (
	<Modal
		onClose={onClose}
		title="Сброс настроек сертификата"
		description="Вы действительно хотите сбросить настройки сертификата? Все внесенные изменения пропадут навсегда"
		isOpen={isOpen}
		styleTypes={[ModalHeight.SMALL]}
	>
		<div className={cl.buttonsContainer}>
			<div className={cl.buttonsContainer}>
				<Button
					onClick={onClose}
					styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
				>
					Отмена
				</Button>
				<Button
					onClick={() => {
						onClose()
						resetCertificateConfigs()
					}}
					styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
				>
					Сбросить настройки
				</Button>
			</div>
		</div>
	</Modal>
)

export default ResetCertificateModal
