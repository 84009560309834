import React, { FC } from 'react'

const CloseIcon: FC = () => (
	<svg
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.8333 1.34169L10.6583 0.166687L6 4.82502L1.34166 0.166687L0.166664 1.34169L4.825 6.00002L0.166664 10.6584L1.34166 11.8334L6 7.17502L10.6583 11.8334L11.8333 10.6584L7.175 6.00002L11.8333 1.34169Z"
			fill="black"
		/>
	</svg>
)

export default CloseIcon
