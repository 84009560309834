import React, { FC } from 'react'
import { ScheduleTabContainerProps } from 'containers/Timetable/Tabs/ScheduleTab'
import { IEvent } from 'types/models/event.model'
import ScheduleTable from 'containers/Timetable/Tabs/ScheduleTab/ScheduleTable'
import { Control, FieldPath } from 'react-hook-form'
import { TimetableContentFormValues } from 'containers/Timetable/TimetableContent'

interface ScheduleTabComponentProps extends ScheduleTabContainerProps {
	events: IEvent[]
	control: Control<TimetableContentFormValues>
	onChange: (
		name: FieldPath<TimetableContentFormValues>
	) => (value: any) => void
}

const ScheduleTab: FC<ScheduleTabComponentProps> = ({
	events,
	onChange,
	control,
	onDetailsOpen
}) => (
	<ScheduleTable
		events={events}
		control={control}
		onChange={onChange}
		onDetailsOpen={onDetailsOpen}
	/>
)

export default ScheduleTab
