import React, { FC } from 'react'
import { Link } from 'UI'
import { LinkStyles, LinkTextStyles, LinkTypes } from 'UI/Link/types'
import vkIconSrc from 'components/Courses/Entities/Lessons/Preview/icons/vk.svg'
import instagramIconSrc from 'components/Courses/Entities/Lessons/Preview/icons/instagram.svg'
import telegramIconSrc from 'components/Courses/Entities/Lessons/Preview/icons/telegram.svg'
import whatsappIconSrc from 'components/Courses/Entities/Lessons/Preview/icons/whatsapp.svg'
import { CommunicationsInfoItem } from 'types/models/course.model/settings/communications.model'

import cl from './style.module.scss'

interface ContactsProps<T = CommunicationsInfoItem> {
	condition: T
	email: T
	phone: T
	whatsapp: T
	telegram: T
	instagram: T
	vk: T
}

const Contacts: FC<ContactsProps> = ({
	condition,
	vk,
	email,
	instagram,
	phone,
	telegram,
	whatsapp
}) => (
	<>
		{((condition.text && condition.isActive) ||
			(email.text && email.isActive) ||
			(phone.text && phone.isActive) ||
			(vk.text && vk.isActive) ||
			(whatsapp.text && whatsapp.isActive) ||
			(instagram.text && instagram.isActive) ||
			(telegram.text && telegram.isActive)) && (
			<div className={cl.contactsInfo}>
				<h2>Связаться с автором</h2>
				{condition.text && <p>{condition.text}</p>}
				{(email.text || phone.text) && (
					<div className={cl.contacts}>
						{email.text && (
							<Link
								type={LinkTypes.TARGET_BLANK}
								href={`mailto:${email.text}`}
								className={cl.contactsLink}
								styleTypes={[LinkTextStyles.UNDERLINE, LinkStyles.SECONDARY]}
							>
								{email.text}
							</Link>
						)}
						{phone.text && (
							<Link
								type={LinkTypes.TARGET_BLANK}
								href={`tel:${phone.text}`}
								className={cl.contactsLink}
								styleTypes={[LinkTextStyles.UNDERLINE, LinkStyles.SECONDARY]}
							>
								{phone.text}
							</Link>
						)}
					</div>
				)}

				{(vk.text || whatsapp.text || instagram.text || telegram.text) && (
					<div className={cl.media}>
						{vk.text && (
							<Link
								href={`https://vk.com/${vk.text}`}
								type={LinkTypes.TARGET_BLANK}
								className={cl.mediaItem}
							>
								<img src={vkIconSrc} alt="social" />
							</Link>
						)}
						{instagram.text && (
							<Link
								href={`https://www.instagram.com/${instagram.text}`}
								type={LinkTypes.TARGET_BLANK}
								className={cl.mediaItem}
							>
								<img src={instagramIconSrc} alt="social" />
							</Link>
						)}
						{telegram.text && (
							<Link
								href={`https://t.me/${telegram.text}`}
								type={LinkTypes.TARGET_BLANK}
								className={cl.mediaItem}
							>
								<img src={telegramIconSrc} alt="social" />
							</Link>
						)}
						{whatsapp.text && (
							<Link
								href={`https://wa.me/${whatsapp.text}`}
								type={LinkTypes.TARGET_BLANK}
								className={cl.mediaItem}
							>
								<img src={whatsappIconSrc} alt="social" />
							</Link>
						)}
					</div>
				)}
			</div>
		)}
	</>
)

export default Contacts
