import { ResponseEntityId } from 'types'
import { ITest, PicturesInfoItem } from 'types/models/test.model'
import { QuestionListAnswersFormState } from 'components/Courses/Entities/Tests/View/QuestionsListPass'

export enum TestQueryApiRoutes {
	BASE_URL = 'tests'
}

export interface DeleteTest {
	sectionId: ResponseEntityId
	testId: ResponseEntityId
}
export interface CreateTest {
	sectionId: ResponseEntityId
	body: Pick<ITest, 'type' | 'name' | 'description'>
}

export interface ChangeTest {
	testId: ResponseEntityId
	body: Partial<ITest>
}
export interface CreateAnswer {
	testId: ResponseEntityId
	userAnswersAttemptId: ResponseEntityId
	userAnswers: QuestionListAnswersFormState[]
}
export interface FinishCreateAnswerResponse {
	isSuccessPassTest: boolean
}

export interface CreateAnswerAttempt {
	testId: ResponseEntityId
}
export interface CreateAnswerAttemptResponse {
	userAnswerId: ResponseEntityId
}
export interface GetTests {
	testIdList: ResponseEntityId[]
}
export interface GetAnswers {
	userAnswersIdList: string[]
	limit?: number
	page?: number
	sortDate?: string
	filterStatus?: string
	userId?: number
}

export interface GetUserAnswers {
	testId: ResponseEntityId
}
export interface ITestAnswer {
	answerStatus: string
	attempt: number
	correctAnswersCount: number
	creatorId: number
	dateCreate: string
	duration: string
	id: ResponseEntityId
	userAnswers: QuestionListAnswersFormState[]
}

export interface GetAnswersResponse {
	data: ITestAnswer[]
	limit?: number
	page?: number
	sortDate?: string
	filterStatus?: string
	totalCount: number
}

// export interface ITestOtherUserAnswer {
// 	answerStatus: string
// 	attempt: number
// 	correctAnswersCount: number
// 	creatorId: number
// 	dateCreate: string
// 	duration: string
// 	id: ResponseEntityId
// 	userAnswers: QuestionAnswerPair[]
// }

// export interface QuestionAnswerPair {
// 	[questionId: string]: string[] | string
// }
export interface GetTest {
	testId: ResponseEntityId
}

export interface CreateAnswerResponse {
	userAnswerId: ResponseEntityId
	userAnswers: number[] | number
}

export interface CreateTestResponse {
	data: { testId: ResponseEntityId }
}

export interface AddImageResponse {
	data: PicturesInfoItem[]
}

export interface AddImagePayload {
	testId: ResponseEntityId
	body: FormData
}

interface DeleteImagePayloadBody {
	pictureId: ResponseEntityId
}

export interface DeleteImagePayload {
	testId: ResponseEntityId
	body: DeleteImagePayloadBody
}
