import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { Constants, ResponseEntityId } from 'types'
import {
	AddImagePayload,
	AddImageResponse,
	ChangeTest,
	CreateAnswer,
	CreateAnswerAttempt,
	CreateAnswerAttemptResponse,
	CreateTest,
	DeleteImagePayload,
	DeleteTest,
	FinishCreateAnswerResponse,
	GetAnswers,
	GetTest,
	GetTests,
	GetUserAnswers,
	ITestAnswer,
	TestQueryApiRoutes
} from 'store/queries/test.query/types'
import { buildHeaders } from 'helpers/api'
import { ITest } from 'types/models/test.model'

const testQuery = createApi({
	reducerPath: 'testQuery',
	tagTypes: ['test'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${Constants.API_BASE_URL}/${TestQueryApiRoutes.BASE_URL}`
	}),
	endpoints: (build) => ({
		createTest: build.mutation<ResponseEntityId, CreateTest>({
			query: ({ body, sectionId }) => ({
				url: `create/section/${sectionId}`,
				method: 'POST',
				body: {
					...body,
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
				},
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['test']
		}),
		changeTest: build.mutation<ResponseEntityId, ChangeTest>({
			query: ({ body, testId }) => ({
				url: `${testId}/change`,
				method: 'POST',
				body: {
					...body,
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
				},
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['test']
		}),
		addPicture: build.mutation<AddImageResponse, AddImagePayload>({
			query: ({ testId, body }) => ({
				url: `${testId}/add-picture`,
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			})
		}),
		deletePicture: build.mutation<void, DeleteImagePayload>({
			query: ({ testId, body }) => ({
				url: `${testId}/remove-picture`,
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			})
		}),
		deleteTest: build.mutation<ResponseEntityId, DeleteTest>({
			query: ({ sectionId, testId }) => ({
				url: `${testId}/section/${sectionId}`,
				method: 'DELETE',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['test']
		}),
		createAnswer: build.mutation<
			{ data: FinishCreateAnswerResponse },
			CreateAnswer
		>({
			query: ({ testId, userAnswers, userAnswersAttemptId }) => ({
				url: `${testId}/create-answer`,
				method: 'POST',
				body: { userAnswersAttemptId, userAnswers },
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['test']
		}),
		createAnswerAttempt: build.mutation<
			CreateAnswerAttemptResponse,
			CreateAnswerAttempt
		>({
			query: ({ testId }) => ({
				url: `${testId}/credit-attempt`,
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['test']
		}),
		getTests: build.mutation<ITest[], GetTests>({
			query: ({ testIdList }) => ({
				url: 'get',
				method: 'POST',
				body: testIdList,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['test']
		}),
		getUserAnswers: build.query<{ data: ITestAnswer[] }, GetUserAnswers>({
			query: (testId) => ({
				url: 'get-user-answers',
				method: 'POST',
				body: testId,
				headers: buildHeaders({ needAuth: true })
			}),
			onQueryStarted(arg): Promise<void> | void {
				if (!arg) {
					throw new Error()
				}
			},
			providesTags: ['test']
		}),
		getAnswers: build.mutation<{ data: GetAnswers }, GetAnswers>({
			query: (userAnswersIdList) => ({
				url: 'get-answers',
				method: 'POST',
				body: userAnswersIdList,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['test']
		}),
		getTest: build.query<{ data: ITest }, GetTest>({
			query: ({ testId }) => ({
				url: `${testId}`,
				params: { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
				method: 'GET',
				headers: buildHeaders({ needAuth: true })
			}),
			onQueryStarted(arg): Promise<void> | void {
				if (!arg) {
					throw new Error()
				}
			},
			providesTags: ['test']
		})
	})
})

export default testQuery
