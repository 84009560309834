import React, { FC } from 'react'
import { UseFormGetValues } from 'react-hook-form'

import TaskAnswer from 'containers/Courses/Entities/Tasks/Answer'
import Contacts from 'containers/Courses/Entities/General/Contacts'
import { TaskFormState } from 'pages/Course/Task/Create.page'
import { CommunicationsSettings } from 'types/models/course.model/settings/communications.model'
import { ITask, TaskResponsibilityStatus } from 'types/models/task.model'

import cl from './style.module.scss'

interface TaskPreviewProps {
	getValues: UseFormGetValues<TaskFormState>
	communicationInfo: CommunicationsSettings
}

const getStartTimeToPassTaskString = (
	datePassStartSettings: ITask<string>['datePassStartSettings'],
	datePassEndSettings: ITask<string>['datePassEndSettings']
) => {
	let str = ''

	if (datePassStartSettings.isActive) {
		str += `${new Date(datePassStartSettings.date).toLocaleDateString()} `
		str += datePassStartSettings.time
	} else if (datePassEndSettings.isActive) {
		str += 'Без ограничений'
	}

	return str
}

const getEndTimeToPassTaskString = (
	datePassStartSettings: ITask<string>['datePassStartSettings'],
	datePassEndSettings: ITask<string>['datePassEndSettings']
) => {
	let str = ''

	if (datePassEndSettings.isActive) {
		str += `${new Date(datePassEndSettings.date).toLocaleDateString()} `
		str += datePassEndSettings.time
	} else if (datePassStartSettings.isActive) {
		str += 'Без ограничений'
	}

	return str
}

const TaskPreview: FC<TaskPreviewProps> = ({
	getValues,
	communicationInfo
}) => (
	<div className={cl.container}>
		<div className={cl.header}>
			<h1>{getValues('name')}</h1>
			<div className={cl.headerInfo}>
				<div className={cl.headerInfoItem}>
					<span>Задание обязательное</span>
					<span>
						{getValues('taskResponsibilityStatus') === TaskResponsibilityStatus.REQUIRED ? 'Да' : 'Нет'}
					</span>
				</div>
				<div className={cl.headerInfoItem}>
					<span>Период прохождения</span>
					<span>
						<span>
							{getStartTimeToPassTaskString(
								getValues('datePassStartSettings'),
								getValues('datePassEndSettings')
							)}
						</span>
						<span>—</span>
						<span>
							{getEndTimeToPassTaskString(
								getValues('datePassStartSettings'),
								getValues('datePassEndSettings')
							)}
						</span>
					</span>
				</div>
				<div className={cl.headerInfoItem}>
					<span>Последние изменения</span>
					<span>
						{getValues('dateEdit') ? `${getValues('dateEdit')}` : '-'}
					</span>
				</div>
			</div>
			{getValues('description') && (
				<>
					{getValues('description')
						.split('\n')
						.map((text) => (
							<p className={cl.caption} key={text}>
								{text}
							</p>
						))}
				</>
			)}
		</div>
		{(getValues('answerResponsibilitiesType')?.isFile ||
			getValues('answerResponsibilitiesType')?.isText) && (
			<TaskAnswer
				userAnswersIdList={getValues('userAnswersIdList')}
				maxCountUploadFiles={getValues('maxCountUploadFiles')}
				countAttempts={getValues('countAttempts')}
				answerResponsibilitiesType={getValues('answerResponsibilitiesType')}
			/>
		)}
		<Contacts communicationsInfo={communicationInfo} />
	</div>
)

export default TaskPreview
