import { Months } from 'types'

const getMonthNameByItsNumber = (monthNumber: Months) => {
	switch (monthNumber) {
		case Months.JANUARY:
			return 'январь'
		case Months.FEBRUARY:
			return 'февраль'
		case Months.MARCH:
			return 'март'
		case Months.APRIL:
			return 'апрель'
		case Months.MAY:
			return 'май'
		case Months.JUNE:
			return 'июнь'
		case Months.JULY:
			return 'июль'
		case Months.AUGUST:
			return 'август'
		case Months.SEPTEMBER:
			return 'сентябрь'
		case Months.OCTOBER:
			return 'октябрь'
		case Months.NOVEMBER:
			return 'ноябрь'
		case Months.DECEMBER:
			return 'декабрь'
		default:
			throw new Error("there's no such month number present")
	}
}
export default getMonthNameByItsNumber
