import classNames from 'classnames'
import EditEventForm from 'containers/Courses/Entities/Events/Edit'
import React, { MouseEvent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { eventsQuery } from 'store/queries'
import { Button, Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'

import cl from '../style.module.scss'

const EventEditPage: React.FC = () => {
	const navigate = useNavigate()
	const { eventId } = useParams()
	const { data: event, isLoading } = eventsQuery.useGetEventByIdQuery(eventId!)

	const onGoBack = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()

		navigate(-1)
	}

	if (!event || isLoading) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<div className={cl.container}>
			<div className={cl.back}>
				<Button onClick={onGoBack}>Вернуться назад</Button>
			</div>
			<div className={classNames(cl.header, cl.headerEvents)}>
				<div className={cl.headerInfo}>
					<h2>{event.data.theme}</h2>

					<p>Настройте в каком формате ученик должен предоставить ответ</p>
				</div>
			</div>
			<EditEventForm event={event.data} />
		</div>
	)
}

export default EventEditPage
