import React, { FC } from 'react'
import {
	EventDateStatuses,
	EventDetailsModalContainerProps
} from 'containers/Timetable/Tabs/WeekTab/WeekTableCell/EventDetailsModal'
import { ModalSize } from 'UI/Modal/types'
import { Button, Modal } from 'UI'

import { EventTypes } from 'types/models/event.model'
import { CourseRole, SchoolRole } from 'types'
import { ButtonStyles } from 'UI/Button/types'
import cl from './style.module.scss'

interface EventDetailsModalComponentProps
	extends EventDetailsModalContainerProps {
	dateString: string
	eventStatus: EventDateStatuses
	currentRole: SchoolRole | CourseRole
	onEditEvent: () => void
	onConnectToZoom: () => void
	eventHostName: string
}

const EventDetailsModal: FC<EventDetailsModalComponentProps> = ({
	activeEvent,
	onClose,
	isOpen,
	dateString,
	eventStatus,
	currentRole,
	onEditEvent,
	onConnectToZoom,
	eventHostName
}) => (
	<Modal
		isOpen={isOpen}
		styleTypes={[ModalSize.BIG]}
		onClose={onClose}
		title="Мероприятие"
	>
		{activeEvent && (
			<>
				<h4 className={cl.eventName}>{activeEvent.theme}</h4>

				<div className={cl.content}>
					<div className={cl.contentItem}>
						<div>Статус:</div>

						{eventStatus === EventDateStatuses.STARTED && (
							<div className={cl.started}>Началось</div>
						)}

						{eventStatus === EventDateStatuses.FINISHED && (
							<div className={cl.started}>Закончилось</div>
						)}

						{eventStatus === EventDateStatuses.NOT_STARTED && (
							<div className={cl.notYetStarted}>Еще не началось</div>
						)}
					</div>

					<div className={cl.contentItem}>
						<div>Организатор:</div>
						<div>{eventHostName}</div>
					</div>

					<div className={cl.contentItem}>
						<div>Тип:</div>
						<div>
							{activeEvent.eventType === EventTypes.OFFLINE_MEETING
								? 'Встреча'
								: 'Вебинар'}
						</div>
					</div>

					<div className={cl.contentItem}>
						<div>Дата и время:</div>
						<div>{dateString}</div>
					</div>

					<div className={cl.contentItem}>
						<div>Место проведения:</div>
						<div>
							{activeEvent.eventType === EventTypes.OFFLINE_MEETING
								? activeEvent.address?.replaceAll('&quot;', '"')
								: 'Zoom'}
						</div>
					</div>

					{activeEvent.comments && (
						<div className={cl.contentItem}>
							<div>Комментарий:</div>
							<div>{activeEvent.comments}</div>
						</div>
					)}
					{activeEvent.capacity && (
						<div className={cl.contentItem}>
							<div>Вместимость:</div>
							<div>{activeEvent.capacity} участников</div>
						</div>
					)}
				</div>

				<div className={cl.buttons}>
					{(currentRole === SchoolRole.ADMIN || currentRole === SchoolRole.TEACHER) && (
						<Button
							styleTypes={[ButtonStyles.DARK]}
							className={cl.button}
							onClick={onEditEvent}
						>
							Редактировать мероприятие
						</Button>
					)}

					{(activeEvent.eventType === EventTypes.OFFLINE_MEETING || eventStatus === EventDateStatuses.FINISHED) && (
						<Button styleTypes={[ButtonStyles.PRIMARY]} onClick={onClose}>
							Понятно
						</Button>
					)}

					{(activeEvent.eventType === EventTypes.ONLINE_MEETING && eventStatus !== EventDateStatuses.FINISHED) && (
						<Button
							styleTypes={[ButtonStyles.PRIMARY]}
							onClick={onConnectToZoom}
						>
							Подключиться
						</Button>
					)}
				</div>
			</>
		)}
	</Modal>
)

export default EventDetailsModal
