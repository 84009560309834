import React, { FC } from 'react'

import { ModalSize } from 'UI/Modal/types'
import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { CreationCancelledModalContainerProps } from 'containers/Courses/Entities/Events/Create/CreationCancelledModal'
import cl from '../style.module.scss'

interface CreationCancelledModalComponentProps
	extends Omit<CreationCancelledModalContainerProps, 'onClose'> {
	onCancelQuitting: () => void
	onQuit: () => void
}

const CreationCancelledModal: FC<CreationCancelledModalComponentProps> = ({
	isOpen,
	onCancelQuitting,
	onQuit
}) => (
	<Modal
		isOpen={isOpen}
		styleTypes={[ModalSize.BIG]}
		title="Публикация мероприятия"
		onClose={onCancelQuitting}
		description="Вы действительно хотите выйти без сохранения изменений? Все внесенные изменения будут потеряны."
	>
		<div className={cl.buttons}>
			<Button onClick={onCancelQuitting} styleTypes={[ButtonStyles.DEFAULT_BG]}>
				Отмена
			</Button>
			<Button onClick={onQuit} styleTypes={[ButtonStyles.DANGER]}>
				Выйти
			</Button>
		</div>
	</Modal>
)

export default CreationCancelledModal
