import React, { FC } from 'react'
import { Control, useWatch } from 'react-hook-form'
import { TestTypes } from 'types/models/test.model'

import { ICheckboxGroupItem } from 'UI/CheckboxGroup/types'
import { IRadioGroupItem } from 'UI/RadioGroup/types'
import CheckboxGroupItem from './item'

interface CheckboxGroupProps {
	itemsList: ICheckboxGroupItem[]
	name: string
	control: Control<any>
	onChange: (value: IRadioGroupItem['value'][]) => void
	disabled?: boolean
	testType?: TestTypes
	viewMode?: boolean
	isFinalTestOngoing?: boolean
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({
	onChange,
	control,
	disabled,
	name,
	itemsList,
	testType,
	viewMode,
	isFinalTestOngoing
}) => {
	const chosenCheckboxes: IRadioGroupItem['value'][] = useWatch({
		control,
		name
	})

	const toggleHandler = (value: IRadioGroupItem['value']) => {
		onChange(
			chosenCheckboxes.includes(value)
				? chosenCheckboxes.filter((chosenCheckbox) => value !== chosenCheckbox)
				: [...new Set([...chosenCheckboxes, value])]
		)
	}

	return (
		<>
			{Array.isArray(chosenCheckboxes) &&
				itemsList.map((item) => (
					<CheckboxGroupItem
						disabled={disabled}
						viewMode={viewMode}
						key={item.id}
						isActive={chosenCheckboxes.includes(item.value)}
						name={name}
						item={item}
						testType={testType}
						onToggle={toggleHandler}
						isFinalTestOngoing={isFinalTestOngoing}
					/>
				))}
		</>
	)
}

export default CheckboxGroup
