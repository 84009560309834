import React, { FC } from 'react'
import cl from './style.module.scss'
import MobileMenuItem from './MobileMenuItem'
import { MobileMenuItems } from './types'

interface MobileMenuComponentProps {
	currentRoute: string
	mobileMenuItems: MobileMenuItems[]
	isFinalTestOngoing?: boolean
}

const MobileMenu: FC<MobileMenuComponentProps> = ({
	currentRoute,
	mobileMenuItems,
	isFinalTestOngoing
}) => (
	<div className={`${cl.menu} ${isFinalTestOngoing ? cl.hide : ''}`}>
		{mobileMenuItems.map((item) => (
			<MobileMenuItem
				{...item}
				active={currentRoute === item.link}
				key={item.id}
			/>
		))}
	</div>
)

export default MobileMenu
