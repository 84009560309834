import React, { useMemo } from 'react'

import {
	ISectionItem,
	SectionItemType
} from 'containers/Courses/Tabs/Content/ContentForm/Table'

import Button from 'UI/Button'
import { ButtonStyles } from 'UI/Button/types'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import cl from './style.module.scss'

interface NavigationThroughLessonsProps {
	currentId: number
	itemsArray: ISectionItem[]
	courseId: number
	isMobile: boolean
}

const NavigationThroughLessons: React.FC<NavigationThroughLessonsProps> = ({
	currentId,
	itemsArray,
	courseId,
	isMobile
}) => {
	const navigate = useNavigate()

	const nextElement = useMemo(() => {
		let currentIndex = itemsArray.findIndex(
			(item) => item && currentId === item.id
		)

		let nextAfterThisElement = itemsArray[currentIndex + 1]

		while (
			nextAfterThisElement &&
			(!nextAfterThisElement.activity ||
				nextAfterThisElement.type === SectionItemType.EVENT)
		) {
			nextAfterThisElement = itemsArray[++currentIndex]
		}

		return nextAfterThisElement || undefined
	}, [currentId, itemsArray])

	const prevElement = useMemo(() => {
		let currentIndex = itemsArray.findIndex(
			(item) => item && currentId === item.id
		)

		let nextPrevElement = itemsArray[currentIndex - 1]

		while (
			nextPrevElement &&
			(!nextPrevElement.activity ||
				nextPrevElement.type === SectionItemType.EVENT)
		) {
			nextPrevElement = itemsArray[--currentIndex]
		}

		return nextPrevElement || undefined
	}, [currentId, itemsArray])

	return (
		<div
			className={classNames(cl.content, {
				[cl.hidden]: !prevElement && !nextElement
			})}
		>
			<div>
				{prevElement && (
					<>
						<Button
							styleTypes={[ButtonStyles.HOLLOW]}
							onClick={() =>
								navigate(
									`/course/${courseId}/${prevElement.type}/view/${prevElement.id}`
								)
							}
						>
							Предыдущее занятие
						</Button>
						<span>
							{!isMobile || prevElement.name.length <= 10
								? prevElement.name
								: `${prevElement.name.slice(0, 10)}...`}
						</span>
					</>
				)}
			</div>
			<div className={cl.next}>
				{nextElement && (
					<>
						<Button
							styleTypes={[ButtonStyles.HOLLOW]}
							onClick={() =>
								navigate(
									`/course/${courseId}/${nextElement.type}/view/${nextElement.id}`
								)
							}
						>
							Следующее занятие
						</Button>
						<span>
							{!isMobile || nextElement.name.length <= 10
								? nextElement.name
								: `${nextElement.name.slice(0, 10)}...`}
						</span>
					</>
				)}
			</div>
		</div>
	)
}

export default NavigationThroughLessons
