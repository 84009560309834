import React, { FC, useCallback } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormHandleSubmit,
	UseFormSetValue
} from 'react-hook-form'

import EditSectionModalComponent from 'components/Courses/Tabs/Content/ContentForm/modals/EditSection'
import { SectionFormState } from 'containers/Courses/Tabs/Content/ContentForm'
import { ResponseEntityId } from 'types'
import courseQuery from 'store/queries/course.query'

export interface EditSectionModalProps {
	isModalOpen: boolean
	closeModal: () => void
	control: Control<SectionFormState>
	errors: FieldErrors<SectionFormState>
	onChange: (name: FieldPath<SectionFormState>) => (value: any) => void
	handleSubmit: UseFormHandleSubmit<SectionFormState>
	currentSectionId: ResponseEntityId
	setValue: UseFormSetValue<SectionFormState>
}

const EditSectionModal: FC<EditSectionModalProps> = ({
	isModalOpen,
	closeModal,
	control,
	handleSubmit,
	errors,
	onChange,
	currentSectionId,
	setValue
}) => {
	const [editSection] = courseQuery.useEditCourseSectionMutation()
	const submitHandler = useCallback(
		handleSubmit(({ sectionName }) => {
			editSection({ name: sectionName, sectionId: currentSectionId })
			setValue('sectionName', '')
			closeModal()
		}),
		[]
	)
	return (
		<EditSectionModalComponent
			control={control}
			errors={errors}
			onChange={onChange}
			onSubmit={submitHandler}
			closeModal={closeModal}
			isModalOpen={isModalOpen}
		/>
	)
}

export default EditSectionModal
