import React, { FC } from 'react'
import { DeleteSectionItemModalProps as DeleteSectionItemModalPropsContainer } from 'containers/Courses/Tabs/Content/ContentForm/modals/DeleteSectionItem'
import { Button, Modal } from 'UI'
import { ModalHeight } from 'UI/Modal/types'
import cl from 'containers/Courses/Table/Modals/ConfirmUnPublish/style.module.scss'
import { ButtonStyles } from 'UI/Button/types'
import { useDisableScrollManual } from 'hooks/useDisableScrollManual'

interface DeleteSectionItemModalProps
	extends Omit<
		DeleteSectionItemModalPropsContainer,
		| 'sectionItemId'
		| 'sectionId'
		| 'currentSectionItemType'
		| 'currentSectionItemName'
	> {
	deleteSectionItem: () => void
	modalHeader: string
	modalDescription: string
}

const DeleteSectionItemModal: FC<DeleteSectionItemModalProps> = ({
	deleteSectionItem,
	modalHeader,
	isModalOpen,
	modalDescription,
	onClose
}) => {
	useDisableScrollManual(isModalOpen)
	return (
		<Modal
			styleTypes={[ModalHeight.SMALL]}
			isOpen={isModalOpen}
			title={modalHeader}
			description={modalDescription}
			onClose={onClose}
		>
			<div className={cl.buttonsContainer}>
				<Button
					onClick={onClose}
					styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
				>
					Отмена
				</Button>
				<Button
					onClick={() => deleteSectionItem()}
					styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
				>
					Удалить
				</Button>
			</div>
		</Modal>
	)
}

export default DeleteSectionItemModal
