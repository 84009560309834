import React, { FC, useCallback, useState } from 'react'
import { Control, FieldPath, useWatch } from 'react-hook-form'
import { TimetableContentFormValues } from 'containers/Timetable/TimetableContent'
import TimetableTabsComponent from 'components/Timetable/Tabs'
import EventDetailsModal from 'containers/Timetable/Tabs/WeekTab/WeekTableCell/EventDetailsModal'
import { IEvent } from 'types/models/event.model'

export interface TimetableTabsContainerProps {
	control: Control<TimetableContentFormValues>
	onChange: (
		name: FieldPath<TimetableContentFormValues>
	) => (value: any) => void
}

const TimetableTabs: FC<TimetableTabsContainerProps> = ({
	control,
	onChange
}) => {
	const [currentEventDetailsOpen, setCurrentEventDetailsOpen] = useState(false)
	const [eventDetails, setEventDetails] = useState<IEvent>()

	const onDetailsOpen = useCallback((event: IEvent) => {
		setCurrentEventDetailsOpen(true)
		setEventDetails(event)
	}, [])

	const onDetailsClose = useCallback(() => {
		setCurrentEventDetailsOpen(false)
		setEventDetails(undefined)
	}, [])

	const mode = useWatch({
		control,
		name: 'mode'
	})

	return (
		<>
			<TimetableTabsComponent
				control={control}
				onChange={onChange}
				currentMode={mode}
				onDetailsOpen={onDetailsOpen}
			/>

			<EventDetailsModal
				isOpen={currentEventDetailsOpen}
				onClose={onDetailsClose}
				activeEvent={eventDetails}
			/>
		</>
	)
}

export default TimetableTabs
