import React, { FC, useMemo, useState } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister
} from 'react-hook-form'
import { DevTool } from '@hookform/devtools'

import { Tabs } from 'UI'
import { ITab } from 'UI/Tabs/types'
import { getUniqueId } from 'helpers'
import { TestFormState } from 'pages/Course/Test/Create.page'
import MainFormTab, { MainFormTabState } from '../Content/MainForm'

import cl from '../style.module.scss'

interface TestsTabsProps {
	register: UseFormRegister<TestFormState>
	handleSubmit: UseFormHandleSubmit<TestFormState>
	errors: FieldErrors<TestFormState>
	control: Control<TestFormState>
	onChange: (name: FieldPath<TestFormState>) => (value: any) => void
	getValues: UseFormGetValues<TestFormState>
	onOpenCanceledChange: () => void
}

const TestsTabs: FC<TestsTabsProps> = ({
	register,
	handleSubmit,
	control,
	onChange,
	errors,
	getValues,
	onOpenCanceledChange
}) => {
	const tabsList = useMemo(
		(): ITab[] => [
			{ id: getUniqueId(), text: 'Основная информация' },
			{ id: getUniqueId(), text: 'Вопросы' },
			{ id: getUniqueId(), text: 'Ограничения' }
		],
		[]
	)
	const [selectedTabId, setSelectedTabId] = useState<ITab['id']>(tabsList[0].id)

	return (
		<div className={cl.container}>
			<div className={cl.tabsToggles}>
				<Tabs
					disabled
					value={selectedTabId}
					tabs={tabsList}
					onChange={setSelectedTabId}
				/>
			</div>
			<div className={cl.tabsContent}>
				{selectedTabId === tabsList[0].id && (
					<MainFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						getValues={
							getValues as unknown as UseFormGetValues<MainFormTabState>
						}
						register={register as unknown as UseFormRegister<MainFormTabState>}
						control={control as unknown as Control<MainFormTabState>}
						onChange={onChange}
						errors={errors}
						handleSubmit={handleSubmit}
					/>
				)}
				<DevTool control={control} />
			</div>
		</div>
	)
}

export default TestsTabs
