import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { Constants } from 'types'
import {
	GetCityOptionsResponse,
	GetLearningFormatsResponse,
	GetTariffPeriodsResponse,
	GetTariffsResponse,
	SelectsPaths
} from './types'

const selectsQuery = createApi({
	reducerPath: 'selectsQuery',
	baseQuery: fetchBaseQuery({
		baseUrl: `${Constants.API_BASE_URL}/${SelectsPaths.BASE_URL}`
	}),
	tagTypes: ['selects'],
	endpoints: (build) => ({
		getTariffs: build.query<GetTariffsResponse, void>({
			query: () => ({
				url: SelectsPaths.GET_TARIFFS,
				method: 'GET'
			})
		}),
		getTariffPeriods: build.query<GetTariffPeriodsResponse, void>({
			query: () => ({
				url: SelectsPaths.GET_TARIFF_PERIODS,
				method: 'GET'
			})
		}),
		getCityOptions: build.query<GetCityOptionsResponse, string>({
			query: (search) => ({
				url: SelectsPaths.GET_CITY_OPTIONS,
				method: 'GET',
				params: {
					search
				}
			})
		}),
		getLearningFormats: build.query<GetLearningFormatsResponse, void>({
			query: () => ({
				url: SelectsPaths.GET_LEARNING_FORMATS,
				method: 'GET'
			})
		})
	})
})

// https://dev.znaviki.ru/api/selects/rates

export default selectsQuery
