import React, { FC, useMemo } from 'react'
import TimeLineComponent from 'components/Timetable/Tabs/WeekTab/TimeLine'
import getDayTimelineItems from 'helpers/time/getDayTimelineItems'
import { IEvent } from 'types/models/event.model'
import { WeekDay } from 'types/models/day-event.model/day-event.model'
import { EntityId } from '@reduxjs/toolkit'
import { getUniqueId } from 'helpers'

interface RowElement {
	id: EntityId
	weekDate: Date
	event?: IEvent
}

export interface EventGroupTime {
	id: EntityId
	time: string
	elements: RowElement[]
}

export interface TimeLineContainerProps {
	mappedWeekDays: WeekDay[]
	getEventsByDays: (_: Date) => IEvent[]
	hoverIndex: number | null
	onHoverIndexChange: (_: number | null) => void
	onDetailsOpen: (_: IEvent) => void
}

const TimeLine: FC<TimeLineContainerProps> = ({
	getEventsByDays,
	mappedWeekDays,
	hoverIndex,
	onHoverIndexChange,
	onDetailsOpen
}) => {
	const grouppedEventsByTime = useMemo<EventGroupTime[]>(() => {
		if (!mappedWeekDays) return []

		const timeLineItems = getDayTimelineItems()

		return timeLineItems.map((t) => ({
			id: getUniqueId(),
			time: t,
			elements: mappedWeekDays.map((weekDay) => ({
				id: getUniqueId(),
				weekDate: weekDay.date,
				event: getEventsByDays(weekDay.date).find(
					(e) => e.time.split(':')[0] === t.split(':')[0]
				)
			}))
		}))
	}, [mappedWeekDays, getEventsByDays])

	return (
		<TimeLineComponent
			grouppedEventsByTime={grouppedEventsByTime}
			hoverIndex={hoverIndex}
			onHoverIndexChange={onHoverIndexChange}
			onDetailsOpen={onDetailsOpen}
		/>
	)
}

export default TimeLine
