import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
	getItemFromStorage,
	removeItemFromStorage,
	saveItemToStorage
} from 'helpers/storage'
import { getUniqueId } from 'helpers'

import {
	CourseRole,
	NodeElementsId,
	ResponseEntityId,
	SchoolRole,
	StorageKeys
} from 'types'
import { StorageTypes } from 'helpers/storage/types'
import {
	ErrorElement,
	SuccessElement,
	SystemSliceState,
	UserRole
} from './types'

const initialState: SystemSliceState = {
	isAuth: false,
	isLoading: true,
	token: getItemFromStorage(StorageKeys.TOKEN, ''),
	errorElementsList: [],
	successElementsList: [],
	haveRegistrationCongratulations: false,
	haveTestPassingSuccess: false,
	haveTestPassingFailed: false,
	userRole: [],
	tariffSoonToExpireModalText: '',
	tariffSoonToExpireModalShown: getItemFromStorage(
		StorageKeys.TARIFF_SOON_TO_EXPIRE_MODAL_SHOWN,
		false,
		StorageTypes.LOCAL_STORAGE
	),
	currentSchoolId: getItemFromStorage(
		StorageKeys.CURRENT_SCHOOL_ID,
		'',
		StorageTypes.LOCAL_STORAGE
	),
	currentRole: getItemFromStorage(
		StorageKeys.CURRENT_ROLE,
		CourseRole.CURATOR,
		StorageTypes.LOCAL_STORAGE
	),
	currentCoursesIdList: getItemFromStorage(
		StorageKeys.CURRENT_COURSES_ID_LIST,
		[],
		StorageTypes.LOCAL_STORAGE
	)
}

const systemSlice = createSlice({
	name: 'system',
	initialState,
	reducers: {
		logIn(state, { payload }: PayloadAction<string | undefined>) {
			state.isAuth = true

			if (!payload && !state.token) {
				state.isAuth = false
				return
			}

			if (payload && payload !== state.token) {
				state.token = payload
				saveItemToStorage([StorageKeys.TOKEN, payload])
			}
		},
		finishCheckAuthorization(state) {
			state.isLoading = false
		},
		pushError(state, { payload }: PayloadAction<Omit<ErrorElement, 'id'>>) {
			const errorId = getUniqueId()

			state.errorElementsList.push({
				id: errorId,
				...payload
			})
		},
		pushSuccess(state, { payload }: PayloadAction<Omit<SuccessElement, 'id'>>) {
			const successId = getUniqueId()

			state.successElementsList.push({
				id: successId,
				...payload
			})
		},
		clearError(state, { payload }: PayloadAction<NodeElementsId>) {
			state.errorElementsList = state.errorElementsList.filter(
				({ id }) => id !== payload
			)
		},
		clearSuccess(state, { payload }: PayloadAction<NodeElementsId>) {
			state.successElementsList = state.successElementsList.filter(
				({ id }) => id !== payload
			)
		},
		testModalPassingSuccessOpen(state) {
			state.haveTestPassingSuccess = true
		},
		testModalPassingSuccessClose(state) {
			state.haveTestPassingSuccess = false
		},
		testModalPassingFailedOpen(state) {
			state.haveTestPassingFailed = true
		},
		testModalPassingFailedClose(state) {
			state.haveTestPassingFailed = false
		},
		congratulationsOnRegistration(state) {
			state.haveRegistrationCongratulations = true
		},
		breakCongratulationsOnRegistration(state) {
			state.haveRegistrationCongratulations = false
		},
		setUserRole(state, { payload }: PayloadAction<UserRole[]>) {
			state.userRole = payload
		},
		setCurrentSchoolId(state, { payload }: PayloadAction<string>) {
			state.currentSchoolId = payload
		},
		setCurrentRole(state, { payload }: PayloadAction<CourseRole | SchoolRole>) {
			state.currentRole = payload
		},
		setCurrentCoursesIdList(
			state,
			{ payload }: PayloadAction<ResponseEntityId[]>
		) {
			state.currentCoursesIdList = payload
		},
		setTariffSoonToExpireModalShown(state, { payload }: PayloadAction<string>) {
			state.tariffSoonToExpireModalText = payload
			state.tariffSoonToExpireModalShown = true
			saveItemToStorage([StorageKeys.TARIFF_SOON_TO_EXPIRE_MODAL_SHOWN, true], {
				storageType: StorageTypes.LOCAL_STORAGE
			})
		},
		hideTariffSoonToExpireModal(state) {
			state.tariffSoonToExpireModalText = ''
			state.tariffSoonToExpireModalShown = false
		},
		logOut(state) {
			removeItemFromStorage([StorageKeys.TOKEN])
			localStorage.clear()
			state.token = ''
			state.isAuth = false
			state.userRole = []
		}
	}
})

export const { pushError } = systemSlice.actions

export default systemSlice
