import React, { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Iframe, Modal, Loader } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ModalSize } from 'UI/Modal/types'
import { Constants } from 'types'
import { downloadFile } from 'helpers/files'
import { useMatchMedia } from 'hooks'
import cl from './style.module.scss'

interface GetCertificateModalProps {
	isOpen: boolean
	title: string
	certificateUrl: string
	onClose: () => void
}

const GetCertificateModal: React.FC<GetCertificateModalProps> = ({
	certificateUrl,
	isOpen,
	title,
	onClose
}) => {
	const linkRef = useRef<HTMLAnchorElement>(null)

	const navigate = useNavigate()

	const onDownload = useCallback(() => {
		downloadFile(`${Constants.BASE_URL}${certificateUrl}`, certificateUrl)
	}, [linkRef, certificateUrl])

	const goBack = () => {
		navigate('/')
	}

	const { isMobile } = useMatchMedia()

	const certificateLink = isMobile ? `https://docs.google.com/viewer?url=${Constants.BASE_URL}${certificateUrl}&embedded=true` : `${Constants.BASE_URL}${certificateUrl}`

	return (
		<Modal
			styleTypes={[ModalSize.BIG]}
			isOpen={isOpen}
			title={title}
			onClose={onClose}
		>
			{!certificateUrl && <Loader />}
			{certificateUrl && (
				<>
					<Iframe
						src={certificateLink}
						className={cl.frame}
					/>

					<div className={cl.buttons}>
						{/* TODO fix the warning */}
						{/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */}
						<a
							download={certificateUrl}
							href={`${Constants.BASE_URL}${certificateUrl}`}
							ref={linkRef}
							style={{ display: 'none' }}
						/>

						<Button styleTypes={[ButtonStyles.DARK]} onClick={onDownload}>
							Скачать сертификат
						</Button>

						<Button styleTypes={[ButtonStyles.PRIMARY]} onClick={goBack}>
							Перейти к списку курсов
						</Button>
					</div>
				</>
			)}
		</Modal>
	)
}

export default GetCertificateModal
