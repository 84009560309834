import React, { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMatchMedia } from 'hooks'
import { useActions, useAppSelector } from 'hooks/redux'

import emptyIconSrc from 'components/Courses/Tabs/Content/ContentForm/icons/emptyIconQuestions.svg'
import MainFormTableComponent from 'components/Courses/Tabs/ViewContent/MainTab'

import { EmptyContent, Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { ResponseEntityId } from 'types'

import { courseQuery, eventsQuery, userQuery } from 'store/queries'

import { SectionItemType } from 'containers/Courses/Tabs/Content/ContentForm/Table'
import EventDetailsModal from 'containers/Timetable/Tabs/WeekTab/WeekTableCell/EventDetailsModal'
import { IEvent } from 'types/models/event.model'
import cl from './style.module.scss'

interface MainFormTableProps {
	sectionsIdList: ResponseEntityId[]
	courseId: ResponseEntityId
}

const MainFormTable: FC<MainFormTableProps> = ({
	sectionsIdList = [],
	courseId
}) => {
	const navigate = useNavigate()
	const { pushError } = useActions((state) => state.system)
	const { token, currentRole } = useAppSelector((state) => state.system)
	const { isMobile } = useMatchMedia()

	const [eventDetailsModalOpen, setEventDetailsModalOpen] = useState(false)
	const [activeEvent, setActiveEvent] = useState<IEvent>()

	const { data: yourselfInfo } = userQuery.useGetYourselfQuery(token)

	const [triggerGetSections, { data: sectionsInfo }] =
		courseQuery.endpoints.getCourseSections.useLazyQuery()

	useEffect(() => {
		if (!yourselfInfo) return

		triggerGetSections({
			userId: yourselfInfo.data.id,
			sectionsIdList,
			courseId,
			role: currentRole
		})
	}, [yourselfInfo, sectionsIdList, currentRole])

	const [fetchNextActiveEvent] = eventsQuery.useLazyGetEventByIdQuery()

	const onOpenEventDetails = useCallback(async (eventId: ResponseEntityId) => {
		const { data: response } = await fetchNextActiveEvent(eventId)

		if (!response) {
			pushError({ message: 'Такого мероприятия не существует' })
			return
		}

		setEventDetailsModalOpen(true)
		setActiveEvent(response.data)
	}, [])

	const onCloseEventDetails = useCallback(() => {
		setEventDetailsModalOpen(false)
		setActiveEvent(undefined)
	}, [])

	if (!sectionsInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	const clickToSectionItemHandler = (
		sectionItemType: SectionItemType,
		sectionItemId: ResponseEntityId,
		courseIdx: ResponseEntityId
	) => {
		switch (sectionItemType) {
			case SectionItemType.LESSON:
				return navigate(`/course/${courseIdx}/lesson/view/${sectionItemId}`)
			case SectionItemType.TASK:
				return navigate(`/course/${courseIdx}/task/view/${sectionItemId}`)
			case SectionItemType.EVENT:
				return onOpenEventDetails(sectionItemId)
			case SectionItemType.TEST:
				return navigate(`/course/${courseIdx}/test/view/${sectionItemId}`)

			default:
				return navigate('/')
		}
	}

	return (
		<>
			{!sectionsInfo.data.length ? (
				<div className={cl.emptyBlock}>
					<EmptyContent
						imageSrc={emptyIconSrc}
						title="Материалы курса отсутсвуют"
					/>
				</div>
			) : (
				<MainFormTableComponent
					courseId={courseId}
					onClick={clickToSectionItemHandler}
					sections={sectionsInfo.data}
					isMobile={isMobile}
					currentRole={currentRole}
				/>
			)}

			<EventDetailsModal
				isOpen={eventDetailsModalOpen}
				onClose={onCloseEventDetails}
				activeEvent={activeEvent}
			/>
		</>
	)
}

export default MainFormTable
