import React, { FC, useCallback } from 'react'
import { FieldPathValue } from 'react-hook-form'
import { TimetableContentFormValues } from 'containers/Timetable/TimetableContent'
import GoForwardComponent from 'components/Timetable/Tabs/ScheduleTab/ScheduleTable/GoForward'

export interface GoForwardContainerProps {
	dateString: string
	currentDate: Date
	onChange: (
		value: FieldPathValue<TimetableContentFormValues, 'currentDate'>
	) => void
}

const GoForward: FC<GoForwardContainerProps> = ({
	dateString,
	currentDate,
	onChange
}) => {
	const onGoForward = useCallback(() => {
		const newDate = new Date(+currentDate)
		newDate.setDate(newDate.getDate() + 1)
		onChange(newDate)
	}, [currentDate, onChange])
	return (
		<GoForwardComponent onGoForward={onGoForward} dateString={dateString} />
	)
}

export default GoForward
