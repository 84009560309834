import React, { FC } from 'react'

const UsersIcon: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.6667 7.92251C12.6258 7.92251 13.3333 7.21584 13.3333 6.25584C13.3333 5.29584 12.6258 4.58917 11.6667 4.58917C10.7083 4.58917 10 5.29584 10 6.25584C10 7.21584 10.7083 7.92251 11.6667 7.92251ZM11.6667 8.75584C9.82583 8.75584 8.33333 10.0992 8.33333 11.7558V12.0775H15V11.7558C15 10.0992 13.5075 8.75584 11.6667 8.75584Z"
			fill="#666666"
		/>
		<path
			d="M16.8519 1.66669H6.48148C5.66444 1.66669 5 2.37022 5 3.23531V13.4314C5 14.2965 5.66444 15 6.48148 15H9.44444H11.6667H13.8889H16.8519C17.6689 15 18.3333 14.2965 18.3333 13.4314V3.23531C18.3333 2.37022 17.6689 1.66669 16.8519 1.66669ZM13.3333 13.3334H11.6667H10H6.66667V3.36403L16.6667 3.33335V13.3334H13.3333Z"
			fill="#666666"
		/>
		<path
			d="M3.33333 6.66669H1.66667V16.6667C1.66667 17.5859 2.41417 18.3334 3.33333 18.3334H13.3333V16.6667H3.33333V6.66669Z"
			fill="#666666"
		/>
	</svg>
)

export default UsersIcon
