import React, { FC } from 'react'
import { CoursesTableMobileProps as CoursesTableMobileContainerProps } from 'containers/MobileTables/Courses/CoursesTable'
import CoursesTableBody from 'containers/MobileTables/Courses/CoursesTableBody'

import cl from '../style.module.scss'

interface CoursesTableMobileProps extends CoursesTableMobileContainerProps {}

const CoursesTable: FC<CoursesTableMobileProps> = ({
	data,
	getDropdownItemsList
}) => (
	<div className={cl.tableMobile}>
		{data.map((course) => (
			<CoursesTableBody
				getDropdownItemsList={getDropdownItemsList}
				key={course.id}
				course={course}
			/>
		))}
	</div>
)

export default CoursesTable
