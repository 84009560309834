import React, { FC, ReactNode, useMemo } from 'react'
import ScheduleTableComponent from 'components/Timetable/Tabs/ScheduleTab/ScheduleTable'
import { IEvent } from 'types/models/event.model'
import { Control, FieldPath, useWatch } from 'react-hook-form'
import { TimetableContentFormValues } from 'containers/Timetable/TimetableContent'
import { getMonthNameByItsNumber } from 'helpers/time'
import TableItem from 'containers/Timetable/Tabs/ScheduleTab/ScheduleTable/TableItem'
import TableHeader from 'containers/Timetable/Tabs/ScheduleTab/ScheduleTable/TableHeader'
import { getUniqueId } from 'helpers'

export interface ScheduleTableContainerProps {
	events: IEvent[]
	control: Control<TimetableContentFormValues>
	onChange: (
		name: FieldPath<TimetableContentFormValues>
	) => (value: any) => void
	onDetailsOpen: (_: IEvent) => void
}

const ScheduleTable: FC<ScheduleTableContainerProps> = ({
	events,
	control,
	onChange,
	onDetailsOpen
}) => {
	const currentDate = useWatch({
		control,
		name: 'currentDate'
	})

	const dateStringAfter = useMemo(() => {
		if (!currentDate) return ''

		return `${currentDate.getDate()} ${getMonthNameByItsNumber(
			currentDate.getMonth()
		)}`
	}, [currentDate])

	const dateStringLater = useMemo(() => {
		if (!currentDate) return ''

		return `${currentDate.getDate()} ${getMonthNameByItsNumber(
			currentDate.getMonth()
		)}`
	}, [currentDate])

	const sortedEvents = useMemo(() => {
		if (!events) return []

		return [...events].sort(
			(a, b) => +a.time.split(':')[0] - +b.time.split(':')[0]
		)
	}, [events])

	const mappedItems = useMemo(() => {
		let lastDate: Date | null = null
		const array: ReactNode[] = []
		let sectionArray: IEvent[] = []

		sortedEvents.forEach((e) => {
			const nextDate = new Date(e.dateEventStart)
			if (!lastDate) {
				lastDate = nextDate

				array.push(<TableHeader key={getUniqueId()} date={nextDate} />)
				array.push(
					<TableItem key={getUniqueId()} {...e} onDetailsOpen={onDetailsOpen} />
				)
				sectionArray.push(e)

				return
			}

			const day = lastDate.getDay()

			if (day !== nextDate.getDay()) {
				lastDate = nextDate
				array.push(
					...sectionArray
						.sort((a, b) => +a.time.split(':')[0] - +b.time.split(':')[0])
						.map((event) => (
							<TableItem {...event} key={e.id} onDetailsOpen={onDetailsOpen} />
						))
				)
				sectionArray = [e]

				array.push(<TableHeader key={getUniqueId()} date={nextDate} />)

				return
			}

			array.push(<TableItem {...e} key={e.id} onDetailsOpen={onDetailsOpen} />)
		})

		return array
	}, [sortedEvents])

	return (
		<ScheduleTableComponent
			events={events}
			onChange={onChange}
			dateStringAfter={dateStringAfter}
			currentDate={currentDate}
			dateStringLater={dateStringLater}
			mappedItems={mappedItems}
		/>
	)
}

export default ScheduleTable
