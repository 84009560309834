import React from 'react'
import { useNavigate } from 'react-router-dom'
import cl from 'components/DownloadInstruction/style.module.scss'
// @ts-ignore
import videoSrc from 'components/DownloadInstruction/AndroidInstructions/video/help-video.webm'
import appleShareSrc from 'components/DownloadInstruction/IOSInstructions/images/apple-share.svg'
import { Button } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import classNames from 'classnames'

const AndroidInstructions = () => {
	const navigate = useNavigate()

	const onConfirm = () => navigate(-1)

	return (
		<div>
			<div className={cl.videoContainer}>
				{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
				<video loop autoPlay className={classNames(cl.video, cl.android)} muted>
					<source src={videoSrc} type="video/mp4" />
				</video>
			</div>

			<div className={cl.container}>
				<div className={cl.text}>
					<ol>
						<li>
							Нажмите кнопку в правом верхнем углу <span>с тремя точками</span>{' '}
							- откроется меню, как на картинке;
						</li>
						<li>
							В конце списка выберите пункт <span>Установить приложение</span>.
						</li>
					</ol>
				</div>
				<div className={cl.text}>
					<p>
						Стабильная работа приложения гарантирована и протестирована в
						браузере Chrome. 
					</p>
				</div>

				<Button
					styleTypes={[ButtonStyles.ROUND, ButtonStyles.PRIMARY]}
					onClick={onConfirm}
				>
					Понятно
				</Button>
			</div>
		</div>
	)
}

export default AndroidInstructions
