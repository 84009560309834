import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import systemSlice from 'store/slices/system'
import { SchoolSliceState } from 'store/slices/school/types'
import { CheckInviteStudentCodeParams } from 'API/CourseService/types'
import courseService from 'API/CourseService'

const checkInviteStudentCode = createAsyncThunk(
	'school/checkInviteStudentCodeExtra',
	async (
		params: CheckInviteStudentCodeParams,
		{ rejectWithValue, dispatch }
	) => {
		try {
			await courseService.checkInviteStudentCode(params)

			return params
		} catch ({ message }) {
			dispatch(systemSlice.actions.pushError({ message: message as string }))

			return rejectWithValue({ message })
		}
	}
)

export const checkInviteStudentCodeExtra = {
	[checkInviteStudentCode.fulfilled.type]: (state: SchoolSliceState) => {
		state.meta.isLoading = false
		state.meta.error = ''
	},
	[checkInviteStudentCode.pending.type]: (state: SchoolSliceState) => {
		state.meta.isLoading = true
		state.meta.error = ''
	},
	[checkInviteStudentCode.rejected.type]: (
		state: SchoolSliceState,
		action: PayloadAction<string>
	) => {
		state.meta.isLoading = false
		state.meta.error = action.payload
	}
}

export default checkInviteStudentCode
