import React, { FC } from 'react'
import { Pagination as AntdPagination } from 'antd'

import cl from './pagination.module.scss'

interface PaginationProps {
	onChange: (page: number, pageSize: number) => void
	total: number
	defaultCurrent?: number
	defaultPageSize?: number
	showSizeChanger?: boolean
	className?: string
	getLimit?: (limit: number) => void
	pageSizeOptions?: number[]
	current?: number
}

export const Pagination: FC<PaginationProps> = ({
	onChange,
	total,
	defaultCurrent,
	defaultPageSize = 12,
	showSizeChanger,
	getLimit,
	pageSizeOptions,
	className,
	current
}) => (
	<div className={`${cl.paginationWrapper} ${className}`}>
		{current ? (
			<AntdPagination
				onChange={onChange}
				total={total}
				defaultCurrent={defaultCurrent}
				defaultPageSize={defaultPageSize}
				showSizeChanger={showSizeChanger}
				pageSizeOptions={pageSizeOptions}
				onShowSizeChange={(_, size) => getLimit && getLimit(size)}
				current={current}
			/>
		) : (
			<AntdPagination
				onChange={onChange}
				total={total}
				defaultCurrent={defaultCurrent}
				defaultPageSize={defaultPageSize}
				showSizeChanger={showSizeChanger}
				pageSizeOptions={pageSizeOptions}
				onShowSizeChange={(_, size) => getLimit && getLimit(size)}
			/>
		)}
	</div>
)
