import React, { FC } from 'react'
import {
	EventGroupTime,
	TimeLineContainerProps
} from 'containers/Timetable/Tabs/WeekTab/TimeLine'
import TimeLineRow from 'containers/Timetable/Tabs/WeekTab/TimeLine/TimeLineRow'

interface TimeLineComponentProps
	extends Omit<
		TimeLineContainerProps,
		'control' | 'mappedWeekDays' | 'getEventsByDays'
	> {
	grouppedEventsByTime: EventGroupTime[]
}

const TimeLine: FC<TimeLineComponentProps> = ({
	grouppedEventsByTime,
	hoverIndex,
	onHoverIndexChange,
	onDetailsOpen
}) => (
	<>
		{grouppedEventsByTime.map((group) => (
			<TimeLineRow
				{...group}
				key={group.id}
				hoverIndex={hoverIndex}
				onHoverIndexChange={onHoverIndexChange}
				onDetailsOpen={onDetailsOpen}
			/>
		))}
	</>
)

export default TimeLine
