import { ResponseEntityId } from 'types'

export interface CourseService {
	inviteStudent: InviteStudent
	checkInviteStudentCode: CheckInviteStudentCode
}

export interface CheckInviteStudentCodeParams {
	inviteCode: string
}

export interface InviteStudentParams {
	email: string
	courseId: ResponseEntityId
}
type InviteStudent = (params: InviteStudentParams) => Promise<void>
type CheckInviteStudentCode = (
	params: CheckInviteStudentCodeParams
) => Promise<void>

export enum CourseApiRoutes {
	INVITE_USER = 'courses/invite-student',
	CHECK_INVITE_CODE = 'courses/invite-student-check-code'
}
