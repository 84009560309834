import React, { FC, useState } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFieldArrayRemove,
	UseFormGetValues,
	UseFormRegister
} from 'react-hook-form'

import {
	CreateQuestionFormState,
	QuestionTabs
} from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import CreateQuestionForm from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/CreateQuestionForm'
import { QuestionsFormTabState } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm'
import QuestionsTabs from './Tabs'
import cl from './style.module.scss'

export interface CreateQuestionsFormProps {
	errors: FieldErrors<CreateQuestionFormState>
	onChange: (name: FieldPath<CreateQuestionFormState>) => (value: any) => void
	onSubmit: () => void
	control: Control<CreateQuestionFormState>
	remove: UseFieldArrayRemove
	tabsList: QuestionTabs[]
	selectedQuestionTabId: QuestionTabs['id']
	onSelectTab: (tabId: QuestionTabs['id']) => void
	fields: Record<'id', string>[]
	getValues: UseFormGetValues<QuestionsFormTabState>
	register: UseFormRegister<QuestionsFormTabState>
	onOpenCanceledChange: () => void
}

const CreateQuestions: FC<CreateQuestionsFormProps> = ({
	errors,
	onChange,
	control,
	onSubmit,
	remove,
	tabsList,
	selectedQuestionTabId,
	onSelectTab,
	fields,
	getValues,
	register,
	onOpenCanceledChange
}) => {
	const [questionMobileModalIsOpen, setQuestionMobileModalIsOpen] =
		useState(false)
	const questionMobileModalOpenHandler = () => {
		setQuestionMobileModalIsOpen(true)
	}
	const questionMobileModalCloseHandler = () => {
		setQuestionMobileModalIsOpen(false)
	}
	return (
		<div className={cl.createQuestionsContainer}>
			<QuestionsTabs
				openModal={questionMobileModalOpenHandler}
				selectedTabId={selectedQuestionTabId}
				tabs={tabsList}
				onSelectTab={onSelectTab}
				remove={remove}
			/>
			{/* TODO refactor костыль */}
			{fields.reduce(
				(acc, field, fieldIndex) =>
					+fieldIndex === +selectedQuestionTabId ? (
						<CreateQuestionForm
							onOpenCanceledChange={onOpenCanceledChange}
							questionMobileModalIsOpen={questionMobileModalIsOpen}
							closeModal={questionMobileModalCloseHandler}
							register={register}
							getValues={getValues}
							key={field.id}
							errors={errors}
							onChange={onChange}
							onSubmit={onSubmit}
							control={control}
							name={`questions.${fieldIndex}`}
						/>
					) : (
						acc
					),
				<></>
			)}
		</div>
	)
}

export default CreateQuestions
