import React, { FC, useCallback, useMemo } from 'react'
import ReminderItemComponent from 'components/Courses/Tabs/Content/NotificationsForm/ReminderForm/ReminderItem'
import {
	NotificationsReminderFormValues,
	ReminderItemValue,
	TimeMeasure
} from 'containers/Courses/Tabs/Content/NotificationsForm/index'
import { Control, FieldPath, FieldPathValue, useWatch } from 'react-hook-form'
import { IOption } from 'UI/Select/types'

export interface ReminderItemContainerProps extends ReminderItemValue {
	control: Control<NotificationsReminderFormValues>
	onChange: (
		name: FieldPath<NotificationsReminderFormValues>
	) => (
		value: FieldPathValue<NotificationsReminderFormValues, typeof name>
	) => void
	index: number
}

const ReminderItem: FC<ReminderItemContainerProps> = ({
	id,
	inNumber,
	timeMeasure,
	index,
	control,
	onChange
}) => {
	const reminderValue = useWatch({
		control,
		name: 'reminderDelaysDaysList'
	})

	const reminderOptions = useMemo<IOption[]>(
		() => [
			{
				value: TimeMeasure.DAYS,
				text: 'Дней'
			},
			{
				value: TimeMeasure.WEEKS,
				text: 'Недель'
			},
			{
				value: TimeMeasure.MONTHS,
				text: 'Месяцев'
			}
		],
		[]
	)

	const onRemoveReminderItem = useCallback(() => {
		onChange('reminderDelaysDaysList')(reminderValue.filter((v) => v.id !== id))
	}, [reminderValue, id])

	return (
		<ReminderItemComponent
			id={id}
			inNumber={inNumber}
			timeMeasure={timeMeasure}
			control={control}
			onChange={onChange}
			onRemoveReminderItem={onRemoveReminderItem}
			reminderOptions={reminderOptions}
			index={index}
		/>
	)
}

export default ReminderItem
