import { PushService } from 'API'
import { isRunningStandalone, urlB64ToUint8Array } from 'helpers'
import { useEffect } from 'react'
import { useAppSelector } from './redux'

const usePushNotifications = () => {
	const { isAuth } = useAppSelector((state) => state.system)

	useEffect(() => {
		if (!isAuth) return

		const requestPermission = () => Notification.requestPermission()

		const sendSubscription = async (sub: PushSubscription) => {
			await PushService.register(sub).catch(console.log)
		}

		const subscribe = async () => {
			if ((await requestPermission()) === 'granted' && isRunningStandalone()) {
				navigator.serviceWorker.ready.then((reg) => {
					reg.pushManager
						.subscribe({
							userVisibleOnly: true,
							applicationServerKey: urlB64ToUint8Array(
								process.env.REACT_APP_PUBLIC_VAPID_KEY as string
							)
						})
						.then(sendSubscription)
				})
			}
		}

		subscribe()
	}, [isAuth])
}

export default usePushNotifications
