import React, { FC } from 'react'
import { PublicationSucceededModalContainerProps } from 'containers/Courses/Entities/Events/Create/PublicationSucceededModal'
import { Button, Modal } from 'UI'
import { ModalSize } from 'UI/Modal/types'

import { ButtonStyles } from 'UI/Button/types'
import cl from './style.module.scss'

interface PublicationSucceededModalComponentProps
	extends Omit<PublicationSucceededModalContainerProps, 'publicatedEventId'> {
	onEditEvent: () => void
	onGoToTimeTable: () => void
}

const PublicationSucceededModal: FC<
	PublicationSucceededModalComponentProps
> = ({ onGoToTimeTable, onEditEvent, isOpen, onClose }) => (
	<Modal
		isOpen={isOpen}
		styleTypes={[ModalSize.BIG]}
		title="Публикация мероприятия"
		onClose={onClose}
		description="Мероприятие успешно опубликовано!"
	>
		<div className={cl.buttons}>
			<Button onClick={onEditEvent} styleTypes={[ButtonStyles.OUTLINE_PRIMARY]}>
				Редактировать мероприятие
			</Button>
			<Button onClick={onGoToTimeTable} styleTypes={[ButtonStyles.PRIMARY]}>
				Перейти к расписанию
			</Button>
		</div>
	</Modal>
)

export default PublicationSucceededModal
