import React, { useEffect, useMemo } from 'react'

import { useParams } from 'react-router-dom'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import taskQuery from 'store/queries/task.query'
import TaskView from 'containers/Courses/Entities/Tasks/View'
import courseQuery from 'store/queries/course.query'
import { useMatchMedia } from 'hooks'
import { useAppSelector } from 'hooks/redux'

const TaskViewPage = () => {
	const { courseId, taskId } = useParams()

	if (!(courseId && taskId)) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	const [triggerTaskQuery, { data: taskInfo }] =
		taskQuery.endpoints.getTask.useLazyQuery()

	const [triggerTaskAnswersAttempts, { data: taskAnswersAttempts }] =
		taskQuery.endpoints.getUserTasks.useLazyQuery()

	const [triggerCourseQuery, { data: courseInfo }] =
		courseQuery.endpoints.getCourse.useLazyQuery()
	const [triggerGetSections, { data: sectionsInfo }] =
		courseQuery.endpoints.getCourseSections.useLazyQuery()
	const { currentRole } = useAppSelector((state) => state.system)

	const { isMobile } = useMatchMedia()

	useEffect(() => {
		if (!taskId || !courseId) {
			return
		}
		triggerTaskQuery({ taskId: Number(taskId), userRole: currentRole })
		triggerCourseQuery(Number(courseId))
		triggerTaskAnswersAttempts({ taskId: Number(taskId) })
	}, [taskId, courseId])

	useEffect(() => {
		if (!courseInfo) return

		triggerGetSections({ sectionsIdList: courseInfo?.data.sectionsIdList })
	}, [courseInfo])

	const mappedItems = useMemo(() => {
		if (!sectionsInfo) return undefined

		return sectionsInfo.data.map((i) => i.items).flat()
	}, [sectionsInfo])

	if (!taskInfo || !courseInfo || !taskAnswersAttempts) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<TaskView
			taskAnswersAttempts={taskAnswersAttempts.data}
			courseId={Number(courseId)}
			communicationsInfo={courseInfo.data.communicationsSettings}
			task={taskInfo.data}
			mappedItems={mappedItems}
			isMobile={isMobile}
		/>
	)
}

export default TaskViewPage
