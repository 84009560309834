import React from 'react'

import { EventItemContainerProps } from 'containers/NotificationsSettings/NotificationSettingsTable/EventItem'
import { Checkbox } from 'UI'
import cl from './style.module.scss'

interface EventItemComponentProps
	extends Omit<EventItemContainerProps, 'email' | 'push' | 'courseSettings'> {
	isMobile: boolean
}

const CourseEventItem: React.FC<EventItemComponentProps> = ({
	text,
	control,
	index,
	disabled,
	onChange,
	isMobile
}) => (
	<>
		{!isMobile ? (
			<>
				<span>{text}</span>
				<Checkbox
					control={control}
					onChange={onChange(`events.${index}.teacher`)}
					name={`events.${index}.teacher`}
					disabled={disabled}
					labelTitle=""
				/>
				<Checkbox
					control={control}
					onChange={onChange(`events.${index}.curator`)}
					name={`events.${index}.curator`}
					disabled={disabled}
					labelTitle=""
				/>
				<Checkbox
					control={control}
					onChange={onChange(`events.${index}.student`)}
					name={`events.${index}.student`}
					disabled={disabled}
					labelTitle=""
				/>
			</>
		) : (
			<>
				<div className={cl.item}>
					<div>
						<span>{text}</span>
					</div>

					<div className={cl.eventItem}>
						<span>Преподаватель</span>
						<Checkbox
							control={control}
							onChange={onChange(`events.${index}.teacher`)}
							name={`events.${index}.teacher`}
							disabled={disabled}
							labelTitle=""
						/>
					</div>

					<div className={cl.eventItem}>
						<span>Куратор</span>
						<Checkbox
							control={control}
							onChange={onChange(`events.${index}.curator`)}
							name={`events.${index}.curator`}
							disabled={disabled}
							labelTitle=""
						/>
					</div>

					<div className={cl.eventItem}>
						<span>Студент</span>
						<Checkbox
							control={control}
							onChange={onChange(`events.${index}.student`)}
							name={`events.${index}.student`}
							disabled={disabled}
							labelTitle=""
						/>
					</div>
				</div>
			</>
		)}
	</>
)

export default CourseEventItem
