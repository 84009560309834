import React, { FC } from 'react'
import QuestionsTestCheck from 'components/Courses/Entities/Tests/Check'
import { ResponseEntityId } from 'types'

export interface QuestionListCheckProps {
	testName: string
	courseName: string
	userAnswersIdList: string[]
	testQuestionsCount: number
	courseId: ResponseEntityId
	testId: ResponseEntityId
}

const QuestionListCheck: FC<QuestionListCheckProps> = ({
	testName,
	courseName,
	userAnswersIdList,
	testQuestionsCount,
	courseId,
	testId
}) => (
	<QuestionsTestCheck
		testId={testId}
		courseId={courseId}
		userAnswersIdList={userAnswersIdList}
		testName={testName}
		courseName={courseName}
		testQuestionsCount={testQuestionsCount}
	/>
)

export default QuestionListCheck
