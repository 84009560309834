import React, { FC, useEffect, useMemo } from 'react'
import MobileMenuComponent from 'components/MobileMenu'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'hooks/redux'
import HatIcon from 'components/MobileMenu/icons/EducationHatIcon'
import TimetableIcon from 'components/MobileMenu/icons/TimetableIcon'
import BellIcon from 'components/MobileMenu/icons/BellIcon'
import ProfileIcon from 'components/MobileMenu/icons/ProfileIcon'
import { getUniqueId } from 'helpers'
import { MobileMenuItems } from 'components/MobileMenu/types'

const MobileMenu: FC = () => {
	const { pathname } = useLocation()
	const { isFinalTestOngoing } = useAppSelector((state) => state.test)

	const mobileMenuItems = useMemo<MobileMenuItems[]>(
		() => [
			{
				id: getUniqueId(),
				labelText: 'Мои курсы',
				link: '/',
				Icon: HatIcon
			},
			{
				id: getUniqueId(),
				labelText: 'Расписание',
				link: '/timetable',
				Icon: TimetableIcon
			},
			{
				id: getUniqueId(),
				labelText: 'Уведомления',
				link: '/notifications',
				Icon: BellIcon
			},
			{
				id: getUniqueId(),
				labelText: 'Профиль',
				link: '/profile/user',
				Icon: ProfileIcon
			}
		],
		[]
	)

	return (
		<MobileMenuComponent
			currentRoute={pathname}
			mobileMenuItems={mobileMenuItems}
			isFinalTestOngoing={isFinalTestOngoing}
		/>
	)
}

export default MobileMenu
