import React, { FC } from 'react'

export interface IconProps {
	fill?: string
}

const BellIcon: FC<IconProps> = ({ fill }) => (
	<svg
		width="22"
		height="24"
		viewBox="0 0 22 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.25 23.6666C11.9725 23.6675 12.6774 23.4433 13.2666 23.0251C13.8558 22.6069 14.3001 22.0156 14.5377 21.3333H7.96233C8.19994 22.0156 8.64422 22.6069 9.23343 23.0251C9.82263 23.4433 10.5275 23.6675 11.25 23.6666ZM19.4167 15.0169V9.66658C19.4167 5.91342 16.8675 2.75175 13.4142 1.80092C13.0723 0.939919 12.237 0.333252 11.25 0.333252C10.263 0.333252 9.42767 0.939919 9.08583 1.80092C5.6325 2.75292 3.08333 5.91342 3.08333 9.66658V15.0169L1.09183 17.0084C0.983285 17.1166 0.897199 17.2451 0.838533 17.3867C0.779867 17.5283 0.749779 17.68 0.750001 17.8333V18.9999C0.750001 19.3093 0.872917 19.6061 1.09171 19.8249C1.3105 20.0437 1.60725 20.1666 1.91667 20.1666H20.5833C20.8928 20.1666 21.1895 20.0437 21.4083 19.8249C21.6271 19.6061 21.75 19.3093 21.75 18.9999V17.8333C21.7502 17.68 21.7201 17.5283 21.6615 17.3867C21.6028 17.2451 21.5167 17.1166 21.4082 17.0084L19.4167 15.0169Z"
			fill={fill || '#666666'}
		/>
	</svg>
)

export default BellIcon
