import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Link, Loader } from 'UI'
import { LinkStyles } from 'UI/Link/types'
import { ButtonSizes, ButtonStyles } from 'UI/Button/types'
import { LoaderStyles } from 'UI/Loader/types'
import courseQuery from 'store/queries/course.query'
import { useAppSelector } from 'hooks/redux'
import CoursesTabs from 'containers/Courses/Tabs/View/Wrapper.container'

import schoolHatImage from 'components/Courses/StudentRole/Content/images/school-hat.png'

import { CourseRole } from 'types'
import cl from './style.module.scss'

const CourseViewPage = () => {
	const navigate = useNavigate()
	const { currentRole } = useAppSelector((state) => state.system)
	const { courseId } = useParams()

	if (!courseId) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	const { data: courseInfo } = courseQuery.useGetCourseQuery(+courseId)

	if (!courseInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<div className={cl.container}>
			<div className={cl.back}>
				<Link styleTypes={[LinkStyles.GO_BACK]} href="/">
					Вернуться назад
				</Link>
			</div>
			<div className={cl.header}>
				<div className={cl.headerInfoView}>
					<div className={cl.courseCover}>
						<img
							src={courseInfo.data.coverUrl || schoolHatImage}
							alt="courseCover"
						/>
					</div>
					<h2>{courseInfo.data.name}</h2>
				</div>
				{currentRole !== CourseRole.CURATOR &&
					currentRole !== CourseRole.STUDENT && (
						<Button
							onClick={() => navigate(`/course/edit/${courseId}`)}
							styleTypes={[
								ButtonSizes.BIG,
								ButtonStyles.ROUND,
								ButtonStyles.DARK
							]}
						>
							Редактировать курс
						</Button>
					)}
			</div>
			<CoursesTabs courseInfo={courseInfo.data} />
		</div>
	)
}

export default CourseViewPage
