import { createApi } from '@reduxjs/toolkit/dist/query/react'

import { makeFetchBaseQueryWithTariffs } from 'store/makeFetchBaseQueryWithTariffs'
import { buildHeaders } from 'helpers/api'

import { Constants, ResponseEntityId, SchoolRole, CourseRole } from 'types'
import { ICourse, ICourseReview, CopyCourse, CourseReviewRequest } from 'types/models/course.model'
import { ISection } from 'containers/Courses/Tabs/Content/ContentForm/Table'
import { ICertificate } from 'types/models/certificate.model'
import {
	ChangeCourseAccessInfo,
	ChangeCourseCertificateInfo,
	ChangeCourse,
	CourseQueryApiRoutes,
	CoursesListFilter,
	CourseAddSection,
	CourseSortSectionItems,
	CourseSortSection,
	CourseInviteStudent,
	CourseDeleteStudent,
	CourseDeleteSection,
	CourseGetSections,
	CourseEditSection,
	CourseGetPassingResponse,
	CourseGetPassing,
	CreateReviewPayload,
	ChangeNotificationSettingsPayload,
	CreateCertificatePayload,
	GetCertificatePayload
} from './types'

const courseQuery = createApi({
	reducerPath: 'courseQuery',
	tagTypes: ['course'],
	baseQuery: makeFetchBaseQueryWithTariffs({
		baseUrl: `${Constants.API_BASE_URL}/${CourseQueryApiRoutes.BASE_URL}`
	}),
	endpoints: (build) => ({
		createCourse: build.mutation<undefined, FormData>({
			query: (body) => ({
				url: 'create',
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		changeCourseMainInfo: build.mutation<ResponseEntityId, ChangeCourse>({
			query: ({ courseId, body }) => ({
				url: `${courseId}/change/main`,
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		changeCourseCertificateInfo: build.mutation<
			ResponseEntityId,
			ChangeCourseCertificateInfo
		>({
			query: ({ courseId, certificateSettings }) => ({
				url: `${courseId}/change/certificate`,
				method: 'POST',
				body: certificateSettings,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		changeCourseAccessInfo: build.mutation<
			ResponseEntityId,
			ChangeCourseAccessInfo
		>({
			query: ({ courseId, teachersIdList, curatorsIdList }) => ({
				url: `${courseId}/change/employees`,
				method: 'POST',
				body: { teachersIdList, curatorsIdList },
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		addCourseSection: build.mutation<ResponseEntityId, CourseAddSection>({
			query: ({ courseId, name }) => ({
				url: `${courseId}/add-section`,
				method: 'POST',
				body: { name },
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		sortCourseSection: build.mutation<ResponseEntityId, CourseSortSection>({
			query: ({ sortItemsIdList }) => ({
				url: `section/sort-section`,
				method: 'POST',
				body: { sortItemsIdList },
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		sortCourseSectionItems: build.mutation<
			ResponseEntityId,
			CourseSortSectionItems
		>({
			query: ({ sectionId, sortItemsIdList }) => ({
				url: `/section/section-items-sort`,
				method: 'POST',
				body: { sortItemsIdList, sectionId },
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		deleteCourseSection: build.mutation<ResponseEntityId, CourseDeleteSection>({
			query: ({ courseId, sectionId }) => ({
				url: `${courseId}/section/${sectionId}`,
				method: 'DELETE',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		inviteCourseStudent: build.mutation<ResponseEntityId, CourseInviteStudent>({
			query: ({ courseId, email }) => ({
				url: `${courseId}/invite-student`,
				method: 'POST',
				body: email,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		checkInviteCode: build.mutation<ResponseEntityId, string>({
			query: (body) => ({
				url: 'invite-student-check-code',
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		deleteCourseStudent: build.mutation<ResponseEntityId, CourseDeleteStudent>({
			query: ({ courseId, studentId }) => ({
				url: `${courseId}/student/${studentId}`,
				method: 'DELETE',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		editCourseSection: build.mutation<undefined, CourseEditSection>({
			query: ({ sectionId, name }) => ({
				url: `change-section`,
				method: 'POST',
				body: { sectionId, name },
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		getCourse: build.query<{ data: ICourse }, ResponseEntityId | undefined>({
			query: (courseId) => ({
				url: `${courseId}`,
				params: { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
				method: 'GET',
				headers: buildHeaders({ needAuth: true })
			}),
			onQueryStarted(arg): Promise<void> | void {
				if (!arg) {
					throw new Error()
				}
			},
			providesTags: ['course']
		}),
		getCourseReviews: build.query<
			{ data: ICourseReview[], totalCount: number },
			CourseReviewRequest
		>({
			query: ({ courseId, limit }) => ({
				url: `${courseId}/get-review/`,
				method: 'GET',
				headers: buildHeaders({ needAuth: true }),
				params: { limit }
			}),
			onQueryStarted(arg): Promise<void> | void {
				if (!arg) {
					throw new Error()
				}
			},
			providesTags: ['course']
		}),
		getLessonsCount: build.query<{ lessonCount: number }, ResponseEntityId>({
			query: (courseId) => ({
				url: `${courseId}/get-lesson-count`,
				method: 'GET',
				headers: buildHeaders({ needAuth: true })
			}),
			providesTags: ['course']
		}),
		getUnverifiedAnswers: build.query<
			{ unverifiedAnswersCount: number },
			ResponseEntityId
		>({
			query: (courseId) => ({
				url: `${courseId}/get-number-unverified-answers`,
				method: 'GET',
				headers: buildHeaders({ needAuth: true })
			}),
			providesTags: ['course']
		}),
		getCourses: build.query<{ data: ICourse[] }, CoursesListFilter | undefined>(
			{
				query: (coursesId) => ({
					url: 'get',
					body: {
						...coursesId,
						timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
					},
					// params: {role: coursesId?.role},
					method: 'POST',
					headers: buildHeaders({ needAuth: true })
				}),
				providesTags: ['course']
			}
		),
		getCourseSections: build.query<{ data: ISection[] }, CourseGetSections>({
			query: ({ role = SchoolRole.ADMIN, ...body }) => ({
				url: 'get-sections',
				body: {
					role,
					...body
				},
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			}),
			providesTags: ['course']
		}),
		coursesArchive: build.mutation<undefined, ResponseEntityId>({
			query: (courseId) => ({
				url: `${courseId}/archive`,
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		coursesPublish: build.mutation<undefined, ResponseEntityId>({
			query: (courseId) => ({
				url: `${courseId}/publish`,
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		coursesUnPublish: build.mutation<undefined, ResponseEntityId>({
			query: (courseId) => ({
				url: `${courseId}/un-publish`,
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		copyCourse: build.mutation<undefined, CopyCourse>({
			query: ({ courseId, role }) => ({
				url: `${courseId}/create-copy`,
				method: 'POST',
				body: {
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					role
				},
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		courseGetPassing: build.query<
			{ data: CourseGetPassingResponse },
			CourseGetPassing
		>({
			query: ({ courseId, userId }) => ({
				url: `${courseId}/get-passing-level/user/${userId}`,
				method: 'GET',
				headers: buildHeaders({ needAuth: true })
			}),
			providesTags: ['course']
		}),
		startCoursePassing: build.mutation<void, CourseGetPassing>({
			query: ({ courseId, userId }) => ({
				url: `${courseId}/start-course-passing/user/${userId}`,
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		createReview: build.mutation<void, CreateReviewPayload>({
			query: ({ courseId, ...body }) => ({
				url: `${courseId}/create-review`,
				method: 'POST',
				body,
				headers: buildHeaders({ needAuth: true })
			})
		}),
		changeNotificationSettings: build.mutation<
			void,
			ChangeNotificationSettingsPayload
		>({
			query: ({ courseId, ...body }) => ({
				url: `${courseId}/change/notifications`,
				method: 'POST',
				body: body.notificationsSettings,
				headers: buildHeaders({ needAuth: true })
			}),
			invalidatesTags: ['course']
		}),
		createCertificate: build.mutation<void, CreateCertificatePayload>({
			query: ({ courseId }) => ({
				url: `${courseId}/create-certificate`,
				method: 'POST',
				headers: buildHeaders({ needAuth: true })
			})
		}),
		getCertificate: build.query<{ data: ICertificate }, GetCertificatePayload>({
			query: ({ certificateId }) => ({
				url: `get-certificate/${certificateId}`,
				method: 'GET',
				headers: buildHeaders({ needAuth: true })
			})
		}),
		checkReview: build.query<{ status: boolean }, number>({
			query: (courseId) => ({
				url: `${courseId}/check-review`,
				method: 'GET',
				headers: buildHeaders({ needAuth: true })
			})
		})
	})
})

export default courseQuery
