import { ResponseEntityId } from 'types'
import { UploadMedia } from '../kinescope.query/types'

export enum LessonQueryApiRoutes {
	BASE_URL = 'lessons'
}

export interface ChangeLesson {
	lessonId: ResponseEntityId
	body: FormData
}
export interface DeleteLesson {
	lessonId: ResponseEntityId
	sectionId: ResponseEntityId
}
export interface CreateLesson {
	sectionId: ResponseEntityId
	body: FormData
}
export interface CreateLessonResponse {
	data: CreateLessonData
}
interface CreateLessonData {
	lessonId: string
	projectId: string
	folderId: string
	token: string
}
export interface AddVideo {
	lessonId: ResponseEntityId
	body: AddVideoBody
}

export interface AddVideoBody {
	videoEmbedLink: string
	videoId: string
	videoTitle: string
}

export type GetDataForKinescopeResponse = Omit<
	UploadMedia,
	'body' | 'lessonId' | 'title' | 'fileName' | 'descr'
>

export interface PassedLesson {
	lessonId: ResponseEntityId
}

export interface GetLesson {
	lessonId: ResponseEntityId
}
