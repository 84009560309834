import React, { FC } from 'react'

import { TableHeaderContainerProps } from 'containers/Timetable/Tabs/ScheduleTab/ScheduleTable/TableHeader'
import cl from './style.module.scss'

interface TableHeaderComponentProps
	extends Omit<TableHeaderContainerProps, 'date'> {
	dayOfTheWeek: string
	day: number
	monthName: string
	year: number
}

const TableHeader: FC<TableHeaderComponentProps> = ({
	day,
	dayOfTheWeek,
	year,
	monthName
}) => (
	<div className={cl.header}>
		{dayOfTheWeek}, {day} {monthName} {year}
	</div>
)

export default TableHeader
