import { combineReducers, configureStore } from '@reduxjs/toolkit'

import {
	systemSlice,
	registrationSlice,
	passwordRecoverySlice,
	authorizationSlice,
	lessonSlice,
	taskSlice,
	courseSlice,
	kinescopeSlice,
	testSlice,
	tariffsSlice,
	userSlice
} from './slices'
import {
	userQuery,
	schoolQuery,
	courseQuery,
	lessonQuery,
	testQuery,
	taskQuery,
	kinescopeQuery,
	eventsQuery,
	selectsQuery,
	paymentsQuery
} from './queries'

const slicesReducers = {
	[systemSlice.name]: systemSlice.reducer,
	[registrationSlice.name]: registrationSlice.reducer,
	[passwordRecoverySlice.name]: passwordRecoverySlice.reducer,
	[authorizationSlice.name]: authorizationSlice.reducer,
	[lessonSlice.name]: lessonSlice.reducer,
	[taskSlice.name]: taskSlice.reducer,
	[courseSlice.name]: courseSlice.reducer,
	[kinescopeSlice.name]: kinescopeSlice.reducer,
	[testSlice.name]: testSlice.reducer,
	[tariffsSlice.name]: tariffsSlice.reducer,
	[userSlice.name]: userSlice.reducer
}

const queriesReducers = {
	[userQuery.reducerPath]: userQuery.reducer,
	[schoolQuery.reducerPath]: schoolQuery.reducer,
	[courseQuery.reducerPath]: courseQuery.reducer,
	[lessonQuery.reducerPath]: lessonQuery.reducer,
	[taskQuery.reducerPath]: taskQuery.reducer,
	[testQuery.reducerPath]: testQuery.reducer,
	[kinescopeQuery.reducerPath]: kinescopeQuery.reducer,
	[eventsQuery.reducerPath]: eventsQuery.reducer,
	[selectsQuery.reducerPath]: selectsQuery.reducer,
	[paymentsQuery.reducerPath]: paymentsQuery.reducer
}

const rootReducer = combineReducers({
	...slicesReducers,
	...queriesReducers
})

export const setupStore = () =>
	configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(
				userQuery.middleware,
				schoolQuery.middleware,
				courseQuery.middleware,
				lessonQuery.middleware,
				taskQuery.middleware,
				testQuery.middleware,
				kinescopeQuery.middleware,
				eventsQuery.middleware,
				selectsQuery.middleware,
				paymentsQuery.middleware
			)
	})

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
