import React, { FC, useCallback, useMemo, useState } from 'react'
import {
	Control,
	FieldPath,
	useFieldArray,
	UseFormGetValues,
	useWatch
} from 'react-hook-form'

import QuestionsListPreviewComponent from 'components/Courses/Entities/Tests/Preview/QuestionsListPreview'
import { TestFormState } from 'pages/Course/Test/Create.page'
import { GetMillisecondsFromTimeResult } from 'helpers/getMillisecondsFromTime'
import { QuestionTabs } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import { Question } from 'types/models/test.model'

export interface QuestionsListPreviewProps {
	getValues: UseFormGetValues<TestFormState>
	passLimitTime: GetMillisecondsFromTimeResult
	control: Control<any>
	onChange: (name: FieldPath<TestFormState>) => (value: any) => void
}

const QuestionsListPreview: FC<QuestionsListPreviewProps> = ({
	getValues,
	passLimitTime,
	control,
	onChange
}) => {
	const { fields } = useFieldArray({
		control,
		name: 'questions'
	})

	const [selectedQuestionTabId, setSelectedQuestionTabId] =
		useState<QuestionTabs['id']>(0)

	const selectTabHandler = useCallback((tabId) => {
		setSelectedQuestionTabId(tabId)
	}, [])

	const questionsList = useWatch({
		control,
		name: 'questions'
	}) as Question[]

	const tabsList = useMemo(
		(): QuestionTabs[] =>
			questionsList.map((question, fieldIndex) => ({
				id: fieldIndex,
				text: question.name
			})),
		[questionsList]
	)

	return (
		<QuestionsListPreviewComponent
			onChange={onChange}
			control={control}
			selectedQuestionTabId={selectedQuestionTabId}
			onSelectTab={selectTabHandler}
			tabsList={tabsList}
			fields={fields}
			passLimitTime={passLimitTime}
			getValues={getValues}
		/>
	)
}

export default QuestionsListPreview
