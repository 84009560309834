import React from 'react'

import {
	CheckPasswordRecoveryCodeLayout,
	CheckRegistrationCodeLayout,
	CheckInviteCodeLayout,
	CheckInviteStudentCodeLayout
} from 'layouts/check'

const CheckRoutesList = [
	{
		path: '/check',
		children: [
			{
				index: true,
				path: '/check/registration-code',
				element: <CheckRegistrationCodeLayout />
			},
			{
				path: '/check/password-recovery-code',
				element: <CheckPasswordRecoveryCodeLayout />
			},
			{ path: '/check/Invite-code', element: <CheckInviteCodeLayout /> },
			{
				path: '/check/Invite-student-code',
				element: <CheckInviteStudentCodeLayout />
			}
		]
	}
]

export default CheckRoutesList
