import { LessonFile } from 'types/models/lesson.model'

type FilterFileListInfoByFileName = (
	filesInfo: LessonFile[],
	fileName: string[] | string,
	urlCheck?: boolean
) => Promise<FileList>

const filterFileListInfoByFileName: FilterFileListInfoByFileName = async (
	filesInfo,
	fileName,
	urlCheck = true
) => {
	const dt = new DataTransfer()

	if (!filesInfo.length) {
		return dt.files
	}

	await Promise.all(
		filesInfo.map(async (fileInfo) => {
			if (urlCheck) {
				if (!fileInfo.url) {
					return false
				}
			}

			if (fileName.includes(fileInfo.title) || fileName === fileInfo.title) {
				return false
			}

			dt.items.add(new File([fileInfo.url ? fileInfo.url : ''], fileInfo.title))

			return true
		})
	)

	return dt.files
}

export default filterFileListInfoByFileName
