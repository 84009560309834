import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import QuestionListPassComponent, {
	TestPassFormState
} from 'components/Courses/Entities/Tests/View/QuestionsListPass/index'
import { ITest } from 'types/models/test.model'
import { QuestionTabs } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import { useFieldArray, useForm } from 'react-hook-form'
import { getMillisecondsFromTime } from 'helpers/getMillisecondsFromTime'
import { ITestAnswer } from 'store/queries/test.query/types'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { useActions } from 'hooks/redux'
import { DevTool } from '@hookform/devtools'

interface QuestionsListPassProps {
	test: ITest
	setIsQuestionsCheckMode: (_: boolean) => void
	userAnswers?: ITestAnswer[]
	userCurrentAttemptCheck: string
	setIsQuestionsPassMode?: (_: boolean) => void
}

const QuestionsListView: FC<QuestionsListPassProps> = ({
	test,
	setIsQuestionsCheckMode,
	userAnswers,
	userCurrentAttemptCheck,
	setIsQuestionsPassMode
}) => {
	const { pushError } = useActions((state) => state.system)
	const [correctAnswersAmount, setCorrectAnswersAmount] = useState(0)
	const [cancelled, setCancelled] = useState(false)

	const { hours, minutes, seconds } = getMillisecondsFromTime(test.passingTimeLimit.timeLimitSeconds)
	const testDuration = `${hours || '00'}:${minutes || '00'}:${seconds || '00'}`

	const onCancelText = useCallback(() => setCancelled(true), [setCancelled])

	const [selectedQuestionTabId, setSelectedQuestionTabId] =
		useState<QuestionTabs['id']>(0)

	const currentAttempt = useMemo(
		() => userAnswers?.find((a) => a.id.toString() === userCurrentAttemptCheck),
		[userAnswers, userCurrentAttemptCheck]
	)

	const [answerAttemptId, setAnswerAttemptId] = useState<number>()

	const selectTabHandler = useCallback((tabId) => {
		setSelectedQuestionTabId(tabId)
	}, [])

	const questionsList = test.questions

	const tabsList = useMemo(
		(): QuestionTabs[] =>
			questionsList.map((question, fieldIndex) => ({
				id: fieldIndex,
				text: question.name
			})),
		[questionsList, userCurrentAttemptCheck]
	)

	const {
		register,
		control,
		getValues: getFinalTestFormState
	} = useForm<TestPassFormState>()

	const { fields, append } = useFieldArray({ control, name: 'questionsPass' })

	register('questionsPass')

	useEffect(() => {
		setAnswerAttemptId(+userCurrentAttemptCheck)
	}, [test, userCurrentAttemptCheck])

	if (!answerAttemptId) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			<DevTool control={control} />
			<QuestionListPassComponent
				getFinalTestFormState={getFinalTestFormState}
				setIsQuestionsPassMode={setIsQuestionsCheckMode}
				answerAttemptId={currentAttempt?.id || 2}
				currentAttempt={currentAttempt}
				fields={fields}
				userAnswers={userAnswers}
				test={test}
				selectedQuestionTabId={selectedQuestionTabId}
				onSelectTab={selectTabHandler}
				tabsList={tabsList}
				correctAnswersAmount={correctAnswersAmount}
				setCorrectAnswersAmount={setCorrectAnswersAmount}
				viewMode
				cancelled={cancelled}
				onSubmit={onCancelText}
				setIsQuestionsPassingMode={setIsQuestionsPassMode}
				testDuration={testDuration}
			/>
		</>
	)
}

export default QuestionsListView
