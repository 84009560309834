import React, { FC } from 'react'
import classnames from 'classnames'
import { UseFieldArrayRemove } from 'react-hook-form'

import { ResponseEntityId } from 'types'
import { QuestionTabs } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/Wrapper.container'
import trashIcon from 'UI/Button/images/trash.svg'

import cl from '../style.module.scss'

interface TabProps {
	tab: QuestionTabs
	isActive: boolean
	onClick: (tabId: QuestionTabs['id']) => void
	onOpenDeleteModal: (questionId: ResponseEntityId, name: string) => void
}

const QuestionTab: FC<TabProps> = ({
	tab,
	isActive,
	onClick,
	onOpenDeleteModal
}) => (
	<div
		className={classnames([cl.tab], {
			[cl.tabSelected]: isActive
		})}
	>
		<p onClick={onClick.bind(null, tab.id)}>{tab.text}</p>
		<div
			onClick={() => onOpenDeleteModal(tab.id, tab.text)}
			className={cl.removeBtn}
		>
			<img src={trashIcon} alt="remove" />
		</div>
	</div>
)

export default QuestionTab
