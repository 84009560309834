import React, { useCallback, useState } from 'react'
import { FieldPath, useForm } from 'react-hook-form'

import { Link } from 'UI'
import { LinkStyles } from 'UI/Link/types'
import CoursesTabs from 'containers/Courses/Tabs/Create/Wrapper.container'
import { ICourseWithCertificateImages } from 'types/models/course.model'
import CancelChangeModal from 'pages/Course/modals/CancelChangeModal'

import cl from './style.module.scss'

export interface CourseFormState extends ICourseWithCertificateImages { }

const CourseCreatePage = () => {
	const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		watch,
		formState,
		control
	} = useForm<CourseFormState>()

	const changeHandler = useCallback(
		(name: FieldPath<CourseFormState>) => (value: any) => setValue(name, value),
		[]
	)
	return (
		<>
			<CancelChangeModal
				isOpen={cancelModalIsOpen}
				onClose={() => setCancelModalIsOpen(false)}
			/>
			<div className={cl.container}>
				<div className={cl.back}>
					<Link styleTypes={[LinkStyles.GO_BACK]} href="/">
						Вернуться назад
					</Link>
				</div>
				<div className={cl.header}>
					<div className={cl.headerInfo}>
						<h2>Новый курс</h2>
						<p>Описание курса</p>
					</div>
				</div>
				<CoursesTabs
					onOpenCanceledChange={() => setCancelModalIsOpen(true)}
					setValue={setValue}
					errors={formState.errors}
					onChange={changeHandler}
					control={control}
					register={register}
					watch={watch}
					handleSubmit={handleSubmit}
					getValues={getValues}
				/>
			</div>
		</>
	)
}

export default CourseCreatePage
