import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'

import 'dayjs/locale/ru'
import locale from 'antd/locale/ru_RU'

import { setupStore } from './store'
import './assets/index.scss'
import App from './App'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const store = setupStore()

ReactDOM.render(

	<ConfigProvider locale={locale}>
		<Provider store={store}>
			<App />
		</Provider>
	</ConfigProvider>
	,
	document.getElementById('root')
)

serviceWorkerRegistration.register()
