import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { DatePicker as DatePickerBaseComponent } from 'antd'
import classnames from 'classnames'
import dayjs from 'dayjs'

import { CalendarIcon } from './images'
import cl from './style.module.scss'

export enum DatePickerTypes {
	WEEK = 'week',
	MONTH = 'month',
	QUARTER = 'quarter',
	YEAR = 'year'
}

interface DatePickerProps {
	control: Control<any>
	name: string
	disabled?: boolean
	error?: string
	picker?: DatePickerTypes
	open?: boolean
	closePopup?: () => void
	className?: string
}

const DatePicker: FC<DatePickerProps> = ({
	control,
	name,
	disabled,
	error,
	picker,
	open,
	closePopup,
	className
}) => (
	// TODO выяснить почему Дата на 1 день раньше отправляется на бэк
	<Controller
		name={name}
		control={control}
		render={({ field }) => (
			<>
				<DatePickerBaseComponent
					disabled={disabled}
					format="DD.MM.YYYY"
					className={classnames([cl.datepickerContainer], className, {
						[cl.datepickerContainerValidateError]: error
					})}
					suffixIcon={<CalendarIcon />}
					allowClear={false}
					onChange={(date, asd) => {
						field.onChange(date)

						if (closePopup) {
							closePopup()
						}
					}}
					value={field.value && dayjs(field.value)}
					picker={picker}
					open={open}
				/>
			</>
		)}
	/>
)

export default DatePicker
