import React, { FC } from 'react'
import { ResponseEntityId } from 'types'
import { Button, Modal } from 'UI'
import cl from 'containers/Courses/Table/Modals/ConfirmUnPublish/style.module.scss'
import { ButtonStyles } from 'UI/Button/types'
import { ModalHeight } from 'UI/Modal/types'
import { useNavigate } from 'react-router-dom'

interface ConfirmEditCourseModalProps {
	onClose: () => void
	isOpen: boolean
	courseId: ResponseEntityId
}

const ConfirmEditCourseModal: FC<ConfirmEditCourseModalProps> = ({
	courseId,
	isOpen,
	onClose
}) => {
	const navigate = useNavigate()
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			styleTypes={[ModalHeight.SMALL]}
			title="Редактирование опубликованного курса"
			description="Внимание! Внесенние изменений в опубликованный курс повлияет на учебный процесс учеников, которые приобрели курс. Могут возникнуть ошибки"
		>
			<div className={cl.buttonsContainer}>
				<Button
					onClick={onClose}
					styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
				>
					Отмена
				</Button>
				<Button
					onClick={() => navigate(`/course/edit/${courseId}`)}
					styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
				>
					Редактировать курс
				</Button>
			</div>
		</Modal>
	)
}

export default ConfirmEditCourseModal
