import React, { FC, useCallback, useState, useEffect } from 'react'
import { Outlet, useBlocker } from 'react-router-dom'
import classnames from 'classnames'

import { useMatchMedia, useCheckOnline } from 'hooks'
import { RestrictionModal } from 'components/TariffRestrictionModals'

import { Header } from 'containers/Header'
import { AsideBarContext } from 'contexts/AsideBar.context'
import { AsideBar } from 'containers/AsideBar'
import { ThanksForRegistrationModal } from 'components/Auth/modals'
import { useAppSelector } from 'hooks/redux'
import AlertWrapper from 'containers/AlertWrapper'
import MobileMenu from 'containers/MobileMenu'

import cl from './style.module.scss'

const UserLayout: FC = () => {
	const [asideIsOpen, setAsideIsOpen] = useState(true)
	const { haveRegistrationCongratulations } = useAppSelector(
		(state) => state.system
	)
	const { isMobile } = useMatchMedia()
	const { online } = useCheckOnline()
	const { isFinalTestOngoing } = useAppSelector((state) => state.test)

	const containerStyles = classnames(cl.userContainer, [
		{
			[cl.asideIsOpen]: asideIsOpen && !isMobile
		}
	])

	useBlocker(!online && isMobile)

	const asideToggleHandler = useCallback(() => {
		if (!isMobile) {
			return
		}

		setAsideIsOpen(false)
	}, [isMobile])

	useEffect(() => {
		if (isFinalTestOngoing) {
			setAsideIsOpen(false && !isMobile)
		} else {
			setAsideIsOpen(true && !isMobile)
		}
	}, [isFinalTestOngoing])

	return (
		<>
			<AlertWrapper />
			<RestrictionModal />
			<AsideBarContext.Provider
				value={{
					setAsideIsOpen,
					asideIsOpen,
					asideOnToggle: asideToggleHandler
				}}
			>
				<Header />
				<div className={containerStyles}>
					<AsideBar />
					<MobileMenu />
					<div className={cl.contentContainer}>
						<Outlet />
					</div>
				</div>
			</AsideBarContext.Provider>
			<ThanksForRegistrationModal isOpen={haveRegistrationCongratulations} />
		</>
	)
}

export default UserLayout
