import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import CreationCancelledModalComponent from 'components/Courses/Entities/Events/Create/CreationCancelledModal'

export interface CreationCancelledModalContainerProps {
	isOpen: boolean
	onClose: () => void
}

const CreationCancelledModal: FC<CreationCancelledModalContainerProps> = ({
	onClose,
	isOpen
}) => {
	const navigate = useNavigate()

	const onQuit = useCallback(() => {
		navigate('/timetable')
	}, [navigate])

	return (
		<CreationCancelledModalComponent
			isOpen={isOpen}
			onCancelQuitting={onClose}
			onQuit={onQuit}
		/>
	)
}

export default CreationCancelledModal
