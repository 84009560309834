import React, { FC, useCallback } from 'react'

import QuestionsFormTabComponents from 'components/Courses/Entities/Tests/Tabs/Content/QuestionsForm'
import {
	Control,
	FieldErrors,
	FieldPath,
	useFieldArray,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister
} from 'react-hook-form'
import { AnswerType, ITest, Question } from 'types/models/test.model'
import { getUniqueId } from 'helpers'
import testQuery from 'store/queries/test.query'
import { useActions } from 'hooks/redux'
import {
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'

export type QuestionsFormTabState = Pick<ITest, 'questions' | 'id'>

export interface QuestionsFormTabProps {
	register: UseFormRegister<QuestionsFormTabState>
	control: Control<QuestionsFormTabState>
	onChange: (name: FieldPath<QuestionsFormTabState>) => (value: any) => void
	errors: FieldErrors<QuestionsFormTabState>
	handleSubmit: UseFormHandleSubmit<QuestionsFormTabState>
	getValues: UseFormGetValues<QuestionsFormTabState>
	onOpenCanceledChange: () => void
}

export const questionsHaveUnfilledCorrectAnswers = (questions: Question[]) =>
	!questions.every(
		(q) =>
			q.correctAnswersIdList ||
			(Array.isArray(q.correctAnswersIdList) &&
				q.correctAnswersIdList.length > 0)
	)

const QuestionsFormTab: FC<QuestionsFormTabProps> = ({
	errors,
	control,
	register,
	onChange,
	handleSubmit,
	getValues,
	onOpenCanceledChange
}) => {
	const [changeTest] = testQuery.useChangeTestMutation()
	const { pushError, pushSuccess } = useActions((state) => state.system)

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'questions'
	})

	const appendHandler = useCallback(() => {
		append({
			id: +getUniqueId(),
			name: `Новый вопрос`,
			answers: [{ id: +getUniqueId(), value: '', comment: '' }],
			answerType: AnswerType.SINGLE,
			correctAnswersIdList: undefined
		})
	}, [])

	const submitHandler = useCallback(
		handleSubmit(async ({ questions }) => {
			if (questionsHaveUnfilledCorrectAnswers(questions)) {
				pushError({ message: ValidationErrorMessages.PICK_CORRECT_ANSWERS })
				return
			}
			const response = await changeTest({
				body: {
					questions
				},
				testId: +`${getValues('id')}`
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
		}),
		[handleSubmit]
	)

	return (
		<QuestionsFormTabComponents
			onOpenCanceledChange={onOpenCanceledChange}
			register={register}
			getValues={getValues}
			control={control}
			onAppend={appendHandler}
			fields={fields}
			errors={errors}
			onSubmit={submitHandler}
			onChange={onChange}
			remove={remove}
		/>
	)
}

export default QuestionsFormTab
