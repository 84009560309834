import React, { FC } from 'react'
import { Avatar } from 'UI'

import cl from './style.module.scss'

interface AnswerCreatorProps {
	firstName: string
	lastName: string
	photoUrl: string
	email: string
	noEmail?: boolean
	noName?: boolean
	hideAvatarMobile?: boolean
}

const AnswerCreator: FC<AnswerCreatorProps> = ({
	photoUrl,
	firstName,
	lastName,
	email,
	noEmail = false,
	noName = false,
	hideAvatarMobile
}) => (
	<div className={cl.profileInfo}>
		<Avatar lastName={lastName} firstName={firstName} photoUrl={photoUrl} classes={hideAvatarMobile && cl.hideAvatarMobile} />
		<div className={cl.initials}>
			{!noName && (
				<span>
					{firstName} {lastName}
				</span>
			)}
			{!noEmail && email && <span>{email}</span>}
		</div>
	</div>
)

export default AnswerCreator
