import React, { FC } from 'react'

import {
	TableCellsInfo,
	WeekTabContainerProps
} from 'containers/Timetable/Tabs/WeekTab'
import CurrentWeek from 'containers/Timetable/Tabs/WeekTab/CurrentWeek'
import WeekDays from 'containers/Timetable/Tabs/WeekTab/WeekDays'
import { WeekDay } from 'types/models/day-event.model/day-event.model'
import TimeLine from 'containers/Timetable/Tabs/WeekTab/TimeLine'
import { IEvent } from 'types/models/event.model'
import cl from './style.module.scss'

interface WeekTabComponentProps extends Omit<WeekTabContainerProps, 'control'> {
	mappedWeekDaysElements: TableCellsInfo[]
	mappedWeekDays: WeekDay[]
	getEventsByDays: (_: Date) => IEvent[]
	hoverIndex: number | null
	onHoverIndexChange: (_: number | null) => void
}

const WeekTab: FC<WeekTabComponentProps> = ({
	mappedWeekDaysElements,
	mappedWeekDays,
	getEventsByDays,
	hoverIndex,
	onHoverIndexChange,
	onDetailsOpen
}) => (
	<div className={cl.weekTab}>
		<span />
		<WeekDays mappedWeekDays={mappedWeekDays || []} />
		<CurrentWeek
			mappedWeekDaysElements={mappedWeekDaysElements}
			onHoverIndexChange={onHoverIndexChange}
			hoverIndex={hoverIndex}
			onDetailsOpen={onDetailsOpen}
		/>
		<TimeLine
			getEventsByDays={getEventsByDays}
			mappedWeekDays={mappedWeekDays || []}
			hoverIndex={hoverIndex}
			onHoverIndexChange={onHoverIndexChange}
			onDetailsOpen={onDetailsOpen}
		/>
	</div>
)

export default WeekTab
