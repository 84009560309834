import React from 'react'
import { useRoutes, createBrowserRouter } from 'react-router-dom'

import {
	CourseCreatePage,
	CourseEditPage,
	LessonCreatePage,
	TaskCreatePage,
	TestCreatePage,
	TaskEditPage,
	LessonEditPage,
	TestEditPage,
	CourseViewPage,
	TaskViewAnswerPage
} from 'pages/Course'
import DownloadInstructionPage from 'pages/DownloadInstruction'
import { NotificationsPage, TimetablePage, TariffsPage } from 'pages/Common'
import { UserProfileEditPage, UserProfileViewPage } from 'pages/User'
import {
	SchoolUsersListPage,
	SchoolCoursesListPage,
	SchoolProfileEditPage
} from 'pages/School'
import { UserLayout } from 'layouts'
import TaskViewPage from 'pages/Course/Task/View.page'
import LessonViewPage from 'pages/Course/Lesson/View.page'
import TestViewPage from 'pages/Course/Test/View.page'
import AnswerAttemptViewPage from 'pages/Course/Test/AnswerAttemptView'
import EventEditPage from 'pages/Course/Event/Edit.page'
import EventCreatePage from 'pages/Course/Event/Create.page'
import GetCertificatePage from 'pages/Course/Certificate/Get.page'
import checkRoutesList from './check.routes.list'

const SchoolAdminRoutes = () =>
	createBrowserRouter([
		{
			path: '/',
			element: <UserLayout />,
			children: [
				{
					index: true,
					element: <SchoolCoursesListPage />
				},
				{
					path: '/course',
					children: [
						{ path: '/course/create', element: <CourseCreatePage /> },
						{ path: '/course/edit/:courseId', element: <CourseEditPage /> },
						{ path: '/course/view/:courseId', element: <CourseViewPage /> },
						{
							path: '/course/:courseId/lesson/section/:sectionId/create',
							element: <LessonCreatePage />
						},
						{
							path: '/course/:courseId/lesson/edit/:lessonId',
							element: <LessonEditPage />
						},
						{
							path: '/course/:courseId/lesson/view/:lessonId',
							element: <LessonViewPage />
						},
						{
							path: '/course/:courseId/test/section/:sectionId/create',
							element: <TestCreatePage />
						},
						{
							path: '/course/:courseId/test/edit/:testId',
							element: <TestEditPage />
						},
						{
							path: '/course/:courseId/test/view/:testId',
							element: <TestViewPage />
						},
						{
							path: '/course/:courseId/test/:testId/answer/attempt/view/:answerId',
							element: <AnswerAttemptViewPage />
						},
						{
							path: '/course/:courseId/task/section/:sectionId/create',
							element: <TaskCreatePage />
						},
						{
							path: '/course/:courseId/task/edit/:taskId',
							element: <TaskEditPage />
						},
						{
							path: '/course/:courseId/task/view/:taskId',
							element: <TaskViewPage />
						},
						{
							path: '/course/:courseId/task/:taskId/answer/attempt/view/:answerId',
							element: <TaskViewAnswerPage />
						},
						{
							path: '/course/:courseId/event/section/:sectionId/create',
							element: <EventCreatePage />
						},
						{
							path: '/course/event/create',
							element: <EventCreatePage />
						},
						{
							path: '/course/event/edit/:eventId',
							element: <EventEditPage />
						},
						{
							path: '/course/:courseId/get-certificate',
							element: <GetCertificatePage />
						}
					]
				},
				{
					path: '/help',
					children: [
						{
							path: '/help/download-app',
							element: <DownloadInstructionPage />
						}
					]
				},
				{
					path: '/user',
					children: [
						{
							path: '/user/list/*',
							element: <SchoolUsersListPage />
						},
						{
							path: '/user/:userId',
							element: <UserProfileViewPage />
						}
					]
				},
				{
					path: '/profile',
					children: [
						{
							index: true,
							path: '/profile/user/*',
							element: <UserProfileEditPage />
						},
						{ path: '/profile/school/*', element: <SchoolProfileEditPage /> }
					]
				},
				{
					path: '/notifications',
					element: <NotificationsPage />
				},
				{
					path: '/timetable',
					element: <TimetablePage />
				},
				{
					path: '/tariffs',
					element: <TariffsPage />
				},
				...checkRoutesList
			]
		},
		{
			path: '*',
			element: <UserLayout />,
			children: [
				{
					index: true,
					element: <SchoolCoursesListPage />
				}
			]
		}
	])

export default SchoolAdminRoutes
