import { GetCertificateContainerProps } from 'containers/Courses/Entities/Certificate/Get'
import CreateReviewForm from 'containers/Courses/Entities/Certificate/Get/CreateReviewForm'
import React from 'react'
import { Control, FieldPath } from 'react-hook-form'

import cl from './style.module.scss'

interface GetCertificateComponentProps extends GetCertificateContainerProps {}

const GetCertificate: React.FC<GetCertificateComponentProps> = ({
	courseId
}) => (
	<div className={cl.container}>
		<h2 className={cl.title}>Отзыв о прохождении курса</h2>

		<CreateReviewForm courseId={courseId} />
	</div>
)

export default GetCertificate
