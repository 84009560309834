import React, { FC } from 'react'
import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ModalSize } from 'UI/Modal/types'

interface PaymentSuccessModalProps {
	isOpen: boolean
	onClose: () => void
}

const PaymentSuccessModal: FC<PaymentSuccessModalProps> = ({
	isOpen,
	onClose
}) => (
	<Modal
		styleTypes={[ModalSize.SMALL]}
		isOpen={isOpen}
		onClose={onClose}
		title="Оплата"
		description="Оплата прошла успешно"
	>
		<Button
			onClick={onClose}
			styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
		>
			Закрыть
		</Button>
	</Modal>
)

export default PaymentSuccessModal
