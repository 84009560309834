import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ResponseEntityId } from 'types'
import { ITask, ITaskAnswerResponse } from 'types/models/task.model'
import { TaskGetAnswersResponse } from 'store/queries/task.query/types'
import { AnswerSortValues } from 'containers/Courses/Entities/Tasks/TaskAnswersCheck/TaskAnswersCheck'
import { TaskSliceState } from './types'

const initialState: TaskSliceState = {
	task: {} as ITask<string>,
	taskAnswersInfo: [],
	taskCurrentAnswer: {} as ITaskAnswerResponse,
	creatorId: 0
}

const taskSlice = createSlice({
	name: 'task',
	initialState,
	reducers: {
		setTask: (state, { payload }: PayloadAction<ITask<string>>) => {
			state.task = { ...state.task, ...payload }
		},
		setCreatorId: (state, { payload }: PayloadAction<ResponseEntityId>) => {
			state.creatorId = payload
		},
		setCurrentTaskAnswer: (
			state,
			{ payload }: PayloadAction<ITaskAnswerResponse>
		) => {
			state.taskCurrentAnswer = payload
		},
		setTaskAnswerData: (
			state,
			{ payload }: PayloadAction<TaskGetAnswersResponse>
		) => {
			state.countPastStudents = payload.countPastStudents
			state.totalCountStudents = payload.totalCountStudents
			state.totalCount = payload.totalCount
			state.taskAnswersInfo = payload.data
		},
		showNextTask: (state, { payload }: PayloadAction<boolean>) => {
			state.showNextTask = payload
		},
		taskCurrentSort: (
			state,
			{ payload }: PayloadAction<AnswerSortValues | undefined>
		) => {
			state.taskCurrentSort = payload
		}
	}
})

export default taskSlice
