import React, { FC, useEffect, useMemo, useState, Dispatch } from 'react'
import classnames from 'classnames'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch
} from 'react-hook-form'
import { DevTool } from '@hookform/devtools'

import { Tabs } from 'UI'
import { ITab } from 'UI/Tabs/types'
import { getUniqueId } from 'helpers'
import { TestFormState } from 'pages/Course/Test/Create.page'
import { ITest } from 'types/models/test.model'
import MainFormTab, { MainFormTabState } from '../Content/MainForm'
import QuestionsFormTab, {
	QuestionsFormTabState
} from '../Content/QuestionsForm'
import LimitationsFormTab, {
	LimitationsFormTabState
} from '../Content/LimitationsForm'

import cl from '../style.module.scss'

interface TestsTabsProps {
	isTestPreview?: boolean
	isQuestionsListPreview: boolean
	register: UseFormRegister<TestFormState>
	handleSubmit: UseFormHandleSubmit<TestFormState>
	setValue: UseFormSetValue<TestFormState>
	errors: FieldErrors<TestFormState>
	watch: UseFormWatch<TestFormState>
	control: Control<TestFormState>
	onChange: (name: FieldPath<TestFormState>) => (value: any) => void
	getValues: UseFormGetValues<TestFormState>
	testInfo: ITest
	onOpenCanceledChange: () => void
	setTestChangesSaved: Dispatch<React.SetStateAction<boolean>>
}

const TestsTabs: FC<TestsTabsProps> = ({
	isTestPreview = false,
	isQuestionsListPreview = false,
	register,
	handleSubmit,
	setValue,
	control,
	onChange,
	errors,
	watch,
	getValues,
	testInfo,
	onOpenCanceledChange,
	setTestChangesSaved
}) => {
	const tabsList = useMemo(
		(): ITab[] => [
			{ id: getUniqueId(), text: 'Основная информация' },
			{ id: getUniqueId(), text: 'Вопросы' },
			{ id: getUniqueId(), text: 'Ограничения' }
		],
		[]
	)
	const [selectedTabId, setSelectedTabId] = useState<ITab['id']>(tabsList[0].id)

	useEffect(() => {
		setValue('id', testInfo.id)
		setValue('name', testInfo.name)
		setValue('description', testInfo.description)
		setValue('type', testInfo.type)
		setValue('testResponsibilityStatus', testInfo.testResponsibilityStatus)
		setValue(
			'testResponsibilityAnswersStatus',
			testInfo.testResponsibilityAnswersStatus
		)
		setValue('passingTimeLimit', testInfo.passingTimeLimit)
		setValue('countAttempts', testInfo.countAttempts)
		setValue('passingScore', testInfo.passingScore)
		setValue('datePassStartSettings', testInfo.datePassStartSettings)
		setValue('datePassEndSettings', testInfo.datePassEndSettings)
		setValue('questions', testInfo.questions)
	}, [testInfo])

	return (
		<div className={cl.container}>
			<div
				style={{
					display: isTestPreview || isQuestionsListPreview ? 'none' : 'block'
				}}
				className={classnames([
					cl.tabsToggles,
					{
						[cl.tabsTogglesHidden]: isTestPreview || isQuestionsListPreview
					}
				])}
			>
				<Tabs
					value={selectedTabId}
					tabs={tabsList}
					onChange={setSelectedTabId}
				/>
			</div>
			<div
				style={{
					display: isTestPreview || isQuestionsListPreview ? 'none' : 'block'
				}}
				className={classnames([
					cl.tabsContent,
					{
						[cl.tabsContentHidden]: isTestPreview || isQuestionsListPreview
					}
				])}
			>
				{selectedTabId === tabsList[0].id && (
					<MainFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						isEditMode
						getValues={
							getValues as unknown as UseFormGetValues<MainFormTabState>
						}
						register={register as unknown as UseFormRegister<MainFormTabState>}
						control={control as unknown as Control<MainFormTabState>}
						onChange={onChange}
						errors={errors}
						handleSubmit={handleSubmit}
					/>
				)}
				{selectedTabId === tabsList[1].id && (
					<QuestionsFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						getValues={
							getValues as unknown as UseFormGetValues<QuestionsFormTabState>
						}
						register={
							register as unknown as UseFormRegister<QuestionsFormTabState>
						}
						onChange={onChange}
						errors={errors}
						handleSubmit={handleSubmit}
						control={control as unknown as Control<QuestionsFormTabState>}
					/>
				)}
				{selectedTabId === tabsList[2].id && (
					<LimitationsFormTab
						onOpenCanceledChange={onOpenCanceledChange}
						getValues={
							getValues as unknown as UseFormGetValues<LimitationsFormTabState>
						}
						watch={watch as unknown as UseFormWatch<LimitationsFormTabState>}
						register={
							register as unknown as UseFormRegister<LimitationsFormTabState>
						}
						control={control as unknown as Control<LimitationsFormTabState>}
						onChange={onChange}
						errors={errors}
						handleSubmit={handleSubmit}
						setTestChangesSaved={setTestChangesSaved}
					/>
				)}
				<DevTool control={control} />
			</div>
		</div>
	)
}

export default TestsTabs
