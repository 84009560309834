import React, { FC, useCallback, useEffect } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister
} from 'react-hook-form'

import MainFormTabComponents from 'components/Courses/Entities/Tests/Tabs/Content/MainForm'
import {
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import { TestFormState } from 'pages/Course/Test/Create.page'
import testQuery from 'store/queries/test.query'
import { useNavigate, useParams } from 'react-router-dom'
import { CreateTestResponse } from 'store/queries/test.query/types'
import { useActions, useAppDispatch } from 'hooks/redux'
import courseQuery from 'store/queries/course.query'

export type MainFormTabState = Pick<
	TestFormState,
	'id' | 'name' | 'description' | 'type'
>

interface MainFormTabProps {
	isEditMode?: boolean
	register: UseFormRegister<MainFormTabState>
	handleSubmit: UseFormHandleSubmit<MainFormTabState>
	errors: FieldErrors<MainFormTabState>
	control: Control<MainFormTabState>
	onChange: (name: FieldPath<MainFormTabState>) => (value: any) => void
	getValues: UseFormGetValues<MainFormTabState>
	onOpenCanceledChange: () => void
}

const MainFormTab: FC<MainFormTabProps> = ({
	handleSubmit,
	register,
	errors,
	control,
	isEditMode,
	onChange,
	getValues,
	onOpenCanceledChange
}) => {
	const { pushSuccess } = useActions((state) => state.system)

	const dispatch = useAppDispatch()

	const { courseId, sectionId } = useParams()

	const [createTest] = testQuery.useCreateTestMutation()
	const [changeTest] = testQuery.useChangeTestMutation()

	const navigate = useNavigate()

	useEffect(() => {
		register('name', {
			required: ValidationErrorMessages.EMPTY,
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('description', {
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('type', {
			required: ValidationErrorMessages.CHOOSE_AT_LEAST_ONE
		})
		return () => {
			register('name', {
				required: undefined,
				minLength: undefined,
				pattern: undefined
			})
			register('description', {
				minLength: undefined,
				pattern: undefined
			})
			register('type', {
				required: undefined
			})
		}
	}, [])

	const submitCreateHandler = useCallback(
		handleSubmit(async ({ name, type, description }) => {
			const response = await createTest({
				body: {
					name: name.trim(),
					type,
					description: description?.trim()
				},
				sectionId: +`${sectionId}`
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				return
			}
			dispatch(courseQuery.util.invalidateTags(['course']))
			const { data } = response as unknown as CreateTestResponse
			navigate(`/course/${courseId}/test/edit/${data.testId}`)
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
		}),
		[handleSubmit]
	)
	const submitEditHandler = useCallback(
		handleSubmit(async ({ name, description, type }) => {
			const response = await changeTest({
				body: {
					name: name.trim(),
					type,
					description: description.trim()
				},
				testId: +`${getValues('id')}`
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				return
			}
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
			dispatch(courseQuery.util.invalidateTags(['course']))
		}),
		[handleSubmit]
	)

	return (
		<MainFormTabComponents
			onOpenCanceledChange={onOpenCanceledChange}
			onSubmit={isEditMode ? submitEditHandler : submitCreateHandler}
			errors={errors}
			control={control}
			onChange={onChange}
		/>
	)
}

export default MainFormTab
