import { ValidationErrorMessages } from 'types'

interface State {
	paymentSuccessModalOpen: boolean
	paymentFailureModalOpen: boolean
}

export const tariffsInitialState: State = {
	paymentFailureModalOpen: false,
	paymentSuccessModalOpen: false
}

export enum ActionTypes {
	SET_SUCCESS_MODAL_OPEN = 'SET_SUCCESS_MODAL_OPEN',
	SET_SUCCESS_MODAL_CLOSED = 'SET_SUCCESS_MODAL_CLOSED',
	SET_FAILURE_MODAL_OPEN = 'SET_FAILURE_MODAL_OPEN',
	SET_FAILURE_MODAL_CLOSED = 'SET_FAILURE_MODAL_CLOSED'
}

interface ActionWithPayload<T, P = undefined> {
	type: T
	payload: P
}

interface Action<T> {
	type: T
}

interface SetSuccessModalOpenAction
	extends Action<ActionTypes.SET_SUCCESS_MODAL_OPEN> {}
interface SetSuccessModalClosedAction
	extends Action<ActionTypes.SET_SUCCESS_MODAL_CLOSED> {}
interface SetFailureModalOpenAction
	extends Action<ActionTypes.SET_FAILURE_MODAL_OPEN> {}
interface SetFailureModalClosedAction
	extends Action<ActionTypes.SET_FAILURE_MODAL_CLOSED> {}

type actions =
	| SetSuccessModalOpenAction
	| SetSuccessModalClosedAction
	| SetFailureModalOpenAction
	| SetFailureModalClosedAction

function tariffsReducer(state: State, action: actions): State {
	switch (action.type) {
		case ActionTypes.SET_SUCCESS_MODAL_OPEN:
			return { ...state, paymentSuccessModalOpen: true }
		case ActionTypes.SET_SUCCESS_MODAL_CLOSED:
			return { ...state, paymentSuccessModalOpen: false }
		case ActionTypes.SET_FAILURE_MODAL_OPEN:
			return {
				...state,
				paymentFailureModalOpen: true
			}
		case ActionTypes.SET_FAILURE_MODAL_CLOSED:
			return {
				...state,
				paymentFailureModalOpen: false
			}
		default:
			throw new Error(ValidationErrorMessages.INCORRECT)
	}
}

export default tariffsReducer
