import React, { FC } from 'react'
import { TaskAttemptsProps as TaskAttemptsContainerProps } from 'containers/Courses/Entities/Tasks/Attempts'
import { ITaskAnswerResponse } from 'types/models/task.model'
import classNames from 'classnames'
import AttemptItem from 'containers/Courses/Entities/Tasks/Attempts/item'
import cl from './style.module.scss'

interface TaskAttemptsProps extends Omit<TaskAttemptsContainerProps, 'taskId'> {
	answers: ITaskAnswerResponse[]
	passingScore: number
	viewMode?: boolean
	noPaddingMobile?: boolean
}

const TaskAttempts: FC<TaskAttemptsProps> = ({
	answers,
	countAttempts,
	passingScore,
	onChangeEditMode,
	answerEditMode,
	viewMode,
	noPaddingMobile
}) => (
	<div className={classNames(cl.container, noPaddingMobile && cl.noPaddingMobile)}>
		<h2>Предыдущие попытки</h2>
		{answers.length > 0 && (
			<div className={cl.answersList}>
				{answers.map((answer) => (
					<AttemptItem
						passingScore={passingScore}
						countAttempts={countAttempts}
						key={answer.id}
						answer={answer}
						onChangeEditMode={onChangeEditMode}
						answerEditMode={answerEditMode}
						viewMode={viewMode}
					/>
				))}
			</div>
		)}
	</div>
)

export default TaskAttempts
