import React, { FC, FormEventHandler } from 'react'
import { Button, DragAndDrop, Modal, RadioGroup, TextArea } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import AnswersList from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/AnswersList'

import {
	CreateQuestionFormProps as CreateQuestionFormContainerProps,
	CreateQuestionImageForm
} from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/CreateQuestionForm'
import { useMatchMedia } from 'hooks'
import { ModalSize } from 'UI/Modal/types'
import { AcceptTypes, ImagePreview } from 'UI/DragAndDrop/types'
import { Control } from 'react-hook-form'
import cl from './style.module.scss'

interface CreateQuestionFromProps
	extends Omit<CreateQuestionFormContainerProps, 'register' | 'onSubmit'> {
	previewPictures: ImagePreview[]
	onPicturesChange: (_: File[]) => void
	createControl: Control<CreateQuestionImageForm>
	onSubmit: FormEventHandler<HTMLFormElement>
	onDeleteImage: (urls: string[]) => void
}

const CreateQuestionForm: FC<CreateQuestionFromProps> = ({
	errors,
	onChange,
	control,
	name,
	onSubmit,
	getValues,
	questionMobileModalIsOpen,
	closeModal,
	onOpenCanceledChange,
	previewPictures,
	onPicturesChange,
	createControl,
	onDeleteImage
}) => {
	const { isTable } = useMatchMedia()
	return (
		<>
			{isTable ? (
				<Modal
					className={cl.modalQuestion}
					styleTypes={[ModalSize.BIG]}
					isOpen={questionMobileModalIsOpen}
					onClose={closeModal}
				>
					<form className={cl.form} onSubmit={onSubmit} noValidate>
						<div className={cl.inputContainer}>
							<label>Выберите тип ответов</label>
							<RadioGroup
								itemsList={[
									{ id: '1', value: 1, labelTitle: 'Выбор одного пункта' },
									{ id: '2', value: 2, labelTitle: 'Выбор нескольких ответов' }
								]}
								name={`${name}.answerType`}
								control={control}
								onChange={onChange(`${name}.answerType`)}
							/>
						</div>
						<div className={cl.answerContainer}>
							<span>Вопрос</span>
							<TextArea
								placeholder="Введите вопрос..."
								name={`${name}.name`}
								control={control}
								onChange={onChange(`${name}.name`)}
								error={errors.questions?.message}
							/>
						</div>
						{/* TODO */}
						<div className={cl.answerContainer}>
							<span>Загрузите картинку для вопроса теста</span>
							<DragAndDrop
								multiple
								imagesPreview={previewPictures}
								onChange={onPicturesChange}
								control={createControl}
								name="pictures"
								recommendedImageWidth={150}
								recommendedImageHeight={150}
								accept={[
									AcceptTypes.JPG,
									AcceptTypes.JPEG,
									AcceptTypes.PNG,
									AcceptTypes.SVG
								]}
								onDelete={onDeleteImage}
								onlyPreviews
							/>
						</div>
						{/* TODO refactor this is bad */}
						<AnswersList
							getValues={getValues}
							errors={errors}
							onChange={onChange}
							control={control}
							name={name}
							onSubmit={onSubmit as () => {}}
						/>
						<div className={cl.splitLine} />
						<div className={cl.buttons}>
							<Button
								onClick={onOpenCanceledChange}
								styleTypes={[ButtonStyles.OUTLINE_PRIMARY, ButtonStyles.ROUND]}
							>
								Отменить изменения
							</Button>
							<Button
								type="submit"
								styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
							>
								Сохранить изменения
							</Button>
						</div>
					</form>
				</Modal>
			) : (
				<form className={cl.form} onSubmit={onSubmit} noValidate>
					<div className={cl.inputContainer}>
						<label>Выберите тип ответов</label>
						<RadioGroup
							itemsList={[
								{ id: '1', value: 1, labelTitle: 'Выбор одного пункта' },
								{ id: '2', value: 2, labelTitle: 'Выбор нескольких ответов' }
							]}
							name={`${name}.answerType`}
							control={control}
							onChange={onChange(`${name}.answerType`)}
						/>
					</div>
					<div className={cl.answerContainer}>
						<span>Вопрос</span>
						<TextArea
							placeholder="Введите вопрос..."
							name={`${name}.name`}
							control={control}
							onChange={onChange(`${name}.name`)}
							error={errors.questions?.message}
						/>
					</div>
					<div className={cl.answerContainer}>
						<span>Загрузите картинку для вопроса теста</span>
						<DragAndDrop
							multiple
							imagesPreview={previewPictures}
							onChange={onPicturesChange}
							control={createControl}
							name="pictures"
							recommendedImageWidth={150}
							recommendedImageHeight={150}
							accept={[
								AcceptTypes.JPG,
								AcceptTypes.JPEG,
								AcceptTypes.PNG,
								AcceptTypes.SVG
							]}
							onDelete={onDeleteImage}
							onlyPreviews
						/>
					</div>

					<AnswersList
						getValues={getValues}
						errors={errors}
						onChange={onChange}
						control={control}
						name={name}
						onSubmit={onSubmit as () => {}}
					/>
					<div className={cl.splitLine} />
					<div className={cl.buttons}>
						<Button
							onClick={onOpenCanceledChange}
							styleTypes={[ButtonStyles.OUTLINE_PRIMARY, ButtonStyles.ROUND]}
						>
							Отменить изменения
						</Button>
						<Button
							type="submit"
							styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
						>
							Сохранить изменения
						</Button>
					</div>
				</form>
			)}
		</>
	)
}

export default CreateQuestionForm
