import React, { useEffect } from 'react'

import NotificationSettingsFormComponent from 'components/NotificationSettingsForm'
import { useAppSelector } from 'hooks/redux'
import { userQuery } from 'store/queries'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'

export interface NotificationSettingsFormContainerProps {}

const NotificationSettingsForm: React.FC<
	NotificationSettingsFormContainerProps
> = () => {
	const { currentSchoolId, currentCoursesIdList, currentRole } = useAppSelector(
		(state) => state.system
	)

	const [getNotificationEvents, { data: notificationEvents, isLoading }] =
		userQuery.useGetEventsForNotificationsMutation()
	const [
		triggerGetSettings,
		{ data: notificationSettings, isLoading: settingsLoading }
	] = userQuery.useGetNotificationSettingsMutation()

	useEffect(() => {
		if (!currentSchoolId || !currentCoursesIdList) return

		getNotificationEvents({ role: currentRole })
		triggerGetSettings({ role: currentRole })
	}, [currentSchoolId])

	if (
		isLoading ||
		!notificationEvents ||
		settingsLoading ||
		!notificationSettings
	) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<NotificationSettingsFormComponent
			notificationEvents={notificationEvents}
			notificationSettings={notificationSettings.data.userNotificationSettings}
			isActive={notificationSettings.data.isActive}
		/>
	)
}

export default NotificationSettingsForm
