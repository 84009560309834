import React, { FC } from 'react'

const CoursesIcon: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.8333 1.66669H5C3.995 1.66669 2.5 2.33252 2.5 4.16669V15.8334C2.5 17.6675 3.995 18.3334 5 18.3334H17.5V16.6667H5.01C4.625 16.6567 4.16667 16.5042 4.16667 15.8334C4.16667 15.7492 4.17417 15.6742 4.18667 15.6059C4.28 15.1267 4.6725 15.0084 5.00917 15H16.6667C16.6817 15 16.6925 14.9925 16.7075 14.9917H17.5V3.33335C17.5 2.41419 16.7525 1.66669 15.8333 1.66669ZM15.8333 13.3334H4.16667V4.16669C4.16667 3.49502 4.625 3.34335 5 3.33335H10.8333V9.16669L12.5 8.33335L14.1667 9.16669V3.33335H15.8333V13.3334Z"
			fill="#666666"
		/>
	</svg>
)

export default CoursesIcon
