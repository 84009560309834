import React, { FC } from 'react'
import { UseFieldArrayRemove } from 'react-hook-form'

import DeleteAnswerModalComponent from 'components/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/AnswersList/modals/DeleteAnswer'
import { ResponseEntityId } from 'types'

export interface DeleteAnswerModalProps {
	isOpen: boolean
	onClose: () => void
	remove: UseFieldArrayRemove
	answerId: ResponseEntityId
}

const DeleteAnswerModal: FC<DeleteAnswerModalProps> = ({
	isOpen,
	onClose,
	remove,
	answerId
}) => (
	<DeleteAnswerModalComponent
		answerId={answerId}
		onClose={onClose}
		isOpen={isOpen}
		remove={remove}
	/>
)

export default DeleteAnswerModal
