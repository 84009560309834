import classNames from 'classnames'
import React, { FC } from 'react'
import ReactPlayer from 'react-player'

import cl from './style.module.scss'

interface VideoProps {
	videoUrl?: string
}

const Video: FC<VideoProps> = ({ videoUrl }) => (
	<div
		className={classNames(cl.container, {
			[cl.noWidth]: !videoUrl
		})}
	>
		{videoUrl && (
			<ReactPlayer
				style={{
					objectFit: 'cover',
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					background: 'black'
				}}
				controls
				width="100%"
				height="100%"
				url={videoUrl}
			/>
		)}
	</div>
)

export default Video
