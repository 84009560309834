import React, { FC, useCallback, useEffect, useState, Dispatch } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormWatch,
	useWatch
} from 'react-hook-form'
import dayjs, { Dayjs } from 'dayjs'
import LimitationsFormTabComponents from 'components/Courses/Entities/Tests/Tabs/Content/LimitationsForm'
import {
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import { TestFormState } from 'pages/Course/Test/Create.page'
import testQuery from 'store/queries/test.query'
import { useActions } from 'hooks/redux'
import { DatePassSettings } from 'types/models/event.model'

export type LimitationsFormTabState = Pick<
	TestFormState,
	| 'id'
	| 'testResponsibilityStatus'
	| 'countAttempts'
	| 'datePassStartSettings'
	| 'datePassEndSettings'
	| 'passingScore'
	| 'passingTimeLimit'
	| 'questions'
	| 'testResponsibilityAnswersStatus'
>

export interface LimitationsFormTabProps {
	register: UseFormRegister<LimitationsFormTabState>
	handleSubmit: UseFormHandleSubmit<LimitationsFormTabState>
	errors: FieldErrors<LimitationsFormTabState>
	control: Control<LimitationsFormTabState>
	onChange: (name: FieldPath<LimitationsFormTabState>) => (value: any) => void
	getValues: UseFormGetValues<LimitationsFormTabState>
	watch: UseFormWatch<LimitationsFormTabState>
	onOpenCanceledChange: () => void
	setTestChangesSaved: Dispatch<React.SetStateAction<boolean>>
}

const LimitationsFormTab: FC<LimitationsFormTabProps> = ({
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	getValues,
	watch,
	onOpenCanceledChange,
	setTestChangesSaved
}) => {
	const [changeTest] = testQuery.useChangeTestMutation()
	const questions = useWatch({
		control,
		name: 'questions'
	})
	const { pushError, pushSuccess } = useActions((state) => state.system)

	useEffect(() => {
		register('testResponsibilityStatus', {
			required: ValidationErrorMessages.CHOOSE_AT_LEAST_ONE
		})
		register('countAttempts')
		register('passingTimeLimit')
		register('passingScore')
		register('datePassStartSettings.date', {
			validate: (value) =>
				!!(
					!getValues('datePassStartSettings.isActive') ||
					(getValues('datePassStartSettings.date') &&
						new Date(value).toLocaleDateString())
				) || ValidationErrorMessages.DATEPICKER_INCORRECT
		})
		register('datePassStartSettings.time', {
			validate: (value) =>
				!!(
					!getValues('datePassStartSettings.isActive') ||
					(getValues('datePassStartSettings.isActive') &&
						getValues('datePassStartSettings.date') &&
						new Date(value).toLocaleDateString())
				) || ValidationErrorMessages.DATEPICKER_INCORRECT
		})
		register('datePassEndSettings.date', {
			validate: (value) =>
				!!(
					!getValues('datePassEndSettings.isActive') ||
					(getValues('datePassEndSettings.time') &&
						new Date(value).toLocaleDateString())
				) || ValidationErrorMessages.DATEPICKER_INCORRECT
		})
		register('datePassEndSettings.time', {
			validate: (value) =>
				!!(
					!getValues('datePassEndSettings.isActive') ||
					(getValues('datePassEndSettings.isActive') &&
						getValues('datePassEndSettings.date') &&
						new Date(value).toLocaleDateString())
				) || ValidationErrorMessages.DATEPICKER_INCORRECT
		})

		return () => {
			register('testResponsibilityStatus', { required: undefined })
			register('testResponsibilityAnswersStatus', { required: undefined })
			register('countAttempts', { required: undefined })
			register('passingTimeLimit')
			register('passingScore', { required: undefined })
			register('datePassStartSettings.date', { validate: undefined })
			register('datePassStartSettings.time', { validate: undefined })
			register('datePassEndSettings.date', { validate: undefined })
			register('datePassEndSettings.time', { validate: undefined })
		}
	}, [])

	const submitHandler = useCallback(
		handleSubmit(
			async ({
				countAttempts,
				datePassEndSettings,
				datePassStartSettings,
				passingScore,
				passingTimeLimit,
				testResponsibilityStatus,
				testResponsibilityAnswersStatus,
				questions: testQuestions
			}) => {
				if (passingScore > testQuestions.length || passingScore < 0) {
					pushError({
						message: ValidationErrorMessages.PASSING_SCORE_INCORRECT
					})
					return
				}

				const datePassStart = {
					...datePassStartSettings,
					date: dayjs(datePassStartSettings.date).add(1, 'day')
				}
				const datePassEnd = {
					...datePassEndSettings,
					date: dayjs(datePassEndSettings.date).add(1, 'day')
				}

				const response = await changeTest({
					body: {
						countAttempts,
						// @ts-ignore
						datePassEndSettings: datePassEndSettings.isActive
							? datePassEnd
							: undefined,
						// @ts-ignore
						datePassStartSettings: datePassStartSettings.isActive
							? datePassStart
							: undefined,
						passingScore,
						passingTimeLimit: passingTimeLimit.isActive
							? passingTimeLimit
							: undefined,
						testResponsibilityStatus,
						testResponsibilityAnswersStatus
					},
					testId: +`${getValues('id')}`
				})

				const { error } = response as unknown as ResponseWithError
				if (error) {
					pushError(error.data)
					return
				}
				const successMessage = {
					message: ValidationSuccessMessages.SUCCESS_CHANGE
				}
				pushSuccess(successMessage)
				setTestChangesSaved(true)
			}
		),
		[handleSubmit]
	)

	return (
		<LimitationsFormTabComponents
			onOpenCanceledChange={onOpenCanceledChange}
			watch={watch}
			control={control}
			onChange={onChange}
			onSubmit={submitHandler}
			errors={errors}
			questionsCount={questions.length}
		/>
	)
}

export default LimitationsFormTab
