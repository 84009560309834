import React, {
	FC,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState
} from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch
} from 'react-hook-form'

import isEmail from 'validator/lib/isEmail'
import { MainFormTab as MainFormTabComponent } from 'components/Courses/Tabs/Content'
import { ITag } from 'UI/Tags/types'
import {
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import { getFormData } from 'helpers'
import {
	CommunicationsInfoItem,
	CommunicationsSettings
} from 'types/models/course.model/settings/communications.model'
import { CourseFormState } from 'pages/Course/Create.page'
import GetImageUrlFromFile from 'helpers/files/getImageUrlFromFile'
import courseQuery from 'store/queries/course.query'
import { useNavigate } from 'react-router-dom'
import { CreateCourseResponse } from 'store/queries/course.query/types'
import CertificateTemplateModal from 'pages/Course/modals/CertificateTemplate'
import { useActions, useAppSelector } from 'hooks/redux'
import { schoolQuery, selectsQuery, userQuery } from 'store/queries'

export type MainFormTabState = Pick<
	CourseFormState,
	| 'id'
	| 'name'
	| 'description'
	| 'coverUrl'
	| 'bannerForMPUrl'
	| 'directionsIdList'
	| 'certificateSettings'
	| 'reviewResponsibilityStatus'
	| 'communicationsSettings'
	| 'marketplaceSettings'
	| 'cover'
	| 'bannerForMP'
	| 'signerSign'
	| 'sampleCertificateForMP'
	| 'signerMark'
	| 'courseCompletionTime'
	| 'accessTimeCourse'
>

interface MainFormTabProps {
	errors: FieldErrors<MainFormTabState>
	onChange: (name: FieldPath<MainFormTabState>) => (value: any) => void
	control: Control<MainFormTabState>
	register: UseFormRegister<MainFormTabState>
	handleSubmit: UseFormHandleSubmit<MainFormTabState>
	watch: UseFormWatch<MainFormTabState>
	getValues: UseFormGetValues<MainFormTabState>
	setValue: UseFormSetValue<MainFormTabState>
	isEditMode?: boolean
	onOpenCanceledChange: () => void
}

const MainFormTab: FC<MainFormTabProps> = ({
	errors,
	onChange,
	control,
	register,
	handleSubmit,
	watch,
	getValues,
	setValue,
	isEditMode = false,
	onOpenCanceledChange
}) => {
	const [createCourse] = courseQuery.useCreateCourseMutation()
	const [changeCourseMainInfo] = courseQuery.useChangeCourseMainInfoMutation()

	const { pushError, pushSuccess } = useActions((state) => state.system)

	const hasCertificateErrors = getValues('certificateSettings.isActive') && errors.certificateSettings?.templateInfo?.teacherSettings?.name?.message
		&& errors.certificateSettings?.templateInfo?.schoolSettings?.signerMarkUrl?.message
		&& errors.certificateSettings?.templateInfo?.schoolSettings?.signerSignUrl?.message
		&& errors.certificateSettings?.templateInfo?.schoolSettings?.schoolName?.message
		&& errors.certificateSettings?.templateInfo?.schoolSettings?.signerPost?.message
		&& errors.certificateSettings?.templateInfo?.schoolSettings?.signerName?.message

	const [certificateTemplateIsOpen, setCertificateTemplateIsOpen] = useState(!!hasCertificateErrors)
	const { data: learningFormats } = selectsQuery.useGetLearningFormatsQuery()

	const studyFormatOptionsList = useMemo(() => {
		if (!learningFormats) return []
		const formattedData = learningFormats.data.map(({ id, text, value }) => ({
			id,
			text,
			value: id
		}))

		return formattedData
	}, [learningFormats])
	const navigate = useNavigate()
	const { currentSchoolId } = useAppSelector(
		(state) => state.system
	)

	const tagsList = useMemo(
		(): ITag[] => [
			{ id: 1, text: 'IT и программирование' },
			{ id: 2, text: 'Финансовая грамотность' },
			{ id: 3, text: 'Дизайн' },
			{ id: 4, text: 'Образование' },
			{ id: 5, text: 'Маркетинг' },
			{ id: 6, text: 'Soft Skills' },
			{ id: 7, text: 'Аналитика' },
			{ id: 8, text: 'Хобби' },
			{ id: 9, text: 'Спорт и здоровье' },
			{ id: 10, text: 'Фото и видео' },
			{ id: 11, text: 'Музыка' },
			{ id: 12, text: 'Другое' }
		],
		[]
	)

	useEffect(() => {
		register('id')
		register('name', {
			required: ValidationErrorMessages.EMPTY
		})
		register('description', {
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('coverUrl')
		register('cover')
		register('bannerForMPUrl')
		register('bannerForMP')
		register('directionsIdList')
		register('certificateSettings.isActive')
		// выбор типа сертификата 'Свой сертификат' или "Учебной платформы"
		// register('certificateSettings.type')
		register('reviewResponsibilityStatus')
		const communicationsSettingsValidationCallback = (
			value: CommunicationsInfoItem
		) => {
			if (!value) {
				return true
			}
			const { text, isActive } = value
			return !isActive || !!text || ValidationErrorMessages.EMPTY
		}
		register('communicationsSettings.condition', {
			validate: communicationsSettingsValidationCallback
		})
		register('communicationsSettings.email', {
			validate: (value) => {
				if (!value) {
					return true
				}
				return communicationsSettingsValidationCallback(value) === true
					? isEmail(value.text) ||
					!(
						getValues('communicationsSettings.email.isActive') && value.text
					) ||
					ValidationErrorMessages.INCORRECT
					: communicationsSettingsValidationCallback(value)
			}
		})
		register('communicationsSettings.phone', {
			validate: communicationsSettingsValidationCallback
		})
		register('communicationsSettings.whatsapp', {
			validate: communicationsSettingsValidationCallback
		})
		register('communicationsSettings.telegram', {
			validate: communicationsSettingsValidationCallback
		})
		register('communicationsSettings.instagram', {
			validate: communicationsSettingsValidationCallback
		})
		register('communicationsSettings.vk', {
			validate: communicationsSettingsValidationCallback
		})
		register('marketplaceSettings.isActive')
		register('marketplaceSettings.priceSettings.isActive')
		register('marketplaceSettings.priceSettings.price', {
			validate: (value) =>
				!getValues('marketplaceSettings.priceSettings.isActive') ||
				!!value ||
				ValidationErrorMessages.EMPTY
		})
		register('marketplaceSettings.studyFormatTypesId')
		register('marketplaceSettings.dateLearningSettings.isActive')
		register('marketplaceSettings.dateLearningSettings.start', {
			// validate: (value) =>
			// 	!!(
			// 		!getValues('marketplaceSettings.dateLearningSettings.isActive') ||
			// 		(getValues('marketplaceSettings.dateLearningSettings.end') &&
			// 			new Date(value).toLocaleDateString())
			// 	) || ValidationErrorMessages.DATEPICKER_INCORRECT
			validate: (value) =>
				!!(!getValues('marketplaceSettings.dateLearningSettings.isActive') || (!!value))
				|| ValidationErrorMessages.DATEPICKER_INCORRECT
		})
		// register('marketplaceSettings.dateLearningSettings.end', {
		// 	validate: (value) =>
		// 		!getValues('marketplaceSettings.dateLearningSettings.isActive') ||
		// 		!(
		// 			getValues('marketplaceSettings.dateLearningSettings.start') &&
		// 			new Date(value).toLocaleDateString()
		// 		) ||
		// 		new Date(getValues('marketplaceSettings.dateLearningSettings.start')) <
		// 		new Date(value) ||
		// 		ValidationErrorMessages.DATEPICKER_END_BIG_THEN_START
		// })
		register('marketplaceSettings.announcementDescription', {
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('marketplaceSettings.documentAfterLearningName', {
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('marketplaceSettings.employmentDescription', {
			minLength: {
				value: 3,
				message: ValidationErrorMessages.INCORRECT
			}
		})
		register('marketplaceSettings.corporateLearningSettings.isActive')
		register('marketplaceSettings.corporateLearningSettings.email', {
			validate: (value) => {
				if (
					getValues('marketplaceSettings.corporateLearningSettings.isActive') &&
					!getValues('marketplaceSettings.corporateLearningSettings.phone') &&
					!value
				) {
					return ValidationErrorMessages.CHOOSE_AT_LEAST_ONE
				}
				if (value && !isEmail(value)) {
					return ValidationErrorMessages.INCORRECT
				}
			}
		})
		register('marketplaceSettings.corporateLearningSettings.phone', {
			validate: (value) => {
				if (
					getValues('marketplaceSettings.corporateLearningSettings.isActive') &&
					!getValues('marketplaceSettings.corporateLearningSettings.email') &&
					!value
				) {
					return ValidationErrorMessages.CHOOSE_AT_LEAST_ONE
				}
				if (value && value.length !== 16) {
					return ValidationErrorMessages.INCORRECT
				}
			}
		})
		register('certificateSettings.resetCertificate')
		register('certificateSettings.templateInfo.teacherSettings.isActive')
		// register('certificateSettings.templateInfo.teacherSettings.name', {
		// 	required: getValues('certificateSettings.isActive') && ValidationErrorMessages.EMPTY || undefined
		// })
		register('certificateSettings.templateInfo.teacherSettings.name', {
			validate: (value) => {
				if (getValues('certificateSettings.isActive') && !value || getValues('certificateSettings.isActive') && value && !value.trim()) {
					return ValidationErrorMessages.EMPTY
				}
			}
		})
		register('certificateSettings.templateInfo.schoolSettings.isActive')
		register('certificateSettings.templateInfo.schoolSettings.schoolName', {
			validate: (value) => {
				if (getValues('certificateSettings.isActive') && !value || getValues('certificateSettings.isActive') && value && !value.trim()) {
					return ValidationErrorMessages.EMPTY
				}
			}
		})
		register('certificateSettings.templateInfo.schoolSettings.signerPost', {
			validate: (value) => {
				if (getValues('certificateSettings.isActive') && !value || getValues('certificateSettings.isActive') && value && !value.trim()) {
					return ValidationErrorMessages.EMPTY
				}
			}
		})
		register('certificateSettings.templateInfo.schoolSettings.signerName', {
			validate: (value) => {
				if (getValues('certificateSettings.isActive') && !value || getValues('certificateSettings.isActive') && value && !value.trim()) {
					return ValidationErrorMessages.EMPTY
				}
			}
		})
		register('certificateSettings.templateInfo.schoolSettings.signerMarkUrl', {
			validate: (value) => {
				if (getValues('certificateSettings.isActive') && !value?.url) {
					return ValidationErrorMessages.EMPTY
				}
			}
		})
		register('certificateSettings.templateInfo.schoolSettings.signerSignUrl', {
			validate: (value) => {
				if (getValues('certificateSettings.isActive') && !value?.url) {
					return ValidationErrorMessages.EMPTY
				}
			}
		})
		register('certificateSettings.templateInfo.schoolSettings.sampleCertificateForMPUrl')
		register('certificateSettings.templateInfo.schoolSettings.signerMark')
		register('certificateSettings.templateInfo.schoolSettings.signerSign', {
			required: getValues('certificateSettings.isActive') && ValidationErrorMessages.EMPTY || undefined
		})
		register(
			'certificateSettings.templateInfo.schoolSettings.sampleCertificateForMP'
		)

		register('signerSign')
		register('sampleCertificateForMP')
		register('signerMark')

		return () => {
			register('id')
			register('name', {
				required: undefined,
				minLength: undefined,
				pattern: undefined
			})
			register('description', {
				minLength: undefined,
				pattern: undefined
			})
			register('communicationsSettings.email', {
				validate: undefined
			})
			register('communicationsSettings.vk', {
				validate: undefined
			})
			register('communicationsSettings.phone', {
				validate: undefined
			})
			register('communicationsSettings.telegram', {
				validate: undefined
			})
			register('communicationsSettings.instagram', {
				validate: undefined
			})
			register('communicationsSettings.whatsapp', {
				validate: undefined
			})
			register('marketplaceSettings.priceSettings.price', {
				validate: undefined,
				pattern: undefined
			})
			register('marketplaceSettings.dateLearningSettings.start', {
				validate: undefined
			})
			register('marketplaceSettings.dateLearningSettings.end', {
				validate: undefined
			})
			register('marketplaceSettings.announcementDescription', {
				minLength: undefined,
				pattern: undefined
			})
			register('marketplaceSettings.documentAfterLearningName', {
				minLength: undefined,
				pattern: undefined
			})
			register('marketplaceSettings.employmentDescription', {
				minLength: undefined,
				pattern: undefined
			})
			register('marketplaceSettings.corporateLearningSettings.email', {
				validate: undefined
			})
			register('marketplaceSettings.corporateLearningSettings.phone', {
				validate: undefined
			})
		}
	}, [])

	useEffect(() => {
		setValue('certificateSettings.templateInfo.schoolSettings.isActive', true)
		setValue('certificateSettings.templateInfo.teacherSettings.isActive', true)
	}, [])

	const coverFile = watch('cover')
	const coverUrlWatcher = watch('coverUrl')

	const bannerForMPFile = watch('bannerForMP')
	const bannerForMPUrlWatcher = watch('bannerForMPUrl')

	useLayoutEffect(() => {
		; (async () => {
			if (coverFile === undefined) {
				return
			}
			const blob = await GetImageUrlFromFile(coverFile)
			setValue('coverUrl', blob ? URL.createObjectURL(new Blob([blob])) : '')
		})()
	}, [coverFile])

	useLayoutEffect(() => {
		; (async () => {
			if (bannerForMPFile === undefined) {
				return
			}
			const blob = await GetImageUrlFromFile(bannerForMPFile)
			setValue(
				'bannerForMPUrl',
				blob ? URL.createObjectURL(new Blob([blob])) : ''
			)
		})()
	}, [bannerForMPFile])

	const submitEditHandler = useCallback(
		handleSubmit(
			async ({
				cover,
				bannerForMP,
				marketplaceSettings,
				certificateSettings,
				communicationsSettings,
				name,
				directionsIdList,
				reviewResponsibilityStatus,
				description,
				coverUrl,
				bannerForMPUrl,
				signerSign,
				sampleCertificateForMP,
				signerMark,
				accessTimeCourse,
				courseCompletionTime
			}) => {
				const transformedCommunicationSettings = transformCommunicationSettings(
					communicationsSettings
				)


				const response = await changeCourseMainInfo({
					body: await getFormData({
						cover,
						bannerForMP,
						marketplaceSettings: JSON.stringify(marketplaceSettings),
						certificateSettings: JSON.stringify(certificateSettings),
						communicationsSettings: JSON.stringify(
							transformedCommunicationSettings
						),
						name: name?.trim(),
						directionsIdList,
						reviewResponsibilityStatus,
						description: description?.trim(),
						coverUrl,
						bannerForMPUrl,
						signerSign,
						sampleCertificateForMP,
						signerMark,
						accessTimeCourse: accessTimeCourse?.days,
						courseCompletionTime: courseCompletionTime?.days,
						timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
					}),
					courseId: getValues('id')
				})
				const { error } = response as unknown as ResponseWithError
				if (error) {
					pushError(error.data)
					return
				}
				const successMessage = {
					message: ValidationSuccessMessages.SUCCESS_CHANGE
				}
				pushSuccess(successMessage)

				function transformCommunicationSettings(
					communicationsSettingsToTransform: CommunicationsSettings
				): CommunicationsSettings {
					const newSettings = JSON.parse(
						JSON.stringify(communicationsSettingsToTransform)
					)

					Object.keys(newSettings).forEach((key) => {
						const keyCasted =
							key as keyof typeof communicationsSettingsToTransform

						if (!newSettings[keyCasted].isActive) {
							newSettings[keyCasted].isActive = false
							newSettings[keyCasted].text = ''
						} else {
							newSettings[keyCasted].isActive = true
							newSettings[keyCasted].text =
								communicationsSettingsToTransform[keyCasted].text
						}
					})

					return newSettings
				}
			}
		),
		[handleSubmit]
	)
	const submitCreateHandler = useCallback(
		handleSubmit(
			async ({
				cover,
				bannerForMP,
				marketplaceSettings,
				certificateSettings,
				communicationsSettings,
				name,
				directionsIdList,
				reviewResponsibilityStatus,
				description,
				coverUrl,
				bannerForMPUrl,
				signerSign,
				sampleCertificateForMP,
				signerMark,
				accessTimeCourse,
				courseCompletionTime
			}) => {
				const transformedCommunicationSettings = transformCommunicationSettings(
					communicationsSettings
				)

				const response = await createCourse(
					await getFormData({
						cover,
						bannerForMP,
						marketplaceSettings: JSON.stringify(marketplaceSettings),
						certificateSettings: JSON.stringify(certificateSettings),
						communicationsSettings: JSON.stringify(
							transformedCommunicationSettings
						),
						name: name?.trim(),
						directionsIdList,
						reviewResponsibilityStatus,
						description: description?.trim(),
						coverUrl,
						bannerForMPUrl,
						signerSign,
						sampleCertificateForMP,
						signerMark,
						accessTimeCourse: accessTimeCourse?.days,
						courseCompletionTime: courseCompletionTime?.days,
						timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
						schoolId: currentSchoolId
					})
				)
				const { error } = response as unknown as ResponseWithError
				if (error) {
					pushError(error.data)
					return
				}
				userQuery.util.invalidateTags(['user'])
				schoolQuery.util.invalidateTags(['school'])
				const { data } = response as unknown as CreateCourseResponse
				navigate(`/course/edit/${data.courseId}`)
				const successMessage = {
					message: ValidationSuccessMessages.SUCCESS_CHANGE
				}
				pushSuccess(successMessage)

				function transformCommunicationSettings(
					communicationsSettingsToTransform: CommunicationsSettings
				): CommunicationsSettings {
					const newSettings = JSON.parse(
						JSON.stringify(communicationsSettingsToTransform)
					)

					Object.keys(newSettings).forEach((key) => {
						const keyCasted =
							key as keyof typeof communicationsSettingsToTransform

						if (!newSettings[keyCasted].isActive) {
							newSettings[keyCasted].isActive = false
							newSettings[keyCasted].text = ''
						} else {
							newSettings[keyCasted].isActive = true
							newSettings[keyCasted].text =
								communicationsSettingsToTransform[keyCasted].text
						}
					})

					return newSettings
				}
			}
		),
		[handleSubmit]
	)

	return (
		<>
			<CertificateTemplateModal
				isOpen={certificateTemplateIsOpen}
				onClose={() => setCertificateTemplateIsOpen(false)}
			/>
			<MainFormTabComponent
				getValues={getValues}
				certificateTemplateIsOpen={certificateTemplateIsOpen}
				onOpenCertificateTemplateModal={() =>
					setCertificateTemplateIsOpen(true)
				}
				onOpenCanceledChange={onOpenCanceledChange}
				setValue={setValue}
				studyFormOptionsList={studyFormatOptionsList}
				control={control}
				onChange={onChange}
				onSubmit={isEditMode ? submitEditHandler : submitCreateHandler}
				previewUrl={coverUrlWatcher || ''}
				previewBannerForMPUrl={bannerForMPUrlWatcher || ''}
				errors={errors}
				tagsList={tagsList}
				watch={watch}
				certificateErrors={!!hasCertificateErrors}
			/>
		</>
	)
}

export default MainFormTab
