import React, { FC, useCallback, useMemo, useState } from 'react'
import WeekTabComponent from 'components/Timetable/Tabs/WeekTab'
import { getFirstAndLastDaysOfTheWeek } from 'helpers/time'
import { Control, useWatch } from 'react-hook-form'
import { TimetableContentFormValues } from 'containers/Timetable/TimetableContent'
import {
	IDayEvent,
	WeekDay
} from 'types/models/day-event.model/day-event.model'
import { getUniqueId } from 'helpers'
import dayjs from 'dayjs'
import { IEvent } from 'types/models/event.model'
import { EntityId } from '@reduxjs/toolkit'

export interface TableCellsInfo {
	id: EntityId
	events: IEvent[]
}

export interface WeekTabContainerProps {
	control: Control<TimetableContentFormValues>
	onDetailsOpen: (_: IEvent) => void
}

const WeekTab: FC<WeekTabContainerProps> = ({ control, onDetailsOpen }) => {
	const currentDate = useWatch({
		control,
		name: 'currentDate'
	})

	const events = useWatch({
		control,
		name: 'events'
	})

	const { lastDayDate, firstDayDate } = useMemo(
		() => getFirstAndLastDaysOfTheWeek(currentDate || new Date()),
		[currentDate]
	)

	const [hoverIndex, setHoverIndex] = useState<number | null>(null)

	const onHoverIndexChange = useCallback(
		(newIndex: number | null) => setHoverIndex(newIndex),
		[setHoverIndex]
	)

	const mappedDaysEvents = useMemo<IDayEvent[]>(() => {
		if (!events) return []

		return events
			.filter(
				(e) =>
					(dayjs(e.dateEventStart).isAfter(firstDayDate) || dayjs(e.dateEventStart).startOf('day').isSame(dayjs(firstDayDate).startOf('day'))) &&
					dayjs(e.dateEventStart).isBefore(lastDayDate)
			)
			.map((e) => ({
				id: getUniqueId(),
				date: new Date(e.dateEventStart),
				value: e
			}))
	}, [dayjs, events])

	const mappedWeekDays = useMemo<WeekDay[]>(() => {
		let days: WeekDay[] = []
		const iterateDate = new Date(+firstDayDate)

		do {
			days = [...days, { id: getUniqueId(), date: new Date(+iterateDate) }]

			iterateDate.setDate(iterateDate.getDate() + 1)
		} while (iterateDate < lastDayDate)

		return days
	}, [mappedDaysEvents, firstDayDate, lastDayDate])

	const getEventsByDays = useCallback(
		(eventDate: Date) =>
			mappedDaysEvents
				.filter((e) => e.date.getDate() === eventDate.getDate())
				.map((e) => e.value),
		[mappedDaysEvents]
	)

	const mappedWeekDaysElements = useMemo<TableCellsInfo[]>(() => {
		const iterateDate = new Date(+firstDayDate)
		let itemsArray: TableCellsInfo[] = []

		do {
			itemsArray = [
				...itemsArray,
				{ id: getUniqueId(), events: getEventsByDays(iterateDate) }
			]

			iterateDate.setDate(iterateDate.getDate() + 1)
		} while (iterateDate < lastDayDate)

		return itemsArray
	}, [firstDayDate, lastDayDate, getEventsByDays, hoverIndex])

	return (
		<WeekTabComponent
			mappedWeekDaysElements={mappedWeekDaysElements}
			mappedWeekDays={mappedWeekDays}
			getEventsByDays={getEventsByDays}
			hoverIndex={hoverIndex}
			onHoverIndexChange={onHoverIndexChange}
			onDetailsOpen={onDetailsOpen}
		/>
	)
}

export default WeekTab
