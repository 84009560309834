import React, { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getFormData, getUniqueId } from 'helpers'
import { useAppDispatch } from 'hooks/redux'
import ContentFormTableComponent from 'components/Courses/Tabs/Content/ContentForm/Table'
import { ResponseEntityId, ResponseWithError, SortDirections } from 'types'
import { DropdownItem } from 'UI/Dropdown/types'
import { courseQuery, lessonQuery, taskQuery, testQuery } from 'store/queries'
import { CoursesTabContentProps } from 'components/Courses/Tabs/Content/ContentForm'
import DeleteSectionItemModal from 'containers/Courses/Tabs/Content/ContentForm/modals/DeleteSectionItem'

import DeleteSectionModal from 'containers/Courses/Tabs/Content/ContentForm/modals/DeleteSection'
import ConfirmModal from 'containers/Courses/Entities/Tasks/TaskStatus/ConfirmModal'

import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import {
	activateIconSrc,
	deactivateIconSrc,
	editIconSrc,
	trashIconSrc
} from './icons'
import CreateSectionItemModal from './modals/CreateSectionItem'

export enum SectionItemType {
	TEST = 'test',
	TASK = 'task',
	EVENT = 'event',
	LESSON = 'lesson'
}

export interface ISectionItem {
	id: ResponseEntityId
	name: string
	label: string
	dateAdded: string
	dateChanged: string
	activity: boolean
	type: SectionItemType
	available: boolean
	required: boolean
	passed: boolean
}

export interface ISection {
	id: ResponseEntityId
	header: string
	items: ISectionItem[]
}

interface ContentFormTableProps
	extends Omit<CoursesTabContentProps, 'openCreateSectionModal'> { }

const ContentFormTable: FC<ContentFormTableProps> = ({
	openEditSectionModal,
	sectionsIdList = [],
	courseId,
	setSortedSectionsIdList
}) => {
	const dispatch = useAppDispatch()

	const [confirmPublicationModalOpen, setConfirmPublicationModalOpen] =
		useState(false)
	const [itemConfirmPendingId, setItemConfirmPendingId] =
		useState<ResponseEntityId | null>(null)
	const [itemConfirmType, setItemConfirmType] =
		useState<SectionItemType | null>(null)

	const [createSectionItemModalIsOpen, setCreateSectionItemModalIsOpen] =
		useState(false)
	const [currentCreatingSection, setCurrentCreatingSection] =
		useState<ResponseEntityId>()
	const [deleteSectionItemModalIsOpen, setDeleteSectionItemModalIsOpen] =
		useState(false)
	const [deleteSectionModalIsOpen, setDeleteSectionModalIsOpen] =
		useState(false)
	const [currentSectionItemType, setCurrentSectionItemType] =
		useState<SectionItemType>()
	const [currentSectionItemName, setCurrentSectionItemName] = useState('')
	const [currentSectionItemId, setCurrentSectionItemId] =
		useState<ResponseEntityId>()
	const [currentSectionId, setCurrentSectionId] = useState<ResponseEntityId>()
	const [currentSectionName, setCurrentSectionName] = useState('')

	const [triggerGetTest] = testQuery.useLazyGetTestQuery()
	const [triggerGetTask] = taskQuery.useLazyGetTaskQuery()

	const [changeTest] = testQuery.useChangeTestMutation()
	const [changeTask] = taskQuery.useChangeTaskMutation()
	const [changeLesson] = lessonQuery.useChangeLessonMutation()

	const navigate = useNavigate()

	const openCreateSectionItemModalHandler = () => {
		setCreateSectionItemModalIsOpen(true)
	}
	const closeCreateSectionItemModalHandler = () => {
		setCreateSectionItemModalIsOpen(false)
	}

	const onGoChangeRestrictions = useCallback(() => {
		navigate(
			`/course/${courseId}/${itemConfirmType}/edit/${itemConfirmPendingId}`
		)
	}, [itemConfirmPendingId, itemConfirmType, courseId])

	const [sortSectionQuery] = courseQuery.useSortCourseSectionMutation()

	const onConfirmPublicationModalClose = useCallback(() => {
		setConfirmPublicationModalOpen(false)
		setItemConfirmPendingId(null)
		setItemConfirmType(null)
	}, [])

	const sortSection = (
		sectionId: ResponseEntityId,
		sortDirection: SortDirections
	) => {
		setSortedSectionsIdList((prev) => {
			const currentSectionIndex = prev.findIndex((item) => item === sectionId)
			const leftPartOfArray = prev.slice(0, currentSectionIndex)
			const rightPartOfArray = prev.slice(currentSectionIndex, prev.length)

			const leftPartOfArrayLastSymbol =
				leftPartOfArray[leftPartOfArray.length - 1]
			const rightPartOfArrayFirstSymbol = rightPartOfArray[0]

			switch (sortDirection) {
				case SortDirections.TO_TOP:
					if (rightPartOfArrayFirstSymbol) {
						leftPartOfArray[leftPartOfArray.length - 1] =
							rightPartOfArrayFirstSymbol
					}
					if (leftPartOfArrayLastSymbol) {
						rightPartOfArray[0] = leftPartOfArrayLastSymbol
					}

					sortSectionQuery({
						sortItemsIdList: [...leftPartOfArray, ...rightPartOfArray]
					})

					return [...leftPartOfArray, ...rightPartOfArray]
				case SortDirections.TO_BOTTOM:
					leftPartOfArray.push(rightPartOfArrayFirstSymbol)
					rightPartOfArray.shift()
					const rightFirst = rightPartOfArray[0]
					const leftLast = leftPartOfArray[leftPartOfArray.length - 1]

					if (leftLast) {
						rightPartOfArray[0] = leftLast
					}
					if (rightFirst) {
						leftPartOfArray[leftPartOfArray.length - 1] = rightFirst
					}

					sortSectionQuery({
						sortItemsIdList: [...leftPartOfArray, ...rightPartOfArray]
					})

					return [...leftPartOfArray, ...rightPartOfArray]
				default:
					return [...prev]
			}
		})
	}

	const deleteSectionItemHandler = useCallback(
		(
			sectionItemId: ResponseEntityId,
			sectionItemType: SectionItemType,
			sectionId: ResponseEntityId,
			sectionItemName: string
		) => {
			setCurrentSectionItemName(sectionItemName)
			setCurrentSectionId(sectionId)
			setDeleteSectionItemModalIsOpen(true)
			switch (sectionItemType) {
				case SectionItemType.TASK:
					setCurrentSectionItemType(SectionItemType.TASK)
					setCurrentSectionItemId(sectionItemId)
					break
				case SectionItemType.TEST:
					setCurrentSectionItemType(SectionItemType.TEST)
					setCurrentSectionItemId(sectionItemId)

					break
				case SectionItemType.LESSON:
					setCurrentSectionItemType(SectionItemType.LESSON)
					setCurrentSectionItemId(sectionItemId)
					break
				case SectionItemType.EVENT:
					setCurrentSectionItemType(SectionItemType.EVENT)
					setCurrentSectionItemId(sectionItemId)
					break
				default:
					break
			}
		},
		[]
	)

	const editSectionItemHandler = useCallback(
		(id: ResponseEntityId, sectionItemType: SectionItemType) => {
			switch (sectionItemType) {
				case SectionItemType.TASK:
					navigate(`/course/${courseId}/task/edit/${id}`)
					break
				case SectionItemType.TEST:
					navigate(`/course/${courseId}/test/edit/${id}`)
					break
				case SectionItemType.LESSON:
					navigate(`/course/${courseId}/lesson/edit/${id}`)
					break
				case SectionItemType.EVENT:
					navigate(`/course/event/edit/${id}`)
					break
				default:
					break
			}
		},
		[]
	)
	const activateTask = useCallback(async (id: ResponseEntityId) => {
		const responseChangeTask = await changeTask({
			taskId: id,
			body: { isActive: 2 }
		})
		const { error: errorChangeTask } =
			responseChangeTask as unknown as ResponseWithError
		if (errorChangeTask) {
			return
		}
		dispatch(courseQuery.util.invalidateTags(['course']))
		onConfirmPublicationModalClose()
	}, [])
	const activateTest = useCallback(async (id: ResponseEntityId) => {
		const responseChangeTest = await changeTest({
			testId: id,
			body: { isActive: 2 }
		})
		const { error: errorChangeTest } =
			responseChangeTest as unknown as ResponseWithError
		if (errorChangeTest) {
			return
		}
		dispatch(courseQuery.util.invalidateTags(['course']))
		onConfirmPublicationModalClose()
	}, [])
	const onConfirmTaskPublication = useCallback(async () => {
		if (!itemConfirmPendingId) return

		activateTask(itemConfirmPendingId)
		setItemConfirmPendingId(null)
		setItemConfirmType(null)
	}, [itemConfirmPendingId])

	const onConfirmTestPublication = useCallback(async () => {
		if (!itemConfirmPendingId) return

		activateTest(itemConfirmPendingId)
		setItemConfirmPendingId(null)
		setItemConfirmType(null)
	}, [itemConfirmPendingId])

	const activateSectionItemHandler = useCallback(
		async (id: ResponseEntityId, sectionItemType: SectionItemType) => {
			switch (sectionItemType) {
				case SectionItemType.TASK:
					const taskInfo = await triggerGetTask({
						taskId: Number(id)
					})

					if (!taskInfo || !taskInfo.data) return

					const { countAttempts, passingScore } = taskInfo.data.data

					if (
						(countAttempts.toString() === '' &&
							passingScore.toString() === '') ||
						(countAttempts === undefined && passingScore === undefined)
					) {
						setConfirmPublicationModalOpen(true)
						setItemConfirmPendingId(id)
						setItemConfirmType(SectionItemType.TASK)
						return
					}
					await activateTask(id)
					break
				case SectionItemType.TEST:
					const testInfo = await triggerGetTest({ testId: id })

					if (!testInfo || !testInfo.data) return

					const {
						countAttempts: countTestAttempts,
						passingScore: testPassingScore
					} = testInfo.data.data

					if (
						(countTestAttempts.toString() === '' &&
							testPassingScore.toString() === '') ||
						(countTestAttempts === undefined && testPassingScore === undefined)
					) {
						setConfirmPublicationModalOpen(true)
						setItemConfirmPendingId(id)
						setItemConfirmType(SectionItemType.TEST)
						return
					}
					activateTest(id)
					break
				case SectionItemType.LESSON:
					const responseChangeLesson = await changeLesson({
						lessonId: id,
						body: await getFormData({ isActive: 2 })
					})
					const { error: errorChangeLesson } =
						responseChangeLesson as unknown as ResponseWithError
					if (errorChangeLesson) {
						return
					}
					dispatch(courseQuery.util.invalidateTags(['course']))
					break
				case SectionItemType.EVENT:
					break
				default:
					break
			}
		},
		[activateTask]
	)
	const deactivateSectionItemHandler = useCallback(
		async (id: ResponseEntityId, sectionItemType: SectionItemType) => {
			switch (sectionItemType) {
				case SectionItemType.TASK:
					const responseChangeTask = await changeTask({
						taskId: id,
						body: { isActive: 1 }
					})
					const { error: errorChangeTask } =
						responseChangeTask as unknown as ResponseWithError
					if (errorChangeTask) {
						return
					}
					dispatch(courseQuery.util.invalidateTags(['course']))
					break
				case SectionItemType.TEST:
					const responseChangeTest = await changeTest({
						testId: id,
						body: { isActive: 1 }
					})
					const { error: errorChangeTest } =
						responseChangeTest as unknown as ResponseWithError
					if (errorChangeTest) {
						return
					}
					dispatch(courseQuery.util.invalidateTags(['course']))
					break
				case SectionItemType.LESSON:
					const responseChangeLesson = await changeLesson({
						lessonId: id,
						body: await getFormData({ isActive: 1 })
					})
					const { error: errorChangeLesson } =
						responseChangeLesson as unknown as ResponseWithError
					if (errorChangeLesson) {
						return
					}
					dispatch(courseQuery.util.invalidateTags(['course']))
					break
				case SectionItemType.EVENT:
					break
				default:
					break
			}
		},
		[]
	)

	const createSectionItemHandler = useCallback((id: ResponseEntityId) => {
		setCurrentCreatingSection(id)
		openCreateSectionItemModalHandler()
	}, [])
	const editSectionHandler = useCallback((id: ResponseEntityId) => {
		openEditSectionModal(id)
	}, [])

	const deleteSectionHandler = useCallback(
		(id: ResponseEntityId, name: string) => {
			setCurrentSectionName(name)
			setCurrentSectionId(id)
			setDeleteSectionModalIsOpen(true)
		},
		[]
	)

	const getDropdownSectionHeader = useCallback(
		(id: ResponseEntityId, name: string): DropdownItem[] => [
			{
				id: getUniqueId(),
				icon: editIconSrc,
				label: 'Создать',
				onClick: createSectionItemHandler.bind(null, id)
			},
			{
				id: getUniqueId(),
				icon: editIconSrc,
				label: 'Редактировать',
				onClick: editSectionHandler.bind(null, id)
			},
			{
				id: getUniqueId(),
				icon: trashIconSrc,
				label: 'Удалить',
				onClick: deleteSectionHandler.bind(null, id, name)
			}
		],
		[editSectionHandler, deleteSectionHandler]
	)

	const getDropdownSectionItemsList = useCallback(
		(
			sectionItemId,
			sectionItemType,
			activity,
			sectionId,
			sectionItemName
		): DropdownItem[] => {
			const activateDropdownItemIndex = 1
			const defaultDropdownItems = [
				{
					id: getUniqueId(),
					icon: editIconSrc,
					label: 'Редактировать',
					onClick: editSectionItemHandler.bind(
						null,
						sectionItemId,
						sectionItemType
					)
				},
				{
					id: getUniqueId(),
					icon: activity ? deactivateIconSrc : activateIconSrc,
					label: activity ? 'Деактивировать' : 'Активировать',
					onClick: activity
						? deactivateSectionItemHandler.bind(
							null,
							sectionItemId,
							sectionItemType
						)
						: activateSectionItemHandler.bind(
							null,
							sectionItemId,
							sectionItemType
						)
				},
				{
					id: getUniqueId(),
					icon: trashIconSrc,
					label: 'Удалить',
					onClick: deleteSectionItemHandler.bind(
						null,
						sectionItemId,
						sectionItemType,
						sectionId,
						sectionItemName
					)
				}
			]

			if (sectionItemType === SectionItemType.EVENT) {
				return defaultDropdownItems.filter(
					(_, i) => i !== activateDropdownItemIndex
				)
			}

			return defaultDropdownItems
		},
		[
			editSectionItemHandler,
			activateSectionItemHandler,
			deactivateSectionItemHandler,
			deleteSectionItemHandler
		]
	)
	const { data: sectionsInfo } = courseQuery.useGetCourseSectionsQuery({
		sectionsIdList
	})

	if (!sectionsInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			<DeleteSectionModal
				sectionName={currentSectionName}
				isModalOpen={deleteSectionModalIsOpen}
				sectionId={currentSectionId || 0}
				onClose={() => setDeleteSectionModalIsOpen(false)}
			/>
			<DeleteSectionItemModal
				onClose={() => setDeleteSectionItemModalIsOpen(false)}
				sectionId={currentSectionId || 0}
				sectionItemId={currentSectionItemId || 0}
				isModalOpen={deleteSectionItemModalIsOpen}
				currentSectionItemName={currentSectionItemName}
				currentSectionItemType={currentSectionItemType}
			/>
			<CreateSectionItemModal
				currentCreatingSection={currentCreatingSection}
				courseId={courseId}
				isModalOpen={createSectionItemModalIsOpen}
				onCloseModal={closeCreateSectionItemModalHandler}
			/>
			<ContentFormTableComponent
				sortSection={sortSection}
				getDropdownSectionItemsList={getDropdownSectionItemsList}
				getDropdownSectionHeader={getDropdownSectionHeader}
				sections={sectionsInfo.data}
			/>
			<ConfirmModal
				isOpen={confirmPublicationModalOpen}
				onClose={onConfirmPublicationModalClose}
				itemType={itemConfirmType!}
				onConfirm={
					itemConfirmType === SectionItemType.TASK
						? onConfirmTaskPublication
						: onConfirmTestPublication
				}
				onGoChangeRestrictions={onGoChangeRestrictions}
			/>
		</>
	)
}

export default ContentFormTable
