import React, { useMemo } from 'react'

import TariffsInfoComponent from 'components/Tariffs/TariffsInfo'
import { IRate } from 'types/models/rate.model'
import { getUniqueId } from 'helpers'
import { useAppSelector } from 'hooks/redux'

export interface TariffItem {
	id: string
	text: string
	value: string
	hide?: boolean
}

export interface TariffsInfoContainerProps {
	currentTariff: IRate | undefined
	usersCount: number
	coursesCount: number
	tariffValidTill: string | null
}

const TariffsInfo: React.FC<TariffsInfoContainerProps> = ({
	currentTariff,
	coursesCount,
	usersCount,
	tariffValidTill
}) => {
	const { tariffFilesCapacity, remainingStorage } = useAppSelector(
		(state) => state.kinescope
	)

	const infoItems = useMemo<TariffItem[]>(
		() => [
			{
				id: getUniqueId(),
				text: 'Ваш тариф',
				value: currentTariff?.value.name || ''
			},
			{
				id: getUniqueId(),
				text: 'Срок действия',
				value: tariffValidTill || ''
			},
			{
				id: getUniqueId(),
				text: 'Пользователей',
				value: `${usersCount} из ${currentTariff?.value.numberUsers}`
			},
			{
				id: getUniqueId(),
				text: 'Курсов',
				value: `${coursesCount} из ${currentTariff?.value.numberCourses}`,
				hide: Boolean(!currentTariff?.value.numberCourses)
			}
		],
		[currentTariff]
	)

	return (
		<TariffsInfoComponent
			infoItems={infoItems}
			remainingStorage={remainingStorage}
			tariffFilesCapacity={tariffFilesCapacity}
		/>
	)
}

export default TariffsInfo
