import React, { FC } from 'react'

import cl from './style.module.scss'

interface EmptyComponentProps {}

const Empty: FC<EmptyComponentProps> = () => (
	<div className={cl.empty}>Кажется, ничего не нашлось</div>
)

export default Empty
