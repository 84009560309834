import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import { testQuery } from 'store/queries'
import TestView from 'containers/Courses/Entities/Tests/View'
import courseQuery from 'store/queries/course.query'

const TestViewPage = () => {
	const { courseId, testId } = useParams()

	if (!(courseId && testId)) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	const [triggerTestQuery, { data: testInfo }] =
		testQuery.endpoints.getTest.useLazyQuery()

	const [triggerCourseQuery, { data: courseInfo }] =
		courseQuery.endpoints.getCourse.useLazyQuery()

	const [triggerGetSections, { data: sectionsInfo }] =
		courseQuery.endpoints.getCourseSections.useLazyQuery()

	useEffect(() => {
		if (!testId || !courseId) {
			return
		}
		triggerTestQuery({ testId: Number(testId) })
		triggerCourseQuery(Number(courseId))
	}, [testId, courseId])

	useEffect(() => {
		if (!courseInfo) return

		triggerGetSections({ sectionsIdList: courseInfo.data.sectionsIdList })
	}, [courseInfo])

	const mappedItems = useMemo(() => {
		if (!sectionsInfo) return undefined

		return sectionsInfo.data.map((i) => i.items).flat()
	}, [sectionsInfo])

	if (!testInfo || !courseInfo) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}
	return (
		<TestView
			userAnswersIdList={testInfo.data.userAnswersIdList}
			courseName={courseInfo.data.name}
			test={testInfo.data}
			courseId={Number(courseId)}
			communicationsInfo={courseInfo.data.communicationsSettings}
			mappedItems={mappedItems!}
		/>
	)
}

export default TestViewPage
