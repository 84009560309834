import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useActions } from 'hooks/redux'
import { getQueryParams } from 'helpers'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'
import cl from 'layouts/main/style.module.scss'

const checkInviteStudentCodeLayout = () => {
	const navigate = useNavigate()
	const { checkInviteStudentCode } = useActions((state) => state.course)

	useEffect(() => {
		const { inviteCode } = getQueryParams()
		if (!inviteCode) {
			return
		}
		checkInviteStudentCode({ inviteCode: `${inviteCode}` })

		navigate('/')
	}, [])

	return (
		<div className={cl.container}>
			<Loader styleTypes={[LoaderStyles.BIG]} />
		</div>
	)
}

export default checkInviteStudentCodeLayout
