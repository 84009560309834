import React, { FC, useCallback } from 'react'

import DeleteSectionModalComponent from 'components/Courses/Tabs/Content/ContentForm/modals/DeleteSection'
import { courseQuery } from 'store/queries'
import { ResponseEntityId, ResponseWithError } from 'types'
import { useAppDispatch } from 'hooks/redux'
import { useParams } from 'react-router-dom'
import { Loader } from 'UI'
import { LoaderStyles } from 'UI/Loader/types'

export interface DeleteSectionModalProps {
	isModalOpen: boolean
	sectionId: ResponseEntityId
	onClose: () => void
	sectionName: string
}

const DeleteSectionModal: FC<DeleteSectionModalProps> = ({
	isModalOpen,
	sectionId,
	onClose,
	sectionName
}) => {
	const dispatch = useAppDispatch()

	const { courseId } = useParams()

	if (!courseId) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	const [deleteSectionQuery] = courseQuery.useDeleteCourseSectionMutation()

	const deleteSection = useCallback(async () => {
		const response = await deleteSectionQuery({
			sectionId,
			courseId: +courseId
		})
		const { error } = response as unknown as ResponseWithError
		if (error) {
			return
		}
		dispatch(courseQuery.util.invalidateTags(['course']))
		onClose()
	}, [sectionId])

	return (
		<DeleteSectionModalComponent
			onClose={onClose}
			sectionName={sectionName}
			isModalOpen={isModalOpen}
			deleteSection={deleteSection}
		/>
	)
}
export default DeleteSectionModal
