import React, { FC } from 'react'
import { MonthTabContainerProps } from 'containers/Timetable/Tabs/MonthTab'
import MonthTable from 'containers/Timetable/Tabs/MonthTab/MonthTable'
import { IEvent } from 'types/models/event.model'

interface MonthTabComponentProps extends MonthTabContainerProps {
	events: IEvent[]
	currentDate: Date
}

const MonthTab: FC<MonthTabComponentProps> = ({
	events,
	currentDate,
	onDetailsOpen
}) => (
	<MonthTable
		events={events}
		currentDate={currentDate}
		onDetailsOpen={onDetailsOpen}
	/>
)

export default MonthTab
