import React, { FC } from 'react'
import {
	Control,
	FieldErrors,
	FieldPath,
	UseFormGetValues,
	UseFormWatch
} from 'react-hook-form'

import {
	Button,
	Datepicker,
	RadioGroup,
	Switch,
	TextInput,
	Timepicker
} from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import ValidateNotify from 'UI/Input/ValidateNotify'
import { LimitationsFormTabState } from 'containers/Courses/Entities/Tasks/Tabs/Content/LimitationsForm'

import cl from './style.module.scss'

interface LimitationsFormTabProps {
	errors: FieldErrors<LimitationsFormTabState>
	onChange: (name: FieldPath<LimitationsFormTabState>) => (value: any) => void
	onSubmit: () => void
	control: Control<LimitationsFormTabState>
	watch: UseFormWatch<LimitationsFormTabState>
	getValues: UseFormGetValues<LimitationsFormTabState>
	onOpenCanceledChange: () => void
}

const LimitationsFormTab: FC<LimitationsFormTabProps> = ({
	control,
	errors,
	onChange,
	onSubmit,
	watch,
	getValues,
	onOpenCanceledChange
}) => (
	<>
		<div className={cl.container}>
			<div className={cl.header}>
				<span>
					Здесь вы можете установить обязательность прохождения задания
				</span>
			</div>
			<form onSubmit={onSubmit} noValidate className={cl.form}>
				<div className={cl.inputs}>
					<div className={cl.inputContainer}>
						<label>Задание является обязательным?</label>
						<RadioGroup
							itemsList={[
								{ id: '2', value: 2, labelTitle: 'Да' },
								{ id: '1', value: 1, labelTitle: 'Нет' }
							]}
							name="taskResponsibilityStatus"
							control={control}
							onChange={onChange('taskResponsibilityStatus')}
						/>
						<ValidateNotify
							className={cl.validateNotify}
							error={errors.taskResponsibilityStatus?.message}
						/>
					</div>
					<div className={cl.inputContainerSplit}>
						<label htmlFor="passingScore">Проходной балл</label>
						<div className={cl.textInputSplitContainer}>
							<TextInput
								error={errors.passingScore?.message}
								placeholder="количество баллов"
								onChange={onChange('passingScore')}
								defaultValue={getValues('passingScore')}
								control={control}
								type="number"
								name="passingScore"
								id="passingScore"
							/>
							<span>из 10 баллов</span>
						</div>
					</div>
					<div className={cl.inputContainerSplit}>
						<label htmlFor="countAttempts">
							Количество попыток для прохождения
						</label>
						<div className={cl.textInputSplitContainer}>
							<TextInput
								error={errors.countAttempts?.message}
								placeholder="количество попыток"
								onChange={onChange('countAttempts')}
								control={control}
								type="number"
								name="countAttempts"
								id="countAttempts"
							/>
							<span />
						</div>
					</div>
					<div className={cl.inputContainer}>
						<label>Ограничение по прохождению</label>
						<Switch
							name="datePassStartSettings.isActive"
							onChange={onChange('datePassStartSettings.isActive')}
							control={control}
							labelTitle="Разрешить выполнение задания с"
						/>
						<div className={cl.datepickerContainer}>
							<Datepicker
								control={control}
								name="datePassStartSettings.date"
								disabled={!watch('datePassStartSettings.isActive')}
							/>
							<Timepicker
								control={control}
								name="datePassStartSettings.time"
								disabled={!watch('datePassStartSettings.isActive')}
							/>
						</div>
						<ValidateNotify
							className={cl.validateError}
							error={
								errors.datePassStartSettings?.date?.message ||
								errors.datePassStartSettings?.time?.message
							}
						/>
						<Switch
							name="datePassEndSettings.isActive"
							onChange={onChange('datePassEndSettings.isActive')}
							control={control}
							labelTitle="Последний срок сдачи"
						/>
						<div className={cl.datepickerContainer}>
							<Datepicker
								control={control}
								name="datePassEndSettings.date"
								disabled={!watch('datePassEndSettings.isActive')}
							/>
							<Timepicker
								control={control}
								name="datePassEndSettings.time"
								disabled={!watch('datePassEndSettings.isActive')}
							/>
						</div>
						<ValidateNotify
							className={cl.validateError}
							error={
								errors.datePassEndSettings?.date?.message ||
								errors.datePassEndSettings?.time?.message
							}
						/>
					</div>
					<div className={cl.splitLine} />
				</div>
				<div className={cl.buttons}>
					<Button
						onClick={onOpenCanceledChange}
						styleTypes={[ButtonStyles.OUTLINE_PRIMARY, ButtonStyles.ROUND]}
					>
						Отменить изменения
					</Button>
					<Button
						type="submit"
						styleTypes={[ButtonStyles.PRIMARY, ButtonStyles.ROUND]}
					>
						Сохранить изменения
					</Button>
				</div>
			</form>
		</div>
	</>
)

export default LimitationsFormTab
