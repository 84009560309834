import { Dayjs } from 'dayjs'

export interface IEvent {
	id: number
	courseId: number
	theme: string
	dateEventStart: string
	time: string
	dateEventEnd: string
	duration: number
	capacity: number
	eventHost: number
	eventType: EventTypes
	comments: string
	timeZone: string
	address?: string
	link?: string
}

export enum EventFilterTypes {
	MONTH = 'month',
	WEEK = 'week',
	DAY = 'day'
}

export enum EventTypes {
	ONLINE_MEETING = 1,
	OFFLINE_MEETING = 2
}

export interface DatePassSettings<T extends Dayjs | string> {
	date: T
	time: string
}

export interface DurationSettings {
	hours: number
	minutes: number
}
