import React, { useCallback, useEffect, useMemo, useState } from 'react'

import CreateEventFormComponent from 'components/Courses/Entities/Events/Create/CreateEventForm'
import { FieldPath, useForm, useWatch } from 'react-hook-form'
import {
	DatePassSettings,
	DurationSettings,
	EventTypes,
	IEvent
} from 'types/models/event.model'
import { useActions, useAppSelector } from 'hooks/redux'
import { courseQuery, eventsQuery, schoolQuery, userQuery } from 'store/queries'

import { IOption } from 'UI/Select/types'
import { LoaderStyles } from 'UI/Loader/types'
import { IRadioGroupItem } from 'UI/RadioGroup/types'
import { Loader } from 'UI'

import {
	ResponseEntityId,
	ResponseWithError,
	ValidationErrorMessages,
	ValidationSuccessMessages
} from 'types'
import { ISection } from 'containers/Courses/Tabs/Content/ContentForm/Table'
import {
	getMillisecondsFromTimeObject,
	getUniqueId,
	dateIsMoreThanToday
} from 'helpers'
import dayjs from 'dayjs'
import { getMillisecondsFromTime } from 'helpers/getMillisecondsFromTime'
import { StatusType } from 'types/models/course.model'
import CreationCancelledModal from 'containers/Courses/Entities/Events/Create/CreationCancelledModal'
import DeleteEventModal from './DeleteEventModal'

export interface ChangeEventFormValues
	extends Omit<IEvent, 'dateEventStart' | 'duration' | 'timeZone'> {
	dateEventStart: DatePassSettings<string>
	duration: DurationSettings
	timeZone: TimeZoneSelectOption
	courseId: ResponseEntityId
	sectionId: ResponseEntityId
}

export interface TimeZoneSelectOption {
	value: string
	label: string
	offset: number
	abbrev: string
	altName: string
}

export interface CreateEventFormContainerProps {
	event: IEvent
}

const EditEventForm: React.FC<CreateEventFormContainerProps> = ({ event }) => {
	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		watch,
		control,
		formState: { errors }
	} = useForm<ChangeEventFormValues>()

	const [zoomCallActive, setZoomCallActive] = useState(!!event.link)
	const { pushError, pushSuccess } = useActions((state) => state.system)

	const { currentSchoolId, currentRole } = useAppSelector(
		(state) => state.system
	)

	const [editCancelledModalOpen, setEditCancelledModalOpen] = useState(false)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [sectionId, setSectionId] = useState<number>()

	const { data: schoolData, isLoading } = schoolQuery.useGetSchoolByIdQuery({
		schoolId: Number(currentSchoolId)
	})

	const { data: userInfo } = userQuery.useGetYourselfQuery()

	const [
		triggerGetTeachers,
		{ data: teachersInfo, isLoading: teachersLoading }
	] = userQuery.endpoints.getUsers.useLazyQuery()

	const [trigger, { data: coursesInfo, isLoading: coursesLoading }] =
		courseQuery.endpoints.getCourses.useLazyQuery()

	const [changeEvent] = eventsQuery.useChangeEventMutation()

	useEffect(() => {
		// на бэк почему-то уходит вчерашнее число, поэтому так
		const ms = new Date(getValues('dateEventStart.date')).getTime() + 86400000
		const tomorrow = new Date(ms)
		register('id')
		register('capacity')
		register('courseId', {
			validate: (val) => !!val || ValidationErrorMessages.EMPTY
		})
		register('dateEventEnd')
		register('dateEventStart.date', {
			validate: (value) =>
				!(
					getValues('dateEventStart.date') &&
					new Date(value).toLocaleDateString()
				) ||
				dateIsMoreThanToday(tomorrow) ||
				ValidationErrorMessages.DATEPICKER_DATE_CANNOTBE_LESS_THAN_TODAY
		})
		register('dateEventStart.time', {
			validate: (value) => !!value || ValidationErrorMessages.EMPTY
		})
		register('timeZone', {
			validate: (value) => !!value || ValidationErrorMessages.EMPTY
		})
		register('duration.hours', {
			validate: (val) => val === 0 || !!val || ValidationErrorMessages.EMPTY
		})
		register('duration.minutes', {
			validate: (val) => {
				if (!Number(getValues('duration.hours')) && !Number(val)) {
					return ValidationErrorMessages.EMPTY
				}
				return !!val || ValidationErrorMessages.EMPTY
			}
		})
		register('eventHost', {
			validate: (val) => !!val || ValidationErrorMessages.EMPTY
		})
		register('eventType')
		register('theme', {
			validate: (val) => !!val || !!val?.trim() || ValidationErrorMessages.EMPTY
		})
		register('comments')
		register('link')
		register('address')
	}, [getValues()])

	useEffect(() => {
		const date = new Date(
			event.dateEventStart.replaceAll('-', '.').split('T')[0]
		)

		const durationInfo = getMillisecondsFromTime(event.duration)
		setValue('id', event.id)
		setValue('eventType', event.eventType)
		setTimeout(
			() =>
				setValue('timeZone', {
					value: event.timeZone,
					label: event.timeZone,
					altName: '',
					abbrev: '',
					offset: 0
				}),
			0
		)

		setValue('capacity', event.capacity)
		setValue('comments', event.comments)
		setValue('courseId', event.courseId)
		setValue('eventHost', event.eventHost)
		setValue('theme', event.theme)
		setValue('dateEventStart.date', dayjs(date) as unknown as string)
		setValue('dateEventStart.time', event.time)
		setValue('duration.hours', durationInfo.hours! || 0)
		setValue('duration.minutes', durationInfo.minutes!)
		setValue('link', event.link)
		setValue('address', event.address?.replaceAll('&quot;', '"'))
	}, [event])

	const onSubmit = useCallback(
		handleSubmit(async ({ duration, ...data }) => {
			const inMs = getMillisecondsFromTimeObject({
				hours: duration.hours,
				minutes: duration.minutes || 0
			})
			// на бэк почему-то уходит вчерашнее число, поэтому так
			const dateStart = dayjs(data.dateEventStart.date).set('hour', 0).set('minute', 0).set('second', 0).add(1, 'day')
			const response = await changeEvent({
				...data,
				// на бэк почему-то уходит вчерашнее число, поэтогму так
				dateEventStart: dateStart as unknown as string,
				duration: inMs,
				timeZone: data.timeZone.value,
				eventId: event.id,
				time: data.dateEventStart.time
			})

			const { error } = response as unknown as ResponseWithError

			if (error) {
				pushError(error.data)

				return
			}

			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}

			pushSuccess(successMessage)
		}),
		[]
	)

	const onChange = useCallback(
		(name: FieldPath<ChangeEventFormValues>) => (value: any) => {
			setValue(name, value)
		},
		[]
	)

	const [triggerGetSections] = courseQuery.useLazyGetCourseSectionsQuery()

	const onZoomLinkToggle = useCallback(
		() => setZoomCallActive((val) => !val),
		[setZoomCallActive]
	)

	const onCancelModalOpen = useCallback(() => {
		setEditCancelledModalOpen(true)
	}, [])

	const onCancelModalClose = useCallback(() => {
		setEditCancelledModalOpen(false)
	}, [])

	const onDeleteModalOpen = useCallback(async () => {
		const currentCousre = coursesInfo?.data.find(course => course.id === event.courseId)
		if (currentCousre?.sectionsIdList) {
			const sections = await triggerGetSections({
				sectionsIdList: currentCousre?.sectionsIdList
			})
			sections.data?.data.find(section => (
				section.items.find(item => {
					if (item.id === event.id) {
						setSectionId(section.id)
						return true
					}
				})
			))
		}

		setDeleteModalOpen(true)
	}, [coursesInfo])

	const onDeleteModalClose = useCallback(() => {
		setDeleteModalOpen(false)
	}, [])

	useEffect(() => {
		if (!schoolData) return

		trigger({ role: currentRole, coursesIdList: schoolData.data.coursesIdList })
		triggerGetTeachers({ usersIdList: schoolData.data.usersIdList })
	}, [schoolData])

	const mappedCourses = useMemo(() => {
		if (!coursesInfo) return []

		return coursesInfo.data
			.filter((c) => c.statusType === StatusType.PUBLISHED)
			.map((c) => ({ name: c.name, id: c.id }))
	}, [coursesInfo])

	const selectItems = useMemo<IOption[]>(() => {
		if (!mappedCourses) return []

		return mappedCourses.map((c) => ({
			text: c.name,
			value: c.id
		}))
	}, [mappedCourses])

	const eventTypeItems = useMemo<IRadioGroupItem[]>(
		() => [
			{
				id: getUniqueId(),
				labelTitle: 'Онлайн-встреча',
				value: EventTypes.ONLINE_MEETING
			},
			{
				id: getUniqueId(),
				labelTitle: 'Офлайн-встреча',
				value: EventTypes.OFFLINE_MEETING
			}
		],
		[]
	)

	const mappedTeachersItems = useMemo<IOption[]>(() => {
		if (!teachersInfo || !userInfo) return []

		const { id, firstName, lastName } = userInfo.data
		const initials = `${firstName} ${lastName}`

		const getCurrentUserOption = () => ({ text: initials, value: id })

		return [
			getCurrentUserOption(),
			...teachersInfo.data.map((t) => ({
				text: `${t.firstName} ${t.lastName}`,
				value: t.id
			}))
		]
	}, [teachersInfo, userInfo])

	// const sectionId = useWatch({
	// 	control,
	// 	name: 'sectionId'
	// })

	if (isLoading || teachersLoading || coursesLoading) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<>
			<CreateEventFormComponent
				control={control}
				watch={watch}
				errors={errors}
				onSubmit={onSubmit}
				onChange={onChange}
				selectItems={selectItems}
				eventTypeItems={eventTypeItems}
				mappedTeachersItems={mappedTeachersItems}
				onZoomLinkToggle={onZoomLinkToggle}
				zoomCallActive={zoomCallActive}
				onCancelCreating={onCancelModalOpen}
				onDeleteEvent={onDeleteModalOpen}
				update
				needToSelectSection={false}
				sectionSelectItems={[]}
			/>

			<CreationCancelledModal
				isOpen={editCancelledModalOpen}
				onClose={onCancelModalClose}
			/>
			<DeleteEventModal
				isOpen={deleteModalOpen}
				onClose={onDeleteModalClose}
				eventId={event.id}
				sectionId={sectionId as number}
				courseId={event.courseId}
			/>
		</>
	)
}

export default EditEventForm
