import React, { FC } from 'react'
import { Button, Modal } from 'UI'
import { ModalHeight } from 'UI/Modal/types'
import cl from 'containers/Courses/Table/Modals/ConfirmUnPublish/style.module.scss'
import { ButtonStyles } from 'UI/Button/types'
import { DeleteSectionModalProps as DeleteSectionModalContainerProps } from 'containers/Courses/Tabs/Content/ContentForm/modals/DeleteSection'

interface DeleteSectionModalProps
	extends Omit<DeleteSectionModalContainerProps, 'sectionId'> {
	deleteSection: () => void
	sectionName: string
}

const DeleteSectionModal: FC<DeleteSectionModalProps> = ({
	sectionName,
	deleteSection,
	isModalOpen,
	onClose
}) => (
	<Modal
		styleTypes={[ModalHeight.SMALL]}
		isOpen={isModalOpen}
		title="Удалить раздел?"
		description={`Вы действительно хотите удалить раздел "${sectionName}"`}
		onClose={onClose}
	>
		<div className={cl.buttonsContainer}>
			<Button
				onClick={onClose}
				styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
			>
				Отмена
			</Button>
			<Button
				onClick={() => deleteSection()}
				styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
			>
				Удалить
			</Button>
		</div>
	</Modal>
)

export default DeleteSectionModal
