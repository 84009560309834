import React, { FC } from 'react'

import cl from 'UI/EmptyContent/style.module.scss'

interface EmptyTableViewProps {
	title: string
	imageSrc: string
}

const EmptyTableView: FC<EmptyTableViewProps> = ({ title, imageSrc }) => (
	<div className={cl.container}>
		<img src={imageSrc} alt="no-content" />
		<span>{title}</span>
	</div>
)

export default EmptyTableView
