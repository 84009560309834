import React, { FC } from 'react'

import StudentsFormTabComponent from 'components/Courses/Tabs/Content/StudentsForm'
import { ICourse } from 'types/models/course.model'

export interface StudentsFormTabProps {
	courseInfo: ICourse
}

const StudentsFormTab: FC<StudentsFormTabProps> = ({ courseInfo }) => (
	<StudentsFormTabComponent courseInfo={courseInfo} />
)

export default StudentsFormTab
