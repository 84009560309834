import React, {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'

import classNames from 'classnames'
import { getUniqueId } from 'helpers'
import {
	clearFileInput,
	getFileList,
	getImageUrlFromFile,
	validateFiles
} from 'helpers/files'
import Button from 'UI/Button'
import { ButtonStyles } from 'UI/Button/types'
import { TaskAnswerDoc } from 'types/models/task.model'
import plusIconSrc from 'UI/Button/images/plus-black-quarternary.svg'
import deleteIcon from 'UI/FileInput/images/delete.svg'

import { FileInputSingleProps } from '../types'

import cl from '../style.module.scss'

const DragAndDropSingle: FC<FileInputSingleProps> = ({
	name,
	accept,
	onChange,
	size,
	id,
	previewFile,
	onDeleteFile,
	className,
	...defaultProps
}) => {
	const uniqueId = useMemo(getUniqueId.bind(null, id), [])

	const fileInput = useRef<HTMLInputElement>(null)

	const [filePreview, setFilePreview] = useState<TaskAnswerDoc | null>(null)

	useEffect(() => {
		setFilePreview(previewFile)
	}, [previewFile])

	const changeHandler = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			if (!event.target.files?.length) {
				return
			}
			const filesList = [...event.target.files]
			const { typesIsValid, sizeIsValid } = validateFiles({
				files: filesList,
				accept,
				size
			})

			if (!typesIsValid || !sizeIsValid) {
				clearFileInput(event.target)
				onChange()
				return
			}

			const fileList = await getFileList(filesList)
			event.target.files = fileList

			const file = [...fileList].pop()
			const currentFileUrl = await getImageUrlFromFile(filesList.pop())
			if (!file || !currentFileUrl) {
				return
			}

			onChange(file)
		},
		[]
	)

	const deleteHandler = useCallback(() => {
		if (!fileInput.current) {
			return
		}

		if (onDeleteFile) {
			onDeleteFile(fileInput.current.files?.item(0) as File)
		}

		onChange()
		clearFileInput(fileInput.current)
		setFilePreview(null)
	}, [fileInput.current])

	return (
		<div className={classNames(cl.fileInputWrapper, className)}>
			<div {...defaultProps} className={cl.fileInput}>
				<input
					name={name}
					ref={fileInput}
					accept={accept}
					onChange={changeHandler}
					id={uniqueId}
					type="file"
				/>
				<Button styleTypes={[ButtonStyles.OUTLINE_DARK, ButtonStyles.ROUND]}>
					<img src={plusIconSrc} alt="plus" />
					<label htmlFor={uniqueId}>Прикрепить файл</label>
				</Button>
			</div>
			{filePreview && (
				<div className={cl.fileInputMultiplePreview}>
					<div className={cl.uploadFile} key={filePreview.title}>
						<span>{filePreview.title}</span>
						<Button onClick={() => deleteHandler()}>
							<img src={deleteIcon} alt="delete" />
						</Button>
					</div>
				</div>
			)}
		</div>
	)
}

export default DragAndDropSingle
