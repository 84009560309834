import React, { FC } from 'react'
import { Button, Modal } from 'UI'
import { ModalHeight } from 'UI/Modal/types'
import cl from 'containers/Courses/Table/Modals/ConfirmUnPublish/style.module.scss'
import { ButtonStyles } from 'UI/Button/types'
import { useNavigate } from 'react-router-dom'

interface CancelChangeModalProps {
	isOpen: boolean
	onClose: () => void
	redirectUri?: string
}

const CancelChangeModal: FC<CancelChangeModalProps> = ({
	onClose,
	isOpen,
	redirectUri
}) => {
	const navigate = useNavigate()
	return (
		<Modal
			onClose={onClose}
			title="Внесенные изменения будут потеряны"
			description="Вы действительно хотите отменить внесенные изменения? Все измененные данные будут потеряны."
			isOpen={isOpen}
			styleTypes={[ModalHeight.SMALL]}
		>
			<div className={cl.buttonsContainer}>
				<div className={cl.buttonsContainer}>
					<Button
						onClick={onClose}
						styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
					>
						Отмена
					</Button>
					<Button
						onClick={() => {
							navigate(redirectUri || `/`)
						}}
						styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
					>
						Отменить изменения
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default CancelChangeModal
