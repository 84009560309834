import React, { FC } from 'react'
import classNames from 'classnames'
import { CoursesTableBodyProps as CoursesTableBodyContainerProps } from 'containers/MobileTables/Courses/CoursesTableBody'
import { Avatar, Dropdown } from 'UI'

import { actionIconSrc } from 'containers/Users/Table/images/icons'
import { IUser } from 'types/models/user.model'
import { getFullName } from 'helpers'
import schoolHatImage from 'components/Courses/StudentRole/Content/images/school-hat.png'

import cl from '../style.module.scss'

interface CoursesTableBodyProps extends CoursesTableBodyContainerProps {
	courseCreator?: IUser
	lessonsCount?: number
	unverifiedAnswersCount?: number
}

const CoursesTableBody: FC<CoursesTableBodyProps> = ({
	getDropdownItemsList,
	courseCreator,
	course,
	lessonsCount,
	unverifiedAnswersCount
}) => (
	<div key={course.id} className={classNames(cl.tableMobileCell)}>
		<div className={cl.mobileHeader}>
			<div className={cl.personalInfo}>
				{course.coverUrl ? (
					<Avatar photoUrl={course.coverUrl} />
				) : (
					<img src={schoolHatImage} className={cl.hat} alt="Hat" />
				)}
				<span className={cl.initialsOfName}>{course.name}</span>
			</div>
			<Dropdown dropdownItemsList={getDropdownItemsList(course)}>
				<img src={actionIconSrc} alt="actions" />
			</Dropdown>
		</div>
		<div className={cl.mobileBody}>
			<div className={cl.mobileItem}>
				<p>Автор</p>
				<p>
					{courseCreator
						? getFullName({
							firstName: courseCreator.firstName,
							lastName: courseCreator.lastName
						})
						: '-'}
				</p>
			</div>
			<div className={cl.mobileItem}>
				<p>количество уроков</p>
				<p>{lessonsCount || '-'}</p>
			</div>
			<div className={cl.mobileItem}>
				<p>Непроверенных ответов</p>
				<p>{unverifiedAnswersCount || '-'}</p>
			</div>
			<div className={cl.mobileItem}>
				<p>Дата создания</p>
				<p>{course.dateCreate}</p>
			</div>
		</div>
	</div>
)

export default CoursesTableBody
