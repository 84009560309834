import React, { FC } from 'react'
import { AttemptsTableProps as AttemptsTableContainerProps } from 'containers/Courses/Entities/Tests/Check/AttemptsTable'
import { ITestAnswer } from 'store/queries/test.query/types'
import { Button } from 'UI'
import classNames from 'classnames'
import AnswerCreator from 'containers/Courses/Entities/Tests/Check/AttemptsTable/AnswerCreator'
import { ButtonStyles } from 'UI/Button/types'
import { useNavigate } from 'react-router-dom'
import { useActions } from 'hooks/redux'

import cl from './style.module.scss'

interface AttemptsTableProps extends AttemptsTableContainerProps {
	testAnswersInfo: ITestAnswer[]
	setCurrentChecking: (_: string) => void
	setCurrentAttempt: (_: string) => void
	sortNormal?: boolean
}

const AttemptsTable: FC<AttemptsTableProps> = ({
	testAnswersInfo,
	testQuestionsCount,
	courseId,
	testId,
	userAnswersIdList,
	setCurrentChecking,
	setCurrentAttempt,
	sortNormal
}) => {
	const navigate = useNavigate()
	const { setUsersAnswersIdList } = useActions((state) => state.test)

	return (
		<div className={cl.table}>
			<div className={cl.tableHeader}>
				<span>Ученик</span>
				<span>Статус</span>
				<span>Верные ответы</span>
				<span>Дата ответа</span>
				<span>Просмотреть</span>
			</div>
			<div className={classNames(sortNormal ? cl.tableBodyNormal : cl.tableBody)}>
				{testAnswersInfo.map((answer) => (
					<div className={cl.tableBodyItem} key={answer.id}>
						<AnswerCreator creatorId={answer.creatorId} hideAvatarMobile />
						<span>{answer.answerStatus}</span>
						<span>
							{answer.correctAnswersCount} из {testQuestionsCount}
						</span>
						<span>{answer.dateCreate}</span>
						<span>
							<Button
								onClick={() => {
									navigate(
										`/course/${courseId}/test/${testId}/answer/attempt/view/${answer.id}`
									)
									setCurrentChecking(answer.id.toString())

									if (setCurrentAttempt) {
										setCurrentAttempt(answer.attempt.toString())
									}
								}}
								styleTypes={[ButtonStyles.DEFAULT_BG, ButtonStyles.ROUND]}
							>
								Просмотреть
							</Button>
						</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default AttemptsTable
