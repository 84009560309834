import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import NotificationsSettingsTableComponent from 'components/NotificationSettingsForm/NotificationSettingsTable'
import { FieldPath, useForm } from 'react-hook-form'
import { INotificationEvent } from 'types/notification-event.model'
import {
	NotificationCourseType,
	NotificationSetting,
	NotificationType
} from 'store/queries/user.query/types'
import { courseQuery, userQuery } from 'store/queries'
import { useActions, useAppSelector } from 'hooks/redux'
import { ReminderItemValue } from 'containers/Courses/Tabs/Content/NotificationsForm'
import { getDaysByWeeks } from 'helpers/time'

export interface EventFormItem {
	text: string
	eventId: number
	push: boolean
	email: boolean
	teacher: boolean
	curator: boolean
	student: boolean
}

export interface SettingsFormValues {
	isActive: boolean
	events: EventFormItem[]
}

export interface NotificationsSettingsTableContainerProps {
	events: INotificationEvent[]
	settings: NotificationSetting
	isActive: boolean
	courseSettings?: boolean
	reminderValue?: ReminderItemValue[]
}

const NotificationsSettingsTable: React.FC<
	NotificationsSettingsTableContainerProps
> = ({ events, settings, isActive, courseSettings, reminderValue }) => {
	const { register, handleSubmit, control, getValues, setValue, watch } =
		useForm<SettingsFormValues>()

	const [cancelModalOpen, setCancelModalOpen] = useState(false)

	const { courseId } = useParams()

	const { pushSuccess } = useActions((state) => state.system)
	const { currentRole } = useAppSelector((state) => state.system)

	const [triggerChangeSettings] =
		userQuery.useChangeNotificationSettingsMutation()

	const [triggerChangeCourseSettings] =
		courseQuery.useChangeNotificationSettingsMutation()

	const navigate = useNavigate()

	const setNotificationSettings = useCallback(() => {
		if (!settings) return

		Object.entries(settings).forEach(([id, value]) => {
			const foundEventIndex = events.findIndex((e) => e.id.toString() === id)

			if (foundEventIndex === -1) return

			events.forEach((e, i) => {
				if (e.id.toString() !== id) return

				if (courseSettings) {
					const courseValue = value as unknown as NotificationCourseType[]

					setValue(
						`events.${i}.teacher`,
						courseValue.includes(NotificationCourseType.TEACHER)
					)
					setValue(
						`events.${i}.student`,
						courseValue.includes(NotificationCourseType.STUDENT)
					)
					setValue(
						`events.${i}.curator`,
						courseValue.includes(NotificationCourseType.CURATOR)
					)
				}

				setValue(`events.${i}.push`, value.includes(NotificationType.PUSH))
				setValue(`events.${i}.email`, value.includes(NotificationType.EMAIL))
			})
		})
	}, [getValues, settings, setValue, courseSettings])

	const onSubmit = useCallback(
		handleSubmit(async (data) => {
			const userNotificationSettings = {} as any

			if (!courseSettings) {
				data.events
					.filter((e) => e.email || e.push)
					.forEach((e) => {
						const resultArray = []

						if (e.email) {
							resultArray.push(NotificationType.EMAIL)
						}
						if (e.push) {
							resultArray.push(NotificationType.PUSH)
						}

						userNotificationSettings[e.eventId.toString()] = resultArray
					})
			}

			if (courseSettings) {
				data.events
					.filter((e) => e.curator || e.student || e.teacher)
					.forEach((e) => {
						const resultArray = []

						if (e.curator && courseSettings) {
							resultArray.push(NotificationCourseType.CURATOR)
						}
						if (e.student && courseSettings) {
							resultArray.push(NotificationCourseType.STUDENT)
						}
						if (e.teacher && courseSettings) {
							resultArray.push(NotificationCourseType.TEACHER)
						}

						userNotificationSettings[e.eventId.toString()] = resultArray
					})
			}

			if (courseSettings && courseId && reminderValue) {
				const reminder = reminderValue.map((item) =>
					getDaysByWeeks(+item.inNumber, item.timeMeasure)
				)

				await triggerChangeCourseSettings({
					notificationsSettings: {
						noticeSettings: {
							eventInformingSettings: userNotificationSettings,
							isActive: data.isActive
						},
						reminderDelaysDaysList: reminder
					},
					courseId: +courseId
				})
			} else {
				await triggerChangeSettings({
					userNotificationSettings,
					isActive: data.isActive,
					role: currentRole
				})
			}

			pushSuccess({ message: 'Настройки успешно изменены' })
		}),
		[courseId, courseSettings, reminderValue]
	)

	const onChange = useCallback(
		(name: FieldPath<SettingsFormValues>) => (value: any) => {
			setValue(name, value)
		},
		[]
	)

	const onCloseModal = useCallback(() => setCancelModalOpen(false), [])
	const onOpenModal = useCallback(() => setCancelModalOpen(true), [])
	const onConfirmModal = useCallback(() => {
		onCloseModal()
		navigate('/')
	}, [onCloseModal])

	useEffect(() => {
		register('isActive')

		if (courseSettings) {
			events.forEach((e, i) => {
				register(`events.${i}.student`)
				register(`events.${i}.curator`)
				register(`events.${i}.teacher`)
				register(`events.${i}.eventId`)
			})

			return
		}

		events.forEach((e, i) => {
			register(`events.${i}.push`)
			register(`events.${i}.email`)
			register(`events.${i}.eventId`)
		})
	}, [events, courseSettings])

	useEffect(() => {
		events.forEach((e, i) => {
			setValue(`events.${i}.eventId`, e.id)
			setValue(`events.${i}.text`, e.text)
		})
	}, [events])

	useEffect(() => {
		setNotificationSettings()
		setValue('isActive', isActive)
	}, [isActive])

	return (
		<NotificationsSettingsTableComponent
			onSubmit={onSubmit}
			onChange={onChange}
			events={getValues('events')}
			control={control}
			watch={watch}
			onOpenModal={onOpenModal}
			onCloseModal={onCloseModal}
			onConfirmModal={onConfirmModal}
			cancelModalOpen={cancelModalOpen}
			courseSettings={courseSettings}
		/>
	)
}

export default NotificationsSettingsTable
