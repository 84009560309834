import { SectionItemType } from 'containers/Courses/Tabs/Content/ContentForm/Table'
import React, { FC } from 'react'
import { Button, Modal } from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import { ModalSize } from 'UI/Modal/types'

import cl from './style.module.scss'

interface ConfirmModalProps {
	isOpen: boolean
	onClose: () => void
	onConfirm: () => Promise<void>
	itemType: SectionItemType
	onGoChangeRestrictions?: () => void
}

function getItemType(type: SectionItemType) {
	switch (type) {
		case SectionItemType.TASK:
			return 'задания'
		case SectionItemType.TEST:
			return 'тестирования'
		default:
			return ''
	}
}

const ConfirmModal: FC<ConfirmModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	itemType,
	onGoChangeRestrictions
}) => {
	const type = getItemType(itemType)
	const description = `Вы действительно хотите опубликовать? Для публикации нужно заполнить раздел "Ограничения"`

	const onChangeRestrictions = () => {
		onClose()

		if (onGoChangeRestrictions) {
			onGoChangeRestrictions()
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			styleTypes={[ModalSize.BIG]}
			onClose={onClose}
			title={`Публикация ${type}`}
			description={description}
		>
			<div className={cl.buttons}>
				<Button
					onClick={onChangeRestrictions}
					styleTypes={[ButtonStyles.PRIMARY_HOLLOW]}
				>
					Перейти к заполнению
				</Button>
				<Button onClick={onConfirm} styleTypes={[ButtonStyles.PRIMARY]}>
					Опубликовать
				</Button>
			</div>
		</Modal>
	)
}

export default ConfirmModal
