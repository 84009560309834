import React, { FC, useCallback, useEffect, useState } from 'react'
import { FieldPath, useForm } from 'react-hook-form'

import { Select } from 'UI'
import { IOption, SelectBorderStyles, SelectSizeStyles } from 'UI/Select/types'
import { DocsFilesUrlList, ILesson } from 'types/models/lesson.model'
import {
	ResponseEntityId,
	ResponseWithError,
	ValidationSuccessMessages
} from 'types'
import lessonQuery from 'store/queries/lesson.query'
import { getFormData } from 'helpers'
import { useActions, useAppDispatch } from 'hooks/redux'
import courseQuery from 'store/queries/course.query'

import classnames from 'classnames'

import cl from './style.module.scss'

interface LessonStatusFormState extends Pick<ILesson, 'isActive'> {}

interface LessonStatusProps {
	isActive: ResponseEntityId
	lessonId: ResponseEntityId
	docsFilesUrlList: DocsFilesUrlList[]
}

const LessonStatus: FC<LessonStatusProps> = ({
	lessonId,
	isActive,
	docsFilesUrlList
}) => {
	const dispatch = useAppDispatch()
	const { pushSuccess, pushError } = useActions((state) => state.system)
	const [changeLesson] = lessonQuery.useChangeLessonMutation()
	const [statusOptionList] = useState<IOption[]>([
		{
			value: 2,
			text: 'Активный'
		},
		{
			value: 1,
			text: 'Неактивный'
		}
	])

	const { register, setValue, control } = useForm<LessonStatusFormState>()

	useEffect(() => {
		register('isActive')
	}, [])

	useEffect(() => {
		setValue('isActive', isActive)
	})

	const changeHandler = useCallback(
		(name: FieldPath<LessonStatusFormState>) => async (value: any) => {
			setValue(name, value)
			const response = await changeLesson({
				lessonId: +lessonId,
				body: await getFormData({
					isActive: value
				})
			})
			const { error } = response as unknown as ResponseWithError
			if (error) {
				pushError(error.data)
				return
			}
			dispatch(courseQuery.util.invalidateTags(['course']))
			const successMessage = {
				message: ValidationSuccessMessages.SUCCESS_CHANGE
			}
			pushSuccess(successMessage)
		},
		[docsFilesUrlList]
	)

	return (
		<Select
			className={classnames([
				cl.statusSelect,
				{
					[cl.statusSelectActive]: isActive === 2,
					[cl.statusSelectNonActive]: isActive === 1
				}
			])}
			styleTypes={[SelectSizeStyles.SMALL, SelectBorderStyles.ROUND]}
			control={control}
			onChange={changeHandler('isActive')}
			name="isActive"
			optionsList={statusOptionList}
		/>
	)
}

export default LessonStatus
