import React, { FC } from 'react'
import { Modal } from 'UI'
import { ModalHeight } from 'UI/Modal/types'
import certificateTemplateUrl from './img/cetificate_template.svg'

import cl from './style.module.scss'

interface CertificateTemplateModalProps {
	isOpen: boolean
	onClose: () => void
}

const CertificateTemplateModal: FC<CertificateTemplateModalProps> = ({
	isOpen,
	onClose
}) => (
	<Modal
		title="Шаблон сертификата от учебной платформы"
		isOpen={isOpen}
		onClose={onClose}
		styleTypes={[ModalHeight.SMALL]}
	>
		<div className={cl.container}>
			<img src={certificateTemplateUrl} alt="template_certificate" />
		</div>
	</Modal>
)

export default CertificateTemplateModal
