import { getResponse } from 'helpers/api'

import { CourseService as ICourseService, CourseApiRoutes } from './types'

const CourseService: ICourseService = {
	checkInviteStudentCode(body) {
		const apiUrl = CourseApiRoutes.CHECK_INVITE_CODE
		return getResponse(apiUrl, body)
	},
	inviteStudent({ courseId, email }) {
		const apiUrl = `courses/${courseId}/invite-student`

		return getResponse(apiUrl, { email })
	}
}

export default CourseService
