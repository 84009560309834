import React, { FC, useCallback } from 'react'
import GoBackComponent from 'components/Timetable/Tabs/ScheduleTab/ScheduleTable/GoBack'

export interface GoBackContainerProps {
	dateString: string
	currentDate: Date
	onChange: (value: any) => void
}

const GoBack: FC<GoBackContainerProps> = ({
	dateString,
	currentDate,
	onChange
}) => {
	const onGoBack = useCallback(() => {
		const newDate = new Date(+currentDate)
		newDate.setDate(newDate.getDate() - 1)
		onChange(newDate)
	}, [currentDate, onChange])

	return <GoBackComponent onGoBack={onGoBack} dateString={dateString} />
}

export default GoBack
