import { Divider } from 'antd'
import {
	CreateEventFormContainerProps,
	CreateEventFormValues
} from 'containers/Courses/Entities/Events/Create/CreateEventForm'
import React from 'react'
import { Control, FieldErrors, FieldPath, UseFormWatch } from 'react-hook-form'
import CameraIcon from 'components/Courses/Entities/Events/images/camera.svg'
import {
	Button,
	Datepicker,
	RadioGroup,
	Select,
	TextArea,
	TextInput,
	Timepicker
} from 'UI'
import { ButtonStyles } from 'UI/Button/types'
import ValidateNotify from 'UI/Input/ValidateNotify'
import { IRadioGroupItem } from 'UI/RadioGroup/types'
import { IOption } from 'UI/Select/types'

import TimezoneSelect from 'react-timezone-select'
import classNames from 'classnames'

import { EventTypes } from 'types/models/event.model'
import cl from './style.module.scss'

interface CreateEventFormComponentProps
	extends Omit<CreateEventFormContainerProps, 'sectionId'> {
	control: Control<CreateEventFormValues>
	watch: UseFormWatch<CreateEventFormValues>
	errors: FieldErrors<CreateEventFormValues>
	onSubmit: () => void
	onChange: (name: FieldPath<CreateEventFormValues>) => (value: any) => void
	selectItems: IOption[]
	eventTypeItems: IRadioGroupItem[]
	mappedTeachersItems: IOption[]
	onZoomLinkToggle: () => void
	onCancelCreating: () => void
	onDeleteEvent?: () => void
	zoomCallActive: boolean
	update?: boolean
	needToSelectSection: boolean
	sectionSelectItems: IOption[]
	disableSubmitButton?: boolean
}

const CreateEventForm: React.FC<CreateEventFormComponentProps> = ({
	control,
	errors,
	selectItems,
	eventTypeItems,
	mappedTeachersItems,
	zoomCallActive,
	update,
	watch,
	onSubmit,
	onChange,
	onZoomLinkToggle,
	onCancelCreating,
	onDeleteEvent,
	needToSelectSection,
	sectionSelectItems,
	disableSubmitButton
}) => (
	<div className={cl.content}>
		<Divider />

		{needToSelectSection && (
			<div className={cl.inputContainer}>
				<label htmlFor="courseId">Курс *</label>

				<Select
					optionsList={selectItems}
					control={control}
					disabled={needToSelectSection}
					name="courseId"
					id="courseId"
					onChange={onChange('courseId')}
				/>

				<ValidateNotify error={errors.courseId?.message} />
			</div>
		)}

		{needToSelectSection && (
			<div className={cl.inputContainer}>
				<label htmlFor="sectionId">Раздел курса *</label>

				<Select
					optionsList={sectionSelectItems}
					control={control}
					name="sectionId"
					id="sectionId"
					onChange={onChange('sectionId')}
				/>

				<ValidateNotify error={errors.sectionId?.message} />
			</div>
		)}

		<div className={cl.inputContainer}>
			<label htmlFor="theme">Тема мероприятия *</label>

			<TextInput
				control={control}
				name="theme"
				id="theme"
				onChange={onChange('theme')}
			/>

			<ValidateNotify error={errors.theme?.message} />
		</div>

		<div className={cl.inputContainer}>
			<label>Дата начала *</label>

			<div className={cl.datepickerContainer}>
				<Datepicker control={control} name="dateEventStart.date" />
				<Timepicker control={control} name="dateEventStart.time" />
			</div>

			<TimezoneSelect
				value={
					watch('timeZone') || Intl.DateTimeFormat().resolvedOptions().timeZone
				}
				onChange={onChange('timeZone')}
			/>

			<ValidateNotify
				error={
					errors.dateEventStart?.date?.message ||
					errors.dateEventStart?.time?.message ||
					errors.timeZone?.message
				}
			/>
		</div>

		<div className={cl.inputContainer}>
			<label htmlFor="duration.hours">Продолжительность *</label>

			<div className={cl.durationContainer}>
				<TextInput
					control={control}
					name="duration.hours"
					id="duration.hours"
					onChange={onChange('duration.hours')}
				/>
				<span className={cl.labelMiddle}>часов</span>
				<TextInput
					control={control}
					name="duration.minutes"
					onChange={onChange('duration.minutes')}
				/>
				<span>минут</span>
			</div>

			<ValidateNotify
				error={
					errors.duration?.hours?.message || errors.duration?.minutes?.message
				}
			/>
		</div>

		<div className={cl.inputContainer}>
			<label>Тип мероприятия</label>

			<RadioGroup
				itemsList={eventTypeItems}
				control={control}
				name="eventType"
				onChange={onChange('eventType')}
			/>
		</div>

		<div
			className={classNames(cl.inputContainer, {
				[cl.hidden]:
					(watch('eventType') as number) !== EventTypes.ONLINE_MEETING
			})}
		>
			<label>Онлайн встреча</label>
			<p className={cl.caption}>
				Добавьте ссылку на онлайн встречу в сервисе Zoom.
			</p>

			<Button
				styleTypes={[ButtonStyles.OUTLINE_BLUE]}
				onClick={onZoomLinkToggle}
			>
				<img src={CameraIcon} alt="" />
				{zoomCallActive ? 'Отключить' : 'Подключить'} сервис конференций Zoom
			</Button>

			<div
				className={classNames(cl.link, {
					[cl.active]: zoomCallActive
				})}
			>
				<TextInput
					control={control}
					name="link"
					onChange={onChange('link')}
					placeholder="Введите ссылку на zoom конференцию"
				/>
			</div>
		</div>

		<div
			className={classNames(cl.inputContainer, {
				[cl.hidden]:
					(watch('eventType') as number) === EventTypes.ONLINE_MEETING
			})}
		>
			<label>Место проведения</label>

			<TextInput
				control={control}
				name="address"
				onChange={onChange('address')}
				placeholder="Укажите точный адрес места проведения"
			/>
		</div>

		<div className={cl.inputContainer}>
			<label htmlFor="comments">Комментарий</label>
			<TextArea
				control={control}
				name="comments"
				id="comments"
				onChange={onChange('comments')}
			/>
		</div>

		<div className={cl.inputContainer}>
			<label htmlFor="capacity">Вместимость</label>
			<div className={cl.durationContainer}>
				<TextInput
					control={control}
					name="capacity"
					id="capacity"
					onChange={onChange('capacity')}
					type="number"
				/>
				<span className={cl.labelMiddle}>участников</span>
			</div>

			<ValidateNotify error={errors.capacity?.message} />
		</div>

		<div className={cl.inputContainer}>
			<label>Ведущий мероприятия *</label>

			<Select
				optionsList={mappedTeachersItems}
				control={control}
				name="eventHost"
				onChange={onChange('eventHost')}
			/>

			<ValidateNotify error={errors.eventHost?.message} />
		</div>

		<Divider />

		<div className={cl.buttons}>
			{update && (
				<Button
					styleTypes={[ButtonStyles.DANGER]}
					onClick={onDeleteEvent && onDeleteEvent}
				>
					Удалить мероприятие
				</Button>
			)}
			<Button
				styleTypes={[ButtonStyles.OUTLINE_PRIMARY]}
				onClick={onCancelCreating}
			>
				Отмена {update ? 'изменения' : 'создания'}
			</Button>
			<Button styleTypes={[ButtonStyles.PRIMARY]} onClick={onSubmit} disabled={disableSubmitButton}>
				{update ? 'Изменить' : 'Опубликовать'} мероприятие
			</Button>
		</div>
	</div>
)

export default CreateEventForm
