import {
	BaseQueryFn,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query'
import { ResponseWithError } from 'types'
import { setModalOpen } from './slices/tariffs'

type baseQueryType = Parameters<typeof fetchBaseQuery>

export const makeFetchBaseQueryWithTariffs = (
	params?: baseQueryType[0]
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => {
	const baseQuery = fetchBaseQuery(params)

	return async (args, api, extraOptions) => {
		const result = await baseQuery(args, api, extraOptions)

		if (result.error && result.error.status === 400) {
			const { error } = result as ResponseWithError

			if (error.data.tariffRestriction) {
				api.dispatch(setModalOpen(error.data.message))
			}
		}
		return result
	}
}
