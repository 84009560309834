import React, {
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import { IEvent } from 'types/models/event.model'

import EventDetailsModalComponent from 'components/Timetable/Tabs/WeekTab/WeekTableCell/EventDetailsModal'
import { getMonthNameByItsNumber } from 'helpers/time'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { formatHoursToDate } from 'helpers/number'
import { useAppSelector } from 'hooks/redux'
import { useNavigate } from 'react-router-dom'
import getMillisecondsFromTimeObject from 'helpers/getMillisecondsFromTimeObject'
import { userQuery } from 'store/queries'

export interface EventDetailsModalContainerProps {
	isOpen: boolean
	activeEvent?: IEvent
	onClose: () => void
}

export enum EventDateStatuses {
	NOT_STARTED,
	STARTED,
	FINISHED
}

const EventDetailsModal: FC<EventDetailsModalContainerProps> = ({
	activeEvent,
	onClose,
	isOpen
}) => {
	dayjs.extend(utc)
	const { data: hostInfo } = userQuery.useGetUserByIdQuery(
		activeEvent?.eventHost || 0
	)

	const { currentRole } = useAppSelector((state) => state.system)
	const [eventStatus, setEventStatus] = useState<EventDateStatuses>(
		EventDateStatuses.NOT_STARTED
	)
	const linkRef = useRef<HTMLAnchorElement>(null)

	const navigate = useNavigate()

	const onEditEvent = useCallback(() => {
		if (!activeEvent) return

		onClose()

		setTimeout(() => {
			navigate(`/course/event/edit/${activeEvent.id}`)
		}, 300)
	}, [activeEvent])

	const onConnectToZoom = useCallback(() => {
		if (!linkRef || !linkRef.current) return

		linkRef.current.click()
	}, [linkRef, activeEvent])

	useEffect(() => {
		if (!activeEvent) return

		const { time, dateEventStart, duration } = activeEvent
		const [hours, minutes] = time.split(':')
		const utcOffset = dayjs(new Date(dateEventStart)).utcOffset()
		const now = new Date()
		const eventStart = dayjs(new Date(dateEventStart))
			.add(+hours, 'hours')
			.add(+minutes, 'minutes')
			.subtract(utcOffset, 'minutes')
			.toDate()
		const dateEventEnd = dayjs(new Date(dateEventStart))
			.add(+hours, 'hours')
			.add(+minutes, 'minutes')
			.add(duration)
			.subtract(utcOffset, 'minutes')
			.toDate()

		if (now < eventStart) {
			setEventStatus(EventDateStatuses.NOT_STARTED)
		} else if (now >= dateEventEnd) {
			setEventStatus(EventDateStatuses.FINISHED)
		} else {
			setEventStatus(EventDateStatuses.STARTED)
		}
	}, [activeEvent])

	const eventHostName = useMemo(() => {
		if (!hostInfo) return ``

		return `${hostInfo.data.firstName} ${hostInfo.data.lastName}`
	}, [hostInfo])

	const dateString = useMemo(() => {
		if (!activeEvent) return ''

		const eventStartDate = new Date(activeEvent.dateEventStart)
		eventStartDate.setHours(0)

		const date = eventStartDate.getDate().toString()
		const monthName = getMonthNameByItsNumber(eventStartDate.getMonth())
		const year = eventStartDate.getFullYear()

		const [hoursStartTime, minutesStartTime] = activeEvent.time.split(':')

		const ms = getMillisecondsFromTimeObject({
			hours: +hoursStartTime,
			minutes: +minutesStartTime
		})

		const eventStartDateTime = dayjs(eventStartDate).add(ms).toDate()

		const endDate = dayjs(eventStartDate)
			.add(activeEvent.duration)
			.add(ms)
			.toDate()

		return `${date} ${monthName} ${year} г., ${formatHoursToDate(
			eventStartDateTime.getHours(),
			eventStartDateTime.getMinutes()
		)} - ${formatHoursToDate(endDate.getHours(), endDate.getMinutes())}`
	}, [activeEvent])

	return (
		<>
			<EventDetailsModalComponent
				eventStatus={eventStatus}
				activeEvent={activeEvent}
				onClose={onClose}
				isOpen={isOpen}
				dateString={dateString}
				currentRole={currentRole}
				onEditEvent={onEditEvent}
				onConnectToZoom={onConnectToZoom}
				eventHostName={eventHostName}
			/>
			<a
				ref={linkRef}
				href={activeEvent?.link}
				rel="noreferrer"
				style={{ display: 'none' }}
				target="_blank"
			>
				Подключиться
			</a>
		</>
	)
}

export default EventDetailsModal
