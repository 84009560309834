import React, { FC, useEffect, useState } from 'react'
import AttemptsTableComponent from 'components/Courses/Entities/Tests/Check/AttemptsTable'
import testQuery from 'store/queries/test.query'
import {
	ITestAnswer,
	GetAnswers,
	GetAnswersResponse
} from 'store/queries/test.query/types'
import { useActions } from 'hooks/redux'
import {
	ResponseEntityId,
	ResponseWithError,
	DEFAULT_TASK_REQUEST_COUNT
} from 'types'
import { useForm } from 'react-hook-form'
import { Pagination } from 'UI/Pagination/Pagination'
import { TestAnswersSort } from '../../TestSort/TestSort'

import cl from './style.module.scss'

export interface AttemptsTableProps {
	userAnswersIdList: string[]
	testQuestionsCount: number
	courseId: ResponseEntityId
	testId: ResponseEntityId
	testName?: string
	courseName?: string
}

export interface TestAnswerSortValues {
	filterStatus?: string
	sortDate: string
	page?: number
}

type PaginationType = Pick<GetAnswers, 'limit' | 'page'>
type FilterBody = Pick<GetAnswers, 'sortDate' | 'filterStatus' | 'page'>

const AttemptsTable: FC<AttemptsTableProps> = ({
	userAnswersIdList,
	testQuestionsCount,
	courseId,
	testId,
	testName,
	courseName
}) => {
	const [testAnswersInfo, setTestAnswersInfo] = useState<ITestAnswer[]>([])
	const [paginationLimits, setPaginationLimits] = useState<PaginationType>({
		limit: DEFAULT_TASK_REQUEST_COUNT,
		page: 1
	})
	const [watchValues, setWatchValues] = useState<FilterBody>()
	const [totalCount, setTotalCount] = useState<number>()

	const {
		setTestAnswersInfo: setTestAnswers,
		setCurrentChecking,
		setCurrentAttempt
	} = useActions((state) => state.test)
	const { pushError } = useActions((state) => state.system)

	const [getAnswersTrigger] = testQuery.useGetAnswersMutation()

	const { control, setValue, register, getValues, watch } =
		useForm<TestAnswerSortValues>()

	let body: GetAnswers = {
		userAnswersIdList,
		limit: paginationLimits.limit,
		filterStatus: getValues().filterStatus,
		sortDate: getValues().sortDate
	}

	const handleSubmit = async (page?: number) => {
		body = {
			...body,
			page: page || paginationLimits.page
		}
		const response = await getAnswersTrigger(body)

		const { error } = response as unknown as ResponseWithError
		if (error) {
			pushError(error.data)
			return
		}

		const { data } = response as unknown as {
			data: GetAnswersResponse
		}

		setTestAnswersInfo(data.data)
		setTestAnswers(data.data)
		setTotalCount(data.totalCount)
	}

	const setPaginationState = (page: number, limit: number) => {
		setValue('page', page)
		setPaginationLimits((prevState) => ({
			...prevState,
			page,
			limit
		}))
	}

	useEffect(() => {
		if (!userAnswersIdList) {
			return
		}

		handleSubmit()
	}, [userAnswersIdList])

	useEffect(() => {
		if (!userAnswersIdList) {
			return
		}

		if (JSON.stringify(body) !== JSON.stringify(watchValues)) {
			setWatchValues(body)
			setPaginationLimits((prevState) => ({
				...prevState,
				page: 1
			}))
			handleSubmit(1)
		}
	}, [watch()])

	useEffect(() => {
		handleSubmit()
	}, [paginationLimits])

	return (
		<div className={cl.container}>
			<TestAnswersSort
				title={`Ответы на «${testName}»`}
				description={`Курс «${courseName}»`}
				setValue={setValue}
				control={control}
				register={register}
				testAnswersInfo={testAnswersInfo}
				testId={testId}
				courseId={courseId}
				setCurrentChecking={setCurrentChecking}
				setCurrentAttempt={setCurrentAttempt}
			/>
			<AttemptsTableComponent
				testId={testId}
				courseId={courseId}
				testQuestionsCount={testQuestionsCount}
				testAnswersInfo={testAnswersInfo}
				userAnswersIdList={userAnswersIdList}
				setCurrentChecking={setCurrentChecking}
				setCurrentAttempt={setCurrentAttempt}
				sortNormal
			/>
			<Pagination
				onChange={(page, limit) => setPaginationState(page, limit)}
				total={totalCount as number}
				showSizeChanger
				pageSizeOptions={[12, 24, 36, 48]}
				current={paginationLimits.page || 1}
			/>
		</div>
	)
}

export default AttemptsTable
