import React, { useCallback, useMemo, useState, useEffect } from 'react'

import { ICourse } from 'types/models/course.model'
import {
	AnswerReviewStatus,
	ITask,
	ITaskAnswerResponse
} from 'types/models/task.model'

import TaskAttempts from 'components/Courses/Entities/Tasks/Attempts'
import AttemptsTable from 'components/Courses/Entities/Tasks/Check/AttemptsTable'
import { Button, Link } from 'UI'
import { LinkStyles } from 'UI/Link/types'
import CheckResponse from 'containers/Courses/Entities/Tasks/CheckResponse'

import { ButtonStyles } from 'UI/Button/types'
import { useNavigate } from 'react-router-dom'
import { useActions, useAppSelector } from 'hooks/redux'
import cl from './style.module.scss'

interface ViewAnswerProps {
	taskId: string
	courseId: string
	task: ITask<string>
	course: ICourse
	creatorId: number
	answerId: string
	taskCurrentAnswer: ITaskAnswerResponse
	taskAnswersInfo: ITaskAnswerResponse[]
}

const ViewAnswer: React.FC<ViewAnswerProps> = ({
	course,
	courseId,
	task,
	taskId,
	creatorId,
	answerId,
	taskCurrentAnswer,
	taskAnswersInfo
}) => {
	const { setCurrentTaskAnswer } = useActions((state) => state.task)
	const navigate = useNavigate()
	const [editMode, setEditMode] = useState(false)
	const [prevAttemptsShown, setPrevAttemptsShown] = useState(false)
	const { showNextTask } = useAppSelector((state) => state.task)
	const { showNextTask: setShowNextTask } = useActions((state) => state.task)

	const allAnswersExceptCurrent = useMemo(
		() =>
			taskAnswersInfo.filter(
				(a) => a.id.toString() !== answerId && a.creatorId === creatorId
			),
		[taskAnswersInfo, answerId]
	)

	const currentAnswerIndex = useMemo(
		() => taskAnswersInfo.findIndex((ans) => ans.id === taskCurrentAnswer.id),
		[taskAnswersInfo, taskCurrentAnswer, showNextTask]
	)

	const lastQuestion = currentAnswerIndex === 0 || currentAnswerIndex === -1
	const firstQuestion =
		currentAnswerIndex >= taskAnswersInfo.length - 1 ||
		currentAnswerIndex === -1

	const onGoBack = useCallback(() => {
		if (lastQuestion) return

		setCurrentTaskAnswer(taskAnswersInfo[currentAnswerIndex - 1])
		navigate(
			`/course/${courseId}/task/${taskId}/answer/attempt/view/${taskAnswersInfo[currentAnswerIndex - 1].id
			}`
		)
	}, [taskAnswersInfo, currentAnswerIndex])

	const onGoForward = useCallback(() => {
		if (firstQuestion) return

		setCurrentTaskAnswer(taskAnswersInfo[currentAnswerIndex + 1])
		navigate(
			`/course/${courseId}/task/${taskId}/answer/attempt/view/${taskAnswersInfo[currentAnswerIndex + 1].id
			}`
		)
	}, [taskAnswersInfo, currentAnswerIndex])

	useEffect(() => {
		if (showNextTask) {
			setShowNextTask(false)
			onGoForward()
		}
	}, [showNextTask])

	const onChangeEditMode = useCallback(() => setEditMode((val) => !val), [])

	return (
		<div className={cl.fullWidth}>
			<Link
				styleTypes={[LinkStyles.GO_BACK]}
				href={`/course/${courseId}/task/view/${taskId}`}
				className={cl.back}
			>
				Вернуться к списку ответов
			</Link>
			<div className={cl.content}>
				<h1>Проверка ответов на {task.name}</h1>

				<p className={cl.course}>Курс {course.name}</p>
				<div className={cl.info}>
					<div className={cl.buttons}>
						<Button
							styleTypes={[ButtonStyles.DARK, ButtonStyles.ROUND]}
							onClick={onGoBack}
							disabled={lastQuestion}
						>
							Предыдущий ответ
						</Button>
						<Button
							styleTypes={[ButtonStyles.DARK, ButtonStyles.ROUND]}
							onClick={onGoForward}
							disabled={firstQuestion}
						>
							Следующий ответ
						</Button>
					</div>
					<div>
						<span>Всего ответов: {task.userAnswersIdList.length}</span>
					</div>
				</div>
			</div>
			<div className={cl.content}>
				<h2>Ответ ученика</h2>

				<TaskAttempts
					answers={[taskCurrentAnswer]}
					countAttempts={task.countAttempts}
					passingScore={10}
					viewMode
					onChangeEditMode={onChangeEditMode}
					noPaddingMobile
				/>

				{(taskCurrentAnswer.answerStatus === AnswerReviewStatus.NEED_CHECK ||
					editMode) && (
						<CheckResponse
							creatorId={taskCurrentAnswer.commentCreatorId}
							passingScore={task.passingScore}
							answerId={answerId}
							taskId={taskId}
							editMode={editMode}
							taskCurrentAnswer={taskCurrentAnswer}
							setEditMode={setEditMode}
							task={task}
							disableShowNextBtn={firstQuestion}
							currentAnswerIndex={currentAnswerIndex}
							taskAnswersInfo={taskAnswersInfo}
						/>
					)}

				<Button
					styleTypes={[ButtonStyles.OUTLINE_DARK, ButtonStyles.ROUND]}
					className={cl.showPrevAttempts}
					onClick={() => setPrevAttemptsShown((val) => !val)}
				>
					{prevAttemptsShown ? 'Скрыть' : 'Показать'} предыдущие попытки
				</Button>

				{prevAttemptsShown && (
					<AttemptsTable
						courseId={+courseId}
						taskAnswersInfo={allAnswersExceptCurrent}
						taskId={+taskId}
						userAnswersIdList={task.userAnswersIdList}
						hideAvatarMobile
					/>
				)}
			</div>
		</div>
	)
}
export default ViewAnswer
