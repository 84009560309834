import React, { FC } from 'react'
import { ErrorMessage } from '@hookform/error-message'

import { Button, CheckboxGroup, RadioGroup, TextInput } from 'UI'
import { AnswersListProps as AnswersListContainerProps } from 'containers/Courses/Entities/Tests/Tabs/Content/QuestionsForm/CreateQuestions/AnswersList'
import { ButtonSizes, ButtonStyles } from 'UI/Button/types'
import plusIconSrc from 'UI/Button/images/plus-secondary.svg'
import trashIconSrc from 'UI/Button/images/trash.svg'
import ValidateNotify from 'UI/Input/ValidateNotify'
import { useMatchMedia } from 'hooks'
import { ResponseEntityId } from 'types'

import cl from '../style.module.scss'

interface AnswersListProps extends AnswersListContainerProps {
	fields: Record<'id', string>[]
	onAppend: () => void
	onOpenModal: (answerId: ResponseEntityId) => void
}

const AnswersList: FC<AnswersListProps> = ({
	fields,
	control,
	onChange,
	getValues,
	errors,
	name,
	onOpenModal,
	onAppend
}) => {
	const { isTable } = useMatchMedia()
	return (
		<div className={cl.answers}>
			{fields.map((field, index) => (
				<div className={cl.inputs} key={field.id}>
					<div className={cl.inputContainer}>
						<TextInput
							onChange={onChange(`${name}.answers.${index}.value`)}
							control={control}
							name={`${name}.answers.${index}.value`}
							placeholder={`Вариант ответа ${index + 1}`}
						/>
						<TextInput
							onChange={onChange(`${name}.answers.${index}.comment`)}
							control={control}
							name={`${name}.answers.${index}.comment`}
							placeholder="Комментарий к ответу"
						/>
						{getValues(`${name}.answerType`) === 1 ? (
							<RadioGroup
								itemsList={[
									{
										id: `${index}`,
										value: getValues(`${name}.answers.${index}.id`),
										labelTitle: 'Верный вариант ответа'
									}
								]}
								name={`${name}.correctAnswersIdList`}
								control={control}
								onChange={onChange(`${name}.correctAnswersIdList`)}
							/>
						) : (
							<CheckboxGroup
								name={`${name}.correctAnswersIdList`}
								control={control}
								onChange={onChange(`${name}.correctAnswersIdList`)}
								itemsList={[
									{
										id: `${index}`,
										value: getValues(`${name}.answers.${index}.id`),
										labelTitle: 'Верный вариант ответа'
									}
								]}
							/>
						)}
						<div className={cl.splitLine} />
					</div>
					<Button onClick={() => onOpenModal(index)}>
						<img src={trashIconSrc} alt="delete" />
						{isTable && 'Удалить'}
					</Button>
				</div>
			))}
			<ErrorMessage
				render={({ message }) => (
					<ValidateNotify className={cl.validateNotify} error={message} />
				)}
				name={`${name}.correctAnswersIdList`}
				errors={errors}
			/>
			<Button
				styleTypes={[
					ButtonStyles.ROUND,
					ButtonSizes.SMALL,
					ButtonStyles.DEFAULT_BG
				]}
				className={cl.addBtn}
				onClick={onAppend}
			>
				<img src={plusIconSrc} alt="plus" />
				Добавить вариант ответа
			</Button>
		</div>
	)
}

export default AnswersList
