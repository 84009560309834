import React from 'react'

import { TariffItem } from 'containers/Tariffs/TariffsInfo'
import cl from './style.module.scss'

interface TariffInfoItemProps extends TariffItem {}

const TariffInfoItem: React.FC<TariffInfoItemProps> = ({ text, value }) => (
	<div className={cl.item}>
		<span className={cl.text}>{text}</span>
		<span className={cl.text}>{value}</span>
	</div>
)

export default TariffInfoItem
