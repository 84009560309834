import React, { FC } from 'react'
import { TimetableTabsContainerProps } from 'containers/Timetable/Tabs'

import { TimetableModeTypes } from 'containers/Timetable/TimetableContent'
import MonthTab from 'containers/Timetable/Tabs/MonthTab'
import WeekTab from 'containers/Timetable/Tabs/WeekTab'
import ScheduleTab from 'containers/Timetable/Tabs/ScheduleTab'
import { IEvent } from 'types/models/event.model'

import classNames from 'classnames'
import cl from './style.module.scss'

interface TimetableTabsComponentProps extends TimetableTabsContainerProps {
	currentMode: TimetableModeTypes
	onDetailsOpen: (_: IEvent) => void
}

const TimetableTabs: FC<TimetableTabsComponentProps> = ({
	control,
	onChange,
	currentMode,
	onDetailsOpen
}) => (
	<div className={cl.table}>
		{currentMode === TimetableModeTypes.MONTH && (
			<div className={cl.fadeIn}>
				<MonthTab
					control={control}
					onChange={onChange}
					onDetailsOpen={onDetailsOpen}
				/>
			</div>
		)}

		{currentMode === TimetableModeTypes.WEEK && (
			<div className={cl.fadeIn}>
				<WeekTab control={control} onDetailsOpen={onDetailsOpen} />
			</div>
		)}

		{currentMode === TimetableModeTypes.SCHEDULE && (
			<div className={classNames(cl.fadeIn, cl.noOverflow)}>
				<ScheduleTab
					control={control}
					onChange={onChange}
					onDetailsOpen={onDetailsOpen}
				/>
			</div>
		)}
	</div>
)

export default TimetableTabs
