import React, { FC } from 'react'
import { UseFieldArrayRemove } from 'react-hook-form'

import { ResponseEntityId } from 'types'
import { Button, Modal } from 'UI'
import { ModalHeight } from 'UI/Modal/types'
import cl from 'containers/Courses/Table/Modals/ConfirmUnPublish/style.module.scss'
import { ButtonStyles } from 'UI/Button/types'

interface DeleteAnswerModalProps {
	isOpen: boolean
	onClose: () => void
	remove: UseFieldArrayRemove
	questionId: ResponseEntityId
	questionName: string
}

const DeleteQuestionModal: FC<DeleteAnswerModalProps> = ({
	isOpen,
	onClose,
	remove,
	questionId,
	questionName
}) => (
	<Modal
		isOpen={isOpen}
		onClose={onClose}
		title="Удалить вопрос?"
		description={`Вы действительно хотите удалить вопрос "${questionName}"?`}
		styleTypes={[ModalHeight.SMALL]}
	>
		<div className={cl.buttonsContainer}>
			<Button
				onClick={onClose}
				styleTypes={[ButtonStyles.DECLINE_PRIMARY, ButtonStyles.ROUND]}
			>
				Отмена
			</Button>
			<Button
				onClick={() => {
					remove(questionId)
					onClose()
				}}
				styleTypes={[ButtonStyles.DANGER, ButtonStyles.ROUND]}
			>
				Удалить
			</Button>
		</div>
	</Modal>
)

export default DeleteQuestionModal
