import React, { FC, FormEvent } from 'react'
import { Control, FieldErrors, FieldPath } from 'react-hook-form'
import classNames from 'classnames'
import {
	TaskAnswerFormState,
	TaskAnswerProps as TaskAnswerContainerProps
} from 'containers/Courses/Entities/Tasks/Answer'
import ValidateNotify from 'UI/Input/ValidateNotify'

import { Avatar, Button, TextArea } from 'UI'
import { IAvatar } from 'UI/Avatar/types'
import { ButtonStyles } from 'UI/Button/types'
import FileInput from 'UI/FileInput'
import { TaskAnswerDoc } from 'types/models/task.model'
import { DevTool } from '@hookform/devtools'

import cl from './style.module.scss'
import AttemptsNumber from './AttemptsNumber'

interface TaskAnswerProps
	extends Omit<TaskAnswerContainerProps, 'taskId' | 'userAnswersIdList'> {
	errors: FieldErrors<TaskAnswerFormState>
	onChange: (name: FieldPath<TaskAnswerFormState>) => (value: any) => void
	onSubmit: (event: FormEvent) => void
	control: Control<TaskAnswerFormState>
	avatarParams: IAvatar
	previewFile: TaskAnswerDoc | TaskAnswerDoc[] | null
	userAnswersIdList: string[]
	attempsExhausted: boolean
	maxCountUploadFiles?: number
	isExpiredDate?: boolean
}

const TaskAnswer: FC<TaskAnswerProps> = ({
	answerResponsibilitiesType,
	errors,
	onChange,
	onSubmit,
	control,
	countAttempts,
	avatarParams = {},
	previewFile,
	userAnswersIdList,
	attempsExhausted,
	maxCountUploadFiles,
	isExpiredDate
}) => (
	<div className={cl.container}>
		<h2>Ответ на задание</h2>
		<form onSubmit={onSubmit} noValidate className={cl.form}>
			<div className={cl.attemptsCount}>
				<AttemptsNumber
					allowedCount={countAttempts}
					currentAnswerCount={userAnswersIdList?.length || 0}
				/>
			</div>
			<div className={cl.answerInfo}>
				<div className={cl.avatar}>
					<Avatar
						firstName={avatarParams.firstName}
						lastName={avatarParams.lastName}
						photoUrl={avatarParams.photoUrl}
					/>
				</div>
				<div className={cl.fields}>
					{answerResponsibilitiesType?.isText && (
						<TextArea
							className={cl.answerTextarea}
							name="answerText"
							error={errors.answerText?.message}
							control={control}
							onChange={onChange('answerText')}
							placeholder="Напишите ответ"
							disabled={isExpiredDate}
						/>
					)}
					<div className={classNames(cl.buttons, answerResponsibilitiesType?.isText && cl.buttonsLeftMob)}>
						{answerResponsibilitiesType?.isFile && (
							<FileInput
								previewFile={previewFile}
								name="docsFiles"
								onChange={onChange('answerFile')}
								control={control}
								disabled={attempsExhausted}
								multiple={
									// eslint-disable-next-line
									maxCountUploadFiles && maxCountUploadFiles > 1 ? true : false
								}
								className={cl.fileInput}
							/>
						)}
						{errors.answerFile && <ValidateNotify error={errors.answerFile.message} />}
						<Button
							className={cl.submitBtn}
							styleTypes={[
								attempsExhausted ? ButtonStyles.DARK : ButtonStyles.PRIMARY,
								ButtonStyles.ROUND
							]}
							type="submit"
							disabled={attempsExhausted || isExpiredDate}
						>
							Отправить ответ
						</Button>
					</div>
				</div>
			</div>
		</form>
		<DevTool control={control} />
	</div>
)

export default TaskAnswer
