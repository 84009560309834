import React, { FC, useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { Column } from 'react-table'
import { getUniqueId } from 'helpers'

import { Dropdown, TableDesktop } from 'UI'
import { DropdownItem } from 'UI/Dropdown/types'
import {
	CourseRole,
	ResponseEntityId,
	ResponseWithError,
	ValidationSuccessMessages
} from 'types'
import { ICourse, StatusType } from 'types/models/course.model'
import { courseQuery, schoolQuery, userQuery } from 'store/queries'
import CoursesMobileTable from 'containers/MobileTables/Courses/CoursesTable'
import { useMatchMedia } from 'hooks'
import PublishModal from 'containers/Courses/Table/Modals/Publish'
import UnPublishModal from 'containers/Courses/Table/Modals/UnPublish'
import ArchiveModal from 'containers/Courses/Table/Modals/Archive'
import CopyModal from 'containers/Courses/Table/Modals/CopyModal'
import CourseOverModal from 'containers/Courses/Table/Modals/CourseOver'

import ConfirmPublishModal from 'containers/Courses/Table/Modals/ConfirmPublish'
import ConfirmUnPublishModal from 'containers/Courses/Table/Modals/ConfirmUnPublish'
import ConfirmEditCourseModal from 'containers/Courses/Table/Modals/ConfirmEditCourse'
import { useAppDispatch, useAppSelector, useActions } from 'hooks/redux'
import { MarketplaceSettings } from 'types/models/course.model/settings/marketplace.model'
import { isPast } from 'helpers/time/index'
import {
	ActionIcon,
	ArchivedIcon,
	CopyIcon,
	EditIcon,
	PublishedIcon,
	TrashedIcon,
	WatchIcon
} from '../Accordion/icons'
import cl from './style.module.scss'

interface CoursesTableParams {
	data: ICourse[]
	maxCoursesReached?: boolean
}

const CoursesTable: FC<CoursesTableParams> = ({ data, maxCoursesReached }) => {
	if (!data) {
		return <></>
	}
	const dataRef = useRef<ICourse[] | null>(null)
	dataRef.current = data

	const maxCoursesRef = useRef<boolean | undefined>()
	maxCoursesRef.current = maxCoursesReached

	const { currentRole } = useAppSelector((state) => state.system)

	const dispatch = useAppDispatch()
	const [setCourseUnPublish] = courseQuery.useCoursesUnPublishMutation()

	const [courseTitle, setCourseTitle] = useState('')
	const [courseId, setCourseId] = useState(0)

	const [setCourseArchive] = courseQuery.useCoursesArchiveMutation()
	const [copyCourse] = courseQuery.useCopyCourseMutation()

	const [isPublishConfirmModalOpen, setIsPublishConfirmModalOpen] =
		useState(false)
	const [isUnPublishConfirmModalOpen, setIsUnPublishConfirmModalOpen] =
		useState(false)
	const [isConfirmEditModalOpen, setIsConfirmEditModalOpen] = useState(false)
	const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
	const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
	const [isUnPublishModalOpen, setIsUnPublishModalOpen] = useState(false)
	const [copyModalOpen, setCopyModalOpen] = useState(false)
	const [courseOverModal, setCourseOverModal] = useState(false)
	const [, setCurrentCourseDateStart] = useState(new Date())
	const { pushError } = useActions((state) => state.system)
	const courseEdit = (id: ResponseEntityId) => {
		setCourseId(id)
		setIsConfirmEditModalOpen(true)
	}

	const coursePublish = (
		id: ResponseEntityId,
		name: string,
		marketplaceSettings: MarketplaceSettings
	) => {
		if (maxCoursesRef.current) {
			pushError({
				message: 'Достигнуто максимальное число опубликованных курсов в рамках текущего тарифа'
			})
			return
		}
		const courseToBePublished = dataRef.current?.find(course => course.id === id)
		const isCourseFinished = courseToBePublished && isPast(courseToBePublished?.marketplaceSettings.dateLearningSettings.end)
		setCourseId(id)
		if (isCourseFinished) {
			setCourseOverModal(true)
			return
		}

		setCurrentCourseDateStart(marketplaceSettings.dateLearningSettings.start)
		setCourseTitle(name)
		setIsPublishConfirmModalOpen(true)
	}
	const courseUnPublish = async (id: ResponseEntityId, name: string) => {
		setCourseTitle(name)
		setCourseId(id)
		setIsUnPublishConfirmModalOpen(true)
	}
	const courseTrashedQuery = async (id: ResponseEntityId, name: string) => {
		const response = await setCourseUnPublish(id)
		const { error } = response as unknown as ResponseWithError
		if (error) {
			return
		}
		setCourseTitle(name)
		setIsUnPublishModalOpen(true)
	}
	const courseArchiveQuery = async (id: ResponseEntityId, name: string) => {
		const response = await setCourseArchive(id)
		const { error } = response as unknown as ResponseWithError
		if (error) {
			return
		}
		setCourseTitle(name)
		setIsArchiveModalOpen(true)
	}
	const courseCopyQuery = async (id: ResponseEntityId, name: string) => {
		const response = await copyCourse({ courseId: id, role: currentRole })
		const { error } = response as unknown as ResponseWithError
		if (error) {
			return
		}
		setCourseTitle(name)
		dispatch(schoolQuery.util.invalidateTags(['school']))
		dispatch(userQuery.util.invalidateTags(['user']))
		setCopyModalOpen(true)
	}

	const { isTable } = useMatchMedia()

	const getDropdownItemsList = useCallback(
		({
			statusType,
			id,
			name,
			marketplaceSettings
		}: ICourse): DropdownItem[] => {
			switch (statusType) {
				case StatusType.PUBLISHED:
					if (
						currentRole !== CourseRole.CURATOR &&
						currentRole !== CourseRole.STUDENT
					) {
						return [
							{
								id: getUniqueId(),
								icon: EditIcon,
								label: 'Редактировать',
								onClick: () => courseEdit(id)
							},
							{
								id: getUniqueId(),
								icon: WatchIcon,
								label: 'Просмотреть материалы',
								link: `/course/view/${id}`
							},
							{
								id: getUniqueId(),
								icon: CopyIcon,
								label: 'Копировать',
								onClick: () => courseCopyQuery(id, name)
							},
							{
								id: getUniqueId(),
								icon: TrashedIcon,
								label: 'Снять с публикации',
								onClick: () => courseUnPublish(id, name)
							},
							{
								id: getUniqueId(),
								icon: ArchivedIcon,
								label: 'Архивировать',
								onClick: () => courseArchiveQuery(id, name)
							}
						]
					}
					return [
						{
							id: getUniqueId(),
							icon: WatchIcon,
							label: 'Просмотреть материалы',
							link: `/course/view/${id}`
						}
					]
				case StatusType.TRASHED:
					if (
						currentRole !== CourseRole.CURATOR &&
						currentRole !== CourseRole.STUDENT
					) {
						return [
							{
								id: getUniqueId(),
								icon: EditIcon,
								label: 'Редактировать',
								link: `/course/edit/${id}`
							},
							{
								id: getUniqueId(),
								icon: CopyIcon,
								label: 'Копировать',
								onClick: () => courseCopyQuery(id, name)
							},
							{
								id: getUniqueId(),
								icon: PublishedIcon,
								label: 'Опубликовать',
								onClick: () => coursePublish(id, name, marketplaceSettings)
							},
							{
								id: getUniqueId(),
								icon: ArchivedIcon,
								label: 'Архивировать',
								onClick: () => courseArchiveQuery(id, name)
							}
						]
					}
					return []
				case StatusType.ARCHIVED:
					if (
						currentRole !== CourseRole.CURATOR &&
						currentRole !== CourseRole.STUDENT
					) {
						return [
							{
								id: getUniqueId(),
								icon: TrashedIcon,
								label: 'Восстановить в черновики',
								onClick: () => courseTrashedQuery(id, name)
							}
						]
					}
					return []
				default:
					return []
			}
		},
		[currentRole, maxCoursesReached]
	)
	const columns: Column<Record<string, unknown>>[] = useMemo(
		() => [
			{
				Header: 'Название',
				accessor: 'name',
				Cell: ({ row }: any) => {
					const original = row.original as ICourse
					const { data: courseInfo } = courseQuery.useGetCourseQuery(
						original.id
					)
					if (!courseInfo) {
						return <></>
					}
					return (
						<div className={cl.nameInfoWithImg}>
							{courseInfo.data.coverUrl && (
								<div className={cl.coverContainer}>
									<img src={courseInfo.data.coverUrl} alt="coverUrl" />
								</div>
							)}
							<span>{courseInfo.data.name}</span>
						</div>
					)
				}
			},
			{
				Header: 'Автор',
				accessor: 'creatorId',
				Cell: ({ value }: any) => {
					const { data: userInfo } = userQuery.useGetUserByIdQuery(value)
					if (!userInfo) {
						return <></>
					}
					return (
						<>
							{userInfo.data.firstName} {userInfo.data.lastName}
						</>
					)
				}
			},
			{
				Header: 'Количество уроков',
				accessor: 'sectionsIdList',
				Cell: ({ row }: any) => {
					const original = row.original as ICourse
					const { data: lessonsCount, error } =
						courseQuery.useGetLessonsCountQuery(original.id)
					if (error) {
						return <>-</>
					}
					return <>{lessonsCount?.lessonCount}</>
				}
			},
			{
				Header: 'Непроверенных ответов',
				Cell: ({ row }: any) => {
					const original = row.original as ICourse
					const { data: unverifiedAnswersCount, error } =
						courseQuery.useGetUnverifiedAnswersQuery(original.id)
					if (error) {
						return <>-</>
					}
					return <>{unverifiedAnswersCount?.unverifiedAnswersCount}</>
				}
			},
			{
				Header: 'Дата создания',
				accessor: 'dateCreate'
			},
			{
				Header: '',
				accessor: 'id',
				Cell: ({ row }: any) => {
					const original = row.original as ICourse
					return (
						<Dropdown dropdownItemsList={getDropdownItemsList(original)}>
							<img src={ActionIcon} alt="actions" />
						</Dropdown>
					)
				}
			}
		],
		[currentRole]
	)
	return (
		<>
			<ConfirmEditCourseModal
				onClose={() => setIsConfirmEditModalOpen(false)}
				courseId={courseId}
				isOpen={isConfirmEditModalOpen}
			/>
			<ConfirmPublishModal
				onClose={() => setIsPublishConfirmModalOpen(false)}
				setIsPublishModalOpen={setIsPublishModalOpen}
				isOpen={isPublishConfirmModalOpen}
				courseTitle={courseTitle}
				courseId={courseId}
			/>
			<ConfirmUnPublishModal
				onClose={() => setIsUnPublishConfirmModalOpen(false)}
				setIsUnPublishModalOpen={setIsUnPublishModalOpen}
				isOpen={isUnPublishConfirmModalOpen}
				courseId={courseId}
			/>
			<PublishModal
				onClose={() => setIsPublishModalOpen(false)}
				isOpen={isPublishModalOpen}
				courseTitle={courseTitle}
			/>
			<UnPublishModal
				onClose={() => setIsUnPublishModalOpen(false)}
				courseTitle={courseTitle}
				isOpen={isUnPublishModalOpen}
			/>
			<ArchiveModal
				onClose={() => setIsArchiveModalOpen(false)}
				isOpen={isArchiveModalOpen}
				courseTitle={courseTitle}
			/>
			<CopyModal
				onClose={() => setCopyModalOpen(false)}
				isOpen={copyModalOpen}
				courseTitle={courseTitle}
			/>
			<CourseOverModal
				onClose={() => setCourseOverModal(false)}
				isOpen={courseOverModal}
				courseId={courseId}
			/>

			{isTable ? (
				<CoursesMobileTable
					getDropdownItemsList={getDropdownItemsList}
					data={data}
				/>
			) : (
				<TableDesktop
					columns={columns}
					data={data as unknown as Record<string, unknown>[]}
				/>
			)}
		</>
	)
}

export default CoursesTable
