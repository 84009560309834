import React, { FC } from 'react'

const TariffsIcon: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.8442 3.19833C14.6901 2.98255 14.4869 2.80663 14.2512 2.68518C14.0155 2.56372 13.7543 2.50024 13.4892 2.5H6.51085C5.97418 2.5 5.46751 2.76083 5.12168 3.24917L1.78835 8.73333C1.69521 8.88634 1.6539 9.06531 1.67056 9.24366C1.68723 9.42201 1.76097 9.59023 1.88085 9.72333L9.38085 18.0567C9.45857 18.144 9.55391 18.214 9.66059 18.2619C9.76727 18.3099 9.88288 18.3347 9.99983 18.3348C10.1168 18.3348 10.2324 18.3102 10.3392 18.2624C10.4459 18.2146 10.5413 18.1448 10.6192 18.0575L18.1192 9.72417C18.2393 9.59119 18.3132 9.42295 18.3299 9.24453C18.3465 9.06612 18.3051 8.88709 18.2117 8.73417L14.8442 3.19833ZM10 16.2542L4.37085 10H15.6283L10 16.2542ZM3.98085 8.33333L6.51085 4.16667L13.4542 4.115L16.0183 8.33333H3.98085Z"
			fill="#666666"
		/>
	</svg>
)

export default TariffsIcon
