import React, { FC, useEffect, useState } from 'react'
import { ReviewItemProps as ReviewItemContainerProps } from 'containers/Courses/Tabs/ViewContent/Feedback/ReviewItem'
import { ResponseEntityId } from 'types'
import { Avatar } from 'UI'
import classnames from 'classnames'
import rateIconSrc from './icons/rate.svg'
import arrowIconSrc from './icons/arrow.svg'

import cl from './style.module.scss'

interface ReviewItemProps {
	text: string
	evaluation: ResponseEntityId
	dateCreate: string
	user: string
	avatar?: string
}

const ReviewItem: FC<ReviewItemProps> = ({
	dateCreate,
	evaluation,
	text,
	user,
	avatar
}) => {
	const [textIsOpen, setTextIsOpen] = useState(false)
	const [trimmedText, setTrimmedText] = useState<string>('')

	const textToggleClasses = classnames(cl.textToggle, {
		[cl.textToggleActive]: textIsOpen
	})

	useEffect(() => {
		if (!textIsOpen && text.length > 240) {
			setTrimmedText(`${text.slice(0, 240)}...`)
			return
		}
		setTrimmedText(text)
	}, [textIsOpen, text])

	return (
		<div className={cl.reviewItem}>
			<div className={cl.reviewImage}>
				<Avatar
					firstName={user}
					photoUrl={avatar}
				/>
			</div>
			<div className={cl.reviewTextContent}>
				<div className={cl.textContentHeader}>
					<p className={cl.reviewInfo}>
						<span className={cl.initials}>
							{user}
						</span>
						<div className={cl.reviewRate}>
							<img src={rateIconSrc} alt={rateIconSrc} />
							<span>{evaluation}</span>
						</div>
					</p>
					<div className={cl.reviewDate}>{dateCreate}</div>
				</div>
				<div className={cl.textContentBody}>
					<p>{trimmedText}</p>
				</div>
				{text.length > 240 && (
					<div className={textToggleClasses}>
						<span onClick={() => setTextIsOpen((prev) => !prev)}>
							{textIsOpen ? 'Свернуть' : 'Показать еще'}
							<img src={arrowIconSrc} alt={arrowIconSrc} />
						</span>
					</div>
				)}
			</div>
		</div>
	)
}

export default ReviewItem
