import React, { FC } from 'react'

import ContentFormTable from 'containers/Courses/Tabs/Content/StudentsForm/Table'
import { StudentsFormTabProps as StudentsFormTabContainerProps } from 'containers/Courses/Tabs/Content/StudentsForm'
import InviteStudent from 'containers/Courses/Tabs/Content/StudentsForm/Invite'

import cl from './style.module.scss'

interface StudentsFormTabProps extends StudentsFormTabContainerProps {}

const StudentsFormTab: FC<StudentsFormTabProps> = ({ courseInfo }) => (
	<div className={cl.container}>
		<div className={cl.header}>
			<span>Просматривайте учеников, поступивших на курс</span>
			<InviteStudent courseId={courseInfo.id} />
		</div>
		<div className={cl.body}>
			<ContentFormTable courseInfo={courseInfo} />
		</div>
	</div>
)

export default StudentsFormTab
