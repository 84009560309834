import React, { useCallback, useEffect } from 'react'

import NotificationsFormTabComponent from 'components/Courses/Tabs/Content/NotificationsForm'
import { useParams } from 'react-router-dom'
import { userQuery } from 'store/queries'
import { LoaderStyles } from 'UI/Loader/types'
import { Loader } from 'UI'
import { NotificationSetting } from 'store/queries/user.query/types'
import { FieldPath, FieldPathValue, useForm } from 'react-hook-form'
import { ICourse } from 'types/models/course.model'
import getWeeksByDays from 'helpers/time/getWeeksByDays'
import { EntityId } from '@reduxjs/toolkit'

export interface NotificationsFormTabContainerProps {
	settings: NotificationSetting
	isActive: boolean
	courseInfo: ICourse
}
export enum TimeMeasure {
	DAYS,
	WEEKS,
	MONTHS
}
interface ReminderItem {
	id: EntityId
	inNumber: string
	timeMeasure: TimeMeasure
}

export type ReminderItemValue = ReminderItem

export interface NotificationsReminderFormValues {
	reminderDelaysDaysList: ReminderItemValue[]
}

const NotificationsFormTab: React.FC<NotificationsFormTabContainerProps> = ({
	settings,
	isActive,
	courseInfo
}) => {
	const { courseId } = useParams()

	const { register, control, setValue, handleSubmit, watch } =
		useForm<NotificationsReminderFormValues>()

	const [getNotificationEvents, { data: notificationEvents, isLoading }] =
		userQuery.useGetEventsForNotificationsMutation()

	useEffect(() => {
		if (!courseId) return

		getNotificationEvents({ courseId, courseSettings: true })
	}, [courseId])

	useEffect(() => {
		register('reminderDelaysDaysList')
	}, [])

	const onChange = useCallback(
		(name: FieldPath<NotificationsReminderFormValues>) =>
			(value: FieldPathValue<NotificationsReminderFormValues, typeof name>) => {
				setValue(name, value)
			},
		[]
	)

	const onSubmit = useCallback(
		handleSubmit(() => {}),
		[]
	)

	useEffect(() => {
		setValue(
			'reminderDelaysDaysList',
			courseInfo.notificationsSettings.reminderDelaysDaysList.map((element) =>
				getWeeksByDays(element)
			) || []
		)
	}, [courseInfo])

	if (isLoading || !notificationEvents) {
		return <Loader styleTypes={[LoaderStyles.BIG]} />
	}

	return (
		<NotificationsFormTabComponent
			settings={settings}
			events={notificationEvents}
			isActive={isActive}
			control={control}
			onChange={onChange}
			onSubmit={onSubmit}
			reminderValue={watch('reminderDelaysDaysList')}
		/>
	)
}

export default NotificationsFormTab
