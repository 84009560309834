import { isNil } from 'lodash'
import { getFileWithoutId } from './files'

type GetFormData = (data: Record<string, any>) => Promise<FormData>
const getFormData: GetFormData = async (data) => {
	const dt = new FormData()
	await Promise.all(
		Object.entries(data).map(async ([key, value]) => {
			if (isNil(value)) {
				return false
			}
			if (Object.getPrototypeOf(value) === File.prototype) {
				const file = await getFileWithoutId(value)
				if (!file) {
					return false
				}
				dt.append(key, file)
				return true
			}

			if (
				Array.isArray(value) &&
				value.every(
					(probablyFile) =>
						Object.getPrototypeOf(probablyFile) === File.prototype
				)
			) {
				await Promise.all(
					value.map(async (fileFromArr, index) => {
						const file = await getFileWithoutId(fileFromArr)
						if (!file) {
							return false
						}
						dt.append(`${key}.${index}`, file)
						return true
					})
				)
				return true
			}

			if (Object.getPrototypeOf(value) === Object.prototype) {
				return getFormData(value)
			}
			dt.append(key, value)
			return true
		})
	)
	return dt
}

export default getFormData
