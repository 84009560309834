import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { SchoolService } from 'API'
import systemSlice from 'store/slices/system'
import { CheckInviteCodeParams } from 'API/SchoolService/types'
import { SchoolSliceState } from 'store/slices/school/types'

const checkInviteCode = createAsyncThunk(
	'school/checkInviteStudentCodeExtra',
	async (params: CheckInviteCodeParams, { rejectWithValue, dispatch }) => {
		try {
			await SchoolService.checkInviteCode(params)

			return params
		} catch ({ message }) {
			dispatch(systemSlice.actions.pushError({ message: message as string }))

			return rejectWithValue({ message })
		}
	}
)

export const checkInviteCodeExtra = {
	[checkInviteCode.fulfilled.type]: (state: SchoolSliceState) => {
		state.meta.isLoading = false
		state.meta.error = ''
	},
	[checkInviteCode.pending.type]: (state: SchoolSliceState) => {
		state.meta.isLoading = true
		state.meta.error = ''
	},
	[checkInviteCode.rejected.type]: (
		state: SchoolSliceState,
		action: PayloadAction<string>
	) => {
		state.meta.isLoading = false
		state.meta.error = action.payload
	}
}

export default checkInviteCode
